import {DEBUG_LEVEL} from "../constant";

export default class Debug {
    constructor(master) {
        this.log = (name, ...args) => {
            if (master._opt.debug && master._opt.debugLevel == DEBUG_LEVEL.debug) {
                const prefix = master._opt.debugUuid ? `[${master._opt.debugUuid}]` : '';
                console.log(`JbPro${prefix}[\u2705\u2705\u2705][${name}]`, ...args);
            }
        };

        this.warn = (name, ...args) => {
            if (master._opt.debug && (master._opt.debugLevel == DEBUG_LEVEL.debug || master._opt.debugLevel == DEBUG_LEVEL.warn)) {
                const prefix = master._opt.debugUuid ? `[${master._opt.debugUuid}]` : '';
                console.log(`JbPro${prefix}[\u2757\u2757\u2757][${name}]`, ...args);
            }
        };

        this.error = (name, ...args) => {
            const prefix = master._opt.debugUuid ? `[${master._opt.debugUuid}]` : '';
            console.error(`JbPro${prefix}[\u274C\u274C\u274C][${name}]`, ...args);

            // if (master.addMemoryLog) {
            //     master.addMemoryLog(`[${name}]`, ...args);
            // }
        };
    }
}
