import Emitter from "../utils/emitter";
import {
    AIDIO_MAX_VIDEO_DIFF,
    AUDIO_ENC_TYPE,
    AUDIO_ENGINE_TYPE,
    EVENTS,
    EVENTS_ERROR, PLAY_TYPE, URL_OBJECT_CLEAR_TIME,
    VIDEO_ENC_TYPE
} from "../constant";
import {
    createWorkletModuleUrl,
    isFalse,
    isInHttps,
    isIOS, isTrue,
    isWeChatInAndroid, isWeChatInIOS,
    noop
} from "../utils";
import CommonContextLoader from "./commonContextLoader";
import Processor from "./processor";
import RateProcessor from "./rateProcessor";

export default class AudioContextLoader extends CommonContextLoader {
    constructor(player) {
        super(player);
        // default is 1
        this.defaultPlaybackRate = 1;
        this.playbackRate = 1;
        this.rateProcessor = null;
        this.processor = null;
        this.scriptNodeInterval = null;
        this.engineType = this.getAutoAudioEngineType()
        this.audioBufferSize = this.getAudioBufferSizeByType();
        this.$audio = null;
        this._delayPlay = false;
        this.eventListenList = [];
        this.workletUrl = null;
        this.clearWorkletUrlTimeout = null;

        // support mobile(ipad) lock screen play
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
        if (this.player._opt.supportLockScreenPlayAudio && (isIOS())) {
            this.$audio = document.createElement("audio");
            Object.assign(this.$audio.style, {
                position: "absolute",
                left: "-100%",
                top: "-100%"
            })
            if (player.$container) {
                player.$container.appendChild(this.$audio);
            } else {
                document.body.appendChild(this.$audio);
            }
            this._bindAudioProxy();
            this.player.debug.log('AudioContext', `create audio element`);
        }

        this.scriptStartTime = 0;
        this.player.debug.log('AudioContext', 'init', `engineType: ${this.engineType}, audioBufferSize: ${this.audioBufferSize}`);
    }

    //
    destroy() {
        super.destroy();

        if (this.workletUrl) {
            URL.revokeObjectURL(this.workletUrl);
            this.workletUrl = null;
        }

        if (this.clearWorkletUrlTimeout) {
            clearTimeout(this.clearWorkletUrlTimeout);
            this.clearWorkletUrlTimeout = null;
        }

        if (this.eventListenList) {
            this.eventListenList.forEach((item) => {
                item();
            })
            this.eventListenList = [];
        }

        if (this.$audio) {
            this.$audio.pause();
            this.$audio.srcObject = null;
            if (this.$audio.parentNode) {
                this.$audio.parentNode.removeChild(this.$audio);
            }
            this.$audio = null;
        }

        if (this.processor) {
            this.processor.destroy();
            this.processor = null;
        }
        if (this.rateProcessor) {
            this.rateProcessor.destroy();
            this.rateProcessor = null;
        }

        if (this.scriptNodeInterval) {
            clearInterval(this.scriptNodeInterval);
            this.scriptNodeInterval = null;
        }

        this.defaultPlaybackRate = 1;
        this.playbackRate = 1;
        this.scriptStartTime = 0;
        this.audioBufferSize = 0;
        this.engineType = AUDIO_ENGINE_TYPE.script;
        this.player.debug.log('AudioContext', 'destroy');
    }

    // is playing
    isAudioPlaying() {
        return this.$audio &&
            isFalse(this.$audio.paused) &&
            isFalse(this.$audio.ended) &&
            this.$audio.playbackRate !== 0 &&
            this.$audio.readyState !== 0;
    }

    _bindAudioProxy() {
        const {proxy} = this.player.events;

        const canplayProxyDestroy = proxy(this.$audio, 'canplay', () => {
            this.player.debug.log('AudioContext', 'canplay');
            if (this._delayPlay) {
                this._audioElementPlay();
            }
        })
        this.eventListenList.push(canplayProxyDestroy);
    }

    _getAudioElementReadyState() {
        let result = 0;
        if (this.$audio) {
            result = this.$audio.readyState;
        }
        return result;
    }

    audioElementPlay() {
        if (this.$audio) {
            const readyState = this._getAudioElementReadyState();
            this.player.debug.log('AudioContext', `play and readyState: ${readyState}`);
            //  wechat in ios not emit canplay 事件
            if (readyState === 0 && !isWeChatInIOS()) {
                this.player.debug.warn('AudioContext', 'readyState is 0 and set _delayPlay to true');
                this._delayPlay = true;
                return;
            }
            this._audioElementPlay();
        }
    }

    _audioElementPlay() {
        this.$audio && this.$audio.play().then(() => {
            this._delayPlay = false;
            this.player.debug.log('AudioContext', '_audioElementPlay success');
            setTimeout(() => {
                if (!this.isAudioPlaying()) {
                    this.player.debug.warn('AudioContext', `play failed and retry play`)
                    this._audioElementPlay();
                }
            }, 100)
            if (this.isAudioPlaying()) {
                this.player.debug.log('AudioContext', `play success and remove document click event listener`);
                document.removeEventListener('click', this._audioElementPlay.bind(this));
            }
        }).catch((e) => {
            this.player.debug.error('AudioContext', '_audioElementPlay error', e);
            document.addEventListener('click', this._audioElementPlay.bind(this));
        })
    }

    getAudioBufferSize() {
        return this.audioBufferSize;
    }

    get oneBufferDuration() {
        return (this.audioBufferSize / this.audioContext.sampleRate) * 1000; // ms
    }

    get isActiveEngineType() {
        return this.engineType === AUDIO_ENGINE_TYPE.active;
    }

    initProcessor() {
        this.processor = new Processor(this.player, this, this.audioInfo.channels, this.audioBufferSize)
        this.rateProcessor = new RateProcessor(this.player, this, this.processor);
    }

    getAutoAudioEngineType() {
        let result = this.player._opt.audioEngine || AUDIO_ENGINE_TYPE.script;
        const _autoCalcAudioEngine = () => {
            if (isWeChatInAndroid()) {
                // wechat android
                result = AUDIO_ENGINE_TYPE.active;
            } else if (isIOS() && this.player._opt.supportLockScreenPlayAudio) {
                result = AUDIO_ENGINE_TYPE.script;
            } else if (isInHttps()) {
                result = AUDIO_ENGINE_TYPE.worklet;
            } else {
                result = AUDIO_ENGINE_TYPE.script;
            }
        }

        if (this.player._opt.audioEngine) {
            if (this.player._opt.audioEngine === AUDIO_ENGINE_TYPE.worklet && isInHttps()) {
                result = AUDIO_ENGINE_TYPE.worklet;
            } else if (this.player._opt.audioEngine === AUDIO_ENGINE_TYPE.active) {
                result = AUDIO_ENGINE_TYPE.active;
            } else if (this.player._opt.audioEngine === AUDIO_ENGINE_TYPE.script) {
                result = AUDIO_ENGINE_TYPE.script;
            } else {
                // auto
                _autoCalcAudioEngine();
            }
        } else {
            _autoCalcAudioEngine();
        }

        return result;
    }

    getAudioBufferSizeByType() {
        const engineType = this.engineType;
        const hasVideo = this.player._opt.hasVideo;
        const weiXinInAndroidAudioBufferSize = this.player._opt.weiXinInAndroidAudioBufferSize;
        if (engineType === AUDIO_ENGINE_TYPE.worklet) {
            return 1024;
        } else if (engineType === AUDIO_ENGINE_TYPE.active) {
            return weiXinInAndroidAudioBufferSize || 4800;
        } else if (engineType === AUDIO_ENGINE_TYPE.script) {
            return 1024;
        } else {
            return 1024;
        }
    }

    //
    initScriptNode() {
        this.playing = true;

        if (this.hasInitScriptNode) {
            return;
        }
        this.initProcessor();

        if (this.engineType === AUDIO_ENGINE_TYPE.worklet) {
            this.initWorkletScriptNode();
        } else if (this.engineType === AUDIO_ENGINE_TYPE.active) {
            this.initIntervalScriptNode();
        } else if (this.engineType === AUDIO_ENGINE_TYPE.script) {
            this.initProcessScriptNode();
        }

        this.audioElementPlay();
    }

    getEngineType() {
        return this.engineType;
    }

    isPlaybackRateSpeed() {
        return this.playbackRate > this.defaultPlaybackRate;
    }

    initProcessScriptNode() {
        const scriptNode = this.audioContext.createScriptProcessor(this.audioBufferSize, 0, this.audioInfo.channels);
        // tips: if audio isStateSuspended  onaudioprocess method not working
        scriptNode.onaudioprocess = (audioProcessingEvent) => {
            const outputBuffer = audioProcessingEvent.outputBuffer;
            this.handleScriptNodeCallback(outputBuffer)
        }

        scriptNode.connect(this.gainNode);
        this.scriptNode = scriptNode;
        this.gainNode.connect(this.mediaStreamAudioDestinationNode);

        if (this.$audio) {
            this.$audio.srcObject = this.mediaStreamAudioDestinationNode.stream;
        } else {
            this.gainNode.connect(this.audioContext.destination);
        }
        this.hasInitScriptNode = true;
    }

    initIntervalScriptNode() {
        this.scriptStartTime = 0;
        //  1000 * 4800 / 48000 = 100ms
        //  1000 * 1600 / 1600 = 100ms
        const intervalTime = 1000 * this.audioBufferSize / this.audioContext.sampleRate;
        this.scriptNodeInterval = setInterval(() => {
            if (this.bufferList.length === 0 ||
                isFalse(this.playing) ||
                this.isMute) {
                if (this.playing && isFalse(this.isMute)) {
                    this.player.debug.log('AudioContext', `interval script node and bufferList is ${this.bufferList.length} or playing is ${this.playing}`)
                }
                return;
            }

            const audioSource = this.audioContext.createBufferSource();
            const outputBuffer = this.audioContext.createBuffer(this.audioInfo.channels, this.audioBufferSize, this.audioContext.sampleRate);
            this.handleScriptNodeCallback(outputBuffer, () => {
                if (this.scriptStartTime < this.audioContext.currentTime) {
                    this.player.debug.log('AudioContext', `script start time ${this.scriptStartTime} is less than current time ${this.audioContext.currentTime}`);
                    this.scriptStartTime = this.audioContext.currentTime;
                }

                audioSource.buffer = outputBuffer;
                audioSource.connect(this.gainNode);
                audioSource.start(this.scriptStartTime);
                this.scriptStartTime += outputBuffer.duration;
            });
        }, intervalTime);

        this.gainNode.connect(this.mediaStreamAudioDestinationNode);

        if (this.$audio) {
            this.$audio.srcObject = this.mediaStreamAudioDestinationNode.stream;
        } else {
            this.gainNode.connect(this.audioContext.destination);
        }
        this.hasInitScriptNode = true;
    }

    initWorkletScriptNode() {

        function audiWorkletProcessor() {
            class WorkletProcessor extends AudioWorkletProcessor {
                constructor() {
                    super();
                    this.audioBufferSize = 1024;
                    this.start = false;
                    this.channels = 1;
                    this.samplesArray = [];
                    this.offset = 0;
                    this.state = 0;
                    this.port.onmessage = (e) => {
                        // console.log('WorkletProcessor onmessage', e.data);
                        if (e.data.message === "init") {
                            this.audioBufferSize = e.data.audioBufferSize;
                            this.start = e.data.start;
                            this.channels = e.data.channels;
                            this.state = 0;
                            this.offset = 0;
                            this.samplesArray = [];
                        } else if (e.data.message === "stop") {
                            this.state = 0;
                            this.start = false;
                            this.offset = 0;
                            this.samplesArray = [];
                        } else if (e.data.message === "data") {
                            this.samplesArray.push(e.data.buffer)
                        } else if (e.data.message === "zero") {
                            this.samplesArray.push({
                                left: new Float32Array(this.audioBufferSize).fill(0),
                                right: new Float32Array(this.audioBufferSize).fill(0)
                            })
                        }
                    }
                }

                process(inputs, outputs, parameters) {
                    const outputLeft = outputs[0][0];
                    const outputRight = outputs[0][1];
                    if (this.offset === 0) {
                        this.port.postMessage({message: "beep"});
                    }
                    if (this.state === 0) {
                        this.state = 1
                    } else if (this.state === 1 && this.samplesArray.length >= 4) {
                        this.state = 2
                    } else if (this.state === 2) {
                        const bufferItem = this.samplesArray[0];
                        for (let i = 0; i < outputLeft.length; i++) {
                            if (this.channels === 1) {
                                outputLeft[i] = bufferItem.left[i + this.offset];
                            } else if (this.channels === 2) {
                                outputLeft[i] = bufferItem.left[i + this.offset];
                                if (outputRight) {
                                    outputRight[i] = bufferItem.right[i + this.offset];
                                }
                            }
                        }
                    } else {
                        if (this.channels === 1) {
                            outputLeft.fill(0)
                        } else if (this.channels === 2) {
                            outputLeft.fill(0)
                            if (outputRight) {
                                outputRight.fill(0)
                            }
                        }
                    }
                    this.offset += 128;
                    if (this.offset === this.audioBufferSize) {
                        this.offset = 0;
                        if (this.state === 2) {
                            this.samplesArray.shift();
                        }
                        if (this.samplesArray.length === 0) {
                            this.state = 0;
                        }
                    }

                    return this.start;
                }
            }

            registerProcessor('worklet-processor', WorkletProcessor);
        }

        let workletUrl = createWorkletModuleUrl(audiWorkletProcessor);
        this.workletUrl = workletUrl;
        this.audioContext && this.audioContext.audioWorklet.addModule(workletUrl).then(() => {
            if (this.player.isDestroyed()) {
                this.player.debug.log('AudioContext', 'initWorkletScriptNode() player is destroyed');
                return;
            }

            if (!this.audioContext) {
                this.player.debug.warn('AudioContext', 'initWorkletScriptNode audioContext is null');
                return;
            }
            let outputChannelCount = [1];
            if (this.audioInfo.channels === 2) {
                outputChannelCount = [1, 1];
            }
            try {
                this.workletProcessorNode = new AudioWorkletNode(this.audioContext, "worklet-processor", {
                    numberOfOutputs: this.audioInfo.channels,
                    outputChannelCount
                });
            } catch (e) {
                //  DOMException: Failed to construct 'AudioWorkletNode': AudioWorkletNode cannot be created:
                //  The node name 'worklet-processor' is not defined in AudioWorkletGlobalScope.
                this.player.debug.error('AudioContext', 'initWorkletScriptNode error', e);
                this.workletProcessorNode = null;
                this.tierDownToProcessScript();
            }

            if (!this.workletProcessorNode) {
                return;
            }

            this.workletProcessorNode.connect(this.gainNode);

            this.gainNode.connect(this.mediaStreamAudioDestinationNode);

            if (this.$audio) {
                this.$audio.srcObject = this.mediaStreamAudioDestinationNode.stream;
            } else {
                this.gainNode.connect(this.audioContext.destination);
            }
            this.hasInitScriptNode = true;
            this.workletProcessorNode.port.postMessage({
                message: "init",
                audioBufferSize: this.audioBufferSize,
                start: true,
                channels: this.audioInfo.channels
            });
            this.workletProcessorNode.port.onmessage = (e) => {
                // console.log('workletProcessorNode onmessage', e.data);
                if (this.workletProcessorNode) {
                    if (this.audioContext) {
                        this.handleScriptNodeCallback(this.workletProcessorNode, null, true)
                    } else {
                        this.workletProcessorNode.port.postMessage({message: "zero"});
                    }
                } else {
                    this.player.debug.error('AudioContext', 'workletProcessorNode is null');
                }
            }
        });
        this.clearWorkletUrlTimeout = setTimeout(() => {
            URL.revokeObjectURL(this.workletUrl);
            this.workletUrl = null;
            this.clearWorkletUrlTimeout = null;
        }, URL_OBJECT_CLEAR_TIME)
    }


    tierDownToProcessScript() {
        this.player.debug.log('AudioContext', 'tierDownToProcessScript');
        this.engineType = AUDIO_ENGINE_TYPE.script;
        this.audioBufferSize = this.getAudioBufferSizeByType();
        this.initProcessScriptNode();
        this.audioElementPlay();
    }

    handleScriptNodeCallback(outputBuffer, cb, isWorklet = false) {
        cb = cb || noop;
        let workletProcessorNode
        let outputBufferLength = outputBuffer.length;
        if (isWorklet) {
            workletProcessorNode = outputBuffer;
            outputBufferLength = this.audioBufferSize;
        }
        const channels = this.audioInfo.channels;
        if (this.bufferList.length && this.playing) {
            const opt = this.player._opt;
            //  窗口隐藏，不触发音视频同步逻辑。
            if (this.player.openSyncAudioAndVideo() &&
                isTrue(this.player.visibility)) {
                this.calcPlaybackRateBySync();

                const diff = this.player.getAudioSyncVideoDiff();
                // audio > video then wait video
                if (diff > this.player._opt.syncAudioAndVideoDiff) {
                    this.player.debug.warn('AudioContext', `audioSyncVideoOption more than diff :${diff}, waiting and bufferList is ${this.bufferList.length}`)
                    // empty audio
                    if (isWorklet) {
                        workletProcessorNode.port.postMessage({message: "zero"});
                    } else {
                        this.fillScriptNodeOutputBuffer(outputBuffer, channels);
                    }
                    cb();
                    return;
                }
            }

            let bufferItem = this._provide(outputBufferLength);

            if (bufferItem.size === 0) {
                // this.player.debug.warn('AudioContext', `bufferList size  is ${this.bufferList.length} outputBufferLength is ${outputBufferLength},and bufferItem.size is 0`)
                this.player.debug.warn('AudioContext', `bufferList size is ${this.bufferList.length} outputBufferLength is ${outputBufferLength},and bufferItem.size is 0`)
                // empty audio
                if (isWorklet) {
                    workletProcessorNode.port.postMessage({message: "zero"});
                } else {
                    this.fillScriptNodeOutputBuffer(outputBuffer, channels);
                }
                cb();
                return;
            }
            // update audio time stamp
            if (bufferItem && bufferItem.ts) {
                this.player.audioTimestamp = bufferItem.ts;
            }
            if (isWorklet) {
                workletProcessorNode.port.postMessage({message: "data", buffer: bufferItem});
            } else {
                this.fillScriptNodeOutputBuffer(outputBuffer, channels, bufferItem);
            }
            cb();
        } else {
            if (this.bufferList.length === 0 &&
                this.playing &&
                isFalse(this.isMute)) {
                this.player.debug.warn('AudioContext', `bufferList size is 0 and outputBufferLength is ${outputBufferLength}`)
            }

            if (isWorklet) {
                workletProcessorNode.port.postMessage({message: "zero"});
            } else {
                this.fillScriptNodeOutputBuffer(outputBuffer, channels);
            }
            cb();
        }
    }

    fillScriptNodeOutputBuffer(outputBuffer, channels, bufferItem) {
        if (channels === 1) {
            const leftBuffer = outputBuffer.getChannelData(0);
            if (bufferItem) {
                if (bufferItem.size === 0) {
                    leftBuffer.fill(0);
                } else {
                    leftBuffer.set(bufferItem.left);
                }
            } else {
                leftBuffer.fill(0);
            }
        } else if (channels === 2) {
            const leftBuffer = outputBuffer.getChannelData(0);
            const rightBuffer = outputBuffer.getChannelData(1);
            if (bufferItem) {
                if (bufferItem.size === 0) {
                    leftBuffer.fill(0);
                    rightBuffer.fill(0);
                } else {
                    leftBuffer.set(bufferItem.left);
                    rightBuffer.set(bufferItem.right);
                }
            } else {
                leftBuffer.fill(0);
                rightBuffer.fill(0);
            }
        }
    }

    //
    play(buffer, ts) {
        // if is mute
        if (this.isMute) {
            return;
        }

        if (!this.hasInitScriptNode) {
            this.player.debug.warn('AudioContext', 'play has not init script node');
            return;
        }


        this.hasAudio = true;

        this.player.latestAudioTimestamp = ts;

        this.bufferList.push({
            buffer,
            ts
        });

        // player audio
        if (isFalse(this.player.openSyncAudioAndVideo())) {
            this.calcPlaybackRateByBuffer();
        }
    }

    //  音视频同步
    calcPlaybackRateBySync() {
        if (this.isMute) {
            return;
        }

        if (!this.playing) {
            return;
        }

        const audioMaxVideoDiff = AIDIO_MAX_VIDEO_DIFF;
        const dropMaxSize = Math.floor((audioMaxVideoDiff) / this.oneBufferDuration);
        //  more than max size and drop
        if (this.bufferList.length > dropMaxSize) {
            this.player.debug.warn('AudioContext', `bufferList length ${this.bufferList.length} more than ${dropMaxSize}, and drop`)
            this.clear();
            return;
        }
        const diff = this.player.getAudioSyncVideoDiff();
        if (this.getEngineType() === AUDIO_ENGINE_TYPE.active) {
            //  audio less than video (syncAudioAndVideoDiff) and
            if (diff < -this.player._opt.syncAudioAndVideoDiff) {
                this.player.debug.warn('AudioContext', `engine active , audioSyncVideoOption ${-this.player._opt.syncAudioAndVideoDiff} less than diff :${diff},
                 and bufferlist is ${this.bufferList.length}`)
                const currentVideoTimestamp = this.player.getRenderCurrentPts();
                while (this.bufferList.length > 0) {
                    const bufferItem = this.bufferList[0];
                    const diff = bufferItem.ts - currentVideoTimestamp;
                    if (diff > -this.player._opt.syncAudioAndVideoDiff / 2) {
                        this.player.audioTimestamp = bufferItem.ts;
                        this.player.debug.log('AudioContext', `engine active , audioSyncVideoOption
                        item.ts is ${bufferItem.ts} and currentVideoTimestamp is ${currentVideoTimestamp}, diff is ${diff} > -${this.player._opt.syncAudioAndVideoDiff / 2} and end`);
                        break;
                    }
                    this.bufferList.shift();
                    this.player.audioTimestamp = bufferItem.ts;
                }
            }
        } else {
            // audio 的速率小于 video
            // 持续丢掉音频数据，让音频数据赶上视频。
            let playbackRate = this.playbackRate;
            if (diff < -this.player._opt.syncAudioAndVideoDiff) {
                if (playbackRate === this.defaultPlaybackRate) {
                    this.player.debug.log('AudioContext', `audioSyncVideoOption ${-this.player._opt.syncAudioAndVideoDiff} less than diff :${diff},
                 speed up, playbackRate is ${playbackRate},
                 and bufferList is ${this.bufferList.length}`)
                    playbackRate = this.defaultPlaybackRate + 0.2;
                }
            } else if (diff > -this.player._opt.syncAudioAndVideoDiff / 2) {
                if (playbackRate !== this.defaultPlaybackRate) {
                    this.player.debug.log('AudioContext', `diff is ${diff} > -${this.player._opt.syncAudioAndVideoDiff / 2} and speed to 1`);
                    playbackRate = this.defaultPlaybackRate;
                }
            }
            this.updatePlaybackRate(playbackRate);
        }
    }

    //  缓冲区。
    calcPlaybackRateByBuffer() {
        if (this.isMute) {
            return;
        }
        if (!this.playing) {
            return;
        }


        let playbackRate = this.playbackRate;
        let audioSyncVideoDiff = 1 * 1000; // 1s
        let audioMaxVideoDiff = 5 * 1000; // 5s
        if (this.isAudioPlayer) {
            audioSyncVideoDiff = this.player._opt.videoBufferDelay;
            audioMaxVideoDiff = this.player._opt.videoBufferMax;
        }
        const maxSize = Math.floor((audioSyncVideoDiff) / this.oneBufferDuration);
        const dropMaxSize = Math.floor((audioMaxVideoDiff) / this.oneBufferDuration);

        if (this.bufferList.length > dropMaxSize) {
            this.player.debug.warn('AudioContext', `bufferList length ${this.bufferList.length} more than ${dropMaxSize}, and drop`)
            this.clear();
            return;
        }

        //  active 没法实现倍率播放。
        if (this.getEngineType() === AUDIO_ENGINE_TYPE.active) {
            return;
        }

        // out of memory
        if (this.bufferList.length > maxSize) {
            playbackRate = this.defaultPlaybackRate + 0.2;
            this.player.debug.log('AudioContext', `bufferList length ${this.bufferList.length} more than ${maxSize}, speed up, playbackRate is ${playbackRate}`)
        } else if (this.bufferList.length < maxSize / 2) {
            playbackRate = this.defaultPlaybackRate;
        }
        this.updatePlaybackRate(playbackRate)
    }

    updatePlaybackRate(playbackRate) {
        if (this.rateProcessor) {
            this.playbackRate = playbackRate;
            this.rateProcessor.setRate(this.playbackRate);
        }
    }

    _provide(size) {
        let provider = this.playbackRate === 1 ? this.processor : this.rateProcessor;
        let audioData = provider.provide(size);
        return audioData;
    }
}
