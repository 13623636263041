import {clamp, isMobile} from "../utils";

export function emptyDom(element) {
    while (element.firstElementChild) {
        element.firstElementChild.remove();
    }
}

export function secondToTime(second) {
    const add0 = (num) => (num < 10 ? `0${num}` : String(num));
    const hour = Math.floor(second / 3600);
    const min = Math.floor((second - hour * 3600) / 60);
    const sec = Math.floor(second - hour * 3600 - min * 60);
    return (hour > 0 ? [hour, min, sec] : [min, sec]).map(add0).join(':');
}


export function getPosFromEvent(control, duration, event) {
    const $playbackProgress = control.$playbackProgress;
    const {left} = $playbackProgress.getBoundingClientRect();
    const eventLeft = isMobile() ? event.touches[0].clientX : event.pageX;
    const width = clamp(eventLeft - left, 0, $playbackProgress.clientWidth);
    const second = parseInt((width / $playbackProgress.clientWidth) * duration, 10);
    const time = secondToTime(second);
    const percentage = clamp(width / $playbackProgress.clientWidth, 0, 1);
    return {second, time, width, percentage};
}


export function getPercentage(second, duration) {
    return clamp(second / duration, 0, 1);
}
