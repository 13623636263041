import Emitter from "../utils/emitter";
import {EVENTS, RENDER_TYPE} from "../constant";
import {getMousePosition, getTarget, toNumber, isMobile, isFalse} from "../utils";

export default class Zoom extends Emitter {
    constructor(player) {
        super();
        this.player = player
        this.TAG_NAME = 'zoom';
        this.bindEvents = [];
        this.isDragging = false;
        this.currentZoom = 1;
        this.prevVideoElementStyleTransform = null;
        this.prevVideoElementStyleScale = null;
        this.maxScale = 5;
        this.tempPosition = {
            x: 0,
            y: 0
        }
        this.videoPosition = {
            left: 0,
            top: 0
        }

        const {
            events: {proxy},
            debug
        } = this.player;

        this.player.on(EVENTS.zooming, (isZooming) => {
            if (isZooming) {
                this.player.$container.classList.add('jessibuca-zoom-control')
                this._bindEvents();
                const styleTransform = this.player.video.$videoElement.style.transform;
                let left = this.player.video.$videoElement.style.left;
                let top = this.player.video.$videoElement.style.top;
                left = parseFloat(left)
                top = parseFloat(top)
                if (left) {
                    this.videoPosition.left = left;
                }
                if (top) {
                    this.videoPosition.top = top;
                }
                this.prevVideoElementStyleTransform = styleTransform;
                let scaleStyleMatch = styleTransform.match(/scale\([0-9., ]*\)/g);
                if (scaleStyleMatch && scaleStyleMatch[0]) {
                    let scaleStyle = scaleStyleMatch[0].replace('scale(', '').replace(')', '')
                    this.prevVideoElementStyleScale = scaleStyle.split(',');
                }
            } else {
                this.player.$container.classList.remove('jessibuca-zoom-control')
                this._unbindEvents();
                this._resetVideoPosition();
                this.player.$container.style.cursor = 'auto';
                let prevVideoElementStyleTransform = this.prevVideoElementStyleTransform;
                this.player.video.$videoElement.style.transform = prevVideoElementStyleTransform;
                this.prevVideoElementStyleTransform = null;
                this.prevVideoElementStyleScale = null;

                if (isMobile() && this.player._opt.useWebFullScreen) {
                    this.player.resize();
                }
            }
        })

        const mouseUpDestroy = proxy(window, isMobile() ? 'touchend' : 'mouseup', (event) => {
            this.handleMouseUp(event);
        })

        this.bindEvents.push(mouseUpDestroy);

        player.debug.log('zoom', 'init')
    }

    destroy() {
        this.bindEvents = [];
        this.isDragging = false;
        this.currentZoom = 1;
        this.prevVideoElementStyleTransform = null;
        this.prevVideoElementStyleScale = null;
        this.tempPosition = {
            x: 0,
            y: 0
        }
        this.videoPosition = {
            left: 0,
            top: 0
        }

        this.off();
        this.player.debug.log('zoom', 'destroy')
    }

    _bindEvents() {
        const {
            events: {proxy},
            debug
        } = this.player;

        const mouseMoveDestroy = proxy(this.player.$container, isMobile() ? 'touchmove' : 'mousemove', (e) => {
            this.handleMouseMove(e)
        })
        this.bindEvents.push(mouseMoveDestroy)
        const mouseDownDestroy = proxy(this.player.$container, isMobile() ? 'touchstart' : 'mousedown', (e) => {
            this.handleMouseDown(e);
        })
        this.bindEvents.push(mouseDownDestroy);
        const mouseUpDestroy = proxy(window, isMobile() ? 'touchend' : 'mouseup', (event) => {
            this.handleMouseUp(event);
        })
        this.bindEvents.push(mouseUpDestroy);
    }

    _unbindEvents() {
        this.bindEvents.forEach((fn) => {
            fn && fn();
        })
    }

    handleMouseMove(event) {
        event.stopPropagation();
        if (this.isDragging && this.player.zooming) {
            // preventDefault to prevent the video from being dragged
            event.preventDefault();
            const {posX, posY} = getMousePosition(event);
            const tempX = this.tempPosition.x - posX;
            const tempY = this.tempPosition.y - posY;
            this.videoPosition.left = this.videoPosition.left - tempX;
            this.videoPosition.top = this.videoPosition.top - tempY;
            this.tempPosition.x = posX;
            this.tempPosition.y = posY;
            this.updateVideoPosition();
        }
    }

    handleMouseDown(event) {
        event.stopPropagation();
        const target = getTarget(event);
        if (!this.player.zooming) {
            return;
        }
        if (target.matches('video') || target.matches('canvas')) {
            //  preventDefault to prevent the video from being dragged
            event.preventDefault();
            const {posX, posY} = getMousePosition(event);
            this.player.$container.style.cursor = 'grabbing';
            this.tempPosition.x = posX;
            this.tempPosition.y = posY;
            this.isDragging = true;
            this.player.debug.log('zoom', 'handleMouseDown is dragging true')
        }
    }

    handleMouseUp(event) {
        event.stopPropagation();
        if (this.isDragging && this.player.zooming) {
            // preventDefault to prevent the video from being dragged
            event.preventDefault();
            this.tempPosition = {
                x: 0,
                y: 0
            }
            this.isDragging = false;
            this.player.$container.style.cursor = 'grab';
            this.player.debug.log('zoom', 'handleMouseUp is dragging false')
        }
    }

    //
    updateVideoPosition() {
        const $videoElement = this.player.video.$videoElement;
        $videoElement.style.left = this.videoPosition.left + 'px';
        $videoElement.style.top = this.videoPosition.top + 'px';
    }

    //
    _resetVideoPosition() {
        // const $videoElement = this.player.video.$videoElement;
        // $videoElement.style.left = 0 + 'px';
        // $videoElement.style.top = 0 + 'px';
        this.player.resize();
        this.tempPosition = {
            x: 0,
            y: 0
        }
        this.videoPosition = {
            left: 0,
            top: 0
        }
        this.currentZoom = 1;
        this.isDragging = false;
    }

    // narrow
    narrowPrecision() {
        if (this.currentZoom <= 1) {
            return;
        }
        this.currentZoom -= 1;
        this.updateVideoElementScale();
    }

    // expand
    expandPrecision() {
        if (this.currentZoom >= this.maxScale) {
            return;
        }
        this.currentZoom += 1;
        this.updateVideoElementScale();

    }

    updatePrevVideoElementStyleScale(value) {
        this.prevVideoElementStyleScale = value;
    }

    updateVideoElementScale() {
        const $videoElement = this.player.video.$videoElement;
        let styleTransform = $videoElement.style.transform;
        let scaleX = 1;
        let scaleY = 1;
        if (this.prevVideoElementStyleScale) {
            const x = this.prevVideoElementStyleScale[0];
            if (x !== undefined) {
                scaleX = x
                scaleY = x
            }
            const y = this.prevVideoElementStyleScale[1];
            if (y !== undefined) {
                scaleY = y;
            }
        }

        scaleY = toNumber(scaleY);
        scaleX = toNumber(scaleX);
        const endScaleX = (0.5 * scaleX) * (this.currentZoom - 1) + scaleX;
        const endScaleY = (0.5 * scaleY) * (this.currentZoom - 1) + scaleY;
        let endStyleTransform
        if (styleTransform.indexOf('scale(') === -1) {
            endStyleTransform = styleTransform + ` scale(${endScaleX},${endScaleY})`
        } else {
            endStyleTransform = styleTransform.replace(/scale\([0-9., ]*\)/, `scale(${endScaleX},${endScaleY})`);
        }
        this.player.debug.log('zoom', `updateVideoElementScale end is ${endScaleX}, ${endScaleY} style is ${endStyleTransform}`)

        $videoElement.style.transform = endStyleTransform;
    }

}
