import {AUDIO_ENC_CODE, AVC_PACKET_TYPE} from "../constant";

/**
 *
 * @param arrayItem
 * @returns {Uint8Array}
 */
export function aacEncoderConfigurationRecord(arrayItem) {
    // 需要取出7个字节的ADTS头。
    const accADTSHeader = arrayItem.slice(0, 7);
    // 七个字节的adts头；
    //     aac 格式
    // 需要先发序列帧。然后再发后续的
    // 序列帧：asc 序列
    // 后续的帧，0 变成 1 就行了。
    // 0: Main profile
    // 1: Low Complexity profile(LC)
    // 2: Scalable Sampling Rate profile(SSR)
    const profile = ((accADTSHeader[2] & 0xc0) >> 6) + 1;
    // const profile = 0;
    // const sampleRate = (+audioInfo.sampleRate);
    const sampleRate = ((accADTSHeader[2] & 0x3C) >> 2)
    // const channel = (+audioInfo.channel);
    const channel = ((accADTSHeader[2] & 0x1) << 2) | ((accADTSHeader[3] & 0xc0) >> 6)
    const config1 = (profile << 3) | ((sampleRate & 0xe) >> 1)
    const config2 = ((sampleRate & 0x1) << 7) | (channel << 3)
    console.log('aacEncoderConfigurationRecord', `profile: ${profile}`, `sampleRate: ${sampleRate}`, `channel: ${channel}`)
    // 0xAF >> 4 === 10
    const temp = [0xAF, 0x00, config1, config2];
    const arrayBuffer = new Uint8Array(temp);

    return arrayBuffer;
}

/**
 * 生成aac的asc头
 * @param profile
 * @param sampleRate  这个是采样率的索引index, 不是采样率本身。
 * @param channel
 * @returns {Uint8Array}
 */
export function aacEncoderConfigurationRecordV2({profile, sampleRate, channel}) {
    const config1 = (profile << 3) | ((sampleRate & 0xe) >> 1)
    const config2 = ((sampleRate & 0x1) << 7) | (channel << 3)
    // 0xAF >> 4 === 10
    const temp = [0xAF, 0x00, config1, config2];
    const arrayBuffer = new Uint8Array(temp);

    return arrayBuffer;
}

/**
 *
 * @param arrayItem
 * @returns {Uint8Array}
 */
export function aacEncoderNalePacket(arrayItem) {
    arrayItem.unshift(0xAF, 0x01);
    const arrayBuffer = new Uint8Array(arrayItem);
    return arrayBuffer;
}

/**
 *
 * @param payload
 * @returns {boolean}
 */
export function isAacCodecPacket(payload) {
    return isAAC(payload) && payload[1] === AVC_PACKET_TYPE.sequenceHeader
}

export function isAAC(payload) {
    return (payload[0] >> 4) === AUDIO_ENC_CODE.AAC;
}

const FREQ = [
    96000,
    88200,
    64000,
    48000,
    44100,
    32000,
    24000,
    22050,
    16000,
    12000,
    11025,
    8000,
    7350
];


export const AAC_FREQ_LIST = FREQ;

export function getSilentFrame(codec, channelCount) {
    switch (codec) {
        case 'mp4a.40.2':
            if (channelCount === 1) {
                return new Uint8Array([0x00, 0xc8, 0x00, 0x80, 0x23, 0x80])
            }
            if (channelCount === 2) {
                return new Uint8Array([
                    0x21, 0x00, 0x49, 0x90, 0x02, 0x19, 0x00, 0x23, 0x80
                ])
            }
            if (channelCount === 3) {
                return new Uint8Array([
                    0x00, 0xc8, 0x00, 0x80, 0x20, 0x84, 0x01, 0x26, 0x40, 0x08, 0x64,
                    0x00, 0x8e
                ])
            }
            if (channelCount === 4) {
                return new Uint8Array([
                    0x00, 0xc8, 0x00, 0x80, 0x20, 0x84, 0x01, 0x26, 0x40, 0x08, 0x64,
                    0x00, 0x80, 0x2c, 0x80, 0x08, 0x02, 0x38
                ])
            }
            if (channelCount === 5) {
                return new Uint8Array([
                    0x00, 0xc8, 0x00, 0x80, 0x20, 0x84, 0x01, 0x26, 0x40, 0x08, 0x64,
                    0x00, 0x82, 0x30, 0x04, 0x99, 0x00, 0x21, 0x90, 0x02, 0x38
                ])
            }
            if (channelCount === 6) {
                return new Uint8Array([
                    0x00, 0xc8, 0x00, 0x80, 0x20, 0x84, 0x01, 0x26, 0x40, 0x08, 0x64,
                    0x00, 0x82, 0x30, 0x04, 0x99, 0x00, 0x21, 0x90, 0x02, 0x00, 0xb2,
                    0x00, 0x20, 0x08, 0xe0
                ])
            }
            break
        default:
            if (channelCount === 1) {
                return new Uint8Array([
                    0x1, 0x40, 0x22, 0x80, 0xa3, 0x4e, 0xe6, 0x80, 0xba, 0x8, 0x0, 0x0,
                    0x0, 0x1c, 0x6, 0xf1, 0xc1, 0xa, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5e
                ])
            }
            if (channelCount === 2) {
                return new Uint8Array([
                    0x1, 0x40, 0x22, 0x80, 0xa3, 0x5e, 0xe6, 0x80, 0xba, 0x8, 0x0, 0x0,
                    0x0, 0x0, 0x95, 0x0, 0x6, 0xf1, 0xa1, 0xa, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5e
                ])
            }
            if (channelCount === 3) {
                return new Uint8Array([
                    0x1, 0x40, 0x22, 0x80, 0xa3, 0x5e, 0xe6, 0x80, 0xba, 0x8, 0x0, 0x0,
                    0x0, 0x0, 0x95, 0x0, 0x6, 0xf1, 0xa1, 0xa, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a, 0x5a,
                    0x5a, 0x5e
                ])
            }
            break
    }
}

export function getFrameDuration(rate, timescale = 90000) {
    return 1024 * timescale / rate
}

export function parseADTS(data, pts) {
    const len = data.length
    let i = 0

    while ((i + 2) < len) {
        if (data[i] === 0xff && (data[i + 1] & 0xf6) === 0xf0) {
            break
        }
        i++
    }

    if (i >= len) return

    const skip = i
    const frames = []
    const samplingFrequencyIndex = (data[i + 2] & 0x3c) >>> 2
    const sampleRate = FREQ[samplingFrequencyIndex]
    if (!sampleRate) throw new Error(`Invalid sampling index: ${samplingFrequencyIndex}`)
    //  profile
    const objectType = ((data[i + 2] & 0xc0) >>> 6) + 1
    //  channel
    const channelCount = ((data[i + 2] & 1) << 2) | ((data[i + 3] & 0xc0) >>> 6)

    let protectionSkipBytes
    let frameLength
    let frameIndex = 0
    const duration = getFrameDuration(sampleRate)

    while ((i + 7) < len) {
        if ((data[i] !== 0xff) || (data[i + 1] & 0xF6) !== 0xf0) {
            i++
            continue
        }

        frameLength = ((data[i + 3] & 0x03) << 11) | (data[i + 4] << 3) | ((data[i + 5] & 0xe0) >> 5)
        if ((len - i) < frameLength) break

        protectionSkipBytes = (~data[i + 1] & 0x01) * 2
        frames.push({
            pts: pts + frameIndex * duration,
            data: data.subarray(i + 7 + protectionSkipBytes, i + frameLength),
        })

        frameIndex++
        i += frameLength
    }

    return {
        skip,
        remaining: i >= len ? undefined : data.subarray(i),
        frames,
        samplingFrequencyIndex,
        sampleRate,
        objectType,
        channelCount,
        originCodec: `mp4a.40.${objectType}`
    }
}


const _mpegSamplingRates = [
    96000, 88200, 64000, 48000, 44100, 32000,
    24000, 22050, 16000, 12000, 11025, 8000, 7350
];

export function parseAACAudioSpecificConfig(arrayBuffer) {
    let array = new Uint8Array(arrayBuffer);
    let config = null;

    /* Audio Object Type:
       0: Null
       1: AAC Main
       2: AAC LC
       3: AAC SSR (Scalable Sample Rate)
       4: AAC LTP (Long Term Prediction)
       5: HE-AAC / SBR (Spectral Band Replication)
       6: AAC Scalable
    */

    let audioObjectType = 0;
    let originalAudioObjectType = 0;
    let audioExtensionObjectType = null;
    let samplingIndex = 0;
    let extensionSamplingIndex = null;

    // 5 bits
    audioObjectType = originalAudioObjectType = array[0] >>> 3;
    // 4 bits
    samplingIndex = ((array[0] & 0x07) << 1) | (array[1] >>> 7);
    if (samplingIndex < 0 || samplingIndex >= _mpegSamplingRates.length) {
        console.error('Flv: AAC invalid sampling frequency index!')
        return;
    }

    let samplingFrequence = _mpegSamplingRates[samplingIndex];

    // 4 bits
    let channelConfig = (array[1] & 0x78) >>> 3;
    if (channelConfig < 0 || channelConfig >= 8) {
        console.log('Flv: AAC invalid channel configuration')
        return;
    }

    if (audioObjectType === 5) {  // HE-AAC?
        // 4 bits
        extensionSamplingIndex = ((array[1] & 0x07) << 1) | (array[2] >>> 7);
        // 5 bits
        audioExtensionObjectType = (array[2] & 0x7C) >>> 2;
    }

    // workarounds for various browsers
    let userAgent = self.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('firefox') !== -1) {
        // firefox: use SBR (HE-AAC) if freq less than 24kHz
        if (samplingIndex >= 6) {
            audioObjectType = 5;
            config = new Array(4);
            extensionSamplingIndex = samplingIndex - 3;
        } else {  // use LC-AAC
            audioObjectType = 2;
            config = new Array(2);
            extensionSamplingIndex = samplingIndex;
        }
    } else if (userAgent.indexOf('android') !== -1) {
        // android: always use LC-AAC
        audioObjectType = 2;
        config = new Array(2);
        extensionSamplingIndex = samplingIndex;
    } else {
        // for other browsers, e.g. chrome...
        // Always use HE-AAC to make it easier to switch aac codec profile
        audioObjectType = 5;
        extensionSamplingIndex = samplingIndex;
        config = new Array(4);

        if (samplingIndex >= 6) {
            extensionSamplingIndex = samplingIndex - 3;
        } else if (channelConfig === 1) {  // Mono channel
            audioObjectType = 2;
            config = new Array(2);
            extensionSamplingIndex = samplingIndex;
        }
    }

    config[0] = audioObjectType << 3;
    config[0] |= (samplingIndex & 0x0F) >>> 1;
    config[1] = (samplingIndex & 0x0F) << 7;
    config[1] |= (channelConfig & 0x0F) << 3;
    if (audioObjectType === 5) {
        config[1] |= ((extensionSamplingIndex & 0x0F) >>> 1);
        config[2] = (extensionSamplingIndex & 0x01) << 7;
        // extended audio object type: force to 2 (LC-AAC)
        config[2] |= (2 << 2);
        config[3] = 0;
    }

    return {
        audioType: 'aac',
        config: config,
        sampleRate: samplingFrequence,
        channelCount: channelConfig,
        objectType: audioObjectType,
        codec: 'mp4a.40.' + audioObjectType,
        originalCodec: 'mp4a.40.' + originalAudioObjectType
    };
}

class Bitop {

    constructor(buffer) {
        this.buffer = buffer;
        this.buflen = buffer.length;
        this.bufpos = 0;
        this.bufoff = 0;
        this.iserro = false;
    }

    read(n) {
        let v = 0;
        let d = 0;
        while (n) {
            if (n < 0 || this.bufpos >= this.buflen) {
                this.iserro = true;
                return 0;
            }

            this.iserro = false;
            d = this.bufoff + n > 8 ? 8 - this.bufoff : n;

            v <<= d;
            v += (this.buffer[this.bufpos] >> (8 - this.bufoff - d)) & (0xff >> (8 - d));

            this.bufoff += d;
            n -= d;

            if (this.bufoff == 8) {
                this.bufpos++;
                this.bufoff = 0;
            }
        }
        return v;
    }

    look(n) {
        let p = this.bufpos;
        let o = this.bufoff;
        let v = this.read(n);
        this.bufpos = p;
        this.bufoff = o;
        return v;
    }

    read_golomb() {
        let n;
        for (n = 0; this.read(1) == 0 && !this.iserro; n++) ;
        return (1 << n) + this.read(n) - 1;
    }
}

function getObjectType(bitop) {
    let audioObjectType = bitop.read(5);
    if (audioObjectType === 31) {
        audioObjectType = bitop.read(6) + 32;
    }
    return audioObjectType;
}

function getSampleRate(bitop, info) {
    info.sampling_index = bitop.read(4);
    return info.sampling_index == 0x0f ? bitop.read(24) : AAC_SAMPLE_RATE[info.sampling_index];
}

const AAC_SAMPLE_RATE = [
    96000, 88200, 64000, 48000,
    44100, 32000, 24000, 22050,
    16000, 12000, 11025, 8000,
    7350, 0, 0, 0
];

const AAC_CHANNELS = [
    0, 1, 2, 3, 4, 5, 6, 8
];

export function readAACSpecificConfig(aacSequenceHeader) {
    let info = {};
    let bitop = new Bitop(aacSequenceHeader);
    bitop.read(16);
    info.object_type = getObjectType(bitop);
    info.sample_rate = getSampleRate(bitop, info);
    info.chan_config = bitop.read(4);
    if (info.chan_config < AAC_CHANNELS.length) {
        info.channels = AAC_CHANNELS[info.chan_config];
    }
    info.sbr = -1;
    info.ps = -1;
    if (info.object_type == 5 || info.object_type == 29) {
        if (info.object_type == 29) {
            info.ps = 1;
        }
        info.ext_object_type = 5;
        info.sbr = 1;
        info.sample_rate = getSampleRate(bitop, info);
        info.object_type = getObjectType(bitop);
    }

    return {
        ...info,
        channelCount: info.channels,
        sampleRate: info.sample_rate,
    };
}
