import Emitter from "../utils/emitter";
import {calculationRate, function2String, isChrome, now} from "../utils";
import {EVENTS, EVENTS_ERROR, PLAYER_STREAM_TYPE, URL_OBJECT_CLEAR_TIME, WORKER_FETCH_CMD_TYPE} from "../constant";

export default class FetchWorkerLoader extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'FetchWorkerLoader'
        this.player = player;
        this.playing = false;
        this.fetchWorker = null;
        this.workerClearTimeout = null;
        this.workerUrl = null;
        this.abortController = new AbortController();
        //
        this.streamRate = calculationRate(rate => {
            player.emit(EVENTS.kBps, (rate / 1024).toFixed(2));
        });
        this.streamRateInterval = null;
        this._initFetchWorker();
        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        this.off();
        // todo:
        if (this.workerUrl) {
            window.URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
        }

        if (this.workerClearTimeout) {
            clearTimeout(this.workerClearTimeout);
            this.workerClearTimeout = null;
        }
        if (this.fetchWorker) {
            this.fetchWorker.postMessage({cmd: WORKER_FETCH_CMD_TYPE.destroy});
            this.fetchWorker.terminate();
            this.fetchWorker = null;
        }
        this._stopStreamRateInterval();
        this.streamRate = null;
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    _initFetchWorker() {
        // fetch in worker
        //  worker fun
        function FetchWorker() {

            function isTrue(value) {
                return value === true || value === 'true';
            }

            function isFalse(value) {
                return value === false || value === 'false';
            }

            const FETCH_ERROR = {
                abortError: 'The user aborted a request',
                abortError2: 'AbortError',
                abort: 'AbortError'
            }

            const WORKER_FETCH_CMD_TYPE = {
                fetch: 'fetch',
                destroy: 'destroy',
                buffer: 'buffer',
                fetchError: 'fetchError',
                fetchClose: 'fetchClose',
                fetchSuccess: 'fetchSuccess',
            }

            const LOADER_STATUS = {
                idle: 'idle', //
                connecting: 'connecting', //
                buffering: 'buffering', //
                error: 'error', //
                complete: 'complete', //
            }


            function isFetchSuccess(res) {
                return (res.ok && (res.status >= 200 && res.status <= 299))
            }

            function supportWritableStream() {
                return typeof WritableStream !== 'undefined';
            }


            class FetchLoader {
                constructor() {
                    this._requestAbort = false;
                    this._status = LOADER_STATUS.idle;
                    this.writableStream = null;
                    this.isChrome = false;
                    this.abortController = new AbortController();
                }

                destroy() {
                    this.abort()
                    if (this.writableStream &&
                        isFalse(this.writableStream.locked)) {
                        this.writableStream.close().catch((e) => {
                            // ignore
                            // The stream you are trying to close is locked.
                        });
                    }
                    this.writableStream = null;
                    this._status = LOADER_STATUS.idle;
                }

                /**
                 *
                 * @param url
                 * @param options
                 */
                fetchStream(url, options = {}) {
                    const fetchOptions = Object.assign({
                        signal: this.abortController.signal,
                    }, {
                        headers: options.headers || {}
                    });
                    fetch(url, fetchOptions).then((res) => {
                        if (this._requestAbort) {
                            this._status = LOADER_STATUS.idle;
                            res.body.cancel();
                            return;
                        }

                        if (!isFetchSuccess(res)) {
                            this.abort();
                            // / 这边会报用户 aborted a request 错误。
                            postMessage({
                                cmd: WORKER_FETCH_CMD_TYPE.fetchError,
                                message: `fetch response status is ${res.status} and ok is ${res.ok}`
                            })
                            return;
                        }

                        postMessage({
                            cmd: WORKER_FETCH_CMD_TYPE.fetchSuccess,
                        })

                        if (supportWritableStream()) {
                            this.writableStream = new WritableStream({
                                write: (value) => {
                                    if (this.abortController &&
                                        this.abortController.signal &&
                                        this.abortController.signal.aborted) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }
                                    if (isTrue(this._requestAbort)) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }
                                    this._status = LOADER_STATUS.buffering;

                                    postMessage({
                                        cmd: WORKER_FETCH_CMD_TYPE.buffer,
                                        buffer: value
                                    }, [value.buffer]);
                                    return;
                                },
                                close: () => {
                                    this._status = LOADER_STATUS.complete;

                                    postMessage({
                                        cmd: WORKER_FETCH_CMD_TYPE.fetchClose
                                    })

                                },
                                abort: (e) => {
                                    if (this.abortController &&
                                        this.abortController.signal &&
                                        this.abortController.signal.aborted) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }

                                    const errorString = e.toString();
                                    // aborted a request 。
                                    if (errorString.indexOf(FETCH_ERROR.abortError) !== -1) {
                                        return
                                    }
                                    if (errorString.indexOf(FETCH_ERROR.abortError2) !== -1) {
                                        return;
                                    }

                                    if (e.name === FETCH_ERROR.abort) {
                                        return;
                                    }
                                    this.abort();
                                    // / 这边会报用户 aborted a request 错误。
                                    postMessage({
                                        cmd: WORKER_FETCH_CMD_TYPE.fetchError,
                                        message: e.toString()
                                    })
                                }
                            })
                            res.body.pipeTo(this.writableStream);
                        } else {
                            const reader = res.body.getReader();
                            const fetchNext = () => {
                                reader.read().then(({done, value}) => {
                                    if (done) {
                                        this._status = LOADER_STATUS.complete;
                                        postMessage({
                                            cmd: WORKER_FETCH_CMD_TYPE.fetchClose
                                        })
                                        return;
                                    }
                                    if (this.abortController &&
                                        this.abortController.signal &&
                                        this.abortController.signal.aborted) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }

                                    if (isTrue(this._requestAbort)) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }

                                    this._status = LOADER_STATUS.buffering;

                                    postMessage({
                                        cmd: WORKER_FETCH_CMD_TYPE.buffer,
                                        buffer: value
                                    }, [value.buffer]);
                                    fetchNext();
                                }).catch((e) => {

                                    if (this.abortController &&
                                        this.abortController.signal &&
                                        this.abortController.signal.aborted) {
                                        this._status = LOADER_STATUS.complete;
                                        return;
                                    }

                                    const errorString = e.toString();
                                    // aborted a request 。
                                    if (errorString.indexOf(FETCH_ERROR.abortError) !== -1) {
                                        return
                                    }
                                    if (errorString.indexOf(FETCH_ERROR.abortError2) !== -1) {
                                        return;
                                    }

                                    if (e.name === FETCH_ERROR.abort) {
                                        return;
                                    }
                                    this.abort();
                                    // / 这边会报用户 aborted a request 错误。
                                    postMessage({
                                        cmd: WORKER_FETCH_CMD_TYPE.fetchError,
                                        message: e.toString()
                                    })
                                })

                            }
                            fetchNext();
                        }


                    }).catch((e) => {
                        if (this.abortController &&
                            this.abortController.signal &&
                            this.abortController.signal.aborted) {
                            return;
                        }

                        if (e.name === 'AbortError') {
                            return;
                        }
                        this.abort();
                        postMessage({
                            cmd: WORKER_FETCH_CMD_TYPE.fetchError,
                            message: e.toString()
                        })
                    })
                }

                abort() {
                    this._requestAbort = true;

                    if (this._status !== LOADER_STATUS.buffering ||
                        isFalse(fetchLeader.isChrome)) {
                        if (this.abortController) {
                            try {
                                this.abortController.abort();
                            } catch (e) {

                            }
                            this.abortController = null
                        }
                    } else {
                        this.abortController = null;
                    }
                }
            }

            let fetchLeader = new FetchLoader();
            self.onmessage = (e) => {
                const msg = e.data;
                switch (msg.cmd) {
                    case WORKER_FETCH_CMD_TYPE.fetch:
                        fetchLeader.isChrome = isTrue(msg.isChrome);
                        fetchLeader.fetchStream(msg.url, JSON.parse(msg.options));
                        break;
                    case WORKER_FETCH_CMD_TYPE.destroy:
                        fetchLeader.destroy();
                        fetchLeader = null;
                        break;
                    default:
                        break;
                }
            }
        }

        const FetchWorkerString = function2String(FetchWorker.toString());
        const blob = new Blob([FetchWorkerString], {type: "text/javascript"});
        let workerUrl = URL.createObjectURL(blob);
        const fetchWorker = new Worker(workerUrl);
        this.workerUrl = workerUrl;

        // 必须要释放，不然每次调用内存都明显泄露内存
        // chrome 83 file协议下如果直接释放，将会使WebWorker无法启动
        this.workerClearTimeout = setTimeout(() => {
            window.URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
            this.workerClearTimeout = null;
        }, URL_OBJECT_CLEAR_TIME)


        fetchWorker.onmessage = (event) => {
            const {demux} = this.player;
            const msg = event.data;
            switch (msg.cmd) {
                case WORKER_FETCH_CMD_TYPE.buffer:
                    this.streamRate && this.streamRate(msg.buffer.byteLength);
                    demux.dispatch(msg.buffer);
                    break;
                case WORKER_FETCH_CMD_TYPE.fetchSuccess:
                    this.emit(EVENTS.streamSuccess);
                    this._startStreamRateInterval();
                    break;
                case WORKER_FETCH_CMD_TYPE.fetchClose:
                    demux.close();
                    this.emit(EVENTS.streamEnd);
                    break;
                case WORKER_FETCH_CMD_TYPE.fetchError:
                    demux.close();
                    // / 这边会报用户 aborted a request 错误。
                    this.emit(EVENTS_ERROR.fetchError, msg.message);
                    break;
                default:
                    break;
            }
        }

        this.fetchWorker = fetchWorker;
    }


    _startStreamRateInterval() {
        this._stopStreamRateInterval();
        this.streamRateInterval = setInterval(() => {
            this.streamRate && this.streamRate(0)
        }, 1000)
    }

    _stopStreamRateInterval() {
        if (this.streamRateInterval) {
            clearInterval(this.streamRateInterval);
            this.streamRateInterval = null;
        }
    }

    fetchStream(url, options = {}) {
        this.player._times.streamStart = now();
        this.fetchWorker.postMessage({
            cmd: WORKER_FETCH_CMD_TYPE.fetch,
            url,
            isChrome: isChrome(),
            options: JSON.stringify(options),
        })
    }

    getStreamType() {
        return PLAYER_STREAM_TYPE.fetch;
    }
}
