import Emitter from "../utils/emitter";
import Hls from "../hls"
import {EVENTS, EVENTS_ERROR, HLS_EVENTS, RENDER_TYPE} from "../constant";

export default class HlsDecoder extends Emitter {
    TAG_NAME = 'Hls256Decoder'

    constructor(player) {
        super();
        this.player = player;
        this.$videoElement = this.player.video.$videoElement;
        this.hls = null;
        this.eventsDestroy = [];
        this.bandwidthEstimateInterval = null;

        //
        this.hls = new Hls(player);
        this._bindEvents();
    }

    async destroy() {
        this._stopBandwidthEstimateInterval();
        if (this.hls) {
            await this.hls.destroy();
            this.hls = null;
        }
        if (this.eventsDestroy.length) {
            this.eventsDestroy.forEach(event => event());
            this.eventsDestroy = [];
        }

        this.$videoElement = null;
        this.player.debug.log(this.TAG_NAME, 'destroy');
        return true;
    }

    _bindEvents() {
        this.hls.on(HLS_EVENTS.ERROR, (e) => {
            this.player.emitError(EVENTS_ERROR.hlsError, e)
        })

        this._startBandwidthEstimateInterval();
    }

    _startBandwidthEstimateInterval() {
        this._stopBandwidthEstimateInterval();
        this.bandwidthEstimateInterval = setInterval(() => {
            const speedInfo = this.hls.speedInfo();
            // avgSpeed is bit/s  要转换成 kbps/s
            //  感觉计算的有问题
            this.player.emit(EVENTS.kBps, (speedInfo.avgSpeed / 1024 / 8).toFixed(2));
            this.hls.resetBandwidth();
        }, 1 * 1000);
    }

    _stopBandwidthEstimateInterval() {
        if (this.bandwidthEstimateInterval) {
            clearInterval(this.bandwidthEstimateInterval);
            this.bandwidthEstimateInterval = null;
        }
    }


    async loadSource(url) {
        this.player.debug.log(this.TAG_NAME, `loadSource() ${url}`);
        this.url = url;
        await this.hls.loadSource(url);
        return true;
    }

    checkHlsBufferedDelay() {
        let result = 0;
        if (this.hls) {
            result = this.hls.getDemuxBuferredDuration();
        }

        return result;
    }

    getDemuxBufferedListLength() {
        let result = 0;
        if (this.hls) {
            result = this.hls.getDemuxBufferedListLength();
        }

        return result;
    }

    getDemuxAudioBufferedListLength() {
        let result = 0;
        if (this.hls) {
            result = this.hls.getDemuxAudioBufferedListLength();
        }

        return result;
    }

    getDemuxVideoBufferedListLength() {
        let result = 0;
        if (this.hls) {
            result = this.hls.getDemuxVideoBufferedListLength();
        }

        return result;
    }
}
