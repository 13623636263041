import {clamp} from "../../utils";
import Stream from "./stream";
import {HLS_EVENTS} from "../../constant";

/**
 * playlist: m3u8
 */
export default class Playlist {


    constructor(hls) {
        this.hls = hls
        this.player = hls.player;
        /** @type {import('./stream').Stream[]} */
        this.streams = []

        /** @type {import('./stream').Stream} */
        this.currentStream = null
        // dvr window
        this.dvrWindow = 0

        this._segmentPointer = -1

        this.TAG_NAME = 'HlsPlaylist'
    }

    destroy() {
        this.reset();
    }


    get lastSegment() {
        return this.currentStream?.lastSegment
    }

    get currentSegment() {
        return this.currentSegments?.[this._segmentPointer]
    }

    get nextSegment() {
        return this.currentSegments?.[this._segmentPointer + 1]
    }

    get currentSegments() {
        return this.currentStream?.segments
    }

    get currentSubtitleEndSn() {
        return this.currentStream?.currentSubtitleEndSn
    }

    get liveEdge() {
        return this.currentStream?.liveEdge
    }

    get totalDuration() {
        return this.currentStream?.totalDuration || 0
    }

    get seekRange() {
        const segments = this.currentSegments
        if (!segments || !segments.length) return
        return [
            segments[0].start,
            segments[segments.length - 1].end
        ]
    }

    get isEmpty() {
        return !this.currentSegments?.length
    }

    get isLive() {
        return this.currentStream?.live
    }

    get hasSubtitle() {
        return !!this.currentStream?.currentSubtitleStream
    }

    getAudioSegment(seg) {
        return this.currentStream?.getAudioSegment(seg)
    }

    moveSegmentPointer(pos) {
        if (pos === null || pos === undefined) pos = this._segmentPointer + 1
        this._segmentPointer = clamp(pos, -1, this.currentSegments?.length)
        this.player.debug.log(this.TAG_NAME, 'moveSegmentPointer()', pos, this._segmentPointer)
    }

    reset() {
        this.streams = []
        this.currentStream = null
        this.dvrWindow = 0
        this._segmentPointer = -1
    }

    getSegmentByIndex(index) {
        return this.currentSegments?.[index]
    }

    setNextSegmentByIndex(index = 0) {
        this._segmentPointer = index - 1
        this.player.debug.log(this.TAG_NAME, 'setNextSegmentByIndex()', index, this._segmentPointer);
    }

    // fxzind segment index by time
    findSegmentIndexByTime(time) {

        const segments = this.currentSegments

        if (segments) {
            for (let i = 0, l = segments.length, seg; i < l; i++) {
                seg = segments[i]
                if (time >= seg.start && time < seg.end) {
                    return i
                }
            }

            const lastSegment = segments[segments.length - 1]

            if (Math.abs(time - lastSegment?.end) < 0.2) {
                return segments.length - 1
            }
        }
    }

    // upsert play list
    upsertPlaylist(playlist, audioPlaylist, subtitlePlaylist) {
        // this.player.debug.log(this.TAG_NAME, 'upsertPlaylist()', playlist, audioPlaylist, subtitlePlaylist);
        if (!playlist) {
            this.player.debug.warn(this.TAG_NAME, 'upsertPlaylist() playlist is null');
            return;
        }

        if (playlist.isMaster) {
            // streams
            this.streams.length = playlist.streams.length

            playlist.streams.filter(x => x.url).forEach((stream, i) => {
                if (this.streams[i]) {
                    this.streams[i].update(stream)
                } else {
                    this.streams[i] = new Stream(stream)
                }
            })

            this.currentStream = this.streams[0]
            // update media
        } else if (Array.isArray(playlist.segments)) {
            // current stream
            const stream = this.currentStream

            if (stream) {

                stream.update(playlist, audioPlaylist, subtitlePlaylist)

                const newSubtitleSegs = stream.updateSubtitle(subtitlePlaylist)

                if (newSubtitleSegs) {
                    this.hls.emit(HLS_EVENTS.SUBTITLE_SEGMENTS, {
                        list: newSubtitleSegs
                    })
                }
            } else {
                this.reset()

                this.currentStream = this.streams[0] = new Stream(playlist, audioPlaylist, subtitlePlaylist)
            }
        }

        const currentStream = this.currentStream

        if (currentStream) {
            //
            if (this.hls.isLive && !this.dvrWindow) {
                this.dvrWindow = this.currentSegments.reduce((a, c) => {
                    a += c.duration
                    return a
                }, 0)
            }
        }
    }

    switchSubtitle(lang) {
        this.currentStream?.switchSubtitle(lang)
    }

    // clear old segment
    clearOldSegment(maxPlaylistSize = 50) {
        const stream = this.currentStream

        if (!this.dvrWindow || !stream) return

        const startTime = stream.endTime - this.dvrWindow

        if (startTime <= 0) {
            this.player.debug.log(this.TAG_NAME, `clearOldSegment() stream.endTime:${stream.endTime}, this.dvrWindow:${this.dvrWindow}  startTime <= 0`);
            return;
        }

        const segments = stream.segments

        if (segments.length <= maxPlaylistSize) {
            this.player.debug.log(this.TAG_NAME, `clearOldSegment() segments.length:${segments.length} <= maxPlaylistSize:${maxPlaylistSize}`);
            return
        }

        const _oldSegmentPointer = this._segmentPointer
        this._segmentPointer = stream.clearOldSegment(startTime, _oldSegmentPointer)

        this.player.debug.log(this.TAG_NAME, 'clearOldSegment() update _segmentPointer:', _oldSegmentPointer, this._segmentPointer);
        this.player.debug.log(this.TAG_NAME, 'currentSegments', this.currentSegments);
    }

    checkSegmentTrackChange(cTime, nbSb) {
        const index = this.findSegmentIndexByTime(cTime)
        const seg = this.getSegmentByIndex(index)

        if (!seg) return

        if (!seg.hasAudio && !seg.hasVideo) return

        // when seek
        if (nbSb !== 2 && seg.hasAudio && seg.hasVideo) return seg

        // continuous play
        if (seg.end - cTime > 0.3) return

        const next = this.getSegmentByIndex(index + 1)

        if (!next) return

        if (!next.hasAudio && !next.hasVideo) return

        if ((next.hasAudio !== seg.hasAudio || next.hasVideo !== seg.hasVideo)) return next

    }

}
