import {formatWatermarkOptions, isMobile, isNotEmptyObject, isNumber} from "./index";
import {CONTROL_HEIGHT, CONTROL_PLAYBACK_HEIGHT, PLAY_TYPE, RENDER_TYPE} from "../constant";

export default class SingleWatermark {
    constructor(player) {
        this.player = player;
        this.TAG_NAME = "SingleWatermark";
        this.configList = [];
        this.shadowRoot = null;
        this.shadowRootRealDom = null;
        this.shadowRootInnerDom = null;
        this.scale = 1;
        this.isDynamic = false;
        this._initDom();
        this.player.debug.log(this.TAG_NAME, 'int');
    }

    destroy() {
        this.configList = [];
        this.shadowRootInnerDom = null;
        this.isDynamic = false;
        this.scale = 1;
        if (this.shadowRoot) {
            this.player.$container.removeChild(this.shadowRootRealDom);
            this.shadowRoot = null;
            this.shadowRootRealDom = null;
        }
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    resize() {
        this.player.debug.log(this.TAG_NAME, 'resize()');
        if (this.player._opt.aspectRatio === 'default' ||
            isMobile()) {
            if (this.player.getRenderType() === RENDER_TYPE.canvas) {
                this._resizeDomForCanvas();
            } else if (this.player.getRenderType() === RENDER_TYPE.video) {
                this._resizeDomForVideo();
            }
        } else {
            this._resizeDomRatio();
        }
    }

    _initDom() {
        const $container = this.player.$container;
        let shadowRoot = null;
        const otDiv = document.createElement('div');
        otDiv.setAttribute('style', 'pointer-events: none !important;display: block !important;');
        if (typeof otDiv.attachShadow === "function") {
            shadowRoot = otDiv.attachShadow({mode: 'open'});
        } else if (otDiv.shadowRoot) {
            shadowRoot = otDiv.shadowRoot;
        } else {
            shadowRoot = otDiv;
        }
        const innerDiv = document.createElement('div');
        innerDiv.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 0; height: 0;display: none;');
        shadowRoot.appendChild(innerDiv);

        const nodeList = $container.children;
        const index = Math.floor(Math.random() * (nodeList.length - 1)) + 1;
        if (nodeList[index]) {
            $container.insertBefore(otDiv, nodeList[index]);
        } else {
            $container.appendChild(otDiv);
        }
        this.shadowRootInnerDom = innerDiv;
        this.shadowRootRealDom = otDiv;
        this.shadowRoot = shadowRoot;
    }

    update(config) {
        this._removeDom();
        let watermarkConfigList = [];
        if (Array.isArray(config)) {
            watermarkConfigList = config;
        } else if (isNotEmptyObject(config)) {
            watermarkConfigList.push(config)
        }

        let defaultConfigList = watermarkConfigList.map((itemOptions) => {
            return formatWatermarkOptions(itemOptions);
        })
        this.configList = defaultConfigList;
        this._updateDom();
    }

    _resizeDomForVideo() {
        const playerWidth = this.player.width;
        const playerHeight = this.player.height;
        const videoInfo = this.player.getVideoInfo();

        if (!(videoInfo && videoInfo.height > 0 && videoInfo.width > 0)) {
            return;
        }

        let resizeWidth = videoInfo.width;
        let resizeHeight = videoInfo.height;
        const option = this.player._opt;
        let height = playerHeight;
        let width = playerWidth;
        if (option.hasControl && !option.controlAutoHide) {
            const controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT;
            if (isMobile() && this.player.fullscreen && option.useWebFullScreen) {
                width -= controlHeight;
            } else {
                height -= controlHeight;
            }
        }

        const rotate = option.rotate;
        let left = ((width - resizeWidth) / 2)
        let top = ((height - resizeHeight) / 2)
        if (rotate === 270 || rotate === 90) {
            resizeWidth = videoInfo.height;
            resizeHeight = videoInfo.width;
        }

        const wScale = width / resizeWidth;
        const hScale = height / resizeHeight;

        let scale = wScale > hScale ? hScale : wScale;

        //
        if (!option.isResize) {
            if (wScale !== hScale) {
                scale = wScale + ',' + hScale;
            }
        }
        //
        if (option.isFullResize) {
            scale = wScale > hScale ? wScale : hScale;
        }
        let transform = "scale(" + scale + ")";

        if (option.mirrorRotate === 'none') {
            if (rotate) {
                transform += ' rotate(' + rotate + 'deg)'
            }
        }

        if (option.mirrorRotate === 'level') {
            transform += ' rotateY(180deg)' // 水平镜像翻转
        } else if (option.mirrorRotate === 'vertical') {
            transform += ' rotateX(180deg)' // 垂直镜像翻转
        }

        this.scale = ('' + scale).indexOf(',') !== -1 ? wScale : scale;
        this.shadowRootInnerDom.style.transform = transform;
        this.shadowRootInnerDom.style.left = left + 'px';
        this.shadowRootInnerDom.style.top = top + 'px';
        this.shadowRootInnerDom.style.width = videoInfo.width + 'px';
        this.shadowRootInnerDom.style.height = videoInfo.height + 'px';
        this.shadowRootInnerDom.style.display = 'block';
    }


    _resizeDomForCanvas() {

        const videoInfo = this.player.getVideoInfo();

        if (!(videoInfo && videoInfo.height > 0 && videoInfo.width > 0)) {
            return;
        }

        const option = this.player._opt;
        let width = this.player.width;
        let height = this.player.height;
        if (option.hasControl && !option.controlAutoHide) {
            const controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT;
            if (isMobile() && this.player.fullscreen && option.useWebFullScreen) {
                width -= controlHeight;
            } else {
                height -= controlHeight;
            }
        }

        let resizeWidth = videoInfo.width;
        let resizeHeight = videoInfo.height;
        const rotate = option.rotate;
        let left = ((width - resizeWidth) / 2)
        let top = ((height - resizeHeight) / 2)
        if (rotate === 270 || rotate === 90) {
            resizeWidth = videoInfo.height;
            resizeHeight = videoInfo.width;
        }

        const wScale = width / resizeWidth;
        const hScale = height / resizeHeight;

        let scale = wScale > hScale ? hScale : wScale;
        //
        if (!option.isResize) {
            if (wScale !== hScale) {
                scale = wScale + ',' + hScale;
            }
        }
        //
        if (option.isFullResize) {
            scale = wScale > hScale ? wScale : hScale;
        }
        let transform = "scale(" + scale + ")";

        if (option.mirrorRotate === 'none') {
            if (rotate) {
                transform += ' rotate(' + rotate + 'deg)'
            }
        }

        if (option.mirrorRotate === 'level') {
            transform += ' rotateY(180deg)' // 水平镜像翻转
        } else if (option.mirrorRotate === 'vertical') {
            transform += ' rotateX(180deg)' // 垂直镜像翻转
        }
        this.shadowRootInnerDom.style.height = videoInfo.height + "px";
        this.shadowRootInnerDom.style.width = videoInfo.width + "px";
        this.shadowRootInnerDom.style.padding = "0";
        this.shadowRootInnerDom.style.transform = transform;
        this.shadowRootInnerDom.style.left = left + "px"
        this.shadowRootInnerDom.style.top = top + "px"
        this.shadowRootInnerDom.style.display = 'block';
    }

    _resizeDomRatio() {
        const videoInfo = this.player.getVideoInfo();
        if (!(videoInfo && videoInfo.height > 0 && videoInfo.width > 0)) {
            return;
        }

        const ratioArray = this.player._opt.aspectRatio.split(':').map(Number);
        let width = this.player.width;
        let height = this.player.height;
        const option = this.player._opt;
        let controlHeight = 0;
        if (option.hasControl && !option.controlAutoHide) {
            controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT
            height -= controlHeight;
        }
        const videoRatio = videoInfo.width / videoInfo.height;
        const setupRatio = ratioArray[0] / ratioArray[1];

        if (videoRatio > setupRatio) {
            const percentage = (setupRatio * videoInfo.height) / videoInfo.width;
            this.shadowRootInnerDom.style.width = `${percentage * 100}%`;
            this.shadowRootInnerDom.style.height = `calc(100% - ${controlHeight}px)`;
            this.shadowRootInnerDom.style.padding = `0 ${(width - width * percentage) / 2}px`;
        } else {
            const percentage = videoInfo.width / setupRatio / videoInfo.height;
            this.shadowRootInnerDom.style.width = '100%';
            this.shadowRootInnerDom.style.height = `calc(${percentage * 100}% - ${controlHeight}px)`;
            this.shadowRootInnerDom.style.padding = `${(height - height * percentage) / 2}px 0`;
        }
        this.shadowRootInnerDom.style.display = 'block';
    }

    _updateDom() {

        if (!this.shadowRoot) {
            return;
        }
        // foreach
        this.configList.forEach((defaultConfig) => {
            const maskDiv = document.createElement('div');
            let innerDom = null;
            if (defaultConfig.image && defaultConfig.image.src) {
                innerDom = document.createElement('img');
                innerDom.style.height = '100%';
                innerDom.style.width = '100%';
                innerDom.style.objectFit = 'contain';
                innerDom.src = defaultConfig.image.src;
            } else if (defaultConfig.text &&
                defaultConfig.text.content) {
                innerDom = document.createTextNode(defaultConfig.text.content);
            } else if (defaultConfig.rect &&
                defaultConfig.rect.color &&
                defaultConfig.rect.width) {
                innerDom = document.createElement('div');
            } else if (defaultConfig.html) {
                innerDom = document.createElement('div');
            } else if (defaultConfig.line &&
                defaultConfig.line.x1 && defaultConfig.line.y1 &&
                defaultConfig.line.x2 && defaultConfig.line.y2) {
                innerDom = document.createElement('div');
            } else if (defaultConfig.polygon &&
                defaultConfig.polygon.list &&
                defaultConfig.polygon.list.length >= 3) {
                innerDom = document.createElement('div');
            }

            if (innerDom) {
                maskDiv.appendChild(innerDom);
                maskDiv.style.visibility = '';
                maskDiv.style.position = "absolute";
                maskDiv.style.display = 'block'
                maskDiv.style['-ms-user-select'] = "none";
                maskDiv.style['-moz-user-select'] = "none";
                maskDiv.style['-webkit-user-select'] = "none";
                maskDiv.style['-o-user-select'] = "none";
                maskDiv.style['user-select'] = "none";
                maskDiv.style['-webkit-touch-callout'] = "none";
                maskDiv.style['-webkit-tap-highlight-color'] = "rgba(0,0,0,0)";
                maskDiv.style['-webkit-text-size-adjust'] = "none";
                maskDiv.style['-webkit-touch-callout'] = "none";
                maskDiv.style.opacity = defaultConfig.opacity;
                if (isNumber(defaultConfig.left)) {
                    maskDiv.style.left = defaultConfig.left + 'px';
                }
                if (isNumber(defaultConfig.right)) {
                    maskDiv.style.right = defaultConfig.right + 'px';
                }
                if (isNumber(defaultConfig.top)) {
                    maskDiv.style.top = defaultConfig.top + 'px';
                }
                if (isNumber(defaultConfig.bottom)) {
                    maskDiv.style.bottom = defaultConfig.bottom + 'px';
                }
                if (defaultConfig.backgroundColor) {
                    maskDiv.style.backgroundColor = defaultConfig.backgroundColor;
                }
                maskDiv.style.overflow = 'hidden';
                maskDiv.style.zIndex = "9999999";
                if (defaultConfig.image &&
                    defaultConfig.image.src) {
                    maskDiv.style.width = defaultConfig.image.width + 'px';
                    maskDiv.style.height = defaultConfig.image.height + 'px';
                } else if (defaultConfig.text &&
                    defaultConfig.text.content) {
                    maskDiv.style.fontSize = defaultConfig.text.fontSize + 'px';
                    maskDiv.style.color = defaultConfig.text.color;
                    if (defaultConfig.text.width) {
                        maskDiv.style.width = defaultConfig.text.width + 'px';
                    }
                    if (defaultConfig.text.height) {
                        maskDiv.style.height = defaultConfig.text.height + 'px';
                    }
                } else if (defaultConfig.rect &&
                    defaultConfig.rect.color &&
                    defaultConfig.rect.width) {
                    maskDiv.style.width = defaultConfig.rect.width + 'px';
                    maskDiv.style.height = defaultConfig.rect.height + 'px';
                    maskDiv.style.borderWidth = defaultConfig.rect.lineWidth + 'px';
                    maskDiv.style.borderStyle = 'solid';
                    maskDiv.style.borderColor = defaultConfig.rect.color;
                    //
                    if (defaultConfig.rect.fill) {
                        const fillDiv = document.createElement('div');
                        fillDiv.style.position = "absolute";
                        fillDiv.style.width = '100%';
                        fillDiv.style.height = '100%';
                        fillDiv.style.backgroundColor = defaultConfig.rect.fill;
                        if (defaultConfig.rect.fillOpacity) {
                            fillDiv.style.opacity = defaultConfig.rect.fillOpacity;
                        }
                        maskDiv.appendChild(fillDiv);
                    }
                } else if (defaultConfig.html) {
                    maskDiv.style.width = '100%';
                    maskDiv.style.height = '100%';
                    maskDiv.innerHTML = defaultConfig.html;
                } else if (defaultConfig.line &&
                    defaultConfig.line.x1 && defaultConfig.line.y1 &&
                    defaultConfig.line.x2 && defaultConfig.line.y2) {
                    this.settingLine(maskDiv, defaultConfig.line)
                } else if (defaultConfig.polygon &&
                    defaultConfig.polygon.list &&
                    defaultConfig.polygon.list.length >= 3) {
                    maskDiv.style.width = '100%';
                    maskDiv.style.height = '100%';
                    let points = defaultConfig.polygon.list;
                    const color = defaultConfig.polygon.color;
                    const lineWidth = defaultConfig.polygon.lineWidth;
                    points = points.sort((a, b) => {
                        return (a.index || 0) - (b.index || 0);
                    })
                    // inner bg
                    if (defaultConfig.polygon.fill) {
                        const fillDiv = document.createElement('div');
                        fillDiv.style.position = "absolute";
                        fillDiv.style.width = '100%';
                        fillDiv.style.height = '100%';
                        const clipPathValue = "polygon(" + points.map(p => `${p.x}px ${p.y}px`).join(", ") + ")";
                        fillDiv.style.clipPath = clipPathValue;
                        fillDiv.style.backgroundColor = defaultConfig.polygon.fill;
                        if (defaultConfig.polygon.fillOpacity) {
                            fillDiv.style.opacity = defaultConfig.polygon.fillOpacity;
                        }
                        maskDiv.appendChild(fillDiv);
                    }
                    points.forEach((itemPoint, index) => {
                        const lineDom = document.createElement('div');
                        // last
                        if (index === points.length - 1) {
                            const firstItemPoint = points[0];
                            const result = {
                                x1: itemPoint.x,
                                y1: itemPoint.y,
                                x2: firstItemPoint.x,
                                y2: firstItemPoint.y,
                                color: color,
                                lineWidth: lineWidth
                            };
                            this.settingLine(lineDom, result);
                            maskDiv.appendChild(lineDom);
                            return;
                        }

                        const nextItemPoint = points[index + 1];
                        const result = {
                            x1: itemPoint.x,
                            y1: itemPoint.y,
                            x2: nextItemPoint.x,
                            y2: nextItemPoint.y,
                            color: color,
                            lineWidth: lineWidth
                        };
                        this.settingLine(lineDom, result);
                        maskDiv.appendChild(lineDom);
                    })
                }
                if (this.isDynamic) {
                    this.shadowRootDynamicDom = maskDiv;
                }
                this.shadowRootInnerDom.appendChild(maskDiv)
            }
        })
    }

    settingLine(dom, options) {
        const x1 = options.x1;
        const y1 = options.y1;
        const x2 = options.x2;
        const y2 = options.y2;
        var length = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
        var angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;

        dom.style.backgroundColor = options.color;
        dom.style.width = length + 'px';
        dom.style.height = options.lineWidth + 'px'; // 可调整线的宽度
        dom.style.position = 'absolute';
        dom.style.top = y1 + 'px';
        dom.style.left = x1 + 'px';
        dom.style.transform = 'rotate(' + angle + 'deg)';
        dom.style.transformOrigin = '0 0'; // 从线条的起点开始旋转
    }

    remove() {
        this._removeDom();
    }

    _removeDom() {
        if (this.shadowRootInnerDom) {
            this.shadowRootInnerDom.innerHTML = '';
        }
    }
}
