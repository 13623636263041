import Emitter from "../utils/emitter";
import {isFalse, now} from "../utils";
import {EVENTS, EVENTS_ERROR, PLAYER_STREAM_TYPE} from "../constant";

export default class WebrtcLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.webrctUrl = null;
        player.debug.log('WebrtcStream', 'init');
    }

    destroy() {
        this.webrctUrl = null;
        this.off();
        this.player.debug.log('WebrtcStream', 'destroy');
    }

    /**
     * webrtc://host:port/webrtc/play/[streamPath]
     * @param url
     */
    fetchStream(url) {
        const {webrtc, debug} = this.player;
        this.player._times.streamStart = now();
        this.webrctUrl = url.replace('webrtc:', window.location.protocol);

        //
        if (this.webrctUrl.indexOf('/webrtc/play') === -1 &&
            this.player.isWebrtcForM7S()) {
            const webrtcUrlObj = new URL(this.webrctUrl);
            const path = '/webrtc/play' + webrtcUrlObj.pathname;
            this.webrctUrl = webrtcUrlObj.origin + path + webrtcUrlObj.search;
            this.player.debug.log('WebrtcStream', `original url is ${url}, and new url is: ${this.webrctUrl}`)
        }

        webrtc.loadSource(this.webrctUrl).then(() => {
            this.player.debug.log('WebrtcStream', 'loadSource success');
            this.emit(EVENTS.streamSuccess);
        }).catch((error) => {
            this.player.debug.error('WebrtcStream', 'loadSource error', error);
            this.emit(EVENTS_ERROR.webrtcError, error);
        });
    }

    getStreamType() {
        return PLAYER_STREAM_TYPE.webrtc;
    }

}
