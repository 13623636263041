/**
 * 将无符号Float32Array数组转化成有符号的Int16Array数组
 * @param {Float32Array} input unsinged Float32Array
 * @return {Int16Array} singed int16
 */
export function floatTo16BitPCM(input) {
    let i = input.length;
    let output = new Int16Array(i);
    while (i--) {
        let s = Math.max(-1, Math.min(1, input[i]));
        output[i] = (s < 0 ? s * 0x8000 : s * 0x7FFF);
    }
    return output;
}

/**
 * 将有符号的Int16Array数组转化成无符号Float32Array数组
 * @param {Int16Array} input singed int16
 * @return {Float32Array}  // unsinged float32
 */
export function int16ToFloat32BitPCM(input) {
    let i = input.length;
    let output = new Float32Array(i);
    while (i--) {
        let int = input[i];
        output[i] = (int >= 0x8000) ? -(0x10000 - int) / 0x8000 : int / 0x7FFF;
    }
    return output;
}

/**
 * 将无符号Float32Array数组转化成有符号的Int8Array数组
 * @param {Float32Array} input unsinged Float32Array
 * @return {Int8Array} singed int8
 */
export function floatTo8BitPCM(input) {
    let i = input.length;
    let output = new Int8Array(i);
    while (i--) {
        let s = Math.max(-1, Math.min(1, input[i]));
        const temp = (s < 0 ? s * 0x8000 : s * 0x7FFF);
        output[i] = parseInt(255 / (65535 / (32768 + temp)), 10)
    }
    return output;
}

/**
 * 将有符号的Int8Array数组转化成无符号Float32Array数组
 * @param {Int8Array} singed int8
 * @return {Float32Array}  // unsinged float32
 */
export function int8ToFloat32BitPCM(input) {
    let i = input.length;
    let output = new Float32Array(i);

    return output;
}

/**
 * 将24位PCM转换为8位PCM：
 * @param input
 * @returns {Uint8Array}
 */
export function int24ToUnit8BitPCM(input) {
    let pcm8Data = new Uint8Array(input.length / 3);

    for (let i = 0, j = 0; i < input.length; i += 3, j++) {
        // 只取每个24位样本的最高8位
        pcm8Data[j] = input[i + 2];
    }

    return pcm8Data;
}

/**
 * 将24位PCM转换为8位PCM：v2 版本
 * @param input
 * @returns {Uint8Array}
 */
export function int24ToUnit8BitPCM$2(input) {
    const pcm8 = new Uint8Array(input.length / 3);
    for (let i = 0, j = 0; j < input.length; i++) {
        //24bit pcm转成浮点数
        let n = ((input[j++] | (input[j++] << 8) | (input[j++] << 16)) << 8) >> 8;
        n = n / 16777216;
        //浮点数转成8位
        pcm8[i] = ((n * 0x7FFF) >> 8) + 128;
    }

    return pcm8;
}

/**
 * 将24位PCM转换为16位PCM：
 *
 * @param input
 * @returns {Uint16Array}
 */
export function int24ToInt16BitPcm(input) {
    const pcm16Data = new Uint16Array(input.length / 3 * 2);

    for (let i = 0, j = 0; i < input.length; i += 3, j += 2) {
        // 取每个24位样本的最高16位，低位在前，高位在后
        pcm16Data[j] = input[i + 1] | (input[i + 2] << 8);
    }

    return pcm16Data;
}

/**
 * 将24位PCM转换为16位PCM v2 版本
 * @param input
 * @returns {Int16Array}
 */
export function int24ToInt16BitPcm$2(input) {
    const pcm16 = new Int16Array(input.length / 3);
    for (let i = 0, j = 0; j < input.length; i++) {
        //24bit pcm转成浮点数
        let n = ((input[j++] | (input[j++] << 8) | (input[j++] << 16)) << 8) >> 8;
        n = n / 16777216;
        //浮点数转成16位
        pcm16[i] = n * 0x7FFF;
    }

    return pcm16;
}


/**
 *
 * @param input
 */
export function int8ToInt24BitPcm(input) {
    const pcm24 = new Uint8Array(input.length * 3);
    for (let i = 0, j = 0; i < input.length; i++) {
        const n = ((input[i] - 128) << 8) / 0x7FFF * 16777216; //8位的多一步先转成16位

        pcm24[j++] = n & 0xff;
        pcm24[j++] = (n & 0xff00) >> 8;
        pcm24[j++] = (n & 0xff0000) >> 16;
    }
    return pcm24;
}

/**
 *
 * @param input
 */
export function int16ToInt24BitPcm(input) {
    const pcm24 = new Uint8Array(input.length * 3);
    for (let i = 0, j = 0; i < input.length; i++) {
        const n = input[i] / 0x7FFF * 16777216;
        pcm24[j++] = n & 0xff;
        pcm24[j++] = (n & 0xff00) >> 8;
        pcm24[j++] = (n & 0xff0000) >> 16;
    }
    return pcm24;
}



export function readBig32 (data, i = 0) {
    return (data[i] << 24 >>> 0) + (data[i + 1] << 16) + (data[i + 2] << 8) + (data[i + 3] || 0)
}
