import Emitter from "../utils/emitter";
import {
    clamp,
    isFalse, isFirefox,
    isMacOsFirefox,
    isTrue,
    isVideoSequenceHeader,
    now,
    supportMSEDecodeHevc,
    supportVideoFrameCallback
} from "../utils";
import {
    AUDIO_ENC_CODE, AV_TRACK_ID,
    AVC_PACKET_TYPE,
    EVENTS,
    EVENTS_ERROR, FILE_SUFFIX, FRAME_TS_MAX_DIFF,
    MEDIA_SOURCE_EVENTS,
    MEDIA_SOURCE_STATE, MEDIA_TYPE, MP4_CODECS, MSE_DELAY_INCREASE_TIME, MSE_MAX_DELAY_TIME, RENDER_TYPE,
    VIDEO_ELEMENT_EVENTS, VIDEO_ENC_CODE, VIDEO_PAYLOAD_MIN_SIZE
} from "../constant";
import {parseAVCDecoderConfigurationRecord} from "../utils/h264";
import {parseHEVCDecoderConfigurationRecord$2} from "../utils/h265";
import MP4 from "../remux/fmp4-generator";
import {isAAC, isAacCodecPacket, parseAACAudioSpecificConfig} from "../utils/aac";
import {parseMp3AudioSpecificConfig} from "../utils/mp3";

export default class MediaSource extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'MediaSource';
        this.player = player;
        this._resetInIt();
        this.mediaSource = new window.MediaSource();
        //  if not check first frame is iframe, will set isVideoFirstIFrame = true
        this.isDecodeFirstIIframe = isFalse(player._opt.checkFirstIFrame) ? true : false;
        //
        this.mediaSourceObjectURL = window.URL.createObjectURL(this.mediaSource);
        this.isSupportVideoFrameCallback = supportVideoFrameCallback();
        this.canvasRenderInterval = null;

        if (player._opt.mseUseCanvasRender) {
            this.$videoElement = document.createElement('video');
            this.$videoElement.src = this.mediaSourceObjectURL;
            this.initVideoEvents();
        } else {
            this.player.video.$videoElement.src = this.mediaSourceObjectURL;
            this.$videoElement = this.player.video.$videoElement;
        }

        this._bindMediaSourceEvents();

        this.audioSourceBufferCheckTimeout = null;

        if (this.player.isPlayback()) {
            this.player.on(EVENTS.playbackPause, (flag) => {
                //  play
                if (isFalse(flag)) {
                    if (isTrue(player._opt.checkFirstIFrame)) {
                        this.player.debug.log(this.TAG_NAME, `playbackPause is false and _opt.checkFirstIFrame is true so set isDecodeFirstIIframe = false`)
                        this.isDecodeFirstIIframe = false;
                    }
                    this.clearUpAllSourceBuffer();
                    // this.$videoElement.currentTime = 0;
                    this.$videoElement.play();
                } else {
                    //  pause
                    this.$videoElement.pause();
                    this.cacheTrack = {};
                }
            })
        }

        // listen visiblity change
        this.player.on(EVENTS.visibilityChange, (flag) => {
            if (flag) {
                //
                setTimeout(() => {
                    if (this.player.isPlaying() && this.$videoElement) {
                        const lastTime = this.getVideoBufferLastTime();
                        if (lastTime > this.$videoElement.currentTime) {
                            this.player.debug.log(this.TAG_NAME, `visibilityChange is true and lastTime is ${lastTime} and currentTime is ${this.$videoElement.currentTime} so set currentTime to lastTime`);
                            this.$videoElement.currentTime = lastTime;
                        }
                    }
                }, 300)
            }
        })
    }

    destroy() {
        this.stop();
        this._clearAudioSourceBufferCheckTimeout();
        this._stopCanvasRender();
        if (this.eventListenList.length) {
            this.eventListenList.forEach(item => item());
            this.eventListenList = [];
        }
        if (this.supportVideoFrameCallbackHandle &&
            this.$videoElement) {
            this.$videoElement.cancelVideoFrameCallback(this.supportVideoFrameCallbackHandle);
            this.supportVideoFrameCallbackHandle = null;
        }
        if (this.$videoElement) {
            if (this.player._opt.mseUseCanvasRender) {
                if (this.$videoElement.src) {
                    this.$videoElement.src = '';
                    this.$videoElement.removeAttribute('src');
                }
            }
            this.$videoElement = null;
        }
        if (this.mediaSourceObjectURL) {
            window.URL.revokeObjectURL(this.mediaSourceObjectURL);
            this.mediaSourceObjectURL = null;
        }
        this._resetInIt();
        this.off();
    }

    needInitAudio() {
        return this.player._opt.hasAudio &&
            this.player._opt.mseDecodeAudio;
    }

    _resetInIt() {
        this.isAvc = null; //
        this.isAAC = null; //
        this.videoMeta = {};
        this.audioMeta = {};
        this.sourceBuffer = null;
        this.audioSourceBuffer = null;
        this.hasInit = false;
        this.hasAudioInit = false;
        this.isInitInfo = false;
        this.isAudioInitInfo = false;
        this.audioMimeType = '';
        this.cacheTrack = {};
        this.cacheAudioTrack = {};
        this.timeInit = false;
        this.sequenceNumber = 0;
        this.audioSequenceNumber = 0;
        this.firstRenderTime = null;
        this.firstAudioTime = null;// 暂时不用
        this.$videoElement = null;
        this.mediaSourceAppendBufferFull = false;
        this.mediaSourceAppendBufferError = false;
        this.mediaSourceAddSourceBufferError = false;
        this.mediaSourceBufferError = false;
        this.mediaSourceError = false;
        this.prevTimestamp = null;
        this.decodeDiffTimestamp = null;
        this.prevDts = null;
        this.prevAudioDts = null;
        this.prevPayloadBufferSize = 0;
        this.isWidthOrHeightChanged = false;
        this.prevTs = null;
        this.prevAudioTs = null;
        this.eventListenList = [];
        this.tempFmp4List = [];
        this.pendingRemoveRanges = [];
        this.pendingSegments = [];
        this.pendingAudioRemoveRanges = [];
        this.pendingAudioSegments = [];
        this.supportVideoFrameCallbackHandle = null;
    }

    get state() {
        return this.mediaSource && this.mediaSource.readyState
    }

    // source 打开，并且准备接受通过 sourceBuffer.appendBuffer 添加的数据。
    get isStateOpen() {
        return this.state === MEDIA_SOURCE_STATE.open;
    }

    // 当前 MS 没有和 media element(比如：video.src) 相关联。创建时，MS 就是该状态
    get isStateClosed() {
        return this.state === MEDIA_SOURCE_STATE.closed;
    }

    // 当 endOfStream() 执行完成，会变为该状态，此时，source 依然和 media element 连接。
    get isStateEnded() {
        return this.state === MEDIA_SOURCE_STATE.ended;
    }

    // 获得当前媒体播放的时间，既可以设置(get)，也可以获取(set)。单位为 s(秒)
    get duration() {
        return (this.mediaSource && this.mediaSource.duration) || -1;
    }

    set duration(duration) {
        this.mediaSource.duration = duration
    }

    _bindMediaSourceEvents() {
        const {
            debug,
            events: {proxy},
        } = this.player;

        // 当 "closed" to "open" 或者 "ended" to "open" 时触发。
        const sourceOpenProxyDestroy = proxy(this.mediaSource, MEDIA_SOURCE_EVENTS.sourceOpen, () => {
            this.player.debug.log(this.TAG_NAME, 'sourceOpen');
            this._onMediaSourceSourceOpen();
            this.player && this.player.emit(EVENTS.mseSourceOpen);
        })

        //当 "open" to "closed" 或者 "ended" to "closed" 时触发。
        const sourceCloseProxyDestroy = proxy(this.mediaSource, MEDIA_SOURCE_EVENTS.sourceClose, () => {
            this.player.debug.log(this.TAG_NAME, 'sourceClose');
            this.player && this.player.emit(EVENTS.mseSourceClose);
        })

        // 当 "open" to "ended" 时触发
        const sourceendedProxyDestroy = proxy(this.mediaSource, MEDIA_SOURCE_EVENTS.sourceended, () => {
            this.player.debug.log(this.TAG_NAME, 'sourceended');
            this.player && this.player.emit(EVENTS.mseSourceended);
        })
        this.eventListenList.push(sourceOpenProxyDestroy, sourceCloseProxyDestroy, sourceendedProxyDestroy);

        // 监听 change
        //  tips:在窗口不是最小化的情况下，会一直触发，但是一旦最小化，就不会触发了。
        const timeUpdateProxyDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.timeUpdate, (event) => {
            // for 降级处理用的。
            if (isFalse(this.isSupportVideoFrameCallback)) {
                if (this.player.checkIsInRender()) {
                    this.player.handleRender();
                } else {
                    const ts = parseInt(event.timeStamp, 10);
                    this.player.debug.log(this.TAG_NAME, `mseUseCanvasRender is ${this.player._opt.mseUseCanvasRender} and
                $videoElement ts is ${ts}, but not in render and vbps is ${this.player._stats.vbps} and fps is ${this.player._stats.fps}`)
                }
            }
        })

        const ratechangeProxyDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.ratechange, () => {
            this.player.debug.log(this.TAG_NAME, 'video playback Rate change', this.$videoElement && this.$videoElement.playbackRate)
            if (this.$videoElement &&
                this.$videoElement.paused) {
                this.player.debug.log(this.TAG_NAME, 'video is paused and next try to replay');
                this.$videoElement.play()
            }
        })
        this.eventListenList.push(timeUpdateProxyDestroy, ratechangeProxyDestroy);
    }

    _onMediaSourceSourceOpen() {
        // 1. remove evnet listener
        // 2. check _pendingSourceBufferInit list
        // 3. do Append Segments

        if (!this.sourceBuffer) {
            this.player.debug.log('MediaSource', 'onMediaSourceSourceOpen() sourceBuffer is null and next init');
            this._initSourceBuffer()
        }

        if (!this.audioSourceBuffer) {
            this.player.debug.log('MediaSource', 'onMediaSourceSourceOpen() audioSourceBuffer is null and next init');
            this._initAudioSourceBuffer();
        }

        //  append immediately only if init segment in subsequence
        if (this._hasPendingSegments()) {
            this._doAppendSegments();
        }
    }

    initVideoEvents() {
        const {proxy} = this.player.events;
        const canplayProxyDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.canplay, () => {
            this.player.debug.log(this.TAG_NAME, 'video canplay');
            this.$videoElement.play().then(() => {
                this.player.emit(EVENTS.removeLoadingBgImage);
                if (supportVideoFrameCallback()) {
                    this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
                } else {
                    //  hls265 for canvas render
                    if (this.player.isUseHls265()) {
                        this._stopCanvasRender();
                        this.canvasRenderInterval = setInterval(() => {
                            this.player.video.render({
                                $video: this.$videoElement,
                                ts: parseInt(this.$videoElement.currentTime * 1000, 10) || 0
                            })
                        }, 1000 / 25);
                    }
                }
                this.player.debug.log(this.TAG_NAME, 'video play');
            }).catch((e) => {
                // DOMException: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD
                this.player.debug.error(this.TAG_NAME, 'video play error ', e);
                this.player.emitError(EVENTS_ERROR.mediaSourceUseCanvasRenderPlayFailed, e);
            });
        })

        const waitingProxyDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.waiting, () => {
            this.player.debug.log(this.TAG_NAME, 'video waiting');
        })

        //
        const timeUpdateProxyDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.timeUpdate, (event) => {
            const timeStamp = parseInt(event.timeStamp, 10);
            // this.player.emit(EVENTS.videoTimeUpdate, timeStamp);
            // check video is playing
            if (this.$videoElement.paused) {
                this.player.debug.warn(this.TAG_NAME, 'video is paused and next try to replay',);
                this.$videoElement.play().then(() => {
                    this.player.debug.log(this.TAG_NAME, 'video is paused and replay success');
                }).catch((e) => {
                    this.player.debug.warn(this.TAG_NAME, 'video is paused and replay error ', e);
                })
            }
        })
        this.eventListenList.push(canplayProxyDestroy, waitingProxyDestroy, timeUpdateProxyDestroy);
    }

    videoFrameCallback(now, metaData = {}) {
        if (this.player.isDestroyed()) {
            this.player.debug.log(this.TAG_NAME, 'videoFrameCallback() player is destroyed');
            return;
        }
        const ts = parseInt((Math.max(metaData.mediaTime, this.$videoElement.currentTime)) * 1000, 10) || 0;
        this.player.handleRender();
        this.player.video.render({
            $video: this.$videoElement,
            ts
        })
        if (this.player.isUseHls265()) {
            this.player.updateStats({
                fps: true,
                ts: ts,
            })
        }
        this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
    }

    decodeVideo(payload, ts, isIframe, cts) {
        const player = this.player;

        if (!player) {
            return;
        }

        if (this.player.isDestroyed()) {
            this.player.debug.warn(this.TAG_NAME, 'decodeVideo() player is destroyed')
            return;
        }

        if (!this.hasInit) {
            // this.player.debug.warn(this.TAG_NAME, `decodeVideo has not init , isIframe is ${isIframe} , payload is ${payload[1]}`)

            if (isIframe && payload[1] === AVC_PACKET_TYPE.sequenceHeader) {
                const videoCodec = (payload[0] & 0x0F);
                player.video.updateVideoInfo({
                    encTypeCode: videoCodec
                })

                // windows 下面的 360 浏览器是支持 mse 解码播放的。
                // edge 也是支持 mse 解码播放的。
                if (videoCodec === VIDEO_ENC_CODE.h265 && isFalse(supportMSEDecodeHevc())) {
                    // this.emit(EVENTS_ERROR.mediaSourceH265NotSupport)
                    this.player.emitError(EVENTS_ERROR.mediaSourceH265NotSupport);
                    return;
                }

                if (!player._times.decodeStart) {
                    player._times.decodeStart = now();
                }

                this.hasInit = this._decodeConfigurationRecord(payload, ts, isIframe, videoCodec)

            } else {
                this.player.debug.warn(this.TAG_NAME, `decodeVideo has not init , isIframe is ${isIframe} , payload is ${payload[1]}`)
            }

        } else {

            if (!this.isDecodeFirstIIframe && isIframe) {
                this.isDecodeFirstIIframe = true;
            }

            if (this.isDecodeFirstIIframe) {
                //  check video width or height is changed
                if (isIframe && payload[1] === 0) {
                    const videoCodec = (payload[0] & 0x0F);
                    let config = {};

                    if (videoCodec === VIDEO_ENC_CODE.h264) {
                        let data = payload.slice(5);
                        config = parseAVCDecoderConfigurationRecord(data);
                    } else if (videoCodec === VIDEO_ENC_CODE.h265) {
                        config = parseHEVCDecoderConfigurationRecord$2(payload);
                    }
                    const videoInfo = this.player.video.videoInfo;
                    if ((videoInfo && videoInfo.width && videoInfo.height) &&
                        (config && config.codecWidth && config.codecHeight) &&
                        (config.codecWidth !== videoInfo.width || config.codecHeight !== videoInfo.height)) {
                        this.player.debug.warn(this.TAG_NAME, `
                        decodeVideo: video width or height is changed,
                        old width is ${videoInfo.width}, old height is ${videoInfo.height},
                        new width is ${config.codecWidth}, new height is ${config.codecHeight},
                        and emit change event`)
                        this.isWidthOrHeightChanged = true;
                        this.player.emitError(EVENTS_ERROR.mseWidthOrHeightChange);
                    }
                }

                if (this.isWidthOrHeightChanged) {
                    this.player.debug.warn(this.TAG_NAME, `decodeVideo: video width or height is changed, and return`)
                    return;
                }

                if (isVideoSequenceHeader(payload)) {
                    this.player.debug.warn(this.TAG_NAME, 'decodeVideo and payload is video sequence header so drop this frame');
                    return;
                }

                if (payload.byteLength < VIDEO_PAYLOAD_MIN_SIZE) {
                    this.player.debug.warn(this.TAG_NAME, `decodeVideo and payload is too small , payload length is ${payload.byteLength}`)
                    return;
                }

                let dts = ts;
                //  just for player
                if (this.player.isPlayer()) {
                    if (this.firstRenderTime === null) {
                        this.firstRenderTime = ts;
                    }
                    dts = ts - this.firstRenderTime;
                    if (dts < 0) {
                        this.player.debug.warn(this.TAG_NAME, `decodeVideo
                     local dts is < 0 , ts is ${ts} and prevTs is ${this.prevTs},
                     firstRenderTime is ${this.firstRenderTime} and mseCorrectTimeDuration is ${this.player._opt.mseCorrectTimeDuration}`);
                        if (this.prevDts === null) {
                            dts = 0;
                        } else {
                            dts = this.prevDts + this.player._opt.mseCorrectTimeDuration;
                        }
                        if (this._checkTsIsMaxDiff(ts)) {
                            this.player.debug.warn(this.TAG_NAME, `decodeVideo is max diff , ts is ${ts} and prevTs is ${this.prevTs}, diff is ${this.prevTs - ts}`)
                            this.player.emitError(EVENTS_ERROR.mediaSourceTsIsMaxDiff);
                            return;
                        }
                    }

                    if (this.prevDts !== null && dts <= this.prevDts) {
                        this.player.debug.warn(this.TAG_NAME, `
                    decodeVideo dts is less than(or equal) prev dts ,
                    dts is ${dts} and prev dts is ${this.prevDts} ，
                    and now ts is ${ts} and prev ts is ${this.prevTs} ，
                    and diff is ${ts - this.prevTs} and firstRenderTime is ${this.firstRenderTime} and isIframe is ${isIframe}，
                    and mseCorrectTimeDuration is ${this.player._opt.mseCorrectTimeDuration},
                    and prevPayloadBufferSize is ${this.prevPayloadBufferSize} and payload size is ${payload.byteLength}`);

                        if (dts === this.prevDts) {
                            if (this.prevPayloadBufferSize === payload.byteLength) {
                                this.player.debug.warn(this.TAG_NAME, 'decodeVideo dts is equal to prev dts and payload size is equal to prev payload size so drop this frame');
                                return;
                            }
                        }

                        dts = this.prevDts + this.player._opt.mseCorrectTimeDuration;
                        if (this._checkTsIsMaxDiff(ts)) {
                            this.player.debug.warn(this.TAG_NAME, `decodeVideo is max diff , ts is ${ts} and prevTs is ${this.prevTs}, diff is ${this.prevTs - ts} and emit replay`);
                            this.emit(EVENTS_ERROR.mediaSourceTsIsMaxDiff);
                            return;
                        }
                    }
                }

                if (this.player.isPlayer()) {
                    this._decodeVideo(payload, dts, isIframe, cts, ts);
                } else if (this.player.isPlayback()) {
                    if (isFalse(this.player.playbackPause)) {
                        //  is not pause
                        if (this.player.playback.isUseLocalCalculateTime) {
                            this.player.playback.increaseLocalTimestamp();
                        }
                        this._decodeVideo(payload, dts, isIframe, cts, ts);
                    }
                }
                this.prevDts = dts;
                this.prevPayloadBufferSize = payload.byteLength;
                this.prevTs = ts;


                // 如果是video
                if (this.player.getRenderType() === RENDER_TYPE.video && this.player.video) {
                    this.player.video.doAddContentToWatermark();
                }
            } else {
                this.player.debug.log(this.TAG_NAME, 'decodeVideo first frame is not iFrame')
            }
        }
    }

    decodeAudio(payload, ts) {
        const player = this.player;

        if (!player) {
            return;
        }

        if (this.player.isDestroyed()) {
            this.player.debug.warn(this.TAG_NAME, 'decodeAudio() player is destroyed')
            return;
        }

        if (isFalse(this.hasAudioInit)) {
            this.hasAudioInit = this._decodeAudioConfigurationRecord(payload, ts);
        } else {
            let dts = ts;

            if (this.player.isPlayer()) {
                if (this.firstAudioTime === null) {
                    this.firstAudioTime = ts;
                    if (this.firstRenderTime !== null && this.prevTs !== null) {
                        // If the video has been played for more than 300ms, this is the audio data,
                        // it is necessary to reduce the start time of the video difference (prevTs - firstRenderTime).
                        const diff = Math.abs(this.firstRenderTime - this.prevTs);
                        if (diff > 300) {
                            this.firstAudioTime -= diff;
                            this.player.debug.warn(this.TAG_NAME, `video
                            firstAudioTime is ${this.firstRenderTime} and current time is ${this.prevTs}
                            play time is ${diff} and firstAudioTime ${ts} - ${diff} = ${this.firstAudioTime}`);
                        }
                    }
                }
                dts = ts - this.firstAudioTime;
                if (dts < 0) {
                    this.player.debug.warn(this.TAG_NAME, `decodeAudio
                     local dts is < 0 , ts is ${ts} and prevTs is ${this.prevAudioTs},
                     firstAudioTime is ${this.firstAudioTime}`);
                    return;
                }

                if (this.prevAudioTs !== null &&
                    dts <= this.prevAudioDts) {
                    this.player.debug.warn(this.TAG_NAME, `
                    decodeAudio dts is less than(or equal) prev dts ,
                    dts is ${dts} and prev dts is ${this.prevAudioDts} ，
                    and now ts is ${ts} and prev ts is ${this.prevAudioTs} ，
                    and diff is ${ts - this.prevAudioTs}`);

                    return;
                }
            }

            if (this.player.isPlayer()) {
                this._decodeAudio(payload, dts, ts);
            } else if (this.player.isPlayback()) {
                if (isFalse(this.player.playbackPause)) {
                    this._decodeAudio(payload, dts, ts);
                }
            }

            this.prevAudioTs = ts;
            this.prevAudioDts = dts;
        }
    }

    _checkTsIsMaxDiff(ts) {
        return this.prevTs > 0
            && ts < this.prevTs
            && this.prevTs - ts > FRAME_TS_MAX_DIFF;
    }

    _decodeConfigurationRecord(payload, ts, isIframe, videoCodec) {
        let data = payload.slice(5);
        let config = {};

        // this.player.debug.log(this.TAG_NAME, `_decodeConfigurationRecord  videoCodec is ${videoCodec}`)
        if (videoCodec === VIDEO_ENC_CODE.h264) {
            config = parseAVCDecoderConfigurationRecord(data);
            // config = parseAVCDecoderConfigurationRecord$2(payload)
        } else if (videoCodec === VIDEO_ENC_CODE.h265) {
            // config = parseHEVCDecoderConfigurationRecord(data);
            config = parseHEVCDecoderConfigurationRecord$2(payload);
        }

        // todo：just for recorder
        if (this.player.recorder &&
            this.player._opt.recordType === FILE_SUFFIX.mp4) {
            this.player.recorder.initMetaData(payload, videoCodec);
        }

        if (config.codecWidth === 0 && config.codecHeight === 0) {
            this.player.debug.warn(this.TAG_NAME, '_decodeConfigurationRecord', config);
            this.player.emitError(EVENTS_ERROR.mediaSourceDecoderConfigurationError);
            return false;
        }
        const metaData = {
            id: AV_TRACK_ID.video, // video tag data
            type: 'video',
            timescale: 1000,
            duration: 0,
            avcc: data,
            codecWidth: config.codecWidth,
            codecHeight: config.codecHeight,
            videoType: config.videoType
        }
        // ftyp
        const metaBox = MP4.generateInitSegment(metaData);
        this.isAvc = videoCodec === VIDEO_ENC_CODE.h264;
        this.player.debug.log(this.TAG_NAME, `_decodeConfigurationRecord mimeType is ${this.isAvc ? MP4_CODECS.avc : MP4_CODECS.hev} and isAvc is ${this.isAvc}`);
        this._initSourceBuffer();
        this.appendBuffer(metaBox.buffer);
        this.sequenceNumber = 0;
        this.cacheTrack = {};
        this.timeInit = false;
        return true;
    }

    _decodeAudioConfigurationRecord(payload, ts) {
        const codecId = payload[0] >> 4;
        const isMp3 = codecId === AUDIO_ENC_CODE.MP3;
        const isAAC = codecId === AUDIO_ENC_CODE.AAC;
        if (isFalse(isAAC || isMp3)) {
            //  inner error, not emit error event。
            // inner change audio engine
            this.player.debug.warn(this.TAG_NAME, `_decodeAudioConfigurationRecord audio codec is not support , codecId is ${codecId} ant auto wasm decode`);
            this.player.emit(EVENTS_ERROR.mediaSourceAudioG711NotSupport)
            return false;
        }
        const metaData = {
            id: AV_TRACK_ID.audio,
            type: 'audio',
            timescale: 1000,
        };
        let metaInfo = {};
        if (isAAC && isAacCodecPacket(payload)) {
            const extraData = payload.slice(2);
            metaInfo = parseAACAudioSpecificConfig(extraData);
            if (metaInfo) {
                metaData.audioSampleRate = metaInfo.sampleRate;
                metaData.channelCount = metaInfo.channelCount;
                metaData.config = metaInfo.config;
                metaData.refSampleDuration = 1024 / metaData.audioSampleRate * metaData.timescale;
            } else {
                return false
            }
        } else if (isMp3) {
            // mp3
            // mp3 没有extradata信息，这里的extradata 就是第一帧mp3
            metaInfo = parseMp3AudioSpecificConfig(payload);
            if (metaInfo) {
                metaData.audioSampleRate = metaInfo.samplingRate;
                metaData.channelCount = metaInfo.channelCount;
                metaData.refSampleDuration = 1152 / metaData.audioSampleRate * metaData.timescale;
            } else {
                return false;
            }
        } else {
            return false;
        }

        metaData.codec = metaInfo.codec;
        metaData.duration = 0;
        let container = 'mp4';
        let codec = metaInfo.codec;
        let mineType = '';
        let metaBox = null;
        if (isMp3 && isFalse(isFirefox())) {
            // 'audio/mpeg' for MP3 audio track
            container = 'mpeg';
            codec = '';
            metaBox = new Uint8Array();
        } else {
            // 'audio/mp4, codecs="codec"'
            metaBox = MP4.generateInitSegment(metaData);
        }
        // console.error('metaData', metaData);
        // console.error('metaBox', metaBox);
        let mimeType = `${metaData.type}/${container}`;
        if (codec && codec.length > 0) {
            mimeType += `;codecs=${codec}`;
        }

        if (isFalse(this.isAudioInitInfo)) {
            this.player.audio.updateAudioInfo({
                encTypeCode: codecId,
                channels: metaData.channelCount,
                sampleRate: metaData.audioSampleRate,
            })
            this.isAudioInitInfo = true;
        }

        this.audioMimeType = mimeType;
        this.isAAC = isAAC;
        this.player.debug.log(this.TAG_NAME, `_decodeAudioConfigurationRecord mimeType is ${mimeType} and isAAC is ${isAAC}`);
        this._initAudioSourceBuffer();
        this.appendAudioBuffer(metaBox.buffer);
        return true;
    }

    _initSourceBuffer() {
        const {
            debug,
            events: {proxy},
        } = this.player;
        if (this.sourceBuffer === null &&
            this.mediaSource !== null &&
            this.isStateOpen &&
            this.isAvc !== null) {
            let codecs = this.isAvc ? MP4_CODECS.avc : MP4_CODECS.hev;
            try {
                this.sourceBuffer = this.mediaSource.addSourceBuffer(codecs);
                debug.log(this.TAG_NAME, '_initSourceBuffer() this.mediaSource.addSourceBuffer()', codecs);
            } catch (e) {
                debug.error(this.TAG_NAME, 'appendBuffer() this.mediaSource.addSourceBuffer()', e.code, e);
                //  need throw error and change to use wasm play
                this.player.emitError(EVENTS_ERROR.mseAddSourceBufferError, e);
                this.mediaSourceAddSourceBufferError = true;
                return;
            }
            if (this.sourceBuffer) {
                const sourceBufferErrorProxyDestroy = proxy(this.sourceBuffer, 'error', (error) => {
                    this.mediaSourceBufferError = true;
                    debug.error(this.TAG_NAME, 'mseSourceBufferError this.sourceBuffer', error);
                    this.player.emitError(EVENTS_ERROR.mseSourceBufferError, error);
                })
                const updateendProxyDestroy = proxy(this.sourceBuffer, 'updateend', () => {
                    if (this._hasPendingRemoveRanges()) {
                        // debug.log(this.TAG_NAME, 'this.sourceBuffer updateend and has pending remove ranges');
                        this._doRemoveRanges();
                    } else if (this._hasPendingSegments()) {
                        // debug.log(this.TAG_NAME, 'this.sourceBuffer updateend and has pending segments');
                        this._doAppendSegments();
                    }
                })
                this.eventListenList.push(sourceBufferErrorProxyDestroy, updateendProxyDestroy);
            }
        } else {
            debug.log(this.TAG_NAME, `_initSourceBuffer and this.isStateOpen is ${this.isStateOpen} and this.isAvc === null is ${this.isAvc === null}`);
        }
    }

    _initAudioSourceBuffer() {
        const {
            debug,
            events: {proxy},
        } = this.player;
        if (this.audioSourceBuffer === null &&
            this.mediaSource !== null &&
            this.isStateOpen &&
            this.audioMimeType) {
            try {
                this.audioSourceBuffer = this.mediaSource.addSourceBuffer(this.audioMimeType);
                this._clearAudioSourceBufferCheckTimeout();
                debug.log(this.TAG_NAME, '_initAudioSourceBuffer() this.mediaSource.addSourceBuffer()', this.audioMimeType);
            } catch (e) {
                debug.error(this.TAG_NAME, 'appendAudioBuffer() this.mediaSource.addSourceBuffer()', e.code, e);
                //  need throw error and change to use wasm play
                this.player.emitError(EVENTS_ERROR.mseAddSourceBufferError, e);
                this.mediaSourceAddSourceBufferError = true;
                return;
            }

            if (this.audioSourceBuffer) {
                const sourceBufferErrorProxyDestroy = proxy(this.audioSourceBuffer, 'error', (error) => {
                    this.mediaSourceBufferError = true;
                    debug.error(this.TAG_NAME, 'mseSourceBufferError this.audioSourceBuffer', error);
                    this.player.emitError(EVENTS_ERROR.mseSourceBufferError, error);
                })

                const updateendProxyDestroy = proxy(this.audioSourceBuffer, 'updateend', () => {
                    if (this._hasPendingRemoveRanges()) {
                        // debug.log(this.TAG_NAME, 'this.audioSourceBuffer updateend and has pending remove ranges');
                        this._doRemoveRanges();
                    } else if (this._hasPendingSegments()) {
                        // debug.log(this.TAG_NAME, 'this.audioSourceBuffer updateend and has pending segments');
                        this._doAppendSegments();
                    }
                })
                this.eventListenList.push(sourceBufferErrorProxyDestroy, updateendProxyDestroy);
            }
        } else {
            debug.log(this.TAG_NAME, `_initAudioSourceBuffer and this.isStateOpen is ${this.isStateOpen} and this.audioMimeType is ${this.audioMimeType}`);
        }
    }

    //
    _decodeVideo(payload, dts, isIframe, cts, ts) {
        const player = this.player;
        let arrayBuffer = payload.slice(5);
        let bytes = arrayBuffer.byteLength;

        if (bytes === 0) {
            player.debug.warn(this.TAG_NAME, '_decodeVideo payload bytes is 0 and return');
            return;
        }

        // 相对时间戳，用来表示PTS与DTS的差值。
        // 如果有b帧的存在，就会有问题。
        // dts 会有问题，要换成 pts
        // 解码时间戳，用于告知解码器该视频帧的解码时间；
        // 显示时间戳，用于告知播放器该视频帧的显示时间；
        let pts = '';
        let nowTime = new Date().getTime();
        let isFirst = false;
        if (!this.prevTimestamp) {
            this.prevTimestamp = nowTime;
            isFirst = true;
        }
        const diffTime = nowTime - this.prevTimestamp;
        this.decodeDiffTimestamp = diffTime;
        if (diffTime > 500 &&
            !isFirst &&
            this.player.isPlayer()) {
            player.debug.warn(this.TAG_NAME, `_decodeVideo now time is ${nowTime} and prev time is ${this.prevTimestamp}, diff time is ${diffTime} ms`);
        }

        const $video = this.$videoElement;
        // const maxDelay = player._opt.videoBufferDelay + player._opt.videoBuffer;
        // if ($video.buffered.length > 1) {
        //     this.removeBuffer($video.buffered.start(0), $video.buffered.end(0));
        //     this.timeInit = false;
        // }
        if (this.cacheTrack.id &&
            dts >= this.cacheTrack.dts) {
            // 需要额外加8个size
            let mdatBytes = 8 + this.cacheTrack.size;
            let mdatbox = new Uint8Array(mdatBytes);
            mdatbox[0] = mdatBytes >>> 24 & 255;
            mdatbox[1] = mdatBytes >>> 16 & 255;
            mdatbox[2] = mdatBytes >>> 8 & 255;
            mdatbox[3] = mdatBytes & 255;
            // mdat box用来存储视频碎片数据，
            mdatbox.set(MP4.types.mdat, 4);
            mdatbox.set(this.cacheTrack.data, 8);

            this.cacheTrack.duration = dts - this.cacheTrack.dts;
            // moof
            // moof box仅在流式MP4中使用，用于将多个sample组合成一个fragment。
            // moof 用来描述mdat
            // console.error('cacheTrack', this.cacheTrack, this.cacheTrack.dts);

            let moofbox = MP4.moof(this.cacheTrack, this.cacheTrack.dts);
            // 用完清空数据。
            this.cacheTrack = {};

            let result = new Uint8Array(moofbox.byteLength + mdatbox.byteLength);
            result.set(moofbox, 0);
            result.set(mdatbox, moofbox.byteLength);
            // appendBuffer
            this.appendBuffer(result.buffer)
            // player.debug.log(this.TAG_NAME, 'decode ts is', dts,ts);
            player.emit(EVENTS.timeUpdate, ts);
            //
            if (player.isPlayer()) {
                if (player.isUseHls265()) {
                    player.updateStats({dfps: true, mseTs: dts})
                } else {
                    player.updateStats({fps: true, dfps: true, ts: ts, mseTs: dts})
                }
            } else if (player.isPlayback()) {
                player.playback.updateStats({
                    ts: ts,
                })
            }
            if (!player._times.videoStart) {
                player._times.videoStart = now();
                player.handlePlayToRenderTimes()
            }
        } else {
            player.debug.log(this.TAG_NAME, `timeInit set false , cacheTrack = {} now dts is ${dts}, and ts is ${ts} cacheTrack dts is ${this.cacheTrack && this.cacheTrack.dts}`);
            this.timeInit = false;
            this.cacheTrack = {};
        }
        if (!this.cacheTrack) {
            this.cacheTrack = {};
        }
        this.cacheTrack.id = AV_TRACK_ID.video;
        this.cacheTrack.sequenceNumber = ++this.sequenceNumber;
        this.cacheTrack.size = bytes;
        this.cacheTrack.dts = dts;
        this.cacheTrack.cts = cts;
        this.cacheTrack.isKeyframe = isIframe;
        this.cacheTrack.data = arrayBuffer;
        //
        this.cacheTrack.flags = {
            isLeading: 0,
            dependsOn: isIframe ? 2 : 1,
            isDependedOn: isIframe ? 1 : 0,
            hasRedundancy: 0,
            isNonSync: isIframe ? 0 : 1
        }

        //
        // if (!this.timeInit && $video.buffered.length === 1) {
        //     player.debug.log(this.TAG_NAME, 'timeInit set true');
        //     this.timeInit = true;
        //     $video.currentTime = $video.buffered.end(0);
        // }

        if (!this.isInitInfo &&
            $video.videoWidth > 0 &&
            $video.videoHeight > 0) {
            player.debug.log(this.TAG_NAME, `updateVideoInfo: ${$video.videoWidth},${$video.videoHeight}`);
            player.video.updateVideoInfo({
                width: $video.videoWidth,
                height: $video.videoHeight
            })
            player.video.initCanvasViewSize();
            this.isInitInfo = true;
        }

        // use canvas render
        if (player._opt.mseUseCanvasRender &&
            isFalse(this.isSupportVideoFrameCallback) &&
            isFalse(player.isUseHls265())) {
            player.video.render({
                $video,
                ts: dts
            })
        }

        this.prevTimestamp = new Date().getTime();
    }

    _stopCanvasRender() {
        if (this.canvasRenderInterval) {
            clearInterval(this.canvasRenderInterval);
            this.canvasRenderInterval = null;
        }
    }

    _decodeAudio(payload, dts, ts) {
        const player = this.player;
        let arrayBuffer = this.isAAC ? payload.slice(2) : payload.slice(1);
        let bytes = arrayBuffer.byteLength;
        if (this.cacheAudioTrack.id &&
            dts >= this.cacheAudioTrack.dts) {
            // 需要额外加8个size
            let mdatBytes = 8 + this.cacheAudioTrack.size;
            let mdatbox = new Uint8Array(mdatBytes);
            mdatbox[0] = mdatBytes >>> 24 & 255;
            mdatbox[1] = mdatBytes >>> 16 & 255;
            mdatbox[2] = mdatBytes >>> 8 & 255;
            mdatbox[3] = mdatBytes & 255;
            // mdat box用来存储视频碎片数据，
            mdatbox.set(MP4.types.mdat, 4);
            mdatbox.set(this.cacheAudioTrack.data, 8);
            this.cacheAudioTrack.duration = dts - this.cacheAudioTrack.dts;
            // moof
            // moof box仅在流式MP4中使用，用于将多个sample组合成一个fragment。
            // moof 用来描述mdat
            // console.error('cacheAudioTrack', this.cacheAudioTrack, this.cacheAudioTrack.dts);
            let moofbox = MP4.moof(this.cacheAudioTrack, this.cacheAudioTrack.dts);
            // 用完清空数据。
            this.cacheAudioTrack = {};
            let result = new Uint8Array(moofbox.byteLength + mdatbox.byteLength);
            result.set(moofbox, 0);
            result.set(mdatbox, moofbox.byteLength);

            this.appendAudioBuffer(result.buffer);
        } else {
            this.cacheAudioTrack = {};
        }

        if (!this.cacheAudioTrack) {
            this.cacheAudioTrack = {};
        }
        this.cacheAudioTrack.id = AV_TRACK_ID.audio;
        this.cacheAudioTrack.sequenceNumber = ++this.audioSequenceNumber;
        this.cacheAudioTrack.size = bytes;
        this.cacheAudioTrack.dts = dts;
        this.cacheAudioTrack.cts = 0;
        this.cacheAudioTrack.data = arrayBuffer;
        this.cacheAudioTrack.flags = {
            isLeading: 0,
            dependsOn: 1,
            isDependedOn: 0,
            hasRedundancy: 0,
        }
    }

    appendBuffer(buffer) {

        if (this.player.isDestroyed()) {
            this.player.debug.warn(this.TAG_NAME, 'appendBuffer() player is destroyed')
            return;
        }

        const {
            debug,
            events: {proxy},
        } = this.player;


        if (this.mediaSourceAddSourceBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAddSourceBufferError is true`);
            return;
        }

        if (this.mediaSourceAppendBufferFull) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAppendBufferFull is true`);
            return;
        }

        if (this.mediaSourceAppendBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAppendBufferError is true`);
            return;
        }

        if (this.mediaSourceBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceBufferError is true`);
            return;
        }

        this.pendingSegments.push(buffer);

        if (this.sourceBuffer) {
            //  just for player
            if (this.player.isPlayer()) {
                this._handleUpdatePlaybackRate();
            }

            if (this.player.isPlayback()) {
                this._handleUpdateBufferDelayTime();
            }

            if (this.player._opt.mseAutoCleanupSourceBuffer &&
                this._needCleanupSourceBuffer()) {
                this._doCleanUpSourceBuffer();
            }

            if (isFalse(this.getSourceBufferUpdating()) &&
                this.isStateOpen &&
                isFalse(this._hasPendingRemoveRanges())) {
                this._doAppendSegments();
                return;
            }
        }

        if (this.isStateClosed) {
            this.mediaSourceBufferError = true;
            this.player.emitError(EVENTS_ERROR.mseSourceBufferError, 'mediaSource is not attached to video or mediaSource is closed');
        } else if (this.isStateEnded) {
            this.mediaSourceBufferError = true;
            this.player.emitError(EVENTS_ERROR.mseSourceBufferError, 'mediaSource is end');
        } else {
            if (this._hasPendingRemoveRanges()) {
                debug.log(this.TAG_NAME, `video has pending remove ranges and video length is ${this.pendingRemoveRanges.length}, audio length is ${this.pendingAudioRemoveRanges.length}`);
            }
        }
    }

    appendAudioBuffer(buffer) {

        if (this.player.isDestroyed()) {
            this.player.debug.warn(this.TAG_NAME, 'appendAudioBuffer() player is destroyed')
            return;
        }

        const {
            debug,
            events: {proxy},
        } = this.player;


        if (this.mediaSourceAddSourceBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAddSourceBufferError is true`);
            return;
        }

        if (this.mediaSourceAppendBufferFull) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAppendBufferFull is true`);
            return;
        }

        if (this.mediaSourceAppendBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceAppendBufferError is true`);
            return;
        }

        if (this.mediaSourceBufferError) {
            debug.warn(this.TAG_NAME, `this.mediaSourceBufferError is true`);
            return;
        }

        this.pendingAudioSegments.push(buffer);
        if (this.audioSourceBuffer) {
            //  just for player
            if (this.player.isPlayer()) {
                this._handleUpdatePlaybackRate();
            }

            if (this.player.isPlayback()) {
                this._handleUpdateBufferDelayTime();
            }

            if (this.player._opt.mseAutoCleanupSourceBuffer &&
                this._needCleanupSourceBuffer()) {
                this._doCleanUpSourceBuffer();
            }

            if (isFalse(this.getAudioSourceBufferUpdating()) &&
                this.isStateOpen &&
                isFalse(this._hasPendingRemoveRanges())) {
                this._doAppendSegments();
                return;
            }
        }

        if (this.isStateClosed) {
            this.mediaSourceBufferError = true;
            this.player.emitError(EVENTS_ERROR.mseSourceBufferError, 'mediaSource is not attached to video or mediaSource is closed');
        } else if (this.isStateEnded) {
            this.mediaSourceBufferError = true;
            this.player.emitError(EVENTS_ERROR.mseSourceBufferError, 'mediaSource is end');
        } else {
            if (this._hasPendingRemoveRanges()) {
                debug.log(this.TAG_NAME, `audio has pending remove ranges and video length is ${this.pendingRemoveRanges.length}, audio length is ${this.pendingAudioRemoveRanges.length}`);
            }
        }
    }


    getSourceBufferUpdating() {
        return this.sourceBuffer && this.sourceBuffer.updating;
    }

    getAudioSourceBufferUpdating() {
        return this.audioSourceBuffer && this.audioSourceBuffer.updating;
    }


    stop() {
        //  remove source buffer
        this.removeSourceBuffer();
        //  end of stream
        this.endOfStream();
        //  abort source buffer
        this.abortSourceBuffer();
    }

    checkSourceBufferDelay() {
        const $video = this.$videoElement;
        let result = 0;
        if ($video.buffered.length > 0) {
            result = $video.buffered.end($video.buffered.length - 1) - $video.currentTime;
        }
        if (result < 0) {
            this.player.debug.warn(this.TAG_NAME, `checkSourceBufferDelay end(0) is ${$video.buffered.end(0)} - currentTime is ${$video.currentTime} and  result < 0 and result is ${result}`);
            result = 0
        }

        return result;
    }

    checkSourceBufferStore() {
        const $video = this.$videoElement;
        let result = 0;
        if ($video.buffered.length > 0) {
            result = $video.currentTime - $video.buffered.start(0);
        }

        return result;
    }


    getDecodeDiffTimes() {
        return this.decodeDiffTimestamp;
    }


    removeBuffer(start, end) {
        const _isMacOsFirefox = isMacOsFirefox();
        this.player.debug.log(this.TAG_NAME, `removeBuffer() start is ${start} and end is ${end} and _isMacOsFirefox is ${_isMacOsFirefox}`);
        if (this.isStateOpen &&
            isFalse(_isMacOsFirefox)) {
            if (isFalse(this.getSourceBufferUpdating())) {
                try {
                    this.sourceBuffer.remove(start, end)
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeBuffer() sourceBuffer error', e);
                }
            }

            if (isFalse(this.getAudioSourceBufferUpdating())) {
                try {
                    this.audioSourceBuffer.remove(start, end)
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeBuffer() audioSourceBuffer error', e);
                }
            }
        }
    }

    //  clear up all source buffer
    clearUpAllSourceBuffer() {
        // const $video = this.$videoElement;
        // const buffered = $video.buffered;

        if (this.sourceBuffer) {
            const buffered = this.sourceBuffer.buffered;
            for (let i = 0; i < buffered.length; i++) {
                let start = buffered.start(i);
                let end = buffered.end(i);
                this.pendingRemoveRanges.push({start: start, end: end});
            }

            if (isFalse(this.getSourceBufferUpdating())) {
                this._doRemoveRanges();
            }
        }

        if (this.audioSourceBuffer) {
            const buffered = this.audioSourceBuffer.buffered;
            for (let i = 0; i < buffered.length; i++) {
                let start = buffered.start(i);
                let end = buffered.end(i);
                this.pendingAudioRemoveRanges.push({start: start, end: end});
            }

            if (isFalse(this.getAudioSourceBufferUpdating())) {
                this._doRemoveRanges();
            }
        }
    }

    endOfStream() {
        // fix: MediaSource endOfStream before demuxer initialization completes (before HAVE_METADATA) is treated as an error
        if (this.isStateOpen &&
            this.$videoElement &&
            this.$videoElement.readyState >= 1) {
            try {
                this.player.debug.log(this.TAG_NAME, 'endOfStream()');
                this.mediaSource.endOfStream();
            } catch (e) {
                this.player.debug.warn(this.TAG_NAME, 'endOfStream() error', e);
            }
        }
    }

    abortSourceBuffer() {
        if (this.isStateOpen) {
            if (this.sourceBuffer) {
                try {
                    this.player.debug.log(this.TAG_NAME, 'abortSourceBuffer() abort sourceBuffer');
                    this.sourceBuffer.abort();
                } catch (e) {

                }
            }

            if (this.audioSourceBuffer) {
                try {
                    this.player.debug.log(this.TAG_NAME, 'abortSourceBuffer() abort audioSourceBuffer');
                    this.audioSourceBuffer.abort();
                } catch (e) {

                }
            }
        }

        this.sourceBuffer = null;
        this.audioSourceBuffer = null;
    }


    removeSourceBuffer() {
        if (!this.isStateClosed &&
            this.mediaSource) {

            //  video
            if (this.sourceBuffer) {
                try {
                    this.player.debug.log(this.TAG_NAME, 'removeSourceBuffer() sourceBuffer');
                    this.mediaSource.removeSourceBuffer(this.sourceBuffer);
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeSourceBuffer() sourceBuffer error', e);
                }
            }

            //  audio
            if (this.audioSourceBuffer) {
                try {
                    this.player.debug.log(this.TAG_NAME, 'removeSourceBuffer() audioSourceBuffer');
                    this.mediaSource.removeSourceBuffer(this.audioSourceBuffer);
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeSourceBuffer() audioSourceBuffer error', e);
                }
            }
        }
    }

    _hasPendingSegments() {
        return this.pendingSegments.length > 0 ||
            this.pendingAudioSegments.length > 0;
    }

    //  pending
    getPendingSegmentsLength() {
        return this.pendingSegments.length;
    }

    _handleUpdatePlaybackRate() {
        if (!this.$videoElement) {
            return;
        }

        const $video = this.$videoElement;
        const videoBuffer = this.player._opt.videoBuffer;
        const videoBufferDelay = this.player._opt.videoBufferDelay;
        let maxDelay = (videoBuffer + videoBufferDelay) / 1000;
        const ranges = $video.buffered;// 已缓冲的时间范围
        const start = ranges.length ? ranges.start(0) : 0;
        const buffered = ranges.length ? ranges.end(ranges.length - 1) : 0;
        let time = $video.currentTime;

        const buffer = buffered - time;
        // not less than MSE_MAX_DELAY_TIME
        const maxDelayTime = Math.max(MSE_MAX_DELAY_TIME, maxDelay + MSE_DELAY_INCREASE_TIME);

        this.player.updateStats({
            mseVideoBufferDelayTime: buffer,
        })

        if (buffer > maxDelayTime) {
            this.player.debug.log(this.TAG_NAME, `handleUpdatePlaybackRate and buffered is ${buffered} and current is ${time} , delay buffer is more than ${maxDelayTime} is ${buffer} and new time is ${buffered}`);
            $video.currentTime = buffered;
            time = $video.currentTime;
        } else if (buffer < 0) {
            this.player.debug.warn(this.TAG_NAME, `handleUpdatePlaybackRate and delay buffer is ${buffered} - current is ${time} = ${buffer} < 0 and check video is paused : ${$video.paused} `);

            // buffer
            if (buffered === 0) {
                this.player.emit(EVENTS_ERROR.mediaSourceBufferedIsZeroError, 'video.buffered is empty');
                return;
            }

            // check if the video is paused
            if ($video.paused) {
                // if paused, play it
                $video.play();
            }
        }

        const rate = this._getPlaybackRate(buffered - time);
        if ($video.playbackRate !== rate) {
            this.player.debug.log(this.TAG_NAME, `handleUpdatePlaybackRate and buffered is ${buffered} and current time is ${time} and delay is ${buffered - time}  set playbackRate is ${rate} `)
            $video.playbackRate = rate;
        }
    }

    _handleUpdateBufferDelayTime() {
        const bufferDelayTime = this.getVideoBufferDelayTime();
        this.player.updateStats({
            mseVideoBufferDelayTime: bufferDelayTime,
        })
    }


    _doAppendSegments() {
        if (this.isStateClosed ||
            this.isStateEnded) {
            return;
        }

        if (this.needInitAudio() &&
            this.audioSourceBuffer === null) {
            this.player.debug.log(this.TAG_NAME, '_doAppendSegments() audioSourceBuffer is null and need init audio source buffer');

            if (this.audioSourceBufferCheckTimeout === null) {
                this.audioSourceBufferCheckTimeout = setTimeout(() => {
                    // this.player.debug.warn(this.TAG_NAME, '_doAppendSegments() init audio timeout and set _opt.hasAudio is false');
                    // this.player._opt.hasAudio = false;
                    // this.audioSourceBufferCheckTimeout = null;
                    this._clearAudioSourceBufferCheckTimeout();
                    this.player.emit(EVENTS_ERROR.mediaSourceAudioInitTimeout);
                }, 1000);
            }
            return;
        }

        if (isFalse(this.getSourceBufferUpdating())) {
            //  video
            if (this.pendingSegments.length > 0) {
                const segment = this.pendingSegments.shift();
                try {
                    this.sourceBuffer.appendBuffer(segment);
                } catch (e) {
                    this.player.debug.error(this.TAG_NAME, 'this.sourceBuffer.appendBuffer()', e.code, e);
                    if (e.code === 22) {
                        // QuotaExceededError
                        // The SourceBuffer is full, and cannot free space to append additional buffers
                        this.stop();
                        this.mediaSourceAppendBufferFull = true;
                        this.player.emitError(EVENTS_ERROR.mediaSourceFull)
                    } else if (e.code === 11) {
                        //     Failed to execute 'appendBuffer' on 'SourceBuffer': The HTMLMediaElement.error attribute is not null.
                        this.stop();
                        this.mediaSourceAppendBufferError = true;
                        this.player.emitError(EVENTS_ERROR.mediaSourceAppendBufferError);
                    } else {
                        this.stop();
                        this.mediaSourceBufferError = true;
                        this.player.debug.error(this.TAG_NAME, 'appendBuffer error', e)
                        this.player.emitError(EVENTS.mseSourceBufferError, e);
                    }
                }
            }

        }

        if (isFalse(this.getAudioSourceBufferUpdating())) {
            //  audio
            if (this.pendingAudioSegments.length > 0) {
                const segment = this.pendingAudioSegments.shift();
                try {
                    this.audioSourceBuffer.appendBuffer(segment);
                } catch (e) {
                    this.player.debug.error(this.TAG_NAME, 'this.audioSourceBuffer.appendBuffer()', e.code, e);
                    if (e.code === 22) {
                        // QuotaExceededError
                        // The SourceBuffer is full, and cannot free space to append additional buffers
                        this.stop();
                        this.mediaSourceAppendBufferFull = true;
                        this.player.emitError(EVENTS_ERROR.mediaSourceFull)
                    } else if (e.code === 11) {
                        //     Failed to execute 'appendBuffer' on 'SourceBuffer': The HTMLMediaElement.error attribute is not null.
                        this.stop();
                        this.mediaSourceAppendBufferError = true;
                        this.player.emitError(EVENTS_ERROR.mediaSourceAppendBufferError);
                    } else {
                        this.stop();
                        this.mediaSourceBufferError = true;
                        this.player.debug.error(this.TAG_NAME, 'appendBuffer error', e)
                        this.player.emitError(EVENTS.mseSourceBufferError, e);
                    }
                }
            }
        }
    }


    _doCleanUpSourceBuffer() {

        if (!this.$videoElement) {
            return
        }

        const $video = this.$videoElement;
        const currentTime = $video.currentTime;

        //  video
        if (this.sourceBuffer) {
            const buffered = this.sourceBuffer.buffered;
            let doRemove = false;
            for (let i = 0; i < buffered.length; i++) {
                let start = buffered.start(i);
                let end = buffered.end(i);

                if (start <= currentTime && currentTime < end + 3) {  // padding 3 seconds
                    if (currentTime - start >= this.player._opt.mseAutoCleanupMaxBackwardDuration) {
                        doRemove = true;
                        let removeEnd = currentTime - this.player._opt.mseAutoCleanupMinBackwardDuration;
                        this.pendingRemoveRanges.push({start: start, end: removeEnd});
                    }
                } else if (end < currentTime) {
                    doRemove = true;
                    this.pendingRemoveRanges.push({start: start, end: end});
                }
            }

            if (doRemove &&
                isFalse(this.getSourceBufferUpdating())) {
                this._doRemoveRanges();
            }
        }

        //  audio
        if (this.audioSourceBuffer) {
            const buffered = this.audioSourceBuffer.buffered;
            let doRemove = false;
            for (let i = 0; i < buffered.length; i++) {
                let start = buffered.start(i);
                let end = buffered.end(i);

                if (start <= currentTime && currentTime < end + 3) {  // padding 3 seconds
                    if (currentTime - start >= this.player._opt.mseAutoCleanupMaxBackwardDuration) {
                        doRemove = true;
                        let removeEnd = currentTime - this.player._opt.mseAutoCleanupMinBackwardDuration;
                        this.pendingAudioRemoveRanges.push({start: start, end: removeEnd});
                    }
                } else if (end < currentTime) {
                    doRemove = true;
                    this.pendingAudioRemoveRanges.push({start: start, end: end});
                }
            }

            if (doRemove &&
                isFalse(this.getAudioSourceBufferUpdating())) {
                this._doRemoveRanges();
            }
        }
    }

    _hasPendingRemoveRanges() {
        return this.pendingRemoveRanges.length > 0 ||
            this.pendingAudioRemoveRanges.length > 0;
    }

    _doRemoveRanges() {
        if (this.sourceBuffer &&
            isFalse(this.getSourceBufferUpdating())) {
            let ranges = this.pendingRemoveRanges;
            while (ranges.length &&
            isFalse(this.getSourceBufferUpdating())) {
                let range = ranges.shift();
                try {
                    this.sourceBuffer.remove(range.start, range.end);
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeBuffer() sourceBuffer error', e);
                }
            }
        }

        if (this.audioSourceBuffer &&
            isFalse(this.getAudioSourceBufferUpdating())) {
            let ranges = this.pendingAudioRemoveRanges;
            while (ranges.length &&
            isFalse(this.getAudioSourceBufferUpdating())) {
                let range = ranges.shift();
                try {
                    this.audioSourceBuffer.remove(range.start, range.end);
                } catch (e) {
                    this.player.debug.warn(this.TAG_NAME, 'removeBuffer() audioSourceBuffer error', e);
                }
            }
        }
    }

    getDecodePlaybackRate() {
        let result = 0;
        const $video = this.$videoElement;
        if ($video) {
            result = $video.playbackRate;
        }
        return result;
    }

    _getPlaybackRate(buffer) {
        const $video = this.$videoElement;
        let videoBufferDelay = this.player._opt.videoBufferDelay + this.player._opt.videoBuffer;
        //  not less than 1000ms
        const maxDelay = Math.max(videoBufferDelay, 1000)
        const minDelay = maxDelay / 2;
        //  s -> ms
        buffer = buffer * 1000;
        switch ($video.playbackRate) {
            case 1:
                if (buffer > maxDelay) {
                    return 1.2;
                }
                return 1;
            default:
                if (buffer <= minDelay) {
                    return 1;
                }
                return $video.playbackRate;
        }
    }

    _needCleanupSourceBuffer() {
        if (isFalse(this.player._opt.mseAutoCleanupSourceBuffer) ||
            !this.$videoElement) {
            return false;
        }

        const $video = this.$videoElement;
        const buffered = $video.buffered;
        const currentTime = $video.currentTime;
        if (buffered.length >= 1) {
            if (currentTime - buffered.start(0) >= this.player._opt.mseAutoCleanupMaxBackwardDuration) {
                return true;
            }
        }
        return false;
    }

    getVideoCurrentTime() {
        let result = 0;
        if (this.$videoElement) {
            result = this.$videoElement.currentTime;
        }
        return result;
    }

    getVideoBufferLastTime() {
        const $video = this.$videoElement;
        let result = 0;
        if ($video) {
            const ranges = $video.buffered;// 已缓冲的时间范围
            const start = ranges.length ? ranges.start(0) : 0;
            const buffered = ranges.length ? ranges.end(ranges.length - 1) : 0;
            result = buffered;
        }

        return result;
    }

    getVideoBufferDelayTime() {
        const $video = this.$videoElement;
        const buffered = this.getVideoBufferLastTime();
        let time = $video.currentTime;
        const buffer = buffered - time;
        return buffer > 0 ? buffer : 0;
    }

    _clearAudioSourceBufferCheckTimeout() {
        if (this.audioSourceBufferCheckTimeout) {
            clearTimeout(this.audioSourceBufferCheckTimeout);
            this.audioSourceBufferCheckTimeout = null;
        }
    }
}
