import Emitter from "../utils/emitter";
import {getDefaultMenuOptions, isMobile, setStyle, uuid16} from "../utils";
import {EVENTS, MENU_ITEM_OPTIONS} from "../constant";
import {addClass, append, hasClass, includeFromEvent, removeClass} from "../utils/dom";

// 右键菜单
export default class Contextmenu extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.LOG_NAME = 'Contextmenu';
        this.menuList = [];
        this.$contextmenus = player.control.$contextmenus;


        if (!isMobile()) {
            this.init();
        } else {
            this.player.debug.warn(this.LOG_NAME, 'not support mobile')
        }
        player.debug.log(this.LOG_NAME, 'init')
    }

    destroy() {
        this.menuList = [];
        this.player.debug.log(this.LOG_NAME, 'destroy')
    }

    get isShow() {
        return hasClass(this.player.$container, 'jessibuca-contextmenus-show');
    }

    show() {
        addClass(this.player.$container, 'jessibuca-contextmenus-show');
    }

    hide() {
        removeClass(this.player.$container, 'jessibuca-contextmenus-show');
    }

    init() {
        const {
            events: {proxy},
            debug,
        } = this.player;

        if (this.player._opt.contextmenuBtns.length > 0) {
            this.player._opt.contextmenuBtns.forEach(btn => {
                this.addMenuItem(btn);
            });
        }

        proxy(this.player.$container, 'contextmenu', (e) => {
            e.preventDefault();
            this.show();
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            const {height: cHeight, width: cWidth, left: cLeft, top: cTop} = this.player.$container.getBoundingClientRect();
            const {height: mHeight, width: mWidth} = this.$contextmenus.getBoundingClientRect();

            let menuLeft = mouseX - cLeft;
            let menuTop = mouseY - cTop;
            if (mouseX + mWidth > cLeft + cWidth) {
                menuLeft = cWidth - mWidth;
            }

            if (mouseY + mHeight > cTop + cHeight) {
                menuTop = cHeight - mHeight;
            }

            setStyle(this.$contextmenus, {
                left: `${menuLeft}px`,
                top: `${menuTop}px`,
            })
        })

        proxy(this.player.$container, 'click', (e) => {
            if (!includeFromEvent(e, this.$contextmenus)) {
                this.hide()
            }
        })

        this.player.on(EVENTS.blur, () => {
            this.hide();
        })
    }

    _validateMenuItem(options) {
        let result = true;

        if (!options.content) {
            this.player.debug.warn(this.LOG_NAME, 'content is required');
            result = false;
        }

        return result;
    }

    addMenuItem(options = {}) {
        const defaultOptions = getDefaultMenuOptions()
        options = Object.assign({}, defaultOptions, options);

        if (!this._validateMenuItem(options)) {
            return
        }

        const {
            events: {proxy},
            debug,
        } = this.player;
        const uuid = uuid16();

        const $btn = `
            <div class="jessibuca-contextmenu jessibuca-contextmenu-${uuid}">
                ${options.content}
            </div>
        `
        const $childList = Array.from(this.$contextmenus.children);
        const nextChild = $childList[options.index];
        if (nextChild) {
            // insert before
            nextChild.insertAdjacentHTML('beforebegin', $btn);
        } else {
            append(this.$contextmenus, $btn);
        }

        const $menuItem = this.$contextmenus.querySelector(`.jessibuca-contextmenu-${uuid}`);


        if (options.click) {
            proxy($menuItem, 'click', (e) => {
                e.preventDefault();
                options.click.call(this.player, this, e);
                this.hide();
            });
        }

        this.menuList.push({
            uuid,
            $menuItem
        })
    }
}
