import ajax from './ajax';

export function getWebRtcRemoteSdp(url, data) {

    // return ajax({
    //     url,
    //     type: 'POST',
    //     data,
    //     contentType: 'application/sdp',
    //     processData: false
    // })

    return fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
        headers: {
            'Content-Type': 'application/sdp'
        },
        body: data,
    })
}

export function getWebRtcRemoteSdpForZLM(url, data) {
    return ajax({
        url,
        type: 'POST',
        data,
        contentType: 'text/plain;charset=utf-8',
        processData: false,
        dataType: 'json'
    })
}

export function getWebRtcRemoteSdpForSRS(url, data) {
    return fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
        headers: {
            'Content-Type': 'application/sdp'
        },
        body: data,
    })
}


export function getWebRtcRemoteSdpForOthers(url, data) {
    return fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
        headers: {
            'Content-Type': 'application/sdp'
        },
        body: data,
    })
}
