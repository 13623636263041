import icons from './icons';
import {PLAY_TYPE, PLAYBACK_CONTROL_TYPE} from "../constant";

export default (player, control) => {

    if (player._opt.hasControl && player._opt.controlAutoHide) {
        player.$container.classList.add('jessibuca-controls-show-auto-hide');
    } else {
        player.$container.classList.add('jessibuca-controls-show');
    }
    const options = player._opt;
    const operateBtns = options.operateBtns;

    const playbackOperateDom = `
        <div class="jessibuca-controls-center">
            <div class="jessibuca-controls-playback-time">
                <div class="jessibuca-controls-playback-time-inner">
                    <div class="jessibuca-controls-playback-time-scroll">
                        <div class="jessibuca-controls-playback-time-list">
                            <div class="jessibuca-playback-time-day">
                                <div class="jessibuca-playback-time-one-wrap"></div>
                                <div class="jessibuca-playback-time-second-wrap"></div>
                            </div>
                        </div>
                        <div class="jessibuca-controls-playback-current-time">
                            <div class="jessibuca-controls-playback-current-time-text">00:00:00</div>
                        </div>
                    </div>
                </div>
            </div>
            ${options.playbackConfig.showPrecisionBtn ? `
                <div class="jessibuca-controls-playback-btns">
                <div class="jessibuca-controls-item jessibuca-playback-narrow">${icons.narrow}</div>
                <div class="jessibuca-controls-item jessibuca-playback-expand">${icons.expand}</div>
            </div>
            ` : ''}
        </div>
    `;


    const playbackOperateSimpleDom = `
        <div class="jessibuca-control-progress-simple">
            <div class="jessibuca-control-progress-inner">
                <div class="jessibuca-progress-hover"></div>
                <div class="jessibuca-progress-played"></div>
                <div class="jessibuca-progress-indicator"></div>
                <div class="jessibuca-progress-tip">00:00</div>
            </div>
        </div>
    `;

    player.$container.insertAdjacentHTML(
        'beforeend',
        `
            ${(options.background) ? `<div class="jessibuca-poster" style="background-image: url(${options.background})"></div>` : ''}
            <div class="jessibuca-loading-bg">
                <img class="jessibuca-loading-bg-image" src="" alt="">
            </div>
            ${options.loadingIcon ? `
            <div class="jessibuca-loading">
                ${icons.loading}
                <div class="jessibuca-loading-text">${options.loadingText}</div>
            </div>
            ` : ''}
            ${options.hasControl && operateBtns.play ? `<div class="jessibuca-play-big"></div>` : ''}
            ${options.hasControl && operateBtns.ptz ? `
            <div class="jessibuca-ptz-controls">
                <div class="jessibuca-ptz-bg-active"></div>
                <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-up" data-arrow="up"></div>
                <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-right" data-arrow="right"></div>
                <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-down" data-arrow="down"></div>
                <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-left" data-arrow="left"></div>
                ${options.ptzMoreArrowShow ? `
                    <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-left-up" data-arrow="left-up"></div>
                    <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-right-up" data-arrow="right-up"></div>
                    <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-left-down" data-arrow="left-down"></div>
                    <div class="jessibuca-ptz-arrow jessibuca-ptz-arrow-right-down" data-arrow="right-down"></div>
                    ` : ''}
                <div class="jessibuca-ptz-control"></div>
                <div class="jessibuca-ptz-btns">
                    ${options.ptzZoomShow ? `
                            <div class="jessibuca-ptz-btn">
                                <div class="jessibuca-ptz-expand jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-expand-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">缩放+</span>
                                    </span>
                                </div>
                                <div class="jessibuca-ptz-narrow jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-narrow-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">缩放-</span>
                                    </span>
                                </div>
                            </div>
                            ` : ''}
                    ${options.ptzApertureShow ? `
                            <div class="jessibuca-ptz-btn">
                                <div class="jessibuca-ptz-aperture-far jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-aperture-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">光圈+</span>
                                    </span>
                                </div>
                                <div class="jessibuca-ptz-aperture-near jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-aperture-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">光圈-</span>
                                    </span>
                                </div>
                            </div>
                            ` : ''}
                    ${options.ptzFocusShow ? `
                            <div class="jessibuca-ptz-btn">
                                <div class="jessibuca-ptz-focus-far jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-focus-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">聚焦+</span>
                                    </span>
                                </div>
                                <div class="jessibuca-ptz-focus-near jessibuca-ptz-icon">
                                    <i class="jessibuca-ptz-focus-icon"></i>
                                    <span class="icon-title-tips">
                                        <span class="icon-title">聚焦-</span>
                                    </span>
                                </div>
                            </div>
                            ` : ''}
                </div>
            </div>
            ` : ''}
           ${options.hasVideo ? `
            <div class="jessibuca-zoom-controls">
                <div class="jessibuca-zoom-narrow">${icons.narrow}</div>
                <div class="jessibuca-zoom-tips">电子放大</div>
                <div class="jessibuca-zoom-expand">${icons.expand}</div>
                <div class="jessibuca-zoom-stop2">${icons.zoomStop}</div>
            </div>
            <div class="jessibuca-recording">
                <div class="jessibuca-recording-red-point"></div>
                <div class="jessibuca-recording-time">00:00:00</div>
                <div class="jessibuca-recording-stop">${icons.recordStop}</div>
            </div>
           ` : ''}

            ${options.hasControl ? `
                <div class="jessibuca-controls">
                    <div class="jessibuca-controls-bottom">
                        <div class="jessibuca-controls-left">
                            ${options.showBandwidth ? `<div class="jessibuca-controls-item jessibuca-speed"></div>` : ''}
                            <div class="jessibuca-playback-control-time"></div>
                            <div class="jessibuca-controls-item-html">${options.controlHtml}</div>
                        </div>
                        ${(options.playType === PLAY_TYPE.playbackTF && options.playbackConfig.showControl && options.playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.normal) ? playbackOperateDom : ''}
                        ${(options.playType === PLAY_TYPE.playbackTF && options.playbackConfig.showControl && options.playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.simple) ? playbackOperateSimpleDom : ''}
                        <div class="jessibuca-controls-right">
                            ${(options.playType === PLAY_TYPE.playbackTF && options.playbackConfig.showRateBtn) ? `
                                 <div class="jessibuca-controls-item-wrap">
                                    <div class="jessibuca-controls-item jessibuca-speed-menu">
                                        <div class="jessibuca-speed-icon-text"></div>
                                        <div class="jessibuca-speed-menu-list"></div>
                                    </div>
                                 </div>
                             ` : ''}
                             ${operateBtns.close ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-close">${icons.close}</div></div>` : ''}
                             ${operateBtns.logSave ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-logSave">${icons.logSave}</div></div>` : ''}
                             ${operateBtns.performance ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-performance">${icons.performance}</div><div class="jessibuca-controls-item jessibuca-performance-active">${icons.performanceActive}</div></div>` : ''}
                             ${operateBtns.aiFace ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-face">${icons.face}</div><div class="jessibuca-controls-item jessibuca-face-active">${icons.faceActive}</div></div>` : ''}
                             ${operateBtns.aiObject ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-object">${icons.object}</div><div class="jessibuca-controls-item jessibuca-object-active">${icons.objectActive}</div></div>` : ''}
                             ${operateBtns.aiOcclusion ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-occlusion">${icons.occlusion}</div><div class="jessibuca-controls-item jessibuca-occlusion-active">${icons.occlusionActive}</div></div>` : ''}
                             ${operateBtns.quality ? `
                                 <div class="jessibuca-controls-item-wrap">
                                    <div class="jessibuca-controls-item jessibuca-quality-menu">
                                        <div class="jessibuca-quality-icon-text"></div>
                                        <div class="jessibuca-quality-menu-list"></div>
                                    </div>
                                 </div>
                             ` : ''}
                             ${operateBtns.scale ? `
                                 <div class="jessibuca-controls-item-wrap">
                                    <div class="jessibuca-controls-item jessibuca-scale-menu">
                                        <div class="jessibuca-scale-icon-text"></div>
                                        <div class="jessibuca-scale-menu-list"></div>
                                     </div>
                                 </div>
                             ` : ''}
                             ${operateBtns.audio ? `
                                <div class="jessibuca-controls-item-wrap">
                                    <div class="jessibuca-controls-item jessibuca-volume">
                                         ${icons.audio}
                                         ${icons.mute}
                                         <div class="jessibuca-volume-panel-wrap">
                                              <div class="jessibuca-volume-panel">
                                                     <div class="jessibuca-volume-panel-handle"></div>
                                              </div>
                                              <div class="jessibuca-volume-panel-text"></div>
                                         </div>
                                     </div>
                                </div>
                             ` : ''}
                             ${operateBtns.play ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-play">${icons.play}</div><div class="jessibuca-controls-item jessibuca-pause">${icons.pause}</div></div>` : ''}
                             ${operateBtns.screenshot ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-screenshot">${icons.screenshot}</div></div>` : ''}
                             ${operateBtns.record ? ` <div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-record">${icons.record}</div><div class="jessibuca-controls-item jessibuca-record-stop">${icons.recordStop}</div></div>` : ''}
                             ${operateBtns.ptz ? ` <div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-ptz">${icons.ptz}</div><div class="jessibuca-controls-item jessibuca-ptz-active">${icons.ptzActive}</div></div>` : ''}
                             ${operateBtns.zoom ? ` <div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-zoom">${icons.zoom}</div><div class="jessibuca-controls-item jessibuca-zoom-stop">${icons.zoomStop}</div></div>` : ''}
                             ${operateBtns.fullscreen ? `<div class="jessibuca-controls-item-wrap"><div class="jessibuca-controls-item jessibuca-fullscreen">${icons.fullscreen}</div><div class="jessibuca-controls-item jessibuca-fullscreen-exit">${icons.fullscreenExit}</div></div>` : ''}
                        </div>
                    </div>
                </div>
            ` : ''}
             <div class="jessibuca-performance-panel"></div>
             <div class="jessibuca-contextmenus"></div>
             <div class="jessibuca-tips-message">
                <div class="jessibuca-tips-message-close">
                    <div class="jessibuca-tips-message-close-icon"></div>
                </div>
                <div class="jessibuca-tips-message-content"></div>
            </div>
        `
    )

    Object.defineProperty(control, '$poster', {
        value: player.$container.querySelector('.jessibuca-poster'),
    });

    Object.defineProperty(control, '$loadingBg', {
        value: player.$container.querySelector('.jessibuca-loading-bg'),
    });
    Object.defineProperty(control, '$loadingBgImage', {
        value: player.$container.querySelector('.jessibuca-loading-bg-image'),
    });

    Object.defineProperty(control, '$loading', {
        value: player.$container.querySelector('.jessibuca-loading'),
    });

    Object.defineProperty(control, '$loadingText', {
        value: player.$container.querySelector('.jessibuca-loading-text'),
    });


    Object.defineProperty(control, '$play', {
        value: player.$container.querySelector('.jessibuca-play'),
    });

    Object.defineProperty(control, '$playBig', {
        value: player.$container.querySelector('.jessibuca-play-big'),
    });

    Object.defineProperty(control, '$recording', {
        value: player.$container.querySelector('.jessibuca-recording'),
    });
    Object.defineProperty(control, '$recordingTime', {
        value: player.$container.querySelector('.jessibuca-recording-time'),
    });

    Object.defineProperty(control, '$recordingStop', {
        value: player.$container.querySelector('.jessibuca-recording-stop'),
    });

    Object.defineProperty(control, '$pause', {
        value: player.$container.querySelector('.jessibuca-pause'),
    });

    Object.defineProperty(control, '$controls', {
        value: player.$container.querySelector('.jessibuca-controls'),
    });

    Object.defineProperty(control, '$controlsInner', {
        value: player.$container.querySelector('.jessibuca-controls-bottom'),
    });
    Object.defineProperty(control, '$controlsLeft', {
        value: player.$container.querySelector('.jessibuca-controls-left'),
    });

    Object.defineProperty(control, '$controlsRight', {
        value: player.$container.querySelector('.jessibuca-controls-right'),
    });

    Object.defineProperty(control, '$volume', {
        value: player.$container.querySelector('.jessibuca-volume'),
    });

    Object.defineProperty(control, '$volumePanelWrap', {
        value: player.$container.querySelector('.jessibuca-volume-panel-wrap'),
    });

    Object.defineProperty(control, '$volumePanelText', {
        value: player.$container.querySelector('.jessibuca-volume-panel-text'),
    });

    Object.defineProperty(control, '$volumePanel', {
        value: player.$container.querySelector('.jessibuca-volume-panel'),
    });

    Object.defineProperty(control, '$volumeHandle', {
        value: player.$container.querySelector('.jessibuca-volume-panel-handle'),
    });

    Object.defineProperty(control, '$volumeOn', {
        value: player.$container.querySelector('.jessibuca-icon-audio'),
    });

    Object.defineProperty(control, '$volumeOff', {
        value: player.$container.querySelector('.jessibuca-icon-mute'),
    });


    Object.defineProperty(control, '$fullscreen', {
        value: player.$container.querySelector('.jessibuca-fullscreen'),
    });

    Object.defineProperty(control, '$fullscreenExit', {
        value: player.$container.querySelector('.jessibuca-fullscreen-exit'),
    });

    Object.defineProperty(control, '$record', {
        value: player.$container.querySelector('.jessibuca-record'),
    });
    Object.defineProperty(control, '$recordStop', {
        value: player.$container.querySelector('.jessibuca-record-stop'),
    });

    Object.defineProperty(control, '$screenshot', {
        value: player.$container.querySelector('.jessibuca-screenshot'),
    });

    Object.defineProperty(control, '$speed', {
        value: player.$container.querySelector('.jessibuca-speed'),
    });

    Object.defineProperty(control, '$controlHtml', {
        value: player.$container.querySelector('.jessibuca-controls-item-html'),
    });

    Object.defineProperty(control, '$playbackTime', {
        value: player.$container.querySelector('.jessibuca-controls-playback-time'),
    });

    Object.defineProperty(control, '$playbackTimeInner', {
        value: player.$container.querySelector('.jessibuca-controls-playback-time-inner'),
    });

    Object.defineProperty(control, '$playbackTimeScroll', {
        value: player.$container.querySelector('.jessibuca-controls-playback-time-scroll'),
    });

    Object.defineProperty(control, '$playbackTimeList', {
        value: player.$container.querySelector('.jessibuca-controls-playback-time-list')
    })

    Object.defineProperty(control, '$playbackTimeListOne', {
        value: player.$container.querySelector('.jessibuca-playback-time-one-wrap')
    })

    Object.defineProperty(control, '$playbackTimeListSecond', {
        value: player.$container.querySelector('.jessibuca-playback-time-second-wrap')
    })

    Object.defineProperty(control, '$playbackCurrentTime', {
        value: player.$container.querySelector('.jessibuca-controls-playback-current-time')
    })


    Object.defineProperty(control, '$playbackCurrentTimeText', {
        value: player.$container.querySelector('.jessibuca-controls-playback-current-time-text')
    })

    Object.defineProperty(control, '$controlsPlaybackBtns', {
        value: player.$container.querySelector('.jessibuca-controls-playback-btns')
    })

    Object.defineProperty(control, '$playbackNarrow', {
        value: player.$container.querySelector('.jessibuca-playback-narrow')
    })

    //
    Object.defineProperty(control, '$playbackExpand', {
        value: player.$container.querySelector('.jessibuca-playback-expand')
    })

    Object.defineProperty(control, '$ptz', {
        value: player.$container.querySelector('.jessibuca-ptz'),
    });

    Object.defineProperty(control, '$ptzActive', {
        value: player.$container.querySelector('.jessibuca-ptz-active'),
    });

    Object.defineProperty(control, '$ptzControl', {
        value: player.$container.querySelector('.jessibuca-ptz-controls'),
    });

    Object.defineProperty(control, '$ptzBgActive', {
        value: player.$container.querySelector('.jessibuca-ptz-bg-active'),
    });

    Object.defineProperty(control, '$ptzControlCircular', {
        value: player.$container.querySelector('.jessibuca-ptz-control'),
    });

    Object.defineProperty(control, '$ptzArrows', {
        value: player.$container.querySelectorAll('.jessibuca-ptz-arrow'),
    });

    Object.defineProperty(control, '$ptzExpand', {
        value: player.$container.querySelector('.jessibuca-ptz-expand'),
    });

    Object.defineProperty(control, '$ptzNarrow', {
        value: player.$container.querySelector('.jessibuca-ptz-narrow'),
    });

    Object.defineProperty(control, '$ptzApertureFar', {
        value: player.$container.querySelector('.jessibuca-ptz-aperture-far'),
    });

    Object.defineProperty(control, '$ptzApertureNear', {
        value: player.$container.querySelector('.jessibuca-ptz-aperture-near'),
    });

    Object.defineProperty(control, '$ptzFocusFar', {
        value: player.$container.querySelector('.jessibuca-ptz-focus-far'),
    });

    Object.defineProperty(control, '$ptzFocusNear', {
        value: player.$container.querySelector('.jessibuca-ptz-focus-near'),
    });

    Object.defineProperty(control, '$qualityText', {
        value: player.$container.querySelector('.jessibuca-quality-icon-text'),
    });

    Object.defineProperty(control, '$qualityMenu', {
        value: player.$container.querySelector('.jessibuca-quality-menu'),
    });

    Object.defineProperty(control, '$qualityMenuList', {
        value: player.$container.querySelector('.jessibuca-quality-menu-list'),
    });

    Object.defineProperty(control, '$scaleText', {
        value: player.$container.querySelector('.jessibuca-scale-icon-text'),
    });

    Object.defineProperty(control, '$scaleMenu', {
        value: player.$container.querySelector('.jessibuca-scale-menu'),
    });

    Object.defineProperty(control, '$scaleMenuList', {
        value: player.$container.querySelector('.jessibuca-scale-menu-list'),
    });

    Object.defineProperty(control, '$zoom', {
        value: player.$container.querySelector('.jessibuca-zoom'),
    });
    Object.defineProperty(control, '$zoomStop', {
        value: player.$container.querySelector('.jessibuca-zoom-stop'),
    });

    Object.defineProperty(control, '$zoomNarrow', {
        value: player.$container.querySelector('.jessibuca-zoom-narrow'),
    });

    Object.defineProperty(control, '$zoomExpand', {
        value: player.$container.querySelector('.jessibuca-zoom-expand'),
    });

    Object.defineProperty(control, '$zoomStop2', {
        value: player.$container.querySelector('.jessibuca-zoom-stop2'),
    });

    Object.defineProperty(control, '$close', {
        value: player.$container.querySelector('.jessibuca-close'),
    });


    Object.defineProperty(control, '$zoomControls', {
        value: player.$container.querySelector('.jessibuca-zoom-controls'),
    });

    Object.defineProperty(control, '$performancePanel', {
        value: player.$container.querySelector('.jessibuca-performance-panel'),
    });
    Object.defineProperty(control, '$performance', {
        value: player.$container.querySelector('.jessibuca-performance'),
    });
    Object.defineProperty(control, '$performanceActive', {
        value: player.$container.querySelector('.jessibuca-performance-active'),
    });

    Object.defineProperty(control, '$faceDetect', {
        value: player.$container.querySelector('.jessibuca-face'),
    })

    Object.defineProperty(control, '$faceDetectActive', {
        value: player.$container.querySelector('.jessibuca-face-active'),
    })

    Object.defineProperty(control, '$objectDetect', {
        value: player.$container.querySelector('.jessibuca-object'),
    })

    Object.defineProperty(control, '$objectDetectActive', {
        value: player.$container.querySelector('.jessibuca-object-active'),
    })

    Object.defineProperty(control, '$occlusionDetect', {
        value: player.$container.querySelector('.jessibuca-occlusion'),
    })

    Object.defineProperty(control, '$occlusionDetectActive', {
        value: player.$container.querySelector('.jessibuca-occlusion-active'),
    })


    Object.defineProperty(control, '$contextmenus', {
        value: player.$container.querySelector('.jessibuca-contextmenus'),
    });

    Object.defineProperty(control, '$speedText', {
        value: player.$container.querySelector('.jessibuca-speed-icon-text'),
    });

    Object.defineProperty(control, '$speedMenu', {
        value: player.$container.querySelector('.jessibuca-speed-menu'),
    });

    Object.defineProperty(control, '$speedMenuList', {
        value: player.$container.querySelector('.jessibuca-speed-menu-list'),
    });

    Object.defineProperty(control, '$logSave', {
        value: player.$container.querySelector('.jessibuca-logSave'),
    });

    Object.defineProperty(control, '$playbackProgress', {
        value: player.$container.querySelector('.jessibuca-control-progress-simple'),
    });

    Object.defineProperty(control, '$playbackProgressTip', {
        value: player.$container.querySelector('.jessibuca-progress-tip'),
    });

    Object.defineProperty(control, '$playbackProgressHover', {
        value: player.$container.querySelector('.jessibuca-progress-hover'),
    });

    Object.defineProperty(control, '$playbackProgressPlayed', {
        value: player.$container.querySelector('.jessibuca-progress-played'),
    });

    Object.defineProperty(control, '$playbackProgressIndicator', {
        value: player.$container.querySelector('.jessibuca-progress-indicator'),
    });

    Object.defineProperty(control, '$playbackProgressTime', {
        value: player.$container.querySelector('.jessibuca-playback-control-time'),
    });

    Object.defineProperty(control, '$tipsMessage', {
        value: player.$container.querySelector('.jessibuca-tips-message'),
    });

    Object.defineProperty(control, '$tipsMessageClose', {
        value: player.$container.querySelector('.jessibuca-tips-message-close'),
    });

    Object.defineProperty(control, '$tipsMessageContent', {
        value: player.$container.querySelector('.jessibuca-tips-message-content'),
    });

}
