import Emitter from "../utils/emitter";
import Hls from "hls.js";
import {canPlayAppleMpegurl, isIOS, now, isSafari, supportVideoFrameCallback, isFalse, isFunction} from "../utils";
import {
    EVENTS,
    MSE_DELAY_INCREASE_TIME,
    MSE_MAX_DELAY_TIME,
    VIDEO_ELEMENT_EVENTS,
    VIDEO_ENC_CODE,
    VIDEO_ENC_TYPE
} from "../constant";

export default class HlsDecoder extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        const _config = player._opt;
        this.canVideoPlay = false;
        this.$videoElement = null;
        this.canvasRenderInterval = null;
        this.bandwidthEstimateInterval = null;
        this.fpsInterval = null;
        this.hlsFps = 0;
        this.hlsPrevFrams = 0
        this.isInitInfo = false;
        this.eventsDestroy = [];
        this.supportVideoFrameCallbackHandle = null;
        // 支持原生的hls 播放。
        if (this.player.isHlsCanVideoPlay()) {
            this.$videoElement = this.player.video.$videoElement;
            this.canVideoPlay = true;
        } else if (Hls.isSupported()) {
            this.$videoElement = this.player.video.$videoElement;
            this.hls = new Hls({
                // levelLoadingTimeOut: _config.loadingTimeout * 1000,
                // levelLoadingMaxRetry: _config.loadingTimeoutReplayTimes,
                // maxBufferHole: 1,//
                // maxBufferLength: 2,

            });
            this._initHls();
            this._bindEvents();
        } else {
            this.player.debug.error('HlsDecoder', 'init hls error ,not support ');
        }

        this.player.debug.log('HlsDecoder', 'init');
    }

    destroy() {
        return new Promise((resolve, reject) => {
            if (this.supportVideoFrameCallbackHandle && this.$videoElement) {
                this.$videoElement.cancelVideoFrameCallback(this.supportVideoFrameCallbackHandle);
                this.supportVideoFrameCallbackHandle = null;
            }

            if (this.hls) {
                this.hls.destroy();
                this.hls = null;
            }
            if (this.eventsDestroy.length) {
                this.eventsDestroy.forEach(event => event());
                this.eventsDestroy = [];
            }
            this.isInitInfo = false;
            this._stopCanvasRender();
            this._stopBandwidthEstimateInterval();
            this._stopFpsInterval()
            this.$videoElement = null;
            this.hlsFps = 0;
            this.player.debug.log('HlsDecoder', 'destroy');
            setTimeout(() => {
                resolve();
            }, 0)
        })

    }

    checkHlsBufferedDelay() {
        const $video = this.$videoElement;
        let result = 0;
        const ranges = $video.buffered;
        const buffered = ranges.length ? ranges.end(ranges.length - 1) : 0;
        result = buffered - $video.currentTime;
        if (result < 0) {
            this.player.debug.warn('HlsDecoder', 'checkHlsBufferedDelay result < 0', result, buffered, $video.currentTime);
            result = 0;
        }

        return result;
    }

    getFps() {
        return this.hlsFps;
    }

    _startCanvasRender() {
        if (supportVideoFrameCallback()) {
            this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
        } else {

            this._stopCanvasRender();
            this.canvasRenderInterval = setInterval(() => {
                this.player.video.render({
                    $video: this.$videoElement,
                    ts: parseInt(this.$videoElement.currentTime * 1000, 10) || 0
                })
            }, 1000 / 25);
        }
    }

    _stopCanvasRender() {
        if (this.canvasRenderInterval) {
            clearInterval(this.canvasRenderInterval);
            this.canvasRenderInterval = null;
        }
    }

    videoFrameCallback(now, metaData = {}) {
        if (this.player.isDestroyed()) {
            this.player.debug.log('HlsDecoder', 'videoFrameCallback() player is destroyed');
            return;
        }
        const ts = parseInt((Math.max(metaData.mediaTime, this.$videoElement.currentTime)) * 1000, 10) || 0;

        this.player.video.render({
            $video: this.$videoElement,
            ts
        })
        this.player.handleRender();
        this.player.updateStats({
            dts: ts
        })

        this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
    }

    _startBandwidthEstimateInterval() {
        this._stopBandwidthEstimateInterval();
        this.bandwidthEstimateInterval = setInterval(() => {
            let bandwidthEstimate = 0;
            if (this.hls.bandwidthEstimate) {
                bandwidthEstimate = this.hls.bandwidthEstimate
            }
            // todo: 感觉计算的有问题：(byteLen * 8 * 1000) / 1024
            this.player.emit(EVENTS.kBps, (bandwidthEstimate / 1024 / 8 / 10).toFixed(2));
        }, 1 * 1000)
    }

    _stopBandwidthEstimateInterval() {
        if (this.bandwidthEstimateInterval) {
            clearInterval(this.bandwidthEstimateInterval);
            this.bandwidthEstimateInterval = null;
        }
    }

    _startFpsInterval() {
        this._stopCanvasRender();
        this.fpsInterval = setInterval(() => {
            if (this.$videoElement) {
                if (isFunction(this.$videoElement.getVideoPlaybackQuality)) {
                    const videoPlaybackQuality = this.$videoElement.getVideoPlaybackQuality();
                    this.hlsFps = videoPlaybackQuality.totalVideoFrames - this.hlsPrevFrams;
                    this.hlsPrevFrams = videoPlaybackQuality.totalVideoFrames;
                } else {
                    const totalVideoFrames = this.$videoElement.webkitDecodedFrameCount || 0;
                    this.hlsFps = totalVideoFrames - this.hlsPrevFrams;
                    this.hlsPrevFrams = totalVideoFrames;
                }
            }
        }, 1 * 1000);
    }

    _stopFpsInterval() {
        if (this.fpsInterval) {
            clearInterval(this.fpsInterval);
            this.fpsInterval = null;
        }
    }


    _initHls() {
        if (this.player._opt.useCanvasRender) {
            this.$videoElement = document.createElement('video');
            this.$videoElement.muted = true;
            if (isSafari()) {
                this.$videoElement.style.position = 'absolute';
            }
            this.initVideoEvents();
            // 这里需要监听事件
        }
        this.hls.attachMedia(this.$videoElement)
    }

    _bindEvents() {
        const player = this.player;
        const {proxy} = this.player.events;
        const hls = this.hls;
        const $videoElement = this.$videoElement;
        const isSupportVideoFrameCallback = supportVideoFrameCallback();


        const timeUpdateDestroy = proxy($videoElement, VIDEO_ELEMENT_EVENTS.timeUpdate, (event) => {
            if (this.hls) {
                const timestamp = parseInt(event.timeStamp, 10);
                if (this.player._opt.useCanvasRender &&
                    isFalse(isSupportVideoFrameCallback)) {
                    player.updateStats({ts: timestamp, dts: timestamp});
                }
            }
        })

        this.eventsDestroy.push(timeUpdateDestroy);

        this._startBandwidthEstimateInterval();

        this._startFpsInterval();

        // error
        this.hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        this.player.debug.warn('HlsDecoder', 'fatal network error encountered, try to recover');
                        // 应调用以恢复网络错误。
                        this.hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        this.player.debug.warn('HlsDecoder', 'fatal media error encountered, try to recover');
                        // 应调用以恢复媒体错误。
                        this.hls.recoverMediaError();
                        break;
                    default:
                        // cannot recover
                        // this.hls.destroy();
                        // todo:
                        break;
                }
            }
        });

        // 貌似没触发。。。
        this.hls.on(Hls.Events.MEDIA_ATTACHING, () => {
            // this.player.debug.log('HlsDecoder', 'MEDIA_ATTACHING');
        })

        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            // this.player.debug.log('HlsDecoder', 'MEDIA_ATTACHED');
        })

        // 在媒体元素分解MediaSource之前被解雇
        this.hls.on(Hls.Events.MEDIA_DETACHING, () => {
            // this.player.debug.log('HlsDecoder', 'MEDIA_DETACHING');
        })

        // 当MediaSource已经从媒体元素分离时触发
        this.hls.on(Hls.Events.MEDIA_DETACHED, () => {
            // this.player.debug.log('HlsDecoder', 'MEDIA_DETACHED');
        })
        this.hls.on(Hls.Events.BUFFER_RESET, () => {
            // this.player.debug.log('HlsDecoder', 'BUFFER_RESET');
        })
        this.hls.on(Hls.Events.BUFFER_CODECS, () => {
            // this.player.debug.log('HlsDecoder', 'BUFFER_CODECS');
        })

        this.hls.on(Hls.Events.BUFFER_CREATED, () => {
            // this.player.debug.log('HlsDecoder', 'BUFFER_CREATED');
        })

        // buffer appending
        this.hls.on(Hls.Events.BUFFER_APPENDING, (event, payload) => {
            this.player.debug.log('HlsDecoder', 'BUFFER_APPENDING', payload.type);
        })
        this.hls.on(Hls.Events.BUFFER_APPENDED, () => {
            // this.player.debug.log('HlsDecoder', 'BUFFER_APPENDED');
        })

        this.hls.on(Hls.Events.BUFFER_EOS, () => {
            // this.player.debug.log('HlsDecoder', 'fired when the stream is finished and we want to notify the media buffer that there will be no more data');
        })

        this.hls.on(Hls.Events.BUFFER_FLUSHING, () => {
            // this.player.debug.log('HlsDecoder', 'fired when the media buffer should be flushed');
        })

        this.hls.on(Hls.Events.BUFFER_FLUSHED, () => {
            // this.player.debug.log('HlsDecoder', 'fired when the media buffer has been flushed');
        })

        // 开始加载playlist m3u8资源
        this.hls.on(Hls.Events.MANIFEST_LOADING, () => {
            this.player.debug.log('HlsDecoder', 'MANIFEST_LOADING 开始加载playlist m3u8资源');
        })
        // playlist m3u8文件加载完成
        this.hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
            this.player.debug.log('HlsDecoder', 'MANIFEST_LOADED playlist m3u8文件加载完成', data.url);

        })
        // playlist m3u8解析完成
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.player.debug.log('HlsDecoder', 'MANIFEST_PARSED playlist m3u8解析完成');
            // 模拟 demux 时间
            if (!player._times.demuxStart) {
                player._times.demuxStart = now();
            }
        })

        // 加载特定码率的m3u8文件
        this.hls.on(Hls.Events.LEVEL_LOADING, () => {
            // this.player.debug.log('HlsDecoder', 'LEVEL_LOADING 加载特定码率的m3u8文件');
        })
        // 特定码率的m3u8文件解析完成，拿到该码率对应的ts列表
        this.hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
            // this.player.debug.log('HlsDecoder', 'LEVEL_LOADED 特定码率的m3u8文件解析完成，拿到该码率对应的ts列表');
        })
        //  开始加载某个ts分片文件，开始根据ts片下载时间预估带宽
        this.hls.on(Hls.Events.FRAG_LOADING, () => {
            // this.player.debug.log('HlsDecoder', 'FRAG_LOADING 开始加载某个ts分片文件，开始根据ts片下载时间预估带宽');
        })

        // ts分片文件加载成功，开始转码
        this.hls.on(Hls.Events.FRAG_LOADED, (event, payload) => {
            // this.player.debug.log('HlsDecoder', 'FRAG_LOADED ts分片文件加载成功，开始转码');
            // 模拟 decode 时间
            if (!player._times.decodeStart) {
                player._times.decodeStart = now();
            }

            // this.player.debug.log('HlsDecoder', 'FRAG_LOADED', payload);
            // const frag = payload.frag || {}
            // const stats = payload.stats || {}
            // const buffering = stats.buffering || {};
            // this.player.debug.log('HlsDecoder', 'FRAG_LOADED buffering.end', buffering, buffering.end);
            // if (buffering.end) {
            //     this.player.updateStats({
            //         dts: buffering.end
            //     })
            // }
        })

        // 视频流赋给video标签
        this.hls.on(Hls.Events.BUFFER_APPENDING, () => {
            // this.player.debug.log('HlsDecoder', 'BUFFER_APPENDING 视频流赋给video标签');
            if (!player._times.videoStart) {
                player._times.videoStart = now();
                player.handlePlayToRenderTimes()
            }
        })

        this.hls.on(Hls.Events.FRAG_DECRYPTED, () => {
            // this.player.debug.log('HlsDecoder', 'FRAG_DECRYPTED fired when a fragment decryption is completed');
        })

        this.hls.on(Hls.Events.KEY_LOADING, () => {
            // this.player.debug.log('HlsDecoder', 'KEY_LOADING fired when a decryption key loading starts');
        })

        this.hls.on(Hls.Events.KEY_LOADING, () => {
            // this.player.debug.log('HlsDecoder', 'KEY_LOADING fired when a fragment decryption is completed');
        })

        this.hls.on(Hls.Events.FPS_DROP, (data) => {
            // this.player.debug.log('HlsDecoder', 'FPS_DROP', data);
        })
        this.hls.on(Hls.Events.FPS_DROP_LEVEL_CAPPING, (data) => {
            // this.player.debug.log('HlsDecoder', 'FPS_DROP_LEVEL_CAPPING', data);
        })

        // fired when Init Segment has been extracted from fragment
        this.hls.on(Hls.Events.FRAG_PARSING_INIT_SEGMENT, (id, payload) => {
            this.player.debug.log('HlsDecoder', 'FRAG_PARSING_INIT_SEGMENT', payload);
            const hasAudio = (payload && payload.tracks && payload.tracks.audio) ? true : false;
            const hasVideo = (payload && payload.tracks && payload.tracks.video) ? true : false;
            if (hasAudio && payload.tracks.audio) {
                let track = payload.tracks.audio;
                const audioChannelCount = (track.metadata && track.metadata.channelCount) ? track.metadata.channelCount : 0;
                const audioCodec = track.codec;
                this.player.audio && this.player.audio.updateAudioInfo({
                    encType: audioCodec,
                    channels: audioChannelCount,
                    sampleRate: 44100 // default audioContext.sampleRate
                })
            }

            if (hasVideo && payload.tracks.video) {
                let track = payload.tracks.video;
                let videoCodec = track.codec;
                const info = {
                    encTypeCode: videoCodec.indexOf('avc') !== -1 ? VIDEO_ENC_CODE.h264 : VIDEO_ENC_CODE.h265,
                }
                if (track.metadata) {
                    info.width = track.metadata.width
                    info.height = track.metadata.height
                }
                this.player.video && this.player.video.updateVideoInfo(info)
            }
        })
    }


    initVideoPlay(url) {
        if (this.player._opt.useCanvasRender) {
            this.$videoElement = document.createElement('video');
            this.initVideoEvents();
        }
        this.$videoElement.autoplay = true;
        this.$videoElement.muted = true;
        this.$videoElement.src = url;
    }

    _initRenderSize() {
        if (!this.isInitInfo) {
            this.player.video.updateVideoInfo({
                width: this.$videoElement.videoWidth,
                height: this.$videoElement.videoHeight
            })
            this.player.video.initCanvasViewSize();
            this.isInitInfo = true;
        }
    }

    //
    initVideoEvents() {
        const {proxy} = this.player.events;
        const canPlayDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.canplay, () => {
            this.player.debug.log('HlsDecoder', 'video canplay');
            this.$videoElement.play().then(() => {
                this.player.debug.log('HlsDecoder', 'video play');
                this._startCanvasRender();
                this._initRenderSize();
            }).catch((e) => {
                this.player.debug.warn('HlsDecoder', 'video play error ', e);
            });
        })

        const waitingDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.waiting, () => {
            // this.player.emit(EVENTS.videoWaiting);
            this.player.debug.log('HlsDecoder', 'video waiting');
        })

        const timeUpdateDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.timeUpdate, (event) => {
            const timeStamp = parseInt(event.timeStamp, 10);
            this.player.handleRender();
            this.player.updateStats({ts: timeStamp})
            // this.player.emit(EVENTS.videoTimeUpdate, timeStamp);
            // check video is playing
            if (this.$videoElement.paused) {
                this.player.debug.warn('HlsDecoder', 'video is paused and next try to replay',);
                this.$videoElement.play().then(() => {
                    this.player.debug.log('HlsDecoder', 'video is paused and replay success');
                }).catch((e) => {
                    this.player.debug.warn('HlsDecoder', 'video is paused and replay error ', e);
                })
            }
        })

        const rateChangeDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.ratechange, () => {
            this.player.debug.log('HlsDecoder', 'video playback Rate change', this.$videoElement && this.$videoElement.playbackRate)
        })

        this.eventsDestroy.push(canPlayDestroy, waitingDestroy, timeUpdateDestroy, rateChangeDestroy);
    }

    loadSource(url) {
        return new Promise((resolve, reject) => {
            if (this.canVideoPlay) {
                this.initVideoPlay(url);
                resolve();
            } else {
                this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {

                    this.hls.loadSource(url)
                    // this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    //     resolve()
                    // })
                    resolve()
                })
            }
        })
    }
}
