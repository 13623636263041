import {getTarget, isPc, isTrue, throttle} from "../utils";
import {includeFromEvent} from "../utils/dom";
import {EVENTS, PLAYER_RESIZE_TIME} from "../constant";

export default (player) => {

    const {
        _opt,
        debug,
        events: {proxy},
    } = player;


    if (_opt.supportDblclickFullscreen) {
        proxy(player.$container, 'dblclick', (e) => {
            const target = getTarget(e);
            const nodeName = target.nodeName.toLowerCase();
            if (nodeName === 'canvas' || nodeName === 'video') {
                player.fullscreen = !player.fullscreen;
            }
        })
    }


    //
    proxy(document, 'visibilitychange', () => {
        player.visibility = "visible" === document.visibilityState;
        debug.log('visibilitychange', document.visibilityState)
        if (_opt.hiddenAutoPause) {
            debug.log('visibilitychange', 'hiddenAutoPause is true ', document.visibilityState, player._isPlayingBeforePageHidden)
            if ("visible" === document.visibilityState) {
                if (player._isPlayingBeforePageHidden) {
                    player.play();
                }
            } else {
                player._isPlayingBeforePageHidden = player.playing;
                // hidden
                if (player.playing) {
                    player.pause();
                }
            }
        }
    })

    if (isPc()) {
        proxy(document, ['click', 'contextmenu'], (e) => {
            if (includeFromEvent(e, player.$container)) {
                // is contextmenu
                if (isTrue(player._opt.disableContextmenu) &&
                    e.type === 'contextmenu') {
                    e.preventDefault();
                }
                player.isInput = e.target.tagName === 'INPUT';
                player.isFocus = true;
                player.emit(EVENTS.focus);
            } else {
                player.isInput = false;
                player.isFocus = false;
                player.emit(EVENTS.blur);
            }
        })
    }

    if (_opt.autoResize) {
        const resizeFn = throttle(() => {
            player.resize();
        }, PLAYER_RESIZE_TIME);

        proxy(window, ['resize', 'orientationchange'], () => {
            resizeFn();
        });

        // check screen orientation change
        if (screen && screen.orientation && screen.orientation.onchange) {
            proxy(screen.orientation, 'change', () => {
                resizeFn();
            });
        }
    }
}
