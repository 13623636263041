import {mat4, vec3} from 'gl-matrix'

export default class WebGpuRender {
    constructor(gpu) {
        this.gpu = gpu;
        this.pipeline = null;
        this.matrixGroupInfo = null;
        this.depthTexture = null;
        this.textureGroupInfo = null;
        this.hasInited = false;
        this.buffers = this._initBuffer();
        this._initPipeline().then((pipeline) => {
            this.pipeline = pipeline;
            this.matrixGroupInfo = this._initMatrixGroupInfo();
            this.hasInited = true;
        })
    }

    destroy() {
        if (this.gpu) {
            this.gpu.device.destroy();
            this.gpu = null;
        }
        this.hasInited = false;
        this.pipeline = null;
        this.matrixGroupInfo = null;
        this.depthTexture = null;
        this.textureGroupInfo = null;
    }


    _initBuffer() {
        const device = this.gpu.device;
        //顶点
        const positions = new Float32Array([
            // Front face
            -1.0, -1.0, -1.0,
            1.0, -1.0, -1.0,
            1.0, 1.0, -1.0,
            -1.0, 1.0, -1.0,

        ]);

        const positionBuffer = device.createBuffer({
            size: positions.byteLength,
            usage: window.GPUBufferUsage.VERTEX | window.GPUBufferUsage.COPY_DST
        })

        device.queue.writeBuffer(positionBuffer, 0, positions)

        //纹理顶点
        const texturePos = new Float32Array([
            // Front face
            0.0, 1.0,
            1.0, 1.0,
            1.0, 0.0,
            0.0, 0.0
        ]);

        const texpositionBuffer = device.createBuffer({
            size: texturePos.byteLength,
            usage: window.GPUBufferUsage.VERTEX | window.GPUBufferUsage.COPY_DST
        })

        device.queue.writeBuffer(texpositionBuffer, 0, texturePos)

        //索引
        const indices = new Uint16Array([
            // Front face
            0, 1, 2, 0, 2, 3

        ]);

        const indexBuffer = device.createBuffer({
            size: indices.byteLength,
            usage: window.GPUBufferUsage.INDEX | window.GPUBufferUsage.COPY_DST

        })

        device.queue.writeBuffer(indexBuffer, 0, indices)

        return {
            positionBuffer,
            texpositionBuffer,
            indexBuffer
        };
    }

    _initPipeline() {
        return new Promise((resolve, reject) => {
            const device = this.gpu.device;
            const format = this.gpu.format;
            const vsSource = `

                  @binding(0) @group(0) var<uniform> uModelMatrix : mat4x4<f32>;
                  @binding(1) @group(0) var<uniform> uViewMatrix : mat4x4<f32>;
                  @binding(2) @group(0) var<uniform> uProjectionMatrix : mat4x4<f32>;

                  struct VertexOutput {
                    @builtin(position) Position : vec4<f32>,
                    @location(0) vTexturePosition : vec2<f32>,
                  }

                  @vertex
                  fn main(
                    @location(0) aVertexPosition : vec4<f32>,
                    @location(1) aTexturePosition : vec2<f32>
                  ) -> VertexOutput {
                    var output : VertexOutput;
                    var tmppos : vec4<f32> = uProjectionMatrix * uViewMatrix * uModelMatrix * aVertexPosition;
                    output.Position = vec4<f32>(tmppos.x, tmppos.y, (tmppos.z+1.)/2., tmppos.w);  // webgl z [-1, 1],  webgpu z [0, 1],  这里z做下调整 z-webgpu = (z-webgl+1)/2
                    output.vTexturePosition = aTexturePosition;
                    return output;
                  }

                `;
            // Fragment shader program
            const fsSource = `
                @group(1) @binding(0) var mySampler: sampler;
                @group(1) @binding(1) var yTexture: texture_2d<f32>;
                @group(1) @binding(2) var uTexture: texture_2d<f32>;
                @group(1) @binding(3) var vTexture: texture_2d<f32>;

                const YUV2RGB : mat4x4<f32> = mat4x4<f32>( 1.1643828125, 0, 1.59602734375, -.87078515625,
                                                         1.1643828125, -.39176171875, -.81296875, .52959375,
                                                         1.1643828125, 2.017234375, 0, -1.081390625,
                                                         0, 0, 0, 1);

                @fragment
                fn main(
                  @location(0) vTexturePosition: vec2<f32>
                ) -> @location(0) vec4<f32> {

                    var y : f32= textureSample(yTexture, mySampler, vTexturePosition).r;
                    var u : f32 = textureSample(uTexture,  mySampler, vTexturePosition).r;
                    var v : f32 = textureSample(vTexture, mySampler, vTexturePosition).r;

                    return  vec4<f32>(y, u, v, 1.0)*YUV2RGB;
                }

                `;

            const descriptor = {
                layout: 'auto',
                vertex: {
                    module: device.createShaderModule({
                        code: vsSource
                    }),
                    entryPoint: 'main',
                    buffers: [{
                        arrayStride: 3 * 4,
                        attributes: [{
                            shaderLocation: 0,
                            offset: 0,
                            format: 'float32x3'
                        }]
                    }, {
                        arrayStride: 2 * 4,
                        attributes: [{
                            shaderLocation: 1,
                            offset: 0,
                            format: 'float32x2'
                        }]
                    }]
                },
                primitive: {
                    topology: 'triangle-list'
                },
                fragment: {
                    module: device.createShaderModule({
                        code: fsSource
                    }),
                    entryPoint: 'main',
                    targets: [
                        {
                            format: format
                        }
                    ]
                },
                depthStencil: {
                    depthWriteEnabled: true,
                    depthCompare: 'less',
                    format: 'depth24plus',
                }
            }
            device.createRenderPipelineAsync(descriptor).then((pipeline) => {
                resolve(pipeline);
            }).catch((e) => {
                reject(e);
            })
        })
    }


    _initMatrixGroupInfo() {
        const device = this.gpu.device;
        const pipeline = this.pipeline;
        const zNear = 0.1;
        const zFar = 100.0;
        const projectionMatrix = mat4.create();
        mat4.ortho(projectionMatrix, -1, 1, -1, 1, zNear, zFar);

        const modelMatrix = mat4.create();
        mat4.identity(modelMatrix);

        const viewMatrix = mat4.create();
        mat4.lookAt(viewMatrix, vec3.fromValues(0, 0, 0), vec3.fromValues(0, 0, -1), vec3.fromValues(0, 1, 0));

        const modelMatrixBuffer = device.createBuffer({
            size: 4 * 4 * 4,
            usage: window.GPUBufferUsage.UNIFORM | window.GPUBufferUsage.COPY_DST
        })

        device.queue.writeBuffer(modelMatrixBuffer, 0, modelMatrix)

        const viewMatrixBuffer = device.createBuffer({
            size: 4 * 4 * 4,
            usage: window.GPUBufferUsage.UNIFORM | window.GPUBufferUsage.COPY_DST
        })

        device.queue.writeBuffer(viewMatrixBuffer, 0, viewMatrix)

        const projectMatrixBuffer = device.createBuffer({
            size: 4 * 4 * 4,
            usage: window.GPUBufferUsage.UNIFORM | window.GPUBufferUsage.COPY_DST
        })

        device.queue.writeBuffer(projectMatrixBuffer, 0, projectionMatrix)

        const group = device.createBindGroup({
            label: 'group0',
            layout: pipeline.getBindGroupLayout(0),
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: modelMatrixBuffer
                    }
                },
                {
                    binding: 1,
                    resource: {
                        buffer: viewMatrixBuffer
                    }
                },
                {
                    binding: 2,
                    resource: {
                        buffer: projectMatrixBuffer
                    }
                }
            ]
        })

        return {modelMatrixBuffer, viewMatrixBuffer, projectMatrixBuffer, group};
    }

    _initTextureGroupInfo(width, height) {
        const device = this.gpu.device;
        const pipeline = this.pipeline;
        const yTexture = device.createTexture({
            size: [width, height],
            format: 'r8unorm',
            usage: window.GPUTextureUsage.TEXTURE_BINDING | window.GPUTextureUsage.COPY_DST | window.GPUTextureUsage.RENDER_ATTACHMENT
        })

        const uTexture = device.createTexture({
            size: [width / 2, height / 2],
            format: 'r8unorm',
            usage: window.GPUTextureUsage.TEXTURE_BINDING | window.GPUTextureUsage.COPY_DST | window.GPUTextureUsage.RENDER_ATTACHMENT
        })

        const vTexture = device.createTexture({
            size: [width / 2, height / 2],
            format: 'r8unorm',
            usage: window.GPUTextureUsage.TEXTURE_BINDING | window.GPUTextureUsage.COPY_DST | window.GPUTextureUsage.RENDER_ATTACHMENT
        })

        const sampler = device.createSampler({
            magFilter: 'linear',
            minFilter: 'linear'
        })

        const group = device.createBindGroup({
            label: 'group1',
            layout: pipeline.getBindGroupLayout(1),
            entries: [
                {
                    binding: 0,
                    resource: sampler
                },
                {
                    binding: 1,
                    resource: yTexture.createView()
                },
                {
                    binding: 2,
                    resource: uTexture.createView()
                },
                {
                    binding: 3,
                    resource: vTexture.createView()
                }
            ]
        })

        return {yTexture, uTexture, vTexture, group};
    }

    _drawScene() {
        const device = this.gpu.device;
        const context = this.gpu.context;
        const commandEncoder = device.createCommandEncoder();
        const view = context.getCurrentTexture().createView();
        const renderPassDescriptor = {
            colorAttachments: [
                {
                    view: view,
                    clearValue: {r: 0, g: 0, b: 0, a: 0.0},
                    loadOp: 'clear',
                    storeOp: 'store'
                }
            ],
            depthStencilAttachment: {
                view: this.depthTexture.createView(),
                depthClearValue: 1.0,
                depthLoadOp: 'clear',
                depthStoreOp: 'store',
            }
        }
        const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor)
        passEncoder.setPipeline(this.pipeline)

        passEncoder.setBindGroup(0, this.matrixGroupInfo.group)
        passEncoder.setBindGroup(1, this.textureGroupInfo.group)

        passEncoder.setVertexBuffer(0, this.buffers.positionBuffer)
        passEncoder.setVertexBuffer(1, this.buffers.texpositionBuffer)

        passEncoder.setIndexBuffer(this.buffers.indexBuffer, 'uint16');
        passEncoder.drawIndexed(6);

        passEncoder.end()

        device.queue.submit([commandEncoder.finish()])
    }

    renderYUV(width, height, data) {
        if (!this.hasInited) {
            return;
        }
        let y = data.slice(0, width * height);
        let u = data.slice(width * height, width * height * 5 / 4);
        let v = data.slice(width * height * 5 / 4, width * height * 3 / 2);
        const device = this.gpu.device;

        if (!this.depthTexture) {
            this.depthTexture = this.gpu.device.createTexture({
                size: [width, height],
                format: 'depth24plus',
                usage: window.GPUTextureUsage.RENDER_ATTACHMENT,
            });
        }

        if (!this.textureGroupInfo) {
            this.textureGroupInfo = this._initTextureGroupInfo(width, height);
        }

        device.queue.writeTexture({texture: this.textureGroupInfo.yTexture}, y, {
            bytesPerRow: width,
            rowsPerImage: height
        }, [width, height]);
        device.queue.writeTexture({texture: this.textureGroupInfo.uTexture}, u, {
            bytesPerRow: width / 2,
            rowsPerImage: height / 2
        }, [width / 2, height / 2]);
        device.queue.writeTexture({texture: this.textureGroupInfo.vTexture}, v, {
            bytesPerRow: width / 2,
            rowsPerImage: height / 2
        }, [width / 2, height / 2]);
        this._drawScene();
    }

    clear() {

    }
}
