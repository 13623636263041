import Decryptor from './decryptor';
import Transmuxer from "./transmuxer";
import TsDemuxer from '../../demux/tsLoader';
import MP4Parser from "../../demux/mp4Parser";

export default class BufferService {


    constructor(hls) {
        this.hls = hls;
        this.player = hls.player;
        this._decryptor = new Decryptor(this.hls,this.player);
        /** @type {Transmuxer} */
        this._transmuxer = null
        /** @type {MSE} */
        this._mse = null
        this._softVideo = null
        this._sourceCreated = false
        this._needInitSegment = true
        this._directAppend = false
        this.TAG_NAME = 'HlsBufferService'
    }

    async destroy() {
        this._softVideo = null
        if (this._transmuxer) {
            this._transmuxer.destroy();
            this._transmuxer = null;
        }
    }

    get baseDts() {
        return this._transmuxer?._demuxer?._baseDts
    }

    get nbSb() {
        return 0;
    }

    async updateDuration(duration) {
        this.player.debug.log(this.TAG_NAME, 'updateDuration()', duration);
    }

    getBuferredDuration() {
        return this._transmuxer?._demuxer?.getBuferredDuration();
    }

    getBufferedSegments() {
        return this._transmuxer?._demuxer?.getSampleListLength();
    }

    getBufferedAudioSegments() {
        return this._transmuxer?._demuxer?.getSampleAudioListLength();
    }

    getBufferedVideoSegments() {
        return this._transmuxer?._demuxer?.getSampleVideoListLength();
    }

    // create source
    createSource(videoChunk, audioChunk, videoCodec, audioCodec) {
        if (this._sourceCreated) return
        const chunk = videoChunk || audioChunk
        if (!chunk) return
        if (TsDemuxer.probe(chunk)) {
            if (!this._transmuxer) {
                this._transmuxer = new Transmuxer(this.hls, false)
            }
        } else if (MP4Parser.probe(chunk)) {
            if (!this._transmuxer) {
                this._transmuxer = new Transmuxer(this.hls, true)
            }
        } else {
            this.player.debug.error(this.TAG_NAME, 'createSource error: chunk is not ts');
        }
    }

    async appendBuffer(segment, audioSegment, videoChunk, audioChunk, discontinuity, contiguous, startTime) {
        if (!videoChunk?.length && !audioChunk?.length) return

        const needInit = this._needInitSegment || discontinuity
        this._transmuxer.transmux(videoChunk, audioChunk, discontinuity, contiguous, startTime, this._needInitSegment || discontinuity)

        return true;
    }


    async clearAllBuffer() {
        this.player.debug.log(this.TAG_NAME, 'clearAllBuffer');
        // if (this._mse) {
        //     return this._mse.clearAllBuffer()
        // }
    }

    decryptBuffer(video, audio) {
        return this._decryptor.decrypt(video, audio)
    }

    async reset(reuseMse = false) {
        this._transmuxer = null
        this._needInitSegment = true
        this._directAppend = false
    }

    async endOfStream() {
        if (this._softVideo) {
            this._softVideo.endOfStream()
        }
    }

    async setLiveSeekableRange(start, end) {

    }


    /**
     * This makes it possible to change codecs or container type mid-stream.
     * @private
     */

    seamlessSwitch() {
        this._needInitSegment = true
    }
}
