import Emitter from "../utils/emitter";
import {isSafari, noop, supportVideoFrameCallback} from "../utils";
import {EVENTS, EVENTS_ERROR, VIDEO_ELEMENT_EVENTS} from "../constant";

export default class CommonWebrtc extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.TAG_NAME = 'CommonWebrtc';
        this.rtcPeerConnection = null;
        this.videoStream = null;
        this.isDisconnected = false;
        this.isH264 = this.player.isWebrtcH264();
        this.eventsDestroy = [];
        this.supportVideoFrameCallbackHandle = null;
        this.isInitInfo = false;
        this.$videoElement = this.player.video.$videoElement;
        this.bandwidthEstimateInterval = null;
        this.rtcPeerTrackReceiver = null;
        if (this.player._opt.webrtcUseCanvasRender &&
            this.isH264) {
            this.$videoElement = document.createElement('video');
            if (isSafari()) {
                this.$videoElement.style.position = 'absolute';
            }
            this._initVideoEvents();
        }
        //  default muted
        this.$videoElement.muted = true;
        this._initRtcPeerConnection();
    }

    destroy() {
        this.isDisconnected = false;
        this.isInitInfo = false;
        this.rtcPeerTrackReceiver = null;
        this._stopBandwidthEstimateInterval();

        if (this.supportVideoFrameCallbackHandle && this.$videoElement) {
            this.$videoElement.cancelVideoFrameCallback(this.supportVideoFrameCallbackHandle);
            this.supportVideoFrameCallbackHandle = null;
        }

        if (this.eventsDestroy.length) {
            this.eventsDestroy.forEach(event => event());
            this.eventsDestroy = [];
        }

        if (this.isH264) {
            if (this.videoStream) {
                this.videoStream.getTracks().forEach(track => track.stop());
                this.videoStream = null;
            }
            this.$videoElement.srcObject = null;
        }

        if (this.rtcPeerConnection) {
            this.rtcPeerConnection.onicecandidate = noop;
            this.rtcPeerConnection.ontrack = noop;
            this.rtcPeerConnection.onconnectionstatechange = noop;
            this.rtcPeerConnection.ondatachannel = noop;
            this.rtcPeerConnection.close();
            this.rtcPeerConnection = null;
        }

    }


    _initVideoEvents() {
        const {proxy} = this.player.events;
        const canPlayDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.canplay, () => {
            this.player.debug.log(this.TAG_NAME, 'video canplay');
            this.$videoElement.play().then(() => {
                this.player.debug.log(this.TAG_NAME, 'video play');
                this._startCanvasRender();
                this._initRenderSize();
            }).catch((e) => {
                this.player.debug.warn(this.TAG_NAME, 'video play error ', e);
            });
        })

        const waitingDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.waiting, () => {
            // this.player.emit(EVENTS.videoWaiting);
            this.player.debug.log('HlsDecoder', 'video waiting');
        })

        const timeUpdateDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.timeUpdate, (event) => {
            const timeStamp = parseInt(event.timeStamp, 10);
            this.player.handleRender();
            this.player.updateStats({ts: timeStamp})
            // this.player.emit(EVENTS.videoTimeUpdate, timeStamp);
            // check video is playing
            if (this.$videoElement.paused) {
                this.player.debug.warn('HlsDecoder', 'video is paused and next try to replay',);
                this.$videoElement.play().then(() => {
                    this.player.debug.log('HlsDecoder', 'video is paused and replay success');
                }).catch((e) => {
                    this.player.debug.warn('HlsDecoder', 'video is paused and replay error ', e);
                })
            }
        })

        const rateChangeDestroy = proxy(this.$videoElement, VIDEO_ELEMENT_EVENTS.ratechange, () => {
            this.player.debug.log('HlsDecoder', 'video playback Rate change', this.$videoElement && this.$videoElement.playbackRate)
        })

        this.eventsDestroy.push(canPlayDestroy, waitingDestroy, timeUpdateDestroy, rateChangeDestroy);

    }

    _initRtcPeerConnection() {
        const rtcPeerConnection = new RTCPeerConnection();
        const player = this.player;
        rtcPeerConnection.addTransceiver("video", {
            direction: "recvonly",
        });
        rtcPeerConnection.addTransceiver("audio", {
            direction: "recvonly",
        });

        rtcPeerConnection.onsignalingstatechange = (e) => {
            this.player.debug.log(this.TAG_NAME, 'onsignalingstatechange', e);
        };

        // ICE代理的状态及其与ICE服务器（STUN、TURN）的连接
        rtcPeerConnection.oniceconnectionstatechange = (e) => {
            this.player.debug.log(this.TAG_NAME,
                'oniceconnectionstatechange',
                rtcPeerConnection.iceConnectionState);
            const iceConnectionState = rtcPeerConnection.iceConnectionState;
            this.player.emit(EVENTS.webrtcOnIceConnectionStateChange, iceConnectionState);
            this.isDisconnected = iceConnectionState === 'disconnected';
            switch (rtcPeerConnection.iceConnectionState) {
                case "new":
                    //  建立ICE连接
                    break;
                case "checking":
                    //  收集候选
                    break;
                case "closed":
                    // 断开ICE
                    break;
                case "failed":
                    player.emit(EVENTS.webrtcFailed);
                    break;
                case "disconnected":
                    // 网络波动或者串流断开
                    player.emit(EVENTS.webrtcDisconnect);
                    break;
                case "connected":
                    //  匹配到可用候选
                    break;
                case "completed":
                    // 匹配完成，连接建立
                    break;
                case "closed":
                    player.emit(EVENTS.webrtcClosed);
                    break;
                default:
                    break;
            }
        };

        rtcPeerConnection.onicecandidate = (event) => {
            this.player.debug.log(this.TAG_NAME, 'onicecandidate', event);

            if (event.candidate) {
                this.player.debug.log(this.TAG_NAME, 'Remote ICE candidate: ', event.candidate.candidate);
                // Send the candidate to the remote peer
            } else {
                // All ICE candidates have been sent
            }

        };

        rtcPeerConnection.ontrack = (event) => {
            this.player.debug.log(this.TAG_NAME, 'ontrack', event);
            if (event.track.kind === 'video') {
                this.rtcPeerTrackReceiver = rtcPeerConnection.getReceivers().find(function (receiver) {
                    return receiver.track === event.track;
                });

                if (this.rtcPeerTrackReceiver) {
                    this._startBandwidthEstimateInterval();
                }
                this.player.debug.log(this.TAG_NAME, 'ontrack audio');
                let videoStream = event.streams[0];
                this.$videoElement.autoplay = true;
                this.$videoElement.srcObject = videoStream;
                this.videoStream = videoStream;
            }
        }


        rtcPeerConnection.onicecandidateerror = (event) => {
            this.player.debug.log(this.TAG_NAME, 'onicecandidateerror', event);
            this.player.emitError(EVENTS_ERROR.webrtcIceCandidateError, event);
        }

        //  rtc ice所有传输组件(RTCIceTransport or RTCDtlsTransport(底层传输)类型)的聚合状态
        rtcPeerConnection.onconnectionstatechange = (event) => {
            this.player.debug.log(this.TAG_NAME, 'onconnectionstatechange', event);
            this.player.emit(EVENTS.webrtcOnConnectionStateChange, rtcPeerConnection.connectionState)

            switch (rtcPeerConnection.connectionState) {
                case "new":
                    //  至少有一个的ICE传输组件（RTICETransport或RTCDTLTransport对象）处于new状态，
                    break;
                case "connecting":
                    //  一个或多个ICE传输组件目前正在建立连接；
                    break;
                case "connected":
                    //  至少有一个ICE传输组件connected或completed状态
                    // 所有ICE连接要么在使用中（connected或completed），要么closed；
                    break;

                case "disconnected":
                    // 至少一个ICE传输组件处于断开状态，
                    // 其他都不是failed、connecting或checking状态
                    break;
                case "failed":
                    // ICE传输组件处于failed状态.
                    if (this.isDisconnected) {
                        player.emit(EVENTS.webrtcFailed);
                    }
                    break;
                case "closed":
                    // RTCPeerConnection关闭
                    break;
                default:
                    break;
            }
        }

        this.rtcPeerConnection = rtcPeerConnection;
    }

    _startBandwidthEstimateInterval() {
        this._stopBandwidthEstimateInterval();
        this.bandwidthEstimateInterval = setInterval(() => {
            this.rtcPeerTrackReceiver.getStats().then((stats) => {
                // let bandwidthEstimate = 0;
                // if(stats.bitrate){
                //     bandwidthEstimate = stats.bitrate;
                // }
                // this.player.emit(EVENTS.kBps, (bandwidthEstimate / 1024 / 8 / 10).toFixed(2));

                stats.forEach((report) => {
                    console.error('report', report);
                })

            })
        }, 1 * 1000)
    }

    _stopBandwidthEstimateInterval() {
        if (this.bandwidthEstimateInterval) {
            clearInterval(this.bandwidthEstimateInterval);
            this.bandwidthEstimateInterval = null;
        }
    }

    _startCanvasRender() {
        if (supportVideoFrameCallback()) {
            this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
        } else {
            this._stopCanvasRender();
            this.canvasRenderInterval = setInterval(() => {
                this.player.video.render({
                    $video: this.$videoElement,
                    ts: 0
                })
            }, 1000 / 25);
        }
    }

    _stopCanvasRender() {
        if (this.canvasRenderInterval) {
            clearInterval(this.canvasRenderInterval);
            this.canvasRenderInterval = null;
        }
    }

    videoFrameCallback(now, metaData = {}) {
        if (this.player.isDestroyed()) {
            this.player.debug.log(this.TAG_NAME, 'videoFrameCallback() player is destroyed');
            return;
        }

        this.player.video.render({
            $video: this.$videoElement,
            ts: metaData.mediaTime || 0
        })
        this.player.updateStats({
            dts: metaData.mediaTime || 0
        })

        this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
    }


    _initRenderSize() {
        if (!this.isInitInfo) {
            this.player.video.updateVideoInfo({
                width: this.$videoElement.videoWidth,
                height: this.$videoElement.videoHeight
            })
            this.player.video.initCanvasViewSize();
            this.isInitInfo = true;
        }
    }

    getVideoCurrentTime() {
        let result = 0;
        if (this.$videoElement) {
            result = this.$videoElement.currentTime;
        }
        return result;
    }
}
