import {EVENTS, EVENTS_ERROR, PLAYER_STREAM_TYPE} from "../constant";
import Emitter from "../utils/emitter";
import {calculationRate, now} from "../utils";

export default class HlsLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        player.debug.log('HlsStream', 'init');
    }

    destroy() {
        this.off();
        this.player.debug.log('HlsStream', 'destroy');
    }

    fetchStream(url) {
        const {hlsDecoder, debug} = this.player;
        this.player._times.streamStart = now();
        hlsDecoder.loadSource(url).then(() => {
            this.player.debug.log('HlsStream', 'loadSource success');
            this.emit(EVENTS.streamSuccess);
        }).catch((error) => {
            this.emit(EVENTS_ERROR.hlsError, error);
        });
    }

    getStreamType() {
        return PLAYER_STREAM_TYPE.hls;
    }
}
