import AudioContextLoader from "./audioContextLoader";
import {EVENTS} from "../constant";

export default class AudioPlaybackLoader extends AudioContextLoader {
    constructor(player) {
        super(player);
        this.delayTimeout = null;

        this.player.on(EVENTS.playbackPause, (flag) => {
            this.listenPlaybackPause(flag);
        })
        this.player.debug.log('AudioPlaybackContext', 'init');
    }

    destroy() {
        if (this.delayTimeout) {
            clearTimeout(this.delayTimeout);
            this.delayTimeout = null;
        }
        super.destroy();
        this.player.debug.log(`AudioPlaybackLoader`, 'destroy');
    }

    listenPlaybackPause(flag) {
        if (flag) {
            this.pause();
            if (this.player.playback.isPlaybackPauseClearCache) {
                this.clear();
            }
        } else {
            this.resume();
        }
    }

    //
    initScriptNodeDelay() {
        const delayTime = this.player._opt.playbackDelayTime
        if (delayTime > 0) {
            this.delayTimeout = setTimeout(() => {
                this.initScriptNode();
            }, delayTime)
        } else {
            this.initScriptNode();
        }
    }


    setRate(value) {
        if (value !== this.defaultPlaybackRate && this.rateProcessor) {
            this.player.debug.log('AudioPlaybackContext', 'setRate', value);
            this.defaultPlaybackRate = value;
            this.updatePlaybackRate(value);
        }
    }
}
