import RecordRTCLoader from "./recordRTCLoader";
import MP4RecorderLoader from "./mp4Loader";
import FlvRecorderLoader from './flvLoader';
import WasmMp4Loader from "./wasmMp4Loader";
import {FILE_SUFFIX, RECORDING_TYPE} from "../constant";

export default class Recorder {
    constructor(player) {
        const Loader = Recorder.getLoaderFactory(player._opt);
        return new Loader(player);
    }

    static getLoaderFactory(opt) {
        if (opt.recordType === FILE_SUFFIX.mp4) {
            if (opt.useWasm || opt.useMSE || opt.useWCS) {
                if (window.JessibucaProMp4Recorder && opt.mp4RecordUseWasm) {
                    return WasmMp4Loader;
                }
                return MP4RecorderLoader;
            }
        } else if (opt.recordType === FILE_SUFFIX.flv) {
            return FlvRecorderLoader;
        }
        // default use RecordRTC
        return RecordRTCLoader;
    }
}
