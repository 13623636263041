import Emitter from "../utils/emitter";
import {
    EVENTS,
    PLAYBACK_CONTROL_TIME_PRECISION,
    PLAYBACK_CONTROL_TIME_PRECISION_ARRAY,
    PLAYBACK_CONTROL_TYPE
} from "../constant";
import {formatSecondTime, getStrLength, isFalse, isNotEmpty, isTrue, now, parseTime} from "../utils";

export default class Playback extends Emitter {
    constructor(player, config) {
        super();
        this.player = player
        this.player.$container.classList.add('jessibuca-container-playback');
        this._showPrecision = null;
        this._startTime = null;
        this._playStartTime = null;
        this._playingTimestamp = null;
        this._fps = parseInt(config.fps, 10) || player._opt.playbackFps;
        this._isUseFpsRender = isTrue(config.isUseFpsRender) ? true : false;
        this._rate = 1; // 播放倍率
        this._audioTimestamp = 0;
        this._videoTimestamp = 0;
        this.controlType = config.controlType || PLAYBACK_CONTROL_TYPE.normal;
        if (config.controlType &&
            [PLAYBACK_CONTROL_TYPE.normal, PLAYBACK_CONTROL_TYPE.simple].indexOf(config.controlType) === -1) {
            this.player.debug.warn('Playback', 'constructor()', 'controlType is not in [normal,simple]', config.controlType);
            this.controlType = PLAYBACK_CONTROL_TYPE.normal;
        }
        this._currentLocalTimestamp = 0;
        this._localOneFrameTimestamp = config.localOneFrameTimestamp || 40
        this._localCalculateTimeInterval = null;
        this._isUseLocalCalculateTime = isTrue(config.isUseLocalCalculateTime) ? true : false;
        this._isPlaybackPauseClearCache = isFalse(config.isPlaybackPauseClearCache) ? false : true;
        this._isCacheBeforeDecodeForFpsRender = isTrue(config.isCacheBeforeDecodeForFpsRender) ? true : false;
        this._startfpsTime = null;
        this._startFpsTimestamp = null;
        this._checkStatsInterval = null;
        this._playbackTs = 0;
        this._renderFps = 0;
        //
        if (this._isUseLocalCalculateTime) {
            this._startLocalCalculateTime();
        } else {
            this._listen();
        }
        //
        this.playbackList = [];
        this._playbackListStartTimestamp = null;
        this._totalDuration = 0;
        if (config.controlType === PLAYBACK_CONTROL_TYPE.normal) {
            this.initPlaybackList(config.playList, config.showPrecision, config.startTime);
        } else if (config.controlType === PLAYBACK_CONTROL_TYPE.simple) {
            // 单位 s
            if (config.duration) {
                //  变成 ms
                this._totalDuration = config.duration * 1000;
            }
            let startTime = config.startTime || 0;
            if (startTime > this.totalDuration) {
                startTime = this.totalDuration;
            }
            this.setStartTime(startTime);
        }


        this.player.on(EVENTS.playbackPause, (flag) => {
            if (flag) {
                this.pause();
            } else {
                this.resume();
            }
        })

        const initObj = {
            fps: this._fps,
            isUseFpsRender: this._isUseFpsRender,
            localOneFrameTimestamp: this._localOneFrameTimestamp,
            isUseLocalCalculateTime: this._isUseLocalCalculateTime,
            uiUsePlaybackPause: config.uiUsePlaybackPause,
            showControl: config.showControl,
        }

        player.debug.log('Playback', 'init', JSON.stringify(initObj));
    }


    destroy() {
        this._startTime = null;
        this._showPrecision = null;
        this._playStartTime = null;
        this._playingTimestamp = null;
        this._totalDuration = 0;
        this._audioTimestamp = 0;
        this._videoTimestamp = 0;
        this._fps = null;
        this._isUseFpsRender = false;
        this._rate = 1; // 播放倍率
        this.playbackList = [];
        this._playbackListStartTimestamp = null;
        this._localCalculateTimeInterval = null;
        this._currentLocalTimestamp = 0;
        this._startfpsTime = null;
        this._startFpsTimestamp = null;
        this._renderFps = 0;
        this._playbackTs = 0;
        this._stopLocalCalculateTime();
        this.clearStatsInterval();
        if (this.player.$container) {
            this.player.$container.classList.remove('jessibuca-container-playback');
        }
        this.off();
        this.player.debug.log('Playback', 'destroy')
    }

    _listen() {
        //
        this.player.on(EVENTS.stats, (stats) => {
            const timestamp = stats.ts;
            if (!this._playStartTime) {
                this._playStartTime = timestamp - 1000;
            }
            // todo：这了会存在一个问题
            let playingTimestamp = timestamp - this._playStartTime;
            this.setPlayingTimestamp(playingTimestamp);
        })
    }

    pause() {
        this.clearStatsInterval();
    }

    resume() {
        this.startCheckStatsInterval()
    }

    updateStats(options = {}) {

        if (!this._startFpsTimestamp) {
            this._startFpsTimestamp = now();
        }

        if (isNotEmpty(options.ts)) {
            this.player.updateStats({fps: true, ts: options.ts});
            this._playbackTs = options.ts;
            if (!this._startfpsTime) {
                this._startfpsTime = options.ts

            }
            this._renderFps += 1;
        }

        const _now = now();
        const timestamp = _now - this._startFpsTimestamp;
        // update fps
        if (timestamp < 1 * 1000) {
            return;
        }


        let dataTimestamp = null;
        if (this._startfpsTime) {
            dataTimestamp = this._playbackTs - this._startfpsTime;
        }

        this.player.emit(EVENTS.playbackStats, {
            fps: this._renderFps,
            rate: this.rate,
            start: this._startfpsTime,
            end: this._playbackTs,
            timestamp: timestamp,
            dataTimestamp: dataTimestamp,
            audioBufferSize: this.player.audio ? this.player.audio.bufferSize : 0,
            videoBufferSize: this.player.video ? this.player.video.bufferSize : 0,
            ts: this._playbackTs
        })
        this._renderFps = 0;
        this._startfpsTime = this._playbackTs;
        this._startFpsTimestamp = _now;
    }

    updateLocalOneFrameTimestamp(timestamp) {
        this._localOneFrameTimestamp = timestamp;
    }

    _startLocalCalculateTime() {
        this._stopLocalCalculateTime();
        this._localCalculateTimeInterval = setInterval(() => {
            const timestamp = this._currentLocalTimestamp;
            if (!this._playStartTime) {
                this._playStartTime = timestamp - 1000;
            }
            let playingTimestamp = timestamp - this._playStartTime;
            this.setPlayingTimestamp(playingTimestamp);
        }, 1000)
    }


    startCheckStatsInterval() {
        this.clearStatsInterval();
        this._checkStatsInterval = setInterval(() => {
            this.updateStats();
        }, 1000)
    }

    _stopLocalCalculateTime() {
        if (this._localCalculateTimeInterval) {
            clearInterval(this._localCalculateTimeInterval);
            this._localCalculateTimeInterval = null;
        }
    }

    clearStatsInterval() {
        if (this._checkStatsInterval) {
            clearInterval(this._checkStatsInterval);
            this._checkStatsInterval = null;
        }
    }

    //
    increaseLocalTimestamp() {
        if (this._isUseLocalCalculateTime) {
            // todo: 如果变成I帧倍率播放的时候，就会有问题。
            this._currentLocalTimestamp += this._localOneFrameTimestamp;
        }
    }

    //
    initPlaybackList(playList, showPrecision, startTime) {
        this.playbackList = playList || [];
        let totalDuration = 0;
        this.playbackList.forEach((playItem, index) => {
            if (getStrLength(playItem.start) === 10) {
                playItem.startTimestamp = playItem.start * 1000;
                playItem.startTime = parseTime(playItem.startTimestamp)
            }
            if (getStrLength(playItem.end) === 10) {
                playItem.endTimestamp = playItem.end * 1000;
                playItem.endTime = parseTime(playItem.endTimestamp)
            }
            playItem.duration = playItem.end - playItem.start;
            totalDuration += playItem.duration;
        })
        this._totalDuration = totalDuration;
        this.player.debug.log('Playback', this.playbackList)
        if (this.playbackList.length > 0) {
            const _startTimestamp = this.playbackList[0].startTimestamp;
            this._playbackListStartTimestamp = _startTimestamp;

            let _startTime = _startTimestamp;
            if (startTime) {
                if (getStrLength(startTime) === 10) {
                    startTime = startTime * 1000;
                }
                // 校验下startTime 是否在playList的范围内
                if (this._isTimeInPlaybackList(startTime)) {
                    _startTime = startTime;
                }
            }

            // 设置时分秒
            this.setStartTime(_startTime);
        }
        const _showPrecision = showPrecision || PLAYBACK_CONTROL_TIME_PRECISION.oneHour;
        // init...
        this.setShowPrecision(_showPrecision)
    }

    //  单位 s
    get totalDuration() {
        return (this._totalDuration || 0) / 1000;
    }


    get startTime() {
        return this._startTime || 0;
    }

    // normal:set start time 开始时间，默认是 某一天的00:00:00
    //  simple: ms打头
    setStartTime(time) {
        this._startTime = time;
        this._playingTimestamp = time;
        this._playStartTime = null;
    }

    setRate(rate) {
        this._rate = rate;
        this.player.emit(EVENTS.playbackRateChange, rate)
    }

    get fps() {
        return this._fps;
    }

    get rate() {
        return this._rate;
    }

    get isUseFpsRender() {
        return this._isUseFpsRender;
    }

    get isUseLocalCalculateTime() {
        return this._isUseLocalCalculateTime;
    }

    get showPrecision() {
        return this._showPrecision;
    }

    get is60Min() {
        return this.showPrecision === PLAYBACK_CONTROL_TIME_PRECISION.oneHour
    }

    get is30Min() {
        return this.showPrecision === PLAYBACK_CONTROL_TIME_PRECISION.halfHour
    }

    get is10Min() {
        return this.showPrecision === PLAYBACK_CONTROL_TIME_PRECISION.tenMin
    }

    get is5Min() {
        return this.showPrecision === PLAYBACK_CONTROL_TIME_PRECISION.fiveMin
    }

    get is1Min() {
        return this.showPrecision === PLAYBACK_CONTROL_TIME_PRECISION.fiveMin
    }

    get isPlaybackPauseClearCache() {
        return this._isPlaybackPauseClearCache;
    }

    get isCacheBeforeDecodeForFpsRender() {
        return this._isCacheBeforeDecodeForFpsRender;
    }

    setShowPrecision(type) {

        if (!PLAYBACK_CONTROL_TIME_PRECISION_ARRAY.includes(type)) {
            this.player.debug.warn('Playback', 'setShowPrecision()', 'type is not in PLAYBACK_CONTROL_TIME_PRECISION_ARRAY', type);
            type = PLAYBACK_CONTROL_TIME_PRECISION.oneHour;
        }

        if (this._showPrecision && this._showPrecision === type) {
            return;
        }

        this._showPrecision = type;
        this.player.emit(EVENTS.playbackPrecision, this._showPrecision, this.playbackList);
        this.player.emit(EVENTS.playbackShowPrecisionChange, this._showPrecision);
    }

    setPlayingTimestamp(ts) {
        let timestamp;
        if (this.controlType === PLAYBACK_CONTROL_TYPE.normal) {
            timestamp = this.startTime + ts;
            this._playingTimestamp = timestamp;
            this.player.emit(EVENTS.playbackTime, timestamp)
            const timeDay = new Date(timestamp);
            this.player.emit(EVENTS.playbackTimestamp, {
                ts: timestamp,
                hour: timeDay.getHours(),
                min: timeDay.getMinutes(),
                second: timeDay.getSeconds()
            })
        } else if (this.controlType === PLAYBACK_CONTROL_TYPE.simple) {
            // 四舍五入到秒
            timestamp = this.startTime + Math.round(ts / 1000);
            if (timestamp > this.totalDuration) {
                this.player.debug.log('Playback', 'setPlayingTimestamp()', `timestamp ${timestamp} > this.totalDuration ${this.totalDuration}`);
                timestamp = this.totalDuration;
            }
            this._playingTimestamp = timestamp;
            this.player.emit(EVENTS.playbackTime, timestamp)
            this.player.emit(EVENTS.playbackTimestamp, {
                ts: timestamp
            })
        }
    }


    get playingTimestamp() {
        return this._playingTimestamp;
    }

    /**
     *
     */
    narrowPrecision() {
        const index = PLAYBACK_CONTROL_TIME_PRECISION_ARRAY.indexOf(this.showPrecision);
        const prev = index - 1;
        if (prev >= 0) {
            const item = PLAYBACK_CONTROL_TIME_PRECISION_ARRAY[prev]
            this.setShowPrecision(item)
        }
    }

    /**
     *
     */
    expandPrecision() {
        const index = PLAYBACK_CONTROL_TIME_PRECISION_ARRAY.indexOf(this.showPrecision);
        const next = index + 1;
        if (next <= PLAYBACK_CONTROL_TIME_PRECISION_ARRAY.length - 1) {
            const item = PLAYBACK_CONTROL_TIME_PRECISION_ARRAY[next]
            this.setShowPrecision(item)
        }
    }

    /**
     *
     * @param obj
     */
    seek(obj) {
        this.player.debug.log('Playback', 'seek()', obj);

        if (this.controlType === PLAYBACK_CONTROL_TYPE.normal) {
            if (obj.hasRecord === 'true') {
                let seconds = obj.time;
                if (obj.type === 'min') {
                    seconds = obj.time * 60;
                }
                let result = formatSecondTime(seconds);
                if (this._playbackListStartTimestamp) {
                    const timestamp = new Date(this._playbackListStartTimestamp).setHours(result.hour, result.min, result.second, 0);
                    result.timestamp = timestamp;
                    const playbackObj = this._findMoreInfoByTimestamp(timestamp);
                    if (result && playbackObj.more) {
                        result.more = playbackObj.more;
                    }
                }
                this.player.emit(EVENTS.playbackSeek, result)
            }
        } else if (this.controlType === PLAYBACK_CONTROL_TYPE.simple) {
            let ts = obj.time;
            this.player.emit(EVENTS.playbackSeek, {ts});
        }
    }

    currentTimeScroll() {
        this.player.emit(EVENTS.playbackTimeScroll);
    }


    _findMoreInfoByTimestamp(timestamp) {
        let result = null;
        this.playbackList.forEach((item, index) => {
            if (item.startTimestamp <= timestamp && item.endTimestamp >= timestamp) {
                result = item;
            }
        })
        return result;
    }

    _isTimeInPlaybackList(timestamp) {
        let result = false;
        this.playbackList.forEach((item, index) => {
            if (item.startTimestamp <= timestamp && item.endTimestamp >= timestamp) {
                result = true;
            }
        })
        return result;
    }

    getControlType() {
        return this.controlType;
    }

    isControlTypeNormal() {
        return this.controlType === PLAYBACK_CONTROL_TYPE.normal;
    }

    isControlTypeSimple() {
        return this.controlType === PLAYBACK_CONTROL_TYPE.simple;
    }
}

