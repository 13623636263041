const REGEXP_TAG = /^#(EXT[^:]*)(?::(.*))?$/
const REGEXP_ATTR = /([^=]+)=(?:"([^"]*)"|([^",]*))(?:,|$)/g
const REGEXP_ABSOLUTE_URL = /^(?:[a-zA-Z0-9+\-.]+:)?\/\//
const REGEXP_URL_PAIR = /^((?:[a-zA-Z0-9+\-.]+:)?\/\/[^/?#]*)?([^?#]*\/)?/

function getLines(text) {
    return text.split(/[\r\n]/).map((x) => x.trim()).filter(Boolean)
}

function parseTag(text) {
    const ret = text.match(REGEXP_TAG)
    if (!ret || !ret[1]) return
    return [ret[1].replace('EXT-X-', ''), ret[2]]
}

function parseAttr(text) {
    const ret = {}
    let match = REGEXP_ATTR.exec(text)
    while (match) {
        ret[match[1]] = match[2] || match[3]
        match = REGEXP_ATTR.exec(text)
    }
    return ret
}

function getAbsoluteUrl(url, parentUrl) {
    if (!parentUrl || !url || REGEXP_ABSOLUTE_URL.test(url)) return url
    const pairs = REGEXP_URL_PAIR.exec(parentUrl)
    if (!pairs) return url
    if (url[0] === '/') return pairs[1] + url
    return pairs[1] + pairs[2] + url
}

const CODECS_REGEXP = {
    audio: [/^mp4a/, /^vorbis$/, /^opus$/, /^flac$/, /^[ae]c-3$/],
    video: [/^avc/, /^hev/, /^hvc/, /^vp0?[89]/, /^av1$/],
    text: [/^vtt$/, /^wvtt/, /^stpp/]
}

/**
 * @param {'audio' | 'video' | 'text'} type
 * @param {Array<string>} codecs
 * @returns {string | undefined}
 */
function getCodecs(type, codecs) {
    const re = CODECS_REGEXP[type]
    if (!re || !codecs || !codecs.length) return
    for (let i = 0; i < re.length; i++) {
        for (let j = 0; j < codecs.length; j++) {
            if (re[i].test(codecs[j])) return codecs[j]
        }
    }
}

class MasterPlaylist {

    constructor() {
        this.version = 0;
        this.streams = []; // MasterStream
        /**
         * @readonly
         */
        this.isMaster = true;
    }
}


const MediaType = {
    Audio: 'AUDIO',
    Video: 'VIDEO',
    SubTitle: 'SUBTITLE',
    ClosedCaptions: 'CLOSED-CAPTIONS'
}

class MediaStream {
    id = 0
    url = ''
    default = false
    autoSelect = false
    forced = false
    group = ''
    name = ''
    lang = ''
    segments = []
    endSN = 0
}

class AudioStream extends MediaStream {
    mediaType = MediaType.Audio
    channels = 0
}

class VideoStream extends MediaStream {
    mediaType = MediaType.Video
}

class SubTitleStream extends MediaStream {
    mediaType = MediaType.SubTitle
}

class ClosedCaptionsStream extends MediaStream {
    mediaType = MediaType.ClosedCaptions
}

class MasterStream {
    id = 0
    bitrate = 0
    width = 0
    height = 0
    name = ''
    url = ''
    audioCodec = ''
    videoCodec = ''
    textCodec = ''
    audioGroup = ''

    /** @type {AudioStream[]} */
    audioStreams = []

    /** @type {SubTitleStream[]} */
    subtitleStreams = []

    /** @type {ClosedCaptionsStream[]} */
    closedCaptionsStream = []
}

class MediaPlaylist {
    version = 0
    url = ''
    type = '' // upper case
    startCC = 0
    endCC = 0
    startSN = 0
    endSN = 0
    totalDuration = 0
    targetDuration = 0
    live = true
    /** @type {Array.<MediaSegment>} */
    segments = []
}

class MediaSegment {
    sn = 0 // Media Sequence Number
    cc = 0
    url = ''
    title = ''
    start = 0
    duration = 0
    /** @type {?MediaSegmentKey} */
    key = null
    byteRange = null // [start, end]
    isInitSegment = false
    /** @type {?MediaSegment} */
    initSegment = null
    isLast = false
    hasAudio = false
    hasVideo = false

    get end() {
        return this.start + this.duration
    }

    setTrackExist(v, a) {
        this.hasVideo = v
        this.hasAudio = a
    }

    setByteRange(data, prevSegment) {
        this.byteRange = [0]
        const bytes = data.split('@')
        if (bytes.length === 1 && prevSegment && prevSegment.byteRange) {
            this.byteRange[0] = prevSegment.byteRange[1] || 0
            if (this.byteRange[0]) this.byteRange[0] += 1
        } else {
            this.byteRange[0] = parseInt(bytes[1])
        }
        this.byteRange[1] = this.byteRange[0] + parseInt(bytes[0]) - 1
    }
}

class MediaSegmentKey {
    method = ''
    url = ''
    /** @type {?Uint8Array} */
    iv = null
    keyFormat = ''
    keyFormatVersions = ''

    constructor(segKey) {
        if (segKey instanceof MediaSegmentKey) {
            this.method = segKey.method
            this.url = segKey.url
            this.keyFormat = segKey.keyFormat
            this.keyFormatVersions = segKey.keyFormatVersions
            if (segKey.iv) this.iv = new Uint8Array(segKey.iv)
        }
    }

    clone(sn) {
        const key = new MediaSegmentKey(this)
        if (sn !== null && sn !== undefined) key.setIVFromSN(sn)
        return key
    }

    setIVFromSN(sn) {
        if (!this.iv && this.method === 'AES-128' && typeof sn === 'number' && this.url) {
            this.iv = new Uint8Array(16)
            for (let i = 12; i < 16; i++) {
                this.iv[i] = (sn >> (8 * (15 - i))) & 0xff
            }
        }
    }
}


// parse media play list
function parseMediaPlaylist(lines, parentUrl) {

    const media = new MediaPlaylist()

    media.url = parentUrl
    let curSegment = new MediaSegment()
    let curInitSegment = null
    let curKey = null
    let totalDuration = 0
    let curSN = 0
    let curCC = 0
    let index = 0
    let line
    let endOfList = false


    // eslint-disable-next-line no-cond-assign
    while (line = lines[index++]) {

        if (endOfList) {
            break
        }

        if (line[0] !== '#') { // url
            curSegment.sn = curSN
            curSegment.cc = curCC
            curSegment.url = getAbsoluteUrl(line, parentUrl)
            if (curKey) curSegment.key = curKey.clone(curSN)
            if (curInitSegment) curSegment.initSegment = curInitSegment
            media.segments.push(curSegment)
            curSegment = new MediaSegment()
            curSN++
            continue
        }

        const tag = parseTag(line)
        if (!tag) continue
        const [name, data] = tag

        switch (name) {
            case 'VERSION':
                media.version = parseInt(data)
                break
            case 'PLAYLIST-TYPE':
                media.type = data?.toUpperCase()
                break
            case 'TARGETDURATION':
                media.targetDuration = parseFloat(data)
                break
            case 'ENDLIST': {
                const lastSegment = media.segments[media.segments.length - 1]
                if (lastSegment) {
                    lastSegment.isLast = true
                }
                media.live = false
                endOfList = true
            }
                break
            case 'MEDIA-SEQUENCE':
                curSN = media.startSN = parseInt(data)
                break
            case 'DISCONTINUITY-SEQUENCE':
                curCC = media.startCC = parseInt(data)
                break
            case 'DISCONTINUITY':
                curCC++
                break
            case 'BYTERANGE':
                curSegment.setByteRange(data, media.segments[media.segments.length - 1])
                break
            case 'EXTINF': {
                const [duration, title] = data.split(',')
                curSegment.start = totalDuration
                curSegment.duration = parseFloat(duration)
                totalDuration += curSegment.duration
                curSegment.title = title
            }
                break
            case 'KEY': {
                const attr = parseAttr(data)
                if (attr.METHOD === 'NONE') {
                    curKey = null
                    break
                }
                if (attr.METHOD !== 'AES-128') throw new Error(`encrypt ${attr.METHOD}/${attr.KEYFORMAT} is not supported`)
                curKey = new MediaSegmentKey()
                curKey.method = attr.METHOD
                curKey.url = /^blob:/.test(attr.URI) ? attr.URI : getAbsoluteUrl(attr.URI, parentUrl)
                curKey.keyFormat = attr.KEYFORMAT || 'identity'
                curKey.keyFormatVersions = attr.KEYFORMATVERSIONS
                if (attr.IV) {
                    let str = attr.IV.slice(2)
                    str = (str.length & 1 ? '0' : '') + str
                    curKey.iv = new Uint8Array(str.length / 2)
                    for (let i = 0, l = str.length / 2; i < l; i++) {
                        curKey.iv[i] = parseInt(str.slice(i * 2, i * 2 + 2), 16)
                    }
                }
            }
                break
            case 'MAP': {
                const attr = parseAttr(data)
                curSegment.url = getAbsoluteUrl(attr.URI, parentUrl)
                if (attr.BYTERANGE) curSegment.setByteRange(attr.BYTERANGE)
                curSegment.isInitSegment = true
                curSegment.sn = 0
                if (curKey) {
                    curSegment.key = curKey.clone(0)
                }
                curInitSegment = curSegment
                curSegment = new MediaSegment()
            }
                break
            default:
        }
    }

    const lastSegment = media.segments[media.segments.length - 1]

    if (lastSegment) media.endSN = lastSegment.sn

    media.totalDuration = totalDuration
    media.endCC = curCC

    return media
}


/**
 * parse master play list
 * @param {Array<string>} lines
 * @param {string} parentUrl
 * @returns {MasterPlaylist}
 */
function parseMasterPlaylist(lines, parentUrl) {

    const master = new MasterPlaylist()

    let index = 0

    let line

    const audioStreams = []

    const subtitleStreams = []

    // eslint-disable-next-line no-cond-assign
    while (line = lines[index++]) {

        const tag = parseTag(line)

        if (!tag) continue
        const [name, data] = tag
        if (name === 'VERSION') {
            master.version = parseInt(data)
        } else if (name === 'MEDIA' && data) {
            const attr = parseAttr(data)
            let stream
            switch (attr.TYPE) {
                case 'AUDIO':
                    stream = new AudioStream()
                    break
                case 'SUBTITLES':
                    stream = new SubTitleStream()
                    break
                default:
                    stream = new MediaStream()
            }

            stream.url = getAbsoluteUrl(attr.URI, parentUrl)
            stream.default = attr.DEFAULT === 'YES'
            stream.autoSelect = attr.AUTOSELECT === 'YES'
            stream.group = attr['GROUP-ID']
            stream.name = attr.NAME
            stream.lang = attr.LANGUAGE
            if (attr.CHANNELS) {
                stream.channels = Number(attr.CHANNELS.split('/')[0])
                if (Number.isNaN(stream.channels)) stream.channels = 0
            }

            if (attr.TYPE === 'AUDIO' && attr.URI) {
                audioStreams.push(stream)
            }

            if (attr.TYPE === 'SUBTITLES') {
                subtitleStreams.push(stream)
            }

        } else if (name === 'STREAM-INF' && data) {
            const stream = new MasterStream()
            const attr = parseAttr(data)

            stream.bitrate = parseInt(attr['AVERAGE-BANDWIDTH'] || attr.BANDWIDTH)
            stream.name = attr.NAME
            stream.url = getAbsoluteUrl(lines[index++], parentUrl)
            if (attr.RESOLUTION) {
                const [w, h] = attr.RESOLUTION.split('x')
                stream.width = parseInt(w)
                stream.height = parseInt(h)
            }
            if (attr.CODECS) {
                const codecs = attr.CODECS.split(/[ ,]+/).filter(Boolean)
                stream.videoCodec = getCodecs('video', codecs)
                stream.audioCodec = getCodecs('audio', codecs)
                stream.textCodec = getCodecs('text', codecs)
            }
            stream.audioGroup = attr.AUDIO
            stream.subtitleGroup = attr.SUBTITLES

            master.streams.push(stream)
        }
    }
    master.streams.forEach((s, i) => {
        s.id = i
    })

    if (audioStreams.length) {
        audioStreams.forEach((s, i) => {
            s.id = i
        })
        master.streams.forEach((stream) => {
            if (stream.audioGroup) {
                stream.audioStreams = audioStreams.filter(x => x.group === stream.audioGroup)
            }
        })
    }

    if (subtitleStreams.length) {
        subtitleStreams.forEach((s, i) => {
            s.id = i
        })
        master.streams.forEach((stream) => {
            if (stream.subtitleGroup) {
                stream.subtitleStreams = subtitleStreams.filter(x => x.group === stream.subtitleGroup)
            }
        })
    }

    return master
}


export default class M3U8Parser {
    static parse(text = '', parentUrl) {
        if (!text.includes('#EXTM3U')) throw new Error('Invalid m3u8 file')

        const lines = getLines(text)

        if (M3U8Parser.isMediaPlaylist(text)) {
            return parseMediaPlaylist(lines, parentUrl)
        }
        return parseMasterPlaylist(lines, parentUrl)
    }

    static isMediaPlaylist(text) {
        return text.includes('#EXTINF:') || text.includes('#EXT-X-TARGETDURATION:')
    }
}
