import FlvLoader from "./flvLoader";

export default class WebTransportLoader extends FlvLoader {
    constructor(player) {
        super(player);
        player.debug.log('WebTransportDemux', 'init');

    }

    destroy() {
        this.player.debug.log('WebTransportDemux', 'destroy');
        super.destroy();
    }
}
