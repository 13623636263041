import Player from './player';
import Events from "./utils/events";
import {
    AI_FACE_DETECTOR_LEVEL, AI_OBJECT_DETECTOR_LEVEL,
    CONTAINER_DATA_SET_KEY,
    CRYPTO_KEY_URL_PATH,
    DEBUG_LEVEL, DECODE_TYPE,
    DEMUX_TYPE,
    EVENTS,
    EVENTS_ERROR,
    JESSIBUCA_EVENTS,
    PLAY_TYPE, PLAYBACK_CONTROL_TYPE, PLAYER_NAME,
    PLAYER_PLAY_PROTOCOL, PLAYER_PLAY_PROTOCOL_LIST,
    PLAYER_STATUS, RENDER_TYPE,
    TALK_EVENTS
} from "./constant";
import {
    b64toUin8,
    base64Decode,
    base64ToString, canPlayAppleMpegurl,
    clamp,
    errorToString,
    formatFullscreenWatermarkOptions,
    getDefaultJessibucaOptions,
    getDefaultPlayerOptions,
    getElementDataset,
    getStrLength,
    getUrlRelativePath,
    isBoolean,
    isEmpty,
    isFalse,
    isNotEmpty,
    isNotEmptyObject,
    isNumber,
    isRelease,
    isTrue,
    isUndefined,
    now,
    proVersionTime, removeElementDataset,
    resolveUrl,
    setElementDataset,
    supportWCS,
    uuid16,
    uuid4
} from "./utils";
import Emitter from "./utils/emitter";
import Debug from "./utils/debug";
import Talk from "./talk";
import {getM7SCryptoStreamKey} from "./utils/crypto";
import Watermark from './utils/watermark';
import {getPTZCmd} from "./utils/ptz-cmd";
import MemoryLogger from "./utils/memoryLogger";

class JessibucaPro extends Emitter {

    constructor(options = {}) {
        super()
        /**@type {import('./constant').DEFAULT_JESSIBUCA_OPTIONS}*/
        this._opt = {};
        this.$container = null;
        Object.keys(options).forEach((key) => {
            if (isUndefined(options[key])) {
                throw new Error(`JbPro option "${key}" can not be undefined`);
                return;
            }
        })
        this.originalOptions = options;
        const defaultOptions = getDefaultJessibucaOptions();
        let _opt = Object.assign({}, defaultOptions, options);
        // 禁用用户配置 url 参数。
        _opt.url = '';

        if (_opt.isMulti) {
            _opt.debugUuid = uuid4();
        }
        this.debug = new Debug(this);
        this.debug.log('JbPro', 'init');

        let $container = options.container;
        if (typeof options.container === 'string') {
            $container = document.querySelector(options.container);
        }
        if (!$container) {
            this.debug.error('JbPro', 'JbPro need container option and now container is', options.container);
            throw new Error('JbPro need container option');
            return;
        }


        //  check opt.decoder is valid
        if (_opt.decoder &&
            isFalse((_opt.decoder.indexOf('decoder-pro.js') !== -1 ||
                _opt.decoder.indexOf('decoder-pro-simd.js') !== -1))) {
            this.debug.error('JbPro', `JbPro decoder ${_opt.decoder} must be decoder-pro.js or decoder-pro-simd.js`);
            throw new Error(`JbPro decoder ${_opt.decoder} must be decoder-pro.js or decoder-pro-simd.js`);
            return;
        }

        // check container node name
        if ($container.nodeName === 'CANVAS' || $container.nodeName === 'VIDEO') {
            this.debug.error('JbPro', `JbPro container type can not be ${$container.nodeName} type`);
            throw new Error(`JbPro container type can not be ${$container.nodeName} type`);
            return;
        }

        if (_opt.videoBuffer >= _opt.heartTimeout) {
            this.debug.error('JbPro', `JbPro videoBuffer ${_opt.videoBuffer}s must be less than heartTimeout ${_opt.heartTimeout}s`)
            throw new Error(`JbPro videoBuffer ${_opt.videoBuffer}s must be less than heartTimeout ${_opt.heartTimeout}s`);
            return;
        }

        if (this._checkHasCreated($container)) {
            this.debug.error('JbPro', 'JbPro container has been created and can not be created again', $container);
            throw new Error(`JbPro container has been created and can not be created again`, $container);
            return;
        }

        $container.classList.add('jessibuca-container');
        setElementDataset($container, CONTAINER_DATA_SET_KEY, uuid16());

        if (isFalse(_opt.isLive)) {
            const $videoElement = document.createElement('video');
            $videoElement.muted = true;
            $videoElement.setAttribute("controlsList", "nodownload");
            $videoElement.disablePictureInPicture = 'disablePictureInPicture';
            $videoElement.style.position = "absolute";
            $videoElement.style.top = 0;
            $videoElement.style.left = 0;
            $videoElement.style.height = "100%";
            $videoElement.style.width = '100%';
            $container.appendChild($videoElement);
            this.$videoElement = $videoElement;
            this.$container = $container;
            this._opt = _opt;
            return;
        }

        delete _opt.container;

        // s -> ms
        if (isNotEmpty(_opt.videoBuffer)) {
            _opt.videoBuffer = Number(_opt.videoBuffer) * 1000
        }

        // s -> ms
        if (isNotEmpty(_opt.videoBufferDelay)) {
            _opt.videoBufferDelay = Number(_opt.videoBufferDelay) * 1000
        }

        // s -> ms
        if (isNotEmpty(_opt.networkDelay)) {
            _opt.networkDelay = Number(_opt.networkDelay) * 1000
        }

        //  s -> ms
        if (isNotEmpty(_opt.aiFaceDetectInterval)) {
            _opt.aiFaceDetectInterval = Number(_opt.aiFaceDetectInterval) * 1000
        }

        //  s -> ms
        if (isNotEmpty(_opt.aiObjectDetectInterval)) {
            _opt.aiObjectDetectInterval = Number(_opt.aiObjectDetectInterval) * 1000
        }

        // setting
        if (isNotEmpty(_opt.timeout)) {
            if (isEmpty(_opt.loadingTimeout)) {
                _opt.loadingTimeout = _opt.timeout;
            }

            if (isEmpty(_opt.heartTimeout)) {
                _opt.heartTimeout = _opt.timeout
            }
        }

        if (isNotEmpty(_opt.autoWasm)) {
            if (isEmpty(_opt.decoderErrorAutoWasm)) {
                _opt.decoderErrorAutoWasm = _opt.autoWasm;
            }

            if (isEmpty(_opt.hardDecodingNotSupportAutoWasm)) {
                _opt.hardDecodingNotSupportAutoWasm = _opt.autoWasm;
            }
        }


        if (isNotEmpty(_opt.aiFaceDetectLevel) &&
            isEmpty(_opt.aiFaceDetectWidth)) {
            const width = AI_FACE_DETECTOR_LEVEL[_opt.aiFaceDetectLevel];
            if (width) {
                _opt.aiFaceDetectWidth = width;
            }
        }

        if (isNotEmpty(_opt.aiObjectDetectLevel) &&
            isEmpty(_opt.aiObjectDetectWidth)) {
            const width = AI_OBJECT_DETECTOR_LEVEL[_opt.aiObjectDetectLevel];
            if (width) {
                _opt.aiObjectDetectWidth = width;
            }
        }

        if (isTrue(_opt.isCrypto)) {
            _opt.isM7sCrypto = true;
        }

        this._opt = _opt;
        this._destroyed = false;
        this.$container = $container;
        this._tempPlayBgObj = {};
        this._loadingTimeoutReplayTimes = 0;
        this._heartTimeoutReplayTimes = 0;
        this.events = new Events(this);
        this.watermark = new Watermark(this);
        this.memoryLogger = new MemoryLogger(this);
        this._initPlayer($container, _opt);
        this._initWatermark();
        this.debug.log('JbPro', `init success and version is ${proVersionTime}`);
        console.log(`JbPro Version is ${proVersionTime} ${isFalse(isRelease) ? 'and is Trial Version[试用版本]' : ''}`);
    }


    destroy() {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'destroy()');
            this._destroyed = true;
            this.off();
            // just for isLive = false
            if (this.$videoElement) {
                this.$videoElement.pause();
                this.$videoElement.currentTime = 0;
                if (this.$videoElement.srcObject) {
                    this.$videoElement.srcObject = null;
                    this.$videoElement.removeAttribute('srcObject');
                }
                if (this.$videoElement.src) {
                    this.$videoElement.src = '';
                    this.$videoElement.removeAttribute('src');
                }
                if (this.$container) {
                    this.$container.removeChild(this.$videoElement);
                }
                this.$videoElement = null;
            }

            if (this.player) {
                this.player.destroy().then(() => {
                    this.player = null;
                    this._destroy();
                    setTimeout(() => {
                        resolve();
                    }, 0)
                }).catch(() => {
                    reject();
                });
            } else {
                this._destroy();
                setTimeout(() => {
                    resolve();
                }, 0)
            }
        })
    }

    _destroy() {
        if (this.events) {
            this.events.destroy();
            this.events = null;
        }

        if (this.talk) {
            this.talk.destroy();
            this.talk = null;
        }
        if (this.watermark) {
            this.watermark.destroy();
            this.watermark = null;
        }

        if (this.memoryLogger) {
            this.memoryLogger.destroy();
            this.memoryLogger = null;
        }

        if (this.$container) {
            this.$container.classList.remove('jessibuca-container');
            this.$container.classList.remove('jessibuca-fullscreen-web');
            removeElementDataset(this.$container, CONTAINER_DATA_SET_KEY);
            this.$container = null;
        }
        this.debug && this.debug.log('JbPro', 'destroy end')
        this._resetOpt();
        this._tempPlayBgObj = null;
        this._loadingTimeoutReplayTimes = 0;
        this._heartTimeoutReplayTimes = 0;
    }

    _resetOpt() {
        this._opt = getDefaultJessibucaOptions();
    }

    _getOriginalOpt() {
        const _opt = getDefaultJessibucaOptions();
        return Object.assign({}, _opt, this.originalOptions);
    }

    _initPlayer($container, options) {
        this.player = new Player($container, options);
        this._bindEvents();
    }

    _initTalk(options = {}) {
        if (this.talk) {
            this.talk.destroy();
            this.talk = null;
        }
        if (this.player) {
            options.debug = this.player._opt.debug;
        }
        this.talk = new Talk(this.player, options)
        this.debug.log('JbPro', '_initTalk', this.talk.getOption())
        this._bindTalkEvents();
    }

    _resetPlayer(options = {}) {
        return new Promise((resolve, reject) => {
            const _init = () => {
                this._opt.url = '';// reset url
                this._opt.playOptions = {};// reset playOptions
                this._opt = Object.assign(this._opt, options);
                this._initPlayer(this.$container, this._opt);
            }

            if (this.player) {
                this.player.destroy().then(() => {
                    this.player = null;
                    _init();
                    setTimeout(() => {
                        resolve()
                    }, 0)
                });
            } else {
                _init();
                setTimeout(() => {
                    resolve()
                }, 0)
            }
        })
    }

    _bindEvents() {
        // 对外的事件
        Object.keys(JESSIBUCA_EVENTS).forEach((key) => {
            this.player.on(JESSIBUCA_EVENTS[key], (...value) => {
                this.emit(key, ...value)
            })
        })
        if (this._opt.playFailedAndPausedShowMessage) {
            this.on(EVENTS.playFailedAndPaused, (code) => {
                this.player && this.player.showTipsMessageByCode(code);
            })
        }


        this.player.once(EVENTS.beforeDestroy, () => {
            // 单独的事件
            this.emit(EVENTS.close);
            this.destroy().then(() => {

            }).catch((e) => {

            });
        })

        // fullscreen watermark
        this.player.on(EVENTS.resize, () => {
            if (this.watermark) {
                this.watermark.resize();
            }
        })
        this.player.on(EVENTS.fullscreen, () => {
            if (this.watermark) {
                this.watermark.resize();
            }
        })

        this.player.on(EVENTS.videoInfo, () => {
            if (this.player) {
                if (this.player.singleWatermark) {
                    this.player.singleWatermark.resize();
                }

                if (this.player.ghostWatermark) {
                    this.player.ghostWatermark.resize();
                }

                if (this.player.dynamicWatermark) {
                    this.player.dynamicWatermark.resize();
                }
            }

        })

        this.player.on(EVENTS.memoryLog, (...args) => {
            this.memoryLogger.logCache(...args);
        })

        this.player.on(EVENTS.downloadMemoryLog, () => {
            this.downloadMemoryLog();
        })
    }

    _bindTalkEvents() {
        // 对外的事件
        Object.keys(TALK_EVENTS).forEach((key) => {
            this.player.on(TALK_EVENTS[key], (value) => {
                this.emit(key, value)
            })
        })
    }

    _initWatermark() {

        if (!isRelease) {
            this._opt.fullscreenWatermarkConfig.text = decodeURIComponent(PLAYER_NAME + '%20%E4%BD%93%E9%AA%8C');
            this._opt.fullscreenWatermarkConfig.color = 'white';
        }

        if (isNotEmptyObject(this._opt.fullscreenWatermarkConfig)) {
            const config = formatFullscreenWatermarkOptions(this.$container, this._opt.fullscreenWatermarkConfig);

            if (!config.watermark_txt) {
                this.debug.warn('JbPro', 'fullscreenWatermarkConfig text is empty');
                return;
            }

            this.watermark.load(config);
        }
    }

    // check player has created on this elements
    _checkHasCreated(element) {
        if (!element) return false;
        const gbProV = getElementDataset(element, CONTAINER_DATA_SET_KEY);
        if (gbProV) {
            return true;
        }
        return false;
    }

    // is destroyed
    isDestroyed() {
        return this._destroyed;
    }

    /**
     *
     * @returns {{}|({rotate: number, isResize: boolean, playbackForwardMaxRateDecodeIFrame: number, loadingTimeoutReplayTimes: number, wasmDecodeErrorReplay: boolean, demuxType: string, useWCS: boolean, useOffscreen: boolean, loadingTimeout: number, playbackConfig: {playList: [], fps: string}, timeout: number, wasmUseVideoRender: boolean, heartTimeoutReplay: boolean, isNotMute: boolean, protocol: number, useMSE: boolean, operateBtns: {play: boolean, fullscreen: boolean, record: boolean, screenshot: boolean, audio: boolean}, isHls: boolean, hotKey: boolean, heartTimeout: number, isFlv: boolean, openWebglAlignment: boolean, hasVideo: boolean, loadingTimeoutReplay: boolean, hasAudio: boolean, debug: boolean, loadingText: string, useWasm: boolean, keepScreenOn: boolean, isFullResize: boolean, watermarkConfig: {}, forceNoOffscreen: boolean, videoBuffer: number, decoder: string, isWebrtc: boolean, url: string, showBandwidth: boolean, hiddenAutoPause: boolean, autoWasm: boolean, heartTimeoutReplayTimes: number, playType: string, background: string, hasControl: boolean, controlAutoHide: boolean, playbackDelayTime: number, playbackFps: number, supportDblclickFullscreen: boolean, wcsUseVideoRender: boolean} & *)}
     */
    getOption() {
        if (this.player) {
            return this.player.getOption();
        }

        return {}
    }

    /**
     * 是否开启控制台调试打印
     * @param value {Boolean}
     */
    setDebug(value) {
        this.debug.log('JbPro', `setDebug() ${value}`);
        this._opt.debug = !!value;
        if (this.player) {
            this.player.updateOption({
                debug: !!value
            }, true)
        } else {
            this.debug.warn('JbPro', 'player is not init')
        }
    }

    getIsDebug() {
        let result = false;
        if (this.player) {
            result = this.player._opt.debug;
        }
        return result;
    }

    /**
     *
     */
    mute() {
        this.debug.log('JbPro', 'mute()');
        this.player && this.player.mute(true);
    }

    /**
     *
     */
    cancelMute() {
        this.debug.log('JbPro', 'cancelMute()');
        this.player && this.player.mute(false);
    }

    /**
     *
     * @param value {number}
     */
    setVolume(value) {
        this.debug.log('JbPro', `setVolume() ${value}`);
        this.player && (this.player.volume = value);
    }

    /**
     * 获取当前音量
     * @returns {null}
     */
    getVolume() {
        let result = null;
        if (this.player) {
            result = this.player.volume;
            result = parseFloat(result).toFixed(2);
        }
        return result;
    }

    /**
     *
     */
    audioResume() {
        this.debug.log('JbPro', 'audioResume()');
        if (this.player && this.player.audio) {
            this.player.audio.audioEnabled(true);
        } else {
            this.debug.warn('JbPro', 'audioResume error');
        }
    }

    /**
     * 设置超时时长, 单位秒 在连接成功之前和播放中途,如果超过设定时长无数据返回,则回调timeout事件
     * @param value {number}
     */
    setTimeout(time) {
        this.debug.log('JbPro', `setTimeout() ${time}`);
        time = Number(time);
        if (isNaN(time)) {
            this.debug.warn('JbPro', `setTimeout error: ${time} is not a number`);
            return;
        }

        this._opt.timeout = time;
        this._opt.loadingTimeout = time;
        this._opt.heartTimeout = time;

        if (this.player) {
            this.player.updateOption({
                timeout: time,
                loadingTimeout: time,
                heartTimeout: time
            })
        } else {

        }

    }

    /**
     *
     * @param type {number}: 0,1,2
     */
    setScaleMode(type) {
        this.debug.log('JbPro', `setScaleMode() ${type}`);
        if (this.player) {
            this.player.setScaleMode(type);
        } else {
            this.debug.warn('JbPro', 'setScaleMode() player is null');
        }
    }

    /**
     *
     * @returns {Promise<commander.ParseOptionsResult.unknown>}
     */
    pause(isClear = false) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `pause() ${isClear}`);
            if (this._opt.pauseAndNextPlayUseLastFrameShow) {
                this._tempPlayBgObj = this._getVideoLastIframeInfo();
            }

            this._pause(isClear).then((res) => {
                resolve(res)
            }).catch((e) => {
                reject(e);
            })
        })
    }

    /**
     *
     * @param isClear 默认 false，不清除画面
     * @returns {Promise<unknown>}
     * @private
     */
    _pause(isClear = false) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `_pause() ${isClear}`);
            if (this.isDestroyed()) {
                return reject('JbPro is destroyed');
            }
            if (this.player) {
                this.player.pause(isClear).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                })
            } else {
                reject('player is null');
            }
        })
    }


    /**
     *
     */
    close() {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'close()');
            // clear url
            this._opt.url = '';
            // reset
            this._loadingTimeoutReplayTimes = 0;
            this._heartTimeoutReplayTimes = 0;
            if (this.player) {
                this.player.close().then(() => {
                    resolve()
                }).catch((e) => {
                    reject(e)
                })
            } else {
                reject(`player is null`)
            }
        })
    }


    /**
     *
     */
    clearView() {
        this.debug.log('JbPro', 'clearView()');
        if (this.player && this.player.video) {
            if (this.getRenderType() === RENDER_TYPE.canvas) {
                this.player.video.clearView();
            } else {
                this.debug.warn('JbPro', 'clearView', 'render type is video, not support clearView, please use canvas render type');
            }
        } else {
            this.debug.warn('JbPro', 'clearView', 'player is null')
        }
    }

    /**
     *
     * @param url {string}
     * @returns {Promise<unknown>}
     */
    play(url = '', options = {}) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `play() ${url}`, JSON.stringify(options));
            if (!url && !this._opt.url) {
                this.emit(EVENTS.error, EVENTS_ERROR.playError)

                reject('url is null and this._opt.url is null');
                return;
            }
            //
            if (url) {
                url = ('' + url).trim();
                //
                if (url.indexOf('http:') === -1 &&
                    url.indexOf('https:') === -1 &&
                    url.indexOf('webrtc:') === -1 &&
                    url.indexOf('ws:') === -1 &&
                    url.indexOf('wss:') === -1 &&
                    url.indexOf('wt:') === -1 &&
                    url.indexOf('artc:') === -1) {
                    return reject(`url ${url} must be "http:" or "https:" or "webrtc:" or "ws:" or "wss：" or "wt:" or "artc:" protocol`);
                }
            }

            // todo:这里校验url地址是否合法
            // if(veriify(url)){
            //     reject('url is not valid');
            //     return ;
            // }

            if (isFalse(this._opt.isLive)) {
                this.$videoElement.controls = 'controls';
                this.$videoElement.muted = false
                this.$videoElement.src = url
                this.$videoElement.play();
                resolve(this.$videoElement);
                return;
            }

            if (this._opt.isM7sCrypto) {
                let cryptoKey = options.cryptoKey || this._opt.playOptions.cryptoKey;
                let cryptoIV = options.cryptoIV || this._opt.playOptions.cryptoIV;

                if (this._opt.m7sCryptoKey && !(cryptoKey && cryptoIV)) {
                    const tempArray = this._opt.m7sCryptoKey.split('.');
                    cryptoKey = b64toUin8(tempArray[0]);
                    cryptoIV = b64toUin8(tempArray[1]);
                }

                if (cryptoKey && cryptoIV) {
                    this._opt.playOptions.cryptoKey = cryptoKey;
                    this._opt.playOptions.cryptoIV = cryptoIV;
                    options.cryptoIV = cryptoIV;
                    options.cryptoKey = cryptoKey;
                } else {
                    const _url = url || this._opt.url;
                    this._cryptoPlay(_url).then(({cryptoIV, cryptoKey}) => {
                        this._opt.playOptions.cryptoKey = cryptoKey;
                        this._opt.playOptions.cryptoIV = cryptoIV;
                        options.cryptoIV = cryptoIV;
                        options.cryptoKey = cryptoKey;
                        this._playBefore(url, options).then(() => {
                            resolve();
                        }).catch((e) => {
                            reject(e)
                        })
                    }).catch((e) => {
                        reject(e);
                    })
                    return;
                }
            } else if (this._opt.isXorCrypto) {
                let cryptoKey = options.cryptoKey || this._opt.playOptions.cryptoKey;
                let cryptoIV = options.cryptoIV || this._opt.playOptions.cryptoIV;

                if (this._opt.xorCryptoKey && !(cryptoKey && cryptoIV)) {
                    const tempArray = this._opt.xorCryptoKey.split('.');
                    cryptoKey = b64toUin8(tempArray[0]);
                    cryptoIV = b64toUin8(tempArray[1]);
                }

                if (cryptoKey && cryptoIV) {
                    this._opt.playOptions.cryptoKey = cryptoKey;
                    this._opt.playOptions.cryptoIV = cryptoIV;
                    options.cryptoIV = cryptoIV;
                    options.cryptoKey = cryptoKey;
                }
            }

            this._playBefore(url, options).then(() => {
                resolve();
            }).catch((e) => {
                reject(e)
            })
        })
    }

    _playBefore(url, options) {
        return new Promise((resolve, reject) => {
            if (this.player) {
                if (url) {
                    // url 相等的时候。
                    if (this._opt.url) {
                        // 存在相同的 url
                        if (url === this._opt.url) {
                            // 正在播放
                            if (this.player.playing) {
                                this.debug.log('JbPro', '_playBefore', 'playing and resolve()');
                                resolve();
                            } else {
                                // pause ->  play
                                this.debug.log('JbPro', '_playBefore', 'this._opt.url === url and ' +
                                    'pause ->  play and destroy play');
                                let originalOpt = this._getOriginalOpt();
                                if (this._opt.pauseAndNextPlayUseLastFrameShow) {
                                    if (this._tempPlayBgObj &&
                                        this._tempPlayBgObj.loadingBackground) {
                                        originalOpt = Object.assign(originalOpt, this._tempPlayBgObj);
                                    }
                                }
                                const url = this._opt.url;
                                const playOptions = this._opt.playOptions;
                                this._resetPlayer(originalOpt).then(() => {
                                    this._play(url, playOptions).then(() => {
                                        // 恢复下之前的音量
                                        this.player.resumeAudioAfterPause();
                                        resolve();
                                    }).catch((e) => {
                                        this.debug.error('JbPro', '_playBefore this.player.play error', e)
                                        this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 1', e));
                                        reject(e)
                                    });
                                }).catch((e) => {
                                    this.debug.error('JbPro', '_resetPlayer error', e);
                                })
                            }
                        } else {
                            this.debug.log('JbPro', '_playBefore', `
                            this._url.url is ${this._opt.url}
                            and new url is ${url}
                            and destroy and play new url`);
                            // url 发生改变了, 有些参数就不适用了。
                            // 需要重置options
                            const originalOpt = this._getOriginalOpt();
                            this._resetPlayer(originalOpt).then(() => {
                                this._play(url, options).then(() => {
                                    resolve();
                                }).catch((e) => {
                                    this.debug.error('JbPro', '_playBefore _play error', e)
                                    this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 2', e));
                                    reject(e)
                                });
                            }).catch((e) => {
                                this.debug.error('JbPro', '_resetPlayer error', e);
                            })
                        }
                    } else {
                        //  this._opt.url is null new play
                        this._play(url, options).then(() => {
                            resolve();
                        }).catch((e) => {
                            this.debug.error('JbPro', '_playBefore _play error', e)
                            this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 3', e));
                            reject(e)
                        });
                    }
                } else {
                    //  url 不存在的时候
                    //  就是从 play -> pause -> play
                    let originalOpt = this._getOriginalOpt();
                    if (this._opt.pauseAndNextPlayUseLastFrameShow) {
                        if (this._tempPlayBgObj &&
                            this._tempPlayBgObj.loadingBackground) {
                            originalOpt = Object.assign(originalOpt, this._tempPlayBgObj);
                        }
                    }
                    const url = this._opt.url;
                    const playOptions = this._opt.playOptions;
                    this._resetPlayer(originalOpt).then(() => {
                        this._play(url, playOptions).then(() => {
                            // 恢复下之前的音量
                            this.player.resumeAudioAfterPause();
                            resolve();
                        }).catch((e) => {
                            this.debug.error('JbPro', '_playBefore _play error', e)
                            this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 4', e));
                            reject(e)
                        });
                    }).catch((e) => {
                        this.debug.error('JbPro', '_resetPlayer error', e);
                    })
                }
            } else {
                // this.player is null
                if (url) {
                    //  new play
                    this._play(url, options).then(() => {
                        resolve();
                    }).catch((e) => {
                        this.debug.error('JbPro', '_playBefore _play error', e)
                        this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 5', e));
                        reject(e)
                    });
                } else {
                    this._play(this._opt.url, this._opt.playOptions).then(() => {
                        resolve();
                    }).catch((e) => {
                        this.debug.error('JbPro', '_playBefore _play error', e)
                        this.emit(EVENTS.crashLog, this.getCrashLog('this.player.play 6', e));
                        reject(e)
                    });
                }
            }
        })
    }

    _cryptoPlay(url) {
        return new Promise((resolve, reject) => {
            const relativePath = getUrlRelativePath(url);
            let cryptoKeyUrl = this._opt.cryptoKeyUrl;
            let href = '';
            const urlObj = resolveUrl(url);
            if (cryptoKeyUrl) {
                href = cryptoKeyUrl;
                if (this._opt.isM7sCrypto &&
                    href.indexOf(`${CRYPTO_KEY_URL_PATH}?stream=`) === -1) {
                    const cryptoKeyUrlObj = resolveUrl(cryptoKeyUrl);
                    href = cryptoKeyUrlObj.origin + CRYPTO_KEY_URL_PATH + `?stream=${relativePath}`;
                }
            } else {
                cryptoKeyUrl = urlObj.origin + CRYPTO_KEY_URL_PATH;
                href = cryptoKeyUrl + `?stream=${relativePath}`;
            }

            this.player.debug.log('JbPro', `_cryptoPlay() cryptoKeyUrl: ${href} and opt.cryptoKeyUrl: ${this._opt.cryptoKeyUrl}`)
            getM7SCryptoStreamKey(href).then((res) => {
                if (res) {
                    const tempArray = res.split('.');
                    const cryptoKey = b64toUin8(tempArray[0]);
                    const cryptoIV = b64toUin8(tempArray[1]);
                    if (cryptoIV && cryptoKey) {
                        resolve({cryptoIV, cryptoKey});
                    } else {
                        reject(`get cryptoIV or cryptoKey error`);
                    }
                } else {
                    reject(`cryptoKeyUrl: getM7SCryptoStreamKey ${href} res is null`);
                }
            }).catch((e) => {
                reject(e);
            })
        })
    }

    /**
     * playback 录像回放
     * @param url {string}
     */
    playback(url, options = {}) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `playback() ${url}, options: ${JSON.stringify(options)}`);
            if (isFalse(this._opt.isLive)) {
                return reject(`this._opt.isLive is false, can not playback`);
            }
            const defaultOptions = getDefaultPlayerOptions();
            const playbackConfig = Object.assign({}, defaultOptions.playbackConfig, this._opt.playbackConfig, options);
            if (!playbackConfig.isUseFpsRender) {
                if (playbackConfig.isCacheBeforeDecodeForFpsRender) {
                    playbackConfig.isCacheBeforeDecodeForFpsRender = false;
                    this.debug.warn('JbPro', 'playbackConfig.isUseFpsRender is false, isCacheBeforeDecodeForFpsRender can not be ture, isCacheBeforeDecodeForFpsRender is set to false');
                }
            }

            if (playbackConfig.rateConfig.length === 0) {
                if (playbackConfig.showRateBtn) {
                    playbackConfig.showRateBtn = false;
                    this.debug.warn('JbPro', 'playbackConfig.rateConfig.length is 0, showRateBtn can not be ture, showRateBtn is set to false');
                }
            }

            //
            if (playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.simple) {

            }


            this._resetPlayer({
                videoBuffer: 0,// 录播的时候不缓存。
                playbackConfig,
                playType: PLAY_TYPE.playbackTF,
                openWebglAlignment: true,
                useMSE: playbackConfig.useMSE, //
                useWCS: playbackConfig.useWCS, // 录播的时候使用 WCS 解码器
                useSIMD: true, // 录播的时候默认使用 SIMD 解码器
            }).then(() => {
                this.play(url, options).then(() => {
                    resolve();
                }).catch((e) => {
                    reject(e);
                })
            }).catch((e) => {
                reject(e);
            });
        })
    }

    // playback pause
    playbackPause(isPause = false) {
        this.debug.log('JbPro', `playbackPause() ${isPause}`);
        if (this._opt.playType === PLAY_TYPE.player) {
            return Promise.reject(`playType is player, can not call playbackPause method`);
        }

        return new Promise((resolve, reject) => {
            if (!this.player) {
                return reject('player is null')
            }

            if (isTrue(isPause)) {
                this._pause().then(() => {
                    resolve();
                }).catch((e) => {
                    reject(e)
                })
            } else {
                this.player.playbackPause = true;
                resolve();
            }
        })
    }

    // playback pause - > resume
    playbackResume() {
        this.debug.log('JbPro', `playbackResume()`);
        if (this._opt.playType === PLAY_TYPE.player) {
            return Promise.reject('playType is player, can not call playbackResume method');
        }
        return new Promise((resolve, reject) => {
            if (!this.player) {
                return reject('player is null')
            }

            this.player.playbackPause = false;
            resolve();
        })
    }


    /**
     * playback 快放 1倍，2倍，4倍，8倍,16倍，32倍 支持范围 0.1 - 32
     * @param rate
     * @returns {Promise<unknown>}
     */
    forward(rate) {
        this.debug.log('JbPro', `forward() ${rate}`);
        if (isFalse(this._opt.isLive) || this._opt.playType === PLAY_TYPE.player) {
            return Promise.reject('forward() method only just for playback type');
        }

        if (!isNumber(Number(rate))) {
            return Promise.reject(`forward() params "rate": ${rate} must be number type`);
        }

        return new Promise((resolve, reject) => {
            if (this.player) {
                rate = clamp(Number(rate), 0.1, 32);
                // update config
                this.player.decoderWorker && this.player.decoderWorker.updateWorkConfig({
                    key: 'playbackRate', value: rate
                })
                this.player.playback.setRate(rate);
                this.player.video && this.player.video.setRate(rate);
                this.player.audio && this.player.audio.setRate(rate);
                if (this.player.isPlaybackUseWCS() ||
                    this.player.isPlaybackUseMSE()) {

                    this.player.demux.dropBuffer$2();
                    if (this.player.isPlaybackCacheBeforeDecodeForFpsRender()) {
                        this.player.demux.initPlaybackCacheLoop();
                    }
                }
                resolve()
            } else {
                reject('player is not playing');
            }
        })
    }

    /**
     * 等同 forward 方法。
     * @param rate
     * @returns {Promise<*>}
     */
    playbackForward(rate) {
        return this.forward(rate);
    }


    /**
     * playback 快放->恢复
     * @returns {Promise<unknown>}
     */
    normal() {
        return this.forward(1)
    }

    /**
     * 等同 normal 方法。
     * @returns {Promise<*>}
     */
    playbackNormal() {
        return this.normal();
    }

    /**
     * playback 更新TF卡流只解码i帧播放倍率，支持playback()之前调用。
     * @param rate
     */
    updatePlaybackForwardMaxRateDecodeIFrame(rate) {
        this.debug.log('JbPro', `updatePlaybackForwardMaxRateDecodeIFrame() ${rate}`);
        rate = Number(rate);
        rate = parseInt(rate, 10);
        rate = clamp(rate, 1, 8);
        this._opt.playbackForwardMaxRateDecodeIFrame = rate

        if (this.player) {
            this.player.updateOption({
                playbackForwardMaxRateDecodeIFrame: rate
            }, true)
        } else {
            this.debug.warn('JbPro', `updatePlaybackForwardMaxRateDecodeIFrame() player is null`);
        }
    }

    /**
     * playback set playback start time  and clear buffer
     * for seek callback
     * @param timestamp
     */
    setPlaybackStartTime(timestamp) {
        this.debug.log('JbPro', `setPlaybackStartTime() ${timestamp}`);
        const strLength = getStrLength(timestamp)
        if (!this.player) {
            this.debug.warn('JbPro', 'setPlaybackStartTime() player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'setPlaybackStartTime() playType is not playback')
            return;
        }

        if (strLength < 10 && timestamp !== 0 && this.player.playback.isControlTypeNormal()) {
            this.debug.warn('JbPro', `setPlaybackStartTime() control type is normal and  timestamp: ${timestamp} is not valid`)
            return;
        }

        if (this.player.playback.isControlTypeSimple()
            && timestamp > this.player.playback.totalDuration) {
            this.debug.warn('JbPro', `setPlaybackStartTime() control type is simple and timestamp: ${timestamp} is more than ${this.player.playback.totalDuration}`)
            return;
        }

        if (this.player.playing) {
            if (this.player.playback.isControlTypeNormal()) {
                if (strLength === 10) {
                    timestamp = timestamp * 1000
                }
            }
            this.player.playback.setStartTime(timestamp);
            this.playbackClearCacheBuffer();
        }
    }

    /**
     * playback set playback show precision
     * @param showPrecision
     */
    setPlaybackShowPrecision(showPrecision) {
        this.debug.log('JbPro', `setPlaybackShowPrecision() ${showPrecision}`);
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'playType is not playback')
            return;
        }

        if (!this.player.playback.isControlTypeNormal()) {
            this.debug.warn('JbPro', 'control type is not normal , not support!')
            return;
        }

        this.player.playback.setShowPrecision(showPrecision);
    }

    playbackCurrentTimeScroll() {
        this.debug.log('JbPro', `playbackCurrentTimeScroll()`);
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'playType is not playback')
            return;
        }

        if (!this.player.playback.isControlTypeNormal()) {
            this.debug.warn('JbPro', 'control type is not normal , not support!')
            return;
        }

        this.player.playback.currentTimeScroll();
    }

    /**
     *
     */
    playbackClearCacheBuffer() {
        this.debug.log('JbPro', `playbackClearCacheBuffer()`);
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'playType is not playback')
            return;
        }
        this.player.video && this.player.video.clear();
        this.player.audio && this.player.audio.clear();
        this.clearBufferDelay();
    }


    getPlaybackCurrentRate() {
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'playType is not playback')
            return;
        }

        return this.player.getPlaybackRate()
    }

    /**
     *
     * @param timestamp  单位 ms
     */
    updatePlaybackLocalOneFrameTimestamp(timestamp) {
        this.debug.log('JbPro', `updatePlaybackLocalOneFrameTimestamp() ${timestamp}`);
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }
        if (!this.player.isPlayback()) {
            this.debug.warn('JbPro', 'playType is not playback')
            return;
        }

        this.player.playback.updateLocalOneFrameTimestamp(timestamp);
    }

    /**
     *
     * @param quality
     */
    setStreamQuality(quality) {
        this.debug.log('JbPro', `setStreamQuality() ${quality}`);
        if (!this.player) {
            this.debug.warn('JbPro', 'player is null')
            return;
        }

        if (!this.player._opt.operateBtns.quality) {
            this.debug.warn('JbPro', 'player._opt.operateBtns.quality is false')
            return;
        }

        const qualityList = this.player._opt.qualityConfig || [];
        if (qualityList.includes(quality)) {
            this.player.streamQuality = quality;
        } else {
            this.debug.warn('JbPro', `quality: ${quality} is not in qualityList`)
        }
    }

    /**
     *
     * @param url {string}
     * @returns {Promise<unknown>}
     * @private
     */
    _play(url = '', options = {}) {
        return new Promise((resolve, reject) => {

            if (!this.player) {
                return reject('player is null');
            }

            // check need reset player
            let needResetPlayer = false;
            if (this._opt.url && this._opt.url !== url) {
                needResetPlayer = true;
            }

            this._opt.url = url;
            this._opt.playOptions = options;
            //  remove search
            const urlPath = url.split("?")[0];

            //  新的url
            const isHttp = urlPath.startsWith('http://') || urlPath.startsWith('https://');
            const isWebrtc = urlPath.startsWith('webrtc://');
            const isAliyunRtc = urlPath.startsWith('artc://');
            const isWebTransport = urlPath.startsWith('wt://');
            const isWebsocket = urlPath.startsWith('ws://') || urlPath.startsWith('wss://');
            const isHttpOrWebsocket = isHttp || isWebsocket;

            const isHls = isHttp && urlPath.endsWith('.m3u8');
            const isFlv = isHttpOrWebsocket && urlPath.endsWith(".flv");
            const isFmp4 = isHttpOrWebsocket && (urlPath.endsWith(".fmp4") || urlPath.endsWith(".mp4"));
            const isMpeg4 = isHttpOrWebsocket && urlPath.endsWith(".mpeg4");
            const isNakedFlow = isHttpOrWebsocket && (urlPath.endsWith(".h264") || urlPath.endsWith(".h265"));

            let isWebrtcForZLM = this._opt.isWebrtcForZLM || false;
            let isWebrtcForSRS = this._opt.isWebrtcForSRS || false;
            let isWebrtcForOthers = this._opt.isWebrtcForOthers || false;
            if (isWebrtc) {
                if (url.indexOf('/index/api/webrtc') !== -1) {
                    isWebrtcForZLM = true;
                    isWebrtcForSRS = false;
                    isWebrtcForOthers = false;
                } else if (url.indexOf('/rtc/v1/play/') !== -1) {
                    isWebrtcForSRS = true;
                    isWebrtcForZLM = false;
                    isWebrtcForOthers = false;
                }
            }
            //
            let protocol = null
            let demuxType = null;

            if (isFlv && !this._opt.isFlv) {
                this._resetDemuxType('isFlv');
            }

            if (isFmp4 && !this._opt.isFmp4) {
                this._resetDemuxType('isFmp4');
            }

            if (isMpeg4 && !this._opt.isMpeg4) {
                this._resetDemuxType('isMpeg4');
            }

            if (isNakedFlow && !this._opt.isNakedFlow) {
                this._resetDemuxType('isNakedFlow');
            }

            if (isHttp) {
                if (isHls) {
                    protocol = PLAYER_PLAY_PROTOCOL.hls;
                } else {
                    protocol = PLAYER_PLAY_PROTOCOL.fetch;
                }
            } else if (isWebTransport) {
                protocol = PLAYER_PLAY_PROTOCOL.webTransport;
            } else {
                if (isWebrtc) {
                    protocol = PLAYER_PLAY_PROTOCOL.webrtc;
                } else if (isAliyunRtc) {
                    protocol = PLAYER_PLAY_PROTOCOL.aliyunRtc;
                } else {
                    protocol = PLAYER_PLAY_PROTOCOL.websocket;
                }
            }
            if (this._opt.isNakedFlow) {
                demuxType = DEMUX_TYPE.nakedFlow;
            } else if (this._opt.isFmp4) {
                demuxType = DEMUX_TYPE.fmp4;
            } else if (this._opt.isMpeg4) {
                demuxType = DEMUX_TYPE.mpeg4;
            } else if (this._opt.isFlv) {
                demuxType = DEMUX_TYPE.flv
            } else {
                if (isHls) {
                    demuxType = DEMUX_TYPE.hls
                } else if (isWebrtc) {
                    demuxType = DEMUX_TYPE.webrtc
                } else if (isAliyunRtc) {
                    demuxType = DEMUX_TYPE.aliyunRtc
                } else if (isWebTransport) {
                    demuxType = DEMUX_TYPE.webTransport
                } else {
                    //  m7s for websocket
                    if (isWebsocket) {
                        demuxType = DEMUX_TYPE.m7s;
                    }
                }
            }

            if (!(protocol && demuxType)) {
                return reject(`play protocol is ${PLAYER_PLAY_PROTOCOL_LIST[protocol]}, demuxType is ${demuxType}`);
            }
            //
            this.debug.log('JbPro', `play protocol is ${PLAYER_PLAY_PROTOCOL_LIST[protocol]}, demuxType is ${demuxType}`);

            const _playNext = () => {
                // webgl
                this.player.once(EVENTS_ERROR.webglAlignmentError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webglAlignmentError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webglAlignmentError, error));
                    if (this.player && this.player._opt.webglAlignmentErrorReplay) {
                        this.debug.log('JbPro', 'webglAlignmentError')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer({openWebglAlignment: true}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webglAlignmentError and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglAlignmentError);
                                // reject();
                                this.debug.error('JbPro', 'webglAlignmentError and play error', e)
                            });
                        }).catch((e) => {
                            this.debug.error('JbPro', 'webglAlignmentError and _resetPlayer error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglAlignmentError);
                            this.debug.log('JbPro', 'webglAlignmentError and webglAlignmentErrorReplay is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglAlignmentError);
                            this.debug.error('JbPro', 'webglAlignmentError and pause error', e)
                        });

                    }
                })
                // webgl
                this.player.once(EVENTS_ERROR.webglContextLostError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webglContextLostError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webglContextLostError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.webglContextLostErrorReplay) {
                        this.debug.log('JbPro', 'webglContextLostError')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webglContextLostError and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglContextLostError, lastFrameInfo);
                                // reject();
                                this.debug.error('JbPro', 'webglContextLostError and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglContextLostError, lastFrameInfo);
                            this.debug.error('JbPro', 'webglContextLostError and _resetPlayer error', e)
                        })

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglContextLostError, lastFrameInfo);
                            this.debug.log('JbPro', 'webglContextLostError and webglContextLostErrorReplay is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webglContextLostError, lastFrameInfo);
                            this.debug.error('JbPro', 'webglAlignmentError and pause error', e)
                        });
                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mediaSourceH265NotSupport, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceH265NotSupport but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceH265NotSupport, error));

                    if (this.player &&
                        this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'mediaSourceH265NotSupport auto wasm [mse-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer({useMSE: false, useWCS: false}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceH265NotSupport auto wasm [mse-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceH265NotSupport);
                                // reject();
                                this.debug.error('JbPro', 'mediaSourceH265NotSupport auto wasm [mse-> wasm] reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceH265NotSupport);
                            this.debug.error('JbPro', 'mediaSourceH265NotSupport auto wasm [mse-> wasm] _resetPlayer error', e);
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceH265NotSupport);
                            this.debug.log('JbPro', 'mediaSourceH265NotSupport and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceH265NotSupport);
                            this.debug.error('JbPro', 'mediaSourceH265NotSupport and pause error', e)
                        });

                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mediaSourceFull, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceFull but player is destroyed');
                        return;
                    }

                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceFull, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    if (this.player &&
                        this.player._opt.mseDecodeErrorReplay) {
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.decoderErrorAutoWasm) {
                            options = {useMSE: false, useWCS: false}
                        }
                        this.debug.log('JbPro', `mediaSourceFull and auto wasm [mse-> ${this.player._opt.decoderErrorAutoWasm ? "wasm" : "mse"}] reset player and play`);

                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceFull and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceFull, lastFrameInfo);
                                // reject();
                                this.debug.error('JbPro', 'mediaSourceFull and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceFull, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceFull and _resetPlayer error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceFull, lastFrameInfo);
                            this.debug.log('JbPro', 'mediaSourceFull and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceFull, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceFull and pause error', e)
                        })
                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mediaSourceAppendBufferError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceAppendBufferError but player is destroyed');
                        return;
                    }

                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceAppendBufferError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player &&
                        this.player._opt.mseDecodeErrorReplay) {
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.decoderErrorAutoWasm) {
                            options = {useMSE: false, useWCS: false}
                        }
                        this.debug.log('JbPro', `mediaSourceAppendBufferError and auto wasm [mse-> ${this.player._opt.decoderErrorAutoWasm ? "wasm" : "mse"}] reset player and play`);

                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceAppendBufferError and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAppendBufferError, lastFrameInfo);
                                // reject();
                                this.debug.error('JbPro', 'mediaSourceAppendBufferError and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAppendBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAppendBufferError and _resetPlayer error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAppendBufferError, lastFrameInfo);
                            this.debug.log('JbPro', 'mediaSourceAppendBufferError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAppendBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAppendBufferError and pause error', e)
                        })
                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mseSourceBufferError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mseSourceBufferError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mseSourceBufferError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.mseDecodeErrorReplay) {
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.decoderErrorAutoWasm) {
                            options = {useMSE: false, useWCS: false}
                        }
                        this.debug.log('JbPro', `mseSourceBufferError auto wasm [mse-> ${this.player._opt.decoderErrorAutoWasm ? "wasm" : "mse"}] reset player and play`)

                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mseSourceBufferError reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseSourceBufferError, lastFrameInfo);
                                this.debug.error('JbPro', 'mseSourceBufferError reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseSourceBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mseSourceBufferError _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseSourceBufferError, lastFrameInfo);
                            this.debug.log('JbPro', 'mseSourceBufferError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseSourceBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mseSourceBufferError and pause error:', e)
                        })
                    }
                })

                // MSE
                this.player.once(EVENTS_ERROR.mediaSourceBufferedIsZeroError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceBufferedIsZeroError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceBufferedIsZeroError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.mseDecodeErrorReplay) {
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.decoderErrorAutoWasm) {
                            options = {useMSE: false, useWCS: false}
                        }
                        this.debug.log('JbPro', `mediaSourceBufferedIsZeroError auto wasm [mse-> ${this.player._opt.decoderErrorAutoWasm ? "wasm" : "mse"}] reset player and play`)

                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceBufferedIsZeroError reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceBufferedIsZeroError, lastFrameInfo);
                                this.debug.error('JbPro', 'mediaSourceBufferedIsZeroError reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceBufferedIsZeroError, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceBufferedIsZeroError _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceBufferedIsZeroError, lastFrameInfo);
                            this.debug.log('JbPro', 'mediaSourceBufferedIsZeroError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceBufferedIsZeroError, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceBufferedIsZeroError and pause error:', e)
                        })
                    }
                })

                // MSE init error ,can not use mse decode only auto wasm
                this.player.once(EVENTS_ERROR.mseAddSourceBufferError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mseAddSourceBufferError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mseAddSourceBufferError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    if (this.player && this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'mseAddSourceBufferError auto wasm [mse-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {useMSE: false, useWCS: false};
                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mseAddSourceBufferError auto wasm [mse-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseAddSourceBufferError, lastFrameInfo);
                                // reject();
                                this.debug.error('JbPro', 'mseAddSourceBufferError auto wasm [mse-> wasm] reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseAddSourceBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mseAddSourceBufferError auto wasm [mse-> wasm] _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseAddSourceBufferError, lastFrameInfo);
                            this.debug.log('JbPro', 'mseAddSourceBufferError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseAddSourceBufferError, lastFrameInfo);
                            this.debug.error('JbPro', 'mseAddSourceBufferError and pause error', e)
                        })
                    }
                })
                // MSE init error ,can not use mse decode only auto wasm
                this.player.once(EVENTS_ERROR.mediaSourceDecoderConfigurationError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceDecoderConfigurationError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceDecoderConfigurationError, error));

                    if (this.player && this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'mediaSourceDecoderConfigurationError auto wasm [mse-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {useMSE: false, useWCS: false};
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceDecoderConfigurationError auto wasm [mse-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceDecoderConfigurationError);
                                this.debug.error('JbPro', 'mediaSourceDecoderConfigurationError auto wasm [mse-> wasm] reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceDecoderConfigurationError);
                            this.debug.error('JbPro', 'mediaSourceDecoderConfigurationError auto wasm [mse-> wasm] _resetPlayer and play error', e)
                        })

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceDecoderConfigurationError);
                            this.debug.log('JbPro', 'mediaSourceDecoderConfigurationError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceDecoderConfigurationError);
                            this.debug.error('JbPro', 'mediaSourceDecoderConfigurationError and pause error', e)
                        })
                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mediaSourceTsIsMaxDiff, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceTsIsMaxDiff but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceTsIsMaxDiff, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();


                    if (this.player && this.player._opt.mediaSourceTsIsMaxDiffReplay) {
                        this.debug.log('JbPro', 'mediaSourceTsIsMaxDiff reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        options = Object.assign({}, options, lastFrameInfo);
                        // support play and playback
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                this.debug.log('JbPro', 'mediaSourceTsIsMaxDiff replay success');
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceTsIsMaxDiff, lastFrameInfo);
                                this.debug.error('JbPro', 'mediaSourceTsIsMaxDiff replay error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceTsIsMaxDiff, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceTsIsMaxDiff _resetPlayer error', e)
                        });

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceTsIsMaxDiff, lastFrameInfo);
                            this.debug.log('JbPro', 'mediaSourceTsIsMaxDiff and replay is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceTsIsMaxDiff, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceTsIsMaxDiff and pause error', e)
                        })
                    }
                })
                // MSE
                this.player.once(EVENTS_ERROR.mseWidthOrHeightChange, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mseWidthOrHeightChange but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mseWidthOrHeightChange, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.widthOrHeightChangeReplay) {
                        this.debug.log('JbPro', 'mseWidthOrHeightChange and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {}
                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {
                            if (this.player._opt.widthOrHeightChangeReplayDelayTime > 0) {
                                setTimeout(() => {
                                    if (this.isDestroyed()) {
                                        this.debug.log('JbPro', 'mseWidthOrHeightChange and widthOrHeightChangeReplayDelayTime but player is destroyed');
                                        return;
                                    }
                                    this.play(_url, _playOptions).then(() => {
                                        this.debug.log('JbPro', 'mseWidthOrHeightChange and reset player and play success')
                                    }).catch((e) => {
                                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseWidthOrHeightChange, lastFrameInfo);
                                        this.debug.error('JbPro', 'mseWidthOrHeightChange and reset player and play error', e)
                                    });

                                }, this.player._opt.widthOrHeightChangeReplayDelayTime * 1000)
                            } else {
                                this.play(_url, _playOptions).then(() => {
                                    this.debug.log('JbPro', 'mseWidthOrHeightChange and reset player and play success')
                                }).catch((e) => {
                                    this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseWidthOrHeightChange, lastFrameInfo);
                                    this.debug.error('JbPro', 'mseWidthOrHeightChange and reset player and play error', e)
                                });
                            }

                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'mseWidthOrHeightChange and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'mseWidthOrHeightChange and _resetPlayer error', e)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mseWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'mseWidthOrHeightChange error and pause error', e)
                        })
                    }
                })
                // MSE
                // inner replay
                this.player.once(EVENTS_ERROR.mediaSourceAudioG711NotSupport, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceAudioG711NotSupport but player is destroyed');
                        return;
                    }
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player &&
                        this.player._opt.mediaSourceAudioG711NotSupportReplay) {
                        this.debug.log('JbPro', 'mediaSourceAudioG711NotSupport and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {
                            mseDecodeAudio: false,
                        }
                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                this.debug.log('JbPro', 'mediaSourceAudioG711NotSupport and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioG711NotSupport, lastFrameInfo);
                                this.debug.error('JbPro', 'mediaSourceAudioG711NotSupport and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioG711NotSupport, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioG711NotSupport and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioG711NotSupport, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioG711NotSupport and _resetPlayer error', e)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioG711NotSupport, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioG711NotSupport error and pause error', e)
                        })
                    }
                })

                // MSE
                // inner replay
                this.player.once(EVENTS_ERROR.mediaSourceAudioInitTimeout, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceAudioInitTimeout but player is destroyed');
                        return;
                    }
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player &&
                        this.player._opt.mediaSourceAudioInitTimeoutReplay) {
                        this.debug.log('JbPro', 'mediaSourceAudioInitTimeout and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {
                            mseDecodeAudio: false,
                        }
                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                this.debug.log('JbPro', 'mediaSourceAudioInitTimeout and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioInitTimeout, lastFrameInfo);
                                this.debug.error('JbPro', 'mediaSourceAudioInitTimeout and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioInitTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioInitTimeout and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioInitTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioInitTimeout and _resetPlayer error', e)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceAudioInitTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'mediaSourceAudioInitTimeout error and pause error', e)
                        })
                    }
                })

                // stream fetch error
                this.player.once(EVENTS_ERROR.mediaSourceUseCanvasRenderPlayFailed, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'mediaSourceUseCanvasRenderPlayFailed but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.mediaSourceUseCanvasRenderPlayFailed, error));

                    if (this.player &&
                        this.player._opt.mediaSourceUseCanvasRenderPlayFailedReplay &&
                        this.player._opt.mediaSourceUseCanvasRenderPlayFailedReplayType) {
                        this.debug.log('JbPro', `mediaSourceUseCanvasRenderPlayFailed relayType is ${this.player._opt.mediaSourceUseCanvasRenderPlayFailedReplayType} and reset player and play`)
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let resetPlayerOptions = {};
                        const replayType = this.player._opt.mediaSourceUseCanvasRenderPlayFailedReplayType
                        if (replayType === RENDER_TYPE.canvas) {
                            resetPlayerOptions = {useMSE: false, useWCS: false}
                        } else if (replayType === RENDER_TYPE.video) {
                            resetPlayerOptions = {useVideoRender: true, useCanvasRender: false}
                        }

                        this._resetPlayer(resetPlayerOptions).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'mediaSourceUseCanvasRenderPlayFailed and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceUseCanvasRenderPlayFailed);
                                this.debug.error('JbPro', 'mediaSourceUseCanvasRenderPlayFailed and reset player and play error', e);
                            });
                        }).catch((e) => {
                            this.debug.error('JbPro', 'mediaSourceUseCanvasRenderPlayFailed auto and _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.debug.log('JbPro', 'mediaSourceUseCanvasRenderPlayFailed and pause player success')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.mediaSourceUseCanvasRenderPlayFailed);
                            this.debug.error('JbPro', 'mediaSourceUseCanvasRenderPlayFailed and pause', e)
                        })
                    }
                })
                // webcodecs init error ,can not use mse decode only auto wasm
                this.player.once(EVENTS_ERROR.webcodecsH265NotSupport, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webcodecsH265NotSupport but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webcodecsH265NotSupport, error));

                    if (this.player && this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'webcodecsH265NotSupport auto wasm [wcs-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer({useMSE: false, useWCS: false}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webcodecsH265NotSupport auto wasm [wcs-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsH265NotSupport);
                                this.debug.error('JbPro', 'webcodecsH265NotSupport auto wasm [wcs-> wasm] reset player and play error', e);
                            });
                        }).catch((e) => {
                            this.debug.error('JbPro', 'webcodecsH265NotSupport auto wasm [wcs-> wasm] _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsH265NotSupport);
                            this.debug.log('JbPro', 'webcodecsH265NotSupport and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsH265NotSupport);
                            this.debug.error('JbPro', 'webcodecsH265NotSupport and pause error', e);
                        });
                    }
                })
                // webcodecs  init error ,can not use mse decode only auto wasm
                this.player.once(EVENTS_ERROR.webcodecsUnsupportedConfigurationError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webcodecsUnsupportedConfigurationError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webcodecsUnsupportedConfigurationError, error));

                    if (this.player && this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'webcodecsUnsupportedConfigurationError auto wasm [wcs-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer({useMSE: false, useWCS: false}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webcodecsUnsupportedConfigurationError auto wasm [wcs-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsUnsupportedConfigurationError);
                                this.debug.error('JbPro', 'webcodecsUnsupportedConfigurationError auto wasm [wcs-> wasm] reset player and play error', e);
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsUnsupportedConfigurationError);
                            this.debug.error('JbPro', 'webcodecsUnsupportedConfigurationError auto wasm [wcs-> wasm] _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsUnsupportedConfigurationError);
                            this.debug.log('JbPro', 'webcodecsUnsupportedConfigurationError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsUnsupportedConfigurationError);
                            this.debug.error('JbPro', 'webcodecsUnsupportedConfigurationError and pause error', e);
                        });
                    }
                })

                // webcodecs
                this.player.once(EVENTS_ERROR.webcodecsDecodeConfigureError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webcodecsDecodeConfigureError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webcodecsDecodeConfigureError, error));

                    if (this.player && this.player._opt.hardDecodingNotSupportAutoWasm) {
                        this.debug.log('JbPro', 'webcodecsDecodeConfigureError auto wasm [wcs-> wasm] reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer({useMSE: false, useWCS: false}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webcodecsDecodeConfigureError auto wasm [wcs-> wasm] reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeConfigureError);
                                this.debug.error('JbPro', 'webcodecsDecodeConfigureError auto wasm [wcs-> wasm] reset player and play error', e);
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeConfigureError);
                            this.debug.error('JbPro', 'webcodecsDecodeConfigureError auto wasm [wcs-> wasm] _resetPlayer and play error', e)
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeConfigureError);
                            this.debug.log('JbPro', 'webcodecsDecodeConfigureError and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeConfigureError);
                            this.debug.error('JbPro', 'webcodecsDecodeConfigureError and pause error', e);
                        });
                    }
                })

                // webcodecs
                this.player.once(EVENTS_ERROR.webcodecsDecodeError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webcodecsDecodeError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webcodecsDecodeError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.wcsDecodeErrorReplay) {
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.decoderErrorAutoWasm) {
                            options = {useMSE: false, useWCS: false}
                        }
                        this.debug.log('JbPro', `webcodecs decode error autoWasm [wcs-> ${this.player._opt.decoderErrorAutoWasm ? "wasm" : "wcs"}] reset player and play`)
                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }

                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'webcodecs decode error  reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeError, lastFrameInfo);
                                this.debug.error('JbPro', 'webcodecs decode error reset player and play error', e)
                            });
                        }).catch(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'webcodecs decode error _resetPlayer error')
                        })
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeError, lastFrameInfo);
                            this.debug.log('JbPro', 'webcodecs decode error and autoWasm is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webcodecsDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'webcodecs decode error and pause error', e)
                        });
                    }
                })
                // webcodecs
                this.player.once(EVENTS_ERROR.wcsWidthOrHeightChange, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'wcsWidthOrHeightChange but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.wcsWidthOrHeightChange, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.widthOrHeightChangeReplay) {
                        this.debug.log('JbPro', 'wcsWidthOrHeightChange and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {}
                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {

                            if (this._opt.widthOrHeightChangeReplayDelayTime > 0) {
                                setTimeout(() => {
                                    if (this.isDestroyed()) {
                                        this.debug.log('JbPro', 'wcsWidthOrHeightChange and widthOrHeightChangeReplayDelayTime but player is destroyed');
                                        return;
                                    }
                                    this.play(_url, _playOptions).then(() => {
                                        // resolve();
                                        this.debug.log('JbPro', 'wcsWidthOrHeightChange and reset player and play success')
                                    }).catch((e) => {
                                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wcsWidthOrHeightChange, lastFrameInfo);
                                        this.debug.error('JbPro', 'wcsWidthOrHeightChange and reset player and play error', e)
                                    });
                                }, this._opt.widthOrHeightChangeReplayDelayTime * 1000)
                            } else {
                                this.play(_url, _playOptions).then(() => {
                                    // resolve();
                                    this.debug.log('JbPro', 'wcsWidthOrHeightChange and reset player and play success')
                                }).catch((e) => {
                                    this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wcsWidthOrHeightChange, lastFrameInfo);
                                    this.debug.error('JbPro', 'wcsWidthOrHeightChange and reset player and play error', e)
                                });
                            }

                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wcsWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wcsWidthOrHeightChange and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wcsWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wcsWidthOrHeightChange and _resetPlayer error', e)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wcsWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wcsWidthOrHeightChange error and pause error', e)
                        })
                    }
                })
                // wasm。
                this.player.once(EVENTS_ERROR.wasmDecodeError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'wasmDecodeError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.wasmDecodeError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.wasmDecodeErrorReplay) {
                        this.debug.log('JbPro', 'wasm decode error and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'wasm decode error and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmDecodeError, lastFrameInfo);
                                this.debug.error('JbPro', 'wasm decode error and reset player and play error', e)
                            });
                        }).catch(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'wasm decode error and _resetPlayer error')
                        });

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmDecodeError, lastFrameInfo);
                            this.debug.log('JbPro', 'wasm decode error and wasmDecodeErrorReplay is false')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'wasm decode error and pause error', e)
                        })
                    }
                })
                // wasm simd
                this.player.once(EVENTS_ERROR.simdDecodeError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'simdDecodeError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.simdDecodeError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.simdDecodeErrorReplay) {
                        this.debug.log('JbPro', `simdDecodeError error simdDecodeErrorReplayType is ${this.player._opt.simdDecodeErrorReplayType} and reset player and play`)
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {}
                        if (this.player._opt.simdDecodeErrorReplayType === DECODE_TYPE.wasm) {
                            options = {useSIMD: false}
                        }

                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'simdDecodeError and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdDecodeError, lastFrameInfo);
                                this.debug.error('JbPro', 'simdDecodeError and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'simdDecodeError and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'simdDecodeError and simdDecodeErrorReplay is false');
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdDecodeError, lastFrameInfo);
                            this.debug.error('JbPro', 'simdDecodeError error and pause error', e)
                        })
                    }
                })
                // wasm
                this.player.once(EVENTS_ERROR.wasmWidthOrHeightChange, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'wasmWidthOrHeightChange but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.wasmWidthOrHeightChange, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player && this.player._opt.widthOrHeightChangeReplay) {
                        this.debug.log('JbPro', 'wasmWidthOrHeightChange and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {}
                        options = Object.assign({}, options, lastFrameInfo);
                        this._resetPlayer(options).then(() => {
                            if (this._opt.widthOrHeightChangeReplayDelayTime > 0) {
                                setTimeout(() => {
                                    if (this.isDestroyed()) {
                                        this.debug.log('JbPro', 'wasmWidthOrHeightChange and widthOrHeightChangeReplayDelayTime but player is destroyed');
                                        return;
                                    }

                                    this.play(_url, _playOptions).then(() => {
                                        // resolve();
                                        this.debug.log('JbPro', 'wasmWidthOrHeightChange and reset player and play success')
                                    }).catch((e) => {
                                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmWidthOrHeightChange, lastFrameInfo);
                                        this.debug.error('JbPro', 'wasmWidthOrHeightChange and reset player and play error', e)
                                    });
                                }, this._opt.widthOrHeightChangeReplayDelayTime * 1000)
                            } else {
                                this.play(_url, _playOptions).then(() => {
                                    // resolve();
                                    this.debug.log('JbPro', 'wasmWidthOrHeightChange and reset player and play success')
                                }).catch((e) => {
                                    this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmWidthOrHeightChange, lastFrameInfo);
                                    this.debug.error('JbPro', 'wasmWidthOrHeightChange and reset player and play error', e)
                                });
                            }


                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wasmWidthOrHeightChange and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wasmWidthOrHeightChange and _resetPlayer error', e)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmWidthOrHeightChange, lastFrameInfo);
                            this.debug.error('JbPro', 'wasmWidthOrHeightChange error and pause error', e)
                        })
                    }
                })
                // wasm 使用 video 渲染失败，降级到 canvas 渲染
                this.player.once(EVENTS_ERROR.wasmUseVideoRenderError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'wasmUseVideoRenderError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.wasmUseVideoRenderError, error));
                    this.debug.log('JbPro', 'wasmUseVideoRenderError and reset player and play')
                    const _url = this._opt.url;
                    const _playOptions = this._opt.playOptions;
                    this._resetPlayer({useVideoRender: false, useCanvasRender: true}).then(() => {
                        this.play(_url, _playOptions).then(() => {
                            // resolve();
                            this.debug.log('JbPro', 'wasmUseVideoRenderError and reset player and play success')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmUseVideoRenderError);
                            this.debug.error('JbPro', 'wasmUseVideoRenderError and reset player and play error', e)
                        });
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.wasmUseVideoRenderError);
                        this.debug.error('JbPro', 'wasmUseVideoRenderError and _resetPlayer error', e)
                    });
                })
                // video element play error
                this.player.once(EVENTS_ERROR.videoElementPlayingFailed, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'videoElementPlayingFailed but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.videoElementPlayingFailed, error));

                    if (this.player && this.player._opt.videoElementPlayingFailedReplay) {
                        this.debug.log('JbPro', `videoElementPlayingFailed and useMSE is ${this._opt.useMSE} and reset player and play`)
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        // 这边如果是 mse 解码的话，需要强制变为其他解码模式。
                        this._resetPlayer({
                            useMSE: false,
                            useVideoRender: false,
                            useCanvasRender: true
                        }).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'videoElementPlayingFailed and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailed);
                                this.debug.error('JbPro', 'videoElementPlayingFailed and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailed);
                            this.debug.error('JbPro', 'videoElementPlayingFailed and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailed);
                            this.debug.error('JbPro', 'videoElementPlayingFailed and videoElementPlayingFailedReplay is false');
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailed);
                            this.debug.error('JbPro', 'videoElementPlayingFailed and _pause error', e)
                        })
                    }
                })
                // simd
                this.player.once(EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'simdH264DecodeVideoWidthIsTooLarge but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge, error));

                    if (this.player && this.player._opt.simdH264DecodeVideoWidthIsTooLargeReplay) {
                        this.debug.log('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and reset player and play')
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        // downgrade to wasm
                        this._resetPlayer({useSIMD: false}).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge);
                                // reject();
                                this.debug.error('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and reset player and play error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge);
                            this.debug.error('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and _resetPlayer error', e)
                        });
                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge, lastFrameInfo);
                            this.debug.error('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and simdDecodeErrorReplay is false');
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge, lastFrameInfo);
                            this.debug.error('JbPro', 'simdH264DecodeVideoWidthIsTooLarge and pause error', e)
                        })
                    }
                })
                // 网络超时
                this.player.once(EVENTS.networkDelayTimeout, (error) => {
                    // 网络超时需要特殊处理，不需要调用pause方法，直接destroy,
                    // 也不抛出playFailedAndPaused事件;
                    if (this.player._opt.networkDelayTimeoutReplay) {
                        if (this.isDestroyed()) {
                            this.debug.log('JbPro', 'networkDelayTimeout but player is destroyed');
                            return;
                        }
                        this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS.networkDelayTimeout, error));
                        const lastFrameInfo = this._getVideoLastIframeInfo();
                        this.debug.log('JbPro', `network delay time out and reset player and play`)
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player && this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                this.debug.log('JbPro', 'wasm decode error and reset player and play success')
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS.networkDelayTimeout, lastFrameInfo);
                                this.debug.error('JbPro', 'wasm decode error and reset player and play error', e)
                            });
                        }).catch(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.networkDelayTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'wasm decode error and _resetPlayer error')
                        });
                    }
                })
                // stream fetch error
                this.player.once(EVENTS_ERROR.fetchError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'fetchError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.fetchError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'fetch error and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.fetchError, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.fetchError, lastFrameInfo);
                        this.debug.error('JbPro', 'fetch error and pause', e)
                    })
                })
                // stream end
                this.player.once(EVENTS.streamEnd, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'streamEnd but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS.streamEnd, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'streamEnd pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS.streamEnd, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS.streamEnd, lastFrameInfo);
                        this.debug.error('JbPro', 'streamEnd pause', e)
                    })
                })
                // stream websocket error
                this.player.once(EVENTS_ERROR.websocketError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'websocketError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.websocketError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'websocketError and reset player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.websocketError, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.websocketError, lastFrameInfo);
                        this.debug.error('JbPro', 'websocketError and pause', e)
                    })
                })
                // stream webrtc error
                this.player.once(EVENTS_ERROR.webrtcError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webrtcError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.webrtcError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'webrtcError and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webrtcError, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.webrtcError, lastFrameInfo);
                        this.debug.error('JbPro', 'webrtcError and pause', e)
                    })
                })
                // stream hls error
                this.player.once(EVENTS_ERROR.hlsError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'hlsError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.hlsError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'hlsError and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.hlsError, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.hlsError, lastFrameInfo);
                        this.debug.error('JbPro', 'hlsError and pause', e)
                    })
                })

                // stream AliyunRtc error
                this.player.once(EVENTS_ERROR.aliyunRtcError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'aliyunRtcError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.aliyunRtcError, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();
                    this.debug.log('JbPro', 'aliyunRtcError and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.aliyunRtcError, lastFrameInfo);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.aliyunRtcError, lastFrameInfo);
                        this.debug.error('JbPro', 'aliyunRtcError and pause', e)
                    })
                })
                // decoder worker init error
                this.player.once(EVENTS_ERROR.decoderWorkerInitError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'decoderWorkerInitError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.decoderWorkerInitError, error));
                    this.debug.log('JbPro', 'decoderWorkerInitError and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.decoderWorkerInitError);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.decoderWorkerInitError);
                        this.debug.error('JbPro', 'decoderWorkerInitError and pause', e)
                    })
                })
                // webrtc h265 stream fetch error
                this.player.once(EVENTS_ERROR.videoElementPlayingFailedForWebrtc, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'videoElementPlayingFailedForWebrtc but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.videoElementPlayingFailedForWebrtc, error));
                    this.debug.log('JbPro', 'videoElementPlayingFailedForWebrtc and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailedForWebrtc);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoElementPlayingFailedForWebrtc);
                        this.debug.error('JbPro', 'videoElementPlayingFailedForWebrtc and pause', e)
                    })
                })
                // video Info Error width is undefined or height is undefined
                this.player.once(EVENTS_ERROR.videoInfoError, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'videoInfoError but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS_ERROR.videoInfoError, error));
                    this.debug.log('JbPro', 'videoInfoError and pause player');
                    const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                    this._pause(isClear).then(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoInfoError);
                    }).catch((e) => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.videoInfoError);
                        this.debug.error('JbPro', 'videoInfoError and pause', e)
                    })
                })
                // webrtc h265 直接触发重播逻辑的。
                this.player.once(EVENTS.webrtcStreamH265, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'webrtcStreamH265 but player is destroyed');
                        return;
                    }
                    // 需要特殊处理，不需要调用pause方法，直接destroy,
                    // 也不抛出playFailedAndPaused事件;
                    this.debug.log('JbPro', `webrtcStreamH265 and reset player and play`)
                    const _url = this._opt.url;
                    const _playOptions = this._opt.playOptions;
                    const options = {
                        isWebrtcH265: true,
                    };
                    this._resetPlayer(options).then(() => {
                        this.play(_url, _playOptions).then(() => {
                            // resolve();
                            this.debug.log('JbPro', 'webrtcStreamH265 and reset player and play success')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.webrtcStreamH265);
                            this.debug.error('JbPro', 'webrtcStreamH265 and reset player and play error', e)
                        });
                    }).catch(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS.webrtcStreamH265);
                        this.debug.error('JbPro', 'webrtcStreamH265 and _resetPlayer error')
                    });
                })
                // hls h265 直接触发重播逻辑的。
                this.player.once(EVENTS_ERROR.hlsV2Mp4NotSupport, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'hlsV2Mp4NotSupport but player is destroyed');
                        return;
                    }
                    // 需要特殊处理，不需要调用pause方法，直接destroy,
                    // 也不抛出playFailedAndPaused事件;
                    this.debug.log('JbPro', `hlsV2Mp4NotSupport and reset player and play`)
                    const _url = this._opt.url;
                    const _playOptions = this._opt.playOptions;
                    const options = {
                        supportHls265: false
                    };
                    this._resetPlayer(options).then(() => {
                        this.play(_url, _playOptions).then(() => {
                            // resolve();
                            this.debug.log('JbPro', 'hlsV2Mp4NotSupport and reset player and play success')
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.hlsV2Mp4NotSupport);
                            this.debug.error('JbPro', 'hlsV2Mp4NotSupport and reset player and play error', e)
                        });
                    }).catch(() => {
                        this.emit(EVENTS.playFailedAndPaused, EVENTS_ERROR.hlsV2Mp4NotSupport);
                        this.debug.error('JbPro', 'hlsV2Mp4NotSupport and _resetPlayer error')
                    });
                })
                // 监听 delay timeout
                this.player.on(EVENTS.delayTimeout, (error) => {
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'delay timeout but player is destroyed');
                        return;
                    }
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS.delayTimeout, error));
                    const lastFrameInfo = this._getVideoLastIframeInfo();

                    if (this.player &&
                        this.player._opt.heartTimeoutReplay &&
                        (this._heartTimeoutReplayTimes < this.player._opt.heartTimeoutReplayTimes ||
                            this.player._opt.heartTimeoutReplayTimes === -1)) {
                        this.debug.log('JbPro', `delay timeout replay time is ${this._heartTimeoutReplayTimes} and heartTimeoutReplayTimes is ${this.player._opt.heartTimeoutReplayTimes}`)
                        if (this.isDestroyed()) {
                            this.debug && this.debug.warn('JbPro', 'delay timeout replay but player is destroyed')
                            return;
                        }

                        this._heartTimeoutReplayTimes += 1;
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        let options = {};
                        if (this.player._opt.heartTimeoutReplayUseLastFrameShow
                            || this.player._opt.replayUseLastFrameShow) {
                            options = Object.assign({}, options, lastFrameInfo, {
                                loadingIcon: this.player._opt.replayShowLoadingIcon
                            });
                        }
                        // support play and playback
                        this._resetPlayer(options).then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                // this._heartTimeoutReplayTimes = 0;
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS.delayTimeout, lastFrameInfo);
                                this.debug.error('JbPro', 'delay timeout replay error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.delayTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'delay timeout _resetPlayer error', e)
                        });

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.delayTimeout, lastFrameInfo);
                            if (this.player) {
                                this.emit(EVENTS.delayTimeoutRetryEnd);
                                this.emit(EVENTS.playFailedAndPaused, EVENTS.delayTimeoutRetryEnd);
                            }
                            this.debug.warn('JbPro', `delayTimeoutRetryEnd and
                            opt.heartTimeout is ${this.player && this.player._opt.heartTimeout} and
                            opt.heartTimeoutReplay is ${this.player && this.player._opt.heartTimeoutReplay} and
                            opt.heartTimeoutReplayTimes is ${this.player && this.player._opt.heartTimeoutReplayTimes},and
                            local._heartTimeoutReplayTimes is ${this._heartTimeoutReplayTimes}`);
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.delayTimeout, lastFrameInfo);
                            this.debug.error('JbPro', 'delay timeout and pause error', e)
                        })
                    }
                })
                // 监听 loading timeout
                this.player.on(EVENTS.loadingTimeout, (error) => {
                    this.emit(EVENTS.crashLog, this.getCrashLog(EVENTS.loadingTimeout, error));
                    if (this.isDestroyed()) {
                        this.debug.log('JbPro', 'loading timeout but player is destroyed');
                        return;
                    }

                    if (this.player &&
                        this.player._opt.loadingTimeoutReplay &&
                        (this._loadingTimeoutReplayTimes < this.player._opt.loadingTimeoutReplayTimes ||
                            this.player._opt.loadingTimeoutReplayTimes === -1)) {
                        this.debug.log('JbPro', `loading timeout and
                             replay time is ${this._loadingTimeoutReplayTimes} and
                             loadingTimeoutReplayTimes is ${this.player._opt.loadingTimeoutReplayTimes}`)
                        if (this.isDestroyed()) {
                            this.debug && this.debug.warn('JbPro', 'delay timeout replay but player is destroyed')
                            return;
                        }
                        this._loadingTimeoutReplayTimes += 1;
                        const _url = this._opt.url;
                        const _playOptions = this._opt.playOptions;
                        this._resetPlayer().then(() => {
                            this.play(_url, _playOptions).then(() => {
                                // resolve();
                                // this._loadingTimeoutReplayTimes = 0;
                            }).catch((e) => {
                                this.emit(EVENTS.playFailedAndPaused, EVENTS.loadingTimeout);
                                this.debug.error('JbPro', 'loading timeout replay error', e)
                            });
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.loadingTimeout);
                            this.debug.error('JbPro', 'loading timeout _resetPlayer error', e)
                        });

                    } else {
                        const isClear = this._opt.playFailedUseLastFrameShow === false ? true : false;
                        this._pause(isClear).then(() => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.loadingTimeout);
                            if (this.player) {
                                this.emit(EVENTS.loadingTimeoutRetryEnd);
                                this.emit(EVENTS.playFailedAndPaused, EVENTS.loadingTimeoutRetryEnd);
                            }
                            this.debug.log('JbPro', `loadingTimeoutRetryEnd and
                            opt.loadingTimeout is ${this.player && this.player._opt.loadingTimeout} and
                            opt.loadingTimeoutReplay is ${this.player && this.player._opt.loadingTimeoutReplay} and
                            local._loadingTimeoutReplayTimes time is ${this._loadingTimeoutReplayTimes} and
                            opt.loadingTimeoutReplayTimes is ${this.player && this.player._opt.loadingTimeoutReplayTimes}`)
                        }).catch((e) => {
                            this.emit(EVENTS.playFailedAndPaused, EVENTS.loadingTimeout);
                            this.debug.error('JbPro', 'loading timeout and pause error', e)
                        })
                    }
                })

                if (this._hasLoaded()) {
                    this.player.play(url, options).then(() => {
                        resolve();
                    }).catch((e) => {
                        this.debug.error('JbPro', '_hasLoaded() and play error', e)
                        this.emit(EVENTS.crashLog, this.getCrashLog('_hasLoaded() and play error', e));
                        this.player && this.player.pause().then(() => {
                            reject(e);
                        }).catch((e) => {
                            reject(e);
                            this.debug.error('JbPro', '_hasLoaded() and play error and next pause error', e)
                        })
                    })
                } else {
                    this.player.once(EVENTS.decoderWorkerInit, () => {
                        this.player.play(url, options).then(() => {
                            resolve();
                        }).catch((e) => {
                            this.debug.error('JbPro', 'decoderWorkerInit and play error', e)
                            this.emit(EVENTS.crashLog, this.getCrashLog('decoderWorkerInit and play error', e));
                            this.player && this.player.pause().then(() => {
                                reject(e);
                            }).catch((e) => {
                                reject(e);
                                this.debug.error('JbPro', 'decoderWorkerInit and play error and next pause error', e)
                            })
                        })
                    })
                }
            }
            const isOldHls = (isHls && isFalse(this._opt.supportHls265))
            const isOldWebrtc = (isWebrtc && isFalse(this._opt.isWebrtcH265))
            const isHlsCanVideoPlay = isHls && !!canPlayAppleMpegurl();
            if (isOldHls ||
                isOldWebrtc ||
                isAliyunRtc ||
                needResetPlayer ||
                isMpeg4 ||
                isHlsCanVideoPlay) {
                this.debug.log('JbPro', `need reset player and
                isOldHls is ${isOldHls} and isOldWebrtc is ${isOldWebrtc} and isAliyunRtc is ${isAliyunRtc} and needResetPlayer(url change) is ${needResetPlayer} and isMpeg4 is ${isMpeg4} and isHlsCanVideoPlay is ${isHlsCanVideoPlay}`);
                this._resetPlayer({
                    protocol,
                    demuxType,
                    isHls,
                    isWebrtc,
                    isWebrtcForZLM,
                    isWebrtcForSRS,
                    isWebrtcForOthers,
                    isAliyunRtc,
                    cryptoKey: options.cryptoKey || '',
                    cryptoIV: options.cryptoIV || '',
                    url
                }).then(() => {
                    _playNext();
                }).catch((e) => {
                    reject('reset player error');
                })
            } else {
                const newOptions = {
                    protocol,
                    demuxType,
                    isHls,
                    isWebrtc,
                    isAliyunRtc,
                    isFlv: this._opt.isFlv,
                    isFmp4: this._opt.isFmp4,
                    isMpeg4: this._opt.isMpeg4,
                    isNakedFlow: this._opt.isNakedFlow,
                    cryptoKey: options.cryptoKey || '',
                    cryptoIV: options.cryptoIV || '',
                }
                if (this._opt.isNakedFlow) {
                    newOptions.mseDecodeAudio = false;
                }

                this.player.updateOption(newOptions);

                if (options.cryptoKey && options.cryptoIV) {
                    if (this.player.decoderWorker) {
                        this.player.decoderWorker.updateWorkConfig({
                            key: 'cryptoKey', value: options.cryptoKey
                        })
                        this.player.decoderWorker.updateWorkConfig({
                            key: 'cryptoIV', value: options.cryptoIV
                        })
                    }
                }
                _playNext();
            }
        })
    }


    _resetDemuxType(type) {
        this._opt.isFlv = false;
        this._opt.isFmp4 = false;
        this._opt.isMpeg4 = false;
        this._opt.isNakedFlow = false;
        this._opt.isHls = false;
        this._opt.isWebrtc = false;
        this._opt.isWebrtcForZLM = false;
        this._opt.isWebrtcForSRS = false;
        this._opt.isWebrtcForOthers = false;
        this._opt.isAliyunRtc = false;

        if (type) {
            this._opt[type] = true;
        }

        if (type !== 'isFmp4') {
            this._opt.isFmp4Private = false;
        }
    }

    /**
     *
     */
    resize() {
        this.debug.log('JbPro', 'resize()');
        this.player && this.player.resize();
    }

    /**
     *
     * @param time {number}
     */
    setBufferTime(time) {
        this.debug.log('JbPro', `setBufferTime() ${time}`);
        time = Number(time)

        if (time > 10) {
            this.debug.warn('JbPro', `setBufferTime() buffer time is ${time} second, is too large, video will show blank screen until cache ${time} second buffer data`)
        }

        const videoBuffer = time * 1000;

        this._opt.videoBuffer = videoBuffer

        if (this.player) {
            // s -> ms
            this.player.updateOption({
                videoBuffer: videoBuffer
            }, true)
        } else {
            this.debug.warn('JbPro', 'setBufferTime() player is null');

        }

    }

    /**
     *
     * @param time
     */
    setBufferDelayTime(time) {
        this.debug.log('JbPro', `setBufferDelayTime() ${time}`);
        time = Number(time)
        if (time < 0.2) {
            this.debug.warn('JbPro', `setBufferDelayTime() buffer time delay is ${time} second, is too small`)
        }

        time = clamp(time, 0.2, 100)

        const videoBufferDelay = time * 1000;

        this._opt.videoBufferDelay = videoBufferDelay;

        if (this.player) {
            // s -> ms
            this.player.updateOption({
                videoBufferDelay: videoBufferDelay
            }, true)
        } else {
            this.debug.warn('JbPro', 'setBufferDelayTime() player is null');
        }
    }

    /**
     *
     * @param deg {number}
     */
    setRotate(deg) {
        this.debug.log('JbPro', `setRotate() ${deg}`);
        deg = parseInt(deg, 10)
        const list = [0, 90, 180, 270];
        if (this._opt.rotate === deg || list.indexOf(deg) === -1) {
            this.debug.warn('JbPro', `setRotate() rotate is ${deg} and this._opt.rotate is ${this._opt.rotate}`);
            return;
        }

        this._opt.rotate = deg;

        if (this.player) {
            this.player.updateOption({
                rotate: deg
            })
            this.resize();
        } else {
            this.debug.warn('JbPro', 'setRotate() player is null');
        }
    }

    /**
     * 设置镜像翻转
     * @param type
     */
    setMirrorRotate(mirrorRotate) {
        this.debug.log('JbPro', `setMirrorRotate() ${mirrorRotate}`);
        const list = ['none', 'level', 'vertical'];

        if (!mirrorRotate) {
            mirrorRotate = 'none';
        }

        if (this._opt.mirrorRotate === mirrorRotate || list.indexOf(mirrorRotate) === -1) {
            this.debug.warn('JbPro', `setMirrorRotate() mirrorRotate is ${mirrorRotate} and this._opt.mirrorRotate is ${this._opt.mirrorRotate}`);
            return;
        }

        this._opt.mirrorRotate = mirrorRotate;

        if (this.player) {
            this.player.updateOption({
                mirrorRotate
            })

            this.resize()
        } else {
            this.debug.warn('JbPro', 'setMirrorRotate() player is null');
        }
    }

    setAspectRatio(aspectRatio) {
        this.debug.log('JbPro', `setAspectRatio() ${aspectRatio}`);
        const list = ['default', '4:3', '16:9'];

        if (!aspectRatio) {
            aspectRatio = 'default';
        }

        if (this._opt.aspectRatio === aspectRatio || list.indexOf(aspectRatio) === -1) {
            this.debug.warn('JbPro', `setAspectRatio() aspectRatio is ${aspectRatio} and this._opt.aspectRatio is ${this._opt.mirrorRotate}`);
            return;
        }

        this._opt.aspectRatio = aspectRatio;
        if (this.player) {
            this.player.updateOption({
                aspectRatio
            })

            this.resize()
        } else {
            this.debug.warn('JbPro', 'setAspectRatio() player is null');
        }
    }

    /**
     *
     * @returns {boolean}
     */
    hasLoaded() {
        return true;
    }

    /**
     * inner method
     * @returns {Player|*|boolean}
     * @private
     */
    _hasLoaded() {
        return (this.player && this.player.loaded) || false;
    }

    /**
     *
     */
    setKeepScreenOn() {
        this.debug.log('JbPro', 'setKeepScreenOn()');
        this._opt.keepScreenOn = true;
        if (this.player) {
            this.player.updateOption({
                keepScreenOn: true
            })
        } else {
            this.debug.warn('JbPro', 'setKeepScreenOn() player is not ready');
        }
    }

    /**
     *
     * @param flag {Boolean}
     */
    setFullscreen(flag) {
        this.debug.log('JbPro', `setFullscreen() ${flag}`);
        const fullscreen = !!flag;
        if (!this.player) {
            this.debug.warn('JbPro', 'setFullscreen() player is not ready');
            return
        }
        if (this.player.fullscreen !== fullscreen) {
            this.player.fullscreen = fullscreen;
        } else {
            this.debug.warn('JbPro', `setFullscreen() fullscreen is ${fullscreen} and this.player.fullscreen is ${this.player.fullscreen}`);
        }
    }

    setWebFullscreen(flag) {
        this.debug.log('JbPro', `setWebFullscreen() ${flag}`);
        const webFullscreen = !!flag;
        if (!this.player) {
            this.debug.warn('JbPro', 'setWebFullscreen() player is not ready');
            return;
        }

        this.player.webFullscreen = webFullscreen;
    }

    /**
     *
     * @param filename {string}
     * @param format {string}
     * @param quality {number}
     * @param type {string} download,base64,blob
     */
    screenshot(filename, format, quality, type) {
        this.debug.log('JbPro', `screenshot() ${filename} ${format} ${quality} ${type}`);
        if (this.player && this.player.video) {
            return this.player.video.screenshot(filename, format, quality, type)
        } else {
            this.debug.warn('JbPro', 'screenshot() player is not ready');
        }
        return null;
    }

    /**
     *
     * @param options
     * @returns Promise
     */
    screenshotWatermark(options) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'screenshotWatermark()', options);
            if (this.player && this.player.video) {
                this.player.video.screenshotWatermark(options).then((data) => {
                    resolve(data);
                }).catch((e) => {
                    reject(e);
                })
            } else {
                this.debug.warn('JbPro', 'screenshotWatermark() player is not ready');
                reject('player is not ready');
            }
        })
    }

    /**
     *
     * @param fileName {string}
     * @param fileType {string}
     * @returns {Promise<unknown>}
     */
    startRecord(fileName, fileType) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `startRecord() ${fileName} ${fileType}`);
            if (!this.player) {
                this.debug.warn('JbPro', 'startRecord() player is not ready');
                return reject('player is not ready');
            }
            if (this.player.playing) {
                this.player.startRecord(fileName, fileType)
                resolve();
            } else {
                this.debug.warn('JbPro', 'startRecord() player is not playing');
                reject('not playing');
            }
        })
    }

    stopRecordAndSave(type, fileName) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', `stopRecordAndSave() ${type} ${fileName}`);
            if (this.player && this.player.recording) {
                this.player.stopRecordAndSave(type, fileName).then((blob) => {
                    resolve(blob);
                }).catch((e) => {
                    reject(e);
                })
            } else {
                reject('not recording');
            }
        })
    }

    /**
     *
     * @returns {Boolean}
     */
    isPlaying() {
        let result = false;
        if (this.player) {
            result = this.player.isPlaying();
        }

        return result;
    }

    /**
     *
     * @returns {boolean}
     */
    isLoading() {
        return this.player ? this.player.loading : false;
    }

    /**
     *
     * @returns {boolean}
     */
    isPause() {
        let result = false;
        if (this._opt.playType === PLAY_TYPE.player) {
            result = !this.isPlaying() && !this.isLoading();
        } else if (this._opt.playType === PLAY_TYPE.playbackTF && this.player) {
            result = this.player.playbackPause;
        }
        return result;
    }

    isPaused() {
        return this.isPause();
    }

    /**
     *
     * @returns {boolean}
     */
    isPlaybackPause() {
        let result = false;

        if (this._opt.playType === PLAY_TYPE.playbackTF && this.player) {
            result = this.player.playbackPause;
        }

        return result;
    }

    /**
     * 是否静音状态
     * @returns {Boolean}
     */
    isMute() {
        let result = true;
        if (this.player) {
            result = this.player.isAudioMute();
        }
        return result;
    }

    /**
     * 是否在录制视频
     * @returns {*}
     */
    isRecording() {
        return (this.player && this.player.recorder) && this.player.recorder.recording || false;
    }

    isFullscreen() {
        let result = false;
        if (this.player) {
            result = this.player.fullscreen;
        }
        return result;
    }

    isWebFullscreen() {
        let result = false;
        if (this.player) {
            result = this.player.webFullscreen;
        }
        return result;
    }


    /**
     * 清除延迟
     */
    clearBufferDelay() {
        this.debug.log('JbPro', 'clearBufferDelay()');
        if (this.player) {
            this.player.clearBufferDelay();
        } else {
            this.debug.warn('JbPro', 'clearBufferDelay() player is not init');
        }
    }

    setNetworkDelayTime(time) {
        this.debug.log('JbPro', `setNetworkDelayTime() ${time}`);
        time = Number(time)

        if (time < 1) {
            this.debug.warn('JbPro', `setNetworkDelayTime() network delay is ${time} second, is too small`)
        }

        time = clamp(time, 1, 100)

        const networkDelay = time * 1000;

        this._opt.networkDelay = networkDelay;

        if (this.player) {
            // s -> ms
            this.player.updateOption({
                networkDelay: networkDelay
            }, true)
        } else {
            this.debug.warn('JbPro', 'setNetworkDelayTime() player is null')
        }
    }

    /**
     * 获取解码方式
     */
    getDecodeType() {
        let result = '';
        if (this.player) {
            result = this.player.getDecodeType();
        }
        return result;
    }

    getRenderType() {
        let result = '';

        if (this.player) {
            result = this.player.getRenderType();
        }

        return result;
    }

    getAudioEngineType() {
        let result = '';
        if (this.player) {
            result = this.player.getAudioEngineType();
        }
        return result;
    }

    /**
     * get playing timestamp
     * @returns {number}
     */
    getPlayingTimestamp() {
        let result = 0;
        if (this.player) {
            result = this.player.getPlayingTimestamp();
        }
        return result;
    }

    /**
     * get player now status
     * @returns {string}
     */
    getStatus() {
        let result = PLAYER_STATUS.destroy;

        if (this.player) {
            if (this.player.loading) {
                result = PLAYER_STATUS.loading
            } else {
                if (this.player.playing) {
                    result = PLAYER_STATUS.playing
                } else {
                    result = PLAYER_STATUS.paused
                }
            }
        }
        return result;
    }

    getPlayType() {
        return this.player ? this.player._opt.playType : PLAY_TYPE.player;
    }

    togglePerformancePanel(flag) {
        this.debug.log('JbPro', `togglePerformancePanel() ${flag}`);
        const prev = this.player._opt.showPerformance;
        let toggleResult = !prev;
        if (isBoolean(flag)) {
            toggleResult = flag;
        }
        if (toggleResult === prev) {
            this.debug.warn('JbPro', `togglePerformancePanel() failed, showPerformance is prev: ${prev} === now: ${toggleResult}`);
            return
        }
        if (this.player) {
            this.player.togglePerformancePanel(toggleResult);
        } else {
            this.debug.warn('JbPro', 'togglePerformancePanel() failed, this.player is not init')
        }
    }

    /**
     *
     */
    openZoom() {
        this.debug.log('JbPro', 'openZoom()');
        if (this.player) {
            this.player.zooming = true;
        } else {
            this.debug.warn('JbPro', 'openZoom() failed, this.player is not init')
        }
    }

    /**
     *
     */
    closeZoom() {
        this.debug.log('JbPro', 'closeZoom()');
        if (this.player) {
            this.player.zooming = false;
        } else {
            this.debug.warn('JbPro', 'closeZoom() failed, this.player is not init')
        }
    }

    /**
     *
     * @returns {boolean}
     */
    isZoomOpen() {
        let result = false;
        if (this.player) {
            result = this.player.zooming;
        }
        return result;
    }

    /**
     *
     * @param flag
     */
    toggleZoom(flag) {
        this.debug.log('JbPro', `toggleZoom() ${flag}`);

        if (!isBoolean(flag)) {
            flag = !this.isZoomOpen();
        }

        if (isTrue(flag)) {
            this.openZoom()
        } else if (isFalse(false)) {
            this.closeZoom();
        }
    }


    /**
     *
     */
    expandZoom() {
        this.debug.log('JbPro', 'expandZoom()');

        if (this.player && this.player.zoom && this.player.zooming) {
            this.player.zoom.expandPrecision();
        } else {
            this.debug.warn('JbPro', 'expandZoom() failed, zoom is not open or not init')
        }
    }

    /**
     *
     */
    narrowZoom() {
        this.debug.log('JbPro', 'narrowZoom()');
        if (this.player && this.player.zoom && this.player.zooming) {
            this.player.zoom.narrowPrecision();
        } else {
            this.debug.warn('JbPro', 'narrowZoom failed, zoom is not open or not init')
        }
    }

    /**
     *
     * @returns {number}
     */
    getCurrentZoomIndex() {
        let result = 1;
        if (this.player && this.player.zoom) {
            result = this.player.zoom.currentZoom;
        }
        return result;
    }

    //---------------------------------------------------------------  talk start ---------------------------------------------

    /**
     *
     * @param wsUrl
     * @param options
     * @returns {Promise<unknown>}
     */
    startTalk(wsUrl, options = {}) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'startTalk()', wsUrl, options);
            this._initTalk(options)

            this.talk.startTalk(wsUrl).then(() => {
                resolve()

                this.talk.once(EVENTS.talkStreamClose, () => {
                    this.debug.warn('JbPro', 'talk stream close')
                    this.stopTalk().catch((e) => {

                    });
                })
                this.talk.once(EVENTS.talkStreamError, (e) => {
                    this.debug.warn('JbPro', 'talk stream error', e);
                    this.stopTalk().catch((e) => {

                    });
                })

                this.talk.once(EVENTS.talkStreamInactive, () => {
                    this.debug.warn('JbPro', 'talk stream inactive');
                    this.stopTalk().catch((e) => {

                    });
                })

            }).catch((e) => {
                reject(e)
            })
        })
    }

    /**
     *
     * @returns {Promise<unknown>}
     */
    stopTalk() {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'stopTalk()');
            if (!this.talk) {
                return reject('stopTalk() talk is not init');
            }
            this.talk.destroy();
            resolve();
        })
    }

    /**
     *
     * @returns {Promise<unknown>}
     */
    getTalkVolume() {
        return new Promise((resolve, reject) => {
            if (!this.talk) {
                return reject('getTalkVolume() talk is not init')
            }
            let result = this.talk.volume;
            resolve(result);
        })
    }

    /**
     *
     * @param volume
     * @returns {Promise<unknown>}
     */
    setTalkVolume(volume) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'setTalkVolume()', volume);
            if (!this.talk) {
                return reject('setTalkVolume() talk is not init')
            }
            this.talk.setVolume(volume / 100);
            resolve();
        })
    }


    //---------------------------------------------------------------  talk end ---------------------------------------------


    setNakedFlowFps(fps) {
        return new Promise((resolve, reject) => {
            this.debug.log('JbPro', 'setNakedFlowFps()', fps);

            if (isEmpty(fps)) {
                return reject('setNakedFlowFps() fps is empty')
            }

            let _fps = Number(fps)
            _fps = clamp(_fps, 1, 100)

            this._opt.nakedFlowFps = _fps;

            if (this.player) {
                this.player.updateOption({
                    nakedFlowFps: _fps
                })
            } else {
                this.debug.warn('JbPro', 'setNakedFlowFps() player is null')
            }

            resolve();
        });
    }

    getCrashLog(type, error = '') {
        if (!this.player) {
            return;
        }

        const statsData = this.player.getAllStatsData();
        const player = this.player;
        let result = {
            url: this._opt.url,
            playType: player.isPlayback() ? 'playback' : 'live',
            demuxType: player.getDemuxType(),
            decoderType: player.getDecodeType(),
            renderType: player.getRenderType(),
            videoInfo: {
                encType: '',
                width: '',
                height: '',
            },
            audioInfo: {
                encType: '',
                sampleRate: '',
                channels: '',
            },
            audioEngine: player.getAudioEngineType(),
            allTimes: statsData.pTs,
            timestamp: now(), // current timestamp
            type: type,
            error: errorToString(error) || type,
        }

        if (player.video) {
            const videoInfo = player.video.videoInfo || {};
            result.videoInfo = {
                encType: videoInfo.encType || '',
                width: videoInfo.width || '',
                height: videoInfo.height || '',
            };
        }

        if (player.audio) {
            const audioInfo = player.audio.audioInfo || {};
            result.audioInfo = {
                encType: audioInfo.encType || '',
                sampleRate: audioInfo.sampleRate || '',
                channels: audioInfo.channels || '',
            };
        }

        return result;
    }

    updateDebugLevel(level) {
        this.debug.log('JbPro', 'updateDebugLevel()', level);
        if (!(level === DEBUG_LEVEL.debug || level === DEBUG_LEVEL.warn)) {
            this.debug.warn('JbPro', `updateDebugLevel() level is not valid, level: ${level}`);
            return;
        }

        if (level === this.player._opt.debugLevel) {
            this.debug.warn('JbPro', `updateDebugLevel() level is same, level: ${level}`);
            return;
        }

        this._opt.debugLevel = level;

        if (this.player) {
            this.player.updateOption({
                debugLevel: level
            }, true)
        } else {
            this.debug.warn('JbPro', 'updateDebugLevel() player is null');

        }

    }

    updateWatermark(config) {
        this.debug.log('JbPro', 'updateWatermark()', config);
        if (this.player) {
            this.player.updateWatermark(config);
        } else {
            this.debug.warn('JbPro', 'updateWatermark() player is not init');
        }
    }

    removeWatermark() {
        this.debug.log('JbPro', 'removeWatermark()');
        if (this.player) {
            this.player.removeWatermark();
        } else {
            this.debug.warn('JbPro', 'removeWatermark() player is not init');
        }
    }

    updateFullscreenWatermark(config) {
        this.debug.log('JbPro', 'updateFullscreenWatermark()', config);
        if (isNotEmptyObject(config)) {
            // update config
            this._opt.fullscreenWatermarkConfig = config;
            const watermarkConfig = formatFullscreenWatermarkOptions(this.$container, config);
            if (!watermarkConfig.watermark_txt) {
                this.debug.warn('JbPro', 'fullscreenWatermarkConfig text is empty');
                return;
            }
            this.watermark.load(watermarkConfig);
        } else {
            this.debug.warn('JbPro', `updateFullscreenWatermark() config is not valid, config: ${config}`);
        }
    }

    removeFullscreenWatermark() {
        this.debug.log('JbPro', 'removeFullscreenWatermark()');
        if (this.watermark) {
            this.watermark.remove();
        } else {
            this.debug.warn('JbPro', 'removeFullscreenWatermark() watermark is not init');
        }
    }

    faceDetectOpen() {
        this.debug.log('JbPro', 'faceDetectOpen()');
        if (this.player) {
            this.player.faceDetect(true);
        } else {
            this.debug.warn('JbPro', 'faceDetectOpen() player is not init');
        }
    }

    faceDetectClose() {
        this.debug.log('JbPro', 'faceDetectClose()');
        if (this.player) {
            this.player.faceDetect(false);
        } else {
            this.debug.warn('JbPro', 'faceDetectClose() player is not init');
        }
    }

    objectDetectOpen() {
        this.debug.log('JbPro', 'objectDetectOpen()');

        if (this.player) {
            this.player.objectDetect(true);
        } else {
            this.debug.warn('JbPro', 'objectDetectOpen() player is not init');
        }
    }

    objectDetectClose() {
        this.debug.log('JbPro', 'objectDetectClose()');

        if (this.player) {
            this.player.objectDetect(false);
        } else {
            this.debug.warn('JbPro', 'objectDetectClose() player is not init');
        }
    }

    sendWebsocketMessage(msg) {
        this.debug.log('JbPro', 'sendWebsocketMessage()', msg);
        if (this.player) {
            this.player.sendWebsocketMessage(msg);
        } else {
            this.debug.warn('JbPro', 'sendWebsocketMessage() player is not init');
        }
    }

    //
    addContentToCanvas(contentList) {
        this.debug.log('JbPro', 'addContentToCanvas()');
        if (this.player) {
            this.player.addContentToCanvas(contentList);
        } else {
            this.debug.warn('JbPro', 'addContentToCanvas() player is not init');
        }
    }

    clearContentToCanvas() {
        this.debug.log('JbPro', 'clearContentToCanvas()');
        if (this.player) {
            this.player.addContentToCanvas([]);
        } else {
            this.debug.warn('JbPro', 'clearContentToCanvas() player is not init');
        }
    }

    setControlHtml(html) {
        this.debug.log('JbPro', 'setControlHtml()', html);
        if (this.player) {
            this.player.setControlHtml(html);
        } else {
            this.debug.warn('JbPro', 'setControlHtml() player is not init');
        }
    }

    clearControlHtml() {
        this.debug.log('JbPro', 'clearControlHtml()');
        if (this.player) {
            this.player.clearControlHtml();
        } else {
            this.debug.warn('JbPro', 'clearControlHtml() player is not init');
        }
    }

    getVideoInfo() {
        let result = null;
        if (this.player) {
            result = this.player.getVideoInfo();
        }
        return result;
    }

    getAudioInfo() {
        let result = null;
        if (this.player) {
            result = this.player.getAudioInfo();
        }
        return result;
    }

    setSm4CryptoKey(key) {
        this.debug.log('JbPro', 'setSm4CryptoKey()', key);

        key = '' + key;

        if (key.length !== 32) {
            this.debug.warn('JbPro', `setSm4CryptoKey() key is invalid and length is ${key.length} !== 32`);
            return;
        }

        this._opt.sm4CryptoKey = key;

        if (this.player) {
            this.player.updateOption({
                sm4CryptoKey: key
            }, true)
        } else {
            this.debug.warn('JbPro', 'setSm4CryptoKey() player is null');
        }
    }

    setM7sCryptoKey(key) {
        this.debug.log('JbPro', 'setM7sCryptoKey()', key);

        key = '' + key;

        this._opt.m7sCryptoKey = key;

        if (this.player) {
            this.player.updateOption({
                m7sCryptoKey: key
            }, true)
        } else {
            this.debug.warn('JbPro', 'setM7sCryptoKey() player is null');
        }
    }

    setXorCryptoKey(key) {
        this.debug.log('JbPro', 'setXorCryptoKey()', key);

        key = '' + key;

        this._opt.xorCryptoKey = key;

        if (this.player) {
            this.player.updateOption({
                xorCryptoKey: key
            }, true)
        } else {
            this.debug.warn('JbPro', 'setXorCryptoKey() player is null');
        }
    }

    updateLoadingText(text) {
        this.debug.log('JbPro', 'updateLoadingText()', text);
        if (this.player) {
            this.player.updateLoadingText(text);
        } else {
            this.debug.warn('JbPro', 'updateLoadingText() player is null');
        }
    }

    updateIsEmitSEI(isEmitSEI) {
        this.debug.log('JbPro', 'updateIsEmitSEI()', isEmitSEI);
        this._opt.isEmitSEI = isEmitSEI;
        if (this.player) {
            this.player.updateOption({
                isEmitSEI: isEmitSEI
            }, true)
        } else {
            this.debug.warn('JbPro', 'updateIsEmitSEI() player is null');
        }
    }

    /**
     *
     * @param ptz ptz type
     * @param speed 0-9
     * @returns {string}
     */
    getPTZCmd(ptz, speed) {
        this.debug.log('JbPro', 'getPTZCmd()', ptz);

        if (!ptz) {
            this.debug.warn('JbPro', 'getPTZCmd() ptz is null');
            return;
        }

        if (this.player) {
            return getPTZCmd({
                type: ptz,
                index: 0,
                speed
            })
        } else {
            this.debug.warn('JbPro', 'getPTZCmd() player is null');
        }

    }


    //  供测试使用
    downloadTempNakedFlowFile() {
        return new Promise((resolve, reject) => {
            if (this.player) {
                this.player.downloadNakedFlowFile();
                resolve();
            } else {
                reject('player is not init')
            }
        })
    }

    //  供测试使用
    downloadTempFmp4File() {
        return new Promise((resolve, reject) => {
            if (this.player) {
                this.player.downloadFmp4File();
                resolve();
            } else {
                reject('player is not init')
            }
        })
    }

    //  供测试使用
    downloadTempMpeg4File() {
        return new Promise((resolve, reject) => {
            if (this.player) {
                this.player.downloadMpeg4File();
                resolve();
            } else {
                reject('player is not init')
            }
        })
    }

    //  供测试使用
    downloadTempRtpFile() {
        return new Promise((resolve, reject) => {
            if (this.talk) {
                this.talk.downloadRtpFile();
                resolve();
            } else {
                reject('talk is not init')
            }
        })
    }

    //
    downloadMemoryLog() {
        if (this.memoryLogger) {
            this.memoryLogger.download();
        }
    }

    _getVideoLastIframeInfo() {
        const videoInfo = this.getVideoInfo() || {};
        const lastImage = this.screenshot('', 'png', 0.92, 'base64');
        return {
            loadingBackground: lastImage,
            loadingBackgroundWidth: videoInfo.width || 0,
            loadingBackgroundHeight: videoInfo.height || 0,
        }
    }

    getExtendBtnList() {
        this.debug.log('JbPro', 'getExtendBtnList()');
        let result = [];
        if (this.player) {
            result = this.player.getExtendBtnList();
        } else {
            this.debug.warn('JbPro', 'getExtendBtnList() player is null');
        }

        return result;
    }

    getFlvMetaData() {
        this.debug.log('JbPro', 'getFlvMetaData()');
        let result = null;
        if (this.player) {
            result = this.player.getMetaData();
        } else {
            this.debug.warn('JbPro', 'getFlvMetaData() player is null');
        }
        return result;
    }

    /**
     *
     * @param interval
     */
    updateAiFaceDetectInterval(interval) {
        this.debug.log('JbPro', 'updateAiFaceDetectInterval()', interval);
        interval = Number(interval)

        const aiFaceDetectInterval = interval * 1000;

        this._opt.aiFaceDetectInterval = aiFaceDetectInterval;

        if (this.player) {
            // s -> ms
            this.player.updateOption({
                aiFaceDetectInterval: aiFaceDetectInterval
            })
        } else {
            this.debug.warn('JbPro', 'updateAiFaceDetectInterval() player is null')
        }
    }


    /**
     *
     * @param level
     */
    updateAiFaceDetectLevel(level) {
        this.debug.log('JbPro', 'updateAiFaceDetectLevel()', level);

        if (!AI_FACE_DETECTOR_LEVEL[level]) {
            this.debug.warn('JbPro', `'updateAiFaceDetectLevel() level ${level} is invalid'`);
            return;
        }

        const width = AI_FACE_DETECTOR_LEVEL[level];

        this._opt.aiFaceDetectWidth = width;

        if (this.player) {

            this.player.updateOption({
                aiFaceDetectWidth: width
            })

            if (this.player.ai) {
                this.player.ai.updateFaceDetectorConfig({
                    detectWidth: width
                })
            }

        } else {
            this.debug.warn('JbPro', 'updateAiFaceDetectLevel() player is null')
        }
    }

    /**
     *
     * @param interval
     */
    updateAiObjectDetectInterval(interval) {
        this.debug.log('JbPro', 'updateAiObjectDetectInterval()', interval);
        interval = Number(interval)

        const aiObjectDetectInterval = interval * 1000;

        this._opt.aiObjectDetectInterval = aiObjectDetectInterval;

        if (this.player) {
            // s -> ms
            this.player.updateOption({
                aiObjectDetectInterval: aiObjectDetectInterval
            })
        } else {
            this.debug.warn('JbPro', 'updateAiObjectDetectInterval() player is null')
        }
    }

    /**
     *
     * @param level
     */
    updateAiObjectDetectLevel(level) {
        this.debug.log('JbPro', 'updateAiObjectDetectLevel()', level);
        if (!AI_OBJECT_DETECTOR_LEVEL[level]) {
            this.debug.warn('JbPro', `'updateAiObjectDetectLevel() level ${level} is invalid'`);
            return;
        }
        const width = AI_OBJECT_DETECTOR_LEVEL[level];
        this._opt.aiObjectDetectWidth = width;

        if (this.player) {
            this.player.updateOption({
                aiObjectDetectWidth: width
            })

            if (this.player.ai) {
                this.player.ai.updateObjectDetectorConfig({
                    detectWidth: width
                })
            }
        } else {
            this.debug.warn('JbPro', 'updateAiObjectDetectLevel() player is null')
        }
    }

    setCryptoKeyUrl(url) {
        this.debug.log('JbPro', 'setCryptoKeyUrl()', url);

        if (!url) {
            return;
        }

        this._opt.cryptoKeyUrl = url;

    }

    showErrorMessageTips(content) {
        this.debug.log('JbPro', 'showErrorMessageTips()', content);

        if (!content) {
            return;
        }

        if (this.player) {
            this.player.showTipsMessageByContent(content);
        } else {
            this.debug.warn('JbPro', 'showErrorMessageTips() player is null')
        }
    }

    hideErrorMessageTips() {
        this.debug.log('JbPro', 'hideErrorMessageTips()');

        if (this.player) {
            this.player.hideTipsMessage();
        } else {
            this.debug.warn('JbPro', 'hideErrorMessageTips() player is null')
        }
    }
}


//
JessibucaPro.ERROR = EVENTS_ERROR;
//
JessibucaPro.EVENTS = JESSIBUCA_EVENTS;

window.JessibucaPro = JessibucaPro;
window.WebPlayerPro = JessibucaPro;

export default JessibucaPro;
