
export const ERR = {
    NETWORK: 'network',
    NETWORK_TIMEOUT: 'network_timeout',
    NETWORK_FORBIDDEN:'network_forbidden',
    OTHER: 'other',
    MANIFEST: 'manifest',
    HLS: 'hls',
    DEMUX: 'demux',
}
export class StreamingError extends Error {
    constructor (type, subType, origin, payload, msg) {
        super(msg || origin?.message)
        this.errorType = type === ERR.NETWORK_TIMEOUT ? ERR.NETWORK : type
        this.originError = origin
        this.ext = payload
        this.errorMessage = this.message
    }

    static create (type, subType, origin, payload, msg) {
        if (type instanceof StreamingError) {
            return type
        } else if (type instanceof Error) {
            origin = type
            type = ''
        }

        if (!type) type = ERR.OTHER

        return new StreamingError(type, subType, origin, payload, msg)
    }

    static network (error) {
        return new StreamingError(
            error?.isTimeout ? ERR.NETWORK_TIMEOUT : ERR.NETWORK,
            null,
            error instanceof Error ? error : null,
            {
                url: error?.url,
                response: error?.response,
                httpCode: error?.response?.status
            }
        )
    }
}
