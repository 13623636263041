export default (player, control) => {

    Object.defineProperty(control, 'controlsRect', {
        get: () => {
            return control.$controls.getBoundingClientRect();
        },
    });

    Object.defineProperty(control, 'controlsInnerRect', {
        get: () => {
            return control.$controlsInner.getBoundingClientRect();
        },
    });
    Object.defineProperty(control, 'controlsLeftRect', {
        get: () => {
            return control.$controlsLeft.getBoundingClientRect();
        },
    });
    Object.defineProperty(control, 'controlsRightRect', {
        get: () => {
            return control.$controlsRight.getBoundingClientRect();
        },
    });
    Object.defineProperty(control, 'controlsPlaybackTimeInner', {
        get: () => {
            return (control.$playbackTimeInner && control.$playbackTimeInner.getBoundingClientRect()) || {};
        },
    });

    Object.defineProperty(control, 'controlsPlaybackBtnsRect', {
        get: () => {
            return (control.$controlsPlaybackBtns && control.$controlsPlaybackBtns.getBoundingClientRect()) || {width: 0};
        },
    });

}
