import MP4Box from "../utils/mp4box";
import CommonLoader from "./commonLoader";
import {AAC_FREQ_LIST, aacEncoderConfigurationRecordV2} from "../utils/aac";
import {MEDIA_TYPE} from "../constant";
import {avcEncoderNalePacketNotLength} from "../utils/h264";
import {hevcEncoderNalePacketNotLength} from "../utils/h265";
import {dropSpecialMp4Box, now} from "../utils";
import TransportDescrambler from "../utils/transportDescrambler";

export default class Fmp4Loader extends CommonLoader {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'Fmp4Loader';
        this.player = player;
        this.mp4Box = MP4Box.createFile();
        this.tempFmp4List = [];
        this.offset = 0;
        this.videoTrackId = null;
        this.audioTrackId = null;
        this.isHevc = false;
        this.transportDescarmber = null;
        if (this.player._opt.isFmp4Private) {
            this.transportDescarmber = new TransportDescrambler();
        }

        this._listenMp4Box();
        player.debug.log(this.TAG_NAME, 'init');

    }

    destroy() {

        if (this.mp4Box) {
            this.mp4Box.flush();
            this.mp4Box = null;
        }

        if (this.transportDescarmber) {
            this.transportDescarmber.destroy();
            this.transportDescarmber = null;
        }

        this.tempFmp4List = [];
        this.offset = 0;
        this.videoTrackId = null;
        this.audioTrackId = null;
        this.isHevc = false;

        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    _listenMp4Box() {
        this.mp4Box.onReady = this.onReady.bind(this);
        this.mp4Box.onError = this.onError.bind(this);
        this.mp4Box.onSamples = this.onSamples.bind(this);
    }

    onReady(info) {
        this.player.debug.log(this.TAG_NAME, 'onReady', info);
        const videoTrack = info.videoTracks[0];
        const audioTrack = info.audioTracks[0];
        if (videoTrack) {
            //
            this.videoTrackId = videoTrack.id;
            const seqHeader = this.getSeqHeader(videoTrack);
            if (seqHeader) {
                this.player.debug.log(this.TAG_NAME, 'seqHeader', seqHeader);
                this._doDecodeByFmp4(seqHeader, MEDIA_TYPE.video, 0, true, 0);
            }

            this.mp4Box.setExtractionOptions(videoTrack.id);
        }

        if (audioTrack &&
            this.player._opt.hasAudio) {
            this.audioTrackId = audioTrack.id;
            const audioInfo = audioTrack.audio || {};
            const sampleRateIndex = AAC_FREQ_LIST.indexOf(audioInfo.sample_rate);
            const profile = audioTrack.codec.replace('mp4a.40.', '');
            this.mp4Box.setExtractionOptions(audioTrack.id);

            const config = {
                profile: parseInt(profile, 10),
                sampleRate: sampleRateIndex,
                channel: audioInfo.channel_count,
            }
            const aacADTSHeader = aacEncoderConfigurationRecordV2(config);
            this.player.debug.log(this.TAG_NAME, 'aacADTSHeader', aacADTSHeader, 'config', config);

            this._doDecodeByFmp4(aacADTSHeader, MEDIA_TYPE.audio, 0, false, 0);
        }

        this.mp4Box.start();
    }

    onError(error) {
        this.player.debug.error(this.TAG_NAME, 'mp4Box onError', error);

    }

    onSamples(trackId, ref, samples) {
        // this.player.debug.log(this.TAG_NAME, 'onSamples', trackId, ref, samples);
        if (trackId === this.videoTrackId) {
            for (const sample of samples) {
                const data = sample.data;
                const isIFrame = sample.is_sync;
                const timestamp = 1000 * sample.cts / sample.timescale;
                const duration = 1000 * sample.duration / sample.timescale;
                this.player.updateStats({
                    vbps: data.byteLength,
                    dts: timestamp,
                })

                if (isIFrame) {
                    this.calcIframeIntervalTimestamp(timestamp);
                }

                let packet = null;
                if (this.isHevc) {
                    packet = hevcEncoderNalePacketNotLength(data, isIFrame);
                } else {
                    packet = avcEncoderNalePacketNotLength(data, isIFrame);
                }

                this._doDecodeByFmp4(packet, MEDIA_TYPE.video, timestamp, isIFrame, 0);
            }
        } else if (trackId === this.audioTrackId) {
            if (this.player._opt.hasAudio) {
                for (const sample of samples) {
                    const data = sample.data;
                    this.player.updateStats({
                        abps: data.byteLength,
                    })
                    const timestamp = 1000 * sample.cts / sample.timescale;
                    const duration = 1000 * sample.duration / sample.timescale;
                    const packet = new Uint8Array(data.byteLength + 2);
                    packet.set([0xAF, 0x01], 0);
                    packet.set(data, 2);
                    // this.player.debug.log(this.TAG_NAME, 'onSamples: audio', 'timestamp', timestamp, 'duration', duration)
                    this._doDecodeByFmp4(packet, MEDIA_TYPE.audio, timestamp, false, 0);
                }
            }
        } else {
            this.player.debug.warn(this.TAG_NAME, 'onSamples() trackId error', trackId);
        }
    }

    getSeqHeader(track) {
        const trak = this.mp4Box.getTrackById(track.id);
        for (const entry of trak.mdia.minf.stbl.stsd.entries) {
            if (entry.avcC || entry.hvcC) {
                const stream = new MP4Box.DataStream(undefined, 0, MP4Box.DataStream.BIG_ENDIAN);
                let prevData = [];
                if (entry.avcC) {
                    entry.avcC.write(stream);
                    prevData = [0x17, 0x00, 0x00, 0x00, 0x00];
                } else {
                    this.isHevc = true;
                    entry.hvcC.write(stream);
                    prevData = [0x1c, 0x00, 0x00, 0x00, 0x00];
                }
                const seqHeader = new Uint8Array(stream.buffer, 8);  // Remove the box header.
                const newData = new Uint8Array(prevData.length + seqHeader.length);
                newData.set(prevData, 0);
                newData.set(seqHeader, prevData.length);
                return newData;
            }
        }
        return null;
    }

    dispatch(data) {
        let buffer = new Uint8Array(data);
        if (typeof data === 'string') {
            this.player.debug.warn(this.TAG_NAME, 'dispatch()', 'data is string', data)
            return;
        }
        if (typeof data === 'object') {
            if (this.transportDescarmber) {
                buffer = this.transportDescarmber.transport(buffer);
            }
            // this.tempFmp4List.push(buffer);
            buffer.buffer.fileStart = this.offset;
            this.offset += buffer.byteLength;
            this.mp4Box.appendBuffer(buffer.buffer);
        } else {
            this.player.debug.warn(this.TAG_NAME, 'dispatch()', 'data is not object', data);
        }

    }

    downloadFmp4File() {
        const blob = new Blob(this.tempFmp4List, {type: 'video/mp4; codecs="avc1.640028,mp4a.40.2"'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = now() + '.fmp4';
        a.click();
        URL.revokeObjectURL(url);
    }

}
