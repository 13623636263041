import {HLS_EVENTS} from '../../constant'

export default class SeiService {


    constructor(emitter) {
        this.emitter = emitter;
        this._seiSet = new Set();

        emitter.on(HLS_EVENTS.SEI, (sei) => {
            if (sei) this._seiSet.add(sei)
        })
    }

    throw(currentTime) {
        if (currentTime === null || currentTime === undefined || !this._seiSet.size) return
        const min = currentTime - 0.2
        const max = currentTime + 0.2
        const toThrow = []
        this._seiSet.forEach(sei => {
            if (sei.time >= min && sei.time <= max) {
                toThrow.push(sei)
            }
        })

        toThrow.forEach((sei) => {
            this._seiSet.delete(sei)
            // 根据当前视频播放时间抛出 sei，触发该事件表示该 sei 将在当前时间点展示。
            this.emitter.emit(HLS_EVENTS.SEI_IN_TIME, sei)
        })
    }

    reset() {
        this._seiSet.clear()
    }
}
