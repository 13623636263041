import template from "./template";
import observer from './observer';
import property from './property';
import events from './events';
import './style.scss'
import hotkey from "./hotkey";
import {addClass, append, createElement, unshift, setStyle, removeElement} from "../utils/dom";
import {CONTROL_BUTTON_OPTIONS, CONTROL_HEIGHT, CONTROL_PLAYBACK_HEIGHT, PLAY_TYPE, RENDER_TYPE} from "../constant";
import {getDefaultButtonOptions, isBoolean, isFalse, isMobile, isNotEmptyObject} from "../utils";

export default class Control {
    constructor(player) {
        this.player = player;
        this.TAG_NAME = 'Control';
        this.extendBtnList = [];
        template(player, this);
        if (player._opt.extendOperateBtns.length > 0) {
            player._opt.extendOperateBtns.forEach(btn => {
                this.addExtendBtn(btn);
            });
        }
        if (player._opt.extendDomConfig && player._opt.extendDomConfig.html) {
            this.addExtendDom(player._opt.extendDomConfig);
        }

        property(player, this);
        observer(player, this);
        events(player, this);
        if (player._opt.hotKey) {
            hotkey(player, this);
        }
        this.btnIndex = 0;
        //  当触发异常机制，要保留最后一帧画面
        this.initLoadingBackground();
        //  update loading style
        if (isNotEmptyObject(player._opt.loadingIconStyle)) {
            this.initLoadingIconStyle(player._opt.loadingIconStyle);
        }
        this.kbpsShow = '0 KB/s';
        this.player.debug.log('Control', 'init');
    }

    destroy() {
        // performance
        if (this.$performancePanel) {
            this.$performancePanel.innerHTML = '';
            const result = removeElement(this.$performancePanel);
            if (!result) {
                const $performancePanel = this.player.$container.querySelector('.jessibuca-performance-panel');
                if ($performancePanel && this.player.$container) {
                    this.player.$container.removeChild($performancePanel);
                }
            }
        }
        //
        if (this.$poster) {
            const result = removeElement(this.$poster)
            if (!result) {
                const $poster = this.player.$container.querySelector('.jessibuca-poster');
                if ($poster && this.player.$container) {
                    this.player.$container.removeChild($poster);
                }
            }
        }
        if (this.$loading) {
            const result = removeElement(this.$loading)
            if (!result) {
                const $loading = this.player.$container.querySelector('.jessibuca-loading');
                if ($loading && this.player.$container) {
                    this.player.$container.removeChild($loading);
                }
            }
        }
        if (this.$loadingBg) {
            const result = removeElement(this.$loadingBg)
            if (!result) {
                const $loadingBg = this.player.$container.querySelector('.jessibuca-loading-bg');
                if ($loadingBg && this.player.$container) {
                    this.player.$container.removeChild($loadingBg);
                }
            }
        }
        if (this.$controls) {
            const result = removeElement(this.$controls)
            if (!result) {
                const $controls = this.player.$container.querySelector('.jessibuca-controls');
                if ($controls && this.player.$container) {
                    this.player.$container.removeChild($controls);
                }
            }
        }

        if (this.$playBig) {
            const result = removeElement(this.$playBig)
            if (!result) {
                const $playBig = this.player.$container.querySelector('.jessibuca-play-big');
                if ($playBig && this.player.$container) {
                    this.player.$container.removeChild($playBig);
                }
            }
        }

        if (this.$recording) {
            const result = removeElement(this.$recording)
            if (!result) {
                const $recording = this.player.$container.querySelector('.jessibuca-recording');
                if ($recording && this.player.$container) {
                    this.player.$container.removeChild($recording);
                }
            }
        }

        if (this.$ptzControl) {
            const result = removeElement(this.$ptzControl)
            if (!result) {
                const $ptzControl = this.player.$container.querySelector('.jessibuca-ptz-controls');
                if ($ptzControl && this.player.$container) {
                    this.player.$container.removeChild($ptzControl);
                }
            }
        }

        if (this.$zoomControls) {
            const result = removeElement(this.$zoomControls)
            if (!result) {
                const $zoomControls = this.player.$container.querySelector('.jessibuca-zoom-controls');
                if ($zoomControls && this.player.$container) {
                    this.player.$container.removeChild($zoomControls);
                }
            }
        }

        if (this.$contextmenus) {
            this.$contextmenus.innerHTML = '';
            const result = removeElement(this.$contextmenus)
            if (!result) {
                const $contextmenus = this.player.$container.querySelector('.jessibuca-contextmenus');
                if ($contextmenus && this.player.$container) {
                    this.player.$container.removeChild($contextmenus);
                }
            }
        }

        if (this.$tipsMessage) {
            const result = removeElement(this.$tipsMessage)
            if (!result) {
                const $tipsMessage = this.player.$container.querySelector('.jessibuca-tips-message');
                if ($tipsMessage && this.player.$container) {
                    this.player.$container.removeChild($tipsMessage);
                }
            }
        }

        if (this.$extendDom) {
            const result = removeElement(this.$extendDom);
            if (!result) {
                const $extendDom = this.player.$container.querySelector('.jessibuca-extend-dom');
                if ($extendDom && this.player.$container) {
                    this.player.$container.removeChild($extendDom);
                }
            }
        }

        this.btnIndex = 0;
        this.extendBtnList = [];
        this.kbpsShow = '0 KB/s';
        if (this.player.$container) {
            this.player.$container.classList.remove('jessibuca-controls-show-auto-hide');
            this.player.$container.classList.remove('jessibuca-controls-show');
        }

        this.player.debug.log('Control', 'destroy');
    }

    getBtnIndex() {
        return this.btnIndex++;
    }

    autoSize() {
        const player = this.player;
        player.$container.style.padding = '0 0';
        const playerWidth = player.width;
        const playerHeight = player.height;
        const playerRatio = playerWidth / playerHeight;
        const canvasWidth = player.video.$videoElement.width;
        const canvasHeight = player.video.$videoElement.height;
        const canvasRatio = canvasWidth / canvasHeight;
        if (playerRatio > canvasRatio) {
            const padding = (playerWidth - playerHeight * canvasRatio) / 2;
            player.$container.style.padding = `0 ${padding}px`;
        } else {
            const padding = (playerHeight - playerWidth / canvasRatio) / 2;
            player.$container.style.padding = `${padding}px 0`;
        }
    }

    initLoadingBackground() {
        const player = this.player;
        if (player._opt.loadingBackground &&
            player._opt.loadingBackgroundWidth &&
            player._opt.loadingBackgroundHeight) {
            player.debug.log('Control', 'initLoadingBackground()');
            if (this.player._opt.aspectRatio === 'default' ||
                isMobile()) {
                if (player.getRenderType() === RENDER_TYPE.canvas) {
                    this._initLoadingBackground();
                } else if (player.getRenderType() === RENDER_TYPE.video) {
                    this._initLoadingBackground();
                }
            } else {
                this._initLoadingBackgroundForRatio();
            }
            setStyle(this.$loadingBg, 'display', 'block');

            // 用完就要销毁
            player._opt.loadingBackground = '';
            player._opt.loadingBackgroundWidth = 0;
            player._opt.loadingBackgroundHeight = 0;
        }
    }


    initLoadingIconStyle(style) {
        const $loadingIcon = this.player.$container.querySelector('.jessibuca-icon-loading');
        if ($loadingIcon) {
            if (style.width) {
                setStyle($loadingIcon, 'width', `${style.width}px`);
            }
            if (style.height) {
                setStyle($loadingIcon, 'height', `${style.height}px`);
            }
            if (style.background) {
                setStyle($loadingIcon, 'backgroundImage', `url("${style.background}")`);
            }
            if (style.hasAnimation === false) {
                setStyle($loadingIcon, 'animationName', "none");
                setStyle($loadingIcon, 'animationDuration', 0);
                setStyle($loadingIcon, 'animationTimingFunction', "ease");
                setStyle($loadingIcon, 'animationIterationCount', 1);
            }
        }
    }

    _initLoadingBackgroundForRatio() {
        const ratioArray = this.player._opt.aspectRatio.split(':').map(Number);
        let width = this.player.width;
        let height = this.player.height;
        const option = this.player._opt;
        let controlHeight = 0;
        if (option.hasControl && !option.controlAutoHide) {
            controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT
            height -= controlHeight;
        }

        const videoInfo = {
            width: this.player._opt.loadingBackgroundWidth,
            height: this.player._opt.loadingBackgroundHeight
        };
        const videoRatio = videoInfo.width / videoInfo.height;
        const setupRatio = ratioArray[0] / ratioArray[1];
        this.$loadingBgImage.src = this.player._opt.loadingBackground;

        if (videoRatio > setupRatio) {
            const percentage = (setupRatio * videoInfo.height) / videoInfo.width;
            this.$loadingBgImage.style.width = `${percentage * 100}%`;
            this.$loadingBgImage.style.height = `calc(100% - ${controlHeight}px)`;
            this.$loadingBgImage.style.padding = `0 ${(width - width * percentage) / 2}px`;
        } else {
            const percentage = videoInfo.width / setupRatio / videoInfo.height;
            this.$loadingBgImage.style.width = '100%';
            this.$loadingBgImage.style.height = `calc(${percentage * 100}% - ${controlHeight}px)`;
            this.$loadingBgImage.style.padding = `${(height - height * percentage) / 2}px 0`;
        }
    }

    _initLoadingBackground() {
        const player = this.player;
        let height = player.height;
        const option = player._opt;
        if (option.hasControl && !option.controlAutoHide) {
            const controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT;
            height -= controlHeight;
        }
        let resizeWidth = player.width;
        let resizeHeight = height;
        const rotate = option.rotate;
        if (rotate === 270 || rotate === 90) {
            resizeWidth = height;
            resizeHeight = player.width;
        }
        this.$loadingBgImage.width = resizeWidth;
        this.$loadingBgImage.height = resizeHeight;
        this.$loadingBgImage.src = player._opt.loadingBackground;
        let left = ((player.width - resizeWidth) / 2)
        let top = ((height - resizeHeight) / 2)
        let objectFill = 'contain';
        if (!option.isResize) {
            objectFill = 'fill';
        }

        if (option.isFullResize) {
            objectFill = 'none';
        }
        let transform = '';
        if (option.mirrorRotate === 'none') {
            if (rotate) {
                transform += ' rotate(' + rotate + 'deg)'
            }
        }
        if (option.mirrorRotate === 'level') {
            transform += ' rotateY(180deg)' // 水平镜像翻转
        } else if (option.mirrorRotate === 'vertical') {
            transform += ' rotateX(180deg)' // 垂直镜像翻转
        }
        if (this.player._opt.videoRenderSupportScale) {
            this.$loadingBgImage.style.objectFit = objectFill;
        }

        this.$loadingBgImage.style.transform = transform;
        this.$loadingBgImage.style.padding = "0";
        this.$loadingBgImage.style.left = left + "px"
        this.$loadingBgImage.style.top = top + "px"
    }

    _validateExtendBtn(options) {
        let result = true;

        if (!options.name) {
            this.player.debug.warn('Control', 'extend button name is required');
            result = false;
        }
        if (result) {
            const findIndex = this.extendBtnList.findIndex(item => item.name === options.name);
            if (findIndex !== -1) {
                this.player.debug.warn('Control', `extend button name: ${options.name} is already exist`);
                result = false;
            }
        }

        if (result) {
            if (!options.icon) {
                this.player.debug.warn('Control', 'extend button icon is required');
                result = false;
            }
        }

        return result;
    }

    addExtendBtn(options = {}) {
        const defaultOptions = getDefaultButtonOptions();
        options = Object.assign({}, defaultOptions, options);
        if (!this._validateExtendBtn(options)) {
            return;
        }

        const name = options.name || '';
        const $controlsRight = this.$controlsRight;
        const hasActive = options.activeIcon && options.activeClick;
        const $btn = `
            <div class="jessibuca-controls-item-wrap jessibuca-controls-item-wrap-${name}">
               ${options.icon ?
            `<div class="jessibuca-controls-item jessibuca-control-extend-${name}">
                                <i class="jessibuca-icon jessibuca-icon-extend-${name}"></i>
                                ${
                options.iconTitle ? `
                                            <span class="icon-title-tips"><span class="icon-title">${options.iconTitle}</span></span>
                                            ` : ``
            }
                                </div>`
            : ''
        }
               ${options.activeIcon ?
            `<div class="jessibuca-controls-item jessibuca-control-extend-${name}-active">
                                <i class="jessibuca-icon jessibuca-icon-extend-${name}-active"></i>
                                ${
                options.activeIconTitle ? `
                                            <span class="icon-title-tips"><span class="icon-title">${options.activeIconTitle}</span></span>
                                            ` : ``
            }
                            </div>`
            : ''
        }
               </div>
            `
        const $childList = Array.from($controlsRight.children);
        const nextChild = $childList[options.index];
        if (nextChild) {
            // insert before
            nextChild.insertAdjacentHTML('beforebegin', $btn);
        } else {
            append($controlsRight, $btn);
        }

        const $iconContainer = $controlsRight.querySelector(`.jessibuca-controls-item-wrap-${name}`)
        const $icon = options.icon ? $controlsRight.querySelector(`.jessibuca-icon-extend-${name}`) : null;
        const $iconWrap = options.icon ? $controlsRight.querySelector(`.jessibuca-control-extend-${name}`) : null;
        const $activeIcon = options.activeIcon ? $controlsRight.querySelector(`.jessibuca-icon-extend-${name}-active`) : null;
        const $activeIconWrap = options.activeIcon ? $controlsRight.querySelector(`.jessibuca-control-extend-${name}-active`) : null;
        const {
            events: {proxy},
            debug
        } = this.player;

        if (options.icon) {
            setStyle($icon, 'background', `url(${options.icon}) no-repeat center`);
            setStyle($icon, 'background-size', '100% 100%');
            setStyle($iconWrap, 'display', 'none');

            if (options.iconHover) {
                proxy($icon, 'mouseover', () => {
                    setStyle($icon, 'background', `url(${options.iconHover}) no-repeat center`);
                    setStyle($icon, 'background-size', '100% 100%');
                })
                proxy($icon, 'mouseout', () => {
                    setStyle($icon, 'background', `url(${options.icon}) no-repeat center`);
                    setStyle($icon, 'background-size', '100% 100%');
                })
            }
        }


        if (options.activeIcon) {
            setStyle($activeIcon, 'background', `url(${options.activeIcon}) no-repeat center`);
            setStyle($activeIcon, 'background-size', '100% 100%');
            setStyle($activeIconWrap, 'display', 'none');

            if (options.activeIconHover) {
                proxy($activeIcon, 'mouseover', () => {
                    setStyle($activeIcon, 'background', `url(${options.activeIconHover}) no-repeat center`);
                    setStyle($activeIcon, 'background-size', '100% 100%');
                })
                proxy($activeIcon, 'mouseout', () => {
                    setStyle($activeIcon, 'background', `url(${options.activeIcon}) no-repeat center`);
                    setStyle($activeIcon, 'background-size', '100% 100%');
                })
            }
        }

        if (options.click && $icon) {
            proxy($icon, 'click', (event) => {
                event.preventDefault();
                if (hasActive) {
                    setStyle($iconWrap, 'display', 'none');
                    setStyle($activeIconWrap, 'display', 'flex');
                }
                if (this.player.isInMulti()) {
                    options.click.call(this.player, event, this.player._opt.multiIndex);
                } else {
                    options.click.call(this.player, event);
                }
            })
        }

        if (options.activeClick && $activeIcon) {
            proxy($activeIcon, 'click', (event) => {
                event.preventDefault();
                setStyle($iconWrap, 'display', 'flex');
                setStyle($activeIconWrap, 'display', 'none');
                if (this.player.isInMulti()) {
                    options.activeClick.call(this.player, event, this.player._opt.multiIndex);
                } else {
                    options.activeClick.call(this.player, event);
                }
            })
        }
        this.extendBtnList.push({
            name,
            $iconContainer,
            $iconWrap,
            $activeIconWrap,
        })
    }


    addExtendDom(extendDomConfig) {
        this.player.debug.log(this.TAG_NAME, 'addExtendDom');
        if (extendDomConfig.html) {
            const extendDomWrap = `
                <div class="jessibuca-extend-dom">
                ${extendDomConfig.html}
                </div>
            `
            this.player.$container.insertAdjacentHTML(
                'beforeend',
                extendDomWrap
            )
            Object.defineProperty(this, '$extendDom', {
                value: this.player.$container.querySelector('.jessibuca-extend-dom'),
            });

            //  show
            if (extendDomConfig.showBeforePlay) {
                setStyle(this.$extendDom, 'display', 'block');
            }
        }
    }

    toggleExtendDom(flag) {
        if (this.$extendDom) {
            if (!isBoolean(flag)) {
                flag = this.$extendDom.style.display === 'none';
            }

            if (flag) {
                setStyle(this.$extendDom, 'display', 'block');
            } else {
                setStyle(this.$extendDom, 'display', 'none');
            }
        }
    }

    updateExtendDom(extendDom) {
        this.player.debug.log(this.TAG_NAME, 'updateExtendDom');
        if (this.$extendDom) {
            this.$extendDom.innerHTML = extendDom;
        }
    }

    removeExtendDom() {
        this.player.debug.log(this.TAG_NAME, 'removeExtendDom');
        if (this.$extendDom) {
            this.$extendDom.innerHTML = '';
        }
    }

    updateLoadingText(text) {
        if (this.$loadingText) {
            this.$loadingText.innerText = text;
        }
    }

    getExtendBtnList() {
        return this.extendBtnList;
    }

    showTipsMessage(content, type) {
        const $tipsMessage = this.$tipsMessage;
        const $tipsMessageContent = this.$tipsMessageContent;

        if ($tipsMessage) {
            const htmlContent = `
            <div class="jessibuca-tips-message-content-item">${content}</div>
            ${
                type ? `<div class="jessibuca-tips-message-content-item">Error Type:${type}</div>` : ''
            }
        `
            $tipsMessageContent.innerHTML = htmlContent;

            setStyle($tipsMessage, 'display', 'block');
        }
    }

    hideTipsMessage() {
        const $tipsMessage = this.$tipsMessage;
        if ($tipsMessage) {
            $tipsMessageContent.innerHTML = '';
            setStyle($tipsMessage, 'display', 'none');
        }
    }
}
