import Emitter from "../utils/emitter";
import {clamp, isEmpty, isIOS, isNotEmpty, isTrue} from "../utils";
import {AUDIO_ENC_TYPE, EVENTS} from "../constant";

export default class AudioLoader extends Emitter {

    constructor(player) {
        super();
        this.player = player;
        this.$video = player.video.$videoElement;
        this.init = false;

        //
        if (this.player._opt.hlsUseCanvasRender) {
            this.$video = this.player.hlsDecoder.$videoElement
        }
        //
        if (this.player._opt.webrtcUseCanvasRender) {
            this.$video = this.player.webrtc.$videoElement
        }

        this.audioInfo = {
            encType: '',
            channels: '',
            sampleRate: ''
        }
        this.player.debug.log('Audio', 'init');

    }

    destroy() {
        this.resetInit();
        this.off();
        this.player.debug.log('Audio', 'destroy');
    }

    resetInit() {
        this.init = false;

        this.audioInfo = {
            encType: '',
            channels: '',
            sampleRate: ''
        }
    }

    getAudioInfo() {
        return this.audioInfo;
    }


    updateAudioInfo(data) {
        if (isNotEmpty(data.encTypeCode)) {
            this.audioInfo.encType = AUDIO_ENC_TYPE[data.encTypeCode];
        }

        if (isNotEmpty(data.encType)) {
            this.audioInfo.encType = data.encType;
        }

        if (isNotEmpty(data.channels)) {
            this.audioInfo.channels = data.channels;
        }
        if (isNotEmpty(data.sampleRate)) {
            this.audioInfo.sampleRate = data.sampleRate;
        }

        // audio 基本信息
        if (isNotEmpty(this.audioInfo.sampleRate) && isNotEmpty(this.audioInfo.channels) && isNotEmpty(this.audioInfo.encType) && !this.init) {
            this.player.debug.log('Audio', 'audioInfo', JSON.stringify(this.audioInfo));
            this.player.emit(EVENTS.audioInfo, this.audioInfo);
            this.init = true;
        }
    }


    get isPlaying() {
        return true
    }

    get volume() {
        return isTrue(this.$video.muted) ? 0 : this.$video.volume;
    }

    get isMute() {
        return this.$video.volume === 0 || isTrue(this.$video.muted);
    }

    mute(muted) {
        this.setVolume(muted ? 0 : (this.player.lastVolume || 0.5));
    }

    setVolume(volume) {
        volume = parseFloat(volume);
        if (isNaN(volume)) {
            return;
        }
        volume = clamp(volume, 0, 1);
        // 值从0.0（静音）到1.0（最大音量）。
        if (this.$video.muted) {
            this.$video.muted = false;
        }

        //  ios 上面通过设置 volume 为 0 是无效果的。只能通过 muted 来设置静音
        if (isIOS()) {
            this.$video.muted = volume === 0;
        }

        if (this.player.isAliyunRtc()) {
            if (this.player.aliyunRtcDecoder &&
                this.player.aliyunRtcDecoder.aliyunRtcRemoteStream) {
                this.player.aliyunRtcDecoder.aliyunRtcRemoteStream.muted = volume === 0;
            }
        }

        this.$video.volume = volume;
        this.player.emit(EVENTS.volumechange, this.player.volume);
    }

    clear() {

    }

    play() {

    }

    pause() {

    }

    resume() {

    }

    getEngineType() {
        return 'audio'
    }

    isPlaybackRateSpeed() {
        return false;
    }

    getAudioBufferSize() {
        return 0;
    }

    setRate(){
        // 空的。
    }
}
