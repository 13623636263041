const iconsMap = {
    play: '播放',
    pause: '暂停',
    audio: '',
    mute: '',
    screenshot: '截图',
    loading: '',
    fullscreen: '全屏',
    fullscreenExit: '退出全屏',
    record: '录制',
    recordStop: '停止录制',
    narrow: '缩小',
    expand: '放大',
    ptz: '操作盘',
    ptzActive: '操作盘激活',
    zoom: '电子放大',
    zoomStop: '关闭电子放大',
    close: '关闭',
    performance: '性能面板',
    performanceActive: '性能面板激活',
    face: '人脸识别',
    faceActive: '人脸识别激活',
    object: '物品识别',
    objectActive: '物品识别激活',
    occlusion: '遮挡物检查',
    occlusionActive: '遮挡物检查激活',
    logSave: '保存日志',
}

export default Object.keys(iconsMap).reduce((icons, key) => {
    icons[key] = `
    <i class="jessibuca-icon jessibuca-icon-${key}"></i>
    ${iconsMap[key] ? `<span class="icon-title-tips"><span class="icon-title">${iconsMap[key]}</span></span>` : ''}
`;
    return icons;
}, {});
