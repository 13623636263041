import CommonLoader from "./commonLoader";
import {calcStreamFpsByBufferList, function2String, isFalse} from "../utils";
import MP4Parser from "./mp4Parser";
import {readBig32} from "../utils/arraybuffer";
import {MEDIA_TYPE, URL_OBJECT_CLEAR_TIME, VIDEO_ENCODE_TYPE} from "../constant";
import {hevcEncoderConfigurationRecord$2, hevcEncoderNalePacketNotLength} from "../utils/h265";
import {avcEncoderConfigurationRecord$2, avcEncoderNalePacketNotLength} from "../utils/h264";
import {aacEncoderConfigurationRecordV2} from "../utils/aac";


export default class HlsFmp4Loader extends CommonLoader {
    constructor(player) {
        super(player);
        this.player = player;
        this.TAG_NAME = 'HlsFmp4Loader';
        this.tempSampleListInfo = {};
        this.isInitVideo = false;
        this.isInitAudio = false;

        this.videoTrack = {
            id: 1,
            samples: [],
            sps: [],
            pps: [],
            vps: [],
            codec: '',
        }
        this.audioTrack = {
            id: 2,
            samples: [],
            sampleRate: 0,
            channelCount: 0,
            codec: '',
            codecType: '',
        };
        this.workerClearTimeout = null;
        this.workerUrl = null;
        this.loopWorker = null;
        this._hasCalcFps = false;
        if (!this.player.isUseMSE()) {
            this._initLoopWorker();
        }

        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        if (this.workerUrl) {
            URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
        }

        if (this.workerClearTimeout) {
            clearTimeout(this.workerClearTimeout);
            this.workerClearTimeout = null;
        }

        if (this.loopWorker) {
            this.loopWorker.postMessage({cmd: 'destroy'});
            this.loopWorker.terminate();
            this.loopWorker = null;
        }
        this._hasCalcFps = false;

        this.videoTrack = null;
        this.audioTrack = null;
        this.isInitVideo = false;
        this.isInitAudio = false;
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    demux(videoData, audioData) {
        let audioTrack = this.audioTrack;
        let videoTrack = this.videoTrack;
        this.checkInitAudio();
        this.checkInitVideo();
        audioTrack.samples = [];
        videoTrack.samples = [];
        if (audioData) {

            this.player.updateStats({
                abps: audioData.byteLength,
            })

            if (isFalse(this.isInitAudio)) {
                const moovBox = MP4Parser.findBox(audioData, ['moov'])[0]
                if (!moovBox) {
                    this.player.debug.error(this.TAG_NAME, 'cannot found moov box');
                    return;
                }
                MP4Parser.moovToTrack(MP4Parser.moov(moovBox), null, audioTrack);
                if (this.checkInitAudio()) {
                    this.player.debug.log(this.TAG_NAME, 'audioData audio init success');
                    this._sendAccADTSHeader(audioTrack)
                }
            }

            const moofBox = MP4Parser.findBox(audioData, ['moof'])[0]
            if (moofBox) {
                const samples = MP4Parser.moofToSamples(MP4Parser.moof(moofBox), null, audioTrack)[audioTrack.id]
                const baseMediaDecodeTime = audioTrack.baseMediaDecodeTime
                if (samples) {
                    const baseOffset = moofBox.start
                    samples.map(x => {
                        x.offset += baseOffset
                        const sampleData = audioData.subarray(x.offset, x.offset + x.size)
                        const pts = x.dts + baseMediaDecodeTime;
                        const arrayBuffer = new Uint8Array(sampleData.length + 2);
                        arrayBuffer.set([0xAF, 0x01], 0);
                        arrayBuffer.set(sampleData, 2);
                        audioTrack.samples.push({
                            type: MEDIA_TYPE.audio,
                            pts,
                            dts: pts,
                            payload: arrayBuffer,
                            duration: x.duration,
                            size: arrayBuffer.byteLength
                        })
                    })
                }
            }
        }
        if (videoData) {

            this.player.updateStats({
                vbps: videoData.byteLength,
            })

            if (isFalse(this.isInitVideo) && isFalse(this.isInitAudio)) {
                const moovBox = MP4Parser.findBox(videoData, ['moov'])[0]
                if (!moovBox) {
                    throw new Error('cannot found moov box')
                }
                MP4Parser.moovToTrack(MP4Parser.moov(moovBox), videoTrack, audioTrack);
                if (isFalse(this.isInitAudio) &&
                    this.checkInitAudio()) {
                    this.player.debug.log(this.TAG_NAME, 'videoData audio init success', audioTrack);
                    this._sendAccADTSHeader(audioTrack)
                }

                if (this.checkInitVideo()) {
                    this.player.debug.log(this.TAG_NAME, 'video init success');
                    const isHevc = videoTrack.codecType === VIDEO_ENCODE_TYPE.h265;
                    let seqHeader = null;
                    if (isHevc) {
                        //  h265
                        if (videoTrack.sps.length &&
                            videoTrack.vps.length &&
                            videoTrack.pps.length) {
                            seqHeader = hevcEncoderConfigurationRecord$2({
                                sps: videoTrack.sps[0],
                                pps: videoTrack.pps[0],
                                vps: videoTrack.vps[0],
                            })
                        }
                    } else {
                        //  h264
                        if (videoTrack.sps.length &&
                            videoTrack.pps.length) {
                            seqHeader = avcEncoderConfigurationRecord$2({
                                sps: videoTrack.sps[0],
                                pps: videoTrack.pps[0],
                            })
                        }
                    }

                    if (seqHeader) {
                        this.player.debug.log(this.TAG_NAME, 'seqHeader');
                        this._doDecodeByHls(seqHeader, MEDIA_TYPE.video, 0, true, 0);
                    }
                }
            }

            const moofBox = MP4Parser.findBox(videoData, ['moof'])[0]
            if (moofBox) {
                const tracks = MP4Parser.moofToSamples(MP4Parser.moof(moofBox), videoTrack, audioTrack)
                const videoBaseMediaDecodeTime = videoTrack.baseMediaDecodeTime
                const audioBaseMediaDecodeTime = audioTrack.baseMediaDecodeTime
                const baseOffset = moofBox.start
                let nalSize
                Object.keys(tracks).forEach(k => {
                    if (videoTrack.id == k) {
                        tracks[k].map(x => {
                            x.offset += baseOffset
                            const sample = {
                                type: MEDIA_TYPE.video,
                                pts: (x.pts || x.dts) + videoBaseMediaDecodeTime,
                                dts: x.dts + videoBaseMediaDecodeTime,
                                units: [],
                                payload: null,
                                isIFrame: false
                            }
                            sample.duration = x.duration
                            sample.gopId = x.gopId
                            if (x.keyframe) sample.isIFrame = true;
                            const sampleData = videoData.subarray(x.offset, x.offset + x.size)
                            sample.payload = sampleData;
                            videoTrack.samples.push(sample)
                        })
                        // eslint-disable-next-line eqeqeq
                    } else if (audioTrack.id == k) {
                        tracks[k].map(x => {
                            x.offset += baseOffset
                            const sampleData = videoData.subarray(x.offset, x.offset + x.size)
                            const pts = x.dts + audioBaseMediaDecodeTime;
                            const arrayBuffer = new Uint8Array(sampleData.length + 2);
                            arrayBuffer.set([0xAF, 0x01], 0);
                            arrayBuffer.set(sampleData, 2);
                            audioTrack.samples.push({
                                type: MEDIA_TYPE.audio,
                                pts,
                                dts: pts,
                                payload: arrayBuffer,
                                duration: x.duration, // ms
                                size: arrayBuffer.byteLength
                            })
                        })
                    }
                })
            }
        }


        // 传入到worker 里面
        const allSampleList = videoTrack.samples.concat(audioTrack.samples);
        allSampleList.sort((a, b) => {
            return a.dts - b.dts;
        })

        allSampleList.forEach((sample) => {
            const arrayBuffer = new Uint8Array(sample.payload);
            delete sample.payload;
            if (this.player.isUseMSE()) {
                if (sample.type === MEDIA_TYPE.video) {
                    //   直接解码
                    this._doDecodeVideo({
                        ...sample,
                        payload: arrayBuffer,
                    })
                } else if (sample.type === MEDIA_TYPE.audio) {
                    this._doDecodeAudio({
                        ...sample,
                        payload: arrayBuffer,
                    })
                }
            } else {
                this.loopWorker.postMessage({
                    ...sample,
                    payload: arrayBuffer,
                    cmd: 'sample',
                }, [arrayBuffer.buffer]);
            }
        })

        if (isFalse(this._hasCalcFps)) {
            this._hasCalcFps = true;
            this._calcDecodeFps(allSampleList);
        }
    }

    checkInitAudio() {
        this.isInitAudio = !!(this.audioTrack.sampleRate && this.audioTrack.channelCount && this.audioTrack.codec && this.audioTrack.codecType === 'aac')
        return this.isInitAudio;
    }

    checkInitVideo() {
        this.isInitVideo = !!(this.videoTrack.pps.length && this.videoTrack.sps.length && this.videoTrack.codec);
        return this.isInitVideo;
    }

    _sendAccADTSHeader(audioTrack) {
        //
        const accADTSHeader = aacEncoderConfigurationRecordV2({
            profile: audioTrack.objectType,
            sampleRate: audioTrack.sampleRateIndex,
            channel: audioTrack.channelCount
        })

        this._doDecodeByHls(accADTSHeader, MEDIA_TYPE.audio, 0, true, 0);
    }

    _calcDecodeFps(sampleList) {

        const _tempSampleTsList = sampleList.map((sample) => {
            return {
                ts: sample.dts || sample.pts,
                type: sample.type
            }
        })

        const streamVideoFps = calcStreamFpsByBufferList(_tempSampleTsList, MEDIA_TYPE.video);

        if (streamVideoFps) {
            this._basefps = streamVideoFps;
            this._postMessageToLoopWorker('updateBaseFps', {
                baseFps: this._basefps
            });

            this.player.debug.log(this.TAG_NAME, `_calcDecodeFps()  video fps is ${streamVideoFps}, update base fps is ${this._basefps}`)
        }
    }


    _initLoopWorker() {
        this.player.debug.log(this.TAG_NAME, '_initLoopWorker()');

        //  worker fun
        //  worker 里面跑interval
        function LoopWorkerFun() {

            const MEDIA_TYPE = {
                audio: 1,
                video: 2
            }

            class LoopWorker {
                constructor() {
                    this.baseFps = 0;
                    this.fpsInterval = null;
                    this.preLoopTimestamp = null;
                    this.startBpsTime = null;
                    this.allSampleList = [];
                }

                destroy() {
                    this._clearInterval();
                    this.baseFps = 0;
                    this.allSampleList = [];
                    this.preLoopTimestamp = null;
                    this.startBpsTime = null;
                }

                updateBaseFps(baseFps) {
                    this.baseFps = baseFps;
                    this._clearInterval();
                    this._startInterval();
                }

                pushSample(sample) {
                    delete sample.cmd;
                    this.allSampleList.push(sample);
                }

                _startInterval() {
                    const fragDuration = Math.ceil(1000 / this.baseFps);
                    this.fpsInterval = setInterval(() => {
                        let nowTime = new Date().getTime()
                        if (!this.preLoopTimestamp) {
                            this.preLoopTimestamp = nowTime;
                        }
                        if (!this.startBpsTime) {
                            this.startBpsTime = nowTime;
                        }

                        const diffTime = nowTime - this.preLoopTimestamp;
                        if (diffTime > (fragDuration * 2)) {
                            console.warn(`JbPro:[TsLoader LoopWorker] loop interval is ${diffTime}ms, more than ${fragDuration} * 2ms`)
                        }
                        this._loop();
                        this.preLoopTimestamp = new Date().getTime();


                        if (this.startBpsTime) {
                            const timestamp = nowTime - this.startBpsTime;

                            if (timestamp >= 1000) {
                                this._calcSampleList();
                                this.startBpsTime = nowTime;
                            }
                        }

                    }, fragDuration)
                }

                _clearInterval() {
                    if (this.fpsInterval) {
                        clearInterval(this.fpsInterval);
                        this.fpsInterval = null;
                    }
                }

                _calcSampleList() {
                    const tempObj = {
                        buferredDuration: 0,
                        allListLength: this.allSampleList.length,
                        audioListLength: 0,
                        videoListLength: 0
                    }

                    this.allSampleList.forEach((sample) => {
                        if (sample.type === MEDIA_TYPE.video) {
                            tempObj.videoListLength++;
                            if (sample.duration) {
                                tempObj.buferredDuration += sample.duration;
                            }
                        } else if (sample.type === MEDIA_TYPE.audio) {
                            tempObj.audioListLength++;
                        }
                    })

                    postMessage({
                        cmd: 'sampleListInfo',
                        ...tempObj
                    })

                }

                _loop() {
                    let sample = null;
                    if (this.allSampleList.length) {
                        sample = this.allSampleList.shift();
                        if (sample.type === MEDIA_TYPE.video) {
                            postMessage({
                                cmd: 'decodeVideo',
                                ...sample
                            }, [sample.payload.buffer])
                            //  check next is audio
                            let tempSample = this.allSampleList[0];
                            //  这边是检查了所有的audio
                            while (tempSample && tempSample.type === MEDIA_TYPE.audio) {
                                sample = this.allSampleList.shift();
                                postMessage({
                                    cmd: 'decodeAudio',
                                    ...sample
                                }, [sample.payload.buffer])
                                tempSample = this.allSampleList[0];
                            }

                        } else if (sample.type === MEDIA_TYPE.audio) {
                            postMessage({
                                cmd: 'decodeAudio',
                                ...sample
                            }, [sample.payload.buffer])
                            // check next is video
                            //  todo:这个就检查了一个。
                            if (this.allSampleList.length && this.allSampleList[0].type === MEDIA_TYPE.video) {
                                sample = this.allSampleList.shift();
                                postMessage({
                                    cmd: 'decodeVideo',
                                    ...sample
                                }, [sample.payload.buffer]);
                            }
                        }
                    }
                }
            }

            let loopWorker = new LoopWorker();
            self.onmessage = (e) => {
                const msg = e.data;
                switch (msg.cmd) {
                    case 'updateBaseFps':
                        loopWorker.updateBaseFps(msg.baseFps);
                        break;
                    case 'sample':
                        loopWorker.pushSample(msg);
                        break;
                    case 'destroy':
                        loopWorker.destroy();
                        loopWorker = null;
                        break;
                    default:
                        break;
                }
            }
        }

        const loopWorkerString = function2String(LoopWorkerFun.toString());

        const blob = new Blob([loopWorkerString], {type: "text/javascript"});
        const workerUrl = URL.createObjectURL(blob);
        let loopWorker = new Worker(workerUrl);
        this.workerUrl = workerUrl;
        // 必须要释放，不然每次调用内存都明显泄露内存
        // chrome 83 file协议下如果直接释放，将会使WebWorker无法启动
        this.workerClearTimeout = setTimeout(() => {
            window.URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
            this.workerClearTimeout = null;
        }, URL_OBJECT_CLEAR_TIME)

        loopWorker.onmessage = (event) => {
            const msg = event.data;
            switch (msg.cmd) {
                case 'decodeVideo':
                    this._doDecodeVideo(msg);
                    break;
                case 'decodeAudio':
                    this._doDecodeAudio(msg);
                    break;
                case 'sampleListInfo':
                    this.tempSampleListInfo = msg;
                    break;
                default:
                    break;
            }
        }

        this.loopWorker = loopWorker;
    }

    _postMessageToLoopWorker(cmd, options) {
        if (this.player.isUseMSE()) {
            return;
        }

        if (this.loopWorker) {
            this.loopWorker.postMessage({
                cmd,
                ...options
            })
        } else {
            this.player.debug.warn(this.TAG_NAME, `loop worker is not init, can not post message`);
        }
    }


    _doDecodeAudio(sample) {
        const uint8Array = new Uint8Array(sample.payload);
        this._doDecodeByHls(uint8Array, MEDIA_TYPE.audio, sample.dts, false, 0);
    }

    _doDecodeVideo(sample) {
        const uint8Array = new Uint8Array(sample.payload);
        let packet = null;
        if (sample.isHevc) {
            //  add 5 header
            packet = hevcEncoderNalePacketNotLength(uint8Array, sample.isIFrame);
        } else {
            packet = avcEncoderNalePacketNotLength(uint8Array, sample.isIFrame);
        }
        this.player.updateStats({
            dts: sample.dts,
        })
        const cts = sample.pts - sample.dts;
        this._doDecodeByHls(packet, MEDIA_TYPE.video, sample.dts, sample.isIFrame, cts);
    }


    getBuferredDuration() {
        return this.tempSampleListInfo.buferredDuration || 0;
    }

    getSampleListLength() {
        return this.tempSampleListInfo.allListLength || 0;
    }

    getSampleAudioListLength() {

        return this.tempSampleListInfo.audioListLength || 0;
    }

    getSampleVideoListLength() {
        return this.tempSampleListInfo.videoListLength || 0;
    }
}
