export default class Webgl2Render {
    constructor(canvas, gl) {
        this.canvas = canvas;
        this.gl = gl;

        const vertexShaderScript = `
            attribute vec2 xy;
            varying highp vec2 uv;
            void main(void) {
                gl_Position = vec4(xy, 0.0, 1.0);
                // Map vertex coordinates (-1 to +1) to UV coordinates (0 to 1).
                // UV coordinates are Y-flipped relative to vertex coordinates.
                uv = vec2((1.0 + xy.x) / 2.0, (1.0 - xy.y) / 2.0);
            }
        `
        const fragmentShaderScript = `
             varying highp vec2 uv;
             uniform sampler2D texture;
             void main(void) {
                gl_FragColor = texture2D(texture, uv);
             }
        `

        const vertexShader = gl.createShader(gl.VERTEX_SHADER);

        gl.shaderSource(vertexShader, vertexShaderScript);
        gl.compileShader(vertexShader);
        if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
            throw gl.getShaderInfoLog(vertexShader);
        }
        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fragmentShader, fragmentShaderScript);
        gl.compileShader(fragmentShader);
        if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
            throw gl.getShaderInfoLog(fragmentShader);
        }

        const program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            throw gl.getProgramInfoLog(program);
        }

        gl.useProgram(program);
        const buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), gl.STATIC_DRAW);
        const xy = gl.getAttribLocation(program, 'xy');
        gl.vertexAttribPointer(xy, 2, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(xy);
        const texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

        this.program = program;
        this.buffer = buffer;
        this.vertexShader = vertexShader;
        this.fragmentShader = fragmentShader;
        this.texture = texture;
    }

    destroy() {
        this.gl.deleteProgram(this.program);
        this.gl.deleteBuffer(this.buffer);
        this.gl.deleteTexture(this.texture);
        this.gl.deleteShader(this.vertexShader);
        this.gl.deleteShader(this.fragmentShader);
        this.program = null;
        this.buffer = null;
        this.vertexShader = null;
        this.fragmentShader = null;
        this.texture = null;
    }

    render(videoFrame) {
        this.canvas.width = videoFrame.displayWidth;
        this.canvas.height = videoFrame.displayHeight;
        const gl = this.gl
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, videoFrame);
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.clearColor(1, 0, 0, 1);
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
    }
}
