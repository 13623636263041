import {isNotEmpty} from "./index";

export function query(selector, parent = document) {
    return parent.querySelector(selector);
}

export function queryAll(selector, parent = document) {
    return Array.from(parent.querySelectorAll(selector));
}

export function addClass(target, className) {
    return target.classList.add(className);
}

export function removeClass(target, className) {
    return target.classList.remove(className);
}

export function hasClass(target, className) {
    return target.classList.contains(className);
}

// append
export function append(parent, child) {
    if (child instanceof Element) {
        parent.appendChild(child);
    } else {
        parent.insertAdjacentHTML('beforeend', String(child));
    }
    return parent.lastElementChild || parent.lastChild;
}

export function unshift(parent, child) {
    if (child instanceof Element) {
        parent.insertBefore(child, parent.firstChild);
    } else {
        parent.insertAdjacentHTML('afterbegin', String(child));
    }
    return parent.firstElementChild || parent.firstChild;
}


export function remove(child) {
    return child.parentNode.removeChild(child);
}

export function setStyle(element, key, value) {
    if (element && element.style && isNotEmpty(key)) {
        element.style[key] = value;
    }
    return element;
}

export function setStyles(element, styles) {
    Object.keys(styles).forEach((key) => {
        setStyle(element, key, styles[key]);
    });
    return element;
}

export function getStyle(element, key, numberType = true) {
    const value = window.getComputedStyle(element, null).getPropertyValue(key);
    return numberType ? parseFloat(value) : value;
}

// 找同级别不是target 的
export function sublings(target) {
    return Array.from(target.parentElement.children).filter((item) => item !== target);
}

// inverse class
export function inverseClass(target, className) {
    sublings(target).forEach((item) => removeClass(item, className));
    addClass(target, className);
}

//
export function tooltip(target, msg, pos = 'up') {
    target.setAttribute('aria-label', msg);
    target.setAttribute('data-balloon-pos', pos);
}

// is in view port
export function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
    return vertInView && horInView;
}

// include from event
export function includeFromEvent(event, target) {
    // js 捕获事件冒泡路径。
    return event.composedPath && event.composedPath().indexOf(target) > -1;
}

export function replaceElement(newChild, oldChild) {
    oldChild.parentNode.replaceChild(newChild, oldChild);
    return newChild;
}


export function createElement(tag) {
    return document.createElement(tag);
}


export function removeElement(element) {
    let result = false;
    if (element) {
        if (element.parentNode) {
            element.parentNode.removeChild(element);
            result = true;
        }
    }
    return result;
}


export function getElementRect(element) {
    const clientRect = element.getBoundingClientRect();
    clientRect.width = Math.max(clientRect.width, element.clientWidth);
    clientRect.height = Math.max(clientRect.height, element.clientHeight);
    return clientRect;
}
