import {clamp, convertToCamelCase, getTarget, isFunction, isMobile, isPc, setStyle} from "../utils";
import {
    EVENTS,
    PLAY_TYPE,
    PLAYBACK_CONTROL_TIME_PRECISION_ARRAY, PLAYBACK_CONTROL_TYPE,
    PTZ_ACTIVE_EVENT_TYPE,
    PTZ_ARROW,
    PTZ_OBJ
} from "../constant";
import {getPosFromEvent} from "./utils";
import {includeFromEvent} from "../utils/dom";

export default (player, control) => {
    const {
        events: {proxy},
        debug
    } = player;

    //
    const options = player._opt;
    const operateBtns = options.operateBtns;

    function volumeChangeFromEvent(event) {
        const {bottom: panelBottom, height: panelHeight} = control.$volumePanel.getBoundingClientRect();
        const {height: handleHeight} = control.$volumeHandle.getBoundingClientRect();

        let moveLen = event.y;

        // if (isMobile() && player.fullscreen) {
        //     moveLen = event.x;
        // }

        const percentage =
            clamp(panelBottom - moveLen - handleHeight / 2, 0, panelHeight - handleHeight / 2) / (panelHeight - handleHeight);
        return percentage;
    }

    //  右键菜单 for pc
    if (isPc()) {
        //
        proxy(window, ['click', 'contextmenu'], event => {
            if (event.composedPath().indexOf(player.$container) > -1) {
                control.isFocus = true;
            } else {
                control.isFocus = false;
            }
        });
    }

    proxy(control.$controls, 'click', (e) => {
        e.stopPropagation();
    })

    if (operateBtns.play) {
        proxy(control.$pause, 'click', (e) => {
            if (options.playType === PLAY_TYPE.playbackTF
                && options.playbackConfig.uiUsePlaybackPause) {
                player.playbackPause = true;
                // player.emit(EVENTS.playbackPauseOrResume, true);
            } else {
                if (isFunction(operateBtns.pauseFn)) {
                    operateBtns.pauseFn();
                } else {
                    player.pauseForControl();
                }
            }
        })
        // 监听 play 方法
        proxy(control.$play, 'click', (e) => {
            if (options.playType === PLAY_TYPE.playbackTF
                && player.playbackPause) {
                player.playbackPause = false;
                // player.emit(EVENTS.playbackPauseOrResume, false);
            } else {
                if (isFunction(operateBtns.playFn)) {
                    operateBtns.playFn();
                } else {
                    player.playForControl().then(() => {
                        player.resumeAudioAfterPause();
                    });
                }
            }
        })
    }


    // 监听 play 方法
    proxy(control.$playBig, 'click', (e) => {
        if (options.playType === PLAY_TYPE.playbackTF &&
            player.playbackPause) {
            player.playbackPause = false;
            // player.emit(EVENTS.playbackPauseOrResume, false);
        } else {
            if (isFunction(operateBtns.playFn)) {
                operateBtns.playFn();
            } else {
                player.playForControl().then(() => {
                    player.resumeAudioAfterPause()
                });
            }
        }
    })


    if (operateBtns.screenshot) {
        proxy(control.$screenshot, 'click', (e) => {
            e.stopPropagation();
            if (isFunction(operateBtns.screenshotFn)) {
                operateBtns.screenshotFn();
            } else {
                player.video.screenshot();
            }
        })
    }

    if (operateBtns.audio) {

        //  audio panel
        if (isPc()) {
            proxy(control.$volume, 'mouseover', () => {
                control.$volumePanelWrap.classList.add('jessibuca-volume-panel-wrap-show');
            })

            proxy(control.$volume, 'mouseout', () => {
                control.$volumePanelWrap.classList.remove('jessibuca-volume-panel-wrap-show');
            })

            proxy(control.$volumePanel, 'click', event => {
                event.stopPropagation();
                player.volume = volumeChangeFromEvent(event);
            });

            proxy(control.$volumeHandle, 'mousedown', (event) => {
                event.stopPropagation();
                control.isVolumeDroging = true;
            });

            proxy(control.$volumeHandle, 'mousemove', event => {
                if (control.isVolumeDroging) {
                    player.volume = volumeChangeFromEvent(event);
                }
            });

            proxy(document, 'mouseup', () => {
                if (control.isVolumeDroging) {
                    control.isVolumeDroging = false;
                }
            });
        }


        proxy(control.$volumeOn, 'click', (e) => {
            e.stopPropagation();
            setStyle(control.$volumeOn, 'display', 'none');
            setStyle(control.$volumeOff, 'display', 'block');
            const lastVolume = player.volume;
            player.volume = 0;
            player._lastVolume = isPc() ? lastVolume : 1;
        })

        proxy(control.$volumeOff, 'click', (e) => {
            e.stopPropagation();
            setStyle(control.$volumeOn, 'display', 'block');
            setStyle(control.$volumeOff, 'display', 'none');
            player.volume = isPc() ? (player.lastVolume || 0.5) : 1;
        })
    }


    if (operateBtns.record) {
        proxy(control.$record, 'click', (e) => {
            e.stopPropagation();
            if (isFunction(operateBtns.recordFn)) {
                operateBtns.recordFn();
            } else {
                player.recording = true;
            }
        })

        proxy(control.$recordStop, 'click', (e) => {
            e.stopPropagation();
            if (isFunction(operateBtns.recordStopFn)) {
                operateBtns.recordStopFn();
            } else {
                player.recording = false;
            }
        })
    }

    proxy(control.$recordingStop, 'click', (e) => {
        e.stopPropagation();
        if (isFunction(operateBtns.recordStopFn)) {
            operateBtns.recordStopFn();
        } else {
            player.recording = false;
        }
    })

    if (operateBtns.fullscreen) {
        proxy(control.$fullscreen, 'click', (e) => {
            e.stopPropagation();

            if (isFunction(operateBtns.fullscreenFn)) {
                operateBtns.fullscreenFn();
            } else {
                player.fullscreen = true;
            }
        })

        proxy(control.$fullscreenExit, 'click', (e) => {
            e.stopPropagation();

            if (isFunction(operateBtns.fullscreenExitFn)) {
                operateBtns.fullscreenExitFn();
            } else {
                player.fullscreen = false;
            }
        })
    }

    if (operateBtns.ptz) {
        proxy(control.$ptz, 'click', (e) => {
            e.stopPropagation();
            setStyle(control.$ptzActive, 'display', 'flex');
            setStyle(control.$ptz, 'display', 'none');
            control.$ptzControl.classList.add('jessibuca-ptz-controls-show');
        })
        proxy(control.$ptzActive, 'click', (e) => {
            e.stopPropagation();
            setStyle(control.$ptz, 'display', 'flex');
            setStyle(control.$ptzActive, 'display', 'none');
            control.$ptzControl.classList.remove('jessibuca-ptz-controls-show');
        })
        // 绑定在 ptz 按钮上面 才会出现的。
        control.$ptzArrows.forEach(($ptzArrow) => {
            if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.click) {
                proxy($ptzArrow, 'click', (e) => {
                    e.stopPropagation();
                    const target = e.currentTarget;
                    const dataset = target.dataset;
                    const arrow = dataset.arrow;
                    control.$ptzBgActive.classList.add('jessibuca-ptz-bg-active-show');
                    control.$ptzBgActive.classList.add(`jessibuca-ptz-bg-active-${arrow}`);
                    control.$ptzControlCircular.classList.add(`jessibuca-ptz-control-${arrow}`);
                    player.emit(EVENTS.ptz, convertToCamelCase(arrow));
                    setTimeout(() => {
                        control.$ptzBgActive.classList.remove('jessibuca-ptz-bg-active-show');
                        PTZ_ARROW.forEach((arrow) => {
                            control.$ptzBgActive.classList.remove(`jessibuca-ptz-bg-active-${arrow}`);
                            control.$ptzControlCircular.classList.remove(`jessibuca-ptz-control-${arrow}`);
                        })
                        player.emit(EVENTS.ptz, PTZ_OBJ.stop);
                    }, options.ptzStopEmitDelay * 1000);
                })
            } else if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.mouseDownAndUp) {
                let isPtzMouseDown = false;
                proxy($ptzArrow, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzMouseDown = true;
                    const target = e.currentTarget;
                    const dataset = target.dataset;
                    const arrow = dataset.arrow;
                    control.$ptzBgActive.classList.add('jessibuca-ptz-bg-active-show');
                    control.$ptzBgActive.classList.add(`jessibuca-ptz-bg-active-${arrow}`);
                    control.$ptzControlCircular.classList.add(`jessibuca-ptz-control-${arrow}`);

                    player.emit(EVENTS.ptz, convertToCamelCase(arrow));
                })
                const ptzStop = () => {
                    isPtzMouseDown = false;
                    control.$ptzBgActive.classList.remove('jessibuca-ptz-bg-active-show');
                    PTZ_ARROW.forEach((arrow) => {
                        control.$ptzBgActive.classList.remove(`jessibuca-ptz-bg-active-${arrow}`);
                        control.$ptzControlCircular.classList.remove(`jessibuca-ptz-control-${arrow}`);
                    })
                    player.emit(EVENTS.ptz, PTZ_OBJ.stop);
                }
                proxy($ptzArrow, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzMouseDown) {
                        ptzStop();
                    }
                })

                proxy(window, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzMouseDown) {
                        ptzStop();
                    }
                })
            }
        })

        if (options.ptzZoomShow) {
            if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.click) {
                proxy(control.$ptzExpand, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.zoomExpand);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.stop);
                    }, options.ptzStopEmitDelay * 1000);
                })
                proxy(control.$ptzNarrow, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.zoomNarrow);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.stop);
                    }, options.ptzStopEmitDelay * 1000);
                })
            } else if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.mouseDownAndUp) {
                let isPtzExpandMouseDown = false;
                let isPtzNarrowMouseDown = false;
                proxy(control.$ptzExpand, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzExpandMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.zoomExpand);
                })
                proxy(control.$ptzNarrow, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzNarrowMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.zoomNarrow);
                })

                const ptzStop = () => {
                    isPtzExpandMouseDown = false;
                    isPtzNarrowMouseDown = false;
                    player.emit(EVENTS.ptz, PTZ_OBJ.stop);
                }
                proxy(control.$ptzExpand, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzExpandMouseDown) {
                        ptzStop();
                    }
                })

                proxy(control.$ptzNarrow, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzNarrowMouseDown) {
                        ptzStop();
                    }
                })

                proxy(window, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzExpandMouseDown || isPtzNarrowMouseDown) {
                        ptzStop();
                    }
                })
            }
        }

        if (options.ptzApertureShow) {
            if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.click) {
                proxy(control.$ptzApertureFar, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.apertureFar);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                    }, options.ptzStopEmitDelay * 1000);
                })

                proxy(control.$ptzApertureNear, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.apertureNear);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                    }, options.ptzStopEmitDelay * 1000);
                })
            } else if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.mouseDownAndUp) {
                let isPtzApertureFarMouseDown = false;
                let isPtzApertureNearMouseDown = false;
                proxy(control.$ptzApertureFar, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzApertureFarMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.apertureFar);
                })
                proxy(control.$ptzApertureNear, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzApertureNearMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.apertureNear);
                })

                const ptzStop = () => {
                    isPtzApertureFarMouseDown = false;
                    isPtzApertureNearMouseDown = false;
                    player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                }
                proxy(control.$ptzApertureFar, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzApertureFarMouseDown) {
                        ptzStop();
                    }
                })

                proxy(control.$ptzApertureNear, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzApertureNearMouseDown) {
                        ptzStop();
                    }
                })

                proxy(window, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzApertureFarMouseDown || isPtzApertureNearMouseDown) {
                        ptzStop();
                    }
                })
            }
        }

        if (options.ptzFocusShow) {
            if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.click) {
                proxy(control.$ptzFocusFar, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.focusFar);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                    }, options.ptzStopEmitDelay * 1000);
                })

                proxy(control.$ptzFocusNear, 'click', (e) => {
                    e.stopPropagation();
                    player.emit(EVENTS.ptz, PTZ_OBJ.focusNear);
                    setTimeout(() => {
                        player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                    }, options.ptzStopEmitDelay * 1000);
                })
            } else if (options.ptzClickType === PTZ_ACTIVE_EVENT_TYPE.mouseDownAndUp) {
                let isPtzFocusFarMouseDown = false;
                let isPtzFocusNearMouseDown = false;
                proxy(control.$ptzFocusFar, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzFocusFarMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.focusFar);
                })
                proxy(control.$ptzFocusNear, 'mousedown', (e) => {
                    e.stopPropagation();
                    isPtzFocusNearMouseDown = true;
                    player.emit(EVENTS.ptz, PTZ_OBJ.focusNear);
                })

                const ptzStop = () => {
                    isPtzFocusFarMouseDown = false;
                    isPtzFocusNearMouseDown = false;
                    player.emit(EVENTS.ptz, PTZ_OBJ.fiStop);
                }
                proxy(control.$ptzFocusFar, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzFocusFarMouseDown) {
                        ptzStop();
                    }
                })

                proxy(control.$ptzFocusNear, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzFocusNearMouseDown) {
                        ptzStop();
                    }
                })

                proxy(window, 'mouseup', (e) => {
                    e.stopPropagation();
                    if (isPtzFocusFarMouseDown || isPtzFocusNearMouseDown) {
                        ptzStop();
                    }
                })
            }

        }
    }


    if (operateBtns.performance) {
        proxy(control.$performance, 'click', (e) => {
            e.stopPropagation();
            player.togglePerformancePanel(true)
        })

        proxy(control.$performanceActive, 'click', (e) => {
            e.stopPropagation();
            player.togglePerformancePanel(false)
        })
    }


    if (operateBtns.logSave) {
        proxy(control.$logSave, 'click', (e) => {
            e.stopPropagation();
            player.downloadMemoryLog();
        })
    }


    if (operateBtns.aiFace) {
        proxy(control.$faceDetect, 'click', (e) => {
            e.stopPropagation();
            player.faceDetect(true)
        })

        proxy(control.$faceDetectActive, 'click', (e) => {
            e.stopPropagation();
            player.faceDetect(false)
        })
    }

    if (operateBtns.aiObject) {
        proxy(control.$objectDetect, 'click', (e) => {
            e.stopPropagation();
            player.objectDetect(true)
        })

        proxy(control.$objectDetectActive, 'click', (e) => {
            e.stopPropagation();
            player.objectDetect(false)
        })
    }

    if (operateBtns.aiOcclusion) {
        proxy(control.$occlusionDetect, 'click', (e) => {
            e.stopPropagation();
            player.occlusionDetect(true)
        })

        proxy(control.$occlusionDetectActive, 'click', (e) => {
            e.stopPropagation();
            player.occlusionDetect(false)
        })
    }


    if (player._opt.hasControl && player._opt.controlAutoHide) {
        //
        proxy(player.$container, 'mouseover', () => {
            if (!player.fullscreen) {
                setStyle(control.$controls, 'display', 'block');
                startDelayControlHidden();
            }
        })

        proxy(player.$container, 'mousemove', () => {
            if (player.$container && control.$controls) {
                if (!player.fullscreen) {
                    if (control.$controls.style.display === 'none') {
                        setStyle(control.$controls, 'display', 'block');
                        startDelayControlHidden();
                    }
                } else {
                    if (control.$controls.style.display === 'none') {
                        setStyle(control.$controls, 'display', 'block');
                        startDelayControlHidden();
                    }
                }
            }
        })

        proxy(player.$container, 'mouseout', () => {
            stopDelayControlHidden();
            setStyle(control.$controls, 'display', 'none');
        })
        let delayHiddenTimeout = null;
        const startDelayControlHidden = () => {
            stopDelayControlHidden();
            delayHiddenTimeout = setTimeout(() => {
                setStyle(control.$controls, 'display', 'none');
            }, 5 * 1000)
        }

        const stopDelayControlHidden = () => {
            if (delayHiddenTimeout) {
                clearTimeout(delayHiddenTimeout);
                delayHiddenTimeout = null;
            }
        }
    }

    // show playback operate
    // 录像流监听方法
    if (player._opt.playType === PLAY_TYPE.playbackTF) {

        let playbackControlType = player._opt.playbackConfig.controlType;

        if (player._opt.playbackConfig.showRateBtn) {
            proxy(control.$speedMenu, 'mouseover', () => {
                control.$speedMenuList.classList.add('jessibuca-speed-menu-shown');
            })
            proxy(control.$speedMenu, 'mouseout', () => {
                control.$speedMenuList.classList.remove('jessibuca-speed-menu-shown');
            })
            proxy(control.$speedMenuList, 'click', (e) => {
                const target = getTarget(e)
                if (target.matches('div.jessibuca-speed-menu-item')) {
                    const dataset = target.dataset;
                    player.emit(EVENTS.playbackPreRateChange, dataset.speed);
                }
            })

        }


        if (playbackControlType === PLAYBACK_CONTROL_TYPE.normal) {
            //
            proxy(control.$playbackNarrow, 'click', (e) => {
                e.stopPropagation();
                if (player.playback) {
                    player.playback.narrowPrecision();
                }
            })

            proxy(control.$playbackExpand, 'click', (e) => {
                e.stopPropagation();
                if (player.playback) {
                    player.playback.expandPrecision();
                }
            })
            //
            proxy(control.$playbackTimeList, 'click', (e) => {
                const target = getTarget(e)
                if (target.matches('div.jessibuca-playback-time-minute-one')) {
                    if (player.playback) {
                        player.playback.seek(target.dataset);
                    }
                }
            })
            // 支持滚动轴
            if (player._opt.playbackConfig.supportWheel) {
                //
                proxy(control.$playbackTimeInner, 'wheel', (event) => {
                    event.preventDefault();
                    const delta = (event.wheelDelta) ? event.wheelDelta / 120 : -(event.detail || 0) / 3;
                    // 向下
                    if (delta > 0) {
                        if (player.playback) {
                            player.playback.expandPrecision();
                        }
                    }
                    // 向上
                    else {
                        if (player.playback) {
                            player.playback.narrowPrecision();
                        }
                    }
                })
            }
        } else if (playbackControlType === PLAYBACK_CONTROL_TYPE.simple) {
            control.isDroging = false;
            //
            proxy(control.$playbackProgress, 'click', (event) => {
                if (event.target !== control.$playbackProgressIndicator) {
                    let percentage = 0;
                    let second = 0;
                    // 看下是否被旋转了。
                    if (player.isInWebFullscreen()) {
                        percentage = event.touches[0].clientY / player.height;
                        second = percentage * player.playback.totalDuration;
                    } else {
                        const position = getPosFromEvent(control, player.playback.totalDuration, event);
                        percentage = position.percentage;
                        second = position.second;
                    }

                    if (player.playback) {
                        player.playback.seek({time: second});
                    }
                }
            })


            proxy(control.$playbackProgress, 'mousemove', (event) => {
                setStyle(control.$playbackProgressTip, 'display', 'block')
                const {width, time} = getPosFromEvent(control, player.playback.totalDuration, event);
                control.$playbackProgressTip.innerHTML = time;
                const tipWidth = control.$playbackProgressTip.clientWidth;
                if (width <= tipWidth / 2) {
                    setStyle(control.$playbackProgressTip, 'left', 0);
                } else if (width > control.$playbackProgress.clientWidth - tipWidth / 2) {
                    setStyle(control.$playbackProgressTip, 'left', `${control.$playbackProgress - tipWidth}px`);
                } else {
                    setStyle(control.$playbackProgressTip, 'left', `${width - tipWidth / 2}px`);
                }
            })

            proxy(control.$playbackProgress, 'mouseout', () => {
                setStyle(control.$playbackProgressTip, 'display', 'none')
            })

            proxy(control.$playbackProgressIndicator, 'mousedown', (event) => {
                control.isDroging = true;
            })

            proxy(control.$playbackProgress, 'mousemove', (event) => {
                if (control.isDroging) {
                    const {second, percentage} = getPosFromEvent(control, player.playback.totalDuration, event);

                    if (player.playback) {
                        player.playback.seek({time: second});
                    }
                }
            })

            proxy(control.$playbackProgress, 'mouseup', (event) => {
                if (control.isDroging) {
                    control.isDroging = false;
                }
            })
        }
    }

    //
    if (operateBtns.quality) {

        proxy(control.$qualityMenu, 'mouseover', () => {
            control.$qualityMenuList.classList.add('jessibuca-quality-menu-shown');
        })

        proxy(control.$qualityMenu, 'mouseout', () => {
            control.$qualityMenuList.classList.remove('jessibuca-quality-menu-shown');
        })

        proxy(control.$qualityMenuList, 'click', (e) => {
            const target = getTarget(e)
            if (target.matches('div.jessibuca-quality-menu-item')) {
                const dataset = target.dataset;
                player.streamQuality = dataset.quality;
            }
        })
    }

    if (operateBtns.scale) {

        proxy(control.$scaleMenu, 'mouseover', () => {
            control.$scaleMenuList.classList.add('jessibuca-scale-menu-shown');
        })

        proxy(control.$scaleMenu, 'mouseout', () => {
            control.$scaleMenuList.classList.remove('jessibuca-scale-menu-shown');
        })

        proxy(control.$scaleMenuList, 'click', (e) => {
            const target = getTarget(e)
            if (target.matches('div.jessibuca-scale-menu-item')) {
                const dataset = target.dataset;
                player.setScaleMode(dataset.scale);
            }
        })
    }

    if (operateBtns.zoom) {

        proxy(control.$zoom, 'click', (e) => {
            e.stopPropagation();
            player.zooming = true;
        })

        proxy(control.$zoomStop, 'click', (e) => {
            e.stopPropagation();
            player.zooming = false;

        })
    }

    proxy(control.$zoomExpand, 'click', (e) => {
        e.stopPropagation();

        if (player.zoom) {
            player.zoom.expandPrecision()
        }
    })
    proxy(control.$zoomNarrow, 'click', (e) => {
        e.stopPropagation();
        if (player.zoom) {
            player.zoom.narrowPrecision()
        }
    })

    proxy(control.$zoomStop2, 'click', (e) => {
        e.stopPropagation();
        player.zooming = false;
    })


    if (operateBtns.close) {
        proxy(control.$close, 'click', (e) => {
            e.stopPropagation();
            player.doDestroy();
        })
    }


    proxy(control.$tipsMessageClose, 'click', (e) => {
        e.stopPropagation();
        // clear and hidden
        control.$tipsMessageContent.innerHTML = '';
        setStyle(control.$tipsMessage, 'display', 'none');
    })

}
