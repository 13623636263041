import FlvLoader from "./flvLoader";
import M7sLoader from "./m7sLoader";
import {DEMUX_TYPE} from "../constant";
import WebTransportLoader from "./webTransportLoader";
import NakedFlowLoader from "./nakedFlowLoader";
import EmptyLoader from "./emptyLoader";
import Fmp4Loader from "./fmp4Loader";
import Mpeg4Loader from "./mpeg4Loader";

export default class Demux {
    constructor(player) {
        const Loader = Demux.getLoaderFactory(player);
        return new Loader(player);
    }

    static getLoaderFactory(player) {
        const type = player._opt.demuxType;
        if (type === DEMUX_TYPE.m7s) {
            return M7sLoader;
        } else if (type === DEMUX_TYPE.flv || player.isWebrtcH265()) {
            return FlvLoader;
        } else if (type === DEMUX_TYPE.webTransport) {
            return WebTransportLoader;
        } else if (type === DEMUX_TYPE.nakedFlow) {
            return NakedFlowLoader;
        } else if (type === DEMUX_TYPE.fmp4) {
            return Fmp4Loader;
        } else if (type === DEMUX_TYPE.mpeg4) {
            return Mpeg4Loader;
        } else {
            return EmptyLoader;
        }
    }
}
