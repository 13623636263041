import NetLoader from "../../utils/netLoader";
import {StreamingError, ERR} from "../error";
import M3U8Parser from "./parser";
import {HLS_EVENTS} from "../../constant";

export default class ManifestLoader {

    constructor(hls) {
        this.hls = hls
        this.player = hls.player
        this.TAG_NAME = 'HlsManifestLoader'


        this._timer = null

        const {retryCount, retryDelay, loadTimeout, fetchOptions} = this.hls.config

        this._loader = new NetLoader({
            ...fetchOptions,
            responseType: 'text',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry
        }, this.player)

        this._audioLoader = new NetLoader({
            ...fetchOptions,
            responseType: 'text',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry,
        }, this.player)

        this._subtitleLoader = new NetLoader({
            ...fetchOptions,
            responseType: 'text',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry,
        }, this.player)

    }

    async destroy() {

        await this.stopPoll();
        if (this._audioLoader) {
            this._audioLoader.destroy()
            this._audioLoader = null
        }

        if (this._subtitleLoader) {
            this._subtitleLoader.destroy()
            this._subtitleLoader = null
        }

        if (this._loader) {
            this._loader.destroy()
            this._loader = null
        }
    }

    // load
    async load(url, audioUrl, subtitleUrl) {
        this.player.debug.log(this.TAG_NAME, 'load()', url, audioUrl, subtitleUrl);
        const toLoad = [this._loader.load(url)]
        if (audioUrl) {
            toLoad.push(this._audioLoader.load(audioUrl))
        }

        if (subtitleUrl) {
            toLoad.push(this._subtitleLoader.load(subtitleUrl))
        }

        let videoText
        let audioText
        let subtitleText

        try {

            const [video, audio, subtitle] = await Promise.all(toLoad)

            if (!video) return []

            videoText = video.data

            if (audioUrl) {
                audioText = audio?.data
                subtitleText = subtitle?.data
            } else {
                subtitleText = audio?.data
            }

        } catch (error) {
            throw StreamingError.network(error)
        }

        let playlist

        let audioPlaylist

        let subtitlePlaylist

        try {

            // playlist 对象
            playlist = M3U8Parser.parse(videoText, url)

            if (playlist?.live === false && playlist.segments && !playlist.segments.length) {
                throw new Error('empty segments list')
            }

            if (audioText) {
                audioPlaylist = M3U8Parser.parse(audioText, audioUrl)
            }
            if (subtitleText) {
                subtitlePlaylist = M3U8Parser.parse(subtitleText, subtitleUrl)
            }

        } catch (error) {
            throw new StreamingError(ERR.MANIFEST, ERR.HLS, error)
        }

        // playlist
        if (playlist) {
            if (playlist.isMaster) {
                // hls manifest loaded
                // 主从m3u8格式时，master m3u8文件加载并解析完成后, 抛出master m3u8解析后的结构
                this.hls.emit(HLS_EVENTS.HLS_MANIFEST_LOADED, {playlist})
            } else {
                // hls level loaded
                // 二级m3u8加载并解析完成后，抛出解析后的结构，
                this.hls.emit(HLS_EVENTS.HLS_LEVEL_LOADED, {playlist})
            }
        }

        // playlist ,audioPlaylist, subtitlePlaylist
        return [playlist, audioPlaylist, subtitlePlaylist]
    }

    // poll
    poll(url, audioUrl, subtitleUrl, cb, errorCb, time) {
        clearTimeout(this._timer)

        time = time || 3000

        let retryCount = this.hls.config.pollRetryCount

        const fn = async () => {
            clearTimeout(this._timer)
            try {
                const res = await this.load(url, audioUrl, subtitleUrl)
                if (!res[0]) return
                retryCount = this.hls.config.pollRetryCount
                cb(res[0], res[1], res[2])
            } catch (e) {
                retryCount--
                if (retryCount <= 0) {
                    errorCb(e)
                }
            }
            this._timer = setTimeout(fn, time)
        }
        this._timer = setTimeout(fn, time)
    }

    stopPoll() {
        clearTimeout(this._timer)
        return this.cancel()
    }

    cancel() {
        return Promise.all([
            this._loader.cancel(),
            this._audioLoader.cancel()
        ])
    }

    _onLoaderRetry = (error, retryTime) => {
        // 请求发生重试时触发。参数如下。
        this.hls.emit(HLS_EVENTS.LOAD_RETRY, {
            error: StreamingError.network(error),
            retryTime
        })
    }
}

