import {
    EVENTS_ERROR,
    FLV_MEDIA_TYPE, FRAME_HEADER_EX, FRAME_TYPE_EX, H264_NAL_TYPE, H265_NAL_TYPE,
    MEDIA_TYPE, PACKET_TYPE_EX,
    VIDEO_ENC_CODE,
    VIDEO_FRAME_TYPE,
    VIDEO_PACKET_TYPE
} from "../constant";
import CommonLoader from "./commonLoader";
import {isFalse, isNotEmpty, isTrue, isVideoSequenceHeader, now} from "../utils";
import OPut from "oput";
import {getUnitSizeFromVideoSequenceHeader, parseAvcC} from "../utils/nalu";
import {parseFlvScriptData} from "../utils/flv";

export default class FlvLoader extends CommonLoader {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'FlvDemux';
        this.input = new OPut(this.demux());
        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this.input = null;
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    dispatch(data) {
        if (this.input) {
            this.input.write(data);
        } else {
            this.player && this.player.debug.warn(this.TAG_NAME, 'dispatch() this.input is null');
        }
        // this.player.debug.log(this.TAG_NAME, `this.input.buffer.length is ${this.input.buffer.length},byteLength is ${this.input.buffer.byteLength}`);
    }

    * demux() {
        yield 9;
        const tmp = new ArrayBuffer(4);
        const tmp8 = new Uint8Array(tmp);
        const tmp32 = new Uint32Array(tmp);
        const player = this.player;
        while (true) {
            if (!this.input) {
                return;
            }
            tmp8[3] = 0;
            const t = yield 15;
            const type = t[4];
            tmp8[0] = t[7];
            tmp8[1] = t[6];
            tmp8[2] = t[5];
            const length = tmp32[0];
            tmp8[0] = t[10];
            tmp8[1] = t[9];
            tmp8[2] = t[8];
            tmp8[3] = t[11];
            let ts = tmp32[0];

            const payload = (yield length).slice();
            if (!player) {
                return;
            }
            switch (type) {
                case FLV_MEDIA_TYPE.audio:
                    if (player._opt.hasAudio) {
                        player.updateStats({
                            abps: payload.byteLength
                        });
                        if (payload.byteLength > 0) {

                            let payloadBuffer = payload
                            if (isTrue(this.player._opt.m7sCryptoAudio)) {
                                payloadBuffer = this.cryptoPayloadAudio(payload);
                            }
                            this._doDecode(payloadBuffer, MEDIA_TYPE.audio, ts);
                        }
                    }
                    break;
                case FLV_MEDIA_TYPE.video:
                    // console.log('flv loader demux', payload);
                    if (player._opt.hasVideo &&
                        payload.length >= 6) {
                        let dts = ts;
                        player.updateStats({
                            vbps: payload.byteLength, dts: dts
                        });

                        if (!player._times.demuxStart) {
                            player._times.demuxStart = now();
                        }

                        const flags = payload[0];
                        if (this._isEnhancedH265Header(flags)) {
                            this._decodeEnhancedH265Video(payload, dts);
                        } else {
                            const codecId = flags & 0x0F;
                            const frameType = flags >> 4 & 0x0F;
                            const isIFrame = frameType === VIDEO_FRAME_TYPE.keyFrame;

                            if (isFalse(codecId === VIDEO_ENC_CODE.h265 ||
                                codecId === VIDEO_ENC_CODE.h264)) {
                                this.player.debug.warn(this.TAG_NAME, `demux() codecId is ${codecId} and ignore`);
                                return;
                            }

                            if (isIFrame) {
                                this.calcIframeIntervalTimestamp(ts);
                                if (this.nalUnitSize === null &&
                                    isVideoSequenceHeader(payload)) {
                                    this.updateNalUnitSize(payload);
                                }
                            }
                            // cts
                            tmp32[0] = payload[4]
                            tmp32[1] = payload[3]
                            tmp32[2] = payload[2]
                            tmp32[3] = 0
                            let cts = tmp32[0]

                            let payloadBuffer = this.cryptoPayload(payload, isIFrame);

                            this._doDecode(payloadBuffer, MEDIA_TYPE.video, ts, isIFrame, cts);
                        }
                    } else {
                        if (payload.length < 6) {
                            player.debug.warn(this.TAG_NAME, `payload.length is ${payload.length} less than 6 and ignore`);
                        }
                    }
                    break;
                case FLV_MEDIA_TYPE.scriptData:
                    if (this.player.isRecordTypeFlv()) {
                        const payloadCopy = new Uint8Array(payload);
                        this.player.recorder.addMetaData(payloadCopy);
                    }
                    const scriptObj = parseFlvScriptData(payload);
                    if (scriptObj && scriptObj.onMetaData) {
                        player.updateMetaData(scriptObj.onMetaData);
                    }
                    break;
                default:
                    player.debug.log(this.TAG_NAME, `demux() type is ${type}`);
                    break;
            }

        }
    }


    close() {
        this.input = null
    }

    getInputByteLength() {
        let result = 0;
        if (this.input && this.input.buffer) {
            result = this.input.buffer.byteLength;
        }
        return result;
    }

    hasUnitTypeIDR(payload, isHevc) {
        const units = parseAvcC(payload.slice(5));
        let hasIDR = false;
        units.forEach((unit) => {
            const type = isHevc ? (unit[0] >>> 1) & 0x3f : unit[0] & 0x1f;
            //  IDR
            if ((isHevc && (type === H265_NAL_TYPE.iFrame || type === H265_NAL_TYPE.nLp)) ||
                (isFalse(isHevc) && type === H264_NAL_TYPE.iFrame)) {
                hasIDR = true;
            }
        })
        return hasIDR;
    }
}
