import SPSParser from './h264-sps-parser.js';
import Bitop from "./bitop";
import {H264_NAL_TYPE, VIDEO_ENCODE_TYPE} from "../constant";
import ExpGolombV2 from "./exp-golomb-v2";

//
export function parseAVCDecoderConfigurationRecord(arrayBuffer) {
    const meta = {}
    const v = new DataView(arrayBuffer.buffer);
    let version = v.getUint8(0);  // configurationVersion
    let avcProfile = v.getUint8(1);  // avcProfileIndication
    let profileCompatibility = v.getUint8(2);  // profile_compatibil
    let avcLevel = v.getUint8(3);  // AVCLevelIndication

    if (version !== 1 || avcProfile === 0) {
        // this._onError(DemuxErrors.FORMAT_ERROR, 'Flv: Invalid AVCDecoderConfigurationRecord');

        return {};
    }

    const _naluLengthSize = (v.getUint8(4) & 3) + 1;  // lengthSizeMinusOne

    if (_naluLengthSize !== 3 && _naluLengthSize !== 4) {  // holy shit!!!
        // this._onError(DemuxErrors.FORMAT_ERROR, `Flv: Strange NaluLengthSizeMinusOne: ${_naluLengthSize - 1}`);
        return {};
    }
    let spsCount = v.getUint8(5) & 31;  // numOfSequenceParameterSets

    if (spsCount === 0) {
        // this._onError(DemuxErrors.FORMAT_ERROR, 'Flv: Invalid AVCDecoderConfigurationRecord: No SPS');
        return {};
    } else if (spsCount > 1) {
        // Log.w(this.TAG, `Flv: Strange AVCDecoderConfigurationRecord: SPS Count = ${spsCount}`);
    }

    let offset = 6;
    for (let i = 0; i < spsCount; i++) {
        let len = v.getUint16(offset, false);  // sequenceParameterSetLength
        offset += 2;

        if (len === 0) {
            continue;
        }

        // Notice: Nalu without startcode header (00 00 00 01)
        let sps = new Uint8Array(arrayBuffer.buffer, offset, len);
        offset += len;
        // flv.js作者选择了自己来解析这个数据结构，也是迫不得已，因为JS环境下没有ffmpeg，解析这个结构主要是为了提取 sps和pps。虽然理论上sps允许有多个，但其实一般就一个。
        // packetTtype 为 1 表示 NALU，NALU= network abstract layer unit，这是H.264的概念，网络抽象层数据单元，其实简单理解就是一帧视频数据。
        // pps的信息没什么用，所以作者只实现了sps的分析器，说明作者下了很大功夫去学习264的标准，其中的Golomb解码还是挺复杂的，能解对不容易，我在PC和手机平台都是用ffmpeg去解析的。
        // SPS里面包括了视频分辨率，帧率，profile level等视频重要信息。
        let config = SPSParser.parseSPS(sps);
        // console.log('h264 sps config',config)
        if (i !== 0) {
            // ignore other sps's config
            continue;
        }
        meta.sps = sps;
        meta.timescale = 1000;
        meta.codecWidth = config.codec_size.width;
        meta.codecHeight = config.codec_size.height;
        meta.presentWidth = config.present_size.width;
        meta.presentHeight = config.present_size.height;

        meta.profile = config.profile_string;
        meta.level = config.level_string;
        meta.bitDepth = config.bit_depth;
        meta.chromaFormat = config.chroma_format;
        meta.sarRatio = config.sar_ratio;
        meta.frameRate = config.frame_rate;

        if (config.frame_rate.fixed === false ||
            config.frame_rate.fps_num === 0 ||
            config.frame_rate.fps_den === 0) {
            meta.frameRate = {
                fixed: true,
                // fps: 23.976,
                // fps_num: 23976,
                fps:25,
                fps_num: 25000,
                fps_den: 1000
            };
        }

        let fps_den = meta.frameRate.fps_den;
        let fps_num = meta.frameRate.fps_num;
        meta.refSampleDuration = meta.timescale * (fps_den / fps_num);

        let codecArray = sps.subarray(1, 4);

        let codecString = 'avc1.';
        for (let j = 0; j < 3; j++) {
            let h = codecArray[j].toString(16);
            if (h.length < 2) {
                h = '0' + h;
            }
            codecString += h;
        }
        // codec
        meta.codec = codecString;
    }

    let ppsCount = v.getUint8(offset);  // numOfPictureParameterSets
    if (ppsCount === 0) {
        // this._onError(DemuxErrors.FORMAT_ERROR, 'Flv: Invalid AVCDecoderConfigurationRecord: No PPS');
        return {};
    } else if (ppsCount > 1) {
        // Log.w(this.TAG, `Flv: Strange AVCDecoderConfigurationRecord: PPS Count = ${ppsCount}`);
    }

    offset++;
    for (let i = 0; i < ppsCount; i++) {
        let len = v.getUint16(offset, false);  // pictureParameterSetLength
        offset += 2;

        if (len === 0) {
            continue;
        }
        let pps = new Uint8Array(arrayBuffer.buffer, offset, len);

        // pps is useless for extracting video information
        offset += len;
        meta.pps = pps;
    }

    meta.videoType = 'avc';

    if (meta.sps) {
        const spsLength = meta.sps.byteLength;
        const spsFlag = new Uint8Array([
            (spsLength >>> 24) & 0xFF,
            (spsLength >>> 16) & 0xFF,
            (spsLength >>> 8) & 0xFF,
            (spsLength) & 0xFF
        ])
        const sps = new Uint8Array(spsLength + 4);
        sps.set(spsFlag, 0);
        sps.set(meta.sps, 4);
        meta.sps = sps;
    }

    if (meta.pps) {
        const ppsLength = meta.pps.byteLength;
        const ppsFlag = new Uint8Array([
            (ppsLength >>> 24) & 0xFF,
            (ppsLength >>> 16) & 0xFF,
            (ppsLength >>> 8) & 0xFF,
            (ppsLength) & 0xFF
        ]);
        const pps = new Uint8Array(ppsLength + 4);
        pps.set(ppsFlag, 0);
        pps.set(meta.pps, 4);
        meta.pps = pps;
    }


    // meta.avcc = arrayBuffer;
    return meta;
}

/**
 *
 * @param arrayBuffer
 * @returns {{}}
 */
export function parseAVCDecoderConfigurationRecord$2(arrayBuffer) {
    let info = {};
    let profile_idc, width, height, crop_left, crop_right,
        crop_top, crop_bottom, frame_mbs_only, n, cf_idc,
        num_ref_frames;
    let bitop = new Bitop(arrayBuffer);
    bitop.read(48);
    info.width = 0;
    info.height = 0;
    do {
        info.profile = bitop.read(8);
        info.compat = bitop.read(8);
        info.level = bitop.read(8);
        info.nalu = (bitop.read(8) & 0x03) + 1;
        info.nb_sps = bitop.read(8) & 0x1F;
        if (info.nb_sps == 0) {
            break;
        }
        /* nal size */
        bitop.read(16);

        /* nal type */
        if (bitop.read(8) != 0x67) {
            break;
        }
        /* SPS */
        profile_idc = bitop.read(8);

        /* flags */
        bitop.read(8);

        /* level idc */
        bitop.read(8);

        /* SPS id */
        bitop.read_golomb();

        if (profile_idc == 100 || profile_idc == 110 ||
            profile_idc == 122 || profile_idc == 244 || profile_idc == 44 ||
            profile_idc == 83 || profile_idc == 86 || profile_idc == 118) {
            /* chroma format idc */
            cf_idc = bitop.read_golomb();

            if (cf_idc == 3) {

                /* separate color plane */
                bitop.read(1);
            }

            /* bit depth luma - 8 */
            bitop.read_golomb();

            /* bit depth chroma - 8 */
            bitop.read_golomb();

            /* qpprime y zero transform bypass */
            bitop.read(1);

            /* seq scaling matrix present */
            if (bitop.read(1)) {

                for (n = 0; n < (cf_idc != 3 ? 8 : 12); n++) {

                    /* seq scaling list present */
                    if (bitop.read(1)) {

                        /* TODO: scaling_list()
                        if (n < 6) {
                        } else {
                        }
                        */
                    }
                }
            }
        }

        /* log2 max frame num */
        bitop.read_golomb();

        /* pic order cnt type */
        switch (bitop.read_golomb()) {
            case 0:

                /* max pic order cnt */
                bitop.read_golomb();
                break;

            case 1:

                /* delta pic order alwys zero */
                bitop.read(1);

                /* offset for non-ref pic */
                bitop.read_golomb();

                /* offset for top to bottom field */
                bitop.read_golomb();

                /* num ref frames in pic order */
                num_ref_frames = bitop.read_golomb();

                for (n = 0; n < num_ref_frames; n++) {

                    /* offset for ref frame */
                    bitop.read_golomb();
                }
        }

        /* num ref frames */
        info.avc_ref_frames = bitop.read_golomb();

        /* gaps in frame num allowed */
        bitop.read(1);

        /* pic width in mbs - 1 */
        width = bitop.read_golomb();

        /* pic height in map units - 1 */
        height = bitop.read_golomb();

        /* frame mbs only flag */
        frame_mbs_only = bitop.read(1);

        if (!frame_mbs_only) {

            /* mbs adaprive frame field */
            bitop.read(1);
        }

        /* direct 8x8 inference flag */
        bitop.read(1);

        /* frame cropping */
        if (bitop.read(1)) {

            crop_left = bitop.read_golomb();
            crop_right = bitop.read_golomb();
            crop_top = bitop.read_golomb();
            crop_bottom = bitop.read_golomb();

        } else {
            crop_left = 0;
            crop_right = 0;
            crop_top = 0;
            crop_bottom = 0;
        }
        info.level = info.level / 10.0;
        info.width = (width + 1) * 16 - (crop_left + crop_right) * 2;
        info.height = (2 - frame_mbs_only) * (height + 1) * 16 - (crop_top + crop_bottom) * 2;

    } while (0);

    info.codecWidth = info.width;
    info.codecHeight = info.height;
    info.videoType = VIDEO_ENCODE_TYPE.h264
    return info;
}


function removeEPB (uint) {
    const length = uint.byteLength
    const emulationPreventionBytesPositions = []
    let i = 1

    while (i < length - 2) {
        if (uint[i] === 0 && uint[i + 1] === 0 && uint[i + 2] === 0x03) {
            emulationPreventionBytesPositions.push(i + 2)
            i += 2
        } else {
            i++
        }
    }

    if (!emulationPreventionBytesPositions.length) return uint

    const newLength = length - emulationPreventionBytesPositions.length
    const newData = new Uint8Array(newLength)

    let sourceIndex = 0
    for (i = 0; i < newLength; sourceIndex++, i++) {
        if (sourceIndex === emulationPreventionBytesPositions[0]) {
            sourceIndex++
            emulationPreventionBytesPositions.shift()
        }
        newData[i] = uint[sourceIndex]
    }

    return newData
}


function getAvcCodec (codecs) {
    let codec = 'avc1.'
    let h
    for (let i = 0; i < 3; i++) {
        h = codecs[i].toString(16)
        if (h.length < 2) h = `0${h}`
        codec += h
    }
    return codec
}

function parseSPS(unit){
    const eg = new ExpGolombV2(unit)
    eg.readUByte()

    const profileIdc = eg.readUByte()
    const profileCompatibility = eg.readUByte()
    const levelIdc = eg.readUByte()
    eg.skipUEG()

    let chromaFormat = 420
    if (
        profileIdc === 100 ||
        profileIdc === 110 ||
        profileIdc === 122 ||
        profileIdc === 244 ||
        profileIdc === 44 ||
        profileIdc === 83 ||
        profileIdc === 86 ||
        profileIdc === 118 ||
        profileIdc === 128 ||
        profileIdc === 138 ||
        profileIdc === 144
    ) {
        const chromaFormatIdc = eg.readUEG()
        if (chromaFormatIdc <= 3) chromaFormat = [0, 420, 422, 444][chromaFormatIdc]
        if (chromaFormatIdc === 3) eg.skipBits(1)
        eg.skipUEG()
        eg.skipUEG()
        eg.skipBits(1)
        if (eg.readBool()) {
            const scalingListCount = chromaFormatIdc !== 3 ? 8 : 12
            for (let i = 0; i < scalingListCount; i++) {
                if (eg.readBool()) {
                    if (i < 6) {
                        eg.skipScalingList(16)
                    } else {
                        eg.skipScalingList(64)
                    }
                }
            }
        }
    }

    eg.skipUEG()
    const picOrderCntType = eg.readUEG()
    if (picOrderCntType === 0) {
        eg.readUEG()
    } else if (picOrderCntType === 1) {
        eg.skipBits(1)
        eg.skipUEG()
        eg.skipUEG()
        const numRefFramesInPicOrderCntCycle = eg.readUEG()
        for (let i = 0; i < numRefFramesInPicOrderCntCycle; i++) {
            eg.skipUEG()
        }
    }

    eg.skipUEG()
    eg.skipBits(1)
    const picWidthInMbsMinus1 = eg.readUEG()
    const picHeightInMapUnitsMinus1 = eg.readUEG()
    const frameMbsOnlyFlag = eg.readBits(1)
    if (frameMbsOnlyFlag === 0) eg.skipBits(1)
    eg.skipBits(1)

    let frameCropLeftOffset = 0
    let frameCropRightOffset = 0
    let frameCropTopOffset = 0
    let frameCropBottomOffset = 0

    if (eg.readBool()) {
        frameCropLeftOffset = eg.readUEG()
        frameCropRightOffset = eg.readUEG()
        frameCropTopOffset = eg.readUEG()
        frameCropBottomOffset = eg.readUEG()
    }

    let sarRatio
    let fixedFrame
    let fpsNum
    let fpsDen
    let fps
    if (eg.readBool()) {
        if (eg.readBool()) {
            const aspectRatioIdc = eg.readUByte()
            switch (aspectRatioIdc) {
                case 1: sarRatio = [1, 1]; break
                case 2: sarRatio = [12, 11]; break
                case 3: sarRatio = [10, 11]; break
                case 4: sarRatio = [16, 11]; break
                case 5: sarRatio = [40, 33]; break
                case 6: sarRatio = [24, 11]; break
                case 7: sarRatio = [20, 11]; break
                case 8: sarRatio = [32, 11]; break
                case 9: sarRatio = [80, 33]; break
                case 10: sarRatio = [18, 11]; break
                case 11: sarRatio = [15, 11]; break
                case 12: sarRatio = [64, 33]; break
                case 13: sarRatio = [160, 99]; break
                case 14: sarRatio = [4, 3]; break
                case 15: sarRatio = [3, 2]; break
                case 16: sarRatio = [2, 1]; break
                case 255: {
                    sarRatio = [
                        (eg.readUByte() << 8) | eg.readUByte(),
                        (eg.readUByte() << 8) | eg.readUByte()
                    ]
                    break
                }
                default:
            }
        }

        if (eg.readBool()) eg.readBool()

        if (eg.readBool()) {
            eg.readBits(4)
            if (eg.readBool()) eg.readBits(24)
        }

        if (eg.readBool()) {
            eg.readUEG()
            eg.readUEG()
        }

        if (eg.readBool()) {
            const numUnitsInTick = eg.readBits(32)
            const timeScale = eg.readBits(32)
            fixedFrame = eg.readBool()

            fpsNum = timeScale
            fpsDen = numUnitsInTick * 2
            fps = fpsNum / fpsDen
        }
    }

    return {
        codec: getAvcCodec(unit.subarray(1, 4)),
        profileIdc,
        profileCompatibility,
        levelIdc,
        chromaFormat,
        width: Math.ceil(
            (picWidthInMbsMinus1 + 1) * 16 -
            2 * (frameCropLeftOffset + frameCropRightOffset)
        ),
        height:
            (2 - frameMbsOnlyFlag) * (picHeightInMapUnitsMinus1 + 1) * 16 -
            (frameMbsOnlyFlag ? 2 : 4) *
            (frameCropTopOffset + frameCropBottomOffset),
        sarRatio,
        fpsNum,
        fpsDen,
        fps,
        fixedFrame
    }
}


export function parseAVCDecoderConfigurationRecord$3(arrayBuffer) {
    if (arrayBuffer.length < 7) {
        return
    }

    const nalUnitSize = (arrayBuffer[4] & 3) + 1

    let spsParsed
    const spsArr = []
    const ppsArr = []

    let offset = 6
    const spsCount = arrayBuffer[5] & 0x1f
    let spsSize
    for (let i = 0; i < spsCount; i++) {
        spsSize = (arrayBuffer[offset] << 8) | arrayBuffer[offset + 1]
        offset += 2
        if (!spsSize) continue

        const sps = arrayBuffer.subarray(offset, offset + spsSize)
        offset += spsSize
        spsArr.push(sps)

        if (!spsParsed) {
            spsParsed = parseSPS(removeEPB(sps))
        }
    }

    const ppsCount = arrayBuffer[offset]
    offset++
    let ppsSize
    for (let i = 0; i < ppsCount; i++) {
        ppsSize = (arrayBuffer[offset] << 8) | arrayBuffer[offset + 1]
        offset += 2
        if (!ppsSize) continue
        ppsArr.push(arrayBuffer.subarray(offset, offset + ppsSize))
        offset += ppsSize
    }

    return {
        sps: spsParsed,
        spsArr,
        ppsArr,
        nalUnitSize
    }

}

export function parseAVCDecoderSPSAndPPS(arrayBuffer) {
    const v = new DataView(arrayBuffer.buffer);
    let version = v.getUint8(0);  // configurationVersion
    let avcProfile = v.getUint8(1);  // avcProfileIndication
    let profileCompatibility = v.getUint8(2);  // profile_compatibil
    let avcLevel = v.getUint8(3);  // AVCLevelIndication
    let spsArray;
    let spsLength = 0;
    let ppsArray;
    let ppsLength = 0;
    if (version !== 1 || avcProfile === 0) {
        return;
    }
    const _naluLengthSize = (v.getUint8(4) & 3) + 1;  // lengthSizeMinusOne

    if (_naluLengthSize !== 3 && _naluLengthSize !== 4) {  // holy shit!!!
        // this._onError(DemuxErrors.FORMAT_ERROR, `Flv: Strange NaluLengthSizeMinusOne: ${_naluLengthSize - 1}`);
        return;
    }
    let spsCount = v.getUint8(5) & 31;  // numOfSequenceParameterSets

    if (spsCount === 0) {
        return;
    } else if (spsCount > 1) {
        return;
    }

    let offset = 6;
    for (let i = 0; i < spsCount; i++) {
        spsLength = v.getUint16(offset, false);  // sequenceParameterSetLength
        offset += 2;

        if (spsLength === 0) {
            continue;
        }

        spsArray = new Uint8Array(arrayBuffer.buffer, offset, spsLength);
        offset += spsLength;
    }

    let ppsCount = v.getUint8(offset);  // numOfPictureParameterSets
    if (ppsCount === 0) {
        return;
    } else if (ppsCount > 1) {
        return;
    }

    offset++;
    for (let i = 0; i < ppsCount; i++) {
        ppsLength = v.getUint16(offset, false);  // pictureParameterSetLength
        offset += 2;

        if (ppsLength === 0) {
            continue;
        }
        ppsArray = new Uint8Array(arrayBuffer.buffer, offset, ppsLength);

        // pps is useless for extracting video information
        offset += ppsLength;
    }

    if (spsLength === 0 || ppsLength === 0) {
        return;
    }

    const spsFlag = new Uint8Array([
        (spsLength >>> 24) & 0xFF,
        (spsLength >>> 16) & 0xFF,
        (spsLength >>> 8) & 0xFF,
        (spsLength) & 0xFF
    ])
    const ppsFlag = new Uint8Array([
        (ppsLength >>> 24) & 0xFF,
        (ppsLength >>> 16) & 0xFF,
        (ppsLength >>> 8) & 0xFF,
        (ppsLength) & 0xFF
    ]);

    const sps = new Uint8Array(spsLength + 4);
    sps.set(spsFlag, 0);
    sps.set(spsArray, 4);
    const pps = new Uint8Array(ppsLength + 4);
    pps.set(ppsFlag, 0);
    pps.set(ppsArray, 4);

    return {
        sps,
        pps
    }
}


/**
 *
 * @param sps
 * @param pps
 * @returns {Uint8Array}
 */
export function avcEncoderConfigurationRecord({sps, pps}) {
//     todo: 1 是 序列帧 2-4cts帧
    // 从 0x01 开始 表示version。
    // RTMP_AVC_HEAD
    // 0x17 keyframe  7:AVC
    // 0x00 AVC sequence header
    // 0x00 0x00 0x00
    // 0x01 configurationVersion
    // 0x42 AVCProfileIndication
    // 0x00 profile_compatibility
    // 0x1E AVCLevelIndication
    // 0xFF lengthSizeMinusOne
    const tmp = [
        0x17, 0x00, 0x00, 0x00, 0x00,
        0x01,
        0x42,
        0x00,
        0x1E,
        0xFF];
    // 是个是私有协议标识，h264的。
    // 0x17 :23
    tmp[0] = 0x17

    // 标准的 nale 格式。。。
    tmp[6] = sps[1];// 0x42 avc profile ( sps[0][1] )
    tmp[7] = sps[2];// 0x00 avc compatibility ( sps[0][2] )
    tmp[8] = sps[3];// 0x1E avc level ( sps[0][3] )
    //tmp[9]  0xFF  6   reserved ( all bits on )
    // temp 的length 是10  最后的下标就是 9

    // 0xE1 : 225
    tmp[10] = 0xE1; //
    // number of SPS NALUs (usually 1)  repeated once per SPS:
    //
    tmp[11] = (sps.byteLength >> 8) & 0xff; //
    // SPS size
    tmp[12] = sps.byteLength & 0xff; //
    // variable   SPS NALU data
    // number of PPS NALUs (usually 1)  　repeated once per PPS
    // PPS size
    // variable PPS NALU data
    tmp.push(...sps, 0x01, (pps.byteLength >> 8) & 0xff, pps.byteLength & 0xff, ...pps);
    //

    const arrayBuffer = new Uint8Array(tmp)
    return arrayBuffer;
}


export function avcEncoderConfigurationRecord$2({sps, pps}) {
    // require Nalu without 4 byte length-header
    let length = 6 + 2 + sps.byteLength + 1 + 2 + pps.byteLength;
    let need_extra_fields = false;
    const sps_details = SPSParser.parseSPS$2(sps);

    if (sps[3] !== 66 && sps[3] !== 77 && sps[3] !== 88) {
        need_extra_fields = true;
        length += 4;
    }
    let data = new Uint8Array(length);
    data[0] = 0x01;    // configurationVersion
    data[1] = sps[1];  // AVCProfileIndication
    data[2] = sps[2];  // profile_compatibility
    data[3] = sps[3];  // AVCLevelIndication
    data[4] = 0xFF;    // 111111 + lengthSizeMinusOne(3)

    data[5] = 0xE0 | 0x01  // 111 + numOfSequenceParameterSets
    let sps_length = sps.byteLength;
    data[6] = sps_length >>> 8;  // sequenceParameterSetLength
    data[7] = sps_length & 0xFF;

    let offset = 8;
    data.set(sps, 8);
    offset += sps_length;

    data[offset] = 1;  // numOfPictureParameterSets

    let pps_length = pps.byteLength;
    data[offset + 1] = pps_length >>> 8;  // pictureParameterSetLength
    data[offset + 2] = pps_length & 0xFF;

    data.set(pps, offset + 3);
    offset += 3 + pps_length;

    if (need_extra_fields) {
        data[offset] = 0xFC | sps_details.chroma_format_idc;
        data[offset + 1] = 0xF8 | (sps_details.bit_depth_luma - 8);
        data[offset + 2] = 0xF8 | (sps_details.bit_depth_chroma - 8);
        data[offset + 3] = 0x00;  // number of sps ext
        offset += 4;
    }
    const prevData = [0x17, 0x00, 0x00, 0x00, 0x00];
    const newData = new Uint8Array(prevData.length + data.byteLength);
    newData.set(prevData, 0);
    newData.set(data, prevData.length);
    return newData;
}

/**
 *
 * @param oneNALBuffer
 * @param isIframe
 * @returns {Uint8Array}
 */
export function avcEncoderNalePacket(oneNALBuffer, isIframe) {
    //     正常发送nal
    const idrBit = 0x10 | 7
    const nIdrBit = 0x20 | 7
    let tmp = [];
    if (isIframe) {
        tmp[0] = idrBit;
    } else {
        tmp[0] = nIdrBit;
    }
    // compositionTime
    tmp[1] = 1;
    tmp[2] = 0;
    tmp[3] = 0;
    tmp[4] = 0;

    //
    tmp[5] = (oneNALBuffer.byteLength >> 24) & 0xff;
    tmp[6] = (oneNALBuffer.byteLength >> 16) & 0xff;
    tmp[7] = (oneNALBuffer.byteLength >> 8) & 0xff;
    tmp[8] = (oneNALBuffer.byteLength) & 0xff;
    const arrayBuffer = new Uint8Array(tmp.length + oneNALBuffer.byteLength);
    arrayBuffer.set(tmp, 0);
    arrayBuffer.set(oneNALBuffer, tmp.length);
    return arrayBuffer;
}


export function avcEncoderNalePacketNotLength(oneNALBuffer, isIframe) {
    //     正常发送nal
    const idrBit = 0x10 | 7
    const nIdrBit = 0x20 | 7
    let tmp = [];
    if (isIframe) {
        tmp[0] = idrBit;
    } else {
        tmp[0] = nIdrBit;
    }
    // compositionTime
    tmp[1] = 1;
    tmp[2] = 0;
    tmp[3] = 0;
    tmp[4] = 0;

    const arrayBuffer = new Uint8Array(tmp.length + oneNALBuffer.byteLength);
    arrayBuffer.set(tmp, 0);
    arrayBuffer.set(oneNALBuffer, tmp.length);
    return arrayBuffer;
}


/**
 * (NALU类型  & 0001  1111)
 * @param nalu
 * @returns {number}
 */
export function getAvcSeqHeadType(nalu) {
    return nalu[0] & 0b0001_1111
}


export function isAvcSeqHead(type) {
    return type === H264_NAL_TYPE.sps || type === H264_NAL_TYPE.pps;
}

export function isHvcSEIType(type) {
    return type === H264_NAL_TYPE.kSliceSEI;
}

export function isNotAvcSeqHead(type) {
    return !isAvcSeqHead(type) && !isHvcSEIType(type);
}

export function isAvcNaluIFrame(type) {
    return type === H264_NAL_TYPE.iFrame
}


export function isSameAvcNaluType(naluList) {
    if (naluList.length === 0) {
        return false;
    }
    const type = getAvcSeqHeadType(naluList[0]);
    for (let i = 1; i < naluList.length; i++) {
        if (type !== getAvcSeqHeadType(naluList[i])) {
            return false;
        }
    }
    return true;
}
