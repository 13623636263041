import FetchLoader from "./fetchLoader";
import FetchWorkerLoader from "./fetchWorkerLoader";
import WebsocketLoader from "./websocketLoader";
import HlsLoader from './hlsLoader';
import {PLAY_TYPE, PLAYER_PLAY_PROTOCOL} from "../constant";
import WebrtcLoader from "./webrtcLoader";
import WebTransportLoader from "./webTransportLoader";
import WorkerLoader from "./workerLoader";
import AliyunRtcLoader from "./aliyunRtcLoader";
import {onlyMseOrWcsVideo} from "../utils";

export default class Stream {
    constructor(player) {
        const Loader = Stream.getLoaderFactory(player._opt);
        return new Loader(player);
    }

    static getLoaderFactory(opt) {
        const {protocol, useWasm, playType, useWCS, useMSE, demuxUseWorker} = opt;

        if (protocol === PLAYER_PLAY_PROTOCOL.fetch) {
            if (playType === PLAY_TYPE.playerAudio) {
                return WorkerLoader;
            } else if (playType === PLAY_TYPE.player) {
                //  wasm
                if (useWasm && !onlyMseOrWcsVideo(opt)) {
                    return WorkerLoader;
                } else {
                    if (demuxUseWorker) {
                        return WorkerLoader;
                    } else {
                        // return FetchLoader;
                        return FetchWorkerLoader;
                    }
                }
            } else {
                //  playback
                if (useWCS || useMSE) {
                    // return FetchLoader;
                    if (demuxUseWorker) {
                        return WorkerLoader;
                    } else {
                        return FetchWorkerLoader;
                    }
                } else {
                    return WorkerLoader;
                }
            }
        } else if (protocol === PLAYER_PLAY_PROTOCOL.websocket) {
            if (playType === PLAY_TYPE.playerAudio) {
                return WorkerLoader;
            } else if (playType === PLAY_TYPE.player) {
                if (useWasm && !onlyMseOrWcsVideo(opt)) {
                    return WorkerLoader;
                } else {
                    if (demuxUseWorker) {
                        return WorkerLoader;
                    } else {
                        return WebsocketLoader;
                    }
                }
            } else {
                //  playback
                if (useWCS || useMSE) {
                    if (demuxUseWorker) {
                        return WorkerLoader;
                    } else {
                        return WebsocketLoader;
                    }
                } else {
                    return WorkerLoader;
                }
            }
        } else if (protocol === PLAYER_PLAY_PROTOCOL.hls) {
            return HlsLoader;
        } else if (protocol === PLAYER_PLAY_PROTOCOL.webrtc) {
            return WebrtcLoader;
        } else if (protocol === PLAYER_PLAY_PROTOCOL.webTransport) {
            return WebTransportLoader;
        }
        else if(protocol === PLAYER_PLAY_PROTOCOL.aliyunRtc){
            return AliyunRtcLoader;
        }
    }
}
