import CommonLoader from "./commonLoader";
import {checkAudioNaluType, checkNaluType, now} from "../utils";
import {AUDIO_ENC_CODE_SHOW, H264_NAL_TYPE, H265_NAL_TYPE, MEDIA_TYPE, VIDEO_ENC_TYPE_SHOW} from "../constant";
import {
    avcEncoderConfigurationRecord$2,
    avcEncoderNalePacket,
    avcEncoderNalePacketNotLength,
    getAvcSeqHeadType,
    isAvcNaluIFrame,
    isHvcSEIType,
    isNotAvcSeqHead,
    isSameAvcNaluType,
} from "../utils/h264";
import {
    getHevcSeqHeadType,
    hevcEncoderConfigurationRecord$2,
    hevcEncoderNalePacket, hevcEncoderNalePacketNotLength,
    isHevcNaluIFrame,
    isHevcSEIType,
    isNotHevcSeqHead, isSameHevcNaluType
} from "../utils/h265";
import {addNaleHeaderLength} from "../utils/nalu";

export default class NakedFlowLoader extends CommonLoader {
    TAG_NAME = 'NakedFlowDemux';

    constructor(player) {
        super(player);
        this.lastBuf = null;
        this.vps = null;
        this.sps = null;
        this.pps = null;
        this.streamVideoType = null;
        this.streamAudioType = null;
        this.tempNaluBufferList = new Uint8Array(0);
        this.localDts = 0;
        this.isSendSeqHeader = false;
        this.isSendAACSeqHeader = false;
        player.debug.log(this.TAG_NAME, 'init')

    }

    destroy() {
        super.destroy();
        this.lastBuf = null;
        this.vps = null;
        this.sps = null;
        this.pps = null;
        this.streamVideoType = null;
        this.streamAudioType = null;
        this.tempNaluBufferList = new Uint8Array(0);
        this.localDts = 0;
        this.localAudioDts = 0;
        this.isSendSeqHeader = false;
        this.isSendAACSeqHeader = false;
        this.player.debug.log(this.TAG_NAME, 'destroy')
    }

    //
    dispatch(data) {
        const player = this.player;
        const uint8Array = new Uint8Array(data);
        // player.debug.log(this.TAG_NAME, 'dispatch', uint8Array.byteLength);
        // const naluArray = this.extractNALu(uint8Array);
        // console.log('naluArray', naluArray);
        this.extractNALu$2(uint8Array);
        // this.addNaluToBuffer(uint8Array);
        // this.handleNALu(uint8Array);
    }

    addNaluToBuffer(nalu) {
        const len = nalu.byteLength + this.tempNaluBufferList.byteLength;
        const newBuffer = new Uint8Array(len);
        newBuffer.set(this.tempNaluBufferList, 0);
        newBuffer.set(nalu, this.tempNaluBufferList.byteLength);
        this.tempNaluBufferList = newBuffer;
        //console.log('addNaluToBuffer byteLength is', this.tempNaluBufferList.byteLength);
    }

    downloadNakedFlowFile() {
        const blob = new Blob([this.tempNaluBufferList]);
        try {
            const oa = document.createElement('a');
            oa.href = window.URL.createObjectURL(blob);
            oa.download = Date.now() + '.h264';
            oa.click();
            window.URL.revokeObjectURL(oa.href);
        } catch (e) {
            console.error('downloadTempNalu', e);
        }
    }

    //
    getNaluDts() {
        const nakedFlowFps = this.player._opt.nakedFlowFps;
        // console.log(`nakedFlowFps is ${nakedFlowFps} and dts is ${(1000 / nakedFlowFps)}`);
        this.localDts = this.localDts + parseInt(1000 / nakedFlowFps);
        return this.localDts;
    }

    getNaluAudioDts() {
        const audioContextSampleRate = this.player.audio.audioContext.sampleRate;
        const audioBufferSize = this.player.audio.audioBufferSize;
        //  dts
        return this.localDts + parseInt((audioBufferSize / audioContextSampleRate) * 1000);
    }

    //
    extractNALu(buffer) {
        let i = 0,
            length = buffer.byteLength,
            value,
            state = 0,
            result = [],
            lastIndex;
        let lastUnitType, unitType;
        while (i < length) {
            value = buffer[i++];
            // Annex-B格式使用start code进行分割，start code为0x000001或0x00000001，
            // SPS/PPS作为一般NALU单元以start code作为分隔符的方式放在文件或者直播流的头部。
            // finding 3 or 4-byte start codes (00 00 01 OR 00 00 00 01)
            switch (state) {
                case 0:
                    if (value === 0) {
                        state = 1;
                    }
                    break;
                case 1:
                    if (value === 0) {
                        state = 2;
                    } else {
                        state = 0;
                    }
                    break;
                case 2:
                case 3:
                    if (value === 0) {
                        state = 3;
                    } else if (value === 1 && i < length) {
                        unitType = buffer[i] & 0x1f;
                        if (lastIndex) {
                            result.push(buffer.subarray(lastIndex, i - state - 1));
                            // console.error('lastUnitType', lastUnitType);
                        }
                        lastIndex = i;
                        lastUnitType = unitType;
                        state = 0;
                    } else {
                        state = 0;
                    }
                    break;
                default:
                    break;
            }
        }

        if (lastIndex) {
            result.push(buffer.subarray(lastIndex, length));
            // console.error('lastUnitType', lastUnitType);
        }
        return result;
    }


    extractNALu$2(buffer) {
        let typedArray = null;
        if (!buffer || buffer.byteLength < 1) return;
        if (this.lastBuf) {
            typedArray = new Uint8Array(buffer.byteLength + this.lastBuf.length);
            typedArray.set(this.lastBuf);
            typedArray.set(new Uint8Array(buffer), this.lastBuf.length);
        } else {
            typedArray = new Uint8Array(buffer);
        }
        let lastNalEndPos = 0;
        let b1 = -1; // byte before one
        let b2 = -2; // byte before two
        const nalStartPos = new Array();
        for (let i = 0; i < typedArray.length; i += 2) {
            const b_0 = typedArray[i];
            const b_1 = typedArray[i + 1];
            if (b1 == 0 && b_0 == 0 && b_1 == 0) {
                nalStartPos.push(i - 1);
            } else if (b_1 == 1 && b_0 == 0 && b1 == 0 && b2 == 0) {
                nalStartPos.push(i - 2);
            }
            b2 = b_0;
            b1 = b_1;
        }
        if (nalStartPos.length > 1) {
            for (let i = 0; i < nalStartPos.length - 1; ++i) {
                const naluItem = typedArray.subarray(nalStartPos[i], nalStartPos[i + 1] + 1);
                this.handleNALu(naluItem);
                //console.log('nakedFlowDemuxer.lastBuf nalType', this.lastBuf.byteLength);
                lastNalEndPos = nalStartPos[i + 1];
            }
        } else {
            lastNalEndPos = nalStartPos[0];
        }
        if (lastNalEndPos != 0 && lastNalEndPos < typedArray.length) {
            this.lastBuf = typedArray.subarray(lastNalEndPos);
        } else {
            if (!!!this.lastBuf) {
                this.lastBuf = typedArray;
            }
            const _newBuf = new Uint8Array(this.lastBuf.length + buffer.byteLength);
            _newBuf.set(this.lastBuf);
            _newBuf.set(new Uint8Array(buffer), this.lastBuf.length);
            this.lastBuf = _newBuf;
        }
    }

    handleNALu(nalu) {
        if (nalu.byteLength < 4) {
            this.player.debug.warn(this.TAG_NAME, `handleNALu nalu byteLength is ${nalu.byteLength} <= 4`);
            return;
        }
        // 0001 去掉前4个字节（start code）
        nalu = nalu.slice(4);
        this.handleVideoNalu(nalu);
    }


    handleVideoNalu(nalu) {
        const uint8Array = new Uint8Array(nalu);
        //  todo：这边首帧必须是sps/pps/vps 不能是P帧或者I帧,这个方法会检测失败的。
        if (!this.streamVideoType) {
            this.streamVideoType = checkNaluType(uint8Array);
        }

        if (this.streamVideoType === VIDEO_ENC_TYPE_SHOW.h264) {
            const tempNalu = this.handleAddNaluStartCode(uint8Array);
            const naluList = this.extractNALu(tempNalu);

            if (naluList.length === 0) {
                this.player.debug.warn(this.TAG_NAME, 'handleVideoNalu', 'naluList.length === 0');
                return;
            }

            const newNaluList = [];
            naluList.forEach((naluItem) => {
                const nalType = getAvcSeqHeadType(naluItem);
                if (nalType === H264_NAL_TYPE.pps ||
                    nalType === H264_NAL_TYPE.sps) {
                    this.handleVideoH264Nalu(naluItem);
                } else {
                    if (isNotAvcSeqHead(nalType)) {
                        newNaluList.push(naluItem);
                    }
                }
            })
            if (newNaluList.length === 1) {
                this.handleVideoH264Nalu(newNaluList[0]);
            } else {
                const isSameNaluType = isSameAvcNaluType(newNaluList);
                if (isSameNaluType) {
                    const naluType = getAvcSeqHeadType(newNaluList[0]);
                    const isIFrame = isAvcNaluIFrame(naluType);
                    this.handleVideoH264NaluList(newNaluList, isIFrame, naluType);
                } else {
                    newNaluList.forEach((naluItem) => {
                        this.handleVideoH264Nalu(naluItem);
                    })
                }
            }
        } else if (this.streamVideoType === VIDEO_ENC_TYPE_SHOW.h265) {
            if (this.player._opt.nakedFlowH265DemuxUseNew) {
                const tempNalu = this.handleAddNaluStartCode(uint8Array);
                const naluList = this.extractNALu(tempNalu);
                if (naluList.length === 0) {
                    this.player.debug.warn(this.TAG_NAME, 'handleVideoNalu', 'h265 naluList.length === 0');
                    return;
                }
                const newNaluList = [];
                naluList.forEach((naluItem) => {
                    const nalType = getHevcSeqHeadType(naluItem);
                    if (nalType === H265_NAL_TYPE.pps ||
                        nalType === H265_NAL_TYPE.sps ||
                        nalType === H265_NAL_TYPE.vps) {
                        this.handleVideoH265Nalu(naluItem);
                    } else {
                        if (isNotHevcSeqHead(nalType)) {
                            newNaluList.push(naluItem);
                        }
                    }
                })
                if (newNaluList.length === 1) {
                    this.handleVideoH265Nalu(newNaluList[0]);
                } else {
                    const isSameNaluType = isSameHevcNaluType(newNaluList);
                    if (isSameNaluType) {
                        const naluType = getHevcSeqHeadType(newNaluList[0]);
                        const isIFrame = isHevcNaluIFrame(naluType);
                        this.handleVideoH265NaluList(newNaluList, isIFrame, naluType);
                    } else {
                        newNaluList.forEach((naluItem) => {
                            this.handleVideoH265Nalu(naluItem);
                        })
                    }
                }
            } else {
                const naluType = getHevcSeqHeadType(uint8Array);
                if (naluType === H265_NAL_TYPE.pps) {
                    this.extractH265PPS(uint8Array);
                } else {
                    this.handleVideoH265Nalu(uint8Array);
                }
            }
        } else {
            this.player.debug.error(this.TAG_NAME, ` this.streamVideoType is null`)
        }
    }

    extractH264PPS(nalu) {
        const tempNalu = this.handleAddNaluStartCode(nalu);
        const naluList = this.extractNALu(tempNalu);
        naluList.forEach((naluItem) => {
            const nalType = getAvcSeqHeadType(naluItem);
            if (isHvcSEIType(nalType)) {
                this.extractH264SEI(naluItem);
            } else {
                this.handleVideoH264Nalu(naluItem);
            }
        })
    }

    extractH265PPS(nalu) {
        const tempNalu = this.handleAddNaluStartCode(nalu);
        const naluList = this.extractNALu(tempNalu);
        naluList.forEach((naluItem) => {
            const nalType = getHevcSeqHeadType(naluItem);
            if (isHevcSEIType(nalType)) {
                this.extractH265SEI(naluItem);
            } else {
                this.handleVideoH265Nalu(naluItem);
            }

        })
    }

    extractH264SEI(nalu) {
        const tempNalu = this.handleAddNaluStartCode(nalu);
        const naluList = this.extractNALu(tempNalu);
        naluList.forEach((naluItem) => {
            this.handleVideoH264Nalu(naluItem);
        })
    }

    extractH265SEI(nalu) {
        const tempNalu = this.handleAddNaluStartCode(nalu);
        const naluList = this.extractNALu(tempNalu);
        //console.log('extractH265SEI', naluList);
        naluList.forEach((naluItem) => {
            this.handleVideoH265Nalu(naluItem);
        })
    }


    handleAddNaluStartCode(nalu) {
        const prefix = [0, 0, 0, 1];
        const newNalu = new Uint8Array(nalu.length + prefix.length);
        newNalu.set(prefix);
        newNalu.set(nalu, prefix.length);
        return newNalu;
    }

    handleAudioAACNalu(nalu) {
        if (!nalu || nalu.byteLength < 1) return;

        if (!this.streamAudioType) {
            this.streamAudioType = AUDIO_ENC_CODE_SHOW.AAC;
        }
        let uint8Array = new Uint8Array(nalu);

        // 需要取出7个字节的ADTS头。
        const accADTSHeader = uint8Array.slice(0, 7);
        // 移除掉 ADTS头
        uint8Array = uint8Array.slice(7);
        if (!this.isSendAACSeqHeader) {
            // 七个字节的adts头；
            //     aac 格式
            // 需要先发序列帧。然后再发后续的
            // 序列帧：asc 序列
            // 后续的帧，0 变成 1 就行了。
            // 0: Main profile
            // 1: Low Complexity profile(LC)
            // 2: Scalable Sampling Rate profile(SSR)
            const profile = ((accADTSHeader[2] & 0xc0) >> 6);
            // const profile = 0;
            // const sampleRate = (+audioInfo.sampleRate);
            const sampleRate = ((accADTSHeader[2] & 0x3C) >> 2)
            // const channel = (+audioInfo.channel);
            const channel = ((accADTSHeader[2] & 0x1) << 2) | ((accADTSHeader[3] & 0xc0) >> 6)
            const config1 = (profile << 3) | ((sampleRate & 0xe) >> 1)
            const config2 = ((sampleRate & 0x1) << 7) | (channel << 3)
            // 0xAF >> 4 === 10
            const temp = [0xAF, 0x00, config1, config2];
            const arrayBuffer = new Uint8Array(temp);
            this.isSendAACSeqHeader = true;
            this._doDecode(arrayBuffer, MEDIA_TYPE.audio, 0, false, 0);

        }
        const dts = this.getNaluAudioDts();
        const arrayBuffer = new Uint8Array(uint8Array.length + 2);
        arrayBuffer.set([0xAF, 0x01], 0);
        arrayBuffer.set(uint8Array, 2);

        this._doDecode(arrayBuffer, MEDIA_TYPE.audio, dts, false, 0);
    }


    handleAudioG711ANalu(nalu) {
        if (!nalu || nalu.byteLength < 1) return;

        if (!this.streamAudioType) {
            this.streamAudioType = AUDIO_ENC_CODE_SHOW.ALAW;
        }
        let uint8Array = new Uint8Array(nalu);
        const dts = this.getNaluAudioDts();
        const arrayBuffer = new Uint8Array(uint8Array.length + 1);
        arrayBuffer.set([(7 << 4) | (1 << 1)], 0);
        arrayBuffer.set(uint8Array, 1);
        this._doDecode(arrayBuffer, MEDIA_TYPE.audio, dts, false, 0);
    }

    handleAudioG711UNalu(nalu) {
        if (!nalu || nalu.byteLength < 1) return;

        if (!this.streamAudioType) {
            this.streamAudioType = AUDIO_ENC_CODE_SHOW.MULAW;
        }
        let uint8Array = new Uint8Array(nalu);

        const dts = this.getNaluAudioDts();
        const arrayBuffer = new Uint8Array(uint8Array.length + 1);
        arrayBuffer.set([(8 << 4) | (1 << 1)], 0);
        arrayBuffer.set(uint8Array, 1);
        this._doDecode(arrayBuffer, MEDIA_TYPE.audio, dts, false, 0);
    }

    handleVideoH264Nalu(nalu) {
        const nalType = getAvcSeqHeadType(nalu);
        // this.player.debug.log(this.TAG_NAME, `handleVideoH264Nalu anlType is ${nalType}, nalu[0] is ${nalu[0]}`);
        switch (nalType) {
            case H264_NAL_TYPE.sps:
                this.sps = nalu;
                break;
            case H264_NAL_TYPE.pps:
                this.pps = nalu;
                break;
            default:
                break;
        }
        if (!this.isSendSeqHeader) {
            if (this.sps && this.pps) {
                this.isSendSeqHeader = true;
                const seqHeader = avcEncoderConfigurationRecord$2({
                    sps: this.sps, pps: this.pps,
                });
                this._doDecode(seqHeader, MEDIA_TYPE.video, 0, true, 0);
                this.sps = null;
                this.pps = null;
            }
        } else {

            if (this.sps && this.pps) {
                const seqHeader = avcEncoderConfigurationRecord$2({
                    sps: this.sps, pps: this.pps,
                });
                const dts = this.getNaluDts();
                this._doDecode(seqHeader, MEDIA_TYPE.video, dts, true, 0);
                this.sps = null;
                this.pps = null;
            }

            if (isNotAvcSeqHead(nalType)) {
                if (!this.player._times.demuxStart) {
                    this.player._times.demuxStart = now();
                }
                const isIFrame = isAvcNaluIFrame(nalType);
                const dts = this.getNaluDts();
                const packet = avcEncoderNalePacket(nalu, isIFrame);
                this._preDoDecode(packet, MEDIA_TYPE.video, dts, isIFrame, 0);
            } else {
                this.player.debug.warn(this.TAG_NAME, `handleVideoH264Nalu is avc seq head nalType is ${nalType}`);
            }
        }
    }

    handleVideoH264NaluList(naluList, isIFrame, naluType) {

        if (this.isSendSeqHeader) {
            if (!this.player._times.demuxStart) {
                this.player._times.demuxStart = now();
            }
            const dts = this.getNaluDts();
            const newNalu = naluList.reduce((pre, cur) => {
                const nalu2 = addNaleHeaderLength(pre);
                const nalu3 = addNaleHeaderLength(cur);
                const nalu4 = new Uint8Array(nalu2.byteLength + nalu3.byteLength);
                nalu4.set(nalu2, 0);
                nalu4.set(nalu3, nalu2.byteLength);
                return nalu4;
            })
            const packet = avcEncoderNalePacketNotLength(newNalu, isIFrame);
            this._preDoDecode(packet, MEDIA_TYPE.video, dts, isIFrame, 0);
        } else {
            this.player.debug.warn(this.TAG_NAME, `handleVideoH264NaluList isSendSeqHeader is false`)
        }
    }

    handleVideoH265Nalu(nalu) {
        const nalType = getHevcSeqHeadType(nalu);
        switch (nalType) {
            case H265_NAL_TYPE.vps:
                this.vps = nalu;
                break;
            case H265_NAL_TYPE.sps:
                this.sps = nalu;
                break;
            case H265_NAL_TYPE.pps:
                this.pps = nalu;
                break;
            default:
                break;
        }
        if (!this.isSendSeqHeader) {
            if (this.vps && this.sps && this.pps) {
                this.isSendSeqHeader = true;
                const seqHeader = hevcEncoderConfigurationRecord$2({
                    vps: this.vps, sps: this.sps, pps: this.pps,
                });
                this._doDecode(seqHeader, MEDIA_TYPE.video, 0, true, 0);
                this.vps = null;
                this.sps = null;
                this.pps = null;
            }
        } else {

            if (this.vps && this.sps && this.pps) {
                const seqHeader = hevcEncoderConfigurationRecord$2({
                    vps: this.vps, sps: this.sps, pps: this.pps,
                });
                const dts = this.getNaluDts();
                this._doDecode(seqHeader, MEDIA_TYPE.video, dts, true, 0);
                this.vps = null;
                this.sps = null;
                this.pps = null;
            }


            if (isNotHevcSeqHead(nalType)) {
                if (!this.player._times.demuxStart) {
                    this.player._times.demuxStart = now();
                }
                const isIFrame = isHevcNaluIFrame(nalType);
                const dts = this.getNaluDts();
                const packet = hevcEncoderNalePacket(nalu, isIFrame);
                this._preDoDecode(packet, MEDIA_TYPE.video, dts, isIFrame, 0)
            }
        }
    }

    handleVideoH265NaluList(naluList, isIFrame, naluType) {
        if (this.isSendSeqHeader) {
            if (!this.player._times.demuxStart) {
                this.player._times.demuxStart = now();
            }
            const dts = this.getNaluDts();
            const newNalu = naluList.reduce((pre, cur) => {
                const nalu2 = addNaleHeaderLength(pre);
                const nalu3 = addNaleHeaderLength(cur);
                const nalu4 = new Uint8Array(nalu2.byteLength + nalu3.byteLength);
                nalu4.set(nalu2, 0);
                nalu4.set(nalu3, nalu2.byteLength);
                return nalu4;
            })
            const packet = hevcEncoderNalePacketNotLength(newNalu, isIFrame);
            this._preDoDecode(packet, MEDIA_TYPE.video, dts, isIFrame, 0)
        } else {
            this.player.debug.warn(this.TAG_NAME, `handleVideoH265NaluList isSendSeqHeader is false`)
        }
    }


    _preDoDecode(packet, type, dts, isIFrame, cts) {

        this.player.updateStats({
            vbps: packet.byteLength, dts: dts
        });

        if (isIFrame) {
            this.calcIframeIntervalTimestamp(dts);
        }

        this._doDecode(packet, MEDIA_TYPE.video, dts, isIFrame, cts);
    }

    getInputByteLength() {
        let result = 0;
        if (this.lastBuf) {
            result = this.lastBuf.byteLength;
        }

        return result;
    }
}
