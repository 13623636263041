import ajax from './ajax';
import aesjs from './aes-js';


function getNaluLength(data) {
    let length = data[3] | (data[2] << 8) | (data[1] << 16) | (data[0] << 24)
    return length;
}

// aes-256-ctr 解密
export function aes256ctrDecrypt(arrayBuffer, key, iv, isHevc = false) {
    key = new Uint8Array(key);
    iv = new Uint8Array(iv);
    const totalLength = arrayBuffer.byteLength;
    // 17(23)/27(39)[是否i帧] ,0/1(), 0,0,0, 0,0,0,0,[NALU],0,0,0,0,[NALU].... 只需要解密NALU部分数据。
    // 其中NALU部分需要跳过两个（nalu头 + 再加一个字节数据）
    let startIndex = 5;
    while (startIndex < totalLength) {
        let tempNaluLength = getNaluLength(arrayBuffer.slice(startIndex, startIndex + 4));
        if (tempNaluLength > totalLength) {
            break;
        }
        let naluType = arrayBuffer[startIndex + 4];
        //  这边只是判断了h264的加密规则，265的目前没有做处理
        let needDecrypt = false;

        if (isHevc) {
            naluType = (naluType >>> 1) & 0x3f
            needDecrypt = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 17, 18, 19, 20, 21].includes(naluType);
        } else {
            naluType = naluType & 0x1f;
            needDecrypt = naluType === 1 || naluType === 5;
        }
        //
        if (needDecrypt) {
            const tempNalu = arrayBuffer.slice(startIndex + 4 + 2, startIndex + 4 + tempNaluLength);
            let aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(iv));
            const decryptMsg = aesCtr.decrypt(tempNalu);
            // release memory
            aesCtr = null;
            arrayBuffer.set(decryptMsg, startIndex + 4 + 2);
        }
        startIndex = startIndex + 4 + tempNaluLength;
    }

    return arrayBuffer;
}

//  aac 解密
export function aes256ctrDecryptAacAudio(arrayBuffer, key, iv) {
    // 跳过30个字节
    const totalLength = arrayBuffer.byteLength;
    if (totalLength <= 30) {
        return arrayBuffer;
    }
    // 跳过30个字节+ 2 header
    const step = 30 + 2;
    const tempNalu = arrayBuffer.slice(step);
    let aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(iv));
    const decryptMsg = aesCtr.decrypt(tempNalu);
    // release memory
    aesCtr = null;
    arrayBuffer.set(decryptMsg, step);

    return arrayBuffer;
}

export function getM7SCryptoStreamKey(path) {
    return new Promise((resolve, reject) => {
        ajax.get(path).then((res) => {
            resolve(res);
        }).catch((e) => {
            reject(e);
        })
    })
}



