import Emitter from "../utils/emitter";
import {CONTROL_HEIGHT, CONTROL_PLAYBACK_HEIGHT, EVENTS, PLAY_TYPE, RENDER_TYPE, VIDEO_ENC_TYPE} from "../constant";
import {isEmpty, isMobile, isNotEmpty} from "../utils";
import SingleWatermark from "../utils/singleWatermark";

export default class CommonLoader extends Emitter {
    constructor() {
        super();
        this.videoInfo = {
            width: null,
            height: null,
            encType: null,
            encTypeCode: null,
        }
        this.init = false;
        this.prevAiFaceDetectTime = null;
        this.prevAiObjectDetectTime = null;
        this.prevOcclusionDetectTime = null;
        this.contentWatermark = null;
        this.aiContentWatermark = null;
        this.tempContentList = [];
        this.tempAiContentList = [];
        this.streamFps = 0;
        this.isDestroyed = false;
    }

    destroy() {
        this.isDestroyed = true;
        this.resetInit();
        if (this.contentWatermark) {
            this.contentWatermark.destroy();
            this.contentWatermark = null;
        }
        this.tempContentList = [];

        if (this.aiContentWatermark) {
            this.aiContentWatermark.destroy();
            this.aiContentWatermark = null;
        }
        this.tempAiContentList = [];
        this.prevAiFaceDetectTime = null;
        this.prevAiObjectDetectTime = null;
        this.streamFps = 0;
        this.off();
    }

    resetInit() {
        this.videoInfo = {
            width: null,
            height: null,
            encType: null,
            encTypeCode: null,
        }
        this.init = false;
    }

    getHasInit() {
        return this.init;
    }

    updateVideoInfo(data) {
        if (isNotEmpty(data.encTypeCode)) {
            this.videoInfo.encType = VIDEO_ENC_TYPE[data.encTypeCode];
            this.videoInfo.encTypeCode = data.encTypeCode;
        }

        if (isNotEmpty(data.encType)) {
            this.videoInfo.encType = data.encType
        }

        if (isNotEmpty(data.width)) {
            this.videoInfo.width = data.width;
        }

        if (isNotEmpty(data.height)) {
            this.videoInfo.height = data.height;
        }

        // video 基本信息
        if (isNotEmpty(this.videoInfo.encType) &&
            isNotEmpty(this.videoInfo.height) &&
            isNotEmpty(this.videoInfo.width) &&
            !this.init) {
            this.player.emit(EVENTS.videoInfo, this.videoInfo);
            this.init = true;
        }
    }

    getVideoInfo() {
        return this.videoInfo;
    }

    clearView() {
        //  清除水印
        this.tempContentList = [];
        this.tempAiContentList = [];
    }

    resize() {
        this.player.debug.log('CommonVideo', 'resize()');
        if (this.player._opt.aspectRatio === 'default' ||
            isMobile()) {
            this._resize();
        } else {
            this._resizeRatio();
        }

        if (this.contentWatermark) {
            this.contentWatermark.resize();
        }

        if (this.aiContentWatermark) {
            this.aiContentWatermark.resize();
        }

        if (this.player.singleWatermark) {
            this.player.singleWatermark.resize();
        }

        if (this.player.ghostWatermark) {
            this.player.ghostWatermark.resize();
        }

        if (this.player.dynamicWatermark) {
            this.player.dynamicWatermark.resize();
        }

        if (this.player.zoom && this.player.zooming) {
            const styleScale = this._getStyleScale();
            this.player.zoom.updatePrevVideoElementStyleScale(styleScale);
            this.player.zoom.updateVideoElementScale();
        }
    }

    _resizeRatio() {
        this.player.debug.log('CommonVideo', '_resizeRatio()');
        const ratioArray = this.player._opt.aspectRatio.split(':').map(Number);
        let width = this.player.width;
        let height = this.player.height;
        const option = this.player._opt;
        let controlHeight = 0;
        if (option.hasControl && !option.controlAutoHide) {
            controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT
            height -= controlHeight;
        }

        const videoInfo = this.videoInfo;
        const videoRatio = videoInfo.width / videoInfo.height;
        const setupRatio = ratioArray[0] / ratioArray[1];

        if (this.getType() === RENDER_TYPE.canvas) {
            this.$videoElement.style.left = '0';
            this.$videoElement.style.top = '0';
            this.$videoElement.style.transform = `none`;
        }

        if (this.getType() === RENDER_TYPE.video) {
            if (this.player._opt.videoRenderSupportScale) {
                this.$videoElement.style.objectFit = 'fill';
            }
        }

        if (videoRatio > setupRatio) {
            const percentage = (setupRatio * videoInfo.height) / videoInfo.width;
            this.$videoElement.style.width = `${percentage * 100}%`;
            this.$videoElement.style.height = `calc(100% - ${controlHeight}px)`;
            this.$videoElement.style.padding = `0 ${(width - width * percentage) / 2}px`;
        } else {
            const percentage = videoInfo.width / setupRatio / videoInfo.height;
            this.$videoElement.style.width = '100%';
            this.$videoElement.style.height = `calc(${percentage * 100}% - ${controlHeight}px)`;
            this.$videoElement.style.padding = `${(height - height * percentage) / 2}px 0`;
        }
    }

    play() {
        //         子类实现
    }

    pause() {
        //         子类实现
    }

    setRate(rate) {
        //         子类实现
    }

    getType() {
        return ''
    }

    getCanvasType() {
        return ''
    }

    getCurrentTime() {
        return 0;
    }

    getStreamFps() {
        return this.streamFps;
    }

    isPlaying() {
        return true;
    }

    getPlaybackQuality() {
        return null;
    }

    //  子类实现
    setStreamFps(fps) {
        this.player.debug.log(`CommonVideo`, 'setStreamFps', fps);
        this.streamFps = fps;
    }

    addContentToCanvas(contentList = []) {
        this.tempContentList = contentList;
    }

    addAiContentToCanvas(contentList = []) {
        this.tempAiContentList = contentList;
    }

    doAddContentToWatermark() {
        if (this.tempContentList.length > 0) {
            if (!this.contentWatermark) {
                this.contentWatermark = new SingleWatermark(this.player);
                this.contentWatermark.resize();
            }

            const watermarkConfigList = []

            this.tempContentList.forEach((item) => {

                let result = {
                    left: item.x || 0,
                    top: item.y || 0,
                };
                // text
                if (item.type === 'text') {
                    result.text = {
                        content: item.text,
                        fontSize: item.fontSize || '14',
                        color: item.color || '#000',
                    }
                }
                //  rect
                else if (item.type === 'rect') {
                    result.rect = {
                        width: item.width,
                        height: item.height,
                        color: item.color || 'green',
                        lineWidth: item.lineWidth || 2,
                        fill: item.fill || '',
                        fillOpacity: item.fillOpacity || 0.2,
                    }
                } else if (item.type === 'polygon') {
                    result.polygon = {
                        list: item.list,
                        color: item.color || 'green',
                        lineWidth: item.lineWidth || 2,
                        fill: item.fill,
                        fillOpacity: item.fillOpacity || 0.2,
                    }
                } else if (item.type === 'line') {
                    result.line = {
                        color: item.color || 'green',
                        lineWidth: item.lineWidth || 2,
                        x1: item.x1,
                        y1: item.y1,
                        x2: item.x2,
                        y2: item.y2
                    }
                }

                watermarkConfigList.push(result);
            });
            this.contentWatermark.update(watermarkConfigList);
        } else {
            if (this.contentWatermark) {
                this.contentWatermark.remove();
            }
        }
    }


    doAddAiContentToWatermark() {
        if (this.tempAiContentList.length > 0) {
            if (!this.aiContentWatermark) {
                this.aiContentWatermark = new SingleWatermark(this.player);
                this.aiContentWatermark.resize();
            }

            const watermarkConfigList = this.tempAiContentList.map((item) => {
                let result = {
                    left: item.x,
                    top: item.y,
                };
                if (item.type === 'text') {
                    result.text = {
                        content: item.text,
                        fontSize: item.fontSize,
                        color: item.color,
                    }
                } else if (item.type === 'rect') {
                    result.rect = {
                        width: item.width,
                        height: item.height,
                        color: item.color,
                        lineWidth: item.lineWidth,
                    }
                }
                return result;
            });
            this.aiContentWatermark.update(watermarkConfigList);
        } else {
            if (this.aiContentWatermark) {
                this.aiContentWatermark.remove();
            }
        }
    }


    _getStyleScale() {
        const styleTransform = this.$videoElement.style.transform;
        let scaleStyleMatch = styleTransform.match(/scale\([0-9., ]*\)/g);
        let styleScale = '';
        if (scaleStyleMatch && scaleStyleMatch[0]) {
            let scaleStyle = scaleStyleMatch[0].replace('scale(', '').replace(')', '')
            styleScale = scaleStyle.split(',');
        }

        return styleScale;
    }
}
