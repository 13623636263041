import Emitter from "../utils/emitter";
import {EVENTS, EVENTS_ERROR, PLAYER_STREAM_TYPE} from "../constant";
import {now} from "../utils";

export default class AliyunRtcLoader extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'AliyunRtcLoader';
        this.player = player;
        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        this.off();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    fetchStream(url) {
        const {aliyunRtcDecoder} = this.player;

        this.player._times.streamStart = now();

        aliyunRtcDecoder.loadSource(url).then(() => {
            this.player.debug.log(this.TAG_NAME, 'loadSource success');
            this.emit(EVENTS.streamSuccess);
        }).catch((error) => {
            this.player.debug.error(this.TAG_NAME, 'loadSource error', error);
            this.emit(EVENTS_ERROR.aliyunRtcError, error);
        })
    }

    getStreamType() {
        return PLAYER_STREAM_TYPE.aliyunRtc;
    }
}
