import Emitter from "../utils/emitter";
import {
    closeVideoFrame,
    createContextGL,
    createEmptyImageBitmap,
    dataURLToFile,
    downloadImg, getRandomInt, hexToRgba, isFunction,
    isMobile, isWebGL2Supported,
    now, strokeRectOrTextInCanvas,
    supportOffscreen
} from "../utils";
import {CANVAS_RENDER_TYPE, CONTROL_HEIGHT, EVENTS, RENDER_TYPE, SCREENSHOT_TYPE, VIDEO_ENC_TYPE} from "../constant";
import CommonCanvasLoader from "./commonCanvasLoader";

export default class CanvasVideoLoader extends CommonCanvasLoader {

    constructor(player) {
        super(player)
        this.yuvList = [];
        this.controlHeight = CONTROL_HEIGHT;
        this.tempTextCanvas = null;
        this.tempTextCanvasCtx = null;

        this.player.debug.log('CanvasVideo', 'init');
    }

    destroy() {
        super.destroy();
        this.yuvList = [];
        if (this.tempTextCanvas) {
            this.tempTextCanvasCtx.clearRect(0, 0, this.tempTextCanvas.width, this.tempTextCanvas.height);
            this.tempTextCanvas.width = 0;
            this.tempTextCanvas.height = 0;
            this.tempTextCanvas = null;
        }
        this.player.debug.log(`CanvasVideoLoader`, 'destroy');
    }


    // 渲染类型
    _initCanvasRender() {
        if (this.player._opt.useWCS && !this._supportOffscreen()) {
            this.renderType = CANVAS_RENDER_TYPE.webcodecs;
            // {alpha: false}
            if (isWebGL2Supported() &&
                this.player._opt.wcsUseWebgl2Render) {
                this._initContextGl2();
                if (this.webglRender) {
                    this.isWcsWebgl2 = true;
                }
            } else {
                this._initContext2D();
            }
        } else if (this.player._opt.useMSE &&
            this.player._opt.mseUseCanvasRender) {
            this.renderType = CANVAS_RENDER_TYPE.mse;
            this._initContext2D();
        } else if (this.player.isOldHls() &&
            this.player._opt.useCanvasRender) {
            this.renderType = CANVAS_RENDER_TYPE.hls;
            this._initContext2D();
        } else if (this.player.isWebrtcH264() &&
            this.player._opt.webrtcUseCanvasRender) {
            this.renderType = CANVAS_RENDER_TYPE.webrtc;
            this._initContext2D();
        } else if (this._supportOffscreen()) {
            this.renderType = CANVAS_RENDER_TYPE.offscreen;
            this._bindOffscreen();
        } else if (this.player._opt.useWebGPU) {
            this.renderType = CANVAS_RENDER_TYPE.webgpu;
            this._initContextGPU();
        } else {
            this.renderType = CANVAS_RENDER_TYPE.webgl;
            this._initContextGl();
        }
    }

    _supportOffscreen() {
        return supportOffscreen(this.$videoElement) && this.player._opt.useOffscreen;
    }

    //
    _bindOffscreen() {
        this.bitmaprenderer = this.$videoElement.getContext('bitmaprenderer');
    }

    render(msg) {
        this.yuvList.push(msg);
        this.startRender();
    }

    startRender() {
        while (true) {
            if (this.yuvList.length <= 0) {
                break;
            }
            const yuv = this.yuvList.shift();
            this.doRender(yuv);
        }
    }

    //
    doRender(msg) {

        //
        if (this.renderType !== CANVAS_RENDER_TYPE.mse) {
            const tempStats = {
                ts: msg.ts || 0,
                fps: true
            }
            this.player.updateStats(tempStats)
        }

        switch (this.renderType) {
            case CANVAS_RENDER_TYPE.offscreen:
                this.bitmaprenderer.transferFromImageBitmap(msg.buffer);
                break;
            case CANVAS_RENDER_TYPE.webgl:
            case CANVAS_RENDER_TYPE.webgpu:

                if (this.isWebglContextLost) {
                    this.player.debug.warn('CanvasVideoLoader', 'doRender() and webgl context is lost');
                    return;
                }

                let yuvData = msg.output;
                //  face ai
                if (this.player.faceDetectActive &&
                    this.player.ai &&
                    this.player.ai.faceDetector) {
                    if (this.prevAiFaceDetectTime === null) {
                        this.prevAiFaceDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiFaceDetectTime >= this.player._opt.aiFaceDetectInterval) {
                        yuvData = this.player.ai.faceDetector.detect({
                            width: this.$videoElement.width,
                            height: this.$videoElement.height,
                            data: msg.output,
                            ts: msg.ts || 0
                        })
                        this.prevAiFaceDetectTime = _nowTime;
                    }
                }

                //  object ai
                if (this.player.objectDetectActive &&
                    this.player.ai &&
                    this.player.ai.objectDetector) {

                    if (this.prevAiObjectDetectTime === null) {
                        this.prevAiObjectDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiObjectDetectTime >= this.player._opt.aiObjectDetectInterval) {
                        yuvData = this.player.ai.objectDetector.detect({
                            width: this.$videoElement.width,
                            height: this.$videoElement.height,
                            data: msg.output,
                            ts: msg.ts || 0
                        })
                        this.prevAiObjectDetectTime = _nowTime;
                    }
                }

                //  occlusion ai
                if (this.player.occlusionDetectActive &&
                    this.player.ai &&
                    this.player.ai.occlusionDetector) {
                    if (this.prevAiOcclusionDetectTime === null) {
                        this.prevAiOcclusionDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiOcclusionDetectTime >=
                        this.player._opt.aiOcclusionDetectInterval) {
                        const result = this.player.ai.occlusionDetector.check({
                            width: this.$videoElement.width,
                            height: this.$videoElement.height,
                            data: msg.output,
                        })
                        this.prevAiOcclusionDetectTime = _nowTime;

                        if (result) {
                            // emit
                            this.player.emit(EVENTS.aiOcclusionDetectResult, {
                                ts: msg.ts || 0
                            })
                        }
                    }
                }

                if (this.player.imageDetectActive &&
                    this.player.ai &&
                    this.player.ai.imageDetector) {
                    const result = this.player.ai.imageDetector.check({
                        width: this.$videoElement.width,
                        height: this.$videoElement.height,
                        data: msg.output,
                    })

                    if (result && result.data) {
                        this.player.emit(EVENTS.aiOcclusionDetectResult, {
                            type: result.type,
                            ts: msg.ts || 0
                        })
                        if (this.player._opt.aiImageDetectDrop) {
                            this.player.debug.log('CanvasVideoLoader', `doRender() and ai image detect result type is ${result.type} and drop`);
                            return;
                        }
                    }
                }


                if (this.renderType === CANVAS_RENDER_TYPE.webgpu) {
                    try {
                        if (!this.webGPURender) {
                            this.player.debug.warn('CanvasVideoLoader', 'doRender webgpu render is not init');
                            return;
                        }
                        this.webGPURender.renderYUV(this.$videoElement.width, this.$videoElement.height, yuvData);
                    } catch (e) {
                        this.player.debug.error(`CanvasVideoLoader`, `doRender webgpu render and error: ${e.toString()}`);
                    }
                } else if (this.renderType === CANVAS_RENDER_TYPE.webgl) {
                    try {
                        this.webglRender.renderYUV(this.$videoElement.width, this.$videoElement.height, yuvData);
                    } catch (e) {
                        this.player.debug.error(`CanvasVideoLoader`, `doRender webgl render context is lost ${this.contextGl && this.contextGl.isContextLost()}  and error: ${e.toString()}`);
                    }
                }
                break;
            case CANVAS_RENDER_TYPE.webcodecs:
                if (this.webglRender) {
                    this.webglRender.render(msg.videoFrame);
                    closeVideoFrame(msg.videoFrame);
                } else if (this.context2D) {
                    if (isFunction(msg.videoFrame.createImageBitmap)) {
                        try {
                            msg.videoFrame.createImageBitmap().then((image) => {
                                this.context2D.drawImage(image, 0, 0, this.$videoElement.width, this.$videoElement.height);
                                closeVideoFrame(msg.videoFrame);
                            })
                        } catch (e) {
                        }
                    } else {
                        this.context2D.drawImage(msg.videoFrame, 0, 0, this.$videoElement.width, this.$videoElement.height);
                        closeVideoFrame(msg.videoFrame);
                    }
                } else {
                    this.player.debug.warn('CanvasVideoLoader', 'doRender() and webcodecs context is lost');
                }
                break;
            case CANVAS_RENDER_TYPE.mse:
                this.context2D.drawImage(msg.$video, 0, 0, this.$videoElement.width, this.$videoElement.height);
                break;
            case CANVAS_RENDER_TYPE.hls:
                this.context2D.drawImage(msg.$video, 0, 0, this.$videoElement.width, this.$videoElement.height);
                break;
            case CANVAS_RENDER_TYPE.webrtc:
                this.context2D.drawImage(msg.$video, 0, 0, this.$videoElement.width, this.$videoElement.height);
                break;
            default:
                break;
        }
        let currentPts = msg.ts || 0;
        if (this.renderType === CANVAS_RENDER_TYPE.mse) {
            currentPts = parseInt(msg.$video.currentTime * 1000, 10) + (this.player.mseDecoder.firstRenderTime || 0);
        }
        this.player.updateCurrentPts(currentPts);
        this.doAddContentToWatermark();
        this.doAddAiContentToWatermark();
    }

    //
    clearView() {
        super.clearView();
        switch (this.renderType) {
            case CANVAS_RENDER_TYPE.offscreen:
                createEmptyImageBitmap(this.$videoElement.width, this.$videoElement.height).then((imageBitMap) => {
                    this.bitmaprenderer.transferFromImageBitmap(imageBitMap);
                })
                break;
            case CANVAS_RENDER_TYPE.webgl:
                this.contextGl.clear(this.contextGl.COLOR_BUFFER_BIT);
                break;
            case CANVAS_RENDER_TYPE.webgpu:
                this.webGPURender.clear();
                break;
            case CANVAS_RENDER_TYPE.webcodecs:
                if (this.contextGl) {
                    this.contextGl.clear(this.contextGl.COLOR_BUFFER_BIT);
                } else if (this.context2D) {
                    this.context2D.clearRect(0, 0, this.$videoElement.width, this.$videoElement.height)
                }
                break;
            case CANVAS_RENDER_TYPE.mse:
                this.context2D.clearRect(0, 0, this.$videoElement.width, this.$videoElement.height)
                break;
            case CANVAS_RENDER_TYPE.hls:
                this.context2D.clearRect(0, 0, this.$videoElement.width, this.$videoElement.height)
                break;
            case CANVAS_RENDER_TYPE.webrtc:
                this.context2D.clearRect(0, 0, this.$videoElement.width, this.$videoElement.height)
                break;
            default:
                break;
        }
    }

    _initTempTextCanvas() {
        this.tempTextCanvas = document.createElement('canvas');
        this.tempTextCanvasCtx = this.tempTextCanvas.getContext('2d');
        this.tempTextCanvas.width = 600;
        this.tempTextCanvas.height = 20;
    }

    //  废弃
    doAddContentToCanvas() {
        if (this.tempContentList.length > 0 &&
            this.context2D) {
            strokeRectOrTextInCanvas({
                ctx: this.context2D,
                list: this.tempContentList,
            })
        }
    }

    //  废弃
    doAddContentToWebGlCanvas() {
        if (this.tempContentList.length > 0
            && this.contextGl &&
            this.webglRectRender) {
            this.tempContentList.forEach((item) => {
                const x = item.x;
                const y = item.y;
                if (item.type === 'rect') {
                    const width = item.width;
                    const height = item.height;
                    const lineColor = hexToRgba(item.color || '#008000');
                    const lineWidth = item.lineWidth || 4;
                    if (!width || !height) return;

                    this.webglRectRender.drawBox({
                        x,
                        y,
                        width,
                        height,
                        lineColor,
                        lineWidth,
                        canvasWidth: this.$videoElement.width,
                        canvasHeight: this.$videoElement.height
                    })
                } else if (item.type === 'text') {
                    const text = item.text || '';
                    if (!text) return;
                    const fontSize = item.fontSize || 20;
                    const textColor = item.color || '#008000';
                    if (!this.tempTextCanvas) {
                        this._initTempTextCanvas();
                    }
                    this.tempTextCanvasCtx.clearRect(0, 0, this.tempTextCanvas.width, this.tempTextCanvas.height);
                    this.tempTextCanvasCtx.font = `${fontSize}px Arial`;
                    this.tempTextCanvasCtx.fillStyle = textColor;
                    this.tempTextCanvasCtx.textBaseline = 'top';
                    this.tempTextCanvasCtx.fillText(text, 0, 0);
                    this.webglRender.drawDom(this.$videoElement.width, this.$videoElement.height, x, y, this.tempTextCanvas);
                }
            })
        }
    }
}
