import Emitter from "../utils/emitter";
import {EVENTS, EVENTS_ERROR} from "../constant";

export default class AliyunRtc extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'AliyunRtc';
        this.player = player;

        if (!window.AliRTS) {
            throw new Error('AliyunRtc is not defined');
        }

        this.aliyunRtc = window.AliRTS.createClient();
        this.aliyunRtcRemoteStream = null;
        //  video element
        this.$videoElement = this.player.video.$videoElement;

        this.listenEvents();

        this.player.debug.log(this.TAG_NAME, 'init');

    }

    destroy() {

        if (this.aliyunRtc) {

            if (this.aliyunRtcRemoteStream) {
                this.aliyunRtcRemoteStream = null;
            }

            this.aliyunRtc.unsubscribe();
            this.aliyunRtc = null;
        }
        this.off();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    // 监听事件，详见 https://help.aliyun.com/document_detail/397570.html
    listenEvents() {


        /*
         * 在onError中获取到错误码10201时，此时网页的音频是静音的，
         * 需要用户在网页上手动触发事件（必须有用户交互，不能直接通过代码控制）
         * 调用remoteStream.muted = false取消静音
         */
        this.aliyunRtc.on('onError', (err) => {
            // console.log("错误事件: ", err);
            this.player.debug.log(this.TAG_NAME, `onError and code is ${err.errorCode} and message: ${err.message}`);
            // https://help.aliyun.com/zh/live/user-guide/error-codes?spm=a2c4g.11186623.0.0.67c86f9fsWhfY5
            // 拉流重连中。
            if (err.errorCode !== 10400) {
                this.player.debug.error(this.TAG_NAME, `onError and code is ${err.errorCode} and message: ${err.message}`);
                this.player.emitError(EVENTS_ERROR.aliyunRtcError, err);
            }
        })
        // 监听重连事件: evt是重连原因
        this.aliyunRtc.on('reconnect', (evt) => {
            // console.log("重连事件: ", evt);
            this.player.debug.log(this.TAG_NAME, 'reconnect', evt);
        })

        const PLAY_EVENT = {
            CANPLAY: "canplay", // 播放准备完成
            WAITING: "waiting", // 卡顿
            PLAYING: "playing", // 卡顿恢复
            MEDIA: "media",     // 每秒上报媒体实时状态
        }

        this.aliyunRtc.on('onPlayEvent', (evt) => {
            /* evt 数据结构: {
                    event: string, // PLAY_EVENT
                    data: any, // 数据
                  }
                */
            if (evt.event === PLAY_EVENT.CANPLAY) {
                // console.log("播放准备就绪");
                this.player.debug.log(this.TAG_NAME, 'onPlayEvent and canplay')
            } else if (evt.event === PLAY_EVENT.WAITING) {
                // console.log("发生卡顿");
                this.player.debug.log(this.TAG_NAME, 'onPlayEvent and playing - > waiting')
            } else if (evt.event === PLAY_EVENT.PLAYING) {
                // console.log("卡顿恢复，播放继续");
                this.player.debug.log(this.TAG_NAME, 'onPlayEvent and waiting -> playing')
            } else if (evt.event === PLAY_EVENT.MEDIA) {
                // console.log("每秒实时媒体数据: ", evt.data);
                const mediaData = evt.data;
                /* evt.data 数据结构: {
                      url: string, // 播放地址
                      aMsid: stirng, // 音频id（默认值'rts audio'）
                      audio: {                          // (部分浏览器不支持)
                        bytesReceivedPerSecond: number, // 音频码率
                        lossRate: number, // 音频丢包率
                        rtt: number, // RTT 音/视频共用
                      },
                      vMsid: string, // 视频id（默认值'rts video'）
                      video: {                          // (部分浏览器不支持)
                        bytesReceivedPerSecond: number, // 视频码率
                        framesDecodedPerSecond: number, // 解码帧率
                        fps: number, // 渲染帧率
                        height: number, // 分辨率高度
                        width: number, // 分辨率宽度
                        lossRate: number, // 视频丢包率
                        rtt: number, // RTT 音/视频共用
                      },
                      networkQuality: number, // 网络状况评分
                    }
                    // networkQuality 网络状况评分取值含义:
                    // 0: 未知, 1: 极佳, 2: 较好, 3: 一般, 4: 较差, 5: 极差, 6: 无网络
                  */
                let stateContent = {};
                let rate = 0;
                if (mediaData.audio) {
                    const bytesReceivedPerSecond = parseInt(mediaData.audio.bytesReceivedPerSecond, 10);
                    rate += bytesReceivedPerSecond;
                    stateContent.abps = bytesReceivedPerSecond;
                }

                if (mediaData.video) {
                    const bytesReceivedPerSecond = parseInt(mediaData.video.bytesReceivedPerSecond, 10);
                    rate += bytesReceivedPerSecond;
                    stateContent.vbps = bytesReceivedPerSecond;
                }

                this.player.updateStats(stateContent);
                this.player.emit(EVENTS.kBps, (rate / 1024).toFixed(2));
            }
        })
    }


    loadSource(url) {
        return new Promise((resolve, reject) => {
            /**
             * isSupport检测是否可用
             * @param {Object} supportInfo 检测信息
             * @param {boolean} supportInfo.isReceiveVideo 是否拉视频流
             * @return {Promise}
             */
            this.aliyunRtc.isSupport({isReceiveVideo: true}).then(() => {
                /**
                 * rts开始拉流接口
                 * @param {string} pullStreamUrl 拉流地址,在地址后添加@subaudio=no或者@subvideo=no来表示不订阅音频流或视频流
                 * @param {Object} [config] （可选）自定义配置
                 * @param {string} [config.signalUrl] （可选）信令地址
                 * @param {number} [config.retryTimes] （可选）最大重连次数（默认5）
                 * @param {number} [config.retryInterval] （可选）重连间隔（单位ms，默认2000）
                 * @return {Promise}
                 */
                this.aliyunRtc.subscribe(url, {}).then((remoteStream) => {
                    this.aliyunRtcRemoteStream = remoteStream;
                    // mediaElement是媒体标签audio或video
                    remoteStream.play(this.$videoElement);
                    resolve();
                    // 调用 remoteStream.play 会将媒体流绑定到媒体标签并尝试自动播放，
                    // 如不希望自动播放，可以传入第二个参数 {autoplay:false}，从2.2.4版本开始支持
                    // remoteStream.play(mediaElement, {autoplay:false});
                }).catch((err) => {
                    this.player.debug.error(this.TAG_NAME, 'loadSource and subscribe is not success: ', err.message);
                    reject(err.message);
                })
            }).catch((err) => {
                this.player.debug.error(this.TAG_NAME, 'loadSource and is not support: ', err.message);
                reject(err.message);    // 不支持
            })
        })
    }


    getVideoCurrentTime() {
        let result = 0;
        if (this.$videoElement) {
            result = this.$videoElement.currentTime;
        }
        return result;
    }

}
