// 播放协议
export const PLAYER_PLAY_PROTOCOL = {
    websocket: 1,
    fetch: 2,
    hls: 3,
    webrtc: 4,
    webTransport: 5,
    aliyunRtc: 6,
}

export const PLAYER_PLAY_PROTOCOL_LIST = [
    '', "websocket", "fetch", "hls", "webrtc", "webTransport", "aliyunRtc"
]

export const PLAYER_STREAM_TYPE = {
    fetch: 'fetch',
    hls: 'hls',
    websocket: 'websocket',
    webrtc: 'webrtc',
    webTransport: 'webTransport',
    worker: 'worker',
    aliyunRtc: 'aliyunRtc',
}

// 播放
export const PLAY_TYPE = {
    player: "player",
    playerAudio: 'playerAudio',
    playbackTF: 'playbackTF',
}

export const FILE_SUFFIX = {
    mp4: 'mp4',
    webm: 'webm',
    flv: 'flv',
    mov: 'mov'
};

export const DEMUX_TYPE = {
    flv: 'flv',
    m7s: 'm7s',
    hls: 'hls',
    webrtc: 'webrtc',
    webTransport: 'webTransport',
    nakedFlow: 'nakedFlow',
    fmp4: 'fmp4',
    mpeg4: 'mpeg4',
    aliyunRtc: 'aliyunRtc',
}

export const DEMUX_TYPE_SHOW = {
    flv: 'FLV',
    m7s: 'm7s',
    hls: 'HLS',
    fmp4: 'FMP4',
    mpeg4: 'MPEG4',
    webrtc: 'Webrtc',
    webTransport: 'WebTransport',
    nakedFlow: '裸流',
    aliyunRtc: 'aliyunRtc',
}

export const DECODE_TYPE = {
    mse: 'mse',
    wcs: 'wcs',
    offscreen: 'offscreen',
    wasm: 'wasm',
    simd: 'simd',
    mt: 'mt',
    webrtc: 'webrtc',
    hls: 'hls',
    aliyunRtc: 'aliyunRtc',
}

export const RENDER_TYPE = {
    canvas: 'canvas',
    video: 'video'
}

export const DEBUG_LEVEL = {
    debug: 'debug',
    warn: 'warn',
}

export const PTZ_ACTIVE_EVENT_TYPE = {
    click: 'click',
    mouseDownAndUp: 'mouseDownAndUp',
}

export const PLAYBACK_CONTROL_TYPE = {
    normal: 'normal', // default normal 24H
    simple: 'simple',
}


export const PLAYER_NAME = 'JessibucaPro'

export const DEFAULT_PLAYBACK_FORWARD_MAX_RATE_DECODE_IFRAME = 4; // default playback forward max rate decode iframe

export const MEDIA_SOURCE_UPDATE_END_TIMEOUT = 10 * 1000

export const DOCUMENT_ONE_SECOND_BUFFER_LENGTH = 60; // default one second buffer length (fps is 25 - 30)

export const MSE_MAX_DELAY_TIME = 5;// 单位 秒

export const MSE_DELAY_INCREASE_TIME = 3;// 单位 秒

export const PLAYER_RESIZE_TIME = 500;

export const PLAYER_SCROLL_TIME = 200;

export const FLV_BUFFER_LARGE_SIZE = 1024 * 1024; // 1024kb = 1mb

export const WASM_DECODE_MAX_DIFF_TIMESTAMP = 1100; // 1s

export const AUDIO_CHANNEL_MAX = 2; // max support 2 channel

export const FRAME_TS_MAX_DIFF = 1000 * 60 * 60; // 1 hour

export const SIMD_H264_DECODE_MAX_WIDTH = 4080;

export const VIDEO_PAYLOAD_MIN_SIZE = 12; // video payload min size: 12 byte

export const CRYPTO_KEY_URL_PATH = '/crypto/'

export const CONTAINER_DATA_SET_KEY = 'jbprov'

export const VIDEO_ELEMENT_RETRY_PLAY_MAX_TIME = 3 // video element retry play max time

export const URL_OBJECT_CLEAR_TIME = 10 * 1000; // url object clear time

export const DEMUX_LOOP_INTERVAL_TIMES = 20; // 20ms

export const ERROR_MESSAGE_TIPS = {
    webglAlignmentError: 'Webgl 渲染失败',
    webglContextLostError: 'webgl 上下文丢失',
    mediaSourceH265NotSupport: '不支持硬解码H265',
    mediaSourceFull: '缓冲区已满',
    mediaSourceAppendBufferError: '初始化解码器失败',
    mseSourceBufferError: '解码失败',
    mseAddSourceBufferError: '初始化解码器失败',
    mediaSourceDecoderConfigurationError: '初始化解码器失败',
    mediaSourceTsIsMaxDiff: '流异常',
    mseWidthOrHeightChange: '流异常',
    mediaSourceAudioG711NotSupport: '硬解码不支持G711a/u音频格式',
    mediaSourceUseCanvasRenderPlayFailed: 'MediaSource解码使用canvas渲染失败',
    webcodecsH265NotSupport: '不支持硬解码H265',
    webcodecsUnsupportedConfigurationError: '初始化解码器失败',
    webcodecsDecodeConfigureError: '初始化解码器失败',
    webcodecsDecodeError: '解码失败',
    wcsWidthOrHeightChange: '解码失败',
    wasmDecodeError: '解码失败',
    simdDecodeError: '解码失败',
    wasmWidthOrHeightChange: '流异常',
    wasmUseVideoRenderError: 'video自动渲染失败',
    videoElementPlayingFailed: 'video自动渲染失败',
    simdH264DecodeVideoWidthIsTooLarge: '不支持该分辨率的视频',
    networkDelayTimeout: '网络超时重播失败',
    fetchError: '请求失败',
    streamEnd: '请求结束',
    websocketError: '请求失败',
    webrtcError: '请求失败',
    hlsError: '请求失败',
    decoderWorkerInitError: '初始化worker失败',
    videoElementPlayingFailedForWebrtc: 'video自动渲染失败',
    videoInfoError: '解析视频分辨率失败',
    webrtcStreamH265: 'webrtc不支持H265',
    hlsV2Mp4NotSupport: 'Hls解码器不支持解码',
    delayTimeout: '播放超时重播失败',
    loadingTimeout: '加载超时重播失败',
    loadingTimeoutRetryEnd: '加载超时重播失败',
    delayTimeoutRetryEnd: '播放超时重播失败',
}


export const DEFAULT_JESSIBUCA_OPTIONS = {
    url: '',
    playbackConfig: {},
    fullscreenWatermarkConfig: {}, // 全屏水印设置。
    playType: PLAY_TYPE.player,
    playbackForwardMaxRateDecodeIFrame: DEFAULT_PLAYBACK_FORWARD_MAX_RATE_DECODE_IFRAME,
    playOptions: {},
    isLive: true,
    isMulti: true, // 默认就是显示uuid
    isM7sCrypto: false,
    // todo:template 里面不设置为true，只有dev 设置为true，方便官网测试使用。
    supportHls265: true,
    playFailedUseLastFrameShow: true, // 播放失败(异常原因)后，使用最后一帧显示。等同于 pause(isClear) isClear = true
    // todo:template 里面不设置为true，只有dev 设置为true，方便官网测试使用。
    playFailedAndPausedShowMessage: true, // 播放失败并暂停，是否显示提示信息。
    pauseAndNextPlayUseLastFrameShow: false,
    widthOrHeightChangeReplayDelayTime: 0,// 单位秒，宽高变化重新播放延迟时间
}

// default player options
export const DEFAULT_PLAYER_OPTIONS = {
    playType: PLAY_TYPE.player, // inner
    container: '',//
    videoBuffer: 1 * 1000, // 1* 1000ms == 1 second
    videoBufferDelay: 1 * 1000,// 1 * 1000ms
    networkDelay: 10 * 1000, //  10 * 1000ms
    isResize: true, //
    isFullResize: false, // full resize
    isFlv: false, // flv
    isHls: false, // hls（inner）
    isFmp4: false, // fmp4,
    isFmp4Private: false,// 是否是fmp4私有协议
    isWebrtc: false,// webrtc (inner)
    isWebrtcForZLM: false,// webrtc for ZLM
    isWebrtcForSRS: false,// webrtc for SRS
    isWebrtcForOthers: false, // webrtc for others
    isNakedFlow: false,// 是否是裸流（264、265）
    isMpeg4: false,// 是否是mpeg4
    isAliyunRtc: false,// 是否是阿里云rtc
    debug: false, // debug log
    debugLevel: DEBUG_LEVEL.warn, // log level
    debugUuid: '', // debug uuid (inner)
    isMulti: true,// 是否多实例播放(配置log用的)
    multiIndex: -1,// 多实例播放的index
    hotKey: false, // 快捷键
    loadingTimeout: 10, // loading timeout 单位秒
    heartTimeout: 10,  // heart timeout 单位秒
    timeout: 10, // timeout 单位秒
    pageVisibilityHiddenTimeout: 5 * 60, //   5 * 60  = 5 minute
    loadingTimeoutReplay: true, // loading timeout replay
    heartTimeoutReplay: true,// heart timeout replay。
    loadingTimeoutReplayTimes: 3, // loading timeout replay fail times
    heartTimeoutReplayTimes: 3, // heart timeout replay fail times
    heartTimeoutReplayUseLastFrameShow: true, // heart timeout replay use last frame
    replayUseLastFrameShow: true,// replay use last frame
    replayShowLoadingIcon: false,// replay show loading icon
    supportDblclickFullscreen: false,
    showBandwidth: false, //
    showPerformance: false, // 是否显示性能面板
    mseCorrectTimeDuration: 20,// mse correct time duration 20ms
    keepScreenOn: true, // keep screen on
    isNotMute: false,
    hasAudio: true,
    hasVideo: true,
    operateBtns: {
        fullscreen: false,
        screenshot: false,
        play: false,
        audio: false,
        record: false,
        ptz: false,
        quality: false,
        zoom: false,
        close: false,
        scale: false,
        performance: false,
        logSave: false,
        aiFace: false,
        aiObject: false,
        aiOcclusion: false,
        fullscreenFn: null,
        fullscreenExitFn: null,
        screenshotFn: null,
        playFn: null,
        pauseFn: null,
        recordFn: null,
        recordStopFn: null,
    },
    extendOperateBtns: [],
    contextmenuBtns: [],
    watermarkConfig: {}, // 局部水印设置
    controlAutoHide: false,
    hasControl: false, // inner params
    loadingIcon: true, // is show loading icon
    loadingIconStyle: {},// loading icon style
    loadingText: '',
    background: '',
    backgroundLoadingShow: false, // 加载过程中是否显示背景
    loadingBackground: '', // 内部参数 重新播放过程中，显示播放失败前的最后一帧数据。
    loadingBackgroundWidth: 0, // 内部参数 重新播放过程中，显示播放失败前的最后一帧数据 width。
    loadingBackgroundHeight: 0, // 内部参数 重新播放过程中，显示播放失败前的最后一帧数据 height。
    decoder: 'decoder-pro.js',
    decoderAudio: 'decoder-pro-audio.js',
    decoderHard: 'decoder-pro-hard.js',  // 硬解码
    wasmMp4RecorderDecoder: 'jessibuca-pro-mp4-recorder-decoder.js', // wasm mp4 recorder decoder
    decoderWASM: '',
    isDecoderUseCDN: false,
    url: '',// inner
    rotate: 0,
    mirrorRotate: 'none', // 镜像xx
    aspectRatio: 'default', // 比例支持 4:3, 16:9,
    playbackConfig: {
        playList: [], // {start:xx,end:xx,more:xx}
        fps: '', // fps值
        showControl: true,
        controlType: PLAYBACK_CONTROL_TYPE.normal, //
        duration: 0, // duration 持续时间。
        startTime: '', // 开始时间
        showRateBtn: false,
        rateConfig: [],// 播放倍率切换，支持[{label:'正常',value:1},{label:'2倍',value:2}]
        showPrecision: '',// 初始化显示精度 'oneHour', 'halfHour', 'tenMin', 'fiveMin'。
        showPrecisionBtn: true,// 是否显示精度切换按钮
        isCacheBeforeDecodeForFpsRender: false, // rfs渲染时，是否在解码前缓存数据
        uiUsePlaybackPause: false, // ui上面是否使用 playbackPause 方法
        isPlaybackPauseClearCache: true,// playbackPause是否清除缓存数据
        isUseFpsRender: false, // 是否使用固定的fps渲染，播放器会动态计算流的fps
        isUseLocalCalculateTime: false, // 是否使用本地时间来计算playback时间
        localOneFrameTimestamp: 40, // 一帧 40ms, isUseLocalCalculateTime 为 true 生效。
        supportWheel: false,// 是否支持滚动轴切换精度。
        useWCS: false,// 是否使用wcs解码
        useMSE: false,// 是否使用mse解码
    },
    qualityConfig: [], // 支持 ['高清','超清','4K']
    defaultStreamQuality: '',
    scaleConfig: ['拉伸', '缩放', '正常'],
    // text: '',
    forceNoOffscreen: true, // 默认是不采用
    hiddenAutoPause: false,
    protocol: PLAYER_PLAY_PROTOCOL.fetch, // 内部参数
    demuxType: DEMUX_TYPE.flv, // 内部参数
    useWasm: false,//wasm 解码 （inner）默认
    useMSE: false, // mse 解码
    useWCS: false, // wcs 解码
    useSIMD: true, // pro 默认优先使用wasm simd解码，不支持则使用wasm解码
    useMThreading: false,// 是否使用多线程解码
    wcsUseVideoRender: true, // wcs 是否使用 video 渲染
    wcsUseWebgl2Render: true,// canvas 模式下 wcs 是否使用webgl2渲染
    wasmUseVideoRender: true, // wasm 用video标签渲染
    mseUseCanvasRender: false,//mse 用canvas标签渲染
    hlsUseCanvasRender: false,// hls 用canvas标签渲染
    webrtcUseCanvasRender: false,// webrtc 用canvas标签渲染 待定

    useOffscreen: false, //  内部参数（废弃）
    useWebGPU: false, //  是否使用webgpu引擎
    mseDecodeErrorReplay: true,// mse 解码失败重新播放
    wcsDecodeErrorReplay: true,// wcs 解码失败重新播放
    wasmDecodeErrorReplay: true, // 解码失败重新播放。
    simdDecodeErrorReplay: true,// simd 解码失败重新播放。
    simdDecodeErrorReplayType: DECODE_TYPE.wasm, // simd 解码失败重新播放类型: wasm 或者 simd
    autoWasm: true, // 自动降级到 wasm 模式
    decoderErrorAutoWasm: true, // 解码失败自动降级到 wasm 模式
    hardDecodingNotSupportAutoWasm: true, // 硬解码不支持自动降级到 wasm 模式
    webglAlignmentErrorReplay: true, // webgl对齐失败重新播放。
    webglContextLostErrorReplay: true, // webgl context lost 重新播放。
    openWebglAlignment: false,//  https://github.com/langhuihui/jessibuca/issues/152
    // todo:template 里面不设置为true，只有dev 设置为true，方便官网测试使用。
    syncAudioAndVideo: true,// 音视频同步
    syncAudioAndVideoDiff: 400, // ms 时间戳超过之后会触发强制同步
    // playback config
    playbackDelayTime: 1000, // TF卡流播放延迟时间  Inner
    playbackFps: 25, // Inner
    playbackForwardMaxRateDecodeIFrame: DEFAULT_PLAYBACK_FORWARD_MAX_RATE_DECODE_IFRAME,// max rate render i frame , Inner
    playbackCurrentTimeMove: true, // Inner 录像流数据的当前时间是否跟着播放时长移动

    useVideoRender: true, // 使用video标签渲染
    useCanvasRender: false, // 使用canvas渲染
    networkDelayTimeoutReplay: false, // 网络延迟重连
    recordType: FILE_SUFFIX.mp4,
    checkFirstIFrame: true, // 检查第一帧是否是I帧
    nakedFlowFps: 25, // 裸流fps
    audioEngine: null, // 音频引擎
    isShowRecordingUI: true, // 是否显示录制中UI
    isShowZoomingUI: true, // 是否显示缩放中
    useFaceDetector: false, // 使用人脸检测
    useObjectDetector: false, // 使用物体检测
    useImageDetector: false,// 使用图片检测
    useOcclusionDetector: false,// 使用遮挡检测
    ptzClickType: PTZ_ACTIVE_EVENT_TYPE.click, // PTZ 点击类型
    ptzStopEmitDelay: 0.3,// ptz停止后，停止发送指令的延迟时间(秒)
    ptzZoomShow: false,// ptz操作是否显示放大缩小操作
    ptzApertureShow: false, // ptz操作是否显示光圈操作
    ptzFocusShow: false, // ptz操作是否显示聚焦操作
    ptzMoreArrowShow: false,// ptz操作是否显示更多箭头
    // 微信安卓音频播放块大小
    // 计算规则 48000 * ms / 1000 = size,
    // 48000 * 200 /1000 = 9600  播放时长200ms
    // 48000 * 175 /1000 = 8400  播放时长175ms
    // 48000 * 150 /1000 = 7200  播放时长150ms
    // 48000 * 125 /1000 = 6000  播放时长125ms
    // 48000 * 100 /1000 = 4800  播放时长100ms default
    // 48000 * 50 /1000 = 2400  播放时长50ms
    // 48000 * 25 /1000 = 1200  播放时长25ms
    // 48000 * 10 /1000 = 480  播放时长10ms
    weiXinInAndroidAudioBufferSize: 4800,
    isM7sCrypto: false,// 是否是m7s加密,
    m7sCryptoAudio: false,// m7s音频是否加密
    isSm4Crypto: false,// 是否是sm4加密
    isXorCrypto: false,// 是否是xor加密
    sm4CryptoKey: '',// sm4加密key
    m7sCryptoKey: '',
    xorCryptoKey: '', // xor加密key
    cryptoKey: '',// 加密key
    cryptoIV: '',// 加密iv
    cryptoKeyUrl: '',// 加密key获取域名(for m7s)
    autoResize: false,// 自动调整大小 inner
    useWebFullScreen: false,// 使用web全屏(旋转播放器90度)(只会在移动端生效)
    ptsMaxDiff: 60 * 60, //单位(秒)，默认值是1H
    aiFaceDetectLevel: 2,// 人脸检测等级(1-5)
    aiFaceDetectWidth: 240, // 人脸检测宽度（inner）
    aiFaceDetectShowRect: true, // 人脸检测显示框子
    aiFaceDetectInterval: 1000, // 人脸检测时间间隔 ms
    aiFaceDetectRectConfig: {},// 人脸检测框子配置
    aiObjectDetectLevel: 2,// 物体检测等级(1-5)
    aiObjectDetectWidth: 240, // 物体检测宽度（inner）
    aiObjectDetectShowRect: true,// 物体检测显示框子
    aiObjectDetectInterval: 1000, // 物品检测时间间隔 ms
    aiObjectDetectRectConfig: {},// 物体检测框子配置
    aiOcclusionDetectInterval: 1000,// 遮挡检测时间间隔 ms
    aiImageDetectDrop: false,// 图片检测是否丢弃不渲染
    aiImageDetectActive: false,// 图片检测是否激活
    videoRenderSupportScale: true, // video渲染支持Scale
    mediaSourceTsIsMaxDiffReplay: true, // 当ts间隔超过最大值之后，重新播放
    controlHtml: '',// 自定义控制栏Html
    isH265: false,// 是否是h265,
    isWebrtcH265: false,// 是否是webrtc h265
    supportLockScreenPlayAudio: true,// 是否支持锁屏播放音频（mobile,ipad 端）
    // todo:template 里面不设置为true，只有dev 设置为true，方便官网测试使用。
    supportHls265: true,// 是否支持hls265
    isEmitSEI: false,// 是否发送sei
    pauseAndNextPlayUseLastFrameShow: false, // pause->play 使用最后一帧显示
    demuxUseWorker: true,// demux 使用worker 解析 其中，
    playFailedAndReplay: true, // 播放失败(异常原因)后，重新播放。(统一配置参数)
    showMessageConfig: ERROR_MESSAGE_TIPS, // 播放失败提示信息配置
    videoElementPlayingFailedReplay: true,// video 播放失败，主要是自动播放的时候，video不允许播放的情况下，降级会canvas。
    mp4RecordUseWasm: true, // mp4 record 使用 wasm
    //  for mse
    mseAutoCleanupSourceBuffer: true, // 是否自动清理sourceBuffer（inner）
    mseAutoCleanupMaxBackwardDuration: 30, // 30s （inner）
    mseAutoCleanupMinBackwardDuration: 10, // 10s（inner）

    // replay
    widthOrHeightChangeReplay: true, // 宽高变化重新播放
    simdH264DecodeVideoWidthIsTooLargeReplay: true, // simd h264 解码视频宽度过大重新播放
    mediaSourceAudioG711NotSupportReplay: true, // mediaSource audio g711 not support replay (inner)
    mediaSourceAudioInitTimeoutReplay: true, // mediaSource audio init timeout replay  (inner)
    mediaSourceUseCanvasRenderPlayFailedReplay: true, // mediaSource 解码使用canvas渲染失败重新播放
    mediaSourceUseCanvasRenderPlayFailedReplayType: RENDER_TYPE.video, // mediaSource 解码使用canvas渲染失败重新播放类型:mse+video,或者wasm+canvas
    widthOrHeightChangeReplayDelayTime: 0,// 单位秒，宽高变化重新播放延迟时间
    // 幽灵水印设置
    ghostWatermarkConfig: {
        on: 5, // 幽灵模式开启时间
        off: 5, // 幽灵模式关闭时间
        content: '', // 幽灵水印内容
        fontSize: 12, // 幽灵水印字体大小
        color: 'white', // 幽灵水印颜色
        opacity: 0.15, // 幽灵水印透明度
        speed: 0.2, // 幽灵水印速度
    },

    //  动态水印设置
    dynamicWatermarkConfig: {
        content: '', // 动态水印内容
        speed: 0.2, // 动态水印速度
        fontSize: 12, // 动态水印字体大小
        color: 'white', // 动态水印颜色
        opacity: 0.15, // 动态水印透明度
    },

    // 遇上时间戳相同的流，直接扔掉整个gop数据。
    isDropSameTimestampGop: false,
    // todo:template 里面不设置为true，只有dev 设置为true，方便官网测试使用。
    mseDecodeAudio: true, //  mse 解码音频数据(支持aac/mp3)

    nakedFlowH265DemuxUseNew: true,// 裸流h265解封装用new的

    //自定义扩展dom
    extendDomConfig: {
        html: '',
        showBeforePlay: false,//是否在播放前显示
        showAfterLoading: true,//是否在加载后显示
    },

    disableContextmenu: false, // 是否禁用右键菜单
}

export const WORKER_CMD_TYPE = {
    init: 'init',
    initVideo: 'initVideo',
    render: 'render',
    playAudio: 'playAudio',
    initAudio: 'initAudio',
    kBps: 'kBps',
    decode: 'decode',
    audioCode: 'audioCode',
    audioNalu: 'audioNalu',
    audioAACSequenceHeader: 'audioAACSequenceHeader',
    videoCode: 'videoCode',
    videoCodec: 'videoCodec',
    videoNalu: 'videoNalu',
    videoPayload: 'videoPayload',
    audioPayload: 'audioPayload',
    wasmError: 'wasmError',
    workerFetch: 'workerFetch',
    iframeIntervalTs: 'iframeIntervalTs',
    isDropping: 'isDropping',
    workerEnd: 'workerEnd',
    networkDelay: 'networkDelay',
    playbackStreamVideoFps: 'playbackStreamVideoFps',
    wasmDecodeVideoNoResponseError: 'wasmDecodeVideoNoResponseError',
    wasmWidthOrHeightChange: 'wasmWidthOrHeightChange',
    simdDecodeError: 'simdDecodeError',
    simdH264DecodeVideoWidthIsTooLarge: 'simdH264DecodeVideoWidthIsTooLarge',
    websocketOpen: 'websocketOpen',
    closeEnd: 'closeEnd',
    tempStream: 'tempStream',
    videoSEI: 'videoSEI',
    //  for flv recorder
    flvScriptData: 'flvScriptData',
    aacSequenceHeader: 'aacSequenceHeader',
    videoSequenceHeader: 'videoSequenceHeader',
    flvBufferData: 'flvBufferData',
    checkFirstIFrame: 'checkFirstIFrame',
}

export const WASM_ERROR = {
    invalidNalUnitSize: 'Invalid NAL unit size',
    // errorSplittingTheInputIntoNALUnits: 'Error splitting the input into NAL units'
}

export const MEDIA_TYPE = {
    audio: 1,
    video: 2
}

export const FLV_MEDIA_TYPE = {
    audio: 8,
    video: 9,
    scriptData: 18
}

export const WORKER_SEND_TYPE = {
    init: 'init',
    decode: 'decode',
    audioDecode: 'audioDecode',
    videoDecode: 'videoDecode',
    initAudioCodec: 'initAudioCodec',
    initVideoCodec: 'initVideoCodec',
    close: 'close',
    updateConfig: 'updateConfig',
    resetDecode: 'resetDecode',
    clearBuffer: 'clearBuffer',
    resetAudioDecode: 'resetAudioDecode',
    resetVideoDecode: 'resetVideoDecode',
    fetchStream: 'fetchStream',
    sendWsMessage: 'sendWsMessage',
}


export const WORKER_FETCH_CMD_TYPE = {
    fetch: 'fetch',
    destroy: 'destroy',
    buffer: 'buffer',
    fetchError: 'fetchError',
    fetchClose: 'fetchClose',
    fetchSuccess: 'fetchSuccess',
}

// inner events
export const EVENTS = {
    fullscreen: 'fullscreen$2',
    webFullscreen: 'webFullscreen',
    decoderWorkerInit: 'decoderWorkerInit',
    play: 'play',
    playing: 'playing',
    pause: 'pause',
    mute: 'mute',
    load: 'load',
    loading: 'loading',
    zooming: 'zooming',
    videoInfo: 'videoInfo',
    timeUpdate: 'timeUpdate',
    audioInfo: "audioInfo",
    log: 'log',
    error: "error",
    kBps: 'kBps',
    timeout: 'timeout',
    delayTimeout: 'delayTimeout',
    delayTimeoutRetryEnd: 'delayTimeoutRetryEnd',
    loadingTimeout: 'loadingTimeout',
    loadingTimeoutRetryEnd: 'loadingTimeoutRetryEnd',
    stats: 'stats',
    performance: "performance",
    videoSmooth: 'videoSmooth',
    faceDetectActive: 'faceDetectActive',
    objectDetectActive: 'objectDetectActive',
    occlusionDetectActive: 'occlusionDetectActive',
    imageDetectActive: 'imageDetectActive',
    // record
    record: 'record',
    recording: 'recording',
    recordingTimestamp: 'recordingTimestamp',
    recordStart: 'recordStart',
    recordEnd: 'recordEnd',
    recordCreateError: 'recordCreateError',
    recordBlob: 'recordBlob',

    buffer: 'buffer',
    videoFrame: 'videoFrame',
    videoSEI: 'videoSEI',
    start: 'start',
    metadata: 'metadata',
    resize: 'resize',
    volumechange: 'volumechange',
    destroy: 'destroy',
    beforeDestroy: 'beforeDestroy',

    // stream
    streamEnd: 'streamEnd',
    streamRate: 'streamRate',
    streamAbps: 'streamAbps',
    streamVbps: 'streamVbps',
    streamDts: 'streamDts',
    streamSuccess: 'streamSuccess',
    streamMessage: 'streamMessage',
    streamError: 'streamError',
    streamStats: 'streamStats',

    // MSE
    mseSourceOpen: 'mseSourceOpen',
    mseSourceClose: 'mseSourceClose',
    mseSourceended: 'mseSourceended',
    mseSourceBufferError: 'mseSourceBufferError',
    mseAddSourceBufferError: 'mseAddSourceBufferError',
    mseSourceBufferBusy: 'mseSourceBufferBusy',
    mseSourceBufferFull: 'mseSourceBufferFull',
    // VIDEO
    videoWaiting: 'videoWaiting',
    videoTimeUpdate: 'videoTimeUpdate',
    videoSyncAudio: 'videoSyncAudio',
    //
    playToRenderTimes: 'playToRenderTimes',
    playbackTime: 'playbackTime',
    playbackTimestamp: 'playbackTimestamp',
    playbackTimeScroll: 'playbackTimeScroll',
    playbackPrecision: 'playbackPrecision',// inner
    playbackShowPrecisionChange: 'playbackShowPrecisionChange',
    playbackJustTime: 'playbackJustTime',
    playbackStats: 'playbackStats',
    playbackSeek: 'playbackSeek',
    playbackPause: 'playbackPause',
    playbackPauseOrResume: 'playbackPauseOrResume',
    playbackRateChange: 'playbackRateChange',
    playbackPreRateChange: 'playbackPreRateChange',

    ptz: 'ptz',
    streamQualityChange: 'streamQualityChange',
    visibilityChange: "visibilityChange",
    netBuf: 'netBuf',
    close: 'close',
    networkDelayTimeout: 'networkDelayTimeout',
    togglePerformancePanel: 'togglePerformancePanel',
    viewResizeChange: 'viewResizeChange',
    flvDemuxBufferSizeTooLarge: 'flvDemuxBufferSizeTooLarge',
    // talk
    talkGetUserMediaSuccess: 'talkGetUserMediaSuccess',
    talkGetUserMediaFail: 'talkGetUserMediaFail',
    talkGetUserMediaTimeout: 'talkGetUserMediaTimeout',
    talkStreamStart: 'talkStreamStart',
    talkStreamOpen: 'talkStreamOpen',
    talkStreamClose: 'talkStreamClose',
    talkStreamError: 'talkStreamError',
    talkStreamInactive: 'talkStreamInactive',

    webrtcDisconnect: 'webrtcDisconnect',
    webrtcFailed: 'webrtcFailed',
    webrtcClosed: 'webrtcClosed',
    webrtcOnConnectionStateChange: 'webrtcOnConnectionStateChange',
    webrtcOnIceConnectionStateChange: 'webrtcOnIceConnectionStateChange',
    // crash
    crashLog: 'crashLog',

    // dom
    focus: 'focus',
    blur: 'blur',
    visibilityHiddenTimeout: 'visibilityHiddenTimeout',

    // websocket
    websocketOpen: 'websocketOpen',
    websocketClose: 'websocketClose',
    websocketError: 'websocketError',
    websocketMessage: 'websocketMessage',

    // ai
    aiObjectDetectorInfo: 'aiObjectDetectorInfo',
    aiFaceDetectorInfo: 'aiFaceDetectorInfo',
    aiOcclusionDetectResult: 'aiOcclusionDetectResult',
    aiImageDetectResult: 'aiImageDetectResult',

    // 异常暂停
    playFailedAndPaused: 'playFailedAndPaused',

    // audio
    audioResumeState: 'audioResumeState',

    // webrtc
    webrtcStreamH265: 'webrtcStreamH265',

    // flv
    flvMetaData: 'flvMetaData',

    // talk
    talkFailedAndStop: 'talkFailedAndStop',

    removeLoadingBgImage: 'removeLoadingBgImage',

    memoryLog: 'memoryLog',

    downloadMemoryLog: 'downloadMemoryLog',

    pressureObserverCpu: 'pressureObserverCpu',

    currentPts: 'currentPts',
}

// jessibuca events
export const JESSIBUCA_EVENTS = {
    load: EVENTS.load,
    timeUpdate: EVENTS.timeUpdate,
    videoInfo: EVENTS.videoInfo,
    audioInfo: EVENTS.audioInfo,
    error: EVENTS.error,
    kBps: EVENTS.kBps,
    start: EVENTS.start,
    timeout: EVENTS.timeout,
    loadingTimeout: EVENTS.loadingTimeout,
    loadingTimeoutRetryEnd: EVENTS.loadingTimeoutRetryEnd,
    delayTimeout: EVENTS.delayTimeout,
    delayTimeoutRetryEnd: EVENTS.delayTimeoutRetryEnd,
    fullscreen: 'fullscreen',
    multiFullscreen: 'multiFullscreen',
    webFullscreen: EVENTS.webFullscreen,
    play: EVENTS.play,
    pause: EVENTS.pause,
    mute: EVENTS.mute,
    stats: EVENTS.stats,
    performance: EVENTS.performance,
    //  record
    recordingTimestamp: EVENTS.recordingTimestamp,
    recordStart: EVENTS.recordStart,
    recordCreateError: EVENTS.recordCreateError,
    recordEnd: EVENTS.recordEnd,
    recordBlob: EVENTS.recordBlob,
    playToRenderTimes: EVENTS.playToRenderTimes,

    // playback
    playbackSeek: EVENTS.playbackSeek,
    playbackStats: EVENTS.playbackStats,
    playbackTimestamp: EVENTS.playbackTimestamp,
    playbackPauseOrResume: EVENTS.playbackPauseOrResume,
    playbackPreRateChange: EVENTS.playbackPreRateChange,
    playbackRateChange: EVENTS.playbackRateChange,
    playbackShowPrecisionChange: EVENTS.playbackShowPrecisionChange,

    ptz: EVENTS.ptz,
    streamQualityChange: EVENTS.streamQualityChange,
    zooming: EVENTS.zooming,
    crashLog: EVENTS.crashLog,

    focus: EVENTS.focus,
    blur: EVENTS.blur,
    visibilityHiddenTimeout: EVENTS.visibilityHiddenTimeout,
    visibilityChange: EVENTS.visibilityChange,

    // websocket
    websocketOpen: EVENTS.websocketOpen,
    websocketClose: EVENTS.websocketClose,
    //
    networkDelayTimeout: EVENTS.networkDelayTimeout,
    //
    aiObjectDetectorInfo: EVENTS.aiObjectDetectorInfo,
    aiFaceDetectorInfo: EVENTS.aiFaceDetectorInfo,
    aiOcclusionDetectResult: EVENTS.aiOcclusionDetectResult,
    aiImageDetectResult: EVENTS.aiImageDetectResult,

    playFailedAndPaused: EVENTS.playFailedAndPaused,

    streamEnd: EVENTS.streamEnd,

    // audio
    audioResumeState: EVENTS.audioResumeState,

    // video
    videoSEI: EVENTS.videoSEI,

    //  flv
    flvMetaData: EVENTS.flvMetaData,

    // webrtc
    webrtcOnConnectionStateChange: EVENTS.webrtcOnConnectionStateChange,
    webrtcOnIceConnectionStateChange: EVENTS.webrtcOnIceConnectionStateChange,

    currentPts: EVENTS.currentPts,

    videoSmooth: EVENTS.videoSmooth,
}

export const TALK_EVENTS = {
    talkStreamClose: EVENTS.talkStreamClose,
    talkStreamError: EVENTS.talkStreamError,
    talkStreamInactive: EVENTS.talkStreamInactive,
    talkGetUserMediaTimeout: EVENTS.talkGetUserMediaTimeout,
    talkFailedAndStop: EVENTS.talkFailedAndStop,
}

export const JESSIBUCA_AUDIO_EVENTS = {
    load: EVENTS.load,
    timeUpdate: EVENTS.timeUpdate,
    audioInfo: EVENTS.audioInfo,
    error: EVENTS.error,
    kBps: EVENTS.kBps,
    start: EVENTS.start,
    timeout: EVENTS.timeout,
    loadingTimeout: EVENTS.loadingTimeout,
    loadingTimeoutRetryEnd: EVENTS.loadingTimeoutRetryEnd,
    delayTimeout: EVENTS.delayTimeout,
    delayTimeoutRetryEnd: EVENTS.delayTimeoutRetryEnd,
    play: EVENTS.play,
    pause: EVENTS.pause,
    mute: EVENTS.mute,
    stats: EVENTS.stats,
    playToRenderTimes: EVENTS.playToRenderTimes,
    crashLog: EVENTS.crashLog,

    // websocket
    websocketOpen: EVENTS.websocketOpen,
    websocketClose: EVENTS.websocketClose,
    //
    playFailedAndPaused: EVENTS.playFailedAndPaused,

    // audio
    audioResumeState: EVENTS.audioResumeState,
}

export const TALK_EVENTS_ERROR = {
    talkStreamError: EVENTS.talkStreamError,
    talkStreamClose: EVENTS.talkStreamClose,
}

export const EVENTS_ERROR = {
    playError: 'playIsNotPauseOrUrlIsNull',
    fetchError: "fetchError",
    websocketError: 'websocketError',
    webcodecsH265NotSupport: 'webcodecsH265NotSupport',
    webcodecsDecodeError: 'webcodecsDecodeError',
    webcodecsUnsupportedConfigurationError: 'webcodecsUnsupportedConfigurationError',
    webcodecsDecodeConfigureError: 'webcodecsDecodeConfigureError',
    mediaSourceH265NotSupport: 'mediaSourceH265NotSupport',
    mediaSourceAudioG711NotSupport: 'mediaSourceAudioG711NotSupport',
    mediaSourceAudioInitTimeout: 'mediaSourceAudioInitTimeout',
    mediaSourceDecoderConfigurationError: 'mediaSourceDecoderConfigurationError',
    mediaSourceFull: EVENTS.mseSourceBufferFull,
    mseSourceBufferError: EVENTS.mseSourceBufferError,
    mseAddSourceBufferError: EVENTS.mseAddSourceBufferError,
    mediaSourceAppendBufferError: 'mediaSourceAppendBufferError',
    mediaSourceTsIsMaxDiff: 'mediaSourceTsIsMaxDiff',
    mediaSourceUseCanvasRenderPlayFailed: 'mediaSourceUseCanvasRenderPlayFailed',
    mediaSourceBufferedIsZeroError: 'mediaSourceBufferedIsZeroError',
    wasmDecodeError: 'wasmDecodeError',
    wasmUseVideoRenderError: 'wasmUseVideoRenderError',
    hlsError: 'hlsError',
    hlsV2Mp4NotSupport: 'hlsV2Mp4NotSupport',
    webrtcError: 'webrtcError',
    webrtcClosed: EVENTS.webrtcClosed,
    webrtcIceCandidateError: 'webrtcIceCandidateError',
    webglAlignmentError: 'webglAlignmentError',
    wasmWidthOrHeightChange: 'wasmWidthOrHeightChange',
    mseWidthOrHeightChange: 'mseWidthOrHeightChange',
    wcsWidthOrHeightChange: 'wcsWidthOrHeightChange',
    widthOrHeightChange: 'widthOrHeightChange',
    tallWebsocketClosedByError: 'tallWebsocketClosedByError',
    flvDemuxBufferSizeTooLarge: EVENTS.flvDemuxBufferSizeTooLarge,
    wasmDecodeVideoNoResponseError: 'wasmDecodeVideoNoResponseError',
    audioChannelError: 'audioChannelError',
    simdH264DecodeVideoWidthIsTooLarge: 'simdH264DecodeVideoWidthIsTooLarge',
    simdDecodeError: 'simdDecodeError',
    webglContextLostError: 'webglContextLostError',
    videoElementPlayingFailed: 'videoElementPlayingFailed',
    videoElementPlayingFailedForWebrtc: 'videoElementPlayingFailedForWebrtc',
    decoderWorkerInitError: 'decoderWorkerInitError',
    videoInfoError: 'videoInfoError',
    videoCodecIdError: 'videoCodecIdError',
    streamEnd: EVENTS.streamEnd,
    delayTimeout: EVENTS.delayTimeout,
    loadingTimeout: EVENTS.loadingTimeout,
    networkDelayTimeout: EVENTS.networkDelayTimeout,

    aliyunRtcError: 'aliyunRtcError',

    ...TALK_EVENTS_ERROR,
}

export const WEBSOCKET_STATUS = {
    notConnect: 'notConnect',
    open: 'open',
    close: 'close',
    error: 'error'
}

export const WEBSOCKET_STATUS_CODE = {
    connecting: 0,
    open: 1,
    closing: 2,
    closed: 3
}

export const BUFFER_STATUS = {
    empty: 'empty',
    buffering: 'buffering',
    full: 'full'
}


export const SCREENSHOT_TYPE = {
    download: 'download',
    base64: 'base64',
    blob: 'blob'
}


export const RECORDING_TYPE = {
    download: 'download',
    blob: 'blob'
}


export const VIDEO_ENC_TYPE = {
    7: 'H264(AVC)', //
    12: 'H265(HEVC)', //
    99: 'MPEG4'
}

export const VIDEO_ENC_CODE = {
    h264: 7,
    h265: 12,
    mpeg4: 99
}

export const VIDEO_ENC_TYPE_SHOW = {
    h264: 'H264(AVC)',
    h265: 'H265(HEVC)'
}

export const AUDIO_ENC_CODE = {
    AAC: 10,
    ALAW: 7,
    MULAW: 8,
    MP3: 2
}

export const AUDIO_ENC_CODE_SHOW = {
    AAC: 'AAC',
    ALAW: 'ALAW(g711a)',
    MULAW: 'MULAW(g711u)',
    MP3: 'MP3'
}

export const AUDIO_ENC_TYPE = {
    10: 'AAC',
    7: 'ALAW',
    8: 'MULAW',
    2: 'MP3'
}


export const H264_NAL_TYPE = {
    sps: 7,
    pps: 8,
    iFrame: 5, //
    kUnspecified: 0,
    kSliceNonIDR: 1,
    kSliceDPA: 2,
    kSliceDPB: 3,
    kSliceDPC: 4,
    kSliceIDR: 5, //  iFrame
    kSliceSEI: 6, // sei 辅助增强信息
    kSliceSPS: 7, // sps
    kSlicePPS: 8, // pps
    kSliceAUD: 9,
    kEndOfSequence: 10,
    kEndOfStream: 11,
    kFiller: 12,
    kSPSExt: 13,
    kReserved0: 14,
}

export const H265_NAL_TYPE = {
    pFrame: 1, //  语义为被参考的后置图像，且非TSA、非STSA的SS编码数据
    iFrame: 19, // IDR_W_RADL 语义为可能有RADL图像的IDR图像的SS编码数据 IDR
    nLp: 20, // kSliceIDR_N_LP
    vps: 32, // 语义为视频参数集
    sps: 33, // 语义为序列参数集
    pps: 34, // 语义为图像参数集
    sei: 39, //SEI 语义为补充增强信息
    prefixSei: 39,//
    suffixSei: 40,//
}


export const CONTROL_HEIGHT = 38
export const CONTROL_PLAYBACK_HEIGHT = 48

export const SCALE_MODE_TYPE = {
    full: 0, //  视频画面完全填充canvas区域,画面会被拉伸
    auto: 1, // 视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边
    fullAuto: 2 // 视频画面做等比缩放后,完全填充canvas区域,画面不被拉伸,没有黑边,但画面显示不全
}


export const CANVAS_RENDER_TYPE = {
    webcodecs: 'webcodecs',
    webgl: 'webgl',
    webgl2: 'webgl2',
    webgpu: 'webgpu',
    offscreen: 'offscreen',
    mse: 'mse',
    hls: 'hls',
    webrtc: 'webrtc'
}

export const ENCODED_VIDEO_TYPE = {
    key: 'key',
    delta: 'delta'
}


export const MP4_CODECS = {
    avc: 'video/mp4; codecs="avc1.64002A"',
    hev: 'video/mp4; codecs="hev1.1.6.L123.b0"',
}


export const MEDIA_SOURCE_STATE = {
    ended: 'ended',
    open: 'open',
    closed: 'closed'
}


// frag duration
export const FRAG_DURATION = Math.ceil(1000 / 25)

export const AIDIO_MAX_VIDEO_DIFF = 2000; // ms
// renderTimeDay once length
export const PLAYBACK_RENDER_ONCE_LENGTH = 2000;

export const HOT_KEY = {
    esc: 27, //
    arrowUp: 38, //
    arrowDown: 40, //
}

// playback control time precision
export const PLAYBACK_CONTROL_TIME_PRECISION = {
    oneHour: 'oneHour', // 60min
    halfHour: 'halfHour', // 30min
    tenMin: 'tenMin', // 10min
    fiveMin: 'fiveMin', // 5min
    // oneMin: "oneMin" // 1min
}

export const PLAYBACK_CONTROL_TIME_PRECISION_CLASS = {
    oneHour: 'one-hour', // 60min
    halfHour: 'half-hour', // 30min
    tenMin: 'ten-min', // 10min
    fiveMin: 'five-min', // 5min
    // oneMin: 'one-min' // 1min
}

export const PLAYBACK_CONTROL_TIME_PRECISION_TEXT = {
    oneHour: '1h', // 60min
    halfHour: '30min', // 30min
    tenMin: '10min', // 10min
    fiveMin: '5min', // 5min
    // oneMin: '1min' // 1min
}

//
export const PLAYBACK_CONTROL_TIME_PRECISION_ARRAY = [
    'oneHour', 'halfHour', 'tenMin', 'fiveMin'
]

export const PTZ_ARROW = ['up', 'right', 'down', 'left', 'left-up', 'right-up', 'left-down', 'right-down'];

export const PTZ_OBJ = {
    up: "up",
    right: 'right',
    down: 'down',
    left: 'left',
    leftUp: 'leftUp',
    leftDown: 'leftDown',
    rightUp: 'rightUp',
    rightDown: 'rightDown',
    // stop
    stop: 'stop',
    fiStop: 'fiStop', // just for 聚焦，光圈
    // 镜头
    zoomExpand: 'zoomExpand',
    zoomNarrow: 'zoomNarrow',
    // 聚焦
    apertureFar: 'apertureFar',
    apertureNear: 'apertureNear',
    // 光圈
    focusFar: 'focusFar',
    focusNear: 'focusNear',
}

export const TALK_ENC_TYPE = {
    g711a: 'g711a',
    g711u: 'g711u',
    pcm: 'pcm',
    opus: 'opus',
}

export const SCREENSHOT_FORMAT_TYPE = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    webp: 'image/webp'
};

export const PLAYBACK_VIDEO_MAX_BUFFER_SIZE = 50;


export const MEDIA_SOURCE_EVENTS = {
    sourceClose: 'sourceclose',
    sourceOpen: 'sourceopen',
    sourceended: 'sourceended'
}

export const VIDEO_ELEMENT_EVENTS = {
    canplay: 'canplay',
    waiting: "waiting",
    timeUpdate: 'timeupdate',
    ratechange: 'ratechange'
}


export const VIDEO_ENCODE_TYPE = {
    h264: 'avc',
    h265: 'hevc'
}


export const AVCPacketType = {
    AVCSequenceHeader: 0,
    AVCNalu: 1,
}


export const WCS_ERROR = {
    keyframeIsRequiredError: 'A key frame is required after configure() or flush()',
    canNotDecodeClosedCodec: "Cannot call 'decode' on a closed codec",
    unsupportedConfiguration: 'Unsupported configuration',
    decoderFailure: 'Decoder failure',
    decodingError: 'Decoding error',
    decoderError: 'Decoder error',
    hevcDecodingIsNotSupported: 'HEVC decoding is not supported',
}
export const FETCH_ERROR = {
    abortError: 'The user aborted a request',
    abortError2: 'AbortError',
    abort: 'AbortError'
}

export const PLAYER_STATUS = {
    loading: 'loading',
    playing: 'playing',
    paused: 'paused',
    destroy: 'destroy'
}


export const AVC_PACKET_TYPE = {
    sequenceHeader: 0,
    nalu: 1
}

export const FRAME_TYPE = {
    keyFrame: 1,
    interFrame: 2
}

//     RTP_PAYLOAD_TYPE_PCMU    = 0,   // g711u
//     RTP_PAYLOAD_TYPE_PCMA    = 8,   // g711a
//     RTP_PAYLOAD_TYPE_JPEG    = 26,
//     RTP_PAYLOAD_TYPE_H264    = 96,
//     RTP_PAYLOAD_TYPE_H265    = 97,
//     RTP_PAYLOAD_TYPE_OPUS    = 98,
//     RTP_PAYLOAD_TYPE_AAC     = 99,
//     RTP_PAYLOAD_TYPE_G726    = 100,
//     RTP_PAYLOAD_TYPE_G726_16 = 101,
//     RTP_PAYLOAD_TYPE_G726_24 = 102,
//     RTP_PAYLOAD_TYPE_G726_32 = 103,
//     RTP_PAYLOAD_TYPE_G726_40 = 104,
//     RTP_PAYLOAD_TYPE_SPEEX   = 105,
export const RTP_PAYLOAD_TYPE = {
    pcma: 8,
    g711a: 8,
    pcmu: 0,
    g711u: 0,
    jpeg: 26,
    h264: 96,
    h265: 97,
    opus: 98,
    aac: 99,
}


export const TALK_PACKET_TYPE = {
    empty: 'empty', // 裸的协议
    rtp: 'rtp',
}

export const TALK_PACKAGE_TCP_SEND_TYPE = {
    tcp: 'tcp',
    udp: 'udp'
}


export const RECORD_STREAM_TYPE = {
    stream: 'stream',
    playback: 'playback'
}


export const WEBSOCKET_EVENTS = {
    open: 'open',
    close: 'close',
    error: 'error',
    message: 'message'
}

export const TALK_ENGINE = {
    worklet: 'worklet',
    script: 'script'
}

// default talk options
export const DEFAULT_TALK_OPTIONS = {
    encType: TALK_ENC_TYPE.g711a,
    packetType: TALK_PACKET_TYPE.rtp, // 默认的包个格式化
    packetTcpSendType: TALK_PACKAGE_TCP_SEND_TYPE.tcp, // 默认的包个格式化
    rtpSsrc: '0000000000', // 10 位
    numberChannels: 1, // 采样通道
    sampleRate: 8000, // 采样率
    sampleBitsWidth: 16, // 采样精度
    sendInterval: 20, // 发送间隔(ms)
    debug: false,
    debugLevel: DEBUG_LEVEL.warn, // debug level
    testMicrophone: false, // 测试麦克风获取
    saveRtpToFile: false, // 保存 rtp 到文件
    audioBufferLength: 160, // 默认走的是 20ms 8000 采样率 16 位精度
    engine: TALK_ENGINE.worklet, //
    checkGetUserMediaTimeout: false, // 检测 getUserMedia 超时
    getUserMediaTimeout: 10 * 1000, // getUserMedia 超时时间 10s
    audioConstraints: {
        // deviceId: '', // 设备 id
        latency: true,//
        noiseSuppression: true, // 降噪
        autoGainControl: true,
        echoCancellation: true, // 回声消除
        sampleRate: 48000,
        channelCount: 1
    },
}


export const AUDIO_ENGINE_TYPE = {
    worklet: 'worklet',
    script: 'script', // default
    active: 'active' //
}


export const CONTROL_BUTTON_OPTIONS = {
    name: '',
    index: 0,
    icon: '',
    iconHover: '',
    iconTitle: '',
    activeIcon: '',
    activeIconHover: '',
    activeIconTitle: '',
    click: null,
    activeClick: null,
}

export const MENU_ITEM_OPTIONS = {
    content: '',
    click: null,
    index: 0
}


// multi options
export const DEFAULT_MULTI_OPTIONS = {
    container: '',
    maxSplit: 4, // 最大分屏数
    split: 1, // 默认分屏数
    draggable: false,// 是否支持拖拽
    showSelectedBorder: true, // 是否显示选中边框
    supportDblclickContainerFullscreen: false, // 是否支持双击全屏
    supportMousemoveEvent: false,
    style: {
        border: "#343434",
        borderSelect: "#FFCC00",
        background: "#000",
    }
}

//
export const MULTI_EVENTS = {
    multiFullscreen: 'multiFullscreen',
    dblSelected: 'multiDblSelected', // 双击选中窗口
    selected: 'multiSelected', // 选中窗口
    mouseover: 'multiMouseover', // 鼠标移入
    mouseout: 'multiMouseout', // 鼠标移出
    mouseup: 'multiMouseup', // 鼠标mouseUp事件
    mousedown: 'multiMousedown', // 鼠标mouseDown事件
    mousemove: 'multiMousemove', // 鼠标mouseMove事件
    dragstart: 'multiDragstart', // 拖拽开始
    dragenter: 'multiDragenter', // 拖拽进入
    dragleave: 'multiDragleave', // 拖拽离开
    drop: 'multiDrop', // 拖拽放下
}

export const MULTI_SHOW_SPECIAL_TYPE = {
    special1: '3-1', // 九宫格- 左上合并了四个。
    special2: '4-1', // 十六宫格- 左上合并了九个。
    special3: '1-1',// 两宫格- 左边一个，右边一个。
    special4: '3-4',// 十二宫格
    special5: '' // 十宫格,左上四个合并，右下四个合并
}


export const WATERMARK_REFERENCE = {
    container: 'container',
    video: 'video'
}


export const VIDEO_FRAME_TYPE = {
    keyFrame: 1,//
    interFrame: 2,//
}

export const VIDEO_PACKET_TYPE = {
    sequenceHeader: 0, //
    nalu: 1, //
}

//  default audio player options
export const DEFAULT_AUDIO_PLAYER_OPTIONS = {
    playType: PLAY_TYPE.playerAudio, // inner
    timeout: 10, // timeout
    loadingTimeout: 10, // loading timeout
    heartTimeout: 10,  // heart timeout
    debug: false, // debug log
    debugLevel: DEBUG_LEVEL.warn, // debug level
    protocol: PLAYER_PLAY_PROTOCOL.fetch, // 内部参数
    demuxType: DEMUX_TYPE.flv, // 内部参数
    isFlv: false, // is flv
    isNotMute: false, // is not mute
    videoBuffer: 1 * 1000, // 1* 1000ms == 1 second
    videoBufferDelay: 1 * 1000, // 1* 1000ms == 1 second
    videoBufferMax: 5 * 1000, // 5* 1000ms == 5 second
    loadingTimeoutReplay: true, // loading timeout replay
    heartTimeoutReplay: true,// heart timeout replay。
    loadingTimeoutReplayTimes: 3, // loading timeout replay fail times
    heartTimeoutReplayTimes: 3, // heart timeout replay fail times
    decoder: 'decoder-pro-audio-player.js', // default pcm
    isDecoderUseCDN: false,
    weiXinInAndroidAudioBufferSize: 4800,
    audioEngine: null, // 音频引擎
    supportLockScreenPlayAudio: true,// 是否支持锁屏播放音频（mobile,ipad 端）
}


export const HLS_EVENTS = {
    SUBTITLE_SEGMENTS: 'subtitle-segments',
    HLS_MANIFEST_LOADED: 'hls-manifest-loaded', // 主从m3u8格式时，master m3u8文件加载并解析完成后
    HLS_LEVEL_LOADED: 'hls-level-loaded', // 二级m3u8加载并解析完成后，抛出解析后的结构
    DEMUXED_TRACK: 'demuxed-track',
    FLV_SCRIPT_DATA: 'flv-script-data',
    METADATA_PARSED: 'metadata-parsed', // 视频元数据被第一次解析到时触发参数被分为两种类型 video 和 audio
    TTFB: 'ttfb', // 分片请求开始到接收到请求响应

    LOAD_RETRY: 'load-retry', // 请求发生重试时触发
    LOAD_START: 'load-start', // 分片在发送请求之前触发
    SPEED: 'speed', // 当收集到网络速度统计时触发
    LOAD_COMPLETE: 'load-complete', // 在请求完成后触发
    LOAD_RESPONSE_HEADERS: 'load-response-headers', // 接收到请求响应头时触发

    SEI: 'sei', // 当解析到视频 sei 时触发
    SEI_IN_TIME: 'sei-in-time', // 根据当前视频播放时间抛出 sei，触发该事件表示该 sei 将在当前时间点展示。

    NO_AUDIO_TRACK: 'no-audio-track',

    REMOVE_BUFFER: 'remove-buffer',

    BUFFEREOS: 'buffereos',

    SOURCEBUFFER_CREATED: 'sourcebuffer-created',

    SWITCH_URL_FAILED: 'switch-url-failed', // switchURL 方法调用后，切换 url 失败后触发。

    SWITCH_URL_SUCCESS: 'switch-url-success', // switchURL 方法调用后，切换 url 成功后触发。

    SUBTITLE_PLAYLIST: 'subtitle-playlist',

    STREAM_PARSED: 'stream-parsed',

    ERROR: 'error',
}

export const AI_FACE_DETECTOR_LEVEL = [0, 160, 240, 320, 480, 640];

export const AI_OBJECT_DETECTOR_LEVEL = [0, 160, 240, 320, 480, 640];

export const CPU_LEVEL = ['轻松', '正常', '较高', '高'];


export const LOADER_STATUS = {
    idle: 'idle', //
    connecting: 'connecting', //
    buffering: 'buffering', //
    error: 'error', //
    complete: 'complete', //
}


export const VIDEO_ERROR_CODE_DESC = {
    1: 'MEDIA_ERR_ABORTED', // media err aborted 取回过程被用户中止
    2: 'MEDIA_ERR_NETWORK', // media err network 当下载时发生错误
    3: 'MEDIA_ERR_DECODE', // media err decode 当解码时发生错误
    4: 'MEDIA_ERR_SRC_NOT_SUPPORTED', // media err src not supported 不支持音频/视频
}


export const AV_TRACK_ID = {
    video: 1,
    audio: 2,
}

//  rtmp 2023 spec
export const FRAME_HEADER_EX = 0x80


export const PACKET_TYPE_EX = {
    PACKET_TYPE_SEQ_START: 0,
    PACKET_TYPE_FRAMES: 1,
    PACKET_TYPE_SEQ_END: 2,
    PACKET_TYPE_FRAMESX: 3, // PACKETTYPE_FRAMESX is an optimization to avoid sending composition time offsets of 0. See Enhanced RTMP spec.
    PACKET_TYPE_METADATA: 4,
};

export const FRAME_TYPE_EX = {
    FT_KEY: 0x10,
    FT_INTER: 0x20
};

