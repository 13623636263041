import {mat4, vec3} from 'gl-matrix'

export default class WebglRender {
    constructor(gl, openWebglAlignment) {
        this.gl = gl;
        if (openWebglAlignment) {
            this.gl.pixelStorei(this.gl.UNPACK_ALIGNMENT, 1);
        }

        const shaderProgram = this._initShaderProgram();
        this._programInfo = {
            program: shaderProgram,
            attribLocations: {
                vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
                texturePosition: gl.getAttribLocation(shaderProgram, 'aTexturePosition'),
            },
            uniformLocations: {
                projectionMatrix: gl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
                modelMatrix: gl.getUniformLocation(shaderProgram, 'uModelMatrix'),
                viewMatrix: gl.getUniformLocation(shaderProgram, 'uViewMatrix'),
                rgbatexture: gl.getUniformLocation(shaderProgram, 'rgbaTexture'),
                ytexture: gl.getUniformLocation(shaderProgram, 'yTexture'),
                utexture: gl.getUniformLocation(shaderProgram, 'uTexture'),
                vtexture: gl.getUniformLocation(shaderProgram, 'vTexture'),
                isyuv: gl.getUniformLocation(shaderProgram, 'isyuv'),
            }
        };

        this._buffers = this._initBuffers();
        this._rgbatexture = this._createTexture();
        this._ytexture = this._createTexture();
        this._utexture = this._createTexture();
        this._vtexture = this._createTexture();
    }

    destroy() {
        this.gl.deleteProgram(this._programInfo.program);

        this.gl.deleteBuffer(this._buffers.position);
        this.gl.deleteBuffer(this._buffers.texPosition);
        this.gl.deleteBuffer(this._buffers.indices);

        this.gl.deleteTexture(this._rgbatexture);
        this.gl.deleteTexture(this._ytexture);
        this.gl.deleteTexture(this._utexture);
        this.gl.deleteTexture(this._vtexture);
        this._programInfo = null;
        this._buffers = null;
        this._rgbatexture = null;
        this._ytexture = null;
        this._utexture = null;
        this._vtexture = null;
    }

    _initShaderProgram() {
        const vertexShaderScript = `
            attribute vec4 aVertexPosition;
            attribute vec2 aTexturePosition;
            varying lowp vec2 vTexturePosition;
            void main(void) {
              gl_Position = aVertexPosition;
              vTexturePosition = aTexturePosition;
            }
        `;
        const fragmentShaderScript = `
            precision highp float;
            varying highp vec2 vTexturePosition;
            uniform int isyuv;
            uniform sampler2D rgbaTexture;
            uniform sampler2D yTexture;
            uniform sampler2D uTexture;
            uniform sampler2D vTexture;

            const mat4 YUV2RGB = mat4( 1.1643828125, 0, 1.59602734375, -.87078515625,
                                       1.1643828125, -.39176171875, -.81296875, .52959375,
                                       1.1643828125, 2.017234375, 0, -1.081390625,
                                       0, 0, 0, 1);


            void main(void) {

                if (isyuv>0) {

                    highp float y = texture2D(yTexture,  vTexturePosition).r;
                    highp float u = texture2D(uTexture,  vTexturePosition).r;
                    highp float v = texture2D(vTexture,  vTexturePosition).r;
                    gl_FragColor = vec4(y, u, v, 1) * YUV2RGB;

                } else {
                    gl_FragColor =  texture2D(rgbaTexture, vTexturePosition);
                }
            }
        `;

        const vertexShader = this._loadShader(this.gl.VERTEX_SHADER, vertexShaderScript);
        const fragmentShader = this._loadShader(this.gl.FRAGMENT_SHADER, fragmentShaderScript);

        // Create the shader program

        const shaderProgram = this.gl.createProgram();
        this.gl.attachShader(shaderProgram, vertexShader);
        this.gl.attachShader(shaderProgram, fragmentShader);
        this.gl.linkProgram(shaderProgram);

        // If creating the shader program failed, alert

        if (!this.gl.getProgramParameter(shaderProgram, this.gl.LINK_STATUS)) {
            console.log('Unable to initialize the shader program: ' + this.gl.getProgramInfoLog(shaderProgram));
            return null;
        }

        return shaderProgram;
    }

    _loadShader(type, source) {
        const gl = this.gl;
        const shader = gl.createShader(type);

        // Send the source to the shader object

        gl.shaderSource(shader, source);

        // Compile the shader program

        gl.compileShader(shader);

        // See if it compiled successfully

        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            console.log('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
            gl.deleteShader(shader);
            return null;
        }

        return shader;
    }

    _initBuffers() {
        const gl = this.gl;
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        const positions = [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0];


        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);


        const facePos = [
            [0.0, 1.0],
            [1.0, 1.0],
            [1.0, 0.0],
            [0.0, 0.0]
        ];

        var texturePos = [];

        texturePos = texturePos.concat(...facePos);

        const texpositionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, texpositionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(texturePos), gl.STATIC_DRAW);

        const indexBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

        const indices = [0, 1, 2, 0, 2, 3];

        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER,
            new Uint16Array(indices), gl.STATIC_DRAW);

        return {
            positions: positions,
            position: positionBuffer,
            texPosition: texpositionBuffer,
            indices: indexBuffer
        };
    }

    _createTexture() {
        let texture = this.gl.createTexture();
        this.gl.bindTexture(this.gl.TEXTURE_2D, texture);

        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);

        return texture;
    }

    _drawScene(w, h, isYUV) {
        this.gl.viewport(0, 0, w, h);

        this.gl.enable(this.gl.BLEND);
        this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this._buffers.position);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this._buffers.positions), this.gl.STATIC_DRAW);
        this.gl.vertexAttribPointer(this._programInfo.attribLocations.vertexPosition, 2, this.gl.FLOAT, false, 0, 0);
        this.gl.enableVertexAttribArray(this._programInfo.attribLocations.vertexPosition);

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this._buffers.texPosition);
        this.gl.vertexAttribPointer(this._programInfo.attribLocations.texturePosition, 2, this.gl.FLOAT, false, 0, 0);
        this.gl.enableVertexAttribArray(this._programInfo.attribLocations.texturePosition);

        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this._buffers.indices);

        let rgbatextunit = 2;
        let ytextunit = rgbatextunit + 1;
        let utextunit = rgbatextunit + 2;
        let vtextunit = rgbatextunit + 3;

        if (isYUV) {
            this.gl.activeTexture(this.gl.TEXTURE0 + ytextunit);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this._ytexture);
            this.gl.activeTexture(this.gl.TEXTURE0 + utextunit);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this._utexture);
            this.gl.activeTexture(this.gl.TEXTURE0 + vtextunit);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this._vtexture);

        } else {
            this.gl.activeTexture(this.gl.TEXTURE0 + rgbatextunit);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this._rgbatexture);
        }

        this.gl.useProgram(this._programInfo.program);
        this.gl.uniform1i(this._programInfo.uniformLocations.rgbatexture, rgbatextunit);
        this.gl.uniform1i(this._programInfo.uniformLocations.ytexture, ytextunit);
        this.gl.uniform1i(this._programInfo.uniformLocations.utexture, utextunit);
        this.gl.uniform1i(this._programInfo.uniformLocations.vtexture, vtextunit);
        this.gl.uniform1i(this._programInfo.uniformLocations.isyuv, isYUV ? 1 : 0);
        this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
    }

    _calRect(x, y, width, height, canvasWidth, canvasHeight) {

        let x1 = x * 2. / canvasWidth - 1.;
        let y1 = (canvasHeight - y - height) * 2. / canvasHeight - 1.;

        let x2 = (x + width) * 2. / canvasWidth - 1.;
        let y2 = (canvasHeight - y) * 2. / canvasHeight - 1.;

        return [x1, y1, x2, y1, x2, y2, x1, y2];
    }

    _clear() {
        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this.gl.clearDepth(1.0);
        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
    }

    render(width, height, y, u, v) {
        const gl = this.gl;
        this._clear();
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, this._ytexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width, height, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, y);
        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, this._utexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width / 2, height / 2, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, u);
        gl.activeTexture(gl.TEXTURE2);
        gl.bindTexture(gl.TEXTURE_2D, this._vtexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width / 2, height / 2, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, v);
        this._buffers.positions = [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0];
        this._drawScene(width, height, true)
    }

    renderYUV(width, height, data) {
        let y = data.slice(0, width * height);
        let u = data.slice(width * height, width * height * 5 / 4);
        let v = data.slice(width * height * 5 / 4, width * height * 3 / 2);
        const gl = this.gl;
        this._clear();
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, this._ytexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width, height, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, y);
        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, this._utexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width / 2, height / 2, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, u);
        gl.activeTexture(gl.TEXTURE2);
        gl.bindTexture(gl.TEXTURE_2D, this._vtexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, width / 2, height / 2, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, v);
        this._buffers.positions = [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0];
        this._drawScene(width, height, true)
    }

    drawDom(width, height, x, y, dom) {
        const gl = this.gl;
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, this._rgbatexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, dom);

        this._buffers.positions = this._calRect(x, y, dom.width, dom.height, width, height);
        this._drawScene(width, height, false);
    }
}
