import AudioContextLoader from "./audioContextLoader";
import AudioLoader from "./audioLoader";
import {PLAY_TYPE} from "../constant";
import AudioPlaybackLoader from "./audioPlaybackLoader";
import AudioPlayerLoader from "./audioPlayerLoader";
import {isFalse} from "../utils";

export default class Audio {
    constructor(player) {
        const Loader = Audio.getLoaderFactory(player._opt);
        return new Loader(player);
    }

    static getLoaderFactory(opt) {
        if (opt.playType === PLAY_TYPE.playbackTF) {
            if((opt.useMSE && opt.mseDecodeAudio)){
                return AudioLoader;
            }
            return AudioPlaybackLoader;
        } else if (opt.playType === PLAY_TYPE.playerAudio) {
            return AudioPlayerLoader;
        } else {
            if ((opt.isHls && isFalse(opt.supportHls265)) ||
                (opt.isWebrtc && isFalse(opt.isWebrtcH265)) ||
                (opt.useMSE && opt.mseDecodeAudio) ||
                (opt.isAliyunRtc)) {
                return AudioLoader;
            }
            return AudioContextLoader;
        }
    }
}
