function reduce(array) {
    return array.reduce(((e, t) => 256 * e + t))
}


function range(array) {
    const s = [101, 103, 119, 99];
    const a = 28;
    const t = array.length - a,
        r = array.slice(t, t + s.length);
    return s.every(((e, t) => e === r[t]))
}

class TransportDescrambler {
    constructor() {
        this.s = null;
        this.a = null;
        this.l = 0;
        this.c = 0;
        this.u = 1 / 0;
        this.A = false;
        this.d = false;
        this.r = 4194304;
        this.n = new Uint8Array([30, 158, 90, 33, 244, 57, 83, 165, 2, 70, 35, 87, 215, 231, 226, 108]);
        this.t = this.n.slice().reverse();
    }
    destroy() {
        this.s = null;
        this.a = null;
        this.l = 0;
        this.c = 0;
        this.u = 1 / 0;
        this.A = false;
        this.d = false;
        this.r = 4194304;
        this.n = null;
        this.t = null;
    }

    transport(buffer) {
        if (!this.s && this.l > 50) {
            return buffer;
        }
        this.l++;
        if (this.d) {
            return buffer;
        }
        const h = new Uint8Array(buffer);
        if (this.A) {
            if (!(this.c < this.u)) {
                if (this.a && this.s) {
                    this.a.set(h, this.r);
                    this.s.parse(null, this.r, h.byteLength);
                    return this.a.slice(this.r, this.r + h.byteLength);
                } else {
                    console.error("video_error_2");
                    this.d = true;
                    return buffer;
                }
            }
            if (range(h)) {
                this.c++;
            }
        } else {
            const r = function (e, t) {

                const r = function (e, t) {

                    for (let r = 0; r < e.byteLength - t.length; r++)
                        for (let n = 0; n < t.length && e[r + n] === t[n]; n++)
                            if (n === t.length - 1)
                                return r;
                    return null
                }(e, t);

                if (r) {
                    const t = reduce(e.slice(r + 16, r + 16 + 8));
                    return [
                        t,
                        reduce(e.slice(r + 24, r + 24 + 8)),
                        function (e) {
                            return e.map((e => ~e))
                        }(e.slice(r + 32, r + 32 + t))
                    ]
                }
                return null
            }(h, this.t);

            if (!r) {
                return buffer;
            }

            const l = function (e) {
                try {
                    if ("object" != typeof WebAssembly || "function" != typeof WebAssembly.instantiate) {
                        throw null;
                    }
                    {
                        const e = new WebAssembly.Module(Uint8Array.of(0, 97, 115, 109, 1, 0, 0, 0));
                        if (!(e instanceof WebAssembly.Module && new WebAssembly.Instance(e) instanceof WebAssembly.Instance))
                            throw null
                    }
                } catch (e) {
                    return new Error("video_error_4")
                }
                let t;
                try {
                    t = {
                        env: {
                            __handle_stack_overflow: () => e(new Error("video_error_1")),
                            memory: new WebAssembly.Memory({initial: 256, maximum: 256})
                        }
                    }
                } catch (e) {
                    return new Error("video_error_5")
                }
                return t
            }(buffer);

            if (l instanceof Error) {
                console.error(l.message);
                this.d = true;
                return buffer;
            }

            this.A = true;
            this.u = r[1];
            if (range(h)) {
                this.c++;
            }
            WebAssembly.instantiate(r[2], l).then((t => {
                if (!function (e) {
                    return "function" == typeof e.parse && "object" == typeof e.memory
                }(t.instance.exports)) {
                    this.d = true;
                    console.error('video_error_3')
                    return;
                }
                this.s = t.instance.exports;
                this.a = new Uint8Array(this.s.memory.buffer);
            })).catch((t => {
                this.d = true;
                console.error('video_error_6');
            }))
        }
        return buffer;
    }
}

export default TransportDescrambler;
