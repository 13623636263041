import Emitter from "../utils/emitter";
import {now} from "../utils";
import {PLAYER_PLAY_PROTOCOL, PLAYER_STREAM_TYPE} from "../constant";

export default class WorkerLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.workUrl = null;
        player.debug.log('WorkerStream', 'init');

    }

    destroy() {
        this.workUrl = null;
        this.off();
        this.player.debug.log('WorkerStream', 'destroy');
    }

    sendMessage(message) {
        this.player.decoderWorker.workerSendMessage(message);
    }

    fetchStream(url, options = {}) {
        this.workUrl = url;
        this.player._times.streamStart = now();
        this.player.decoderWorker.workerFetchStream(url);
    }

    getStreamType() {
        const protocol = this.player._opt.protocol;
        const streamType = protocol === PLAYER_PLAY_PROTOCOL.fetch ? PLAYER_STREAM_TYPE.fetch : PLAYER_STREAM_TYPE.websocket;
        return PLAYER_STREAM_TYPE.worker + ' ' + streamType;
    }
}
