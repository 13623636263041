import Emitter from "../utils/emitter";

const mp3FrameParseStats = {
    init: 0,
    findFirstStartCode: 1,
    findSecondStartCode: 2,
}

export default class Mp3FrameParseLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.isDestroyed = false;
        this.reset();
    }

    destroy() {
        this.isDestroyed = false;
        this.off();
        this.reset();
    }

    reset() {
        this.stats = mp3FrameParseStats.init;
        this.tempBuffer = new Uint8Array(0);
        this.parsedOffset = 0;
        this.versionLayer = 0;
    }

    dispatch(data, ts) {

        let newBuffer = new Uint8Array(this.tempBuffer.length + data.length);
        newBuffer.set(this.tempBuffer, 0);
        newBuffer.set(data, this.tempBuffer.length);


        this.tempBuffer = newBuffer;

        while (1) {
            if (this.isDestroyed) {
                break;
            }

            if (this.state == mp3FrameParseStats.Init) {
                let bf = false;
                while (this.tempBuffer.length - this.parsedOffset >= 2) {
                    if (this.isDestroyed) {
                        break;
                    }

                    // mp3帧头是连续11bit等于1
                    if (this.tempBuffer[this.parsedOffset] != 0xFF) {

                        this.parsedOffset++;
                        continue
                    }

                    if (this.tempBuffer[this.parsedOffset + 1] & 0xE0 != 0xE0) {


                        this.parsedOffset++;
                        continue
                    }
                    this.versionLayer = this.tempBuffer[this.parsedOffset + 1]
                    this.state = mp3FrameParseStats.findFirstStartCode
                    this.fisrtStartCodeOffset = this.parsedOffset
                    this.parsedOffset += 2
                    bf = true;
                    break;
                }

                if (bf) {
                    continue;
                } else {
                    break;
                }

            } else if (this.state == mp3FrameParseStats.findFirstStartCode) {

                let bf = false;
                while (this.tempBuffer.length - this.parsedOffset >= 2) {
                    if (this.isDestroyed) {
                        break;
                    }

                    if (this.tempBuffer[this.parsedOffset] != 0xFF) {

                        this.parsedOffset++;
                        continue
                    }

                    if (this.tempBuffer[this.parsedOffset + 1] != this.versionLayer) {

                        this.parsedOffset++;
                        continue
                    }

                    this.state = mp3FrameParseStats.findSecondStartCode
                    this.secondStartCodeOffset = this.parsedOffset
                    this.parsedOffset += 2
                    bf = true;
                    break;

                }

                if (bf) {
                    continue;

                } else {
                    break;
                }

            } else if (this.state == mp3FrameParseStats.findSecondStartCode) {

                let lastFrame = this.tempBuffer.slice(this.fisrtStartCodeOffset, this.secondStartCodeOffset)
                this.emit('data', lastFrame, ts);
                this.tempBuffer = this.tempBuffer.slice(this.secondStartCodeOffset)
                this.fisrtStartCodeOffset = 0
                this.parsedOffset = 2
                this.state = mp3FrameParseStats.findFirstStartCode
            }
        }
    }
}
