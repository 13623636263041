import {concatUint8Array} from "../hls/utils";
import {addNaleHeaderLength, addNaluStartCode, parseAnnexB, parseSEI, removeEPB} from "../utils/nalu";
import {AUDIO_ENC_CODE, EVENTS, MEDIA_TYPE, URL_OBJECT_CLEAR_TIME, VIDEO_ENC_CODE} from "../constant";
import {
    hevcEncoderConfigurationRecord$2,
    hevcEncoderNalePacket,
    hevcEncoderNalePacketNotLength,
    isNotHevcSeqHead
} from "../utils/h265";
import {
    parseADTS,
    getFrameDuration,
    getSilentFrame,
    aacEncoderConfigurationRecord,
    aacEncoderConfigurationRecordV2
} from "../utils/aac";
import {
    avcEncoderConfigurationRecord$2,
    avcEncoderNalePacket, avcEncoderNalePacketNotLength,
    isNotAvcSeqHead
} from "../utils/h264";
import CommonLoader from "./commonLoader";
import {calcStreamFpsByBufferList, function2String, isEmpty, isFalse, isSafari, isTrue, now} from "../utils";

const LARGE_AV_FIRST_FRAME_GAP = 90000 / 2 // 500ms
const AUDIO_GAP_OVERLAP_THRESHOLD_COUNT = 3
const MAX_SILENT_FRAME_DURATION = 90000 // 1s
const AUDIO_EXCETION_LOG_EMIT_DURATION = 5 * 90000 // 5s
const MAX_VIDEO_FRAME_DURATION = 90000 // 1s
const MAX_DTS_DELTA_WITH_NEXT_CHUNK = 90000 / 2 // 500ms


export default class TsLoader extends CommonLoader {

    constructor(player) {
        super(player);
        this.player = player;

        this._pmtId = -1;
        this._remainingPacketData = null;
        this._videoPesData = [];
        this._audioPesData = [];
        this._gopId = 0;
        this._videoPid = -1;
        this._audioPid = -1;
        this._codecType = VIDEO_ENC_CODE.h264;
        this._audioCodecType = AUDIO_ENC_CODE.AAC;
        this._vps = null;
        this._sps = null;
        this._pps = null;
        this.TAG_NAME = 'TsLoader';

        this.videoTrack = TsLoader.initVideoTrack();
        this.audioTrack = TsLoader.initAudioTrack();

        this._baseDts = -1;
        this._baseDtsInited = false;
        this._basefps = 25; // audio + video
        this._baseFpsInterval = null;
        this._tempSampleTsList = [];
        this._hasAudio = false;
        this._hasVideo = false;
        this._audioNextPts = undefined;
        this._videoNextDts = undefined;

        this._audioTimestampBreak = false;
        this._videoTimestampBreak = false;

        this._lastAudioExceptionGapDot = 0;
        this._lastAudioExceptionOverlapDot = 0;
        this._lastAudioExceptionLargeGapDot = 0;
        this._isSendAACSeqHeader = false;

        this.workerClearTimeout = null;
        this.workerUrl = null;
        this.loopWorker = null;
        this.tempSampleListInfo = {};

        if (!this.player.isUseMSE()) {
            this._initLoopWorker();
        }

        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();

        if (this.workerUrl) {
            URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
        }

        if (this.workerClearTimeout) {
            clearTimeout(this.workerClearTimeout);
            this.workerClearTimeout = null;
        }

        if (this.loopWorker) {
            this.loopWorker.postMessage({cmd: 'destroy'});
            this.loopWorker.terminate();
            this.loopWorker = null;
        }

        this._stopDecodeLoopInterval();

        this.videoTrack = null;
        this.audioTrack = null;
        this.tempSampleListInfo = {};

        this._baseDts = -1
        this._baseDtsInited = false
        this._basefps = 50;
        this._hasCalcFps = false;

        this._audioNextPts = undefined;
        this._videoNextDts = undefined;

        this._audioTimestampBreak = false
        this._videoTimestampBreak = false

        this._lastAudioExceptionGapDot = 0
        this._lastAudioExceptionOverlapDot = 0
        this._lastAudioExceptionLargeGapDot = 0

        this._isSendAACSeqHeader = false;
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    static initVideoTrack() {
        return {
            samples: []
        }
    }

    static initAudioTrack() {
        return {
            samples: []
        }
    }


    static probe(data) {
        if (!data.length) return false
        return data[0] === 0x47 && data[188] === 0x47 && data[376] === 0x47
    }

    static _parsePES(data) {
        const headerDataLen = data[8]
        if (headerDataLen === null || headerDataLen === undefined || data.length < (headerDataLen + 9)) return
        const startPrefix = data[0] << 16 | data[1] << 8 | data[2]
        if (startPrefix !== 1) return
        const pesLen = (data[4] << 8) + data[5]
        if (pesLen && pesLen > data.length - 6) return

        let pts
        let dts
        const ptsDtsFlags = data[7]
        if (ptsDtsFlags & 0xc0) {
            pts = (data[9] & 0x0e) * 536870912 +
                (data[10] & 0xff) * 4194304 +
                (data[11] & 0xfe) * 16384 +
                (data[12] & 0xff) * 128 +
                (data[13] & 0xfe) / 2

            if (ptsDtsFlags & 0x40) {
                dts = (data[14] & 0x0e) * 536870912 +
                    (data[15] & 0xff) * 4194304 +
                    (data[16] & 0xfe) * 16384 +
                    (data[17] & 0xff) * 128 +
                    (data[18] & 0xfe) / 2
                if (pts - dts > 60 * 90000) pts = dts
            } else {
                dts = pts
            }
        }

        return {
            data: data.subarray(9 + headerDataLen),
            pts: pts,
            dts: dts,
            originalPts: pts,
            originalDts: dts
        }
    }

    _demux(data, discontinuity = false, contiguous = true) {

        if (discontinuity) {
            this._pmtId = -1
            this.videoTrack = TsLoader.initVideoTrack();
            this.audioTrack = TsLoader.initAudioTrack();
        }

        if (!contiguous || discontinuity) {
            this._remainingPacketData = null
            this._videoPesData = []
            this._audioPesData = []
        } else {
            // 清空上次的pes数据
            this.videoTrack.samples = []
            this.audioTrack.samples = []
            if (this._remainingPacketData) {
                data = concatUint8Array(this._remainingPacketData, data)
                this._remainingPacketData = null
            }
        }


        let dataLen = data.length
        //
        const remainingLength = dataLen % 188
        //
        if (remainingLength) {
            //
            this._remainingPacketData = data.subarray(dataLen - remainingLength)
            //
            dataLen -= remainingLength
        }
        for (let start = 0; start < dataLen; start += 188) {
            if (data[start] !== 0x47) {
                throw new Error('TS packet did not start with 0x47')
            }
            const payloadUnitStartIndicator = !!(data[start + 1] & 0x40)
            const pid = ((data[start + 1] & 0x1f) << 8) + data[start + 2]
            const adaptationFiledControl = (data[start + 3] & 0x30) >> 4

            let offset
            if (adaptationFiledControl > 1) {
                offset = start + 5 + data[start + 4]
                if (offset === start + 188) continue
            } else {
                offset = start + 4
            }

            switch (pid) {
                case 0: // PAT
                    if (payloadUnitStartIndicator) {
                        offset += data[offset] + 1
                    }

                    this._pmtId = ((data[offset + 10] & 0x1f) << 8) | data[offset + 11]
                    break
                case this._pmtId: {
                    if (payloadUnitStartIndicator) {
                        offset += data[offset] + 1
                    }

                    const tableEnd = offset + 3 + (((data[offset + 1] & 0x0f) << 8) | data[offset + 2]) - 4
                    const programInfoLength = ((data[offset + 10] & 0x0f) << 8) | data[offset + 11]
                    offset += 12 + programInfoLength

                    while (offset < tableEnd) {
                        const esPid = ((data[offset + 1] & 0x1f) << 8) | data[offset + 2]
                        switch (data[offset]) {
                            case 0x0f: // AAC ADTS
                                //console.log('AAC ADTS pid is', esPid);
                                this._audioPid = esPid;
                                this._audioCodecType = AUDIO_ENC_CODE.AAC;
                                break
                            case 0x1b: // AVC
                                // console.log('AVC pid is', esPid);
                                this._videoPid = esPid;
                                this._codecType = VIDEO_ENC_CODE.h264
                                break
                            case 0x24: // HEVC
                                // console.log('HEVC pid is', esPid);
                                this._videoPid = esPid;
                                this._codecType = VIDEO_ENC_CODE.h265
                                break
                            default:
                                // console.warn(`Unsupported stream. type: ${data[offset]}, pid: ${esPid}`)
                                this.player.debug.warn(this.TAG_NAME, `Unsupported stream. type: ${data[offset]}, pid: ${esPid}`)
                                break;
                        }

                        offset += (((data[offset + 3] & 0x0f) << 8) | data[offset + 4]) + 5
                    }
                }
                    break
                case this._videoPid:
                    if (payloadUnitStartIndicator && this._videoPesData.length) {
                        this._parseVideoData()
                    }
                    this._videoPesData.push(data.subarray(offset, start + 188))
                    break
                case this._audioPid:
                    if (payloadUnitStartIndicator && this._audioPesData.length) {
                        this._parseAudioData()
                    }
                    this._audioPesData.push(data.subarray(offset, start + 188))
                    break
                case 17:
                case 0x1fff:
                    break
                default:
                    // console.warn(`Unknown pid: ${pid}`);
                    this.player.debug.warn(this.TAG_NAME, `Unknown pid: ${pid}`)
                    break;
            }
        }
        this._parseVideoData();
        this._parseAudioData();
        this.audioTrack.formatTimescale = this.videoTrack.formatTimescale = this.videoTrack.timescale = 90000
        this.audioTrack.timescale = this.audioTrack.sampleRate || 0

    }

    demuxAndFix(data, discontinuity, contiguous, startTime) {
        //
        if (!this.player._times.demuxStart) {
            this.player._times.demuxStart = now();
        }
        this._demux(data, discontinuity, contiguous, startTime);
        this._fix(startTime, discontinuity, contiguous);
    }

    _parseVideoData() {
        if (!this._videoPesData.length) {
            // console.log('_parseVideoData', 'no video pes data');
            this.player.debug.log(this.TAG_NAME, '_parseVideoData', 'no video pes data')
            return;
        }
        //
        const pes = TsLoader._parsePES(concatUint8Array(...this._videoPesData))
        if (!pes) {
            // console.warn('Cannot parse video pes', this._videoPesData)
            this.player.debug.warn(this.TAG_NAME, 'Cannot parse video pes', this._videoPesData)
            return
        }

        const units = parseAnnexB(pes.data);
        if (units) {
            this._createVideoSample(units, pes.pts, pes.dts)
        } else {
            this.player.debug.warn(this.TAG_NAME, 'Cannot parse avc units', pes)
        }

        this._videoPesData = []
    }

    _createVideoSample(units, pts, dts) {
        if (!units.length) {
            return;
        }
        const isHevc = this._codecType === VIDEO_ENC_CODE.h265;

        const videoSample = {
            isIFrame: false,
            type: MEDIA_TYPE.video,
            isHevc,
            vps: null,
            sps: null,
            pps: null,
            pts,
            dts,
            payload: null
        }

        units.forEach((unit) => {
            const type = isHevc ? (unit[0] >>> 1) & 0x3f : unit[0] & 0x1f;
            let isSps = false;
            let isPps = false;
            let isSei = false;
            let isVps = false;
            let isAud = false;
            switch (type) {
                case 5: // IDR
                case 16: // HEVC BLA_W_LP
                case 17: // HEVC BLA_W_RADL
                case 18: // HEVC BLA_N_LP
                case 19: // HEVC IDR_W_RADL
                case 20: // HEVC IDR_N_LP
                case 21: // HEVC CRA_NUT
                case 22: // HEVC RSV_IRAP_VCL22
                case 23: // HEVC RSV_IRAP_VCL23
                    if ((!isHevc && type !== 5) || (isHevc && type === 5)) {
                        break;
                    }
                    videoSample.isIFrame = true
                    this._gopId++
                    break
                case 6: // SEI
                case 39: // HEVC PREFIX_SEI
                case 40: // HEVC SUFFIX_SEI
                    if ((!isHevc && type !== 6) || (isHevc && type === 6)) {
                        break;
                    }
                    // todo sei信息
                    const sei = parseSEI(removeEPB(unit), isHevc);
                    isSei = true;

                    // if (this.player._opt.isEmitSEI) {
                    //     this.player.emit(EVENTS.videoSEI, {
                    //         ts: dts / 90,
                    //         data: unit
                    //     })
                    // }

                    // fix 分割nal之前只要sei信息被当做单独一个sample
                    return;
                case 32: // HEVC VPS
                    if (!isHevc) {
                        break
                    }
                    if (!videoSample.vps) {
                        videoSample.vps = unit;
                        isVps = true;
                    }
                    // todo vps信息
                    break;
                case 7: // SPS
                case 33: // HEVC SPS
                    if ((!isHevc && type !== 7) || (isHevc && type === 7)) {
                        break
                    }
                    if (!videoSample.sps) {
                        videoSample.sps = unit;
                        isSps = true;
                    }

                    // todo sps信息
                    //console.log('sps', unit);
                    break
                case 8: // PPS
                case 34: // HEVC PPS
                    if ((!isHevc && type !== 8) || (isHevc && type === 8)) {
                        break
                    }
                    if (!videoSample.pps) {
                        videoSample.pps = unit;
                        isPps = true;
                    }
                    // todo pps信息
                    break;
                case 9: // AUD
                case 35: // HEVC AUD
                    // todo: HEVC AUD
                    if ((!isHevc && type !== 9) || (isHevc && type === 9)) {
                        break
                    }
                    isAud = true;
                    break
                default:
                    // console.error('unknown NAL unit type', type);
                    break;
            }
            //  264/265 merge unit
            if ((isHevc && isNotHevcSeqHead(type)) ||
                (!isHevc && isNotAvcSeqHead(type))) {
                //  拼接
                const newUnit = addNaleHeaderLength(unit);
                if (!videoSample.payload) {
                    videoSample.payload = newUnit;
                } else {
                    //  merge
                    // this.player.debug.log(this.TAG_NAME, 'append nal unit to payload');
                    const newBuffer = new Uint8Array(videoSample.payload.byteLength + newUnit.byteLength);
                    newBuffer.set(videoSample.payload, 0);
                    newBuffer.set(newUnit, videoSample.payload.byteLength);
                    videoSample.payload = newBuffer;
                }
            }
        })

        let seqHeader = null;
        if (isHevc) {
            if (videoSample.sps && videoSample.vps && videoSample.pps) {
                seqHeader = hevcEncoderConfigurationRecord$2({
                    vps: videoSample.vps,
                    sps: videoSample.sps,
                    pps: videoSample.pps,
                });
            }
        } else {
            if (videoSample.sps && videoSample.pps) {
                seqHeader = avcEncoderConfigurationRecord$2({
                    sps: videoSample.sps,
                    pps: videoSample.pps,
                });
            }
        }

        if (seqHeader) {
            this.player.debug.log(this.TAG_NAME, '_createVideoSample', 'seqHeader');
            this._doDecodeByHls(seqHeader, MEDIA_TYPE.video, Math.round(videoSample.pts / 90), true, 0);
        }

        if (videoSample.isIFrame) {
            this.calcIframeIntervalTimestamp(Math.round(videoSample.dts / 90));
        }


        this.videoTrack.samples = this.videoTrack.samples.concat(videoSample);
    }

    _parseAudioData() {
        if (!this._audioPesData.length) return
        const pes = TsLoader._parsePES(concatUint8Array(...this._audioPesData))
        if (!pes) {
            // console.warn('Cannot parse audio pes', this._audioPesData);
            this.player.debug.warn(this.TAG_NAME, 'Cannot parse audio pes', this._audioPesData)
            return
        }

        if (!this.player._opt.hasAudio) {
            return;
        }

        // console.log('_parseAudioData', pes.dts, pes.pts);
        if (this._audioCodecType === AUDIO_ENC_CODE.AAC) {
            const ret = parseADTS(pes.data, pes.originalPts);
            if (ret) {

                this.audioTrack.codec = ret.codec;
                this.audioTrack.sampleRate = ret.sampleRate;
                this.audioTrack.channelCount = ret.channelCount;
                if (!this._isSendAACSeqHeader) {
                    // 先要取 accADTSHeader = [];
                    const accADTSHeader = aacEncoderConfigurationRecordV2({
                        profile: ret.objectType,
                        sampleRate: ret.samplingFrequencyIndex,
                        channel: ret.channelCount,
                    });
                    this._isSendAACSeqHeader = true;
                    this.player.debug.log(this.TAG_NAME, 'aac seq header', `profile: ${ret.objectType}, sampleRate:${ret.sampleRate},sampleRateIndex: ${ret.samplingFrequencyIndex}, channel: ${ret.channelCount}`);
                    this._doDecodeByHls(accADTSHeader, MEDIA_TYPE.audio, 0, false, 0)
                }
                if (this._isSendAACSeqHeader) {
                    const audioSampleList = [];
                    ret.frames.forEach((s) => {
                        const pts = s.pts;
                        const arrayBuffer = new Uint8Array(s.data.length + 2);
                        arrayBuffer.set([0xAF, 0x01], 0);
                        arrayBuffer.set(s.data, 2);
                        const audioSample = {
                            type: MEDIA_TYPE.audio,
                            pts,
                            dts: pts,
                            payload: arrayBuffer
                        }
                        audioSampleList.push(audioSample);
                    })

                    this.audioTrack.samples = this.audioTrack.samples.concat(audioSampleList);
                } else {
                    this.player.debug.warn(this.TAG_NAME, `aac seq header not send`);
                }
            } else {
                this.player.debug.warn(this.TAG_NAME, `aac parseADTS error`);
            }
        } else {
            // g711a/g711u
        }

        this._audioPesData = []
    }

    _fix(startTime = 0, discontinuity = false, contiguous = true) {
        startTime = Math.round(startTime * 90000)
        const videoTrack = this.videoTrack
        const audioTrack = this.audioTrack

        const vSamples = videoTrack.samples
        const aSamples = audioTrack.samples

        if (!vSamples.length && !aSamples.length) {
            return
        }

        const firstVideoSample = vSamples[0]
        const firstAudioSample = aSamples[0]

        // consider av delta
        let vaDelta = 0

        if (vSamples.length && aSamples.length) {
            vaDelta = firstVideoSample.dts - firstAudioSample.pts
        }

        if (!this._baseDtsInited) {
            this._calculateBaseDts()
        }
        // recalc baseDts
        if (discontinuity) {
            this._calculateBaseDts()
            this._baseDts -= startTime
        }

        if (!contiguous) {
            /**
             *  segment.start = min(a, v)
             *  segment.start
             *      |
             *      a
             *       -- vaDelta --
             *                   v
             */
            this._videoNextDts = vaDelta > 0 ? startTime + vaDelta : startTime
            this._audioNextPts = vaDelta > 0 ? startTime : startTime - vaDelta

            const vDeltaToNextDts = firstVideoSample ? firstVideoSample.dts - this._baseDts - this._videoNextDts : 0
            const aDeltaToNextDts = firstAudioSample ? firstAudioSample.pts - this._baseDts - this._audioNextPts : 0

            //  90000 = 1s
            if (Math.abs(vDeltaToNextDts || aDeltaToNextDts) > MAX_VIDEO_FRAME_DURATION) {
                this._calculateBaseDts(this.audioTrack, this.videoTrack)
                this._baseDts -= startTime
            }
        }

        this._resetBaseDtsWhenStreamBreaked()

        // fix audio first
        this._fixAudio(audioTrack)
        this._fixVideo(videoTrack)

        let allSampleList = videoTrack.samples.concat(audioTrack.samples);
        //  sort
        allSampleList = allSampleList.map((sample) => {
            sample.dts = Math.round(sample.dts / 90);
            sample.pts = Math.round(sample.pts / 90);
            sample.cts = sample.pts - sample.dts;
            return sample;
        }).sort((a, b) => {
            return a.dts - b.dts;
        })

        allSampleList.forEach((sample) => {
            const arrayBuffer = new Uint8Array(sample.payload);
            delete sample.payload;
            if (this.player.isUseMSE()) {

                if (sample.type === MEDIA_TYPE.video) {
                    //   直接解码
                    this._doDecodeVideo({
                        ...sample,
                        payload: arrayBuffer,
                    })
                } else if (sample.type === MEDIA_TYPE.audio) {
                    this._doDecodeAudio({
                        ...sample,
                        payload: arrayBuffer,
                    })
                }

            } else {
                this.loopWorker.postMessage({
                    ...sample,
                    payload: arrayBuffer,
                    cmd: 'sample',
                }, [arrayBuffer.buffer]);
            }
        })


        if (isFalse(this._hasCalcFps)) {
            this._hasCalcFps = true;
            this._calcDecodeFps(allSampleList);
        }

        // todo 如果缓存数据超过一定量，需要清理，防止延迟过高
    }

    _calculateBaseDts() {
        const audioTrack = this.audioTrack;
        const videoTrack = this.videoTrack;
        const audioSamps = audioTrack.samples
        const videoSamps = videoTrack.samples
        if (!audioSamps.length && !videoSamps.length) {
            return false
        }

        let audioBasePts = Infinity
        let videoBaseDts = Infinity

        if (audioSamps.length) {
            audioTrack.baseDts = audioBasePts = audioSamps[0].pts
        }

        if (videoSamps.length) {
            videoTrack.baseDts = videoBaseDts = videoSamps[0].dts
        }

        this._baseDts = Math.min(audioBasePts, videoBaseDts)

        const delta = videoBaseDts - audioBasePts


        if (Number.isFinite(delta) && Math.abs(delta) > LARGE_AV_FIRST_FRAME_GAP) {
            // warn: LARGE_AV_SHIFT
            this.player.debug.warn(this.TAG_NAME, `large av first frame gap,
                video pts: ${videoBaseDts},
                audio pts: ${audioBasePts},
                base dts: ${this._baseDts},
                detect is: ${delta}`)
        }

        this._baseDtsInited = true
        return true
    }

    _resetBaseDtsWhenStreamBreaked() {
        if (this._baseDtsInited && this._videoTimestampBreak && this._audioTimestampBreak) {
            /**
             * timestamp breaked
             *                     _audioNextDts
             *  ---------------------|
             * (_baseDts)          _videoNextDts
             * ----------------------|
             *                        <----------------
             *                                       nextVideo.dts
             * ----------------------------------------|
             *                                       nextAudio.dts
             * ---------------------------------------|
             */

                // calc baseDts base on new samples
            const calc = this._calculateBaseDts(this.audioTrack, this.videoTrack)

            if (!calc) return

            // consider the expect dts for next frame
            this._baseDts -= Math.min(this._audioNextPts, this._videoNextDts)
            this._audioLastSample = null
            this._videoLastSample = null
            this._videoTimestampBreak = false
            this._audioTimestampBreak = false
        }
    }

    _fixAudio(audioTrack) {
        const samples = audioTrack.samples

        if (!samples.length) return
        samples.forEach(x => {
            x.pts -= this._baseDts
            x.dts = x.pts
        })

        this._doFixAudioInternal(audioTrack, samples, 90000)
    }

    _fixVideo(videoTrack) {
        const samples = videoTrack.samples

        if (!samples.length) return
        samples.forEach(x => {
            x.dts -= this._baseDts
            x.pts -= this._baseDts
        })
        if (this._videoNextDts === undefined) {
            const samp0 = samples[0]
            this._videoNextDts = samp0.dts
        }

        const len = samples.length
        let sampleDuration = 0
        const firstSample = samples[0]
        const nextSample = samples[1]
        const vDelta = this._videoNextDts - firstSample.dts
        //  90000 / 2 // 500ms
        if (Math.abs(vDelta) > MAX_DTS_DELTA_WITH_NEXT_CHUNK) {
            // resolve first frame first
            firstSample.dts += vDelta
            firstSample.pts += vDelta

            this.player.debug.warn(this.TAG_NAME, `large video gap between chunk,
             next dts is ${this._videoNextDts},
             first dts is ${firstSample.dts},
             next dts is ${nextSample.dts},
             duration is ${vDelta}`);

            // check to ajust the whole segment
            // // 1s
            if (nextSample && Math.abs(nextSample.dts - firstSample.dts) > MAX_VIDEO_FRAME_DURATION) {
                this._videoTimestampBreak = true
                samples.forEach((x, i) => {
                    if (i === 0) return
                    x.dts += vDelta
                    x.pts += vDelta
                })
            }
        }

        let refSampleDurationInt
        const first = videoTrack.samples[0]
        const last = videoTrack.samples[len - 1]
        // 100ms default
        refSampleDurationInt = len === 1 ? 9000 : Math.floor((last.dts - first.dts) / (len - 1))

        for (let i = 0; i < len; i++) {
            const dts = samples[i].dts
            const nextSample = samples[i + 1]
            if (i < len - 1) {
                sampleDuration = nextSample.dts - dts
            } else if (samples[i - 1]) {
                sampleDuration = Math.min(dts - samples[i - 1].dts, refSampleDurationInt)
            } else {
                sampleDuration = refSampleDurationInt
            }

            if (sampleDuration > MAX_VIDEO_FRAME_DURATION || sampleDuration < 0) {
                // dts exception of adjacent frame
                this._videoTimestampBreak = true

                // check if only video breaked!
                sampleDuration = this._audioTimestampBreak ? refSampleDurationInt : Math.max(sampleDuration, 30 * 90) // 30ms

                // check if sample breaked within current fragment
                const expectFragEnd = (this._audioNextPts || 0)
                if (nextSample && nextSample.dts > expectFragEnd) {
                    sampleDuration = refSampleDurationInt
                }
                // todo: LARGE_VIDEO_GAP
                this.player.debug.warn(this.TAG_NAME, `large video gap between frames,
                time is ${dts / videoTrack.timescale},
                dts is ${dts},
                origin dts is ${samples[i].originalDts},
                next dts is ${this._videoNextDts},
                sample Duration is ${sampleDuration} ,
                ref Sample DurationInt is ${refSampleDurationInt}`);
            }

            samples[i].duration = sampleDuration
            this._videoNextDts += sampleDuration
        }
    }

    _doFixAudioInternal(audioTrack, samples, timescale) {
        if (!audioTrack.sampleDuration) {
            audioTrack.sampleDuration = getFrameDuration(audioTrack.timescale, timescale)
        }

        const refSampleDuration = audioTrack.sampleDuration

        if (this._audioNextPts === undefined) {
            const samp0 = samples[0]
            this._audioNextPts = samp0.pts
        }

        for (let i = 0; i < samples.length; i++) {

            const nextPts = this._audioNextPts

            const sample = samples[i]

            const delta = sample.pts - nextPts

            // fill frames
            // delta >= 3 * refSampleDurationInt
            // delta <= 500s
            if (!this._audioTimestampBreak &&
                delta >= AUDIO_GAP_OVERLAP_THRESHOLD_COUNT * refSampleDuration &&
                delta <= MAX_SILENT_FRAME_DURATION && !isSafari()) {

                const silentFrame = getSilentFrame(audioTrack.codec, audioTrack.channelCount) || samples[0].data.subarray()

                const count = Math.floor(delta / refSampleDuration)

                if (Math.abs(sample.pts - this._lastAudioExceptionGapDot) > AUDIO_EXCETION_LOG_EMIT_DURATION) {
                    this._lastAudioExceptionGapDot = sample.pts
                }
                // todo:WarningType.AUDIO_FILLED
                this.player.debug.warn(this.TAG_NAME, `audio gap detected,
                pts is ${samples.pts},
                originPts is ${samples.originalPts},
                count is ${count},
                nextPts is ${nextPts},
                ref sample duration is ${refSampleDuration}`)

                for (let j = 0; j < count; j++) {
                    // const silentSample = new AudioSample(Math.floor(nextPts), silentFrame)
                    // silentSample.originPts = Math.floor(this._baseDts + nextPts)
                    // samples.splice(i, 0, silentSample)
                    this._audioNextPts += refSampleDuration
                    i++
                }

                i--
                // delta  <= -3 * refSampleDurationInt
                // delta  >= -500ms
            } else if (delta <= -AUDIO_GAP_OVERLAP_THRESHOLD_COUNT * refSampleDuration && delta >= -1 * MAX_SILENT_FRAME_DURATION) {
                // need discard frames
                if (Math.abs(sample.pts - this._lastAudioExceptionOverlapDot) > AUDIO_EXCETION_LOG_EMIT_DURATION) {
                    this._lastAudioExceptionOverlapDot = sample.pts
                    // todo:AUDIO_DROPPED
                    this.player.debug.warn(this.TAG_NAME, `audio overlap detected,
                    pts is ${sample.pts},
                    originPts is ${sample.originalPts},
                    nextPts is ${nextPts},
                    ref sample duration is ${refSampleDuration}`);
                }
                samples.splice(i, 1)
                i--
            } else {
                if (Math.abs(delta) >= MAX_SILENT_FRAME_DURATION) {
                    this._audioTimestampBreak = true

                    if (Math.abs(sample.pts - this._lastAudioExceptionLargeGapDot) > AUDIO_EXCETION_LOG_EMIT_DURATION) {
                        this._lastAudioExceptionLargeGapDot = sample.pts
                        // todo: LARGE_AUDIO_GAP
                        this.player.debug.warn(this.TAG_NAME, `large audio gap detected,
                        time is ${sample.pts / 1000}
                        pts is ${sample.pts},
                        originPts is ${sample.originalPts},
                        nextPts is ${nextPts},
                        sample duration is ${delta}
                        ref sample duration is ${refSampleDuration}`)
                    }
                }

                sample.dts = sample.pts = nextPts
                this._audioNextPts += refSampleDuration
            }
        }
    }

    _calcDecodeFps(sampleList) {

        const _tempSampleTsList = sampleList.map((sample) => {
            return {
                ts: sample.dts || sample.pts,
                type: sample.type
            }
        })

        const streamVideoFps = calcStreamFpsByBufferList(_tempSampleTsList, MEDIA_TYPE.video);

        if (streamVideoFps) {
            this._basefps = streamVideoFps;
            this._postMessageToLoopWorker('updateBaseFps', {
                baseFps: this._basefps
            });

            this.player.debug.log(this.TAG_NAME, `_calcDecodeFps()  video fps is ${streamVideoFps}, update base fps is ${this._basefps}`)
        }
    }

    _initLoopWorker() {
        this.player.debug.log(this.TAG_NAME, '_initLoopWorker()');
        //  worker fun
        //  worker 里面跑interval
        function LoopWorkerFun() {

            const MEDIA_TYPE = {
                audio: 1,
                video: 2
            }

            class LoopWorker {
                constructor() {
                    this.baseFps = 0;
                    this.fpsInterval = null;
                    this.preLoopTimestamp = null;
                    this.startBpsTime = null;
                    this.allSampleList = [];
                }

                destroy() {
                    this._clearInterval();
                    this.baseFps = 0;
                    this.allSampleList = [];
                    this.preLoopTimestamp = null;
                    this.startBpsTime = null;
                }

                updateBaseFps(baseFps) {
                    this.baseFps = baseFps;
                    this._clearInterval();
                    this._startInterval();
                }

                pushSample(sample) {
                    delete sample.cmd;
                    this.allSampleList.push(sample);
                }

                _startInterval() {
                    const fragDuration = Math.ceil(1000 / this.baseFps);
                    this.fpsInterval = setInterval(() => {
                        let nowTime = new Date().getTime()
                        if (!this.preLoopTimestamp) {
                            this.preLoopTimestamp = nowTime;
                        }
                        if (!this.startBpsTime) {
                            this.startBpsTime = nowTime;
                        }

                        const diffTime = nowTime - this.preLoopTimestamp;
                        if (diffTime > (fragDuration * 2)) {
                            console.warn(`JbPro:[TsLoader LoopWorker] loop interval is ${diffTime}ms, more than ${fragDuration} * 2ms`)
                        }
                        this._loop();
                        this.preLoopTimestamp = new Date().getTime();


                        if (this.startBpsTime) {
                            const timestamp = nowTime - this.startBpsTime;

                            if (timestamp >= 1000) {
                                this._calcSampleList();
                                this.startBpsTime = nowTime;
                            }
                        }

                    }, fragDuration)
                }

                _clearInterval() {
                    if (this.fpsInterval) {
                        clearInterval(this.fpsInterval);
                        this.fpsInterval = null;
                    }
                }

                _calcSampleList() {
                    const tempObj = {
                        buferredDuration: 0,
                        allListLength: this.allSampleList.length,
                        audioListLength: 0,
                        videoListLength: 0
                    }

                    this.allSampleList.forEach((sample) => {
                        if (sample.type === MEDIA_TYPE.video) {
                            tempObj.videoListLength++;
                            if (sample.duration) {
                                tempObj.buferredDuration += Math.round(sample.duration / 90);
                            }
                        } else if (sample.type === MEDIA_TYPE.audio) {
                            tempObj.audioListLength++;
                        }
                    })

                    postMessage({
                        cmd: 'sampleListInfo',
                        ...tempObj
                    })

                }

                _loop() {
                    let sample = null;
                    if (this.allSampleList.length) {
                        sample = this.allSampleList.shift();
                        if (sample.type === MEDIA_TYPE.video) {
                            postMessage({
                                cmd: 'decodeVideo',
                                ...sample
                            }, [sample.payload.buffer])
                            //  check next is audio
                            let tempSample = this.allSampleList[0];
                            //  这边是检查了所有的audio
                            while (tempSample && tempSample.type === MEDIA_TYPE.audio) {
                                sample = this.allSampleList.shift();
                                postMessage({
                                    cmd: 'decodeAudio',
                                    ...sample
                                }, [sample.payload.buffer])
                                tempSample = this.allSampleList[0];
                            }

                        } else if (sample.type === MEDIA_TYPE.audio) {
                            postMessage({
                                cmd: 'decodeAudio',
                                ...sample
                            }, [sample.payload.buffer])
                            // check next is video
                            //  todo:这个就检查了一个。
                            if (this.allSampleList.length && this.allSampleList[0].type === MEDIA_TYPE.video) {
                                sample = this.allSampleList.shift();
                                postMessage({
                                    cmd: 'decodeVideo',
                                    ...sample
                                }, [sample.payload.buffer]);
                            }
                        }
                    }
                }
            }

            let loopWorker = new LoopWorker();
            self.onmessage = (e) => {
                const msg = e.data;
                switch (msg.cmd) {
                    case 'updateBaseFps':
                        loopWorker.updateBaseFps(msg.baseFps);
                        break;
                    case 'sample':
                        loopWorker.pushSample(msg);
                        break;
                    case 'destroy':
                        loopWorker.destroy();
                        loopWorker = null;
                        break;
                    default:
                        break;
                }
            }
        }

        const loopWorkerString = function2String(LoopWorkerFun.toString());

        const blob = new Blob([loopWorkerString], {type: "text/javascript"});
        const workerUrl = URL.createObjectURL(blob);
        let loopWorker = new Worker(workerUrl);
        this.workerUrl = workerUrl;
        // 必须要释放，不然每次调用内存都明显泄露内存
        // chrome 83 file协议下如果直接释放，将会使WebWorker无法启动
        this.workerClearTimeout = setTimeout(() => {
            window.URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
            this.workerClearTimeout = null;
        }, URL_OBJECT_CLEAR_TIME)

        loopWorker.onmessage = (event) => {
            const msg = event.data;
            switch (msg.cmd) {
                case 'decodeVideo':
                    this._doDecodeVideo(msg);
                    break;
                case 'decodeAudio':
                    this._doDecodeAudio(msg);
                    break;
                case 'sampleListInfo':
                    this.tempSampleListInfo = msg;
                    break;
                default:
                    break;
            }
        }

        this.loopWorker = loopWorker;
    }

    _postMessageToLoopWorker(cmd, options) {

        if (this.player.isUseMSE()) {
            return;
        }

        if (this.loopWorker) {
            this.loopWorker.postMessage({
                cmd,
                ...options
            })
        } else {
            this.player.debug.warn(this.TAG_NAME, `loop worker is not init, can not post message`);
        }
    }

    _doDecodeAudio(sample) {
        const uint8Array = new Uint8Array(sample.payload);
        this.player.updateStats({
            abps: uint8Array.byteLength,
        });
        let payloadBuffer = uint8Array;
        if (isTrue(this.player._opt.m7sCryptoAudio)) {
            payloadBuffer = this.cryptoPayloadAudio(uint8Array);
        }
        this._doDecodeByHls(payloadBuffer, MEDIA_TYPE.audio, sample.dts, false, 0);
    }

    _doDecodeVideo(sample) {
        const uint8Array = new Uint8Array(sample.payload);
        let packet = null;
        if (sample.isHevc) {
            //  add 5 header
            packet = hevcEncoderNalePacketNotLength(uint8Array, sample.isIFrame);
        } else {
            packet = avcEncoderNalePacketNotLength(uint8Array, sample.isIFrame);
        }
        this.player.updateStats({
            dts: sample.dts,
            vbps: packet.byteLength,
        });

        const cts = sample.pts - sample.dts;

        let payloadBuffer = this.cryptoPayload(packet, sample.isIFrame);

        this._doDecodeByHls(payloadBuffer, MEDIA_TYPE.video, sample.dts, sample.isIFrame, cts);
    }

    _stopDecodeLoopInterval() {
        if (this._baseFpsInterval) {
            clearInterval(this._baseFpsInterval)
            this._baseFpsInterval = null;
        }
    }

    getBuferredDuration() {
        return this.tempSampleListInfo.buferredDuration || 0;
    }

    getSampleListLength() {
        return this.tempSampleListInfo.allListLength || 0;
    }

    getSampleAudioListLength() {

        return this.tempSampleListInfo.audioListLength || 0;
    }

    getSampleVideoListLength() {
        return this.tempSampleListInfo.videoListLength || 0;
    }
}
