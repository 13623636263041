import {EVENTS, PLAYER_NAME} from "../constant";
import SingleWatermark from "./singleWatermark";
import {isFalse, isRelease} from "./index";

/**
 * 注意：
 * 1. 水印移动范围为实际视频显示区域，如果视频自带黑边，播放器无法进行规避。
 * 2. 在使用动态水印功能时，播放器对象的引用不能暴露到全局环境，否则动态水印可以轻易去除。
 * 3. 动态水印不适合移动端场景，特别是劫持播放的场景。常见问题：https://cloud.tencent.com/document/product/881/20219
 * 4. 可以通过屏蔽全屏按钮，规避部分全屏后被劫持导致水印失效的情况。
 */
export default class DynamicWatermark extends SingleWatermark {
    constructor(player, options = {}) {
        super(player);
        this.TAG_NAME = "DynamicWatermark";
        this.isPauseAnimation = false;
        this.isStopAnimation = false;
        this.rafID = null;
        this.speed = options.speed || 0.2;
        this.isDynamic = true; // 是否开启动态水印
        this.shadowRootDynamicDom = null;
        this.isGhost = options.isGhost === true ? true : false; // 是否开启幽灵模式
        this.ghostConfig = {
            on: options.on || 5, // 幽灵模式开启时间
            off: options.off || 5, // 幽灵模式关闭时间
        }
        this.waterMarkWillRemove = false;
        this.waterMarkWillAdd = false;

        this.player.once(EVENTS.start, () => {
            const content = isFalse(isRelease) ? decodeURIComponent(PLAYER_NAME + '%20%E4%BD%93%E9%AA%8C') : options.content;
            let videoInfo = this.player.getVideoInfo();
            if (!videoInfo) {
                videoInfo = {
                    width: 200,
                    height: 200
                }
            }
            this.update({
                text: {
                    content: content,
                    fontSize: options.fontSize || 18,
                    color: options.color || 'white',
                },
                // 这里可以获取到播放器的宽和高
                left: videoInfo.width * Math.random(),
                top: videoInfo.height * Math.random(),
                opacity: options.opacity || 0.15,
            })
            this.startAnimation();
        })

        this.player.debug.log(this.TAG_NAME, 'int');
    }

    destroy() {
        super.destroy();
        if (this.rafID) {
            cancelAnimationFrame(this.rafID);
            this.rafID = null;
        }
    }

    startAnimation() {
        if (!this.rafID) {
            // 垂直方向
            let columnArrow = 1;
            // 水平方向
            let levelArrow = 1;

            let random = Math.random();
            let markContentElementWidthAndHeight = {
                width: 0,
                height: 0,
            }

            const animation = () => {
                try {
                    if (isFalse(this.isPauseAnimation)) {

                        if (this.shadowRootDynamicDom &&
                            this.shadowRootInnerDom) {
                            const containerElement = this.shadowRootInnerDom;
                            const markContentElement = this.shadowRootDynamicDom;
                            const containerElementRect = containerElement.getBoundingClientRect();
                            const markContentElementRect = markContentElement.getBoundingClientRect();
                            if (markContentElementRect.width && markContentElementRect.height) {
                                markContentElementWidthAndHeight.width = markContentElementRect.width;
                                markContentElementWidthAndHeight.height = markContentElementRect.height;
                            }

                            if (!this.shadowRootInnerDom.contains(this.shadowRootDynamicDom)) {

                                if (this.isGhost) {
                                    //  对于幽灵模式，如果幽灵模式开启时，水印被移除，需要重新添加
                                    if (isFalse(this.waterMarkWillAdd)) {
                                        this.waterMarkWillAdd = true;
                                        setTimeout(() => {
                                            this._addDom(containerElementRect, markContentElementWidthAndHeight);
                                            this.waterMarkWillAdd = false;
                                        }, 1000 * this.ghostConfig.off)
                                    }
                                } else {
                                    this._addDom(containerElementRect, markContentElementWidthAndHeight);
                                }


                                if (this.speed !== 0) {
                                    requestAnimationFrame(animation);
                                }
                                return;
                            }

                            const speed = Math.min(1, 0 === this.speed ? 0 : this.speed ? this.speed : .2);

                            let offsetLeft = markContentElementRect.left - containerElementRect.left;
                            let offsetTop = markContentElementRect.top - containerElementRect.top;
                            offsetLeft += speed * levelArrow * random;
                            offsetTop += speed * columnArrow * (1 - random);
                            if (offsetLeft + markContentElementWidthAndHeight.width > containerElementRect.width) {
                                levelArrow = -1;
                                random = Math.random();
                            } else if (offsetLeft < 0) {
                                levelArrow = 1;
                                random = Math.random();
                            }
                            if (offsetTop + markContentElementWidthAndHeight.height > containerElementRect.height) {
                                columnArrow = -1;
                                random = Math.random();
                            } else if (offsetTop < 0) {
                                columnArrow = 1;
                                random = Math.random();
                            }

                            offsetLeft = Math.min(containerElementRect.width - markContentElementWidthAndHeight.width, offsetLeft);
                            offsetTop = Math.min(containerElementRect.height - markContentElementWidthAndHeight.height, offsetTop);
                            const contentLeft = offsetLeft / containerElementRect.width * 100;
                            const contentTop = offsetTop / containerElementRect.height * 100;
                            this.shadowRootDynamicDom.style.left = `${contentLeft}%`;
                            this.shadowRootDynamicDom.style.top = `${contentTop}%`;

                            if (isFalse(this.waterMarkWillRemove) && this.isGhost) {
                                this.waterMarkWillRemove = true;
                                setTimeout(() => {
                                    this._removeDom();
                                    this.waterMarkWillRemove = false;
                                }, 1000 * this.ghostConfig.on)
                            }
                        }


                    }
                } catch (e) {

                }

                if (this.isStopAnimation) {
                    this.isStopAnimation = false;
                    cancelAnimationFrame(this.rafID);
                    this.rafID = null;
                    return;
                }

                if (this.speed !== 0) {
                    requestAnimationFrame(animation);
                }
            }
            this.rafID = requestAnimationFrame(animation);
        }
    }

    _addDom(containerElementRect, markContentElementWidthAndHeight) {
        if (this.shadowRootInnerDom && this.shadowRootDynamicDom) {
            this.shadowRootInnerDom.appendChild(this.shadowRootDynamicDom);
            // 需要更新下位置
            let offsetLeft = containerElementRect.width * Math.random();
            let offsetTop = containerElementRect.height * Math.random();
            offsetLeft = Math.min(containerElementRect.width - (markContentElementWidthAndHeight.width * 2), offsetLeft);
            offsetTop = Math.min(containerElementRect.height - (markContentElementWidthAndHeight.height * 2), offsetTop);
            this.shadowRootDynamicDom.style.left = `${offsetLeft}px`;
            this.shadowRootDynamicDom.style.top = `${offsetTop}px`;
        }
    }


    resumeAnimation() {
        this.isPauseAnimation = false;
    }

    pauseAnimation() {
        this.isPauseAnimation = true;
    }

    stopAnimation() {
        this.isStopAnimation = true;
    }
}


