import {ERR, StreamingError} from "../../error";
import {concatUint8Array} from "../../utils";
import TsDemuxer from '../../../demux/tsLoader';
import HlsFmp4Loader from "../../../demux/hlsFmp4Loader";
import {EVENTS_ERROR} from "../../../constant";

export default class Transmuxer {


    constructor(hls, isMP4) {

        this.hls = hls
        this.player = this.hls.player;
        this.isMP4 = isMP4

        this._initSegmentId = ''
        this.TAG_NAME = 'HlsTransmuxer'
        this.hlsV2Mp4NotSupport = false;

        if (isMP4) {
            this._demuxer = new HlsFmp4Loader(this.hls.player);
        } else {
            this._demuxer = new TsDemuxer(this.hls.player)
        }

        this.player.debug.log(this.TAG_NAME, `init and isMP4 is ${isMP4}`);
    }

    destroy() {
        this.hlsV2Mp4NotSupport = false;
        if (this._demuxer) {
            this._demuxer.destroy();
            this._demuxer = null;
        }
    }

    transmux(videoChunk, audioChunk, discontinuity, contiguous, startTime, needInit) {
        this.player.debug.log(this.TAG_NAME, `transmux videoChunk:${videoChunk && videoChunk.byteLength}, audioChunk:${audioChunk && audioChunk.byteLength}, discontinuity:${discontinuity}, contiguous:${contiguous}, startTime:${startTime}, needInit:${needInit}`);
        const demuxer = this._demuxer
        try {
            if (this.isMP4) {

                demuxer.demux(videoChunk, audioChunk);
                // if (this.hlsV2Mp4NotSupport) {
                //     return;
                // }
                // // todo : 想下如何解决。
                // this.player.debug.warn(this.TAG_NAME, 'transmuxer isMP4');
                //
                // // 先降级到低版本去支持。
                // this.player.emitError(EVENTS_ERROR.hlsV2Mp4NotSupport);
                // this.hlsV2Mp4NotSupport = true;
            } else {
                demuxer.demuxAndFix(concatUint8Array(videoChunk, audioChunk), discontinuity, contiguous, startTime)
            }

        } catch (error) {
            throw new StreamingError(ERR.DEMUX, ERR.HLS, error)
        }
    }
}
