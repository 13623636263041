import Emitter from "../utils/emitter";
import {calculationRate, now} from "../utils";
import {EVENTS, PLAYER_STREAM_TYPE} from "../constant";

export default class WebTransportLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.transport = null;
        this.wtUrl = null;

        //
        this.streamRate = calculationRate(rate => {
            player.emit(EVENTS.kBps, (rate / 1024).toFixed(2));
        });
        this.streamRateInterval = null;
        player.debug.log('WebTransportLoader', 'init');
    }

    destroy() {
        this.abort();
        this.off();
        this.player.debug.log('WebTransportLoader', 'destroy');
    }

    startStreamRateInterval() {
        this.stopStreamRateInterval();
        this.streamRateInterval = setInterval(() => {
            this.streamRate && this.streamRate(0)
        }, 1000)
    }

    stopStreamRateInterval() {
        if (this.streamRateInterval) {
            clearInterval(this.streamRateInterval);
            this.streamRateInterval = null;
        }
    }


    _createWebTransport() {
        const player = this.player;
        const {
            debug,
            events: {proxy},
            demux,
        } = player;
        try {
            this.transport = new WebTransport(this.wtUrl);
            this.transport.ready.then(() => {
                this.emit(EVENTS.streamSuccess);
                this.startStreamRateInterval();
                this.transport.createBidirectionalStream().then((stream) => {
                    stream.readable.pipeTo(new WritableStream(demux.input));
                })
            }).catch((e) => {
                this.player.debug.warn('WebTransportLoader', '_createWebTransport-ready', e);
            })
        } catch (e) {
            this.player.debug.warn('WebTransportLoader', '_createWebTransport', e);
        }
    }

    fetchStream(url) {
        this.player._times.streamStart = now();
        this.wtUrl = url.replace(/^wt:/, 'https:');
        this._createWebTransport();
    }

    abort() {
        if (this.transport) {
            try {
                this.transport.close();
                this.transport = null;
            } catch (e) {
                this.transport = null;
            }
        }
    }
    getStreamType() {
        return PLAYER_STREAM_TYPE.webTransport;
    }
}
