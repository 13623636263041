import {
    AUDIO_CHANNEL_MAX,
    EVENTS,
    EVENTS_ERROR, FILE_SUFFIX,
    MEDIA_TYPE,
    PLAY_TYPE, PLAYER_STREAM_TYPE,
    RENDER_TYPE, URL_OBJECT_CLEAR_TIME,
    WASM_ERROR,
    WORKER_CMD_TYPE,
    WORKER_SEND_TYPE
} from "../constant";
import {isChrome, isEmpty, isFalse, isRelease, isWebglRenderSupport, now} from "../utils";
import {isAAC, isAacCodecPacket} from "../utils/aac";
import {parseFlvScriptData} from "../utils/flv";

export default class DecoderWorker {
    constructor(player) {
        this.player = player;
        this.destroyResolve = null;
        this.workerClearTimeout = null;
        this.workerUrl = null;
        let decoder = player._opt.decoder;
        this.decoderWorkerCloseTimeout = null;

        // wcs or mse
        if (isFalse(this.player._opt.useWasm)) {
            if (this.player._opt.demuxUseWorker) {
                decoder = player._opt.decoderHard;
            } else {
                decoder = player._opt.decoderAudio;
            }
        }

        if (decoder.indexOf('http') === 0 && this.player._opt.isDecoderUseCDN) {
            const blob = new Blob([`importScripts("${decoder}")`], {"type": 'application/javascript'});
            decoder = window.URL.createObjectURL(blob);
            this.workerUrl = decoder;

            //  必须要释放，不然每次调用内存都明显泄露内存
            //chrome 83 file协议下如果直接释放，将会使WebWorker无法启动
            this.workerClearTimeout = setTimeout(() => {
                window.URL.revokeObjectURL(this.workerUrl);
                this.workerUrl = null;
                this.workerClearTimeout = null;
            }, URL_OBJECT_CLEAR_TIME)
        }

        this.decoderWorker = new Worker(decoder);

        this._initDecoderWorker();
        player.debug.log('decoderWorker', `init and decoder url is ${decoder}`);

        player.on(EVENTS.visibilityChange, () => {
            this.updateWorkConfig({
                key: 'visibility',
                value: player.visibility
            })
        })
    }

    destroy() {
        return new Promise((resolve, reject) => {
            if (this.player.loaded) {
                this.player.debug.log('decoderWorker', 'has loaded and post message to destroy');
                if (this.decoderWorker) {
                    this.decoderWorker.postMessage({cmd: WORKER_SEND_TYPE.close});
                    this.destroyResolve = resolve;

                    // 需要添加个防御如果worker 线程没有返回，则直接触发超时逻辑。
                    this.decoderWorkerCloseTimeout = setTimeout(() => {
                        this.player.debug.warn('decoderWorker', 'send close but not response and destroy directly');
                        if (this.decoderWorkerCloseTimeout) {
                            clearTimeout(this.decoderWorkerCloseTimeout);
                            this.decoderWorkerCloseTimeout = null;
                        }
                        this._destroy();
                        setTimeout(() => {
                            resolve();
                        }, 0)
                    }, 2 * 1000);
                } else {
                    this.player.debug.warn('decoderWorker', 'has loaded but decoderWorker is null and destroy directly');
                    this._destroy();
                    setTimeout(() => {
                        resolve();
                    }, 0)
                }
            } else {
                this.player.debug.log('decoderWorker', 'has not loaded and destroy directly');
                this._destroy();
                setTimeout(() => {
                    resolve();
                }, 0)
            }
        })
    }

    _destroy() {

        if (this.decoderWorkerCloseTimeout) {
            clearTimeout(this.decoderWorkerCloseTimeout);
            this.decoderWorkerCloseTimeout = null;
        }

        if (this.workerUrl) {
            window.URL.revokeObjectURL(this.workerUrl);
            this.workerUrl = null;
        }

        if (this.workerClearTimeout) {
            clearTimeout(this.workerClearTimeout);
            this.workerClearTimeout = null;
        }

        if (this.decoderWorker) {
            this.decoderWorker.terminate();
            this.decoderWorker.onerror = null;
            this.decoderWorker.onmessageerror = null;
            this.decoderWorker.onmessage = null;
            this.decoderWorker = null;
        }
        this.player.debug.log(`decoderWorker`, 'destroy');
        if (this.destroyResolve) {
            this.destroyResolve();
            this.destroyResolve = null;
        }
    }


    _initDecoderWorker() {
        const {
            debug,
            events: {proxy},
        } = this.player;

        //
        this.decoderWorker.onerror = (e) => {
            this.player.debug.error(`decoderWorker`, 'onerror', e);
            this.player.emitError(EVENTS_ERROR.decoderWorkerInitError, e);
        }

        this.decoderWorker.onmessageerror = (e) => {
            this.player.debug.error(`decoderWorker`, 'onmessageerror', e);
        }

        this.decoderWorker.onmessage = (event) => {
            const msg = event.data;
            switch (msg.cmd) {
                case WORKER_CMD_TYPE.init:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.init);
                    //
                    if (this.decoderWorker) {
                        this._initWork();
                    }
                    if (!this.player.loaded) {
                        this.player.emit(EVENTS.load);
                    }
                    this.player.emit(EVENTS.decoderWorkerInit);
                    break;
                case WORKER_CMD_TYPE.videoCode:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.videoCode, msg.code);

                    if (!this.player._times.decodeStart) {
                        this.player._times.decodeStart = now();
                    }
                    this.player.video.updateVideoInfo({
                        encTypeCode: msg.code
                    })
                    break;
                case WORKER_CMD_TYPE.videoCodec:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.videoCodec, msg.codecId);
                    if (this.player.recorder) {
                        this.player.recorder.initMetaData(msg.buffer, msg.codecId);
                    }
                    break;
                case WORKER_CMD_TYPE.audioCode:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.audioCode, msg.code);
                    this.player.audio && this.player.audio.updateAudioInfo({
                        encTypeCode: msg.code
                    })
                    break;
                case WORKER_CMD_TYPE.audioAACSequenceHeader:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.audioAACSequenceHeader);
                    if (this.player.recorder) {
                        this.player.recorder.initAudioAacExtraData(msg.buffer)
                    }
                    break;
                case WORKER_CMD_TYPE.initVideo:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.initVideo, `width:${msg.w},height:${msg.h}`);

                    if (isEmpty(msg.w) || isEmpty(msg.h)) {
                        this.player.emitError(EVENTS_ERROR.videoInfoError, `video width ${msg.w} or height ${msg.h} is empty`);
                        return;
                    }

                    this.player.video.updateVideoInfo({
                        width: msg.w,
                        height: msg.h
                    })
                    // just for canvas render
                    if (!this.player._opt.openWebglAlignment &&
                        !isWebglRenderSupport(msg.w) &&
                        this.player.getRenderType() === RENDER_TYPE.canvas) {
                        this.player.emitError(EVENTS_ERROR.webglAlignmentError);
                        return;
                    }

                    this.player.video.initCanvasViewSize();
                    if (this.player._opt.playType === PLAY_TYPE.playbackTF) {
                        this.player.video.initFps();
                        this.player.video.initVideoDelay();
                    }
                    break;
                case WORKER_CMD_TYPE.initAudio:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.initAudio, `channels:${msg.channels},sampleRate:${msg.sampleRate}`);

                    if (msg.channels > AUDIO_CHANNEL_MAX) {
                        this.player.emitError(EVENTS_ERROR.audioChannelError, `audio channel is ${msg.channels}, max is ${AUDIO_CHANNEL_MAX}`);
                        return;
                    }

                    if (this.player.audio) {
                        this.player.audio.updateAudioInfo(msg);
                        if (this.player._opt.playType === PLAY_TYPE.player) {
                            this.player.audio.initScriptNode();
                        } else if (this.player._opt.playType === PLAY_TYPE.playbackTF) {
                            this.player.audio.initScriptNodeDelay()
                        }
                    }
                    break;
                case WORKER_CMD_TYPE.render:
                    if (!this.player.video) {
                        debug.warn('decoderWorker', 'onmessage render but video is null');
                        return;
                    }
                    // debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.render, `msg ts:${msg.ts}`);
                    if (this.player.isPlayer()) {
                        // un caught typeError: failed to construct 'VideoFrame': codedWidth muse be nonzero;
                        if (isFalse(this.player.video.getHasInit())) {
                            debug.warn('decoderWorker', 'onmessage render but video has not init');
                            return;
                        }

                        this.player.video.render(msg);
                        this.player.handleRender();
                        this.player.emit(EVENTS.timeUpdate, msg.ts)
                        this.player.updateStats({dfps: true, buf: msg.delay})
                        if (!this.player._times.videoStart) {
                            this.player._times.videoStart = now();
                            this.player.handlePlayToRenderTimes();
                        }
                    } else if (this.player.isPlayback()) {
                        this.player.updateStats({dfps: true});
                        if (isFalse(this.player.playbackPause)) {
                            if (this.player.playback.isUseLocalCalculateTime) {
                                this.player.playback.increaseLocalTimestamp();
                            }
                            if (this.player.playback.isUseFpsRender) {
                                this.player.video.pushData(msg);
                            } else {
                                this.player.video.render$2(msg);
                            }
                        } else {
                            // 暂停的时候，如果不需要清除缓存
                            if (!this.player.playback.isPlaybackPauseClearCache &&
                                this.player.playback.isCacheBeforeDecodeForFpsRender) {
                                if (this.player.playback.isUseFpsRender) {
                                    this.player.video.pushData(msg);
                                }
                            }
                        }
                    }

                    break;
                case WORKER_CMD_TYPE.videoNalu:
                    if (this.player.recorder &&
                        this.player.recorder.isRecording &&
                        this.player._opt.recordType === FILE_SUFFIX.mp4) {
                        this.player.recorder.handleAddNaluTrack(msg.buffer, msg.isIFrame, msg.ts, msg.cts);
                    }
                    break;
                case WORKER_CMD_TYPE.audioNalu:
                    if (this.player.recorder &&
                        this.player.recorder.isRecording &&
                        this.player._opt.recordType === FILE_SUFFIX.mp4 &&
                        this.player.recorder.isWasmMp4()) {
                        this.player.recorder.handleAddAudioTrack(msg.buffer, msg.ts);
                    }
                    break;
                case WORKER_CMD_TYPE.videoPayload:
                    const {webcodecsDecoder, mseDecoder} = this.player;
                    this.player.updateStats({buf: msg.delay})
                    const uint8Array = new Uint8Array(msg.payload);
                    if (this.player._opt.useWCS && !this.player._opt.useOffscreen) {
                        webcodecsDecoder.decodeVideo(uint8Array, msg.ts, msg.isIFrame, msg.cts);
                    } else if (this.player._opt.useMSE) {
                        mseDecoder.decodeVideo(uint8Array, msg.ts, msg.isIFrame, msg.cts);
                    }
                    break;
                case WORKER_CMD_TYPE.audioPayload:
                    if (this.player._opt.useMSE) {
                        const uint8Array = new Uint8Array(msg.payload);
                        this.player.mseDecoder.decodeAudio(uint8Array, msg.ts, msg.cts);
                    }
                    break;
                case WORKER_CMD_TYPE.playAudio:
                    // debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.playAudio, `msg ts:${msg.ts}`);
                    // 只有在 playing 的时候。
                    // 或者 设置hasVideo 为false的情况
                    if (!this.player.audio) {
                        debug.warn('decoderWorker', 'onmessage playAudio but audio is null');
                        return;
                    }

                    if ((this.player.playing && this.player.audio) || !this.player.video) {
                        // 如果不解码video
                        if (!this.player._opt.hasVideo) {
                            this.player.handleRender();
                        }
                        if (this.player._opt.playType === PLAY_TYPE.player) {
                            this.player.audio.play(msg.buffer, msg.ts);
                        } else if (this.player._opt.playType === PLAY_TYPE.playbackTF) {
                            if (isFalse(this.player.playbackPause)) {
                                this.player.audio.play(msg.buffer, msg.ts);
                            } else {
                                if (!this.player.playback.isPlaybackPauseClearCache &&
                                    this.player.playback.isCacheBeforeDecodeForFpsRender) {
                                    if (this.player.playback.isUseFpsRender) {
                                        this.player.audio.play(msg.buffer, msg.ts);
                                    }
                                }
                            }
                        }
                    }
                    break;
                case WORKER_CMD_TYPE.workerFetch:
                    // debug.log(`decoderWorker`, `workerFetch: ${msg.type},${msg.value}`);
                    if (msg.type === EVENTS.streamSuccess) {
                        if (this.player.stream) {
                            this.player.stream.emit(EVENTS.streamSuccess)
                        } else {
                            debug.warn('decoderWorker', `onmessage and workerFetch response stream success but stream is null`)
                        }
                    } else if (msg.type === EVENTS.streamRate) {
                        this.player.emit(EVENTS.kBps, (msg.value / 1024).toFixed(2));
                    } else if (msg.type === EVENTS.streamEnd) {
                        if (this.player) {
                            if (msg.value === PLAYER_STREAM_TYPE.websocket) {
                                this.player.emit(EVENTS.websocketClose);
                            }
                            if (this.player.stream) {
                                this.player.stream.emit(EVENTS.streamEnd)
                            } else {
                                debug && debug.warn('decoderWorker', `onmessage and workerFetch response stream end but player.stream is null`)
                            }
                        } else {
                            debug && debug.warn('decoderWorker', `onmessage and workerFetch response stream end but player is null`)
                        }
                    } else if (msg.type === EVENTS_ERROR.websocketError) {
                        if (this.player && this.player.stream) {
                            this.player.stream.emit(EVENTS_ERROR.websocketError, msg.value);
                        } else {
                            debug && debug.warn('decoderWorker', `onmessage and workerFetch response websocket error but stream is null`)
                        }
                    } else if (msg.type === EVENTS_ERROR.fetchError) {
                        if (this.player && this.player.stream) {
                            this.player.stream.emit(EVENTS_ERROR.fetchError, msg.value);
                        } else {
                            debug && debug.warn('decoderWorker', `onmessage and workerFetch response fetch error but stream is null`)
                        }
                    } else if (msg.type === EVENTS.streamAbps) {
                        this.player.updateStats({
                            abps: msg.value
                        })
                    } else if (msg.type === EVENTS.streamVbps) {
                        if (!this.player._times.demuxStart) {
                            this.player._times.demuxStart = now();
                        }
                        this.player.updateStats({
                            vbps: msg.value
                        })
                    } else if (msg.type === EVENTS.streamDts) {
                        this.player.updateStats({
                            dts: msg.value
                        })
                    } else if (msg.type === EVENTS.netBuf) {
                        this.player.updateStats({
                            netBuf: msg.value
                        })
                    } else if (msg.type === EVENTS.networkDelayTimeout) {
                        this.player.emit(EVENTS.networkDelayTimeout, msg.value)
                    } else if (msg.type === EVENTS.streamStats) {
                        const obj = JSON.parse(msg.value);
                        this.player.updateStats({
                            workerStats: obj
                        })
                    } else if (msg.type === EVENTS.websocketOpen) {
                        this.player.emit(EVENTS.websocketOpen);
                    }

                    break;
                case WORKER_CMD_TYPE.iframeIntervalTs:
                    if (this.player) {
                        this.player.videoIframeIntervalTs = msg.value;
                    }
                    break;
                case WORKER_CMD_TYPE.isDropping:
                    if (this.player) {
                        this.player.updateStats({
                            isDropping: true
                        })
                    }
                    break;
                case WORKER_CMD_TYPE.checkFirstIFrame:
                    this.player.decoderCheckFirstIFrame();
                    break;
                case WORKER_CMD_TYPE.playbackStreamVideoFps:
                    if (this.player && this.player.video) {
                        this.player.video.setStreamFps(msg.value);
                    }
                    break;
                case WORKER_CMD_TYPE.wasmError:
                    if (msg.message) {
                        if (msg.message.indexOf(WASM_ERROR.invalidNalUnitSize) !== -1) {
                            this.player.emitError(EVENTS_ERROR.wasmDecodeError, '');
                        }
                    }
                    break;
                case WORKER_CMD_TYPE.wasmDecodeVideoNoResponseError:
                    this.player.emitError(EVENTS_ERROR.wasmDecodeVideoNoResponseError);
                    break;
                case WORKER_CMD_TYPE.simdH264DecodeVideoWidthIsTooLarge:
                    this.player.emitError(EVENTS_ERROR.simdH264DecodeVideoWidthIsTooLarge);
                    break;
                case WORKER_CMD_TYPE.wasmWidthOrHeightChange:
                    this.player.emitError(EVENTS_ERROR.wasmWidthOrHeightChange);
                    break;
                case WORKER_CMD_TYPE.simdDecodeError:
                    this.player.emitError(EVENTS_ERROR.simdDecodeError);
                    break;
                case WORKER_CMD_TYPE.workerEnd:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.workerEnd);
                    if (!isRelease) {
                        this.player.destroy();
                        console.error('jessibuca pro 体验结束,请刷新页面再次体验(wasm内部会暂停解码)，如需要购买商业授权，可以联系微信：bosswancheng');
                        alert('jessibuca pro 体验结束,请刷新页面再次体验，如需要购买商业授权，可以联系微信：bosswancheng');
                        window.location.reload()
                    }
                    break;
                case WORKER_CMD_TYPE.closeEnd:
                    debug.log(`decoderWorker`, 'onmessage:', WORKER_CMD_TYPE.closeEnd);
                    this._destroy();
                    break;
                case WORKER_CMD_TYPE.tempStream:
                    if (this.player) {
                        this.player.pushTempStream(msg.buffer)
                    }
                    break;
                case WORKER_CMD_TYPE.videoSEI:
                    if (this.player) {
                        this.player.emit(EVENTS.videoSEI, {
                            ts: msg.ts,
                            data: new Uint8Array(msg.buffer)
                        })
                    }
                    break;
                case WORKER_CMD_TYPE.flvScriptData:
                    if (this.player) {
                        if (this.player.isRecordTypeFlv()) {
                            const payloadCopy = new Uint8Array(msg.buffer);
                            this.player.recorder.addMetaData(payloadCopy);
                        }
                        const payload = new Uint8Array(msg.buffer)
                        const scriptObj = parseFlvScriptData(payload);
                        if (scriptObj && scriptObj.onMetaData) {
                            this.player.updateMetaData(scriptObj.onMetaData);
                        }
                    }
                    break;
                case WORKER_CMD_TYPE.aacSequenceHeader:
                    if (this.player && this.player.isRecordTypeFlv()) {
                        const payloadCopy = new Uint8Array(msg.buffer);
                        this.player.recorder.addAACSequenceHeader(payloadCopy, msg.ts);
                    }
                    break;
                case WORKER_CMD_TYPE.videoSequenceHeader:
                    if (this.player && this.player.isRecordTypeFlv()) {
                        const payloadCopy = new Uint8Array(msg.buffer);
                        this.player.recorder.addVideoSequenceHeader(payloadCopy, msg.ts);
                    }
                    break;
                case WORKER_CMD_TYPE.flvBufferData:
                    if (this.player &&
                        this.player.isRecordTypeFlv() &&
                        this.player.recording) {
                        const payloadCopy = new Uint8Array(msg.buffer);
                        if (msg.type === MEDIA_TYPE.video) {
                            this.player.recorder.addVideo(payloadCopy, msg.ts);
                        } else if (msg.type === MEDIA_TYPE.audio) {
                            this.player.recorder.addAudio(payloadCopy, msg.ts);
                        }
                    }
                    break;
                default:
                    this.player[msg.cmd] && this.player[msg.cmd](msg);
                    break;
            }
        }
    }

    _initWork() {
        const opt = {
            debug: this.player._opt.debug,
            debugLevel: this.player._opt.debugLevel,
            debugUuid: this.player._opt.debugUuid,
            useOffscreen: this.player._opt.useOffscreen,
            useWCS: this.player._opt.useWCS,
            useMSE: this.player._opt.useMSE,
            videoBuffer: this.player._opt.videoBuffer,
            videoBufferDelay: this.player._opt.videoBufferDelay,
            openWebglAlignment: this.player._opt.openWebglAlignment,
            playType: this.player._opt.playType,
            hasAudio: this.player._opt.hasAudio,
            hasVideo: this.player._opt.hasVideo,
            playbackRate: 1,
            playbackForwardMaxRateDecodeIFrame: this.player._opt.playbackForwardMaxRateDecodeIFrame,
            playbackIsCacheBeforeDecodeForFpsRender: this.player._opt.playbackConfig.isCacheBeforeDecodeForFpsRender,
            sampleRate: (this.player.audio && this.player.audio.audioContext && this.player.audio.audioContext.sampleRate) || 0,
            audioBufferSize: (this.player.audio && this.player.audio.getAudioBufferSize()) || 1024,
            networkDelay: this.player._opt.networkDelay,
            visibility: this.player.visibility,
            useSIMD: this.player._opt.useSIMD,
            recordType: this.player._opt.recordType,
            checkFirstIFrame: this.player._opt.checkFirstIFrame,
            isM7sCrypto: this.player._opt.isM7sCrypto,
            isXorCrypto: this.player._opt.isXorCrypto,
            isSm4Crypto: this.player._opt.isSm4Crypto,
            sm4CryptoKey: this.player._opt.sm4CryptoKey,
            m7sCryptoAudio: this.player._opt.m7sCryptoAudio,
            isFlv: this.player._opt.isFlv,
            isFmp4: this.player._opt.isFmp4,
            isMpeg4: this.player._opt.isMpeg4,
            isNakedFlow: this.player._opt.isNakedFlow,
            isHls265: this.player.isUseHls265(),
            isFmp4Private: this.player._opt.isFmp4Private,
            isEmitSEI: this.player._opt.isEmitSEI,
            isRecordTypeFlv: this.player.isRecordTypeFlv(),
            isWasmMp4: (this.player.recorder && this.player.recorder.isWasmMp4()) || false,
            isChrome: isChrome(),
            isDropSameTimestampGop: this.player._opt.isDropSameTimestampGop,
            mseDecodeAudio: this.player._opt.mseDecodeAudio,
            nakedFlowH265DemuxUseNew: this.player._opt.nakedFlowH265DemuxUseNew
        }
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.init,
            opt: JSON.stringify(opt)
        })

        if (this.player._opt.isM7sCrypto) {
            this.updateWorkConfig({
                key: 'cryptoKey', value: this.player._opt.cryptoKey
            })
            this.updateWorkConfig({
                key: 'cryptoIV', value: this.player._opt.cryptoIV
            })
        }
    }

    decodeVideo(arrayBuffer, ts, isIFrame) {
        if (this.player._opt.playType === PLAY_TYPE.player) {
            if (this.player.isUseHls265()) {
                this._decodeVideoNoDelay(arrayBuffer, ts, isIFrame);
            } else {
                this._decodeVideo(arrayBuffer, ts, isIFrame);
            }
        } else if (this.player._opt.playType === PLAY_TYPE.playbackTF) {
            // if rate
            if (this.player.video.rate >= this.player._opt.playbackForwardMaxRateDecodeIFrame) {
                if (isIFrame) {
                    this.player.debug.log(`decoderWorker`, `current rate is ${this.player.video.rate},only decode i frame`);
                    this._decodeVideoNoDelay(arrayBuffer, ts, isIFrame);
                }
            } else {
                if (this.player.video.rate === 1) {
                    this._decodeVideo(arrayBuffer, ts, isIFrame)
                } else {
                    this._decodeVideoNoDelay(arrayBuffer, ts, isIFrame);
                }
            }
        }
    }


    _decodeVideo(arrayBuffer, ts, isIFrame) {
        const options = {
            type: MEDIA_TYPE.video,
            ts: Math.max(ts, 0),
            isIFrame
        }
        // this.player.debug.log('decoderWorker', 'decodeVideo', options);
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.decode,
            buffer: arrayBuffer,
            options
        }, [arrayBuffer.buffer])
    }

    /**
     *
     * @param arrayBuffer
     * @param ts
     * @private
     */
    _decodeVideoNoDelay(arrayBuffer, ts, isIFrame) {
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.videoDecode,
            buffer: arrayBuffer,
            ts: Math.max(ts, 0),
            isIFrame
        }, [arrayBuffer.buffer])
    }

    decodeAudio(arrayBuffer, ts) {
        if (this.player._opt.playType === PLAY_TYPE.player) {
            if (this.player._opt.useWCS) {
                this._decodeAudioNoDelay(arrayBuffer, ts);
            } else if (this.player._opt.useMSE) {
                this._decodeAudioNoDelay(arrayBuffer, ts);
            } else if (this.player.isUseHls265()) {
                this._decodeAudioNoDelay(arrayBuffer, ts);
            } else {
                this._decodeAudio(arrayBuffer, ts);
            }
        } else if (this.player._opt.playType === PLAY_TYPE.playbackTF) {
            // this._decodeAudioNoDelay(arrayBuffer, ts);
            if (this.player.video.rate === 1) {
                this._decodeAudio(arrayBuffer, ts);
            } else {
                this._decodeAudioNoDelay(arrayBuffer, ts);
            }
        }
    }

    //
    _decodeAudio(arrayBuffer, ts) {
        const options = {
            type: MEDIA_TYPE.audio,
            ts: Math.max(ts, 0)
        }
        // this.player.debug.log('decoderWorker', 'decodeAudio',options);
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.decode,
            buffer: arrayBuffer,
            options
        }, [arrayBuffer.buffer])
    }


    _decodeAudioNoDelay(arrayBuffer, ts) {
        // console.log('_decodeAudioNoDelay', ts);
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.audioDecode,
            buffer: arrayBuffer,
            ts: Math.max(ts, 0)
        }, [arrayBuffer.buffer])
    }


    updateWorkConfig(config) {
        this.decoderWorker && this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.updateConfig,
            key: config.key,
            value: config.value
        })
    }

    workerFetchStream(url) {
        const {_opt} = this.player
        const opt = {
            protocol: _opt.protocol,
            isFlv: _opt.isFlv,
            isFmp4: _opt.isFmp4,
            isMpeg4: _opt.isMpeg4,
            isNakedFlow: _opt.isNakedFlow,
        }
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.fetchStream,
            url,
            opt: JSON.stringify(opt)
        })
    }

    clearWorkBuffer(needClear = false) {
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.clearBuffer,
            needClear
        })
    }

    workerSendMessage(message) {
        this.decoderWorker.postMessage({
            cmd: WORKER_SEND_TYPE.sendWsMessage,
            message
        })
    }
}
