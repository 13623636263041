// 24小时，以一个小时为单位的。
import {formatMinTimeTips, formatMinuteTimestamp, formatSecondTimestamp, formatSecondTimeTips} from "../utils";
import {PLAYBACK_RENDER_ONCE_LENGTH} from "../constant";

function _isInPlayList(list, time) {
    let result = false;
    list.forEach((item) => {
        if (!result) {
            if (item.startTimestamp <= time && item.endTimestamp > time) {
                result = true;
            }
        }
    })

    return result;
}


// 一个小时 60个格子
export function initHourMinList1(playbackList = []) {
    let result = [];
    const playbackItem = playbackList[0] || {};
    const oneDay = playbackItem.startTimestamp;
    for (let i = 0; i < 24 * 60; i++) {
        const isStart = i % 60 === 0;
        let hasRecord = false;
        if (oneDay) {
            const tempMin = formatMinuteTimestamp(oneDay, i);
            hasRecord = _isInPlayList(playbackList, tempMin);
        }
        result.push({
            title: formatMinTimeTips(i),
            timestamp: i,
            dataType: 'min',
            hasRecord,
            isStart
        });
    }
    return result;
}


// 半个小时 60个格式
export function initHalfHourMinSecondList1(playbackList = []) {
    let result = [];
    const playbackItem = playbackList[0] || {};
    const oneDay = playbackItem.startTimestamp;
    for (let i = 0; i < 24 * 60; i++) {
        let second = i * 60
        let isStart = second % (30 * 60) === 0
        let hasRecord = false;
        if (oneDay) {
            const tempSecond = formatSecondTimestamp(oneDay, second);
            hasRecord = _isInPlayList(playbackList, tempSecond);
        }
        result.push({
            title: formatSecondTimeTips(second),
            timestamp: second,
            dataType: 'second',
            hasRecord,
            isStart
        });
        let second2 = i * 60 + 30
        isStart = second2 % (30 * 60) === 0
        if (oneDay) {
            const tempSecond2 = formatSecondTimestamp(oneDay, second2);
            hasRecord = _isInPlayList(playbackList, tempSecond2);
        }
        result.push({
            title: formatSecondTimeTips(second2),
            timestamp: second2,
            dataType: 'second',
            hasRecord,
            isStart
        });
    }
    return result;
}

// 十分钟 60个格子
export function initTenMinSecondList1(playbackList = []) {
    //
    let result = [];
    const playbackItem = playbackList[0] || {};
    const oneDay = playbackItem.startTimestamp;
    // 时
    for (let i = 0; i < 24 * 6; i++) {
        // 内层
        for (let j = 0; j < 60; j++) {
            let step = 10 * j + i * 600;
            let isStart = step % (10 * 60) === 0
            let hasRecord = false;
            if (oneDay) {
                const tempSecond = formatSecondTimestamp(oneDay, step);
                hasRecord = _isInPlayList(playbackList, tempSecond);
            }
            result.push({
                title: formatSecondTimeTips(step),
                timestamp: step,
                dataType: 'second',
                isStart,
                hasRecord,
            });
        }
    }
    return result;
}

// 五分钟 60个格子
export function initFiveMinSecondList1(playbackList = []) {
    // 外层 23个 小时
    let result = [];
    const playbackItem = playbackList[0] || {};
    const oneDay = playbackItem.startTimestamp;
    for (let i = 0; i < 24 * 12; i++) {
        // 内层 60个格子
        for (let j = 0; j < 60; j++) {
            let step = 5 * j + i * 300;
            let isStart = step % (5 * 60) === 0
            let hasRecord = false;
            if (oneDay) {
                const tempSecond = formatSecondTimestamp(oneDay, step);
                hasRecord = _isInPlayList(playbackList, tempSecond);
            }
            result.push({
                title: formatSecondTimeTips(step),
                timestamp: step,
                dataType: 'second',
                isStart,
                hasRecord,
            });
        }
    }
    return result;
}

// 初始化以秒为单位
export function initSecondList1(playbackList = []) {
    let result = [];
    const playbackItem = playbackList[0] || {};
    const oneDay = playbackItem.startTimestamp;
    for (let i = 0; i < 24 * 60 * 60; i++) {
        let hasRecord = false;
        if (oneDay) {
            const tempSecond = formatSecondTimestamp(oneDay, i);
            hasRecord = _isInPlayList(playbackList, tempSecond);
        }
        result.push({
            title: formatSecondTimeTips(i),
            timestamp: i,
            dataType: 'second',
            hasRecord,
        });
    }
    return result;
}

// 24小时  以一个小时间隔
export function initHourList2() {
    let result = [];
    for (let i = 0; i < 24; i++) {
        let title = i + ":00";
        if (i < 10) {
            title = "0" + title;
        }
        result.push({
            title: title,
            hour: i,
            min: 0,
            second: 0
        });
    }

    return result;
}

// 24小时 以半个小时间隔
export function initHalfHourList2() {
    let result = [];
    for (let i = 0; i < 24; i++) {
        let title = i + ":00";
        let titleHalf = i + ':30';
        if (i < 10) {
            title = "0" + title;
            titleHalf = '0' + titleHalf;
        }
        result.push({
            title: title,
            hour: i,
            min: 0,
            second: 0,
        });

        result.push({
            title: titleHalf,
            hour: i,
            min: 30,
            second: 0,
        })
    }
    return result;
}

// 24小时，10分钟为间隔
export function initTenMinList2() {
    let result = [];
    for (let i = 0; i < 24; i++) {
        let title = i + ":00";
        if (i < 10) {
            title = "0" + title;
        }
        result.push({
            title: title,
            hour: i,
            min: 0,
            second: 0,
        });
        for (let j = 1; j < 6; j++) {
            let tenMin = ('' + j) + '0'
            result.push({
                title: title.replace(':00', ':' + tenMin),
                hour: i,
                min: j * 10,
                second: 0,
            });
        }

    }
    return result;
}

// 24小时，5分钟为间隔
export function initFileMinList2() {
    let result = [];
    for (let i = 0; i < 24; i++) {
        let title = i + ":00";
        if (i < 10) {
            title = "0" + title;
        }
        result.push({
            title: title,
            hour: i,
            min: 0,
            second: 0,
        });

        result.push({
            title: title.replace(':00', ':05'),
            hour: i,
            min: 5,
            second: 0,
        });

        for (let j = 1; j < 6; j++) {
            let tenMin = ('' + j) + '0'
            let fileMin = j + '5'
            result.push({
                title: title.replace(':00', ':' + tenMin),
                hour: i,
                min: j * 10,
                second: 0,
            });
            result.push({
                title: title.replace(':00', ':' + fileMin),
                hour: i,
                min: j * 10 + 5,
                second: 0,
            });
        }

    }
    return result;
}

// 24小时 1分钟为间隔
export function initOneMinList2() {
    let result = [];
    for (let i = 0; i < 24; i++) {
        let title = i + ":00";
        if (i < 10) {
            title = "0" + title;
        }
        for (let j = 0; j < 60; j++) {
            let minTitle = j;
            if (j < 10) {
                minTitle = '0' + j;
            }
            minTitle = title.replace(':00', ':' + minTitle);
            result.push({
                title: minTitle,
                hour: i,
                min: j,
                second: 0,
            });
        }
    }
    return result;
}

function renderTimeDay(oneList = [], secondList = [], control) {
    const oneListLength = oneList.length;
    const secondListLength = secondList.length;
    const total = Math.max(oneListLength, secondListLength);
    const once = PLAYBACK_RENDER_ONCE_LENGTH;
    const loopCount = Math.ceil(total / once)
    let countRender = 0;
    let currentIndex = 0;

    function render() {
        let fragment = '';
        let fragment2 = '';
        for (let i = 0; i < once; i++) {
            const time = oneList[currentIndex];
            if (time) {
                fragment += `
                     <div class="jessibuca-playback-time-minute-one${time.hasRecord ? ' active' : ''}${time.isStart ? ' start' : ''}" data-has-record="${time.hasRecord}"
                     data-time="${time.timestamp}" data-type="${time.dataType}">
                        <span class="jessibuca-playback-time-title-tips ${currentIndex > oneListLength - 60 ? 'jessibuca-playback-time-title-tips-left' : ''}"><span class="jessibuca-playback-time-title">${time.title}</span></span>
                    </div>
                `
            }
            const hour = secondList[currentIndex];
            if (hour) {
                fragment2 += `
                  <div class="jessibuca-playback-time-hour" data-hour="${hour.hour}" data-min="${hour.min}" data-second="${hour.second}"><span class="jessibuca-playback-time-hour-text">${hour.title}</span></div>
                `
            }

            currentIndex += 1;
        }

        if (fragment) {
            control.$playbackTimeListOne.insertAdjacentHTML(
                'beforeend',
                fragment
            )
        }

        if (fragment2) {
            control.$playbackTimeListSecond.insertAdjacentHTML(
                'beforeend',
                fragment2
            )
        }

        countRender += 1;
        if (countRender < loopCount) {
            control.rafId = window.requestAnimationFrame(render);
        }
    }

    render();
}


// 一个小时一个间隔
export function renderHourTimeDay(playbackList, control) {
    const minuteList = initHourMinList1(playbackList);
    const hourList = initHourList2();
    renderTimeDay(minuteList, hourList, control)
}

// 半个小时一个间隔
export function renderHalfHourTimeDay(playbackList, control) {
    const minuteList = initHalfHourMinSecondList1(playbackList);
    const hourList = initHalfHourList2();
    renderTimeDay(minuteList, hourList, control)
}

// 10分钟一个间隔
export function renderTenMinTimeDay(playbackList, control) {
    const minuteList = initTenMinSecondList1(playbackList);
    const hourList = initTenMinList2();
    renderTimeDay(minuteList, hourList, control)

}

// 5分钟一个间隔
export function renderFiveMinTimeDay(playbackList, control) {
    const minuteList = initFiveMinSecondList1(playbackList);
    const hourList = initFileMinList2();
    renderTimeDay(minuteList, hourList, control)
}

// 1分钟一个间隔
export function renderOneMinTimeDay(playbackList, control) {
    const secondList = initSecondList1(playbackList);
    const minList = initOneMinList2();
    renderTimeDay(secondList, minList, control)
}
