import CommonLoader from "./commonLoader";

export default class Mpeg4Loader extends CommonLoader {
    LOG_NAME = 'Mpeg4Loader';

    constructor(player) {
        super(player);
        this.player = player;

        this.player.debug.log(this.LOG_NAME, 'init');
    }

    destroy() {
        super.destroy();

        this.player.debug.log(this.LOG_NAME, 'destroy');
    }
}
