import {getWebRtcRemoteSdpForOthers} from "../utils/webrtc";
import CommonWebrtc from "./commonWebrtc";

export default class WebrtcForOthersDecoder extends CommonWebrtc {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'WebrtcForOthersDecoder'
        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    loadSource(url) {
        return new Promise((resolve, reject) => {
            const rtcPeerConnection = this.rtcPeerConnection;
            rtcPeerConnection.createOffer().then((res) => {
                rtcPeerConnection.setLocalDescription(res);
                this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp loadSource`);
                getWebRtcRemoteSdpForOthers(url, res.sdp).then((response) => {
                    this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp response and code is ${response.code}`);
                    response.text().then((sdp) => {
                        this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp response`);
                        rtcPeerConnection.setRemoteDescription(
                            new RTCSessionDescription({
                                type: "answer",
                                sdp
                            })
                        ).then(() => {
                            resolve()
                        }).catch((e) => {
                            reject(e);
                        })
                    }).catch((e) => {
                        this.player.debug.error(this.TAG_NAME, `loadSource response.text() error`, e);
                        reject(e)
                    })
                }).catch((e) => {
                    this.player.debug.error(this.TAG_NAME, `loadSource getWebRtcRemoteSdp response error`, e);
                    reject(e);
                })
            }).catch((e) => {
                this.player.debug.error(this.TAG_NAME, `loadSource rtcPeerConnection.createOffer() error`, e);
                reject(e);
            })
        })
    }
}
