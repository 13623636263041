import Emitter from "../utils/emitter";
import {canPlayAppleMpegurl} from "../utils";
import {getConfig} from "./config";
import ManifestLoader from "./manifest-loader";
import SegmentLoader from "./segment-loader";
import Playlist from "./playlist";
import BufferService from "./buffer-service";
import SeiService from "./utils/sei";
import MediaStatsService from "./media-stats-service";
import Buffer from "./utils/buffer";
import {StreamingError, ERR} from "./error";
import {HLS_EVENTS} from "../constant";

export default class HlsLoader extends Emitter {

    constructor(player, cfg = {}) {
        super();
        this.player = player;
        /** @type {import('./config').HlsOption} */
        this.config = null;
        /** @type {ManifestLoader} */
        this._manifestLoader = null;
        /** @type {SegmentLoader} */
        this._segmentLoader = null;
        /** @type {Playlist} */
        this._playlist = null;
        /** @type {BufferService} */
        this._bufferService = null;
        /** @type {SeiService} */
        this._seiService = null;
        /** @type {MediaStatsService} */
        this._stats = null;
        //
        this._prevSegSn = null;
        //
        this._prevSegCc = null;
        //
        this._tickTimer = null;
        //
        this._tickInterval = 500;
        //
        this._segmentProcessing = false;
        //
        this._reloadOnPlay = false;
        //
        this._switchUrlOpts = null;
        //
        this._disconnectTimer = null;
        //
        this.TAG_NAME = 'Hls256';

        this.canVideoPlay = false;
        this.$videoElement = null;
        this.config = cfg = getConfig(cfg);
        this._manifestLoader = new ManifestLoader(this);
        this._segmentLoader = new SegmentLoader(this);
        this._playlist = new Playlist(this);
        this._bufferService = new BufferService(this);
        this._seiService = new SeiService(this);
        this._stats = new MediaStatsService(this, 90000);

        this.player.debug.log(this.TAG_NAME, 'init');
    }

    async destroy() {
        this.player.debug.log(this.TAG_NAME, 'destroy()');
        this._playlist.reset();
        this._segmentLoader.reset();
        this._seiService.reset()
        await Promise.all([this._clear(), this._bufferService.destroy()])

        if (this._manifestLoader) {
            await this._manifestLoader.destroy();
            this._manifestLoader = null
        }

        if (this._segmentLoader) {
            this._segmentLoader.destroy()
            this._segmentLoader = null
        }
        if (this._playlist) {
            this._playlist.destroy()
            this._playlist = null
        }
        this.player.debug.log(this.TAG_NAME, 'destroy end');
    }

    _startTick() {
        this._stopTick()
        this._tickTimer = setTimeout(() => {
            this._tick();
        }, this._tickInterval)
    }

    _stopTick() {

        if (this._tickTimer) {
            clearTimeout(this._tickTimer)
        }
        this._tickTimer = null
    }

    _tick() {
        this._startTick();
        this._loadSegment();
    }

    get isLive() {
        return this._playlist.isLive
    }

    get streams() {
        return this._playlist.streams
    }

    get currentStream() {
        return this._playlist.currentStream
    }

    get hasSubtitle() {
        return this._playlist.hasSubtitle
    }

    get baseDts() {
        return this._bufferService?.baseDts
    }

    speedInfo() {
        return this._segmentLoader.speedInfo()
    }

    resetBandwidth() {
        this._segmentLoader.resetBandwidth()
    }


    /**
     * @returns {Stats}
     */
    getStats() {
        return this._stats.getStats()
    }


    // load source
    async loadSource(url) {
        this.player.debug.log(this.TAG_NAME, `loadSource() ${url}`);
        await this._reset()
        await this._loadData(url)
        this._startTick();
        return true;
    }

    /**
     * @param {string} url
     * @private
     */
    async _loadData(url) {
        this.player.debug.log(this.TAG_NAME, `_loadData() ${url}`);
        try {
            if (url) url = url.trim()
        } catch (e) {
        }

        if (!url) {
            throw this._emitError(new StreamingError(ERR.OTHER, ERR.OTHER, null, null, 'm3u8 url is missing'))
        }

        // 获取到 manifest 内容
        const manifest = await this._loadM3U8(url)
        // current stream
        const {currentStream} = this._playlist

        // url switching
        if (this._urlSwitching) {
            if (currentStream.bitrate === 0 && this._switchUrlOpts?.bitrate) {
                currentStream.bitrate = this._switchUrlOpts?.bitrate
            }

            const switchTimePoint = this._getSeamlessSwitchPoint()
            this.config.startTime = switchTimePoint

            const segIdx = this._playlist.findSegmentIndexByTime(switchTimePoint)
            const nextSeg = this._playlist.getSegmentByIndex(segIdx + 1)

            if (nextSeg) {
                // move to next segment in case of media stall
                const bufferClearStartPoint = nextSeg.start
                this.player.debug.warn(this.TAG_NAME, `clear buffer from ${bufferClearStartPoint}`);
                // await this._bufferService.removeBuffer(bufferClearStartPoint)
            }
        }

        //
        if (manifest) {
            // is live
            if (this.isLive) {
                this.player.debug.log(this.TAG_NAME, 'is live');
                // 设置live seek able range
                // 0xffffffff 无限大
                // 设置为无限大，可以让播放器一直缓存，不会清除缓存
                this._bufferService.setLiveSeekableRange(0, 0xffffffff)

                // 配置的目标延迟小于首次获取分片总时长
                if (this.config.targetLatency < this._playlist.totalDuration) {
                    this.config.targetLatency = this._playlist.totalDuration
                    this.config.maxLatency = 1.5 * this.config.targetLatency
                }
                // 如果不是master
                if (!manifest.isMaster) {
                    this._pollM3U8(url)
                }

            } else {
                this.player.debug.log(this.TAG_NAME, `is vod and totalDuration is ${currentStream.totalDuration} s`);
                // update duration
                await this._bufferService.updateDuration(currentStream.totalDuration)
            }
        }
        await this._loadSegment()
    }


    /**
     * @private
     */
    async _loadM3U8(url) {
        this.player.debug.log(this.TAG_NAME, `load m3u8: ${url}`);
        let playlist
        try {
            [playlist] = await this._manifestLoader.load(url)
        } catch (error) {
            throw this._emitError(StreamingError.create(error))
        }
        // this.player.debug.log(this.TAG_NAME, 'playlist is', playlist);
        if (!playlist) {
            this.player.debug.warn(this.TAG_NAME, '_loadM3U8() playlist is empty');
            return
        }
        this._playlist.upsertPlaylist(playlist)

        if (playlist.isMaster) {
            if (this._playlist.currentStream.subtitleStreams?.length) {
                this.emit(HLS_EVENTS.SUBTITLE_PLAYLIST, {
                    list: this._playlist.currentStream.subtitleStreams
                })
            }
            //
            await this._refreshM3U8()
        } else {
            this.player.debug.warn(this.TAG_NAME, '_loadM3U8() is not master playlist');
        }
        this.emit(HLS_EVENTS.STREAM_PARSED)
        return playlist
    }

    /**
     * @private 首次更新 master playlist 的 media level
     */
    _refreshM3U8() {
        this.player.debug.log(this.TAG_NAME, '_refreshM3U8()')
        const stream = this._playlist.currentStream
        if (!stream || !stream.url) {
            throw this._emitError(StreamingError.create(null, null, new Error('m3u8 url is not defined')))
        }
        const url = stream.url
        const audioUrl = stream.currentAudioStream?.url
        const subtitleUrl = stream.currentSubtitleStream?.url
        return this._manifestLoader.load(url, audioUrl, subtitleUrl).then(([mediaPlaylist, audioPlaylist, subtitlePlaylist]) => {
            if (!mediaPlaylist) {
                this.player.debug.warn(this.TAG_NAME, '_refreshM3U8() mediaPlaylist is empty');
                return
            }
            this._playlist.upsertPlaylist(mediaPlaylist, audioPlaylist, subtitlePlaylist)
            if (!this.isLive) {
                return;
            }
            this._pollM3U8(url, audioUrl, subtitleUrl)
        }).catch(err => {
            throw this._emitError(StreamingError.create(err))
        })
    }

    /**
     * @private
     */
    _pollM3U8(url, audioUrl, subtitleUrl) {
        // this.player.debug.log(this.TAG_NAME, '_pollM3U8()', url, audioUrl, subtitleUrl);
        let isEmpty = this._playlist.isEmpty
        //  poll manifest loader
        this._manifestLoader.poll(
            url,
            audioUrl,
            subtitleUrl,
            (p1, p2, p3) => {
                this._playlist.upsertPlaylist(p1, p2, p3)
                this._playlist.clearOldSegment()
                if (p1 && isEmpty && !this._playlist.isEmpty) {
                    this._loadSegment()
                }
                if (isEmpty) {
                    isEmpty = this._playlist.isEmpty
                }
            }, (err) => {
                this._emitError(StreamingError.create(err))
            },
            // 刷新时间
            (this._playlist.lastSegment?.duration || 0) * 1000)
    }

    /**
     * @private
     */
    _loadSegment = async () => {
        this.player.debug.log(this.TAG_NAME, '_loadSegment()', '_segmentProcessing', this._segmentProcessing);
        if (this._segmentProcessing) {
            this.player.debug.warn('_loadSegment()', '_segmentProcessing is ture and return');
            return;
        }

        const curSeg = this._playlist.currentSegment;

        const nextSeg = this._playlist.nextSegment

        this.player.debug.log(this.TAG_NAME, '_loadSegment()', 'curSeg', curSeg, 'nextSeg', nextSeg);

        if (!nextSeg) {
            this.player.debug.log(this.TAG_NAME, `nextSeg is null and return`);
            return
        }

        return this._loadSegmentDirect()
    }


    /**
     * @private
     */
    async _loadSegmentDirect() {
        this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect()');

        const seg = this._playlist.nextSegment

        if (!seg) {
            this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect() !seg');
            return
        }

        let appended = false
        let cachedError = null
        try {
            this._segmentProcessing = true
            appended = await this._reqAndBufferSegment(seg, this._playlist.getAudioSegment(seg))
        } catch (error) {
            // If an exception is thrown here, other reference functions
            // need to handle the exception, so the error stops here
            cachedError = error
        } finally {
            this._segmentProcessing = false
        }

        if (cachedError) {
            return this._emitError(StreamingError.create(cachedError))
        }

        if (appended) {
            if (this._urlSwitching) {
                this._urlSwitching = false
                // switchURL 方法调用后，切换 url 成功后触发。
                this.emit(HLS_EVENTS.SWITCH_URL_SUCCESS, {url: this.config.url})
            }

            this._playlist.moveSegmentPointer()
            this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect()', 'seg.isLast', seg.isLast);
            if (seg.isLast) {
                this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect()', 'seg.isLast')
                this._end()
            } else {
                this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect()', 'and next _loadSegment()');
                this._loadSegment()
            }
        } else {
            this.player.debug.log(this.TAG_NAME, '_loadSegmentDirect() not appended');
        }

        return appended
    }

    /**
     * @param {MediaSegment} seg
     * @param {MediaSegment} audioSeg
     * @private
     */
    async _reqAndBufferSegment(seg, audioSeg) {
        this.player.debug.log(this.TAG_NAME, `video seg`, seg && seg.url, 'audio seg', audioSeg && audioSeg.url);
        const cc = seg ? seg.cc : audioSeg.cc;

        const discontinuity = this._prevSegCc !== cc

        let responses = []
        try {
            responses = await this._segmentLoader.load(seg, audioSeg, discontinuity)
        } catch (e) {
            e.fatal = false
            this._segmentLoader.error = e
            throw e
        }

        if (!responses[0]) {
            return
        }

        const data = await this._bufferService.decryptBuffer(...responses)

        if (!data) {
            this.player.debug.log(this.TAG_NAME, `decryptBuffer return null`)
            return
        }
        const sn = seg ? seg.sn : audioSeg.sn
        const start = seg ? seg.start : audioSeg.start
        const stream = this._playlist.currentStream
        //
        this._bufferService.createSource(data[0], data[1], stream?.videoCodec, stream?.audioCodec)
        //
        await this._bufferService.appendBuffer(seg, audioSeg, data[0], data[1], discontinuity, this._prevSegSn === sn - 1, start)
        this._prevSegCc = cc
        this._prevSegSn = sn
        return true
    }

    /**
     * @private
     */
    async _clear() {
        this.player.debug.log(this.TAG_NAME, '_clear()');
        clearTimeout(this._disconnectTimer)
        this._stopTick()
        await Promise.all([
            this._segmentLoader.cancel(),
            this._manifestLoader.stopPoll()
        ])
        this._segmentProcessing = false
    }

    /**
     * @private
     */
    async _reset(reuseMse = false) {
        this.player.debug.log(this.TAG_NAME, '_reset()');
        this._reloadOnPlay = false
        this._prevSegSn = null
        this._prevSegCc = null
        this._switchUrlOpts = null
        this._playlist.reset()
        this._segmentLoader.reset()
        this._seiService.reset()
        this._stats.reset()
        await this._clear()
        return this._bufferService.reset(reuseMse)
    }

    /**
     * @private
     */
    _end() {
        this.player.debug.log(this.TAG_NAME, '_end()');
        this._clear()
        // this._bufferService.endOfStream()
    }


    /**
     * @param {StreamingError} error
     * @param {boolean?} endOfStream
     * @private
     */
    _emitError(error, endOfStream = false) {
        if (error.originError?.fatal === false) {
            console.warn(error)
        } else {
            console.table(error)
            console.error(error)
            console.error(this.media?.error)

            this._stopTick()
            if (this._urlSwitching) {
                this._urlSwitching = false
                // switchURL 方法调用后，切换 url 失败后触发。
                this.emit(HLS_EVENTS.SWITCH_URL_FAILED, error)
            }
            if (endOfStream) this._end()
            this._seiService.reset()
            this.emit(HLS_EVENTS.ERROR, error)
        }
        return error
    }

    /**
     * @private
     */
    _getSeamlessSwitchPoint() {
        const {media} = this
        let nextLoadPoint = media.currentTime
        if (!media.paused) {
            const segIdx = this._playlist.findSegmentIndexByTime(media.currentTime)
            const curSeg = this._playlist.getSegmentByIndex(segIdx)
            const latestKbps = this._stats?.getStats().downloadSpeed // latest download speed
            if (latestKbps && curSeg) {
                const delay = (curSeg.duration * this._playlist.currentStream.bitrate) / latestKbps + 1

                nextLoadPoint += delay
            } else {
                nextLoadPoint += 5
            }
        }

        return nextLoadPoint
    }

    getDemuxBuferredDuration() {
        return this._bufferService.getBuferredDuration() || 0
    }

    getDemuxBufferedListLength() {
        return this._bufferService.getBufferedSegments() || 0
    }

    getDemuxAudioBufferedListLength() {
        return this._bufferService.getBufferedAudioSegments() || 0
    }

    getDemuxVideoBufferedListLength() {
        return this._bufferService.getBufferedVideoSegments() || 0
    }
}
