export function createWebGPUContext(canvas) {
    return new Promise((resolve, reject) => {
        if (navigator.gpu) {
            navigator.gpu.requestAdapter().then(adapter => {
                if (adapter) {
                    adapter.requestDevice().then(device => {
                        if (device) {
                            const context = canvas.getContext('webgpu');
                            if (context) {
                                const format = navigator.gpu.getPreferredCanvasFormat();

                                context.configure({
                                    device,
                                    format,
                                    alphaMode: 'opaque',
                                });
                                resolve({
                                    adapter,
                                    device,
                                    context,
                                    format,
                                });
                            } else {
                                reject('WebGPU "context" create fail');
                            }
                        } else {
                            reject('WebGPU "device" request fail');
                        }
                    }).catch((e) => {
                        reject('WebGPU "adapter.requestDevice()" fail');
                    });
                } else {
                    reject('WebGPU "adapter" request fail is empty');
                }
            }).catch((e) => {
                reject('WebGPU "navigator.gpu.requestAdapter()" fail');
            });
        } else {
            reject('WebGPU not support!!');
        }
    });
}
