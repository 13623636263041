class MP4 {

    static init() {

        MP4.types = {
            avc1: [],
            avcC: [],
            hvc1: [],
            hvcC: [],
            btrt: [],
            dinf: [],
            dref: [],
            esds: [],
            ftyp: [], // 视频类型
            hdlr: [],
            mdat: [], // 视频数据
            mdhd: [],
            mdia: [],
            mfhd: [],
            minf: [],
            moof: [], //
            moov: [], // 视频信息(视频参数)
            mp4a: [],
            mvex: [],
            mvhd: [],
            sdtp: [],
            stbl: [],
            stco: [],
            stsc: [],
            stsd: [],
            stsz: [],
            stts: [],
            tfdt: [],
            tfhd: [],
            traf: [],
            // 视频参数（moov）中主要的子box 为track,每个track都是一个随时间变化的媒体序列，
            // 时间单位为一个sample，可以是一帧数据，或者音频（注意，一帧音频可以分解成多个音频sample，所以音频一般用sample作为单位，而不用帧）
            trak: [],
            trun: [],
            trex: [],
            tkhd: [],
            vmhd: [],
            smhd: [],
            '.mp3': [],
            free: [],
            edts: [],
            elst: [],
            stss: []
        }

        for (let name in MP4.types) {
            if (MP4.types.hasOwnProperty(name)) {
                MP4.types[name] = [
                    name.charCodeAt(0),
                    name.charCodeAt(1),
                    name.charCodeAt(2),
                    name.charCodeAt(3)
                ];
            }
        }

        let constants = MP4.constants = {};

        // File Type Box，描述文件遵从的MP4规范与版本
        constants.FTYP = new Uint8Array([
            0x69, 0x73, 0x6F, 0x6D,  // major_brand: isom
            0x0, 0x0, 0x02, 0x0,   // minor_version: 0x20
            0x69, 0x73, 0x6F, 0x6D,  // isom
            0x69, 0x73, 0x6F, 0x32,  // iso
            0x61, 0x76, 0x63, 0x31,   // avc1
            0x6D, 0x70, 0x34, 0x31,   // MP4
            0x00, 0x00, 0x00, 0x00
        ]);

        constants.STSD_PREFIX = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x01   // entry_count
        ]);

        constants.STTS = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00   // entry_count
        ]);

        constants.STSC = constants.STCO = constants.STTS;

        constants.STSZ = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00,  // sample_size
            0x00, 0x00, 0x00, 0x00   // sample_count
        ]);

        constants.HDLR_VIDEO = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00,  // pre_defined
            0x76, 0x69, 0x64, 0x65,  // handler_type: 'vide'
            0x00, 0x00, 0x00, 0x00,  // reserved: 3 * 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x56, 0x69, 0x64, 0x65,
            0x6F, 0x48, 0x61, 0x6E,
            0x64, 0x6C, 0x65, 0x72, 0x00  // name: VideoHandler
        ]);

        constants.HDLR_AUDIO = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00,  // pre_defined
            0x73, 0x6F, 0x75, 0x6E,  // handler_type: 'soun'
            0x00, 0x00, 0x00, 0x00,  // reserved: 3 * 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x53, 0x6F, 0x75, 0x6E,
            0x64, 0x48, 0x61, 0x6E,
            0x64, 0x6C, 0x65, 0x72, 0x00  // name: SoundHandler
        ]);

        constants.DREF = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x01,  // entry_count
            0x00, 0x00, 0x00, 0x0C,  // entry_size
            0x75, 0x72, 0x6C, 0x20,  // type 'url '
            0x00, 0x00, 0x00, 0x01   // version(0) + flags
        ]);

        // Sound media header
        constants.SMHD = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00   // balance(2) + reserved(2)
        ]);

        // video media header
        constants.VMHD = new Uint8Array([
            0x00, 0x00, 0x00, 0x01,  // version(0) + flags
            0x00, 0x00,              // graphicsmode: 2 bytes
            0x00, 0x00, 0x00, 0x00,  // opcolor: 3 * 2 bytes
            0x00, 0x00
        ]);
    }

    // Generate a box
    // Type是box的类型
    // box的参数除了第一个为类型，其他参数都需要是二进制的arraybuffer类型。
    static box(type) {
        // box前8位为预留位，这8位中前4位为数据size，
        // 当size值为0时，表示该box为文件的最后一个box（仅存在于mdat box中），
        // 当size值为1时，表示该box的size为large size（8位）
        // 真正的box size要在largesize中得到（同样仅存在于mdat box中）。
        // 后4位为前面box type的Unicode编码。当type是uuid时，代表Box中的数据是用户自定义扩展类型。
        let size = 8;
        let result = null;
        // 方法中的第三行表示获取参数中除去第一个参数的其他参数
        let datas = Array.prototype.slice.call(arguments, 1);
        let arrayCount = datas.length;

        for (let i = 0; i < arrayCount; i++) {
            size += datas[i].byteLength;
        }
        // Box由header和body组成

        result = new Uint8Array(size);
        // 以32位的4字节整数存储方式存储到内存，
        // 开头4个字节（32位）为box size。
        result[0] = (size >>> 24) & 0xFF;  // size
        result[1] = (size >>> 16) & 0xFF;
        result[2] = (size >>> 8) & 0xFF;
        result[3] = (size) & 0xFF;

        // 后面紧跟的4位为box的类型
        result.set(type, 4);  // type

        let offset = 8;
        // Box body可以由数据组成，也可以由子box组成。
        for (let i = 0; i < arrayCount; i++) {  // data body
            result.set(datas[i], offset);
            offset += datas[i].byteLength;
        }

        return result;
    }


    // emit ftyp & moov
    static generateInitSegment(meta, trakList, mdatBytes) {
        // Ftypbox 是一个由四个字符组成的码字，用来表示编码类型、兼容协议或者媒体文件的用途。
        // 在普通MP4文件中，ftyp box有且仅有一个，在文件的开始位置。
        let ftyp = MP4.box(MP4.types.ftyp, MP4.constants.FTYP);
        //
        let free = MP4.box(MP4.types.free);
        // allocate mdatbox init fps = 25
        let offset = 8;
        let mdatbox = new Uint8Array();
        // 所有的长度。。。。。
        // Mdat box中，可能会使用到box的large size，当数据足够大，无法用4个字节来描述时，便会使用到large size。
        // 在读取MP4文件时，当mdat box的size位为1时，真正的box size在large size中，
        // 同样在写mp4文件时，若需要large size，需要将box size位配置为1。

        if (mdatBytes + offset >= Math.pow(2, 32) - 1) {  //large size

            offset = 16;

            mdatbox = new Uint8Array(mdatBytes + offset);

            mdatbox.set(new Uint8Array([0x00, 0x00, 0x00, 0x01]), 0);
            // 视频数据（mdat）
            mdatbox.set(MP4.types.mdat, 4);

            mdatbox.set(new Uint8Array([
                (mdatBytes + 8 >>> 56) & 0xFF,
                (mdatBytes + 8 >>> 48) & 0xFF,
                (mdatBytes + 8 >>> 40) & 0xFF,
                (mdatBytes + 8 >>> 32) & 0xFF,
                (mdatBytes + 8 >>> 24) & 0xFF,
                (mdatBytes + 8 >>> 16) & 0xFF,
                (mdatBytes + 8 >>> 8) & 0xFF,
                (mdatBytes + 8) & 0xFF
            ]), 8);
        } else {
            mdatbox = new Uint8Array(mdatBytes + offset);
            mdatbox[0] = (mdatBytes + 8 >>> 24) & 0xFF;
            mdatbox[1] = (mdatBytes + 8 >>> 16) & 0xFF;
            mdatbox[2] = (mdatBytes + 8 >>> 8) & 0xFF;
            mdatbox[3] = (mdatBytes + 8) & 0xFF;
            //视频数据（mdat）
            mdatbox.set(MP4.types.mdat, 4);
        }

        // Write samples into mdatbox
        for (let i = 0; i < trakList.length; i++) {

            let trak = trakList[i];
            // duration
            trak.duration = trak.refSampleDuration * trak.sequenceNumber;

            for (let j = 0; j < trak.sequenceNumber; j++) {
                // 遍历 samples
                let sample = trak.samples[j];
                // sample
                sample.chunkOffset = ftyp.byteLength + free.byteLength + offset;

                // 合并 data 数据。。。。。。
                let data = sample.data;
                mdatbox.set(data, offset);
                offset += data.byteLength;
            }
        }
        // Moov box中存放着媒体信息，上面提到的stbl里存放帧信息，属于媒体信息，也在moov box里。
        // Moov box 用来描述媒体数据。
        // Moov box 主要包含 mvhd、trak、mvex三种子box。
        let moov = MP4.moov(meta, trakList);
        // 视频类型（ftyp）、视频数据（mdat）、视频信息（moov）
        let result = new Uint8Array(ftyp.byteLength + moov.byteLength + mdatbox.byteLength + free.byteLength);
        // ftyp 视频类型
        result.set(ftyp, 0);
        // free
        result.set(free, ftyp.byteLength);
        // mdat 视频数据
        result.set(mdatbox, ftyp.byteLength + free.byteLength);
        // moov 视频信息
        result.set(moov, ftyp.byteLength + mdatbox.byteLength + free.byteLength);
        return result;
    }

    // Movie metadata box
    // 媒体的metadata信息，有且仅有一个，位于moov box中。
    // Moov box 主要包含 mvhd、trak、mvex三种子box。
    // 视频参数（moov）中主要的子box 为track，
    // 每个track都是一个随时间变化的媒体序列，时间单位为一个sample，可以是一帧数据，
    // 或者音频（注意，一帧音频可以分解成多个音频sample，所以音频一般用sample作为单位，而不用帧）。
    // Sample按照事件顺序排列。track里面的每个sample通过引用关联到一个sample description。
    // 这个sample descriptios定义了怎样解码这个sample，例如使用的压缩算法。（注：在目前的使用中，该值为1）

    static moov(meta, trakList) {
        let timescale = meta.timescale;
        let duration = meta.duration;
        let trakLen = trakList.length;
        // Mvhd box定义了整个文件的特性
        let mvhd = MP4.mvhd(timescale, duration);
        let trakArrayBuffer = new Uint8Array();
        for (let i = 0; i < trakLen; i++) {
            let trak = MP4.trak(trakList[i]);
            let arrayBuffer = new Uint8Array(trak.byteLength + trakArrayBuffer.byteLength);
            arrayBuffer.set(trakArrayBuffer, 0);
            arrayBuffer.set(trak, trakArrayBuffer.byteLength);
            trakArrayBuffer = new Uint8Array(arrayBuffer.byteLength);
            trakArrayBuffer.set(arrayBuffer, 0);
        }
        return MP4.box(MP4.types.moov, mvhd, trakArrayBuffer);

    }

    // Movie header box
    // 这里写mp4时需要传入的参数为Time scale 和 Duration，其他的使用默认值即可。
    // MP4文件的整体信息，跟具体的视频流、音频流无关，比如创建时间、文件时长等。
    // mvhd针对整个影片
    // 这里写mp4时需要传入的参数为Time scale 和 Duration，其他的使用默认值即可。
    static mvhd(timescale, duration) {
        //
        return MP4.box(MP4.types.mvhd, new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0xCE, 0xBA, 0xFD, 0xA8,  // creation_time 文件创建时间；
            0xCE, 0xBA, 0xFD, 0xA8,  // modification_time 文件修改时间；
            (timescale >>> 24) & 0xFF,  // timescale: 4 bytes 一秒包含的时间单位（整数）。举个例子，如果timescale等于1000，那么，一秒包含1000个时间单位（后面track等的时间，都要用这个来换算，比如track的duration为10,000，那么，track的实际时长为10,000/1000=10s）；
            (timescale >>> 16) & 0xFF,
            (timescale >>> 8) & 0xFF,
            (timescale) & 0xFF,
            (duration >>> 24) & 0xFF,   // duration: 4 bytes 影片时长（整数），根据文件中的track的信息推导出来，等于时间最长的track的duration
            (duration >>> 16) & 0xFF,
            (duration >>> 8) & 0xFF,
            (duration) & 0xFF,
            0x00, 0x01, 0x00, 0x00,  // Preferred rate: 1.0 推荐的播放速率，32位整数，高16位、低16位分别代表整数部分、小数部分（[16.16]），举例 0x0001 0000 代表1.0，正常播放速度；
            0x01, 0x00, 0x00, 0x00,  // Preferred Volume(1.0, 2bytes) + reserved(2bytes) 播放音量，16位整数，高8位、低8位分别代表整数部分、小数部分（[8.8]），举例 0x01 00 表示 1.0，即最大音量；
            0x00, 0x00, 0x00, 0x00,  // reserved: 4 + 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x01, 0x00, 0x00,  // ----begin composition matrix----
            0x00, 0x00, 0x00, 0x00,  // 视频的转换矩阵，一般可以忽略不计；
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x01, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x40, 0x00, 0x00, 0x00,  // ----end composition matrix----
            0x00, 0x00, 0x00, 0x00,  // ----begin pre_defined 6 * 4 bytes----
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,  // ----end pre_defined 6 * 4 bytes----
            0x00, 0x00, 0x00, 0x03  // next_track_ID: 4 bytes  3 32位整数，非0，一般可以忽略不计。当要添加一个新的track到这个影片时，可以使用的track id，必须比当前已经使用的track id要大。也就是说，添加新的track时，需要遍历所有track，确认可用的track id；
        ]));
    }

    // Track box
    // 一个Track box定义了movie中的一个track。一部movie可以包含一个或多个tracks，它们之间相互独立，各自有各自的时间和空间信息。每个track box 都有与之关联的mdat box。
    // 包含媒体数据引用和描述
    // 包含modifier track
    // 流媒体协议的打包信息（hint trak），引用或者复用对应的媒体sample data。
    // Hint tracks和modifier tracks必须保证完整性，同时和至少一个media track一起存在。
    // 换句话说，即使hint tracks复制了对应的媒体sample data，media tracks 也不能从一部hinted movie中删除。

    // 写mp4时仅用到第一个目的，所以这里只介绍媒体数据的引用和描述。
    // 一个trak box一般主要包含了tkhd box、 edts box 、mdia box
    static trak(meta) {
        return MP4.box(MP4.types.trak, MP4.tkhd(meta), MP4.mdia(meta));
    }

    // Track header box
    // 用来描述trak box的header 信息，定义了一个trak的时间、空间、音量信息。
    static tkhd(meta) {
        let trackId = meta.id,
            duration = meta.duration;
        let width = meta.presentWidth,
            height = meta.presentHeight;
        if (meta.type === 'video') {
            return MP4.box(MP4.types.tkhd, new Uint8Array([
                0x00, 0x00, 0x00, 0x0F,  // version(0) + flags tkhd box的版本；
                0xCE, 0xBA, 0xFD, 0xA8,  // creation_time 当前track的创建时间；
                0xCE, 0xBA, 0xFD, 0xA8,  // modification_time 当前track的最近修改时间；
                (trackId >>> 24) & 0xFF,  // track_ID: 4 bytes 当前track的唯一标识，不能为0，不能重复；
                (trackId >>> 16) & 0xFF,
                (trackId >>> 8) & 0xFF,
                (trackId) & 0xFF,
                0x00, 0x00, 0x00, 0x00,  // reserved: 4 bytes
                (duration >>> 24) & 0xFF, // duration: 4 bytes 当前track的完整时长（需要除以timescale得到具体秒数）；
                (duration >>> 16) & 0xFF,
                (duration >>> 8) & 0xFF,
                (duration) & 0xFF,
                0x00, 0x00, 0x00, 0x00,  // reserved: 2 * 4 bytes
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,  // layer(2bytes) + alternate_group(2bytes)   layer 视频轨道的叠加顺序，数字越小越靠近观看者，比如1比2靠上，0比1靠上； alternate_group 当前track的分组ID，alternate_group值相同的track在同一个分组里面。同个分组里的track，同一时间只能有一个track处于播放状态。当alternate_group为0时，表示当前track没有跟其他track处于同个分组。一个分组里面，也可以只有一个track；
                0x00, 0x00, 0x00, 0x00,  // volume(2bytes) + reserved(2bytes) audio track的音量，介于0.0~1.0之间；
                0x00, 0x01, 0x00, 0x00,  // ----begin composition matrix----
                0x00, 0x00, 0x00, 0x00,  // 视频的变换矩阵； 36
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x01, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x40, 0x00, 0x00, 0x00,  // ----end composition matrix----
                (width >>> 8) & 0xFF,    // width and height 视频的宽高； 4
                (width) & 0xFF,
                0x00, 0x00,
                (height >>> 8) & 0xFF, // width and height 视频的宽高； 4
                (height) & 0xFF,
                0x00, 0x00
            ]));
        } else if (meta.type === 'audio') {
            return MP4.box(MP4.types.tkhd, new Uint8Array([
                0x00, 0x00, 0x00, 0x0F,  // version(0) + flags
                0xCE, 0xBA, 0xFD, 0xA8,  // creation_time
                0xCE, 0xBA, 0xFD, 0xA8,  // modification_time
                (trackId >>> 24) & 0xFF,  // track_ID: 4 bytes
                (trackId >>> 16) & 0xFF,
                (trackId >>> 8) & 0xFF,
                (trackId) & 0xFF,
                0x00, 0x00, 0x00, 0x00,  // reserved: 4 bytes
                (duration >>> 24) & 0xFF, // duration: 4 bytes
                (duration >>> 16) & 0xFF,
                (duration >>> 8) & 0xFF,
                (duration) & 0xFF,
                0x00, 0x00, 0x00, 0x00,  // reserved: 2 * 4 bytes
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,  // layer(2bytes) + alternate_group(2bytes)
                0x01, 0x00, 0x00, 0x00,  // volume(2bytes) + reserved(2bytes) audio track的音量，介于0.0~1.0之间；
                0x00, 0x01, 0x00, 0x00,  // ----begin composition matrix----
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x01, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x00, 0x00, 0x00, 0x00,
                0x40, 0x00, 0x00, 0x00,  // ----end composition matrix----
                0x00, 0x00, 0x00, 0x00,   // audio （8 bytes 0）
                0x00, 0x00, 0x00, 0x00
            ]));
        }

    }

    static edts(meta, i) {
        return MP4.box(MP4.types.edts, MP4.elst(meta, i));
    }

    // 该box为edst box的唯一子box，不是所有的MP4文件都有edst box，这个box是使其对应的trak box的时间戳产生偏移。
    // 暂时未发现需要该偏移量的地方，编码时也未对该box进行编码。
    static elst(meta, i) {
        let videoList = [],
            videoDelayDuration = 0;
        for (let j = 0; j < i; j++) {
            if (meta[j].type === 'video') {
                videoDelayDuration += meta[j].duration;
            }
        }
        let duration = meta[i].duration;
        if (videoDelayDuration === 0) {
            videoDelayDuration = meta[i].refSampleDuration;
        }
        return MP4.box(MP4.types.elst, new Uint8Array([
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x02,
            (videoDelayDuration >>> 24) & 0xFF,   // SampleDuration: 4 bytes
            (videoDelayDuration >>> 16) & 0xFF,
            (videoDelayDuration >>> 8) & 0xFF,
            (videoDelayDuration) & 0xFF,
            0xFF, 0xFF, 0xFF, 0xFF, //media_time
            0x00, 0x01, 0x00, 0x00,  //  media_rate(2byte) + Media rate fraction(3byte)
            (duration >>> 24) & 0xFF,   // Duration: 4 bytes
            (duration >>> 16) & 0xFF,
            (duration >>> 8) & 0xFF,
            (duration) & 0xFF,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x01, 0x00, 0x00
        ]));
    }

    // Media Box
    // 该box定义了trak box的类型和sample的信息。
    static mdia(meta) {
        return MP4.box(MP4.types.mdia, MP4.mdhd(meta), MP4.hdlr(meta), MP4.minf(meta));
    }

    // Media header box
    // mdhd box 定义了该box的timescale
    // 和duration（注：这里的这两个参数与前面说的mvhd有区别，这里的这两个参数都是以一个sample为时间单位的，
    // 例：在只有一个视频trak的情况下，mvhd的timescale为1000，一个sample的duration为40
    // ，那么这里的timescale为1000/40，同理这里的duration算法与之一样理解。） 1000/40 = 25
    static mdhd(meta) {
        let timescale = meta.timescale / meta.refSampleDuration;
        let duration = timescale * meta.duration / meta.timescale;
        return MP4.box(MP4.types.mdhd, new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0xCE, 0xBA, 0xFD, 0xA8,  // creation_time
            0xCE, 0xBA, 0xFD, 0xA8,  // modification_time
            (timescale >>> 24) & 0xFF,  // timescale: 4 bytes
            (timescale >>> 16) & 0xFF,
            (timescale >>> 8) & 0xFF,
            (timescale) & 0xFF,
            (duration >>> 24) & 0xFF,   // duration: 4 bytes
            (duration >>> 16) & 0xFF,
            (duration >>> 8) & 0xFF,
            (duration) & 0xFF,
            0x55, 0xC4,             // language: und (undetermined)
            0x00, 0x00              // pre_defined = 0
        ]));
    }

    // Media handler reference box
    // 声明当前track的类型，以及对应的处理器（handler）。

    /**
     *  vide（0x76 69 64 65），video track；
     soun（0x73 6f 75 6e），audio track；
     hint（0x68 69 6e 74），hint track；
     //Hdlr box 定义了这段trak的媒体处理组件，以下图会更清晰的解释这个box
     * @param meta
     * @returns {null}
     */
    static hdlr(meta) {
        let data = null;
        if (meta.type === 'audio') {
            data = MP4.constants.HDLR_AUDIO;
        } else {
            data = MP4.constants.HDLR_VIDEO;
        }

        return MP4.box(MP4.types.hdlr, data);
    }

    // Media infomation box
    // 该box也是上面的mdia box的子box，其主要用来描述该trak的具体的媒体处理组件内容的。
    static minf(meta) {
        let xmhd = null;
        if (meta.type === 'audio') {
            xmhd = MP4.box(MP4.types.smhd, MP4.constants.SMHD);
        } else {
            xmhd = MP4.box(MP4.types.vmhd, MP4.constants.VMHD);
        }

        return MP4.box(MP4.types.minf, xmhd, MP4.dinf(), MP4.stbl(meta));
    }

    // Data infomation box
    // dinf box 定义了该trak的数据信息，包括了数据的引用方式，数据的存储方式等。
    // dinf box 用来定义媒体处理组件如何获取媒体数据的
    static dinf() {
        return MP4.box(MP4.types.dinf,
            MP4.box(MP4.types.dref, MP4.constants.DREF)
        );
    }

    // Sample table box
    // Sample Table Box（stbl）是上面minf的子box之一，用来定义存放时间/偏移的映射关系，数据信息都在以下子box中
    // 在普通mp4中，在获取数据之前，需要解析每个帧数据所在位置，每个帧数据都存放在mdat中，而这些帧的信息全部存放在stbl box 中，
    // 所以，若要mp4文件能够正常播放，需要在写mp4文件时，将所有的帧数据信息写入 stbl box中。
    // MP4文件的媒体数据部分在mdat box里，而stbl则包含了这些媒体数据的索引以及时间信息，了解stbl对解码、渲染MP4文件很关键。
    // 在MP4文件中，媒体数据被分成多个chunk，每个chunk可包含多个sample，而sample则由帧组成（通常1个sample对应1个帧），关系如下：
    static stbl(meta) {
        let sampleList = meta.samples;
        let sampleToChunk = [
            {
                No: 1,
                num: 0,
                sampleDelte: 1,
                chunkNo: 1,
                duration: sampleList[0].duration
            }
        ];
        let durationList = [sampleList[0].duration];
        let len = sampleList.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < sampleToChunk.length; j++) {
                if (sampleList[i].duration === sampleToChunk[j].duration) {
                    sampleToChunk[j].num++;
                } else {
                    if (durationList.indexOf(sampleList[i].duration) < 0) {
                        durationList.push(sampleList[i].duration);
                        sampleToChunk.push({
                            No: 2,
                            num: 0,
                            sampleDelte: 1,
                            chunkNo: i + 1,
                            duration: sampleList[i].duration
                        });
                    }
                }
            }

        }

        return MP4.box(MP4.types.stbl,  // type: stbl
            MP4.stsd(meta),  // Sample Description Table Sample Description Box用来描述数据的格式，比如视频格式为avc，比如音频格式为aac
            MP4.stts(sampleToChunk), // Time-To-Sample 时间戳和Sample序号映射表
            MP4.stss(sampleList), // 关键帧序号，该box存在于video trak，因为audio trak 中以sample为单位，但多个sample才组成一帧音频，所以在audio trak中无需该box。
            MP4.stsc(sampleToChunk), //Sample-To-Chunk Sample to chunk 的映射表。这个算法比较巧妙，在多个chunk时，该算法较为复杂。在本次使用中未考虑多个chunk的状态，仅考虑整个文件单个chunk的情况。
            MP4.stsz(sampleList), // Sample size Sample Size Boxes 每个Sample大小的表。Stz2是另一种sample size的存储算法，更节省空间，使用时使用其中一种即可，这里使用stsz。原因简单，因为算法容易。
            MP4.stco(sampleToChunk, sampleList)// Chunk offset co64: 每个Chunk位置偏移表，sample的偏移可根据其他box推算出来，co64是指64位的chunk偏移，暂时只使用到32位的，因此这里使用stco即可。
        );

    }

    // 每个sample的时长；
    // stts包含了DTS到sample number的映射表，主要用来推导每个帧的时长。
    static stts(sampleToChunk) {
        let sampleToChunkLen = sampleToChunk.length;
        let stts = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            (sampleToChunkLen >>> 24) & 0xFF,   // entry_count: 4 bytes tts 中包含的entry条目数；
            (sampleToChunkLen >>> 16) & 0xFF,
            (sampleToChunkLen >>> 8) & 0xFF,
            (sampleToChunkLen) & 0xFF
        ]);
        let offset = stts.byteLength, sttsInfo = new Uint8Array(offset + sampleToChunkLen * 8);

        sttsInfo.set(stts, 0);
        for (let index = 0; index < sampleToChunkLen; index++) {
            sttsInfo.set(new Uint8Array([
                (sampleToChunk[index].num >>> 24) & 0xFF,   // samplesPerChunk: 4 bytes
                (sampleToChunk[index].num >>> 16) & 0xFF,
                (sampleToChunk[index].num >>> 8) & 0xFF,
                (sampleToChunk[index].num) & 0xFF,
                (sampleToChunk[index].sampleDelte >>> 24) & 0xFF,   // samplesDescription index: 4 bytes
                (sampleToChunk[index].sampleDelte >>> 16) & 0xFF,
                (sampleToChunk[index].sampleDelte >>> 8) & 0xFF,
                (sampleToChunk[index].sampleDelte) & 0xFF
            ]), offset);
            offset += 8;
        }

        return MP4.box(MP4.types.stts, sttsInfo);
    }

    // 哪些sample是关键帧；
    // mp4文件中，关键帧所在的sample序号。如果没有stss的话，所有的sample中都是关键帧。
    static stss(mdatDataList) {
        let keyFrameMap = [], len = mdatDataList.length;
        for (let i = 0; i < len; i++) {
            if (mdatDataList[i].isKeyframe === true) {
                keyFrameMap.push(i + 1);
            }
        }
        let keyFrameLen = keyFrameMap.length;
        let stss = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            (keyFrameLen >>> 24) & 0xFF,   // entry_count: 4 bytes entry的条目数，可以认为是关键帧的数目；
            (keyFrameLen >>> 16) & 0xFF,
            (keyFrameLen >>> 8) & 0xFF,
            (keyFrameLen) & 0xFF
        ]);
        let offset = stss.byteLength, stssInfo = new Uint8Array(offset + keyFrameLen * 4);

        stssInfo.set(stss, 0);
        for (let index = 0; index < keyFrameLen; index++) {
            stssInfo.set(new Uint8Array([
                (keyFrameMap[index] >>> 24) & 0xFF,   // entry_count: 4 bytes
                (keyFrameMap[index] >>> 16) & 0xFF,
                (keyFrameMap[index] >>> 8) & 0xFF,
                (keyFrameMap[index]) & 0xFF
            ]), offset);
            offset += 4;
        }

        return MP4.box(MP4.types.stss, stssInfo);
    }

    // 每个thunk中包含几个sample；
    // sample 以 chunk 为单位分成多个组。chunk的size可以是不同的，chunk里面的sample的size也可以是不同的。
    static stsc(sampleToChunk) {
        let sampleToChunkLen = sampleToChunk.length;
        let stsc = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            (sampleToChunkLen >>> 24) & 0xFF,   // entry_count: 4 bytes 有多少个表项（每个表项，包含first_chunk、samples_per_chunk、sample_description_index信息）；
            (sampleToChunkLen >>> 16) & 0xFF,
            (sampleToChunkLen >>> 8) & 0xFF,
            (sampleToChunkLen) & 0xFF
        ]);
        let offset = stsc.byteLength, stscInfo = new Uint8Array(offset + sampleToChunkLen * 12);

        stscInfo.set(stsc, 0);
        for (let index = 0; index < sampleToChunkLen; index++) {
            let firstChunk = sampleToChunk[index].chunkNo,
                samplesPerChunk = sampleToChunk[index].num,
                sampleDelte = sampleToChunk[index].sampleDelte;
            stscInfo.set(new Uint8Array([
                (firstChunk >>> 24) & 0xFF,   // firstChunk: 4 bytes 当前表项中，对应的第一个chunk的序号；
                (firstChunk >>> 16) & 0xFF,
                (firstChunk >>> 8) & 0xFF,
                (firstChunk) & 0xFF,
                (samplesPerChunk >>> 24) & 0xFF,   // samplesPerChunk: 4 bytes 每个chunk包含的sample数；
                (samplesPerChunk >>> 16) & 0xFF,
                (samplesPerChunk >>> 8) & 0xFF,
                (samplesPerChunk) & 0xFF,
                (sampleDelte >>> 24) & 0xFF,   // samplesDescription index: 4 bytes 指向 stsd 中 sample description 的索引值（参考stsd小节）；
                (sampleDelte >>> 16) & 0xFF,
                (sampleDelte >>> 8) & 0xFF,
                (sampleDelte) & 0xFF
            ]), offset);
            offset += 12;
        }

        return MP4.box(MP4.types.stsc, stscInfo);
    }

    // 每个sample的size（单位是字节）；
    // 每个sample的大小（字节），根据 sample_size 字段，可以知道当前track包含了多少个sample（或帧）。
    static stsz(mdatDataList) {
        let len = mdatDataList.length;
        let stsz = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            0x00, 0x00, 0x00, 0x00,  // sample size    当所有sample的size都一样时，该值为sample的size ，否则为0
            (len >>> 24) & 0xFF,   // sample count: 4 bytes  当前track里面的sample数目。如果 sample_size==0，那么，sample_count 等于下面entry的条目；
            (len >>> 16) & 0xFF,
            (len >>> 8) & 0xFF,
            (len) & 0xFF
        ]);
        let offset = stsz.byteLength, stszInfo = new Uint8Array(offset + len * 4);

        stszInfo.set(stsz, 0);
        for (let i = 0; i < len; i++) {
            let data = mdatDataList[i].data;
            let dataLen = data.byteLength;
            stszInfo.set(new Uint8Array([
                (dataLen >>> 24) & 0xFF,   //per  sample size: 4 bytes
                (dataLen >>> 16) & 0xFF,
                (dataLen >>> 8) & 0xFF,
                (dataLen) & 0xFF
            ]), offset);
            offset += 4;
        }

        return MP4.box(MP4.types.stsz, stszInfo);

    }

    // thunk在文件中的偏移；
    //  存储了该 track 中每个 chunk 在文件中的偏移。
    static stco(sampleToChunk, mdatDataList) {
        let offset = mdatDataList[0].chunkOffset;

        return MP4.box(MP4.types.stco, new Uint8Array([
            0x00, 0x00, 0x00, 0x00, // version(0) + flags
            0x00, 0x00, 0x00, 0x01, // entry_count: 4 bytes // 默认只有视频时，只有一段chunk
            (offset >>> 24) & 0xFF,   // samplesPerChunk: 4 bytes
            (offset >>> 16) & 0xFF,
            (offset >>> 8) & 0xFF,
            (offset) & 0xFF
        ]));
    }


    // Sample description box
    // 给出视频、音频的编码、宽高、音量等信息，以及每个sample中包含多少个frame；
    static stsd(meta) {
        if (meta.type === 'audio') {
            //
            if (meta.codec === 'mp3') {
                return MP4.box(MP4.types.stsd, MP4.constants.STSD_PREFIX, MP4.mp3(meta));
            }
            // else: aac -> mp4a
            return MP4.box(MP4.types.stsd, MP4.constants.STSD_PREFIX, MP4.mp4a(meta));
        } else {
            if (meta.videoType === 'avc') {
                return MP4.box(MP4.types.stsd, MP4.constants.STSD_PREFIX, MP4.avc1(meta));
            } else {
                return MP4.box(MP4.types.stsd, MP4.constants.STSD_PREFIX, MP4.hvc1(meta))
            }
            //
        }

    }

    static mp3(meta) {
        let channelCount = meta.channelCount;
        let sampleRate = meta.sampleRate;

        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // reserved(4)
            0x00, 0x00, 0x00, 0x01,  // reserved(2) + data_reference_index(2)
            0x00, 0x00, 0x00, 0x00,  // reserved: 2 * 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, channelCount,      // channelCount(2)
            0x00, 0x10,              // sampleSize(2)
            0x00, 0x00, 0x00, 0x00,  // reserved(4)
            (sampleRate >>> 8) & 0xFF,  // Audio sample rate
            (sampleRate) & 0xFF,
            0x00, 0x00
        ]);

        return MP4.box(MP4.types['.mp3'], data);
    }

    static mp4a(meta) {
        let channelCount = meta.channelCount;
        let sampleRate = meta.sampleRate;

        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // reserved(4)
            0x00, 0x00, 0x00, 0x01,  // reserved(2) + data_reference_index(2)
            0x00, 0x00, 0x00, 0x00,  // reserved: 2 * 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, channelCount,      // channelCount(2)
            0x00, 0x10,              // sampleSize(2)
            0x00, 0x00, 0x00, 0x00,  // reserved(4)
            (sampleRate >>> 8) & 0xFF,  // Audio sample rate
            (sampleRate) & 0xFF,
            0x00, 0x00
        ]);

        return MP4.box(MP4.types.mp4a, data, MP4.esds(meta));
    }

    static esds(meta) {
        let config = meta.config || [];
        let configSize = config.length;
        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version 0 + flags

            0x03,                    // descriptor_type
            0x17 + configSize,       // length3
            0x00, 0x01,              // es_id
            0x00,                    // stream_priority

            0x04,                    // descriptor_type
            0x0F + configSize,       // length
            0x40,                    // codec: mpeg4_audio
            0x15,                    // stream_type: Audio
            0x00, 0x00, 0x00,        // buffer_size
            0x00, 0x00, 0x00, 0x00,  // maxBitrate
            0x00, 0x00, 0x00, 0x00,  // avgBitrate

            0x05                     // descriptor_type
        ].concat([
            configSize
        ]).concat(
            config
        ).concat([
            0x06, 0x01, 0x02         // GASpecificConfig
        ]));
        return MP4.box(MP4.types.esds, data);
    }

    static avc1(meta) {
        let avcc = meta.avcc;
        let width = meta.codecWidth,
            height = meta.codecHeight;

        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // reserved(4)
            0x00, 0x00, 0x00, 0x01,  // reserved(2) + data_reference_index(2)
            0x00, 0x00, 0x00, 0x00,  // pre_defined(2) + reserved(2)
            0x00, 0x00, 0x00, 0x00,  // pre_defined: 3 * 4 bytes
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            (width >>> 8) & 0xFF,    // width: 2 bytes
            (width) & 0xFF,
            (height >>> 8) & 0xFF,   // height: 2 bytes
            (height) & 0xFF,
            0x00, 0x48, 0x00, 0x00,  // horizresolution: 4 bytes
            0x00, 0x48, 0x00, 0x00,  // vertresolution: 4 bytes
            0x00, 0x00, 0x00, 0x00,  // reserved: 4 bytes
            0x00, 0x01,              // frame_count
            0x0D,                    // strlen  10bytes
            0x6a, 0x65, 0x73, 0x73,  // compressorname: 32 bytes
            0x69, 0x62, 0x75, 0x63,
            0x61, 0x2d, 0x70, 0x72,
            0x6f, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00, 0x00,
            0x00, 0x00, 0x00,
            0x00, 0x18,              // depth
            0xFF, 0xFF               // pre_defined = -1
        ]);
        return MP4.box(MP4.types.avc1, data, MP4.box(MP4.types.avcC, avcc));
    }


    // hvc
    static hvc1(meta) {
        let avcc = meta.avcc;
        const width = meta.codecWidth;
        const height = meta.codecHeight;
        let data = new Uint8Array([
            0, 0, 0, 0,
            0, 0, 0, 1,
            0, 0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0, 0,
            width >>> 8 & 255,
            width & 255,
            height >>> 8 & 255,
            height & 255,
            0, 72, 0, 0,
            0, 72, 0, 0,
            0, 0, 0, 0,
            0, 1,
            13,
            106, 101, 115, 115,
            105, 98, 117, 99,
            97, 45, 112, 114,
            111, 0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0,
            0, 24,
            255, 255
        ]);
        return MP4.box(MP4.types.hvc1, data, MP4.box(MP4.types.hvcC, avcc))
    }

    // Movie Extends box
    static mvex(meta) {
        return MP4.box(MP4.types.mvex, MP4.trex(meta));
    }

    // Track Extends box
    // 用来给 fMP4 的 sample 设置各种默认值，比如时长、大小等。
    static trex(meta) {
        let trackId = meta.id;
        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) + flags
            (trackId >>> 24) & 0xFF, // track_ID 对应的 track 的 ID，比如video track、audio track 的ID；
            (trackId >>> 16) & 0xFF,
            (trackId >>> 8) & 0xFF,
            (trackId) & 0xFF,
            0x00, 0x00, 0x00, 0x01,  // default_sample_description_index sample description 的默认 index（指向stsd）；
            0x00, 0x00, 0x00, 0x00,  // default_sample_duration sample 默认时长，一般为0；
            0x00, 0x00, 0x00, 0x00,  // default_sample_size  sample 默认大小，一般为0；
            0x00, 0x01, 0x00, 0x01   // default_sample_flags sample 的默认flag，一般为0；
        ]);
        return MP4.box(MP4.types.trex, data);
    }

    // Movie fragment box
    // moof是个container box，相关 metadata 在内嵌box里，比如 mfhd、 tfhd、trun 等。
    static moof(meta, baseMediaDecodeTime) {
        return MP4.box(MP4.types.moof, MP4.mfhd(meta.sequenceNumber), MP4.traf(meta, baseMediaDecodeTime));
    }

    // 结构比较简单，sequence_number 为 movie fragment 的序列号。根据 movie fragment 产生的顺序，从1开始递增。
    static mfhd(sequenceNumber) {
        let data = new Uint8Array([
            0x00, 0x00, 0x00, 0x00,
            (sequenceNumber >>> 24) & 0xFF,  // sequence_number: int32
            (sequenceNumber >>> 16) & 0xFF,
            (sequenceNumber >>> 8) & 0xFF,
            (sequenceNumber) & 0xFF
        ]);
        return MP4.box(MP4.types.mfhd, data);
    }

    // Track fragment box
    // 对 fmp4 来说，数据被氛围多个 movie fragment。一个 movie fragment 可包含多个track fragment（每个 track 包含0或多个 track fragment）。每个 track fragment 中，可以包含多个该 track 的 sample。
    // 每个 track fragment 中，包含多个 track run，每个 track run 代表一组连续的 sample。
    static traf(meta, baseMediaDecodeTime) {
        let trackId = meta.id;

        // Track fragment header box
        // tfhd 用来设置 track fragment 中 的 sample 的 metadata 的默认值。
        let tfhd = MP4.box(MP4.types.tfhd, new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) & flags
            (trackId >>> 24) & 0xFF, // track_ID
            (trackId >>> 16) & 0xFF,
            (trackId >>> 8) & 0xFF,
            (trackId) & 0xFF
        ]));
        // Track Fragment Decode Time
        let tfdt = MP4.box(MP4.types.tfdt, new Uint8Array([
            0x00, 0x00, 0x00, 0x00,  // version(0) & flags
            (baseMediaDecodeTime >>> 24) & 0xFF,  // baseMediaDecodeTime: int32
            (baseMediaDecodeTime >>> 16) & 0xFF,
            (baseMediaDecodeTime >>> 8) & 0xFF,
            (baseMediaDecodeTime) & 0xFF
        ]));
        let sdtp = MP4.sdtp(meta);
        let trun = MP4.trun(meta, sdtp.byteLength + 16 + 16 + 8 + 16 + 8 + 8);

        return MP4.box(MP4.types.traf, tfhd, tfdt, trun, sdtp);
    }

    // Sample Dependency Type box
    static sdtp(meta) {
        let samples = meta.samples || [];
        let sampleCount = samples.length;
        let data = new Uint8Array(4 + sampleCount);
        // 0~4 bytes: version(0) & flags
        for (let i = 0; i < sampleCount; i++) {
            let flags = samples[i].flags;
            data[i + 4] = (flags.isLeading << 6)    // is_leading: 2 (bit)
                | (flags.dependsOn << 4)    // sample_depends_on
                | (flags.isDependedOn << 2) // sample_is_depended_on
                | (flags.hasRedundancy);    // sample_has_redundancy
        }
        return MP4.box(MP4.types.sdtp, data);
    }

    // Track fragment run box
    static trun(meta, offset) {
        let samples = meta.samples || [];
        let sampleCount = samples.length;
        let dataSize = 12 + 16 * sampleCount;
        let data = new Uint8Array(dataSize);
        offset += 8 + dataSize;

        data.set([
            0x00, 0x00, 0x0F, 0x01,      // version(0) & flags
            (sampleCount >>> 24) & 0xFF, // sample_count
            (sampleCount >>> 16) & 0xFF,
            (sampleCount >>> 8) & 0xFF,
            (sampleCount) & 0xFF,
            (offset >>> 24) & 0xFF,      // data_offset
            (offset >>> 16) & 0xFF,
            (offset >>> 8) & 0xFF,
            (offset) & 0xFF
        ], 0);

        for (let i = 0; i < sampleCount; i++) {
            let duration = samples[i].duration;
            let size = samples[i].size;
            let flags = samples[i].flags;
            let cts = samples[i].cts;
            data.set([
                (duration >>> 24) & 0xFF,  // sample_duration
                (duration >>> 16) & 0xFF,
                (duration >>> 8) & 0xFF,
                (duration) & 0xFF,
                (size >>> 24) & 0xFF,      // sample_size
                (size >>> 16) & 0xFF,
                (size >>> 8) & 0xFF,
                (size) & 0xFF,
                (flags.isLeading << 2) | flags.dependsOn,  // sample_flags
                (flags.isDependedOn << 6) | (flags.hasRedundancy << 4) | flags.isNonSync,
                0x00, 0x00,                // sample_degradation_priority
                (cts >>> 24) & 0xFF,       // sample_composition_time_offset
                (cts >>> 16) & 0xFF,
                (cts >>> 8) & 0xFF,
                (cts) & 0xFF
            ], 12 + 16 * i);
        }
        return MP4.box(MP4.types.trun, data);
    }

    // Media Data Box，存放实际的媒体数据，一般有多个
    // Mdat box 中包含了MP4文件的媒体数据，在文件中的位置可以在moov的前面，也可以在moov的后面，
    // 因我们这里用到MP4文件格式用来写mp4文件，需要计算每一帧媒体数据在文件中的偏移量，为了方便计算，mdat放置moov前面。
    // Mdat box数据格式单一，无子box。主要分为box header 和box body，box header中存放box size 和box type（mdat），box body中存放所有媒体数据，媒体数据以sample为数据单元。
    // 这里使用时，视频数据中，每一个sample是一个视频帧，存放sample时，需要根据帧数据类型进行拼帧处理后存放。
    static mdat(data) {
        return MP4.box(MP4.types.mdat, data);
    }
}

MP4.init();

export default MP4;
