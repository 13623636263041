import Emitter from "../utils/emitter";
import {
    CONTROL_HEIGHT,
    CONTROL_PLAYBACK_HEIGHT,
    EVENTS, EVENTS_ERROR, MSE_DELAY_INCREASE_TIME, MSE_MAX_DELAY_TIME,
    PLAY_TYPE,
    RENDER_TYPE, SCREENSHOT_FORMAT_TYPE,
    SCREENSHOT_TYPE, VIDEO_ELEMENT_RETRY_PLAY_MAX_TIME, VIDEO_ENC_CODE,
    VIDEO_ENC_TYPE, VIDEO_ERROR_CODE_DESC
} from "../constant";
import {
    base64ToBlob,
    closeVideoFrame, createCanvasImage,
    createImageWatermark,
    createVideoFrame, dataURLToFile,
    getBrowser, isAndroid, isFalse, isFirefox, isFunction, isIOS,
    isMobile,
    isString, isTrue,
    now, supportMediaStream,
    supportMediaStreamTrack, supportVideoFrameCallback
} from "../utils";
import CommonLoader from "./commonLoader";
import saveAs from "../utils/file-save";

export default class VideoLoader extends CommonLoader {
    constructor(player) {
        super();
        this.player = player;
        this.TAG_NAME = 'Video';
        const $videoElement = document.createElement('video');
        const $canvasElement = document.createElement('canvas');
        $videoElement.muted = true;
        $videoElement.style.position = "absolute";
        $videoElement.style.top = 0;
        $videoElement.style.left = 0;
        this._delayPlay = false;
        player.$container.appendChild($videoElement);
        this.$videoElement = $videoElement;
        this.$canvasElement = $canvasElement;
        this.canvasContext = $canvasElement.getContext('2d');
        this.mediaStream = null;
        this.vwriter = null;
        if (player.canVideoTrackWritter() &&
            supportMediaStreamTrack() &&
            supportMediaStream()) {
            this.trackGenerator = new MediaStreamTrackGenerator({kind: 'video'});
            this.mediaStream = new MediaStream([this.trackGenerator]);
            $videoElement.srcObject = this.mediaStream;
            this.vwriter = this.trackGenerator.writable.getWriter();
        }
        this.fixChromeVideoFlashBug();
        this.fixMobileAutoFullscreen();
        this.resize();
        this.eventListenList = [];
        this.isRenderRetryPlaying = false;
        this.isRenderRetryPlayingTimes = 0;
        this.isRetryPlaying = false;
        this.isRetryPlayingTimes = 0;
        this.checkVideoCanplayTimeout = null;
        const isSupportVideoFrameCallback = supportVideoFrameCallback();
        this.supportVideoFrameCallbackHandle = null;

        const {proxy} = this.player.events;

        const canplayProxyDestroy = proxy(this.$videoElement, 'canplay', () => {
            this.player.debug.log('Video', 'canplay');
            if (this._delayPlay) {
                this.clearCheckVideoCanplayTimeout();
                this._play();
            }
        })

        const waitingProxyDestroy = proxy(this.$videoElement, 'waiting', () => {
            // this.player.emit(EVENTS.videoWaiting);
            this.player.debug.log('Video', 'waiting');
        })

        const loadedmetadataDestroy = proxy(this.$videoElement, 'loadedmetadata', () => {
            this.player.debug.log('Video', 'loadedmetadata');
        })

        //  视频的进度条都是250毫秒更新一次，所以这里也是250毫秒更新一次
        const timeupdateProxyDestroy = proxy(this.$videoElement, 'timeupdate', (event) => {
            // this.player.debug.log('Video', 'timeupdate', event.timeStamp);
            //
            if (isFalse(isSupportVideoFrameCallback)) {
                //  ms
                const timeStamp = parseInt(event.timeStamp * 1000, 10);
                //
                if (player.isWebrtcH264() ||
                    this.player.isOldHls() ||
                    this.player.isAliyunRtc()) {

                    this.player.emit(EVENTS.timeUpdate, timeStamp);
                    player.handleRender();
                    //
                    player.updateStats({
                        fps: true,
                        ts: timeStamp,
                        dts: timeStamp
                    })
                }
            }
        })

        const errorProxyDestroy = proxy(this.$videoElement, 'error', () => {
            this.player.debug.error('Video', "Error Code " + this.$videoElement.error.code + ' ' + VIDEO_ERROR_CODE_DESC[this.$videoElement.error.code] + "; Details: " + this.$videoElement.error.message);
            // for hls video play
            if (this.player.isHlsCanVideoPlay()) {

            }
        })
        this.eventListenList.push(canplayProxyDestroy, waitingProxyDestroy, timeupdateProxyDestroy, errorProxyDestroy, loadedmetadataDestroy);

        //  如果支持videoFrameCallback，那么就使用videoFrameCallback
        if (supportVideoFrameCallback()) {
            this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
        } else {
            this.player.debug.warn('Video', 'not support requestVideoFrameCallback and use timeupdate event to update stats');
        }
        this.player.debug.log('Video', 'init')
    }

    destroy() {
        super.destroy();
        this.clearCheckVideoCanplayTimeout();
        if (this.supportVideoFrameCallbackHandle && this.$videoElement) {
            this.$videoElement.cancelVideoFrameCallback(this.supportVideoFrameCallbackHandle);
            this.supportVideoFrameCallbackHandle = null;
        }

        if (this.eventListenList) {
            this.eventListenList.forEach((item) => {
                item();
            })
            this.eventListenList = [];
        }
        this.isRenderRetryPlaying = false;
        this.isRenderRetryPlayingTimes = 0;
        this.isRetryPlaying = false;
        this.isRetryPlayingTimes = 0;
        if (this.player._opt.videoRenderSupportScale &&
            this._isNeedAddBackDropFilter()) {
            const $container = this.player.$container;
            $container.style.backdropFilter = 'none';
            $container.style.transform = 'none';
        }
        this.$canvasElement.height = 0;
        this.$canvasElement.width = 0;
        this.$canvasElement = null;
        this.canvasContext = null;
        if (this.$videoElement) {
            this.$videoElement.pause();
            this.$videoElement.currentTime = 0;
            if (this.$videoElement.srcObject) {
                this.$videoElement.srcObject = null;
                this.$videoElement.removeAttribute('srcObject');
            }
            if (this.$videoElement.src) {
                this.$videoElement.src = '';
                this.$videoElement.removeAttribute('src');
            }
            try {
                this.$videoElement.load();
            } catch (e) {
                // ignore
            }
            this.player.$container.removeChild(this.$videoElement);
            this.$videoElement = null;
        }
        if (this.trackGenerator) {
            this.trackGenerator.stop();
            this.trackGenerator = null;
        }
        if (this.vwriter) {
            this.vwriter.close();
            this.vwriter = null;
        }
        this._delayPlay = false;
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach(track => track.stop());
            this.mediaStream = null;
        }
        this.off();
        this.player.debug.log('Video', 'destroy');
    }

    videoFrameCallback(now, metaData = {}) {
        if (this.isDestroyed) {
            this.player.debug.log('Video', 'videoFrameCallback() and isDestroyed');
            return;
        }

        // this.player.debug.log('Video', 'videoFrameCallback', now, metaData);
        /**
         * metaData:
         *  presentationTime:用户代理提交帧以进行合成的时间。
         *  expectedDisplayTime:用户代理预计将显示帧的时间。
         *  width:帧的宽度。
         *  height:帧的高度。
         *  mediaTime:帧的媒体时间。它在video.currentTime时间线上的时间戳
         *  presentedFrames:自用户代理启动以来已提交的帧数。
         *  processingDuration:用户代理处理帧的时间。
         */
        this.player.handleRender();
        const ts = parseInt((Math.max(metaData.mediaTime, this.getCurrentTime())) * 1000, 10) || 0;

        if (this.player.isUseHls265() &&
            this.player.isUseMSE()) {
            this.player.updateStats({
                fps: true,
                ts: ts,
            })
        }

        if (this.player.isWebrtcH264() ||
            this.player.isOldHls() ||
            this.player.isAliyunRtc()) {
            this.player.emit(EVENTS.timeUpdate, ts);
            // update width and height
            if (isFalse(this.getHasInit()) &&
                metaData.width &&
                metaData.height) {
                const info = {
                    width: metaData.width,
                    height: metaData.height,
                }

                //  hls 会有encTypeCode
                if (!this.videoInfo.encTypeCode &&
                    !this.player.isOldHls()) {
                    info.encTypeCode = VIDEO_ENC_CODE.h264;
                }

                this.updateVideoInfo(info);
            }
            //
            this.player.updateStats({
                fps: true,
                ts: ts,
                dts: ts
            })
            this.player.updateCurrentPts(ts);
            this.doAddContentToWatermark();
        } else if (isTrue(this.player._opt.useMSE) &&
            isFalse(this.player._opt.mseUseCanvasRender)) {
            if (this.player.mseDecoder) {
                let ts = parseInt((Math.max(metaData.mediaTime, this.getCurrentTime())) * 1000, 10) + (this.player.mseDecoder.firstRenderTime || 0);
                this.player.updateCurrentPts(ts);
            }

            this.doAddContentToWatermark();
        }
        this.supportVideoFrameCallbackHandle = this.$videoElement.requestVideoFrameCallback(this.videoFrameCallback.bind(this));
    }

    /**
     * 修复chrome系浏览器，在设置video的objectFit属性后，video标签会无缘无故的闪烁，
     * 但是添加了backdropFilter属性之后，就不会闪烁了。
     */
    fixChromeVideoFlashBug() {
        if (this.player._opt.videoRenderSupportScale) {
            if (this._isNeedAddBackDropFilter()) {
                const $container = this.player.$container;
                $container.style.backdropFilter = 'blur(0px)';
                $container.style.transform = 'translateZ(0)';
            }
        }
    }

    //  使用video标签在html5中播放视频时，视频自动全屏播放
    //  ios端video标签必须加webkit-playsinline、playsinline属性
    // android端部分视频也会存在自动全屏问题，添加webkit-playsinline属性
    fixMobileAutoFullscreen() {
        const _isIos = isIOS();
        const _isAndroid = isAndroid();
        if (_isIos || _isAndroid) {
            this.player.debug.log('Video', `fixMobileAutoFullscreen and isIOS ${_isIos} and isAndroid ${_isAndroid}`);
            // 解决视频在ios端播放默认全屏问题
            this.$videoElement.setAttribute('webkit-playsinline', 'true');
            this.$videoElement.setAttribute('playsinline', 'true');

            // 解决视频在android端播放默认全屏问题
            this.$videoElement.setAttribute('x5-video-player-type', 'h5-page');
        }
    }


    _isNeedAddBackDropFilter() {
        const browser = getBrowser();
        const type = browser.type.toLowerCase();
        if (type === 'chrome' || type === 'edge') {
            return true;
        }
        return false;
    }

    isPaused() {
        let result = true;
        if (this.$videoElement) {
            result = this.$videoElement.paused;
        }
        return result;
    }

    isPause() {
        return this.isPaused();
    }

    /**
     * HAVE_NOTHING    0    没有关于音频/视频是否就绪的信息
     * HAVE_METADATA    1    音频/视频已初始化
     * HAVE_CURRENT_DATA    2    数据已经可以播放 (当前位置已经加载) 但没有数据能播放下一帧的内容
     * HAVE_FUTURE_DATA    3    当前及至少下一帧的数据是可用的 (换句话来说至少有两帧的数据)
     * HAVE_ENOUGH_DATA    4    可用数据足以开始播放 - 如果网速得到保障 那么视频可以一直播放到底
     */
    _getVideoReadyState() {
        let result = 0;
        if (this.$videoElement) {
            result = this.$videoElement.readyState;
        }
        return result;
    }

    /**
     * get video current time
     * @returns {number}
     * @private
     */
    _getVideoCurrentTime() {
        let result = 0;
        if (this.$videoElement) {
            result = this.$videoElement.currentTime;
        }
        return result;
    }

    play() {
        if (this.$videoElement) {
            const readyState = this._getVideoReadyState();
            this.player.debug.log('Video', `play and readyState: ${readyState}`);

            if (readyState === 0) {
                this.player.debug.log('Video', 'readyState is 0 and set _delayPlay to true and listen canplay event to play');
                this._delayPlay = true;
                //  延迟去检查下readyState是否变成了4，如果没有变成canplay状态，则需要手动尝试播放看看，如果播放失败了，则需要做降级处理。
                if (this.checkVideoCanplayTimeout === null) {
                    this.checkVideoCanplayTimeout = setTimeout(() => {
                        this.clearCheckVideoCanplayTimeout();
                        if (this.player.isDestroyed()) {
                            return;
                        }

                        //  如果还没有可以播放
                        if (isFalse(this.isPlaying())) {
                            const bufferStore = this._getBufferStore();
                            this.player.debug.warn('Video', `checkVideoCanplayTimeout and video is not playing and buffer store is ${bufferStore} and retry play`);
                            this.$videoElement.currentTime = bufferStore;
                            this._replay();
                        }
                    }, 1 * 1000);
                }

                return;
            }
            this._play();
        }
    }


    _play() {
        this.$videoElement && this.$videoElement.play().then(() => {
            this._delayPlay = false;

            this.player.debug.log('Video', '_play success');
            if (!this.isPlaying()) {
                setTimeout(() => {
                    this._replay();
                }, 100)
            } else {
                //  play success
                this.player.emit(EVENTS.removeLoadingBgImage);
                //  reset retry times
                this.isRetryPlayingTimes = 0;
                this.isRetryPlaying = false;
            }
        }).catch((e) => {
            if (this.player.isDestroyed()) {
                this.player.debug.log('Video', '_play error and player is destroyed and return');
                return;
            }
            this.player.debug.error('Video', '_play error', e);
            this.isRetryPlaying = false;
            // todo try to play again
            setTimeout(() => {
                this._replay();
            }, 100)
        })
    }

    _replay() {

        if (!this.isPlaying() &&
            isFalse(this.player.isDestroyed()) &&
            isFalse(this.isRetryPlaying)) {
            this.isRetryPlaying = true;
            if (this.isRetryPlayingTimes >= VIDEO_ELEMENT_RETRY_PLAY_MAX_TIME) {
                if (this.player.isWebrtcH264()) {
                    this.player.debug.error('Video', `_replay(webrtc H264) then but not playing and retry play times is ${this.isRetryPlayingTimes} and emit error`)
                    this.player.emitError(EVENTS_ERROR.videoElementPlayingFailedForWebrtc);
                } else {
                    this.player.debug.error('Video', `_replay then but not playing and retry play times is ${this.isRetryPlayingTimes} and emit error to use canvas render`)
                    this.player.emitError(EVENTS_ERROR.videoElementPlayingFailed)
                }
                return;
            }

            this.player.debug.warn('Video', `_play then but not playing and retry play and isRetryPlayingTimes is ${this.isRetryPlayingTimes}`)
            this._play();
            this.isRetryPlayingTimes++;
        } else {
            this.player.debug.log('Video', `_replay() and isPlaying is ${this.isPlaying()} and isRetryPlaying is ${this.isRetryPlaying} and isDestroyed is ${this.player.isDestroyed()} and return;`);
        }
    }

    pause(isNow) {
        // 预防
        // https://developer.chrome.com/blog/play-request-was-interrupted/
        // http://alonesuperman.com/?p=23
        if (this.isPlaying()) {
            if (isNow) {
                this.$videoElement && this.$videoElement.pause();
            } else {
                setTimeout(() => {
                    this.$videoElement && this.$videoElement.pause();
                }, 100)
            }
        }
    }

    clearView() {
        super.clearView();
        if (this.$videoElement) {
            this.$videoElement.pause();
            this.$videoElement.currentTime = 0;
            if (this.$videoElement.src) {
                this.$videoElement.src = '';
                this.$videoElement.removeAttribute('src');
            }
            if (this.$videoElement.srcObject) {
                this.$videoElement.srcObject = null;
                this.$videoElement.removeAttribute('srcObject');
            }
        }
    }

    screenshot(filename, format, quality, type) {

        if (!this._canScreenshot()) {
            this.player.debug.warn('Video', 'screenshot failed, video is not ready');
            return null;
        }

        filename = filename || now();
        type = type || SCREENSHOT_TYPE.download;
        let encoderOptions = 0.92;
        if (!SCREENSHOT_FORMAT_TYPE[format] && SCREENSHOT_TYPE[format]) {
            type = format;
            format = 'png';
            quality = undefined
        }
        if (typeof quality === "string") {
            type = quality;
            quality = undefined;
        }

        if (typeof quality !== 'undefined') {
            encoderOptions = Number(quality);
        }
        const $video = this.$videoElement;
        let canvas = this.$canvasElement;
        canvas.width = $video.videoWidth;
        canvas.height = $video.videoHeight;
        this.canvasContext.drawImage($video, 0, 0, canvas.width, canvas.height);
        const formatType = SCREENSHOT_FORMAT_TYPE[format] || SCREENSHOT_FORMAT_TYPE.png
        const dataURL = canvas.toDataURL(formatType, encoderOptions);
        // release memory
        this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = 0;
        canvas.height = 0;

        if (type === SCREENSHOT_TYPE.base64) {
            return dataURL;
        } else {
            const file = dataURLToFile(dataURL);
            if (type === SCREENSHOT_TYPE.blob) {
                return file;
            } else if (type === SCREENSHOT_TYPE.download) {
                const suffix = formatType.split('/')[1];
                saveAs(file, filename + '.' + suffix);
            }
        }
    }

    screenshotWatermark(options) {
        return new Promise((resolve, reject) => {
            if (isString(options)) {
                options = {
                    filename: options
                }
            }
            if (!this._canScreenshot()) {
                this.player.debug.warn('Video', 'screenshot failed, video is not ready');
                return reject('screenshot failed, video is not ready');
            }
            const $video = this.$videoElement;
            options = options || {}
            options.width = $video.videoWidth;
            options.height = $video.videoHeight;
            options.filename = options.filename || now();
            options.format = options.format ? SCREENSHOT_FORMAT_TYPE[options.format] : SCREENSHOT_FORMAT_TYPE.png
            options.quality = Number(options.quality) || 0.92
            options.type = options.type || SCREENSHOT_TYPE.download;
            let canvas = this.$canvasElement;
            canvas.width = $video.videoWidth;
            canvas.height = $video.videoHeight;
            this.canvasContext.drawImage($video, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL(options.format, options.quality);
            // release memory
            this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = 0;
            canvas.height = 0;

            createImageWatermark(dataURL, options).then((dataURL2) => {
                if (options.type === SCREENSHOT_TYPE.base64) {
                    resolve(dataURL);
                } else {
                    const file = dataURLToFile(dataURL2)
                    if (options.type === SCREENSHOT_TYPE.blob) {
                        resolve(file);
                    } else if (options.type === SCREENSHOT_TYPE.download) {
                        resolve();
                        const suffix = options.format.split('/')[1];
                        saveAs(file, options.filename + '.' + suffix);
                    }
                }
            }).catch((e) => {
                reject(e);
            })
        })
    }

    initCanvasViewSize() {
        this.resize();
    }

    clear() {
        const $video = this.$videoElement;
        const ranges = $video.buffered;
        const buffered = ranges.length ? ranges.end(ranges.length - 1) : 0;
        $video.currentTime = buffered;
    }

    //
    render(msg) {
        //  for wcs or wasm
        if (this.vwriter) {
            //
            if (!this.$videoElement.srcObject) {
                this.$videoElement.srcObject = this.mediaStream;
            }
            if (this.isPaused()) {
                const readyState = this._getVideoReadyState();
                this.player.debug.warn('Video', 'render() error, video is paused and readyState is ' + readyState);

                if (readyState === 4 && isFalse(this.isRenderRetryPlaying)) {
                    this.isRenderRetryPlaying = true;

                    if (this.isRenderRetryPlayingTimes > VIDEO_ELEMENT_RETRY_PLAY_MAX_TIME) {
                        this.player.debug.error('Video', 'render() error, video is paused and readyState is ' + readyState + ', retry times is ' + this.isRenderRetryPlayingTimes + ', emit error and use canvas render');
                        this.player.emitError(EVENTS_ERROR.videoElementPlayingFailed);
                        return;
                    }

                    this.$videoElement.play().then(() => {
                        this.isRenderRetryPlayingTimes = 0;
                        this.isRenderRetryPlaying = false;
                        this.player.debug.log('Video', 'render() video is paused and replay success');
                    }).catch((e) => {
                        this.isRenderRetryPlaying = false;
                        this.isRenderRetryPlayingTimes++;
                        this.player.debug.warn('Video', 'render() error, video is paused and replay error ', e);
                    })
                }
            }

            this.player.updateStats({fps: true, ts: msg.ts || 0})
            if (msg.videoFrame) {
                // just for wcs
                this.vwriter.write(msg.videoFrame);
                // release memory
                closeVideoFrame(msg.videoFrame);
            } else if (msg.output) {
                // just for wasm
                let yuvData = msg.output
                if (this.player.faceDetectActive &&
                    this.player.ai &&
                    this.player.ai.faceDetector) {

                    if (this.prevAiFaceDetectTime === null) {
                        this.prevAiFaceDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiFaceDetectTime > this.player._opt.aiFaceDetectInterval) {
                        yuvData = this.player.ai.faceDetector.detect({
                            width: this.videoInfo.width,
                            height: this.videoInfo.height,
                            data: msg.output,
                            ts: msg.ts || 0
                        })
                        this.prevAiFaceDetectTime = _nowTime;
                    }
                }

                if (this.player.objectDetectActive &&
                    this.player.ai &&
                    this.player.ai.objectDetector) {

                    if (this.prevAiObjectDetectTime === null) {
                        this.prevAiObjectDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiObjectDetectTime > this.player._opt.aiObjectDetectInterval) {
                        yuvData = this.player.ai.objectDetector.detect({
                            width: this.videoInfo.width,
                            height: this.videoInfo.height,
                            data: msg.output,
                            ts: msg.ts || 0
                        })
                        this.prevAiObjectDetectTime = _nowTime;
                    }
                }

                //  occlusion ai
                if (this.player.occlusionDetectActive &&
                    this.player.ai &&
                    this.player.ai.occlusionDetector) {
                    if (this.prevAiOcclusionDetectTime === null) {
                        this.prevAiOcclusionDetectTime = now();
                    }

                    const _nowTime = now();

                    if (_nowTime - this.prevAiOcclusionDetectTime >=
                        this.player._opt.aiOcclusionDetectInterval) {
                        const result = this.player.ai.occlusionDetector.check({
                            width: this.videoInfo.width,
                            height: this.videoInfo.height,
                            data: msg.output,
                            ts: msg.ts || 0
                        })
                        this.prevAiOcclusionDetectTime = _nowTime;

                        if (result) {
                            this.player.debug.log('Video', `render() and ai occlusion detect result is true`);
                            // emit
                            this.player.emit(EVENTS.aiOcclusionDetectResult, {
                                ts: msg.ts || 0
                            })
                        }
                    }
                }

                if (this.player.imageDetectActive &&
                    this.player.ai &&
                    this.player.ai.imageDetector) {
                    const result = this.player.ai.imageDetector.check({
                        width: this.videoInfo.width,
                        height: this.videoInfo.height,
                        data: msg.output,
                        ts: msg.ts || 0
                    })

                    if (result && result.data) {
                        this.player.emit(EVENTS.aiOcclusionDetectResult, {
                            type: result.type,
                            ts: msg.ts || 0
                        })
                        if (this.player._opt.aiImageDetectDrop) {
                            this.player.debug.log('Video', `render() and ai image detect result type is ${result.type} and drop`);
                            return;
                        }
                    }
                }

                try {
                    const videoFrame = createVideoFrame(yuvData, {
                        format: 'I420',
                        codedWidth: this.videoInfo.width,
                        codedHeight: this.videoInfo.height,
                        timestamp: msg.ts
                    });

                    this.vwriter.write(videoFrame);
                    // release memory
                    closeVideoFrame(videoFrame);
                } catch (e) {
                    this.player.debug.error('Video', 'render error', e);
                    this.player.emitError(EVENTS_ERROR.wasmUseVideoRenderError, e);
                }
            }
            this.player.updateCurrentPts(msg.ts || 0);
            this.doAddContentToWatermark();
            this.doAddAiContentToWatermark();
        } else {
            this.player.debug.warn('Video', 'render and this.vwriter is null');
        }
    }


    _resize() {
        this.player.debug.log('Video', '_resize()');
        let width = this.player.width;
        let height = this.player.height;
        const option = this.player._opt;
        const rotate = option.rotate;
        if (option.hasControl && !option.controlAutoHide) {
            const controlHeight = option.playType === PLAY_TYPE.playbackTF ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT

            if (isMobile() &&
                this.player.fullscreen &&
                option.useWebFullScreen) {
                width -= controlHeight;
            } else {
                height -= controlHeight;
            }
        }

        this.$videoElement.width = width;
        this.$videoElement.height = height;
        this.$videoElement.style.width = width + "px";
        this.$videoElement.style.height = height + "px";
        // rotate
        if (rotate === 270 || rotate === 90) {
            this.$videoElement.width = height;
            this.$videoElement.height = width;
            this.$videoElement.style.width = height + "px";
            this.$videoElement.style.height = width + "px";
        }
        let resizeWidth = this.$videoElement.width;
        let resizeHeight = this.$videoElement.height;
        let left = ((width - resizeWidth) / 2)
        let top = ((height - resizeHeight) / 2)
        let objectFill = 'contain';

        // 默认是true
        // 视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边

        // 视频画面完全填充canvas区域,画面会被拉伸
        if (isFalse(option.isResize)) {
            objectFill = 'fill';
        }

        // 视频画面做等比缩放后,完全填充canvas区域,画面不被拉伸,没有黑边,但画面显示不全
        if (option.isFullResize) {
            objectFill = 'none';
        }

        let transform = '';

        if (option.mirrorRotate === 'none') {
            if (rotate) {
                transform += ' rotate(' + rotate + 'deg)'
            }
        }

        if (option.mirrorRotate === 'level') {
            transform += ' rotateY(180deg)' // 水平镜像翻转
        } else if (option.mirrorRotate === 'vertical') {
            transform += ' rotateX(180deg)' // 垂直镜像翻转
        }

        if (this.player._opt.videoRenderSupportScale) {
            this.$videoElement.style.objectFit = objectFill;
        }

        this.$videoElement.style.transform = transform;
        this.$videoElement.style.padding = "0";
        this.$videoElement.style.left = left + "px"
        this.$videoElement.style.top = top + "px"
    }


    getType() {
        return RENDER_TYPE.video
    }

    getCurrentTime() {
        return this.$videoElement.currentTime
    }

    // is playing
    isPlaying() {
        return this.$videoElement &&
            isFalse(this.$videoElement.paused) &&
            isFalse(this.$videoElement.ended) &&
            this.$videoElement.playbackRate !== 0 &&
            this.$videoElement.readyState !== 0;
    }

    _canScreenshot() {
        return this.$videoElement && this.$videoElement.readyState >= 2;
    }

    getPlaybackQuality() {
        let result = null;

        if (this.$videoElement) {
            if (isFunction(this.$videoElement.getVideoPlaybackQuality)) {
                const info = this.$videoElement.getVideoPlaybackQuality()
                result = {
                    // 从创建起的已丢弃帧数数量
                    // 创建起的损坏帧数数量的 unsigned long 值。一个损坏帧可能属于创建帧或丢弃帧。
                    droppedVideoFrames: info.droppedVideoFrames || info.corruptedVideoFrames,
                    totalVideoFrames: info.totalVideoFrames,
                    creationTime: info.creationTime
                }
            } else {
                result = {
                    droppedVideoFrames: this.$videoElement.webkitDroppedFrameCount,
                    totalVideoFrames: this.$videoElement.webkitDecodedFrameCount,
                    creationTime: now()
                }
            }
            if (result) {
                result.renderedVideoFrames = result.totalVideoFrames - result.droppedVideoFrames;
            }
        }
        return result;
    }

    setRate(value) {
        if (this.$videoElement) {
            this.$videoElement.playbackRate = value;
        }
    }

    get rate() {
        let result = 1;
        if (this.$videoElement) {
            result = this.$videoElement.playbackRate;
        }

        return result;
    }

    clearCheckVideoCanplayTimeout() {
        if (this.checkVideoCanplayTimeout) {
            clearTimeout(this.checkVideoCanplayTimeout);
            this.checkVideoCanplayTimeout = null;
        }
    }

    _getBufferStore() {
        const $video = this.$videoElement;
        let result = 0;
        if ($video.buffered.length > 0) {
            result = $video.buffered.start(0);
        }

        return result;
    }
}
