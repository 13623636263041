import CommonCanvasLoader from "./commonCanvasLoader";
import {
    CANVAS_RENDER_TYPE,
    CONTROL_HEIGHT,
    CONTROL_PLAYBACK_HEIGHT,
    EVENTS, MEDIA_TYPE,
    PLAYBACK_VIDEO_MAX_BUFFER_SIZE
} from "../constant";
import {calcStreamFpsByBufferList, clamp, closeVideoFrame, isFunction, isWebGL2Supported, now} from "../utils";

export default class CanvasPlaybackLoader extends CommonCanvasLoader {
    constructor(player) {
        super(player);
        this.controlHeight = CONTROL_PLAYBACK_HEIGHT;
        // yuvlist
        this.bufferList = [];
        //
        this.playing = false;
        //
        this.playInterval = null;
        //
        this.fps = 1;
        //
        this.preFps = 1;

        this.streamFps = 0;
        //
        this.playbackRate = 1;
        //
        this._firstTimestamp = null;
        //
        this._renderFps = 0;
        //
        this._startfpsTime = null;
        //
        this._startFpsTimestamp = null;

        this._hasCalcFps = false;

        this.player.on(EVENTS.playbackPause, (flag) => {
            if (flag) {
                this.pause();
                if (this.player.playback.isPlaybackPauseClearCache) {
                    this.clear();
                }
            } else {
                this.resume();
            }
        })

        this.player.debug.log(`CanvasPlaybackLoader`, 'init');

    }

    destroy() {
        this._stopSync();
        this._firstTimestamp = null;
        this.playing = false;
        this.playbackRate = 1;
        this.fps = 1;
        this.preFps = 1;
        this.bufferList = [];
        this._renderFps = 0;
        this._startfpsTime = null;
        this._startFpsTimestamp = null;
        this._hasCalcFps = false;
        super.destroy();
        this.player.debug.log(`CanvasPlaybackLoader`, 'destroy');
    }

    _initCanvasRender() {

        if (this.player._opt.useWCS) {
            this.renderType = CANVAS_RENDER_TYPE.webcodecs;

            if (isWebGL2Supported() &&
                this.player._opt.wcsUseWebgl2Render) {
                this._initContextGl2();
                if (this.webglRender) {
                    this.isWcsWebgl2 = true;
                }
            } else {
                this._initContext2D();
            }
        } else {
            if (this.player._opt.useWebGPU) {
                this.renderType = CANVAS_RENDER_TYPE.webgpu;
                this._initContextGPU();
            } else {
                this.renderType = CANVAS_RENDER_TYPE.webgl;
                this._initContextGl();
            }
        }
    }

    _sync() {
        this._stopSync();
        // 第一帧
        this._doPlay();
        this.playInterval = setInterval(() => {
            // 后续帧。
            this._doPlay();
        }, this.fragDuration)
    }

    _doPlay() {
        if (this.bufferList.length > 0 && !this.player.seeking) {
            const bufferData = this.bufferList.shift();
            if (bufferData && bufferData.buffer) {
                this._doRender(bufferData.buffer);
                this.player.handleRender();
                this.player.playback.updateStats({
                    ts: bufferData.ts,
                    tfTs: bufferData.tfTs,
                });
            }
        }
    }

    _stopSync() {
        if (this.playInterval) {
            clearInterval(this.playInterval);
            this.playInterval = null;
        }
    }

    // render method
    _doRender(buffer) {
        if (this.player._opt.useWCS) {
            if (this.webglRender) {
                this.webglRender.render(buffer);
                closeVideoFrame(buffer);
            } else {
                if (isFunction(buffer.createImageBitmap)) {
                    try {
                        buffer.createImageBitmap().then((image) => {
                            this.context2D.drawImage(image, 0, 0, this.$videoElement.width, this.$videoElement.height);
                            closeVideoFrame(buffer);
                        })
                    } catch (e) {
                    }
                } else {
                    this.context2D.drawImage(buffer, 0, 0, this.$videoElement.width, this.$videoElement.height);
                    closeVideoFrame(buffer);
                }
            }
        } else {
            if (this.getCanvasType() === CANVAS_RENDER_TYPE.webgl) {
                try {
                    this.webglRender.renderYUV(this.$videoElement.width, this.$videoElement.height, buffer);
                } catch (e) {
                    this.player.debug.error(`CanvasPlaybackLoader`, `doRender webgl render context is lost ${this.contextGl && this.contextGl.isContextLost()}  and error: ${e.toString()}`);
                }

            } else if (this.getCanvasType() === CANVAS_RENDER_TYPE.webgpu) {
                try {
                    if (!this.webGPURender) {
                        this.player.debug.warn('CanvasVideoLoader', 'doRender webgpu render is not init');
                        return;
                    }
                    this.webGPURender.renderYUV(this.$videoElement.width, this.$videoElement.height, buffer);
                } catch (e) {
                    this.player.debug.error(`CanvasPlaybackLoader`, `doRender webgpu render and error: ${e.toString()}`);
                }
            }
        }
    }

    get rate() {
        return this.playbackRate;
    }


    get fragDuration() {
        return Math.ceil(1000 / (this.fps * this.playbackRate));
    }

    get bufferSize() {
        return this.bufferList.length;
    }

    getStreamFps() {
        return this.streamFps;
    }

    initFps() {
        if (!this._hasCalcFps) {
            this.preFps = clamp(this.player.playback.fps, 1, 100);
            this.fps = this.preFps;
        } else {
            this.player.debug.log(`CanvasPlaybackLoader`, 'initFps, has calc fps');
        }
    }

    setFps(value) {
        if (value !== this.fps) {

            // 如果fps 超过了，则需要
            if (value > 100) {
                this.player.debug.warn('CanvasPlaybackLoader', 'setFps max', value);
            }

            if (value < 0) {
                this.player.debug.warn('CanvasPlaybackLoader', 'setFps min', value);
            }

            this.fps = clamp(value, 1, 100)
            this.player.debug.log('CanvasPlaybackLoader', `setFps ${this.preFps} -> ${this.fps}`)
            if (this.player.playback.isUseFpsRender) {
                this._sync();
            }
        } else {
            this.player.debug.log(`CanvasPlaybackLoader`, `setFps, same fps ${value}`);
        }
    }

    setStreamFps(fps) {
        this.player.debug.log(`CanvasPlaybackLoader`, 'setStreamFps', fps);
        this._hasCalcFps = true;
        this.streamFps = fps;
        this.preFps = fps;
        this.setFps(fps)
    }

    setRate(value) {
        if (value !== this.playbackRate) {
            // this.player.debug.log(`CanvasPlaybackLoader`, 'setRate', value);
            this.playbackRate = value;
            if (this.player.playback.isUseFpsRender) {
                this._sync();
            }
        }
    }

    render$2(msg) {
        if (this._firstTimestamp === null) {
            this._firstTimestamp = msg.ts;
        }
        const data = {
            tfTs: msg.ts - this._firstTimestamp,
            ts: msg.ts,
        }
        if (msg.videoFrame) {
            data.buffer = msg.videoFrame;
        } else {
            data.buffer = msg.output;
        }

        this.bufferList.push(data);
        this.startRender();
        this.player.handleRender();
        this.player.playback.updateStats({
            ts: msg.ts,
            tfTs: data.tfTs,
        });
    }

    startRender() {
        while (true) {
            if (this.bufferList.length <= 0) {
                break;
            }
            const yuv = this.bufferList.shift();
            this._doRender(yuv.buffer);
        }
    }


    pushData(msg) {
        if (this._firstTimestamp === null) {
            this._firstTimestamp = msg.ts;
        }
        const data = {
            tfTs: msg.ts - this._firstTimestamp,
            ts: msg.ts,
        }
        if (msg.videoFrame) {
            data.buffer = msg.videoFrame;
        } else {
            data.buffer = msg.output;
        }

        // 是否解码器缓冲数据
        const isCacheBeforeDecodeForFpsRender = this.player._opt.playbackConfig.isCacheBeforeDecodeForFpsRender;


        if (!isCacheBeforeDecodeForFpsRender) {
            // 如果缓冲数据超过 2s 直接加速播放掉。
            if (this.bufferSize > (this.fps * this.playbackRate) * 2) {
                this.player.debug.warn('CanvasPlaybackLoader', `buffer size is ${this.bufferSize}`);
                this._doPlay();
            }
        }


        this.bufferList.push(data);

        if (!this._hasCalcFps) {
            const streamFps = calcStreamFpsByBufferList(this.bufferList);
            if (streamFps !== null) {
                if (streamFps !== this.preFps) {
                    this.player.debug.log('CanvasPlaybackLoader', `calc fps is ${streamFps} pre fps is ${this.preFps} and updatePreFps`);
                    this.setStreamFps(streamFps);
                }
            }
        }

        if (!isCacheBeforeDecodeForFpsRender) {
            // this.player.debug.log(`CanvasPlaybackLoader`, 'pushData', this.bufferSize);
            // if buffer length
            const bufferListLength = this.bufferList.length;
            const fps = this.fps * this.playbackRate;
            const rate = bufferListLength / fps;
            this.player.debug.log(`CanvasPlaybackLoader`, 'rate is', rate);

            if (rate <= 1) {
                this.setFps(this.preFps);
            } else {
                this.setFps(this.fps + Math.floor(rate * this.playbackRate));
                this.player.debug.warn('CanvasPlaybackLoader', 'rate is', rate, 'fps is', this.fps, 'bufferListLength is', bufferListLength);
            }
        }

        //  check bufferList length is more large max buffer size then drop buffer
        // if (this.bufferList.length) {
        //
        // }
    }

    initVideo() {
        if (this.player.playback && this.player.playback.isUseFpsRender) {
            this._sync();
        }
        this.playing = true;
    }

    initVideoDelay() {
        const delayTime = this.player._opt.playbackDelayTime
        if (delayTime > 0) {
            this.delayTimeout = setTimeout(() => {
                this.initVideo();
                // console.error('initVideoDelay , bufferList length', this.bufferList.length);
            }, delayTime)
        } else {
            this.initVideo();
        }
    }

    clearView() {
        super.clearView()
        this.contextGl.clear(this.contextGl.COLOR_BUFFER_BIT);
    }

    clear() {
        if (this.player._opt.useWCS) {
            this.bufferList.forEach((bufferData) => {
                if (bufferData.buffer) {
                    closeVideoFrame(bufferData.buffer);
                }
            })
        }

        this.bufferList = [];
    }

    resume() {
        if (this.player.playback.isUseFpsRender) {
            this._sync();
        }
        this.playing = true;
    }

    pause() {
        if (this.player.playback.isUseFpsRender) {
            this._stopSync();
        }
        this.playing = false;
    }
}
