import {SoundTouch, SimpleFilter} from "../utils/soundRate";

export default class RateProcessor {
    constructor(player, audio, source) {
        this.player = player;
        this.audio = audio;
        this.soundTouch = new SoundTouch();
        this.soundTouch.tempo = 1
        this.soundTouch.rate = 1
        this.filter = new SimpleFilter(source, this.soundTouch)
    }

    setRate(value) {
        if (value !== this.soundTouch.rate) {
            this.soundTouch.tempo = value;
        }
    }

    provide(size) {
        let target = new Float32Array(size * 2);
        let framesExtracted = this.filter.extract(target, size);
        let left = new Float32Array(framesExtracted);
        let right = new Float32Array(framesExtracted);
        // 缩小
        for (let i = 0; i < framesExtracted; i++) {
            left[i] = target[i * 2];
            right[i] = target[i * 2 + 1]
        }
        //
        return {
            size: framesExtracted,
            left,
            right,
            ts: this.audio.tempAudioTimestamp || 0
        }
    }

    destroy() {
        if (this.soundTouch) {
            this.soundTouch.clear();
            this.soundTouch = null;
        }
        if (this.filter) {
            this.filter = null;
        }
    }
}
