import CanvasVideoLoader from './canvasLoader';
import VideoLoader from "./videoLoader";
import {PLAY_TYPE} from "../constant";
import CanvasPlaybackLoader from "./canvasPlaybackLoader";
import {isFalse} from "../utils";

export default class Video {
    constructor(player) {
        const Loader = Video.getLoaderFactory(player._opt);
        return new Loader(player);
    }

    static getLoaderFactory(opt) {
        if (opt.useMSE) {
            if (opt.mseUseCanvasRender) {
                return CanvasVideoLoader
            } else {
                return VideoLoader;
            }
        } else if (opt.isHls && isFalse(opt.supportHls265)) {
            if (opt.useCanvasRender) {
                return CanvasVideoLoader
            } else {
                return VideoLoader;
            }
        } else if (opt.isWebrtc && isFalse(opt.isWebrtcH265)) {
            if (opt.useCanvasRender) {
                return CanvasVideoLoader
            } else {
                return VideoLoader;
            }
        } else if (opt.isAliyunRtc) {
            return VideoLoader;
        } else if (opt.useWCS) {
            //  playback
            if (opt.playType === PLAY_TYPE.playbackTF) {
                return CanvasPlaybackLoader;
            } else {
                if (!opt.useOffscreen && opt.wcsUseVideoRender) {
                    return VideoLoader
                } else {
                    return CanvasVideoLoader
                }
            }

        } else {
            //  wasm
            if (opt.playType === PLAY_TYPE.playbackTF) {
                return CanvasPlaybackLoader;
            } else {
                if (opt.wasmUseVideoRender && !opt.useOffscreen) {
                    return VideoLoader
                } else {
                    return CanvasVideoLoader;
                }
            }
        }
    }
}
