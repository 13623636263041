import CommonLoader from "./commonLoader";

export default class EmptyLoader extends CommonLoader {
    constructor(player) {
        super(player);
        this.player = player;
        player.debug.log('EmptyDemux', 'init');
    }

    destroy() {
        super.destroy()
        this.player.debug.log('EmptyDemux', 'destroy')
    }
}
