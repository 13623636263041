import fileSave from "./file-save";

const LOG_MAX_SIZE = 200 * 1024
const SIMPLE_TYPE = ['Boolean', 'Number', 'String', 'Undefined', 'Null', 'Date', 'Object']

function reduceDepth(val) {
    if (typeof val !== 'object') {
        return val
    }
    const objType = Object.prototype.toString.call(val).slice(8, -1)
    switch (objType) {
        case 'Array':
        case 'Uint8Array':
        case 'ArrayBuffer':

            return objType + '[' + val.length + ']'
        case 'Object':
            return '{}'
        default:
            return objType
    }
}

function logable(obj, maxDepth, depth) {
    if (!depth) depth = 1
    if (!maxDepth) maxDepth = 2
    const result = {}

    if (!obj || typeof obj !== 'object') {
        return obj
    }

    const objType = Object.prototype.toString.call(obj).slice(8, -1)
    if (!SIMPLE_TYPE.includes(objType)) {
        return objType
    }

    if (depth > maxDepth) {
        return undefined
    }


    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (depth === maxDepth) {
                result[key] = reduceDepth(obj[key])
            } else if (typeof obj[key] === 'object') {
                result[key] = logable(obj[key], maxDepth, depth + 1)
            } else {
                result[key] = obj[key]
            }
        }
    }
    return result
}

function nowTime() {
    return new Date().toLocaleString()
}

export default class MemoryLogger {
    constructor(player, config = {}) {
        this.player = player;
        this.logMaxSize = config?.logMaxSize || LOG_MAX_SIZE;
        this.logSize = 0;
        this.logTextArray = [];
    }

    destroy() {
        this.clear();
    }

    clear() {
        this.logSize = 0;
        this.logTextArray = [];
    }

    logCache(...logText) {
        let text = ''
        try {
            const finLogText = logText.map(item => logable(item))
            text = '[JbPro] ' + nowTime() + (JSON.stringify(finLogText))
        } catch (e) {
            return;
        }
        this.logSize += text.length;
        this.logTextArray.push(text);
        if (this.logSize > this.logMaxSize) {
            const delLog = this.logTextArray.shift()
            this.logSize -= delLog.length
        }
    }

    download() {
        // 将数组里面的日志转换成字符串换行，并下载成.log文件
        const logText = this.logTextArray.join('\n')
        this.clear();
        const blob = new Blob([logText], {type: 'text/plain'})
        fileSave(blob, 'JbPro-' + nowTime() + '.log')
    }
}
