import AudioContextLoader from "./audioContextLoader";
import {isFalse} from "../utils";

export default class AudioPlayerLoader extends AudioContextLoader {

    constructor(player) {
        super(player);
        this.TAG_NAME = 'AudioPlayerLoader';
        this.isAudioPlayer = true;
        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    play(buffer, ts) {
        if (isFalse(this.playing)) {
            return;
        }
        super.play(buffer, ts);
    }

    pause() {
        this.player.debug.log(this.TAG_NAME, 'pause');
        this.playing = false;
        this.clear();
    }

    resume() {
        this.player.debug.log(this.TAG_NAME, 'resume');
        this.playing = true;
    }
}
