export class MP3Data {
    object_type;
    sample_rate;
    channel_count;

    data;
}

const mpegAudioV10SampleRateTable = [44100, 48000, 32000, 0];
const mpegAudioV20SampleRateTable = [22050, 24000, 16000, 0];
const mpegAudioV25SampleRateTable = [11025, 12000, 8000, 0];

const mpegAudioL1BitRateTable = [0, 32, 64, 96, 128, 160, 192, 224, 256, 288, 320, 352, 384, 416, 448, -1];
const mpegAudioL2BitRateTable = [0, 32, 48, 56, 64, 80, 96, 112, 128, 160, 192, 224, 256, 320, 384, -1];
const mpegAudioL3BitRateTable = [0, 32, 40, 48, 56, 64, 80, 96, 112, 128, 160, 192, 224, 256, 320, -1];

export function parseMp3AudioSpecificConfig(mp3frame) {

    if (mp3frame.length < 4) {
        console.error(`Invalid MP3 packet, header missing!`);
        return;
    }

    let array = new Uint8Array(mp3frame.buffer);
    let result = null;

    if (array[0] !== 0xFF) {
        console.error(`Invalid MP3 packet, first byte != 0xFF `);
        return;
    }
    let ver = (array[1] >>> 3) & 0x03;
    let layer = (array[1] & 0x06) >> 1;

    let bitrate_index = (array[2] & 0xF0) >>> 4;
    let sampling_freq_index = (array[2] & 0x0C) >>> 2;

    let channel_mode = (array[3] >>> 6) & 0x03;
    let channel_count = channel_mode !== 3 ? 2 : 1;

    let sample_rate = 0;
    let bit_rate = 0;
    let object_type = 34;  // Layer-3, listed in MPEG-4 Audio Object Types

    let codec = 'mp3';

    switch (ver) {
        case 0:  // MPEG 2.5
            sample_rate = mpegAudioV25SampleRateTable[sampling_freq_index];
            break;
        case 2:  // MPEG 2
            sample_rate = mpegAudioV20SampleRateTable[sampling_freq_index];
            break;
        case 3:  // MPEG 1
            sample_rate = mpegAudioV10SampleRateTable[sampling_freq_index];
            break;
    }

    switch (layer) {
        case 1:  // Layer 3
            object_type = 34;
            if (bitrate_index < mpegAudioL3BitRateTable.length) {
                bit_rate = mpegAudioL3BitRateTable[bitrate_index];
            }
            break;
        case 2:  // Layer 2
            object_type = 33;
            if (bitrate_index < mpegAudioL2BitRateTable.length) {
                bit_rate = mpegAudioL2BitRateTable[bitrate_index];
            }
            break;
        case 3:  // Layer 1
            object_type = 32;
            if (bitrate_index < mpegAudioL1BitRateTable.length) {
                bit_rate = mpegAudioL1BitRateTable[bitrate_index];
            }
            break;
    }

    result = {
        bitRate: bit_rate,
        samplingRate: sample_rate,
        channelCount: channel_count,
        codec: codec,
        originalCodec: codec,
        audioType: 'mp3'
    };

    return result;
}
