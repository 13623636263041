import CommonLoader from "./commonLoader";
import {EVENTS, FILE_SUFFIX, FLV_MEDIA_TYPE, RECORDING_TYPE} from "../constant";
import {now, saveBlobToFile} from "../utils";
import {mergeArrayBuffer$2} from "../utils/buffer";

export default class FlvRecorderLoader extends CommonLoader {

    constructor(player) {
        super(player);
        this.TAG_NAME = 'FlvRecorderLoader';
        this.player = player;
        this._init();
        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this._init();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    _init() {
        this.hasAudio = false;
        this.hasVideo = false;
        this.startTime = null;
        this.currentTime = 0;
        this.prevTimestamp = 0;
        this.totalByteLength = 0;
        this.totalDuration = 0;
        this.flvMetaData = null;
        this.aacSequenceHeader = null;
        this.videoSequenceHeader = null;
        this.bufferList = [];
    }

    _reset() {
        super._reset();
        this._init();
    }

    startRecord() {
        const debug = this.player.debug;
        this._isRecording = true;
        this.player.emit(EVENTS.recording, true);
        debug.log(this.TAG_NAME, 'start recording');
        this.player.emit(EVENTS.recordStart);
        this.startRecordingInterval();
    }

    // override
    startRecordingInterval() {
        this.stopRecordingInterval();
        this.recordingInterval = window.setInterval(() => {
            this.player.emit(EVENTS.recordingTimestamp, this.getTotalDuration());
        }, 1000);
    }


    addMetaData(arrayBuffer) {
        this.flvMetaData = arrayBuffer;
    }

    addAACSequenceHeader(arrayBuffer) {
        this.aacSequenceHeader = arrayBuffer;
    }

    addVideoSequenceHeader(arrayBuffer) {
        this.videoSequenceHeader = arrayBuffer;
    }

    addVideo(arrayBuffer, ts) {
        this._setStartTime(ts);
        const newTs = this._getBufferTs(ts);
        this.hasVideo = true;
        this._createBufferItem(arrayBuffer, FLV_MEDIA_TYPE.video, newTs);
    }

    addAudio(arrayBuffer, ts) {
        this._setStartTime(ts);
        const newTs = this._getBufferTs(ts);
        this.hasAudio = true;
        this._createBufferItem(arrayBuffer, FLV_MEDIA_TYPE.audio, newTs)
    }

    _setStartTime(ts) {
        if (this.startTime === null && this._isRecording) {
            this.startTime = ts;
            this.player.debug.log(this.TAG_NAME, `_setStartTime is ${ts}`)
        }
    }

    _getBufferTs(ts) {
        if (ts > this.currentTime) {
            this.currentTime = ts;
        }
        let result = 0;
        if (this.startTime && ts >= this.startTime) {
            result = ts - this.startTime;
        }
        if (result > this.prevTimestamp) {
            this.prevTimestamp = result;
        } else {
            result = this.prevTimestamp;
        }

        return result;
    }

    _createBufferItem(uint8Array, type, ts) {
        const packet = this._createFlvPacket(uint8Array, type, ts);
        const tag = this._createFlvTag(packet);
        this.totalByteLength += tag.byteLength;
        this.bufferList.push(tag);
    }


    _createFlvTag(packet) {
        let PreviousTagSize = 11 + packet.header.length;
        let tagBuffer = new Uint8Array(PreviousTagSize + 4);
        tagBuffer[0] = packet.header.type;
        let dv = new DataView(tagBuffer.buffer);
        tagBuffer[1] = (packet.header.length >> 16) & 0xff
        tagBuffer[2] = (packet.header.length >> 8) & 0xff;
        tagBuffer[3] = packet.header.length & 0xff
        tagBuffer[4] = (packet.header.timestamp >> 16) & 0xff;
        tagBuffer[5] = (packet.header.timestamp >> 8) & 0xff;
        tagBuffer[6] = packet.header.timestamp & 0xff;
        tagBuffer[7] = (packet.header.timestamp >> 24) & 0xff;

        tagBuffer[8] = 0;
        tagBuffer[9] = 0;
        tagBuffer[10] = 0;
        dv.setUint32(PreviousTagSize, PreviousTagSize);
        tagBuffer.set(packet.payload.subarray(0, packet.header.length), 11);

        return tagBuffer;
    }

    _createFlvPacket(payload = null, type = 0, time = 0) {
        return {
            header: {
                length: payload ? payload.length : 0,
                timestamp: time,
                type: type
            },
            payload: payload
        };
    }

    stopRecordAndSave(type = RECORDING_TYPE.download, fileName) {
        return new Promise((resolve, reject) => {
            if (!this.isRecording) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, isRecording is false ');

                return reject('stop recording fail, isRecording is false ');
            }

            if (this.bufferList.length === 0) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, this.bufferList.length is 0 ');
                return reject('stop recording fail, this.bufferList.length is 0 ');
            }
            if (fileName) {
                this.setFileName(fileName);
            }

            const flvHeader = new Uint8Array([
                0x46, 0x4c, 0x56, // flv
                0x01, 0x00, 0x00, 0x00, 0x00, 0x09, 0x00, 0x00, 0x00, 0x00]);

            if (this.hasVideo) {
                flvHeader[4] |= 0b00000001;
            }

            if (this.hasAudio) {
                flvHeader[4] |= 0b00000100;
            }
            let tempBufferList = [flvHeader];

            // script tag
            if (this.flvMetaData) {
                const packet = this._createFlvPacket(this.flvMetaData, FLV_MEDIA_TYPE.scriptData);
                const tag = this._createFlvTag(packet);
                tempBufferList.push(tag);
            }

            //  video sequence header
            if (this.videoSequenceHeader) {
                const packet = this._createFlvPacket(this.videoSequenceHeader, FLV_MEDIA_TYPE.video);
                const tag = this._createFlvTag(packet);
                tempBufferList.push(tag);
            }

            //  aac sequence header
            if (this.aacSequenceHeader) {
                const packet = this._createFlvPacket(this.aacSequenceHeader, FLV_MEDIA_TYPE.audio);
                const tag = this._createFlvTag(packet);
                tempBufferList.push(tag);
            }

            let bufferList = tempBufferList.concat(this.bufferList);

            const mergeBuffer = mergeArrayBuffer$2(bufferList)

            this.player.debug.log(this.TAG_NAME, 'stop recording');
            const blob = new Blob([mergeBuffer], {'type': 'application/octet-stream'})
            if (type === RECORDING_TYPE.blob) {
                resolve(blob);
                this.player.emit(EVENTS.recordBlob, blob);
            } else {
                resolve();
                const fileName = (this.fileName || now()) + '.' + FILE_SUFFIX.flv;
                saveBlobToFile(fileName, blob);
            }
            this._reset();
            this.player.emit(EVENTS.recording, false);

        })
    }

    getTotalDuration() {
        let result = 0;
        if (this.startTime !== null && this.currentTime !== null) {
            result = this.currentTime - this.startTime;
        }
        //  四舍五入
        return Math.round(result / 1000);
    }

    getType() {
        return FILE_SUFFIX.flv
    }

    //
    getToTalByteLength() {
        return this.totalByteLength;
    }
}
