import {
    MP4_CODECS,
    FILE_SUFFIX,
    H264_NAL_TYPE,
    VIDEO_ENC_TYPE_SHOW,
    H265_NAL_TYPE,
    AUDIO_ENC_TYPE,
    AUDIO_ENC_CODE_SHOW,
    AUDIO_ENC_CODE,
    DEFAULT_JESSIBUCA_OPTIONS,
    DEFAULT_PLAYER_OPTIONS,
    DEFAULT_TALK_OPTIONS,
    CONTROL_BUTTON_OPTIONS,
    MENU_ITEM_OPTIONS,
    DEFAULT_MULTI_OPTIONS,
    FRAME_TYPE,
    AVC_PACKET_TYPE,
    WATERMARK_REFERENCE,
    DEFAULT_AUDIO_PLAYER_OPTIONS
} from "../constant";
import screenfull from "screenfull";
import {isAacCodecPacket} from "./aac";

export function noop() {
}


export function supportOffscreen($canvas) {
    return typeof $canvas.transferControlToOffscreen === 'function';
}


export function supportOffscreenV2() {
    return typeof OffscreenCanvas !== "undefined";
}


export function createContextGL($canvas) {
    let gl = null;

    const validContextNames = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"];
    let nameIndex = 0;

    while (!gl && nameIndex < validContextNames.length) {
        const contextName = validContextNames[nameIndex];

        try {
            let contextOptions = {preserveDrawingBuffer: true};
            gl = $canvas.getContext(contextName, contextOptions);
        } catch (e) {
            console.error(e);
            gl = null;
        }

        if (!gl || typeof gl.getParameter !== "function") {
            gl = null;
        }

        ++nameIndex;
    }


    return gl;
}


export function createContextGL2($canvas) {
    let gl2 = null;
    gl2 = $canvas.getContext("webgl2");
    return gl2;
}

export function audioContextUnlock(context) {
    context.resume();
    const source = context.createBufferSource();
    source.buffer = context.createBuffer(1, 1, 22050);
    source.connect(context.destination);
    if (source.noteOn) {
        source.noteOn(0);
    } else {
        source.start(0);
    }
}

export function dataURLToFile(dataURL = '') {
    const arr = dataURL.split(",");
    const bstr = atob(arr[1]);
    const type = arr[0].replace("data:", "").replace(";base64", "")
    let n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'file', {type});
}

export function downloadFile(file, fileName) {
    if (file instanceof Blob || file instanceof File) {
        file = new Blob([file]);
    }
    const aLink = document.createElement("a");
    aLink.download = fileName;
    aLink.href = file;
    aLink.click();
}


export function downloadImg(content, fileName) {
    const aLink = document.createElement("a");
    aLink.download = fileName;
    const href = URL.createObjectURL(content);
    aLink.href = href;
    aLink.click();
    setTimeout(() => {
        URL.revokeObjectURL(href);
    }, isIOS() ? 1000 : 0)
}

export function checkFull() {
    let isFull = document.fullscreenElement || window.webkitFullscreenElement || document.msFullscreenElement;
    if (isFull === undefined) isFull = false;
    return !!isFull;
}

export function now() {
    return new Date().getTime();
}

export const supportedWasm = (() => {
    try {
        if (typeof WebAssembly === "object"
            && typeof WebAssembly.instantiate === "function") {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module)
                return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
        }
    } catch (e) {
    }
    return false;
})();

export function clamp(num, a, b) {
    return Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));
}

export function setStyle(element, key, value) {
    if (!element) {
        return
    }
    if (typeof key === 'object') {
        Object.keys(key).forEach(item => {
            setStyle(element, item, key[item]);
        });
    }
    element.style[key] = value;
    return element;
}


export function getStyle(element, key, numberType = true) {
    if (!element) {
        return 0
    }

    const value = getComputedStyle(element, null).getPropertyValue(key);
    return numberType ? parseFloat(value) : value;
}

export function getNowTime() {
    if (performance && typeof performance.now === 'function') {
        return performance.now();
    }
    return Date.now();
}

export function calculationRate(callback) {
    let totalSize = 0;
    let lastTime = getNowTime();
    return size => {
        if (!isNumber(size)) {
            return;
        }
        totalSize += size;
        const thisTime = getNowTime();
        const diffTime = thisTime - lastTime;
        if (diffTime >= 1000) {
            callback((totalSize / diffTime) * 1000);
            lastTime = thisTime;
            totalSize = 0;
        }
    };
}

export function downloadRecord(blob, name, suffix) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = (name || now()) + '.' + (suffix || FILE_SUFFIX.webm);
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, isIOS() ? 1000 : 0)
}

export const env = '__ENV__';
export const version = '__VERSION__'
export const proVersionTime = '__BUILD_DAY__'
export const isDev = env === '"development"';
export const isProd = env === '"production"';
export const isRelease = env === '"release"';
export const experienceTimeout = '__TIMEOUT__';

export function isMobile() {
    return (/iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(window.navigator.userAgent.toLowerCase()));
}

export function isNoSleepMobile() {
    return /(iphone|ipad|ipod|ios|android)/i.test(window.navigator.userAgent.toLowerCase())
}

export function isPad() {
    return (/ipad|android(?!.*mobile)|tablet|kindle|silk/i.test(window.navigator.userAgent.toLowerCase()));
}

export function isPc() {
    return !(isMobile() || isPad())
}

export function isAndroid() {
    const UA = window.navigator.userAgent.toLowerCase();
    return (/android/i.test(UA));
}

export function isFirefox() {
    const UA = window.navigator.userAgent.toLowerCase();
    return (/firefox/i.test(UA));
}

export function getBrowser() {
    const UserAgent = window.navigator.userAgent.toLowerCase() || '';
    const browserInfo = {
        type: '',
        version: ''
    };
    const browserArray = {
        IE: window.ActiveXObject || "ActiveXObject" in window, // IE
        Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
        Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
        Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
        Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1, // safari浏览器
        Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
        QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
        WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
    };
    // console.log(browserArray)
    for (let i in browserArray) {
        if (browserArray[i]) {
            let versions = '';
            if (i === 'IE') {
                const versionArray = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)
                if (versionArray && versionArray.length > 2) {
                    versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2];
                }
            } else if (i === 'Chrome') {
                for (let mt in navigator.mimeTypes) {
                    //检测是否是360浏览器(测试只有pc端的360才起作用)
                    if (navigator.mimeTypes[mt]['type'] === 'application/360softmgrplugin') {
                        i = '360';
                    }
                }
                const versionArray = UserAgent.match(/chrome\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            } else if (i === 'Firefox') {
                const versionArray = UserAgent.match(/firefox\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            } else if (i === 'Opera') {
                const versionArray = UserAgent.match(/opera\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            } else if (i === 'Safari') {
                const versionArray = UserAgent.match(/version\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            } else if (i === 'Edge') {
                const versionArray = UserAgent.match(/edge\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            } else if (i === 'QQBrowser') {
                const versionArray = UserAgent.match(/qqbrowser\/([\d.]+)/);
                if (versionArray && versionArray.length > 1) {
                    versions = versionArray[1];
                }
            }
            browserInfo.type = i;
            browserInfo.version = parseInt(versions);
        }
    }
    return browserInfo;
}

export function isIOS() {
    const UA = window.navigator.userAgent.toLowerCase();
    return UA && /iphone|ipad|ipod|ios/.test(UA);
}

export function isSafari() {
    const ua = window.navigator.userAgent;
    return !ua.match(/Chrome/gi) && !!ua.match(/Safari/gi);
}

export function isEdge() {
    // new edge ua is like
    // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36 Edg/116.0.1938.62'
    const ua = window.navigator.userAgent;
    return ua.indexOf('Edg') !== -1;
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    var date;
    if (typeof time === 'object') {
        date = time
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000;
        time = +time; // 转成int 型
        date = new Date(time)
    }
    var formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        var value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    });
    return time_str
}

// 是否支持 webcodecs
export function supportWCS() {
    return "VideoEncoder" in window;
}

export function wcsIsConfigSupported(config) {
    return new Promise((resolve, reject) => {
        if (!supportWCS()) {
            return reject('VideoEncoder is not supported')
        }

        if (!VideoEncoder.isConfigSupported) {
            return reject('VideoEncoder.isConfigSupported is not a function')
        }

        VideoEncoder.isConfigSupported(config).then((result) => {
            if (result.supported) {
                resolve()
            } else {
                reject('VideoEncoder.isConfigSupported() result is not supported');
            }
        })
    })

}

export function supportWasmUseVideoRender() {
    return 'VideoFrame' in window;
}

export function toNumber(value) {
    if (typeof value !== 'string') {
        return value;
    } else {
        // 转换成 number 类型
        var parsed = Number(value);
        return isNaN(parsed) ? value : parsed;
    }
}

export function uuid16() {
    return 'xxxxxxxxxxxx4xxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export function throttle(callback, delay) {
    let isThrottled = false;
    let args;
    let context;

    function fn(...args2) {
        if (isThrottled) {
            args = args2;
            context = this;
            return;
        }

        isThrottled = true;
        callback.apply(this, args2);
        setTimeout(() => {
            isThrottled = false;
            if (args) {
                fn.apply(context, args);
                args = null;
                context = null;
            }
        }, delay);
    }

    return fn;
}


export function isDef(v) {
    return v !== undefined && v !== null;
}

export function formatVideoDecoderConfigure(avcC) {

}

export function formatAvcVideoDecoderConfigure(avcC) {
    let codecArray = avcC.subarray(1, 4);
    let codecString = "avc1.";
    for (let j = 0; j < 3; j++) {
        let h = codecArray[j].toString(16);
        if (h.length < 2) {
            h = "0" + h
        }
        codecString += h
    }
    return {
        codec: codecString,
        description: avcC
    }
}

export function formatHevcVideoDecoderConfigure(hecvC) {
    // The codec string begins with the prefix "hev1." or "hvc1.", with a suffix of four dot-separated fields as described
    // 'hvc1.2.4.L93.B0', //'hev1.1.6.H120.B0'
    let codecArray = hecvC.subarray(1, 4);
    //
    // let codecString = "hvc1.2.4.L93.B0";
    // hvc1.1.6.L120.90 这个是hls.js 用的编码格式。
    let codecString = 'hev1.1.6.L120.90';
    // for (let j = 0; j < 3; j++) {
    //     let h = codecArray[j].toString(16);
    //     if (h.length < 2) {
    //         h = "0" + h
    //     }
    //     codecString += h
    // }

    return {
        codec: codecString,
        description: hecvC
    }
}


export function isFullScreen() {
    // return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
    return screenfull.isFullscreen
}

//
export function bpsSize(value) {
    if (null == value || value === '') {
        return "0 KB/s";
    }
    let size = parseFloat(value);
    size = size.toFixed(2);
    return size + 'KB/s';
}

export function bpsSize$2(value) {
    if (null == value || value === '' || parseFloat(value) === 0 || value === 'NaN') {
        return "0 KB/s";
    }
    const unitArr = ["KB/s", "MB/s", "GB/s", "TB/s", "PB/s", "EB/s", "ZB/s", "YB/s"];
    let index = 0;
    const srcsize = parseFloat(value);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    let size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2);//
    return size + (unitArr[index] || unitArr[0]);
}

export function formatFileSize(value) {
    if (null == value || value == '') {
        return "0 Bytes";
    }
    const unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
    let index = 0;
    const srcsize = parseFloat(value);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    var size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2);//保留的小数位数
    return size + unitArr[index];
}

export function isNumber(value) {
    const toString = Object.prototype.toString;
    return toString.call(value) === "[object Number]";
}


export function fpsStatus(fps, metaDataFps) {
    let result = 3; // 流畅
    const baseFps = metaDataFps || 25;
    if (fps < (baseFps * 0.33)) {
        result = 0;// 非常卡顿
    } else if (fps < baseFps * 0.5) {
        result = 1; // 卡顿
    } else if (fps < baseFps * 0.83) {
        result = 2; // 稍微卡顿
    }

    return result;
}

export function createEmptyImageBitmap(width, height) {
    const $canvasElement = document.createElement("canvas");
    $canvasElement.width = width;
    $canvasElement.height = height;
    const imageBitmap = window.createImageBitmap($canvasElement, 0, 0, width, height);
    // release canvas
    $canvasElement.width = 0;
    $canvasElement.height = 0;
    return imageBitmap;
}


export function supportMSE() {
    let result = false;
    if ('MediaSource' in window && window.MediaSource.isTypeSupported(MP4_CODECS.avc)) {
        result = true;
    }
    return result;
}

//
export function supportMSEDecodeHevc() {
    let result = false;
    if ('MediaSource' in window && window.MediaSource.isTypeSupported(MP4_CODECS.hev)) {
        result = true;
    }
    return result;
}

// 查看是否webcodecs 支持 hevc解码
// chrome 107
export function supportWCSDecodeHevc() {
    const browserInfo = getBrowser();
    return browserInfo.type.toLowerCase() === 'chrome' && browserInfo.version >= 107;
}

export function supportMediaStreamTrack() {
    let result = false;
    if ('MediaStreamTrackGenerator' in window) {
        result = true;
    }
    return result
}

export function supportMediaStream() {
    let result = false;
    if ('MediaStream' in window) {
        result = true;
    }
    return result;
}


export function formatMp4VideoCodec(codec) {
    return `video/mp4; codecs="${codec}"`
}


export function saveBlobToFile(fileName, blob) {
    let url = window.URL.createObjectURL(blob);
    let aLink = window.document.createElement('a');
    aLink.download = fileName;
    aLink.href = url;
    //创建内置事件并触发
    let evt = window.document.createEvent('MouseEvents');
    evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.dispatchEvent(evt);
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, isIOS() ? 1000 : 0)
}

export function isEmpty(value) {
    return value === null || value === undefined
}

export function isBoolean(value) {
    return value === true || value === false;
}

export function isNotEmpty(value) {
    return !isEmpty(value)
}

export function isUndefined(value) {
    return value === undefined;
}

export function initPlayTimes() {
    return {
        playInitStart: '', //1
        playStart: '', // 2
        streamStart: '', //3
        streamResponse: '', // 4
        demuxStart: '', // 5
        decodeStart: '', // 6
        videoStart: '', // 7
        playTimestamp: '',// playStart- playInitStart
        streamTimestamp: '',// streamStart - playStart
        streamResponseTimestamp: '',// streamResponse - streamStart
        demuxTimestamp: '', // demuxStart - streamResponse
        decodeTimestamp: '', // decodeStart - demuxStart
        videoTimestamp: '',// videoStart - decodeStart
        allTimestamp: '' // videoStart - playInitStart
    }
}

export function formatWatermarkOptions(options) {
    let defaultConfig = {
        left: '',
        right: '',
        top: '',
        bottom: '',
        opacity: 1,
        backgroundColor: '',
        image: {
            src: '',
            width: '100',
            height: '60'
        },
        text: {
            content: '',
            fontSize: '14',
            color: '#000',
            width: '',
            height: ''
        },
        rect: {
            color: 'green', // border color
            lineWidth: 2,
            width: '',
            height: '',
            fill: '', // fill color
            fillOpacity: 0.2, // fill opacity
        },
        line: {
            x1: '',
            y1: '',
            x2: '',
            y2: '',
            color: 'green',
            lineWidth: 2
        },
        // 多边形
        polygon: {
            color: 'green',
            lineWidth: 2,
            list:[],
            fill: '', // fill color
            fillOpacity: 0.2, // fill opacity
        },
        html: ''
    }
    const imageConfig = Object.assign(defaultConfig.image, options.image || {})
    const textConfig = Object.assign(defaultConfig.text, options.text || {})
    const rectConfig = Object.assign(defaultConfig.rect, options.rect || {})
    const lineConfig = Object.assign(defaultConfig.line, options.line || {})
    defaultConfig = Object.assign(defaultConfig, options, {
        image: imageConfig,
        text: textConfig,
        rect: rectConfig,
        line: lineConfig
    })

    return defaultConfig;
}


export function formatFullscreenWatermarkOptions(container, options) {
    let defaultConfig = {
        container: container || '',
        text: '', // 文本
        opacity: '',// 透明度
        angle: '',// 倾斜角度
        color: '',// 字体颜色
        fontSize: '',// 字体大小
        fontFamily: '',// 字体
    }
    defaultConfig = Object.assign(defaultConfig, options)
    return {
        watermark_parent_node: defaultConfig.container,
        watermark_alpha: defaultConfig.opacity,
        watermark_angle: defaultConfig.angle,
        watermark_fontsize: defaultConfig.fontSize,
        watermark_color: defaultConfig.color,
        watermark_font: defaultConfig.fontFamily,
        watermark_txt: defaultConfig.text,
    }
}

// create watermark
export function createWatermark(watermarkList, container) {
    let defaultConfigList = watermarkList.map((itemOptions) => {
        return formatWatermarkOptions(itemOptions);
    })
    const $container = container;

    if (!$container) {
        return
    }


    let shadowRoot = null;
    const otDiv = document.createElement('div');
    otDiv.setAttribute('style', 'pointer-events: none !important; display: block !important');

    if (typeof otDiv.attachShadow === "function") {
        shadowRoot = otDiv.attachShadow({mode: 'open'});
    } else if (otDiv.shadowRoot) {
        shadowRoot = otDiv.shadowRoot;
    } else {
        shadowRoot = otDiv;
    }

    const nodeList = $container.children;
    const index = Math.floor(Math.random() * (nodeList.length - 1));

    if (nodeList[index]) {
        $container.insertBefore(otDiv, nodeList[index]);
    } else {
        $container.appendChild(otDiv);
    }
    // foreach
    defaultConfigList.forEach((defaultConfig) => {
        const maskDiv = document.createElement('div');
        let innerDom = null;
        if (defaultConfig.image && defaultConfig.image.src) {
            innerDom = document.createElement('img');
            innerDom.style.height = '100%';
            innerDom.style.width = '100%';
            innerDom.style.objectFit = 'contain';
            innerDom.src = defaultConfig.image.src;
        } else if (defaultConfig.text && defaultConfig.text.content) {
            innerDom = document.createTextNode(defaultConfig.text.content);
        } else if (defaultConfig.rect && defaultConfig.rect.color && defaultConfig.rect.width) {
            innerDom = document.createElement('div');
        } else if (defaultConfig.html) {
            innerDom = document.createElement('div');
        }

        if (innerDom) {
            maskDiv.appendChild(innerDom);

            maskDiv.style.visibility = '';
            maskDiv.style.position = "absolute";
            maskDiv.style.display = 'block'
            maskDiv.style['-ms-user-select'] = "none";
            maskDiv.style['-moz-user-select'] = "none";
            maskDiv.style['-webkit-user-select'] = "none";
            maskDiv.style['-o-user-select'] = "none";
            maskDiv.style['user-select'] = "none";
            maskDiv.style['-webkit-touch-callout'] = "none";
            maskDiv.style['-webkit-tap-highlight-color'] = "rgba(0,0,0,0)";
            maskDiv.style['-webkit-text-size-adjust'] = "none";
            maskDiv.style['-webkit-touch-callout'] = "none";
            maskDiv.style.opacity = defaultConfig.opacity;
            if (isNumber(defaultConfig.left)) {
                maskDiv.style.left = defaultConfig.left + 'px';
            }
            if (isNumber(defaultConfig.right)) {
                maskDiv.style.right = defaultConfig.right + 'px';
            }
            if (isNumber(defaultConfig.top)) {
                maskDiv.style.top = defaultConfig.top + 'px';
            }
            if (isNumber(defaultConfig.bottom)) {
                maskDiv.style.bottom = defaultConfig.bottom + 'px';
            }
            if (defaultConfig.backgroundColor) {
                maskDiv.style.backgroundColor = defaultConfig.backgroundColor;
            }
            maskDiv.style.overflow = 'hidden';
            maskDiv.style.zIndex = "9999999";
            if (defaultConfig.image && defaultConfig.image.src) {
                maskDiv.style.width = defaultConfig.image.width + 'px';
                maskDiv.style.height = defaultConfig.image.height + 'px';
            } else if (defaultConfig.text && defaultConfig.text.content) {
                maskDiv.style.fontSize = defaultConfig.text.fontSize + 'px';
                maskDiv.style.color = defaultConfig.text.color;
            } else if (defaultConfig.rect && defaultConfig.rect.color && defaultConfig.rect.width) {
                maskDiv.style.width = defaultConfig.rect.width + 'px';
                maskDiv.style.height = defaultConfig.rect.height + 'px';
                maskDiv.style.borderWidth = defaultConfig.rect.lineWidth + 'px';
                maskDiv.style.borderStyle = 'solid';
                maskDiv.style.borderColor = defaultConfig.rect.color;
            } else if (defaultConfig.html) {
                maskDiv.innerHTML = defaultConfig.html;
                maskDiv.style.width = defaultConfig.width + 'px';
                maskDiv.style.height = defaultConfig.height + 'px';
            }

            shadowRoot.appendChild(maskDiv)
        }
    })


    // remove function
    return () => {
        $container.removeChild(otDiv);
    }
}

// create image watermark
export function createImageWatermark(dataUrl, options) {
    return new Promise((resolve, reject) => {
        let defaultConfig = formatWatermarkOptions(options);

        if (!defaultConfig.image.src && !defaultConfig.text.content) {
            return resolve(dataUrl);
        }


        let canvas = document.createElement('canvas')
        canvas.width = options.width;
        canvas.height = options.height;
        let ctx = canvas.getContext('2d')
        let x = 0;
        let y = 0;
        if (isNumber(defaultConfig.left)) {
            x = defaultConfig.left;
        } else if (isNumber(defaultConfig.right)) {
            x = canvas.width - defaultConfig.right
        }

        if (isNumber(defaultConfig.top)) {
            y = defaultConfig.top;
        } else if (isNumber(defaultConfig.bottom)) {
            y = canvas.height - defaultConfig.bottom;
        }
        const imag = new Image();
        imag.src = dataUrl;
        imag.onload = () => {
            ctx.drawImage(imag, 0, 0);
            if (defaultConfig.image && defaultConfig.image.src) {
                const tempImage = new Image();
                tempImage.src = defaultConfig.image.src;
                tempImage.setAttribute("crossOrigin", 'Anonymous')
                tempImage.onload = () => {
                    x -= defaultConfig.image.width;
                    // y -= defaultConfig.image.height;
                    ctx.drawImage(tempImage, x, y, defaultConfig.image.width, defaultConfig.image.height);
                    resolve(canvas.toDataURL(options.format, options.quality));
                }
                tempImage.onerror = (e) => {
                    reject();
                }
            } else if (defaultConfig.text && defaultConfig.text.content) {
                // 设置填充字号和字体，样式
                ctx.font = defaultConfig.text.fontSize + "px 宋体";
                // color
                ctx.fillStyle = defaultConfig.text.color;
                // 设置右对齐
                ctx.textAlign = 'right';
                // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
                ctx.fillText(defaultConfig.text.content, x, y)
                resolve(canvas.toDataURL(options.format, options.quality))
            }
        }

        imag.onerror = (e) => {
            reject(e)
        }
    })
}

export function formatTimeTips(time) {
    var result;

    //
    if (time > -1) {
        var hour = Math.floor(time / 3600);
        var min = Math.floor(time / 60) % 60;
        var sec = time % 60;

        sec = Math.round(sec);

        if (hour < 10) {
            result = '0' + hour + ":";
        } else {
            result = hour + ":";
        }

        if (min < 10) {
            result += "0";
        }
        result += min + ":";
        if (sec < 10) {
            result += "0";
        }
        result += sec.toFixed(0);
    }

    return result;
}


//
export function createVideoFrame(arrayBuffer, init) {
    return new VideoFrame(arrayBuffer, init)
}


export function getLocationSearch() {
    var url = window.location.search; //获取url中"?"符后的字串
    var theRequest = {};
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};

// min timestamp
export function formatMinTimeTips(time, second) {
    let result = '';

    //
    if (time > -1) {
        const hour = Math.floor(time / 60) % 60;
        let min = time % 60;

        min = Math.round(min);

        if (hour < 10) {
            result = '0' + hour + ":";
        } else {
            result = hour + ":";
        }

        if (min < 10) {
            result += "0";
        }
        result += min;

        if (!isEmpty(second)) {
            if (second < 10) {
                second = '0' + second;
            }
            result += (':' + second);
        }
    }

    return result;
}

// second timestamp
export function formatSecondTimeTips(time) {
    let result = '';
    if (time > -1) {
        const hour = Math.floor(time / 60 / 60) % 60;
        let min = Math.floor(time / 60) % 60;
        let second = time % 60

        min = Math.round(min);

        if (hour < 10) {
            result = '0' + hour + ":";
        } else {
            result = hour + ":";
        }

        if (min < 10) {
            result += "0";
        }
        result += min + ':';

        if (second < 10) {
            result += '0'
        }
        result += second
    }

    return result;
}


export function formatSecondTime(time) {
    let result = {};
    if (time > -1) {
        const hour = Math.floor(time / 60 / 60) % 60;
        let min = Math.floor(time / 60) % 60;
        let second = time % 60
        result = {
            hour,
            min,
            second
        }
    }

    return result;
}

/**
 *
 * @param time
 */
export function formatMinuteTimestamp(day, time) {
    const hour = Math.floor(time / 60) % 60;
    const min = Math.floor(time % 60);
    const nowMinuteTimestamp = new Date(day).setHours(
        hour,
        min,
        0,
        0
    );
    return nowMinuteTimestamp;
}

export function formatSecondTimestamp(day, time) {
    const hour = Math.floor(time / 60 / 60) % 60;
    const min = Math.floor(time / 60) % 60;
    const second = time % 60
    const nowSecondTimestamp = new Date(day).setHours(
        hour,
        min,
        second,
        0
    );
    return nowSecondTimestamp;
}

export function getStrLength(value) {
    return ('' + value).length
}


export function isEmptyObject(obj) {
    return (obj && Object.keys(obj).length === 0)
}

export function isNotEmptyObject(obj) {
    return !isEmptyObject(obj);
}


export function isString(value) {
    return typeof value === "string";
}


export function isSupportSIMD() {
    return WebAssembly.validate(new Uint8Array([0, 97, 115, 109, 1, 0, 0, 0, 1, 5, 1, 96, 0, 1, 123, 3, 2, 1, 0, 10, 10, 1, 8, 0, 65, 0, 253, 15, 253, 98, 11]));
}

export function isSupportSharedArrayBuffer() {
    return typeof SharedArrayBuffer !== 'undefined';
}


export const isWeChat = () => {
    const userAgent = window.navigator.userAgent;
    return /MicroMessenger/i.test(userAgent)
};

export const isChrome = () => {
    const userAgent = window.navigator.userAgent;
    return /Chrome/i.test(userAgent)
}

//
export const isWeChatInAndroid = () => {
    return isWeChat() && isAndroid();
}

export function isChromeInIOS() {
    return isChrome() && isIOS();
}

export const isWeChatInIOS = () => {
    return isWeChat() && isIOS();
}

export const isMobileOrPad = () => {
    return isMobile() || isPad();
}


export function getTarget(e) {
    const event = e || window.event;
    const target = event.target || event.srcElement;
    return target;
}

const _mime = function (option, value) {
    const mimeTypes = navigator.mimeTypes;
    for (let mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
            return true;
        }
    }
    return false;
};

export function isWindow() {
    let isWindows = false;
    const agent = navigator.userAgent.toLowerCase();
    const isMac = /macintosh|mac os x/i.test(agent);
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        //your code
        isWindows = true;
    }
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        //your code
        isWindows = true;
    }
    if (isMac) {
        //your code
        isWindows = false;
    }

    return isWindows;
}

export function isMacOs() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /macintosh|mac os x/i.test(userAgent);
}

export function isMacOsFirefox() {
    return isFirefox() && isMacOs();
}

export function isWindow360() {
    const userAgent = window.navigator.userAgent;
    let is360 = userAgent.indexOf('QihooBrowser') > -1 ||
        userAgent.indexOf('QHBrowser') > -1 ||
        userAgent.indexOf('360EE') > -1 ||
        userAgent.indexOf('360SE') > -1;

    if (!is360) {
        if (window.chrome) {
            const chrome_version = userAgent.replace(/^.*Chrome\/([\d]+).*$/, '$1');
            if (_mime("type", "application/360softmgrplugin") || _mime("type", "application/mozilla-npqihooquicklogin")) {
                is360 = true;
            } else if (chrome_version > 36 && window.showModalDialog) {
                is360 = true;
            } else if (chrome_version > 45) {
                is360 = _mime("type", "application/vnd.chromium.remoting-viewer");
                if (!is360 && chrome_version >= 69) {
                    is360 = _mime("type", "application/hwepass2001.installepass2001") || _mime("type", "application/asx");
                }
            }
        }
    }

    return is360 && isWindow();
}


export function isFunction(fn) {
    return typeof fn === "function"
}


export function isSupportOfflineAudioContext() {
    let offlineAudioContext = new OfflineAudioContext(1, 1, 44100);
    return Boolean(offlineAudioContext.audioWorklet && typeof offlineAudioContext.audioWorklet.addModule == "function")
}


export function isWebglRenderSupport(width) {
    return (width / 2) % 4 === 0
}

/**
 * is green yuv
 * @param arrayBuffer
 * @returns {boolean}
 */
export function isGreenYUV(arrayBuffer) {
    let zeroNum = 0;
    for (let i = 0; i < arrayBuffer.length; i++) {
        let temp = arrayBuffer[i];
        if (temp === 0) {
            zeroNum += 1
        }
    }
    const isGreen = zeroNum === arrayBuffer.length;
    if (isGreen) {
        console.log('green yuv', arrayBuffer)
    }
    return isGreen
}

/**
 *
 * @param event
 * @returns {{posX: number, posY: number}}
 */
export function getMousePosition(event) {
    if (isMobile()) {
        let clientX = 0;
        let clientY = 0;
        if (event.touches.length === 1) {
            let clientObject = event.touches[0];
            clientX = clientObject.clientX;
            clientY = clientObject.clientY;
        }
        return {
            posX: clientX,
            posY: clientY
        }
    }
    let posX = 0;
    let posY = 0;
    const e = event || window.event;  //标准化事件对象
    if (e.pageX || e.pageY) {  //获取鼠标指针的当前坐标值
        posX = e.pageX;
        posY = e.pageY;
    } else if (e.clientX || e.clientY) {
        posX = event.clientX + document.documentElement.scrollLeft + document.body.scrollLeft;
        posY = event.clientY + document.documentElement.scrollTop + document.body.scrollTop;
    }
    return {
        posX,
        posY
    }
}

export function canPlayAppleMpegurl() {
    let video = document.createElement('video');
    let result = video.canPlayType('application/vnd.apple.mpegurl')
    video = null;
    return result;
}


export function isSupportGetUserMedia() {
    let result = false;
    const navigator = window.navigator;

    if (navigator) {
        result = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)

        if (!result) {
            result = !!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)
        }
    }

    return result;
}

export function onlyMseOrWcsVideo(opt) {
    let result = (isFalse(opt.hasAudio) &&
            (opt.useMSE || (opt.useWCS && !opt.useOffscreen))) &&
        isFalse(opt.demuxUseWorker)

    if (isFalse(result) &&
        (opt.useMSE && opt.mseDecodeAudio && isFalse(opt.demuxUseWorker))) {
        return true
    }

    return result
}

export function checkNaluType(naluBuffer) {
    let result = null;
    let type = naluBuffer[0] & 0b0001_1111;
    if (type === H264_NAL_TYPE.sps || type === H264_NAL_TYPE.pps) {
        result = VIDEO_ENC_TYPE_SHOW.h264;
    }
    if (!result) {
        type = (naluBuffer[0] & 0x7E) >> 1;
        if (type === H265_NAL_TYPE.vps || type === H265_NAL_TYPE.sps || type === H265_NAL_TYPE.pps) {
            result = VIDEO_ENC_TYPE_SHOW.h265;
        }
    }

    return result;
}


export function checkAudioNaluType(naluBuffer) {
    let result = null;
    const codecId = naluBuffer[0] >> 4;

    if (isAacCodecPacket(naluBuffer)) {
        result = AUDIO_ENC_CODE_SHOW.AAC
    } else if (codecId === AUDIO_ENC_CODE.ALAW) {
        result = AUDIO_ENC_CODE_SHOW.ALAW
    } else if (codecId === AUDIO_ENC_CODE.MULAW) {
        result = AUDIO_ENC_CODE_SHOW.MULAW
    }

    return result;
}


export function createWorkletModuleUrl(func) {

    function functionToString(str) {
        return str.trim().match(/^function\s*\w*\s*\([\w\s,]*\)\s*{([\w\W]*?)}$/)[1]
    }

    const funcStr = functionToString(func.toString());
    const blob = new Blob([funcStr], {type: 'application/javascript'});
    return URL.createObjectURL(blob);
}

export function supportWritableStream() {
    return typeof WritableStream !== 'undefined';
}


export function closeVideoFrame(videoFrame) {
    videoFrame.close()
}

export function isInHttps() {
    return window.location.protocol === 'https:' || window.location.hostname === 'localhost';
}

export function errorToString(error) {
    const nativeToString = Object.prototype.toString;

    function isErrorLike(error) {
        switch (nativeToString.call(error)) {
            case '[object Error]':
                return true;
            case '[object Exception]':
                return true;
            case '[object DOMException]':
                return true;
            default:
                try {
                    return error instanceof Error;
                } catch (e) {
                    return false;
                }
        }
    }

    if (isErrorLike(error)) {
        return error.message;
    } else {
        return error == null
            ? '' :
            typeof error === 'object'
                ? JSON.stringify(error, null, 2)
                : String(error)
    }
}


export function isInSampleHost(url) {
    let result = false;


    return result;
}


export function calcStreamFpsByBufferList(bufferList, type) {

    if (type) {
        bufferList = bufferList.filter(item => item.type && item.type === type)
    }

    let firstItem = bufferList[0];
    let oneSecondLength = null;
    let nextIndex = 1;
    if (bufferList.length > 0) {
        let nextItem = bufferList[1];
        if (nextItem && nextItem.ts - firstItem.ts > 100000) {
            firstItem = nextItem;
            nextIndex = 2;
        }
    }

    if (firstItem) {
        // next start
        for (let i = nextIndex; i < bufferList.length; i++) {
            let tempItem = bufferList[i];
            if (type && tempItem.type && tempItem.type !== type) {
                tempItem = null;
            }
            if (tempItem) {
                const diff = tempItem.ts - firstItem.ts;
                if (diff >= 1000) {
                    const prevTempItem = bufferList[i - 1];
                    const diff2 = prevTempItem.ts - firstItem.ts;
                    if (diff2 < 1000) {
                        oneSecondLength = i + 1;
                    }
                }
            }
        }
    }

    return oneSecondLength;
}


export function isFetchSuccess(res) {
    return (res.ok && (res.status >= 200 && res.status <= 299))
}

// stroke rect or text in canvas
export function strokeRectOrTextInCanvas({ctx, list}) {
    ctx.save();
    (list || []).forEach(item => {
        if (item.type === 'text') {
            ctx.font = `${item.fontSize || 12}px Arial`;
            ctx.fillStyle = item.color || 'green';
            ctx.fillText(item.text, item.x, item.y);
        } else if (item.type === 'rect') {
            ctx.strokeStyle = item.color || 'green';
            ctx.lineWidth = item.lineWidth || 2;
            ctx.strokeRect(item.x, item.y, item.width, item.height);
        }
    })
    ctx.restore();
}

export function hexToRgba(hex) {
    const r = parseInt(hex.substring(1, 3), 16) / 255;
    const g = parseInt(hex.substring(3, 5), 16) / 255;
    const b = parseInt(hex.substring(5, 7), 16) / 255;
    return [r, g, b, 1.0];
}

export function createTransformMatrix(width, height) {
    return [
        2 / width, 0, 0,
        0, -2 / height, 0,
        -1, 1, 1
    ];
}


// stroke text in canvas
export function strokeTextInCanvas({ctx, color, fontSize, text, x, y}) {
    ctx.save();
    ctx.font = `${fontSize || 12}px Arial`;
    ctx.fillStyle = color || 'green';
    ctx.fillText(text, x, y);
    ctx.restore();
}


export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function getUrlRelativePath(href) {
    const url = href || document.location.toString();
    const arrUrl = url.split("//");

    const start = arrUrl[1].indexOf("/");
    let relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符

    if (relUrl.indexOf("?") != -1) {
        relUrl = relUrl.split("?")[0];
    }
    return relUrl;
}

export function base64Decode(data) {
    const utf8Decode = function (str_data) {
        var tmp_arr = [], i = 0, ac = 0, c1 = 0, c2 = 0, c3 = 0;
        // 变成string类型
        str_data += '';

        while (i < str_data.length) {
            c1 = str_data.charCodeAt(i);
            if (c1 < 128) {
                tmp_arr[ac++] = String.fromCharCode(c1);
                i++;
            } else if (c1 > 191 && c1 < 224) {
                c2 = str_data.charCodeAt(i + 1);
                tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = str_data.charCodeAt(i + 1);
                c3 = str_data.charCodeAt(i + 2);
                tmp_arr[ac++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }

        return tmp_arr.join('');
    };

    const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let o1, o2, o3, h1, h2, h3, h4, bits, i = 0, ac = 0, dec = "", tmp_arr = [];
    if (!data) {
        return data;
    }
    data += '';
    do {
        h1 = b64.indexOf(data.charAt(i++));
        h2 = b64.indexOf(data.charAt(i++));
        h3 = b64.indexOf(data.charAt(i++));
        h4 = b64.indexOf(data.charAt(i++));
        bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;
        o1 = bits >> 16 & 0xff;
        o2 = bits >> 8 & 0xff;
        o3 = bits & 0xff;
        if (h3 == 64) {
            tmp_arr[ac++] = String.fromCharCode(o1);
        } else if (h4 == 64) {
            tmp_arr[ac++] = String.fromCharCode(o1, o2);
        } else {
            tmp_arr[ac++] = String.fromCharCode(o1, o2, o3);
        }
    } while (i < data.length);
    dec = tmp_arr.join('');
    dec = utf8Decode(dec);
    return dec;
}

export function b64toUin8(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function base64ToString(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    let result = '';

    for (let i = 0; i < rawData.length; ++i) {
        result += rawData.charCodeAt(i);
    }
    return result;
}


export function resolveUrl(url) {
    const msie = /(msie|trident)/i.test(navigator.userAgent);
    const urlParsingNode = document.createElement('a');
    let href = url;

    if (msie) {
        urlParsingNode.setAttribute('href', href);
        href = urlParsingNode.href;
    }

    urlParsingNode.setAttribute('href', href);


    return {
        origin: urlParsingNode.origin,
        href: urlParsingNode.href,
        protocol: urlParsingNode.protocol ? urlParsingNode.protocol.replace(/:$/, '') : '',
        host: urlParsingNode.host,
        search: urlParsingNode.search ? urlParsingNode.search.replace(/^\?/, '') : '',
        hash: urlParsingNode.hash ? urlParsingNode.hash.replace(/^#/, '') : '',
        hostname: urlParsingNode.hostname,
        port: urlParsingNode.port,
        pathname: (urlParsingNode.pathname.charAt(0) === '/') ?
            urlParsingNode.pathname :
            '/' + urlParsingNode.pathname
    };
}


export function uuid4() {
    return 'xxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export function clone(obj) {
    let result = '';
    //
    if (typeof obj === 'object') {
        try {
            result = JSON.stringify(obj);
            result = JSON.parse(result);
        } catch (e) {
            result = obj;
        }
    } else {
        result = obj;
    }

    return result;
}

/**
 *
 * @returns {object:DEFAULT_JESSIBUCA_OPTIONS}
 */
export function getDefaultJessibucaOptions() {
    return clone(DEFAULT_JESSIBUCA_OPTIONS);
}

/**
 *
 * @returns {object:DEFAULT_PLAYER_OPTIONS}
 */
export function getDefaultPlayerOptions() {
    return clone(DEFAULT_PLAYER_OPTIONS);
}

/**
 *
 * @returns {object:DEFAULT_TALK_OPTIONS}
 */
export function getDefaultTalkOptions() {
    return clone(DEFAULT_TALK_OPTIONS);
}


/**
 *
 * @returns {object:CONTROL_BUTTON_OPTIONS}
 */
export function getDefaultButtonOptions() {
    return clone(CONTROL_BUTTON_OPTIONS);
}


/**
 *
 * @returns {object:MENU_ITEM_OPTIONS}
 */
export function getDefaultMenuOptions() {
    return clone(MENU_ITEM_OPTIONS);
}


/**
 *
 * @returns {object:DEFAULT_MULTI_OPTIONS}
 */
export function getDefaultMultiOptions() {
    return clone(DEFAULT_MULTI_OPTIONS);
}

/**
 *
 * @returns {object:DEFAULT_AUDIO_PLAYER_OPTIONS}
 */
export function getDefaultAudioPlayerOptions() {
    return clone(DEFAULT_AUDIO_PLAYER_OPTIONS);
}


export function base64ToBlob(base64Data) {
    const byteString = atob(base64Data.split(",")[1]);
    const mimeType = base64Data.match(/:(.*?);/)[1];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeType});
}

export function createCanvasImage(base64Data) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const image = new Image();
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0);
            const posterUrl = canvas.toDataURL("image/png");
            // clear
            context.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = 0;
            canvas.height = 0;
            //
            resolve(posterUrl);
        };
        image.onerror = (e) => {
            reject(e)
        }
        image.src = base64Data;
    })
}


export function isVideoSequenceHeader(payload) {
    return (payload[0] >> 4) === FRAME_TYPE.keyFrame && payload[1] === AVC_PACKET_TYPE.sequenceHeader;
}


export function isTrue(value) {
    return value === true || value === 'true';
}

export function isFalse(value) {
    return value !== true && value !== 'true';
}

export function isWebGpuSupport() {
    let result = false;
    if ('gpu' in navigator) {
        result = true;
    }
    return result;
}


export function toString(value) {
    return value == null ? '' : '' + value;

}

export function sleep(t = 0) {
    return new Promise((resolve) => setTimeout(resolve, t))
}


export function setElementDataset(element, key, value) {
    if (!element) {
        return;
    }

    if (element.dataset) {
        element.dataset[key] = value;
    } else {
        element.setAttribute('data-' + key, value);
    }
}

export function getElementDataset(element, key) {
    if (!element) {
        return '';
    }

    if (element.dataset) {
        return element.dataset[key];
    }
    return element.getAttribute('data-' + key);
}

export function removeElementDataset(element, key) {
    if (!element) {
        return;
    }

    if (element.dataset) {
        delete element.dataset[key];
    } else {
        element.removeAttribute('data-' + key);
    }
}

export function convertToCamelCase(str) {
    return str.replace(/-([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    });
}


export function isHlsSupported() {

}

//  是否iphone 手机端
export function isIphone() {
    return /iphone/i.test(navigator.userAgent);
}

//
export function getPerformanceMemory() {
    if (window.performance && window.performance.memory) {
        return window.performance.memory;
    }
    return null;
}


export function arraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
}

export function getArrayBufferBytes(arrayBuffer, start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(arrayBuffer[i]);
    }
    return result;
}


export function dropSpecialMp4Box(arrayBuffer) {
    const newBuffer = new Uint8Array(32);
    newBuffer.set(arrayBuffer.slice(arrayBuffer.byteLength - 32));
    const tempBuffer = getArrayBufferBytes(newBuffer, 4, 8);
    if (arraysEqual([101, 103, 119, 99], tempBuffer)) {
        return arrayBuffer.slice(0, arrayBuffer.byteLength - 32);
    }

    return arrayBuffer;
}


export function isWebGL2Supported() {
    try {
        var canvas = document.createElement('canvas');
        return !!(
            window.WebGL2RenderingContext &&
            canvas.getContext('webgl2')
        );
    } catch (e) {
        return false;
    }
}


export function isWebGLSupported() {
    try {
        var canvas = document.createElement('canvas');
        return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
}

export function function2String(fun) {
    return fun.trim().match(/^function\s*\w*\s*\([\w\s,]*\)\s*{([\w\W]*?)}$/)[1]
}


export function supportVideoFrameCallback() {
    let result = false;
    if ("requestVideoFrameCallback" in HTMLVideoElement.prototype) {
        result = true;
    }

    return result;
}

//
export function blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result);
        }
        reader.onerror = function (e) {
            reject(e);
        }
        reader.readAsArrayBuffer(blob);
    })
}


/**
 * 将一帧图像划分为两部分，上下各一半；然后在其中一般随机选择10*10的部分进行检测，如果检测到绿色像素点超过200个，则认为是绿屏
 * @param arraybuffer
 * @param width
 * @param height
 * @returns {boolean}
 */
export function checkGreenScreen(arraybuffer, width, height) {
    if (!arraybuffer || width <= 0 || height <= 0) {
        return true;
    }
    const x1 = Math.random() % (width - 9);
    const y1 = Math.random() % (height / 2 - 9);

    const x2 = Math.random() % (width - 9);
    const y2 = Math.random() % (height / 2 - 9) + height / 2;

    let zeroCount = 0;
    for (let j = y1; j < y1 + 10; j++) {
        for (let i = x1; i < x1 + 10; i++) {
            const index = j * width + i;
            if (arraybuffer[index] === 0) {
                zeroCount += 1;
            }
        }
    }

    for (let j = y2; j < y2 + 10; j++) {
        for (let i = x2; i < x2 + 10; i++) {
            const index = j * width + i;
            if (arraybuffer[index] === 0) {
                zeroCount += 1;
            }
        }
    }

    if (zeroCount > 200) {
        return true;
    }

    return false;
}


export function supportPressureObserver() {
    let result = false;
    //  or use 'globalThis' support
    if ('PressureObserver' in window) {
        result = true;
    }
    return result;
}


export function removeMaxAndMin(arr) {
    // 寻找最大值和最小值
    const max = Math.max(...arr);
    const min = Math.min(...arr);

    // 使用filter过滤掉最大值和最小值
    // 注意：这将仅删除第一个遇到的最大值和最小值
    return arr.filter(value => value !== max && value !== min);
}
