import Emitter from "../utils/emitter";
import {EVENTS, EVENTS_ERROR, PLAYER_STREAM_TYPE, WEBSOCKET_STATUS} from "../constant";
import {calculationRate, isTrue, now} from "../utils";

export default class WebsocketLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.socket = null;
        this.socketStatus = WEBSOCKET_STATUS.notConnect;
        this.wsUrl = null;
        this.requestAbort = false;
        this.socketDestroyFnList = [];
        //
        this.streamRate = calculationRate(rate => {
            player.emit(EVENTS.kBps, (rate / 1024).toFixed(2));
        });
        this.streamRateInterval = null;
        player.debug.log('WebsocketStream', 'init');

    }

    destroy() {
        this._closeWebSocket();
        this.stopStreamRateInterval();
        this.wsUrl = null;
        this.off();
        this.player.debug.log('WebsocketStream', 'destroy');
    }

    startStreamRateInterval() {
        this.stopStreamRateInterval();
        this.streamRateInterval = setInterval(() => {
            this.streamRate && this.streamRate(0)
        }, 1000)
    }

    stopStreamRateInterval() {
        if (this.streamRateInterval) {
            clearInterval(this.streamRateInterval);
            this.streamRateInterval = null;
        }
    }

    _createWebSocket() {
        const player = this.player;
        const {
            debug,
            events: {proxy},
            demux,
        } = player;

        this.socket = new WebSocket(this.wsUrl);
        this.socket.binaryType = 'arraybuffer';
        const socketOpenDestroy = proxy(this.socket, 'open', () => {
            debug.log('WebsocketStream', 'socket open');
            this.socketStatus = WEBSOCKET_STATUS.open;
            this.emit(EVENTS.streamSuccess);
            this.player.emit(EVENTS.websocketOpen);
            this.startStreamRateInterval();
        });

        const socketMessageDestroy = proxy(this.socket, 'message', event => {
            this.streamRate && this.streamRate(event.data.byteLength);
            this._handleMessage(event.data);
        });


        const socketCloseDestroy = proxy(this.socket, 'close', (event) => {
            debug.log('WebsocketStream', `socket close and code is ${event.code}`);
            if (event.code === 1006) {
                debug.error('WebsocketStream', `socket close abnormally and code is ${event.code}`);
            }

            if (isTrue(this.requestAbort)) {
                this.requestAbort = false;
                debug.log('WebsocketStream', `socket close and requestAbort is true`);
                return;
            }

            demux.close();
            this.socketStatus = WEBSOCKET_STATUS.close;
            this.player.emit(EVENTS.websocketClose);
            this.emit(EVENTS.streamEnd);
        });

        const socketErrorDestroy = proxy(this.socket, 'error', error => {
            debug.error('WebsocketStream', 'socket error', error);
            this.socketStatus = WEBSOCKET_STATUS.error;
            this.emit(EVENTS_ERROR.websocketError, error);
            demux.close();
            debug.log('WebsocketStream', `socket error:`, error.isTrusted ? 'websocket user aborted' : 'websocket error');
        });
        this.socketDestroyFnList.push(socketOpenDestroy, socketMessageDestroy, socketCloseDestroy, socketErrorDestroy);
    }

    _closeWebSocket() {
        this.socketDestroyFnList.forEach(event => event());
        if (this.socket &&
            (this.socket.readyState === 0 || this.socket.readyState === 1)) {
            this.requestAbort = true;
            // CONNECTING || OPEN
            this.socket.close(1000, 'Client disconnecting');
        } else {
            if (this.socket) {
                this.player.debug.log('WebsocketStream', `_closeWebSocket() socket is null or socket status is ${this.socket && this.socket.readyState}`);
            }
        }

        this.socket = null;
        this.socketStatus = WEBSOCKET_STATUS.notConnect;
        this.streamRate = null;
    }

    //
    _handleMessage(message) {
        const {demux} = this.player;
        if (!demux) {
            this.player.debug.warn('WebsocketStream', 'websocket handle message demux is null');
            return;
        }
        demux.dispatch(message);
    }

    /**
     *
     * @param url
     * @param options
     */
    fetchStream(url, options) {
        this.player._times.streamStart = now();
        this.wsUrl = url;
        this._createWebSocket();
    }

    sendMessage(msg) {
        if (this.socket) {
            if (this.socketStatus === WEBSOCKET_STATUS.open) {
                this.socket.send(msg);
            } else {
                this.player.debug.error('WebsocketStream', `websocket send message error and  socket status is ${this.socketStatus}`);
            }
        } else {
            this.player.debug.error('WebsocketStream', 'websocket send message socket is null');
        }
    }

    /**
     *
     */
    resetFetchStream() {
        this._closeWebSocket();
        this._createWebSocket();
    }

    getStreamType() {
        return PLAYER_STREAM_TYPE.websocket;
    }
}
