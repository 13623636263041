function readBig32(data, i = 0) {
    return (data[i] << 24 >>> 0) + (data[i + 1] << 16) + (data[i + 2] << 8) + (data[i + 3] || 0)
}

/**
 * parse annexb format
 * --Annex-B格式 也叫MPEG-2 transport stream format格式（ts格式）, ElementaryStream格式。
 * @param data
 * @returns {*[]}
 */
export function parseAnnexB(data) {
    const len = data.length
    let start = 2
    let end = 0
    while (data[start] !== null && data[start] !== undefined && data[start] !== 1) {
        start++
    }
    start++
    end = start + 2

    if (end >= len) return []

    const units = []

    while (end < len) {
        switch (data[end]) {
            case 0:
                if (data[end - 1] !== 0) {
                    end += 2
                    break
                } else if (data[end - 2] !== 0) {
                    end++
                    break
                }

                if (start !== end - 2) units.push(data.subarray(start, end - 2))

                do {
                    end++
                } while (data[end] !== 1 && end < len)
                start = end + 1
                end = start + 2
                break
            case 1:
                if (data[end - 1] !== 0 || data[end - 2] !== 0) {
                    end += 3
                    break
                }
                if (start !== end - 2) units.push(data.subarray(start, end - 2))
                start = end + 1
                end = start + 2
                break
            default:
                end += 3
                break
        }
    }

    if (start < len) units.push(data.subarray(start))

    return units
}


//  AVCC格式 也叫AVC1格式，MPEG-4格式，字节对齐，因此也叫Byte-Stream Format。用于mp4/flv/mkv, VideoToolbox。
// 使用NALU长度（固定字节，通常为4字节）分隔NAL。
export function parseAvcC(data, size = 4) {
    if (data.length < 4) return
    const dataLen = data.length
    const units = []

    let offset = 0
    let length
    while ((offset + size) < dataLen) {
        length = readBig32(data, offset)
        if (size === 3) length >>>= 8
        offset += size

        if (!length) continue
        if (offset + length > dataLen) {
            break
        }

        units.push(data.subarray(offset, offset + length))
        offset += length
    }

    return units
}

//  remove 0x000003
export function removeEPB(uint) {
    const length = uint.byteLength
    const emulationPreventionBytesPositions = []
    let i = 1

    while (i < length - 2) {
        if (uint[i] === 0 && uint[i + 1] === 0 && uint[i + 2] === 0x03) {
            emulationPreventionBytesPositions.push(i + 2)
            i += 2
        } else {
            i++
        }
    }

    if (!emulationPreventionBytesPositions.length) return uint

    const newLength = length - emulationPreventionBytesPositions.length
    const newData = new Uint8Array(newLength)

    let sourceIndex = 0
    for (i = 0; i < newLength; sourceIndex++, i++) {
        if (sourceIndex === emulationPreventionBytesPositions[0]) {
            sourceIndex++
            emulationPreventionBytesPositions.shift()
        }
        newData[i] = uint[sourceIndex]
    }

    return newData
}

//  parse SEI
export function parseSEI(unit, isHevc) {
    const len = unit.length
    let i = isHevc ? 2 : 1
    let type = 0
    let size = 0
    let uuid = ''

    while (unit[i] === 255) {
        type += 255
        i++
    }

    type += unit[i++]

    while (unit[i] === 255) {
        size += 255
        i++
    }
    size += unit[i++]

    if (type === 5 && len > i + 16) {
        for (let j = 0; j < 16; j++) {
            uuid += unit[i].toString(16)
            i++
        }
    }

    return {
        payload: unit.subarray(i), type, size, uuid
    }
}

// add 4 byte start code (normal [0,0,0,1])
export function addNaluStartCode(nalu) {
    const prefix = [0, 0, 0, 1];
    const newNalu = new Uint8Array(nalu.length + prefix.length);
    newNalu.set(prefix);
    newNalu.set(nalu, prefix.length);
    return newNalu;
}

//  add preview 4 byte length (preview tag size)
export function addNaleHeaderLength(nalUnit) {
    const nalUnitLength = nalUnit.byteLength;
    const header = new Uint8Array(4);
    header[0] = (nalUnitLength >>> 24) & 0xff;
    header[1] = (nalUnitLength >>> 16) & 0xff;
    header[2] = (nalUnitLength >>> 8) & 0xff;
    header[3] = nalUnitLength & 0xff;
    const result = new Uint8Array(nalUnitLength + 4);
    result.set(header, 0);
    result.set(nalUnit, 4);
    return result;
}

export function getUnitSizeFromVideoSequenceHeader(payload, isHevc) {
    let result = null;

    if (isHevc) {
        if (payload.length >= (23 + 5)) {
            result = ((payload[21 + 5] & 3) + 1);
        }
    } else {
        if (payload.length >= (7 + 5)) {
            result = ((payload[4 + 5] & 3) + 1);
        }
    }
    return result;
}
