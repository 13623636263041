import Emitter from "../utils/emitter";
import {EVENTS} from "../constant";

export default class AiLoader extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.faceDetector = null;
        this.objectDetector = null;
        this.imageDetector = null;
        this.occlusionDetector = null;
        this.initFaceDetector();
        this.initObjectDetector();
        this.initImageDetector();
        this.initOcclusionDetector();
        let tips = 'init';

        if (this.faceDetector) {
            tips += ' and use faceDetector'
        }

        if (this.objectDetector) {
            tips += ' and use objectDetector';
        }


        if (this.imageDetector) {
            tips += ' and use imageDetector';
        }

        if (this.occlusionDetector) {
            tips += ' and use occlusionDetector';
        }

        this.player.debug.log('AiLoader', tips);
    }

    destroy() {
        this.off();
        if (this.faceDetector) {
            this.faceDetector.destroy();
            this.faceDetector = null;
        }

        if (this.objectDetector) {
            this.objectDetector.destroy();
            this.objectDetector = null;
        }

        if (this.imageDetector) {
            this.imageDetector.destroy();
            this.imageDetector = null;
        }

        if (this.occlusionDetector) {
            this.occlusionDetector.destroy();
            this.occlusionDetector = null;
        }
        this.player.debug.log('AiLoader', 'destroy');
    }

    initFaceDetector() {
        if (this.player._opt.useFaceDetector &&
            window.JessibucaProFaceDetector) {
            const faceDetector = new JessibucaProFaceDetector({
                detectWidth: this.player._opt.aiFaceDetectWidth,
                showRect: false,
                debug: this.player._opt.debug,
                debugLevel: this.player._opt.debugLevel,
                debugUuid: this.player._opt.debugUuid,
            });
            faceDetector.load().then(() => {
                this.player.debug.log('AiLoader', 'init face detector success');
                this.faceDetector = faceDetector;

                this.faceDetector.on('jessibuca-pro-face-detector-info', (info) => {
                    this.player.emit(EVENTS.aiFaceDetectorInfo, info);

                    if (this.player._opt.aiFaceDetectShowRect) {
                        const config = this.player._opt.aiFaceDetectRectConfig || {};
                        const list = (info.list || []).map((item) => {
                            item.type = 'rect';
                            item.color = config.borderColor || '#0000FF';
                            item.lineWidth = config.borderWidth || 2;
                            return item;
                        });

                        if (this.player.video) {
                            this.player.video.addAiContentToCanvas(list);
                        }
                    }
                })
            })
        }
    }

    initObjectDetector() {
        if (this.player._opt.useObjectDetector &&
            window.JessibucaProObjectDetector) {
            const objectDetector = new JessibucaProObjectDetector({
                detectWidth: this.player._opt.aiObjectDetectWidth,
                showRect: false,
                debug: this.player._opt.debug,
                debugLevel: this.player._opt.debugLevel,
                debugUuid: this.player._opt.debugUuid,
            });
            objectDetector.load().then(() => {
                this.player.debug.log('AiLoader', 'init object detector success');
                this.objectDetector = objectDetector;

                this.objectDetector.on('jessibuca-pro-object-detector-info', (info) => {
                    this.player.emit(EVENTS.aiObjectDetectorInfo, info);

                    if (this.player._opt.aiObjectDetectShowRect) {
                        const list = [];
                        const config = this.player._opt.aiObjectDetectRectConfig || {};

                        (info.list || []).forEach((item) => {
                            const boxItem = {
                                type: 'rect',
                                color: config.borderColor || '#0000FF',
                                lineWidth: config.borderWidth || 2,
                                x: item.rect.x,
                                y: item.rect.y,
                                width: item.rect.width,
                                height: item.rect.height,
                            }
                            const textItem = {
                                type: 'text',
                                color: config.color || '#000',
                                fontSize: config.fontSize || 14,
                                text: item.zh,
                                x: item.rect.x,
                                y: item.rect.y - 25,
                            }

                            list.push(boxItem, textItem)
                        })

                        if (this.player.video) {
                            this.player.video.addAiContentToCanvas(list);
                        }
                    }
                })
            })
        }
    }

    initImageDetector() {
        if (this.player._opt.useImageDetector &&
            window.JessibucaProImageDetector) {
            const imageDetector = new JessibucaProImageDetector({
                debug: this.player._opt.debug,
                debugLevel: this.player._opt.debugLevel,
                debugUuid: this.player._opt.debugUuid,
            });
            imageDetector.load().then(() => {
                this.player.debug.log('AiLoader', 'init image detector success');
                this.imageDetector = imageDetector;
            })
        }
    }

    initOcclusionDetector() {
        if (this.player._opt.useOcclusionDetector &&
            window.JessibucaProOcclusionDetector) {
            const occlusionDetector = new JessibucaProOcclusionDetector({
                debug: this.player._opt.debug,
                debugLevel: this.player._opt.debugLevel,
                debugUuid: this.player._opt.debugUuid,
            });
            occlusionDetector.load().then(() => {
                this.player.debug.log('AiLoader', 'init occlusion detector success');
                this.occlusionDetector = occlusionDetector;
            })
        }
    }

    updateFaceDetectorConfig(config = {}) {
        if (this.faceDetector) {
            this.faceDetector.updateConfig(config);
        }
    }

    updateObjectDetectorConfig(config = {}) {
        if (this.objectDetector) {
            this.objectDetector.updateConfig(config);
        }
    }

    updateImageDetectorConfig(config = {}) {
        if (this.imageDetector) {
            this.imageDetector.updateConfig(config);
        }
    }
    updateOcclusionDetectorConfig(config = {}) {
        if (this.occlusionDetector) {
            this.occlusionDetector.updateConfig(config);
        }
    }
}
