import {downloadRecord, now} from "../utils";
import saveAs from "../utils/file-save";
// import EBML from '../utils/EBML'

import RecordRTC from 'recordrtc';
import {EVENTS, FILE_SUFFIX, RECORDING_TYPE, RENDER_TYPE} from "../constant";
import Emitter from "../utils/emitter";
import CommonLoader from "./commonLoader";

export default class RecordRTCLoader extends CommonLoader {
    constructor(player) {
        super(player);
        this.totalByteLength = 0;
        this._startRecordingTimestamp = null;
        player.debug.log('RecorderRTC', 'init');
    }

    _reset() {
        super._reset();
        this.totalByteLength = 0;
        this._startRecordingTimestamp = null;
        if (this.recorder) {
            this.recorder.destroy();
            this.recorder = null;
        }
    }

    destroy() {
        super.destroy();
        this._reset();
        this.player.debug.log('RecorderRTC', 'destroy');
    }


    getSeekableBlob(inputBlob) {
        const reader = new EBML.Reader();
        const decoder = new EBML.Decoder();
        const tool = EBML.tools;

        const fileReader = new FileReader();
        const renderPromise = new Promise((res, rej) => {
            fileReader.onload = function (e) {
                const ebmlElms = decoder.decode(this.result);
                ebmlElms.forEach(function (element) {
                    reader.read(element);
                });
                reader.stop();
                const refinedMetadataBuf = tool.makeMetadataSeekable(
                    reader.metadatas,
                    reader.duration,
                    reader.cues
                );
                const body = this.result.slice(reader.metadataSize);
                const newBlob = new Blob([refinedMetadataBuf, body], {
                    type: "video/webm",
                });

                res(newBlob);
            };
            fileReader.readAsArrayBuffer(inputBlob);
        });

        return renderPromise;
    }


    startRecord() {
        const debug = this.player.debug;
        const options = {
            type: 'video',
            mimeType: 'video/webm;codecs=h264',
            timeSlice: 1000,
            onTimeStamp: (timestamp) => {
                debug.log('RecorderRTC', 'record timestamp :' + timestamp);
                if (this._startRecordingTimestamp === null) {
                    this._startRecordingTimestamp = timestamp;
                }

                this._recordingTimestamp = (timestamp - this._startRecordingTimestamp) / 1000;
            },
            ondataavailable: (blob) => {
                this.totalByteLength += blob.size;
                debug.log('RecorderRTC', 'ondataavailable', blob.size);
            },
            disableLogs: !this.player._opt.debug
        }

        try {
            // video
            let stream = null;

            //  safari 浏览器不支持 captureStream() 方法。
            // canvas
            if (this.player.getRenderType() === RENDER_TYPE.canvas) {
                stream = this.player.video.$videoElement.captureStream(25);
            } else {
                // video
                // wcs or wasm decode
                if (this.player.video.mediaStream) {
                    stream = this.player.video.mediaStream;
                } else {
                    if (this.player.isOldHls()) {
                        // hls
                        stream = this.player.video.$videoElement.captureStream(25);
                    } else if (this.player._opt.useMSE) {
                        // mse decode
                        stream = this.player.video.$videoElement.captureStream(25);
                    } else if (this.player._opt.useWCS) {
                        // wcs
                        stream = this.player.video.$videoElement.captureStream(25);
                    } else if (this.player.isWebrtcH264()) {
                        // webrtc
                        stream = this.player.webrtc.videoStream;
                    } else if (this.player.isAliyunRtc()) {
                        //  aliyun rtc
                        stream = this.player.video.$videoElement.captureStream(25);
                    }
                }
            }
            if (stream) {
                // audio
                if (this.player.audio &&
                    this.player.audio.mediaStreamAudioDestinationNode &&
                    this.player.audio.mediaStreamAudioDestinationNode.stream &&
                    !this.player.audio.isStateSuspended() &&
                    this.player.audio.hasAudio &&
                    this.player._opt.hasAudio) {
                    const audioStream = this.player.audio.mediaStreamAudioDestinationNode.stream;
                    if (audioStream.getAudioTracks().length > 0) {
                        const audioTrack = audioStream.getAudioTracks()[0];
                        if (audioTrack && audioTrack.enabled) {
                            stream.addTrack(audioTrack);
                        }
                    }
                }
                this.recorder = RecordRTC(stream, options);
            } else {
                debug.error('RecorderRTC', 'startRecord error and can not create stream');
                this.player.emitError(EVENTS.recordCreateError, 'can not create stream');
                return;
            }

        } catch (e) {
            debug.error('RecorderRTC', 'startRecord error', e);
            this.player.emitError(EVENTS.recordCreateError, e);
            return;
        }
        if (this.recorder) {
            this._isRecording = true;
            this.player.emit(EVENTS.recording, true);
            this.recorder.startRecording();
            debug.log('RecorderRTC', 'start recording');
            this.player.emit(EVENTS.recordStart);
            this.startRecordingInterval();
        }
    }

    startRecordingInterval() {
        this.stopRecordingInterval();
        this.recordingInterval = window.setInterval(() => {
            this.player.emit(EVENTS.recordingTimestamp, this._recordingTimestamp);
        }, 1000);
    }

    stopRecordAndSave(type = RECORDING_TYPE.download, fileName) {
        return new Promise((resolve, reject) => {
            if (!this.recorder || !this._isRecording) {
                reject('recorder is not ready');
            }
            if (fileName) {
                this.setFileName(fileName);
            }
            this.recorder.stopRecording(() => {
                this.player.debug.log('RecorderRTC', 'stop recording');
                const fileName = (this.fileName || now()) + '.' + (FILE_SUFFIX.webm)
                const blob = this.recorder.getBlob();
                // this.getSeekableBlob(blob).then((seekableBlob) => {
                //     if (type === RECORDING_TYPE.blob) {
                //         resolve(seekableBlob);
                //         this.player.emit(EVENTS.recordBlob, seekableBlob);
                //     } else {
                //         resolve();
                //         RecordRTC.invokeSaveAsDialog(seekableBlob, fileName);
                //     }
                // })
                if (type === RECORDING_TYPE.blob) {
                    const blob = this.recorder.getBlob();
                    resolve(blob);
                    this.player.emit(EVENTS.recordBlob, blob);
                } else {
                    resolve();
                    // saveAs(blob, fileName)
                    this.recorder.save(fileName);
                }
                this.player.emit(EVENTS.recordEnd)
                this._reset();
                this.player.emit(EVENTS.recording, false);
            })
        });
    }


    getToTalByteLength() {
        return this.totalByteLength;
    }

    getTotalDuration() {
        return this.recordTime;
    }

    getType() {
        return FILE_SUFFIX.webm;
    }

    // 占个坑位
    initMetaData() {

    }
}
