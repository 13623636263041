import {getWebRtcRemoteSdp} from "../utils/webrtc";
import {EVENTS, EVENTS_ERROR} from "../constant";
import {calculationRate, isFalse, noop} from "../utils";
import CommonWebrtc from "./commonWebrtc";

export default class WebrtcDecoder extends CommonWebrtc {
    constructor(player) {
        super(player);

        this.rtcPeerConnectionDataChannel = null;
        if (this.player.isWebrtcH265()) {
            //
            this.streamRate = calculationRate(rate => {
                player.emit(EVENTS.kBps, (rate / 1024).toFixed(2));
            });
        }
        this.TAG_NAME = 'WebrtcForM7SDecoder';
        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this.stopStreamRateInterval();

        if (this.rtcPeerConnectionDataChannel) {
            this.rtcPeerConnectionDataChannel.onopen = noop;
            this.rtcPeerConnectionDataChannel.onclose = noop;
            this.rtcPeerConnectionDataChannel.onmessage = noop;
            this.rtcPeerConnectionDataChannel.close();
            this.rtcPeerConnectionDataChannel = null;
        }

        this.player.debug.log(this.TAG_NAME, 'destroy');
    }


    _initRtcPeerConnection() {
        const rtcPeerConnection = new RTCPeerConnection();
        const player = this.player;
        rtcPeerConnection.addTransceiver("video", {
            direction: "recvonly",
        });
        rtcPeerConnection.addTransceiver("audio", {
            direction: "recvonly",
        });

        rtcPeerConnection.onsignalingstatechange = (e) => {
            this.player.debug.log(this.TAG_NAME, "onsignalingstatechange", e);
        };

        // ICE代理的状态及其与ICE服务器（STUN、TURN）的连接
        rtcPeerConnection.oniceconnectionstatechange = (e) => {
            this.player.debug.log(this.TAG_NAME,
                "oniceconnectionstatechange",
                rtcPeerConnection.iceConnectionState
            );
            const iceConnectionState = rtcPeerConnection.iceConnectionState;

            this.player.emit(EVENTS.webrtcOnIceConnectionStateChange, iceConnectionState);

            this.isDisconnected = iceConnectionState === 'disconnected';
            switch (rtcPeerConnection.iceConnectionState) {
                case "new":
                    //  建立ICE连接
                    break;
                case "checking":
                    //  收集候选
                    break;
                case "closed":
                    // 断开ICE
                    break;
                case "failed":
                    //  没有合适候选
                    player.emit(EVENTS.webrtcFailed);
                    break;
                case "disconnected":
                    // 网络波动或者串流断开
                    player.emit(EVENTS.webrtcDisconnect);
                    break;
                case "connected":
                    //  匹配到可用候选
                    break;
                case "completed":
                    // 匹配完成，连接建立
                    break;
                case 'closed':
                    player.emit(EVENTS.webrtcClosed);
                    break;
                default:
                    break;
            }

        };
        // ICE代理的状态及其与ICE服务器（STUN、TURN）的连接
        rtcPeerConnection.onicecandidate = (event) => {
            this.player.debug.log(this.TAG_NAME, "onicecandidate", event);

            if (event.candidate) {
                this.player.debug.log(this.TAG_NAME, 'Remote ICE candidate: ', event.candidate.candidate);
                // Send the candidate to the remote peer
            } else {
                // All ICE candidates have been sent
            }
        };

        rtcPeerConnection.ontrack = (event) => {
            this.player.debug.log(this.TAG_NAME, "ontrack", event);
            const $video = player.video.$videoElement;

            if (event.track.kind === 'video' && player.isWebrtcH264()) {
                let videoStream = event.streams[0];
                $video.autoplay = true;
                $video.srcObject = videoStream;
                this.videoStream = videoStream;
            }
        }

        rtcPeerConnection.onicecandidateerror = (event) => {
            this.player.debug.log(this.TAG_NAME, 'onicecandidateerror', event);
            this.player.emitError(EVENTS_ERROR.webrtcIceCandidateError, event);
        }

        //  rtc ice所有传输组件(RTCIceTransport or RTCDtlsTransport(底层传输)类型)的聚合状态
        rtcPeerConnection.onconnectionstatechange = (event) => {
            player.debug.log(this.TAG_NAME, `sdp connect status ${rtcPeerConnection.connectionState}`);
            switch (rtcPeerConnection.connectionState) {
                case "new":
                    //  至少有一个的ICE传输组件（RTICETransport或RTCDTLTransport对象）处于new状态，
                    break;
                case "connecting":
                    //  一个或多个ICE传输组件目前正在建立连接；
                    break;
                case "connected":
                    //  一个或多个ICE传输组件目前正在建立连接；
                    // 所有ICE连接要么在使用中（connected或completed），要么closed；
                    break;
                case "disconnected":
                    // 至少一个ICE传输组件处于断开状态，
                    // 其他都不是failed、connecting或checking状态
                    break;
                case "failed":
                    // ICE传输组件处于failed状态.
                    if (this.isDisconnected) {
                        player.emit(EVENTS.webrtcFailed);
                    }
                    break;
                case "closed":
                    // RTCPeerConnection关闭
                    break;
                default:
                    break;
            }
        }


        //  for h265
        rtcPeerConnection.ondatachannel = (event) => {
            //  RTCDataChannel
            const channel = event.channel
            this.player.debug.log(this.TAG_NAME, 'ondatachannel');
            channel.onopen = () => {
                this.player.debug.log(this.TAG_NAME, 'ondatachannel and onopen');
            }

            channel.onmessage = (event) => {
                const data = event.data;
                if (this.player.isWebrtcH264()) {
                    this.player.debug.warn(this.TAG_NAME, 'ondatachannel is H265 but decode is h264 so emit webrtcStreamH265 ');
                    this.player.emit(EVENTS.webrtcStreamH265);
                    return;
                }
                // this.player.debug.log(this.TAG_NAME, 'ondatachannel,onmessage', data.byteLength);
                if (this.player.isDestroyed()) {
                    this.player.debug.warn(this.TAG_NAME, 'ondatachannel and player is destroyed');
                    return;
                }
                this.streamRate && this.streamRate(data.byteLength);
                if (this.player.demux) {
                    this.player.demux.dispatch(data);
                }
            }

            channel.onclose = () => {
                this.player.debug.warn(this.TAG_NAME, 'ondatachannel and onclose');
            }

            this.rtcPeerConnectionDataChannel = channel;
        }

        const signalChannel = rtcPeerConnection.createDataChannel("signal");
        signalChannel.onmessage = (event) => {
            this.player.debug.log(this.TAG_NAME, 'signalChannel,onmessage', event);
            const signal = JSON.parse(event.data)
            switch (signal.type) {
                case "offer":
                    break;
                case "answer":
                    break;
                case "candidate":
                    break;
                case "remove":
                    break;
                default:
                    break;
            }
        }

        this.rtcPeerConnection = rtcPeerConnection;
    }

    startStreamRateInterval() {
        this.stopStreamRateInterval();
        this.streamRateInterval = setInterval(() => {
            this.streamRate && this.streamRate(0)
        }, 1000)
    }

    stopStreamRateInterval() {
        if (this.streamRateInterval) {
            clearInterval(this.streamRateInterval);
            this.streamRateInterval = null;
        }
    }


    loadSource(url) {
        return new Promise((resolve, reject) => {
            const rtcPeerConnection = this.rtcPeerConnection;
            rtcPeerConnection.createOffer().then((res) => {
                rtcPeerConnection.setLocalDescription(res);
                this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp loadSource`);
                getWebRtcRemoteSdp(url, res.sdp).then((response) => {
                    response.text().then((sdp) => {
                        this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp response`);
                        rtcPeerConnection.setRemoteDescription(
                            new RTCSessionDescription({
                                type: "answer",
                                sdp
                            })
                        ).then(() => {
                            if (this.player.isWebrtcH265()) {
                                this.startStreamRateInterval();
                            }
                            resolve()
                        }).catch((e) => {
                            reject(e);
                        })

                    }).catch((e) => {
                        this.player.debug.error(this.TAG_NAME, `loadSource response.text() error`, e);
                        reject(e)
                    })
                }).catch((e) => {
                    this.player.debug.error(this.TAG_NAME, `loadSource getWebRtcRemoteSdp response error`, e);
                    reject(e);
                })
            }).catch((e) => {
                this.player.debug.error(this.TAG_NAME, `loadSource rtcPeerConnection.createOffer() error`, e);
                reject(e);
            })
        })
    }
}


