import Emitter from "./emitter";
import {isEmpty, isNotEmpty, sleep} from "./index";

const CACHESIZE = 2 * 1024 * 1024

const LoaderType = {
    FETCH: 'fetch',
    XHR: 'xhr'
}

const ResponseType = {
    ARRAY_BUFFER: 'arraybuffer',
    TEXT: 'text',
    JSON: 'json'
}

const EVENT = {
    REAL_TIME_SPEED: 'real_time_speed'

}

const toString = Object.prototype.toString

function isObject(a) {
    return a !== null && typeof a === 'object'
}

function isPlainObject(val) {
    if (toString.call(val) !== '[object Object]') {
        return false
    }

    const prototype = Object.getPrototypeOf(val)
    return prototype === null || prototype === Object.prototype
}

function isDate(a) {
    return toString.call(a) === '[object Date]'
}

function isNumber(n) {
    return typeof n === 'number' && !Number.isNaN(n)
}


function getConfig(cfg) {
    return {
        loaderType: LoaderType.FETCH,
        retry: 0,
        retryDelay: 0, // ms
        timeout: 0,
        request: null, // Request
        onTimeout: undefined,
        onProgress: undefined,
        onRetryError: undefined,
        transformRequest: undefined,
        transformResponse: undefined,
        transformError: undefined,
        responseType: ResponseType.TEXT,
        range: undefined,
        url: '',
        params: undefined,
        method: 'GET',
        headers: {},
        body: undefined,
        mode: undefined,
        credentials: undefined,
        cache: undefined,
        redirect: undefined,
        referrer: undefined,
        referrerPolicy: undefined,
        integrity: undefined,
        onProcessMinLen: 0,
        ...cfg
    }
}

function getRangeValue(value) {
    if (!value || value[0] === null || value[0] === undefined || (value[0] === 0 && (value[1] === null || value[1] === undefined))) {
        return
    }
    let ret = 'bytes=' + value[0] + '-'
    if (value[1]) ret += value[1]
    return ret
}

function encode(val) {
    return encodeURIComponent(val)
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, '+')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']')
}

function setUrlParams(url, params) {
    if (!url) return
    if (!params) return url
    let v
    const str = Object.keys(params).map(k => {
        v = params[k]
        if (v === null || v === undefined) return
        if (Array.isArray(v)) {
            k = k + '[]'
        } else {
            v = [v]
        }

        return v.map(x => {
            if (isDate(x)) {
                x = x.toISOString()
            } else if (isObject(x)) {
                x = JSON.stringify(x)
            }
            return `${encode(k)}=${encode(x)}`
        }).join('&')
    }).filter(Boolean).join('&')

    if (str) {
        const hashIndex = url.indexOf('#')
        if (hashIndex !== -1) {
            url = url.slice(0, hashIndex)
        }

        url += (url.indexOf('?') === -1 ? '?' : '&') + str
    }

    return url
}

function createResponse(
    data,
    done,
    response,
    contentLength,
    age,
    startTime,
    firstByteTime,
    index,
    range,
    vid,
    priOptions
) {
    age = (age !== null && age !== undefined) ? parseFloat(age) : null
    contentLength = parseInt(contentLength || '0', 10)
    if (Number.isNaN(contentLength)) contentLength = 0
    const option = {range, vid, index, contentLength, age, startTime, firstByteTime, endTime: Date.now(), priOptions}
    return {data, done, option, response}
}

function calculateSpeed(byteLen, millisec) {
    return Math.round(byteLen * 8 * 1000 / millisec / 1024)
}

class NetError extends Error {
    retryCount = 0
    isTimeout = false
    loaderType = LoaderType.FETCH
    startTime = 0
    endTime = 0
    options = {}

    constructor(url, request, response, msg) {
        super(msg)
        this.url = url
        this.request = request
        this.response = response
    }
}


class FetchLoader extends Emitter {
    _abortController = null
    _timeoutTimer = null
    _reader = null
    _response = null
    _aborted = false
    _index = -1
    _range = null
    _receivedLength = 0
    _running = false
    _logger = null
    _vid = ''
    _onProcessMinLen = 0
    _onCancel = null
    _priOptions = null // 比较私有化的参数传递，回调时候透传
    TAG_NAME = 'FetchLoader'

    constructor(player) {
        super()
        this.player = player;
    }

    load({
             url,
             vid,
             timeout, // ms
             responseType,
             onProgress,
             index,
             onTimeout,
             onCancel,
             range,
             transformResponse,
             request,
             params,
             logger,

             method,
             headers,
             body,
             mode,
             credentials,
             cache,
             redirect,
             referrer,
             referrerPolicy,
             onProcessMinLen,
             priOptions
         }) {
        this._aborted = false
        this._onProcessMinLen = onProcessMinLen
        this._onCancel = onCancel
        this._abortController = typeof AbortController !== 'undefined' && new AbortController()
        this._running = true
        this._index = index
        this._range = range || [0, 0]
        this._vid = vid || url
        this._priOptions = priOptions || {}
        const init = {
            method,
            headers,
            body,
            mode,
            credentials,
            cache,
            redirect,
            referrer,
            referrerPolicy,
            signal: this._abortController?.signal
        }

        let isTimeout = false
        clearTimeout(this._timeoutTimer)

        url = setUrlParams(url, params)

        const rangeValue = getRangeValue(range)
        if (rangeValue) {
            if (request) {
                headers = request.headers
            } else {
                headers = init.headers = init.headers || (Headers ? new Headers() : {})
            }
            if (Headers && headers instanceof Headers) {
                headers.append('Range', rangeValue)
            } else {
                headers.Range = rangeValue
            }
        }

        if (timeout) {
            this._timeoutTimer = setTimeout(() => {
                isTimeout = true
                this.cancel()
                if (onTimeout) {
                    const error = new NetError(url, init, null, 'timeout')
                    error.isTimeout = true
                    onTimeout(error, {
                        index: this._index,
                        range: this._range,
                        vid: this._vid,
                        priOptions: this._priOptions
                    })
                }
            }, timeout)
        }

        const startTime = Date.now()
        if (isNotEmpty(index) || isNotEmpty(range)) {
            this.player.debug.log(this.TAG_NAME, '[fetch load start], index,', index, ',range,', range)
        }
        return new Promise((resolve, reject) => {
            fetch(request || url, request ? undefined : init).then(async (response) => {
                clearTimeout(this._timeoutTimer)
                this._response = response
                if (this._aborted || !this._running) return
                if (transformResponse) {
                    response = transformResponse(response, url) || response
                }
                if (!response.ok) {
                    throw new NetError(url, init, response, 'bad network response')
                }

                const firstByteTime = Date.now()
                let data
                if (responseType === ResponseType.TEXT) {
                    data = await response.text()
                    this._running = false
                } else if (responseType === ResponseType.JSON) {
                    data = await response.json()
                    this._running = false
                } else {
                    if (onProgress) {
                        this.resolve = resolve
                        this.reject = reject
                        this._loadChunk(response, onProgress, startTime, firstByteTime)
                        return
                    } else {
                        data = await response.arrayBuffer()
                        data = new Uint8Array(data)
                        this._running = false
                        const costTime = Date.now() - startTime
                        const speed = calculateSpeed(data.byteLength, costTime)
                        this.emit(EVENT.REAL_TIME_SPEED, {
                            speed,
                            len: data.byteLength,
                            time: costTime,
                            vid: this._vid,
                            index: this._index,
                            range: this._range,
                            priOptions: this._priOptions
                        })
                    }
                }
                if (isNotEmpty(index) || isNotEmpty(range)) {
                    this.player.debug.log(this.TAG_NAME, '[fetch load end], index,', index, ',range,', range);
                }
                resolve(createResponse(
                    data,
                    true,
                    response,
                    response.headers.get('Content-Length'),
                    response.headers.get('age'),
                    startTime,
                    firstByteTime,
                    index,
                    range,
                    this._vid,
                    this._priOptions
                ))
            }).catch((error) => {
                clearTimeout(this._timeoutTimer)
                this._running = false
                if (this._aborted && !isTimeout) return
                error = error instanceof NetError ? error : new NetError(url, init, null, error?.message)
                error.startTime = startTime
                error.endTime = Date.now()
                error.isTimeout = isTimeout
                error.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
                reject(error)
            })
        })
    }

    async cancel() {
        if (this._aborted) return
        this._aborted = true
        this._running = false
        if (this._response) {
            try {
                // await this._response.body.cancel()
                if (this._reader) {
                    await this._reader.cancel()
                }
            } catch (error) {
                // ignore
            }
            this._response = this._reader = null
        }

        if (this._abortController) {
            try {
                this._abortController.abort()
            } catch (error) {
                // ignore
            }
            this._abortController = null
        }
        if (this._onCancel) {
            this._onCancel({index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions})
        }
    }

    _loadChunk(response, onProgress, st, firstByteTime) {
        if (!response.body || !response.body.getReader) {
            this._running = false
            const err = new NetError(response.url, '', response, 'onProgress of bad response.body.getReader')
            err.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
            this.reject(err)
            return
        }
        if (this._onProcessMinLen > 0) {
            this._cache = new Uint8Array(CACHESIZE)
            this._writeIdx = 0
        }
        const reader = this._reader = response.body.getReader()
        let data

        let startTime
        let endTime
        const pump = async () => {
            startTime = Date.now()
            try {
                data = await reader.read()
                endTime = Date.now()
            } catch (e) {
                // request aborted
                endTime = Date.now()
                if (!this._aborted) {
                    this._running = false
                    e.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
                    this.reject(e)
                }
                return
            }
            const startRange = this._range?.length > 0 ? this._range[0] : 0
            const startByte = startRange + this._receivedLength
            if (this._aborted) {
                this._running = false
                onProgress(undefined, false, {
                    range: [startByte, startByte],
                    vid: this._vid,
                    index: this._index,
                    startTime,
                    endTime,
                    st,
                    firstByteTime,
                    priOptions: this._priOptions
                }, response)
                return
            }
            const curLen = data.value ? data.value.byteLength : 0
            this._receivedLength += curLen
            this.player.debug.log(this.TAG_NAME, '【fetchLoader,onProgress call】,task,', this._range, ', start,', startByte, ', end,', startRange + this._receivedLength, ', done,', data.done)
            let retData
            if (this._onProcessMinLen > 0) {
                if (this._writeIdx + curLen >= this._onProcessMinLen || data.done) {
                    retData = new Uint8Array(this._writeIdx + curLen)
                    retData.set(this._cache.slice(0, this._writeIdx), 0)
                    curLen > 0 && retData.set(data.value, this._writeIdx)
                    this._writeIdx = 0
                    this.player.debug.log(this.TAG_NAME, '【fetchLoader,onProgress enough】,done,', data.done, ',len,', retData.byteLength, ', writeIdx,', this._writeIdx)
                } else {
                    if (curLen > 0 && this._writeIdx + curLen < CACHESIZE) {
                        this._cache.set(data.value, this._writeIdx)
                        this._writeIdx += curLen
                        this.player.debug.log(this.TAG_NAME, '【fetchLoader,onProgress cache】,len,', curLen, ', writeIdx,', this._writeIdx)
                    } else if (curLen > 0) {
                        const temp = new Uint8Array(this._writeIdx + curLen + 2048)
                        this.player.debug.log(this.TAG_NAME, '【fetchLoader,onProgress extra start】,size,', this._writeIdx + curLen + 2048, ', datalen,', curLen, ', writeIdx,', this._writeIdx)
                        temp.set(this._cache.slice(0, this._writeIdx), 0)
                        curLen > 0 && temp.set(data.value, this._writeIdx)
                        this._writeIdx += curLen
                        delete this._cache
                        this._cache = temp
                        this.player.debug.log(this.TAG_NAME, '【fetchLoader,onProgress extra end】,len,', curLen, ', writeIdx,', this._writeIdx)
                    }
                }
            } else {
                retData = data.value
            }
            if (retData && retData.byteLength > 0 || data.done) {
                onProgress(retData, data.done, {
                    range: [this._range[0] + this._receivedLength - (retData ? retData.byteLength : 0), this._range[0] + this._receivedLength],
                    vid: this._vid,
                    index: this._index,
                    startTime,
                    endTime,
                    st,
                    firstByteTime,
                    priOptions: this._priOptions
                }, response)
            }
            if (!data.done) {
                pump()
            } else {
                const costTime = Date.now() - st
                const speed = calculateSpeed(this._receivedLength, costTime)
                this.emit(EVENT.REAL_TIME_SPEED, {
                    speed,
                    len: this._receivedLength,
                    time: costTime,
                    vid: this._vid,
                    index: this._index,
                    range: this._range,
                    priOptions: this._priOptions
                })
                this._running = false
                this.player.debug.log(this.TAG_NAME, '[fetchLoader onProgress end],task,', this._range, ',done,', data.done)
                this.resolve(createResponse(
                    data,
                    true,
                    response,
                    response.headers.get('Content-Length'),
                    response.headers.get('age'),
                    st,
                    firstByteTime,
                    this._index,
                    this._range,
                    this._vid,
                    this._priOptions
                ))
            }
        }
        pump()
    }

    get receiveLen() {
        return this._receivedLength
    }

    get running() {
        return this._running
    }

    set running(status) {
        this._running = status
    }

    static isSupported() {
        return !!(typeof fetch !== 'undefined')
    }
}

class Task {
    TAG_NAME = 'Task'

    constructor(type, config, player) {
        this.promise = createPublicPromise()
        this.alive = !!config.onProgress
        this._loaderType = type
        this.player = player;
        this._loader = type === LoaderType.FETCH && !!window.fetch ? new FetchLoader(player) : new XhrLoader(player)
        this._config = config
        this._retryCount = 0
        this._retryTimer = null
        this._canceled = false
        this._retryCheckFunc = config.retryCheckFunc
    }

    exec() {
        const {
            retry,
            retryDelay,
            onRetryError,
            transformError,
            ...rest
        } = this._config

        const request = async () => {
            try {
                const response = await this._loader.load(rest)
                this.promise.resolve(response)
            } catch (e) {
                this._loader.running = false
                this.player.debug.log(this.TAG_NAME, '[task request catch err]', e)
                if (this._canceled) return

                e.loaderType = this._loaderType
                e.retryCount = this._retryCount

                let error = e
                if (transformError) {
                    error = transformError(error) || error
                }

                if (onRetryError && this._retryCount > 0) onRetryError(error, this._retryCount, {
                    index: rest.index,
                    vid: rest.vid,
                    range: rest.range,
                    priOptions: rest.priOptions
                })

                this._retryCount++
                let isRetry = true
                if (this._retryCheckFunc) {
                    isRetry = this._retryCheckFunc(e)
                }
                if (isRetry && this._retryCount <= retry) {
                    clearTimeout(this._retryTimer)
                    this.player.debug.log(this.TAG_NAME, '[task request setTimeout],retry', this._retryCount, ',retry range,', rest.range)
                    this._retryTimer = setTimeout(request, retryDelay)
                    return
                }
                this.promise.reject(error)
            }
        }

        request()
        return this.promise
    }

    async cancel() {
        clearTimeout(this._retryTimer)
        this._canceled = true
        this._loader.running = false
        return this._loader.cancel()
    }

    get running() {
        return this._loader && this._loader.running
    }

    get loader() {
        return this._loader
    }
}


class XhrLoader extends Emitter {

    _xhr = null
    _aborted = false
    _timeoutTimer = null
    _range = null
    _receivedLength = 0
    _url = null
    _onProgress = null
    _index = -1
    _headers = null
    // _chunkSizeKBList = [
    //   128, 256, 384, 512, 768, 1024, 1536, 2048, 3072, 4096, 5120, 6144, 7168, 8192
    // ]

    _currentChunkSizeKB = 384
    _timeout = null
    _xhr = null
    _withCredentials = null
    _startTime = -1
    // _speedSampler = new SpeedSampler()
    _loadCompleteResolve = null
    _loadCompleteReject = null
    _runing = false
    _logger = false
    _vid = ''
    _responseType
    _credentials
    _method
    _transformResponse
    _firstRtt
    _onCancel = null
    _priOptions = null // 比较私有化的参数传递，回调时候透传
    TAG_NAME = 'XhrLoader'

    constructor(player) {
        super()
        this.player = player;
    }

    load(req) {
        clearTimeout(this._timeoutTimer)
        this._range = req.range
        this._onProgress = req.onProgress
        this._index = req.index
        this._headers = req.headers
        this._withCredentials = req.credentials === 'include' || req.credentials === 'same-origin'
        this._body = req.body || null
        req.method && (this._method = req.method)
        this._timeout = req.timeout || null
        this._runing = true
        this._vid = req.vid || req.url
        this._responseType = req.responseType
        this._firstRtt = -1
        this._onTimeout = req.onTimeout
        this._onCancel = req.onCancel
        this._request = req.request
        this._priOptions = req.priOptions || {}
        this.player.debug.log(this.TAG_NAME, '【xhrLoader task】, range', this._range)

        this._url = setUrlParams(req.url, req.params)

        const startTime = Date.now()
        return new Promise((resolve, reject) => {
            this._loadCompleteResolve = resolve
            this._loadCompleteReject = reject
            this._startLoad()
        }).catch((error) => {
            clearTimeout(this._timeoutTimer)
            this._runing = false
            if (this._aborted) return
            error = error instanceof NetError ? error : new NetError(this._url, this._request)
            error.startTime = startTime
            error.endTime = Date.now()
            error.options = {index: this._index, vid: this._vid, priOptions: this._priOptions}
            throw error
        })
    }

    _startLoad() {
        let range = null
        if (this._responseType === ResponseType.ARRAY_BUFFER && this._range && this._range.length > 1) {
            if (this._onProgress) {
                this._firstRtt = -1
                const chunkSize = this._currentChunkSizeKB * 1024
                const from = this._range[0] + this._receivedLength
                let to = this._range[1]
                if (chunkSize < this._range[1] - from) {
                    to = from + chunkSize
                }
                range = [from, to]
                this.player.debug.log(this.TAG_NAME, '[xhr_loader->],tast :', this._range, ', SubRange, ', range)
            } else {
                range = this._range
                this.player.debug.log(this.TAG_NAME, '[xhr_loader->],tast :', this._range, ', allRange, ', range)
            }
        }
        this._internalOpen(range)
    }

    _internalOpen(range) {
        try {
            this._startTime = Date.now()
            const xhr = this._xhr = new XMLHttpRequest()
            xhr.open(this._method || 'GET', this._url, true)
            xhr.responseType = this._responseType
            this._timeout && (xhr.timeout = this._timeout)
            xhr.withCredentials = this._withCredentials
            xhr.onload = this._onLoad.bind(this)
            xhr.onreadystatechange = this._onReadyStatechange.bind(this)
            xhr.onerror = (errorEvent) => {
                this._running = false
                const error = new NetError(this._url, this._request, errorEvent?.currentTarget?.response, ('xhr.onerror.status:' + errorEvent?.currentTarget?.status + ',statusText,' + errorEvent?.currentTarget?.statusText))
                error.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
                this._loadCompleteReject(error)
            }
            xhr.ontimeout = (event) => {
                this.cancel()
                const error = new NetError(this._url, this._request, {status: 408}, 'timeout')
                if (this._onTimeout) {
                    error.isTimeout = true
                    this._onTimeout(error, {
                        index: this._index,
                        range: this._range,
                        vid: this._vid,
                        priOptions: this._priOptions
                    })
                }
                error.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
                this._loadCompleteReject(error)
            }
            const headers = this._headers || {}
            const rangeValue = getRangeValue(range)
            if (rangeValue) {
                headers.Range = rangeValue
            }
            if (headers) {
                Object.keys(headers).forEach(k => {
                    xhr.setRequestHeader(k, headers[k])
                })
            }
            this.player.debug.log(this.TAG_NAME, '[xhr.send->] tast,', this._range, ',load sub range, ', range)
            xhr.send(this._body)
        } catch (e) {
            e.options = {index: this._index, range, vid: this._vid, priOptions: this._priOptions}
            this._loadCompleteReject(e)
        }
    }

    _onReadyStatechange(e) {
        const xhr = e.target
        if (xhr.readyState === 2) {
            this._firstRtt < 0 && (this._firstRtt = Date.now())
        }
    }

    _onLoad(e) {
        const status = e.target.status
        if (status < 200 || status > 299) {
            const error = new NetError(this._url, null, {...e.target.response, status}, 'bad response,status:' + status)
            error.options = {index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions}
            return this._loadCompleteReject(error)
        }
        let data = null
        let done = false
        let byteStart
        const startRange = (this._range?.length > 0 ? this._range [0] : 0)
        if (this._responseType === ResponseType.ARRAY_BUFFER) {
            const chunk = new Uint8Array(e.target.response)
            byteStart = startRange + this._receivedLength
            if (chunk && chunk.byteLength > 0) {
                this._receivedLength += chunk.byteLength
                const costTime = Date.now() - this._startTime
                const speed = calculateSpeed(this._receivedLength, costTime)
                this.emit(EVENT.REAL_TIME_SPEED, {
                    speed,
                    len: this._receivedLength,
                    time: costTime,
                    vid: this._vid,
                    index: this._index,
                    range: [byteStart, startRange + this._receivedLength],
                    priOptions: this._priOptions
                })
            }
            data = chunk
            if (this._range?.length > 1 && this._range[1] && this._receivedLength < this._range[1] - this._range[0]) {
                done = false
            } else {
                done = true
            }
            this.player.debug.log(this.TAG_NAME, '[xhr load done->], tast :', this._range, ', start', byteStart, 'end ', startRange + this._receivedLength, ',dataLen,', (chunk ? chunk.byteLength : 0), ',receivedLength', this._receivedLength, ',index,', this._index, ', done,', done)
        } else {
            done = true
            data = e.target.response
        }
        let response = {
            ok: status >= 200 && status < 300,
            status,
            statusText: this._xhr.statusText,
            url: this._xhr.responseURL,
            headers: this._getHeaders(this._xhr),
            body: this._xhr.response
        }
        if (this._transformResponse) {
            response = this._transformResponse(response, this._url) || response
        }
        if (this._onProgress) {
            this._onProgress(data, done, {
                index: this._index,
                vid: this._vid,
                range: [byteStart, startRange + this._receivedLength],
                startTime: this._startTime,
                endTime: Date.now(),
                priOptions: this._priOptions
            }, response)
        }

        if (!done) {
            this._startLoad()
        } else {
            this._runing = false
            this._loadCompleteResolve && this._loadCompleteResolve(createResponse(
                this._onProgress ? null : data,
                done,
                response,
                response.headers['content-length'],
                response.headers.age,
                this._startTime,
                this._firstRtt,
                this._index,
                this._range,
                this._vid,
                this._priOptions
            ))
        }
    }

    cancel() {
        if (this._aborted) return
        this._aborted = true
        this._runing = false
        super.removeAllListeners()
        if (this._onCancel) {
            this._onCancel({index: this._index, range: this._range, vid: this._vid, priOptions: this._priOptions})
        }
        if (this._xhr) {
            return this._xhr.abort()
        }
    }

    static isSupported() {
        return typeof XMLHttpRequest !== 'undefined'
    }

    get receiveLen() {
        return this._receivedLength
    }

    get running() {
        return this._running
    }

    set running(status) {
        this._running = status
    }

    _getHeaders(xhr) {
        const headerLines = xhr.getAllResponseHeaders().trim().split('\r\n')
        const headers = {}
        for (const header of headerLines) {
            const parts = header.split(': ')
            headers[parts[0].toLowerCase()] = parts.slice(1).join(': ')
        }
        return headers
    }
}


function createPublicPromise() {
    let res, rej
    const promise = new Promise((resolve, reject) => {
        res = resolve
        rej = reject
    })
    promise.used = false
    promise.resolve = (...args) => {
        promise.used = true
        return res(...args)
    }
    promise.reject = (...args) => {
        promise.used = true
        return rej(...args)
    }
    return promise
}

export default class NetLoader extends Emitter {
    type = LoaderType.FETCH

    _queue = []

    _alive = []

    _currentTask = null

    _config

    constructor(cfg, player) {
        super()
        this.player = player;
        this._config = getConfig(cfg)
        if (
            this._config.loaderType === LoaderType.XHR ||
            !FetchLoader.isSupported()
        ) {
            this.type = LoaderType.XHR
        }
    }

    destroy() {
        this._queue = []
        this._alive = []
        this._currentTask = null
    }

    isFetch() {
        return this.type === LoaderType.FETCH
    }

    static isFetchSupport() {
        return FetchLoader.isSupported()
    }

    load(url, config = {}) {
        if (typeof url === 'string' || !url) {
            config.url = url || config.url || this._config.url
        } else {
            config = url
        }

        config = Object.assign({}, this._config, config)
        if (config.params) config.params = Object.assign({}, config.params)
        if (config.headers && isPlainObject(config.headers)) config.headers = Object.assign({}, config.headers)
        if (config.body && isPlainObject(config.body)) config.body = Object.assign({}, config.body)

        if (config.transformRequest) {
            config = config.transformRequest(config) || config
        }

        const task = new Task(this.type, config, this.player)
        task.loader.on(EVENT.REAL_TIME_SPEED, (data) => {
            this.emit(EVENT.REAL_TIME_SPEED, data)
        })
        this._queue.push(task)
        if (this._queue.length === 1 && (!this._currentTask || !this._currentTask.running)) {
            this._processTask()
        }

        return task.promise
    }

    async cancel() {
        const cancels = this._queue.map(t => t.cancel()).concat(this._alive.map(t => t.cancel()))
        if (this._currentTask) {
            cancels.push(this._currentTask.cancel())
        }
        this._queue = []
        this._alive = []
        await Promise.all(cancels)
        // 不是很理解 sleep 的意思。
        await sleep()
    }

    _processTask() {
        this._currentTask = this._queue.shift()
        if (!this._currentTask) return

        if (this._currentTask.alive) {
            this._alive.push(this._currentTask)
        }
        const req = this._currentTask.exec().catch(e => {
        })

        if (!(req && typeof req.finally === 'function')) return

        req.finally(() => {
            if (this._currentTask?.alive && this._alive?.length > 0) {
                this._alive = this._alive.filter(task => task && task !== this._currentTask)
            }
            this._processTask()
        })

    }
}
