import {isFalse, supportPressureObserver} from "./index";
import {EVENTS} from "../constant";

export default class PressureObserverCpu {
    constructor(player) {
        this.player = player;
        this.TAG_NAME = 'PressureObserverCpu';
        this.observer = null;
        this.latestCpuInfo = null;
        this.currentLevel = -1;
        this._init();
        this.player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        if (this.observer) {
            // this.observer.unobserve('cpu')
            this.observer.disconnect();
            this.observer = null;
        }
        this.latestCpuInfo = null;
        this.currentLevel = -1;
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    getCurrentCpuState() {
        return this.currentLevel;
    }

    _init() {
        if (isFalse(supportPressureObserver())) {
            this.player.debug.log(this.TAG_NAME, 'not support PressureObserver');
            return;
        }

        this.observer = new PressureObserver(changes => {
            const latestCpuInfo = (changes || []).find((item) => {
                return item.source === 'cpu';
            }); // cpu
            if (latestCpuInfo) {
                this.latestCpuInfo = latestCpuInfo;
                switch (latestCpuInfo.state) {
                    case "nominal":
                        //  CPU 的压力正常
                        this.currentLevel = 0;
                        break;
                    case "fair":
                        // 表示 CPU 的压力正常，但是有一些任务正在运行。
                        this.currentLevel = 1;
                        break;
                    case "serious":
                        //  表示 CPU 的压力严重，但是仍然可以正常工作。
                        this.currentLevel = 2;
                        break;
                    case "critical":
                        // 表示 CPU 的压力非常严重，无法正常工作。
                        this.currentLevel = 3;
                        break;
                    default:
                        this.currentLevel = -1;
                        break;
                }

                this.player.emit(EVENTS.pressureObserverCpu, this.currentLevel);
            }
        })

        if (this.observer) {
            this.observer.observe("cpu");
        }
    }
}
