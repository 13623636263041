import {H265_NAL_TYPE, VIDEO_ENCODE_TYPE} from "../constant";
import SPSParser from "./h265-sps-parser";
import Bitop from "./bitop";
import {parseHevcVPS, parseHevcPPS, parseHevcSPS} from "./h265-vps-sps-pps-parser";
import ExpGolombV2 from "./exp-golomb-v2";
import {removeEPB} from "./nalu";

/**
 *
 * @param arrayBuffer
 * @returns {{}}
 */
export function parseHEVCDecoderConfigurationRecord(arrayBuffer) {
    const meta = {
        codecWidth: 0,
        codecHeight: 0,
        videoType: VIDEO_ENCODE_TYPE.h265
    }
    let offset = 28 - 5;
    //
    const vpsTag = arrayBuffer[offset];

    if (vpsTag !== H265_NAL_TYPE.vps) {
        return meta;
    }

    offset += 2;
    offset += 1;
    const vpsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);
    offset += 2;
    const vpsData = arrayBuffer.slice(offset, (offset + vpsLength));
    // console.log('vpsData:', Uint8Array.from(vpsData));
    offset += vpsLength;

    const spsTag = arrayBuffer[offset]
    if (spsTag !== H265_NAL_TYPE.sps) {
        return meta;
    }
    offset += 2;
    offset += 1;
    const spsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);

    offset += 2;
    const spsData = arrayBuffer.slice(offset, (offset + spsLength))
    // console.log('spsData:', Uint8Array.from(spsData));

    offset += spsLength;

    const ppsTag = arrayBuffer[offset];

    if (ppsTag !== H265_NAL_TYPE.pps) {
        return meta;
    }
    offset += 2;
    offset += 1;
    const ppsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);
    offset += 2;
    const ppsData = arrayBuffer.slice(offset, (offset + ppsLength))
    // console.log('ppsData:', Uint8Array.from(ppsData));

    let sps = Uint8Array.from(spsData);
    let config = SPSParser.parseSPS(sps);

    meta.codecWidth = config.codec_size.width;
    meta.codecHeight = config.codec_size.height;
    meta.presentWidth = config.present_size.width;
    meta.presentHeight = config.present_size.height;

    meta.profile = config.profile_string;
    meta.level = config.level_string;
    meta.bitDepth = config.bit_depth;
    meta.chromaFormat = config.chroma_format;
    meta.sarRatio = config.sar_ratio;

    return meta;
}

/**
 *
 * @param arrayBuffer
 */
export function parseHEVCDecoderConfigurationRecord$2(arrayBuffer) {
    let info = {};
    info.width = 0;
    info.height = 0;
    info.profile = 0;
    info.level = 0;
    //  remove 5 bytes
    arrayBuffer = arrayBuffer.slice(5);

    do {
        let hevc = {};
        if (arrayBuffer.length < 23) {
            console.warn('parseHEVCDecoderConfigurationRecord$2', `arrayBuffer.length ${arrayBuffer.length} < 23`)
            break;
        }

        hevc.configurationVersion = arrayBuffer[0];
        if (hevc.configurationVersion != 1) {
            break;
        }
        hevc.general_profile_space = (arrayBuffer[1] >> 6) & 0x03;
        hevc.general_tier_flag = (arrayBuffer[1] >> 5) & 0x01;
        hevc.general_profile_idc = arrayBuffer[1] & 0x1F;
        hevc.general_profile_compatibility_flags = (arrayBuffer[2] << 24) | (arrayBuffer[3] << 16) | (arrayBuffer[4] << 8) | arrayBuffer[5];
        hevc.general_constraint_indicator_flags = ((arrayBuffer[6] << 24) | (arrayBuffer[7] << 16) | (arrayBuffer[8] << 8) | arrayBuffer[9]);
        hevc.general_constraint_indicator_flags = (hevc.general_constraint_indicator_flags << 16) | (arrayBuffer[10] << 8) | arrayBuffer[11];
        hevc.general_level_idc = arrayBuffer[12];
        hevc.min_spatial_segmentation_idc = ((arrayBuffer[13] & 0x0F) << 8) | arrayBuffer[14];
        hevc.parallelismType = arrayBuffer[15] & 0x03;
        hevc.chromaFormat = arrayBuffer[16] & 0x03;
        hevc.bitDepthLumaMinus8 = arrayBuffer[17] & 0x07;
        hevc.bitDepthChromaMinus8 = arrayBuffer[18] & 0x07;
        hevc.avgFrameRate = (arrayBuffer[19] << 8) | arrayBuffer[20];
        hevc.constantFrameRate = (arrayBuffer[21] >> 6) & 0x03;
        hevc.numTemporalLayers = (arrayBuffer[21] >> 3) & 0x07;
        hevc.temporalIdNested = (arrayBuffer[21] >> 2) & 0x01;
        hevc.lengthSizeMinusOne = arrayBuffer[21] & 0x03;
        let numOfArrays = arrayBuffer[22];
        let p = arrayBuffer.slice(23);
        for (let i = 0; i < numOfArrays; i++) {
            if (p.length < 3) {
                break;
            }
            let nalutype = p[0] & 0x3F;
            let n = (p[1]) << 8 | p[2];
            // console.log('nalutype', nalutype,n)
            p = p.slice(3);
            for (let j = 0; j < n; j++) {
                if (p.length < 2) {
                    break;
                }
                let k = (p[0] << 8) | p[1];
                // console.log('k', k)
                if (p.length < 2 + k) {
                    break;
                }
                p = p.slice(2);
                if (nalutype == 33) {
                    //SPS
                    let sps = new Uint8Array(k);
                    sps.set(p.slice(0, k), 0);
                    hevc.psps = HEVCParseSPS(sps, hevc);
                    info.profile = hevc.general_profile_idc;
                    info.level = hevc.general_level_idc / 30.0;
                    info.width = hevc.psps.pic_width_in_luma_samples - (hevc.psps.conf_win_left_offset + hevc.psps.conf_win_right_offset);
                    info.height = hevc.psps.pic_height_in_luma_samples - (hevc.psps.conf_win_top_offset + hevc.psps.conf_win_bottom_offset);
                }
                p = p.slice(k);
            }
        }
    } while (0);

    info.codecWidth = info.width || 1920;
    info.codecHeight = info.height || 1080;
    info.presentHeight = info.codecHeight;
    info.presentWidth = info.codecWidth;
    info.timescale = 1000;
    info.refSampleDuration = 1000 * (1000 / 23976)
    info.videoType = VIDEO_ENCODE_TYPE.h265
    return info;
}

/**
 *
 * @param arrayBuffer
 * @param hvcC
 * @returns {{}|{ppsArr: *[], nalUnitSize: number, vpsArr: *[], sps: {chromaFormat: number, codec: string, width: *, height: *, hvcC: ({}|{})}, spsArr: *[], hvcC: {}}}
 */
export function parseHEVCDecoderConfigurationRecord$3(arrayBuffer, hvcC = {}) {

    function _parseProfileTierLevel(eg, maxSubLayersMinus1, hvcC) {
        const generalTierFlag = hvcC.generalTierFlag || 0
        hvcC.generalProfileSpace = eg.readBits(2)
        hvcC.generalTierFlag = Math.max(eg.readBits(1), generalTierFlag)
        hvcC.generalProfileIdc = Math.max(eg.readBits(5), hvcC.generalProfileIdc || 0)
        hvcC.generalProfileCompatibilityFlags = eg.readBits(32)
        hvcC.generalConstraintIndicatorFlags = [eg.readBits(8), eg.readBits(8), eg.readBits(8), eg.readBits(8), eg.readBits(8), eg.readBits(8)]
        const generalLevelIdc = eg.readBits(8)
        if (generalTierFlag < hvcC.generalTierFlag) {
            hvcC.generalLevelIdc = generalLevelIdc
        } else {
            hvcC.generalLevelIdc = Math.max(generalLevelIdc, hvcC.generalLevelIdc || 0)
        }

        const subLayerProfilePresentFlag = []
        const subLayerLevelPresentFlag = []
        for (let j = 0; j < maxSubLayersMinus1; j++) {
            subLayerProfilePresentFlag[j] = eg.readBits(1)
            subLayerLevelPresentFlag[j] = eg.readBits(1)
        }

        if (maxSubLayersMinus1 > 0) {
            eg.readBits((8 - maxSubLayersMinus1) * 2)
        }

        for (let i = 0; i < maxSubLayersMinus1; i++) {
            if (subLayerProfilePresentFlag[i] !== 0) {
                eg.readBits(2)
                eg.readBits(1)
                eg.readBits(5)

                eg.readBits(16)
                eg.readBits(16)

                eg.readBits(4)

                eg.readBits(16)
                eg.readBits(16)
                eg.readBits(12)
            }
            if (subLayerLevelPresentFlag[i] !== 0) {
                eg.readBits(8)
            }
        }
    }

    function parseVPS(unit, hvcC) {
        hvcC = hvcC || {}
        const eg = new ExpGolombV2(unit)
        eg.readUByte()
        eg.readUByte()

        eg.readBits(12)
        const vpsMaxSubLayersMinus1 = eg.readBits(3)
        hvcC.numTemporalLayers = Math.max(hvcC.numTemporalLayers || 0, vpsMaxSubLayersMinus1 + 1)
        eg.readBits(17)
        _parseProfileTierLevel(eg, vpsMaxSubLayersMinus1, hvcC)

        return hvcC
    }

    function parseSPS(unit, hvcC = {}) {
        hvcC = hvcC || {}
        const eg = new ExpGolombV2(unit)
        eg.readUByte()
        eg.readUByte()

        eg.readBits(4)
        const spsMaxSubLayersMinus1 = eg.readBits(3)
        hvcC.numTemporalLayers = Math.max(spsMaxSubLayersMinus1 + 1, hvcC.numTemporalLayers || 0)
        hvcC.temporalIdNested = eg.readBits(1)
        _parseProfileTierLevel(eg, spsMaxSubLayersMinus1, hvcC)

        eg.readUEG() // sps_seq_parameter_set_id

        const chromaFormatIdc = hvcC.chromaFormatIdc = eg.readUEG()
        let chromaFormat = 420
        if (chromaFormatIdc <= 3) chromaFormat = [0, 420, 422, 444][chromaFormatIdc]

        let separateColourPlaneFlag = 0
        if (chromaFormatIdc === 3) {
            separateColourPlaneFlag = eg.readBits(1)
        }

        let width = eg.readUEG() // pic_width_in_luma_samples
        let height = eg.readUEG() // pic_height_in_luma_samples

        const conformanceWindowFlag = eg.readBits(1)

        let confWinLeftOffset
        let confWinRightOffset
        let confWinTopOffset
        let confWinBottomOffset
        if (conformanceWindowFlag === 1) {
            confWinLeftOffset = eg.readUEG() // conf_win_left_offset
            confWinRightOffset = eg.readUEG() // conf_win_right_offset
            confWinTopOffset = eg.readUEG() // conf_win_top_offset
            confWinBottomOffset = eg.readUEG() // conf_win_bottom_offset
        }

        hvcC.bitDepthLumaMinus8 = eg.readUEG() // bit_depth_luma_minus8
        hvcC.bitDepthChromaMinus8 = eg.readUEG() // bit_depth_chroma_minus8

        if (conformanceWindowFlag === 1) {
            const subWidthC = (((chromaFormatIdc === 1) || (chromaFormatIdc === 2)) && (separateColourPlaneFlag === 0)) ? 2 : 1
            const subHeightC = ((chromaFormatIdc === 1) && (separateColourPlaneFlag === 0)) ? 2 : 1
            width -= (subWidthC * (confWinRightOffset + confWinLeftOffset))
            height -= (subHeightC * (confWinBottomOffset + confWinTopOffset))
        }

        return {
            codec: 'hev1.1.6.L93.B0', //
            width,
            height,
            chromaFormat,
            hvcC
        }
    }

    let info = {};
    info.width = 0;
    info.height = 0;
    info.profile = 0;
    info.level = 0;
    //  remove 5 bytes
    const data = arrayBuffer.slice(5);

    if (data.length < 23) {
        console.warn('parseHEVCDecoderConfigurationRecord$3', `arrayBuffer.length ${data.length} < 23`);
        return info;
    }
    const nalUnitSize = (data[21] & 3) + 1

    let vpsParsed
    let spsParsed
    const spsArr = []
    const ppsArr = []
    const vpsArr = []

    let offset = 23
    const numOfArrays = data[22]

    let nalUnitType
    let numNalus
    let nalSize
    for (let i = 0; i < numOfArrays; i++) {
        nalUnitType = data[offset] & 0x3f
        numNalus = (data[offset + 1] << 8) | data[offset + 2]

        offset += 3

        for (let j = 0; j < numNalus; j++) {
            nalSize = (data[offset] << 8) | data[offset + 1]
            offset += 2
            if (!nalSize) continue
            switch (nalUnitType) {
                case 32: {
                    const vps = data.subarray(offset, offset + nalSize)
                    if (!vpsParsed) vpsParsed = parseVPS(removeEPB(vps), hvcC)
                    vpsArr.push(vps)
                }
                    break
                case 33: {
                    const sps = data.subarray(offset, offset + nalSize)
                    if (!spsParsed) spsParsed = parseSPS(removeEPB(sps), hvcC)
                    spsArr.push(sps)
                }
                    break
                case 34:
                    ppsArr.push(data.subarray(offset, offset + nalSize))
                    break
                default:
            }

            offset += nalSize
        }
    }

    return {
        hvcC,
        sps: spsParsed,
        spsArr,
        ppsArr,
        vpsArr,
        nalUnitSize
    }
}

export function parseHEVCDecoderVPSAndSPSAndPPS(arrayBuffer) {
    //
    let offset = 28 - 5; // 23
    //
    const vpsTag = arrayBuffer[offset];

    if ((vpsTag & 0x3F) !== H265_NAL_TYPE.vps) {
        console.warn(`parseHEVCDecoderVPSAndSPSAndPPS and vpsTag is ${vpsTag}`)
        return {};
    }

    offset += 2;
    offset += 1;
    const vpsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);
    offset += 2;
    const vpsData = arrayBuffer.slice(offset, (offset + vpsLength));
    // console.log('vpsData:', Uint8Array.from(vpsData));
    offset += vpsLength;

    const spsTag = arrayBuffer[offset]
    if ((spsTag & 0x3F) !== H265_NAL_TYPE.sps) {
        console.warn(`parseHEVCDecoderVPSAndSPSAndPPS and sps tag is ${spsTag}`)
        return {};
    }
    offset += 2;
    offset += 1;
    const spsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);

    offset += 2;
    const spsData = arrayBuffer.slice(offset, (offset + spsLength))
    // console.log('spsData:', Uint8Array.from(spsData));

    offset += spsLength;

    const ppsTag = arrayBuffer[offset];

    if ((ppsTag & 0x3F) !== H265_NAL_TYPE.pps) {
        console.warn(`parseHEVCDecoderVPSAndSPSAndPPS and pps tag is ${ppsTag}`)
        return {};
    }
    offset += 2;
    offset += 1;
    const ppsLength = arrayBuffer[offset + 1] | (arrayBuffer[offset] << 8);
    offset += 2;
    const ppsData = arrayBuffer.slice(offset, (offset + ppsLength))
    // console.log('ppsData:', Uint8Array.from(ppsData));
    const spsFlag = new Uint8Array([
        (spsLength >>> 24) & 0xFF,
        (spsLength >>> 16) & 0xFF,
        (spsLength >>> 8) & 0xFF,
        (spsLength) & 0xFF
    ])
    const ppsFlag = new Uint8Array([
        (ppsLength >>> 24) & 0xFF,
        (ppsLength >>> 16) & 0xFF,
        (ppsLength >>> 8) & 0xFF,
        (ppsLength) & 0xFF
    ]);
    const vpsFlag = new Uint8Array([
        (vpsLength >>> 24) & 0xFF,
        (vpsLength >>> 16) & 0xFF,
        (vpsLength >>> 8) & 0xFF,
        (vpsLength) & 0xFF
    ]);
    const sps = new Uint8Array(spsLength + 4);
    sps.set(spsFlag, 0);
    sps.set(spsData, 4);
    const pps = new Uint8Array(ppsLength + 4);
    pps.set(ppsFlag, 0);
    pps.set(ppsData, 4);
    const vps = new Uint8Array(vpsLength + 4);
    vps.set(vpsFlag, 0);
    vps.set(vpsData, 4);
    return {
        sps,
        pps,
        vps
    }
}

export function HEVCParsePtl(bitop, hevc, max_sub_layers_minus1) {
    let general_ptl = {};

    general_ptl.profile_space = bitop.read(2);
    general_ptl.tier_flag = bitop.read(1);
    general_ptl.profile_idc = bitop.read(5);
    general_ptl.profile_compatibility_flags = bitop.read(32);
    general_ptl.general_progressive_source_flag = bitop.read(1);
    general_ptl.general_interlaced_source_flag = bitop.read(1);
    general_ptl.general_non_packed_constraint_flag = bitop.read(1);
    general_ptl.general_frame_only_constraint_flag = bitop.read(1);
    bitop.read(32);
    bitop.read(12);
    general_ptl.level_idc = bitop.read(8);

    general_ptl.sub_layer_profile_present_flag = [];
    general_ptl.sub_layer_level_present_flag = [];

    for (let i = 0; i < max_sub_layers_minus1; i++) {
        general_ptl.sub_layer_profile_present_flag[i] = bitop.read(1);
        general_ptl.sub_layer_level_present_flag[i] = bitop.read(1);
    }

    if (max_sub_layers_minus1 > 0) {
        for (let i = max_sub_layers_minus1; i < 8; i++) {
            bitop.read(2);
        }
    }

    general_ptl.sub_layer_profile_space = [];
    general_ptl.sub_layer_tier_flag = [];
    general_ptl.sub_layer_profile_idc = [];
    general_ptl.sub_layer_profile_compatibility_flag = [];
    general_ptl.sub_layer_progressive_source_flag = [];
    general_ptl.sub_layer_interlaced_source_flag = [];
    general_ptl.sub_layer_non_packed_constraint_flag = [];
    general_ptl.sub_layer_frame_only_constraint_flag = [];
    general_ptl.sub_layer_level_idc = [];

    for (let i = 0; i < max_sub_layers_minus1; i++) {
        if (general_ptl.sub_layer_profile_present_flag[i]) {
            general_ptl.sub_layer_profile_space[i] = bitop.read(2);
            general_ptl.sub_layer_tier_flag[i] = bitop.read(1);
            general_ptl.sub_layer_profile_idc[i] = bitop.read(5);
            general_ptl.sub_layer_profile_compatibility_flag[i] = bitop.read(32);
            general_ptl.sub_layer_progressive_source_flag[i] = bitop.read(1);
            general_ptl.sub_layer_interlaced_source_flag[i] = bitop.read(1);
            general_ptl.sub_layer_non_packed_constraint_flag[i] = bitop.read(1);
            general_ptl.sub_layer_frame_only_constraint_flag[i] = bitop.read(1);
            bitop.read(32);
            bitop.read(12);
        }
        if (general_ptl.sub_layer_level_present_flag[i]) {
            general_ptl.sub_layer_level_idc[i] = bitop.read(8);
        } else {
            general_ptl.sub_layer_level_idc[i] = 1;
        }
    }
    return general_ptl;
}

export function HEVCParseSPS(SPS, hevc) {
    let psps = {};
    let NumBytesInNALunit = SPS.length;
    let NumBytesInRBSP = 0;
    let rbsp_array = [];
    let bitop = new Bitop(SPS);

    bitop.read(1);//forbidden_zero_bit
    bitop.read(6);//nal_unit_type
    bitop.read(6);//nuh_reserved_zero_6bits
    bitop.read(3);//nuh_temporal_id_plus1

    for (let i = 2; i < NumBytesInNALunit; i++) {
        if (i + 2 < NumBytesInNALunit && bitop.look(24) == 0x000003) {
            rbsp_array.push(bitop.read(8));
            rbsp_array.push(bitop.read(8));
            i += 2;
            let emulation_prevention_three_byte = bitop.read(8); /* equal to 0x03 */
        } else {
            rbsp_array.push(bitop.read(8));
        }
    }
    let rbsp = new Uint8Array(rbsp_array);
    let rbspBitop = new Bitop(rbsp);
    psps.sps_video_parameter_set_id = rbspBitop.read(4);
    psps.sps_max_sub_layers_minus1 = rbspBitop.read(3);
    psps.sps_temporal_id_nesting_flag = rbspBitop.read(1);
    psps.profile_tier_level = HEVCParsePtl(rbspBitop, hevc, psps.sps_max_sub_layers_minus1);
    psps.sps_seq_parameter_set_id = rbspBitop.read_golomb();
    psps.chroma_format_idc = rbspBitop.read_golomb();
    if (psps.chroma_format_idc == 3) {
        psps.separate_colour_plane_flag = rbspBitop.read(1);
    } else {
        psps.separate_colour_plane_flag = 0;
    }
    psps.pic_width_in_luma_samples = rbspBitop.read_golomb();
    psps.pic_height_in_luma_samples = rbspBitop.read_golomb();
    psps.conformance_window_flag = rbspBitop.read(1);
    if (psps.conformance_window_flag) {
        let vert_mult = 1 + (psps.chroma_format_idc < 2);
        let horiz_mult = 1 + (psps.chroma_format_idc < 3);
        psps.conf_win_left_offset = rbspBitop.read_golomb() * horiz_mult;
        psps.conf_win_right_offset = rbspBitop.read_golomb() * horiz_mult;
        psps.conf_win_top_offset = rbspBitop.read_golomb() * vert_mult;
        psps.conf_win_bottom_offset = rbspBitop.read_golomb() * vert_mult;
    } else {
        psps.conf_win_left_offset = 0;
        psps.conf_win_right_offset = 0;
        psps.conf_win_top_offset = 0;
        psps.conf_win_bottom_offset = 0;
    }
    // Logger.debug(psps);
    return psps;
}

/**
 *
 * @param vps
 * @param pps
 * @param sps
 * @returns {Uint8Array}
 */
export function hevcEncoderConfigurationRecord({vps, pps, sps}) {
    // 0x1c & 0x0F 等于 12 刚好是 h265 标准。
    // seq header 总共需要 43 + vps.length + sps.length + pps.length
    // 一样的是从 1开始（0x01）
    // unsigned int(8) configurationVersion = 1;
    // 0x1c : 28
    const tmp = [0x1c, 0, 0, 0, 0, 1];

    // unsigned int(8) general_level_idc;
    // tmp[17] = generalLevelIdc

    // bit(4) reserved = ‘1111’b;
    // unsigned int(12) min_spatial_segmentation_idc;
    // bit(6) reserved = ‘111111’b;
    // unsigned int(2) parallelismType;
    // tmp[20] = 0xfc


    // bit(6) reserved = ‘111111’b;
    // unsigned int(2) chromaFormat;
    // tmp[21] = chromaFormat | 0xfc

    // bit(5) reserved = ‘11111’b;
    // unsigned int(3) bitDepthLumaMinus8;
    // tmp[22] = bitDepthLumaMinus8 | 0xf8

    // bit(5) reserved = ‘11111’b;
    // unsigned int(3) bitDepthChromaMinus8;
    // tmp[23] = bitDepthChromaMinus8 | 0xf8

    // bit(2) constantFrameRate;
    // bit(3) numTemporalLayers;
    // bit(1) temporalIdNested;
    // unsigned int(2) lengthSizeMinusOne;
    tmp[26] = (0 << 6) | (1 << 3) | (1 << 2) | 3
    // num of vps sps pps
    tmp[27] = 0x03;

    // 28下标之后
    // vps
    // num of vps
    tmp.push(H265_NAL_TYPE.vps, 0, 1);
    tmp.push((vps.byteLength >> 8) & 0xff, (vps.byteLength) & 0xff);
    // 33下标之后
    tmp.push(...vps);
    // sps
    tmp.push(H265_NAL_TYPE.sps, 0, 1);
    tmp.push((sps.byteLength >> 8) & 0xff, (sps.byteLength) & 0xff);
    tmp.push(...sps);
    // pps
    tmp.push(H265_NAL_TYPE.pps, 0, 1);
    tmp.push((pps.byteLength >> 8) & 0xff, (pps.byteLength) & 0xff);
    tmp.push(...pps);

    const arrayBuffer = new Uint8Array(tmp);

    return arrayBuffer;
}

export function hevcEncoderConfigurationRecord$2({vps, pps, sps}) {

    let detail = {
        configurationVersion: 1,
    }
    const vpsDetail = parseHevcVPS(vps);
    const spsDetail = parseHevcSPS(sps);
    const ppsDetail = parseHevcPPS(pps);
    detail = Object.assign(detail, vpsDetail, spsDetail, ppsDetail);

    let length = 23 + (3 + 2 + vps.byteLength) + (3 + 2 + sps.byteLength) + (3 + 2 + pps.byteLength);
    let data = new Uint8Array(length);

    data[0] = 0x01; // configurationVersion
    data[1] = ((detail.general_profile_space & 0x03) << 6) | ((detail.general_tier_flag ? 1 : 0) << 5) | ((detail.general_profile_idc & 0x1F));
    data[2] = detail.general_profile_compatibility_flags_1 || 0;
    data[3] = detail.general_profile_compatibility_flags_2 || 0;
    data[4] = detail.general_profile_compatibility_flags_3 || 0;
    data[5] = detail.general_profile_compatibility_flags_4 || 0;
    data[6] = detail.general_constraint_indicator_flags_1 || 0;
    data[7] = detail.general_constraint_indicator_flags_2 || 0;
    data[8] = detail.general_constraint_indicator_flags_3 || 0;
    data[9] = detail.general_constraint_indicator_flags_4 || 0;
    data[10] = detail.general_constraint_indicator_flags_5 || 0;
    data[11] = detail.general_constraint_indicator_flags_6 || 0;
    data[12] = 0x3C;
    data[13] = 0xF0 | ((detail.min_spatial_segmentation_idc & 0x0F00) >> 8)
    data[14] = (detail.min_spatial_segmentation_idc & 0xFF);
    data[15] = 0xFC | (detail.parallelismType & 0x03);
    data[16] = 0xFC | (detail.chroma_format_idc & 0x03);
    data[17] = 0xF8 | (detail.bit_depth_luma_minus8 & 0x07);
    data[18] = 0xF8 | (detail.bit_depth_chroma_minus8 & 0x07);
    data[19] = 0;
    data[20] = 0;
    data[21] = ((detail.constant_frame_rate & 0x03) << 6) | ((detail.num_temporal_layers & 0x07) << 3) | ((detail.temporal_id_nested ? 1 : 0) << 2) | 3;
    data[22] = 3;
    data[23 + 0 + 0] = 0x80 | H265_NAL_TYPE.vps;
    data[23 + 0 + 1] = 0;
    data[23 + 0 + 2] = 1;
    data[23 + 0 + 3] = (vps.byteLength & 0xFF00) >> 8;
    data[23 + 0 + 4] = (vps.byteLength & 0x00FF) >> 0;
    data.set(vps, 23 + 0 + 5);
    data[23 + (5 + vps.byteLength) + 0] = 0x80 | H265_NAL_TYPE.sps;
    data[23 + (5 + vps.byteLength) + 1] = 0;
    data[23 + (5 + vps.byteLength) + 2] = 1;
    data[23 + (5 + vps.byteLength) + 3] = (sps.byteLength & 0xFF00) >> 8;
    data[23 + (5 + vps.byteLength) + 4] = (sps.byteLength & 0x00FF) >> 0;
    data.set(sps, 23 + (5 + vps.byteLength) + 5);
    data[23 + (5 + vps.byteLength + 5 + sps.byteLength) + 0] = 0x80 | H265_NAL_TYPE.pps;
    data[23 + (5 + vps.byteLength + 5 + sps.byteLength) + 1] = 0;
    data[23 + (5 + vps.byteLength + 5 + sps.byteLength) + 2] = 1;
    data[23 + (5 + vps.byteLength + 5 + sps.byteLength) + 3] = (pps.byteLength & 0xFF00) >> 8;
    data[23 + (5 + vps.byteLength + 5 + sps.byteLength) + 4] = (pps.byteLength & 0x00FF) >> 0;
    data.set(pps, 23 + (5 + vps.byteLength + 5 + sps.byteLength) + 5);
    const prevData = [0x1c, 0, 0, 0, 0]
    const newData = new Uint8Array(prevData.length + data.byteLength)
    newData.set(prevData, 0)
    newData.set(data, prevData.length)
    return newData;
}

/**
 *
 * @param oneNALBuffer
 * @param isIframe
 * @returns {Uint8Array}
 */
export function hevcEncoderNalePacket(oneNALBuffer, isIframe) {
    //     正常发送nal
    // 这边增加 是否i帧， 然后前面封装了1 + 8 个字节的数据。
    const idrBit = 0x10 | 12
    const nIdrBit = 0x20 | 12
    let tmp = [];
    if (isIframe) {
        tmp[0] = idrBit;
    } else {
        tmp[0] = nIdrBit;
    }
    tmp[1] = 1;
    //
    tmp[2] = 0;
    tmp[3] = 0;
    tmp[4] = 0;
    // 真正开始的地方。。。
    tmp[5] = (oneNALBuffer.byteLength >> 24) & 0xff;
    tmp[6] = (oneNALBuffer.byteLength >> 16) & 0xff;
    tmp[7] = (oneNALBuffer.byteLength >> 8) & 0xff;
    tmp[8] = (oneNALBuffer.byteLength) & 0xff;

    const arrayBuffer = new Uint8Array(tmp.length + oneNALBuffer.byteLength);
    arrayBuffer.set(tmp, 0);
    arrayBuffer.set(oneNALBuffer, tmp.length);
    return arrayBuffer;
}

export function hevcEncoderNalePacketNotLength(oneNALBuffer, isIframe) {
    //     正常发送nal
    // 这边增加 是否i帧， 然后前面封装了1 + 8 个字节的数据。
    const idrBit = 0x10 | 12
    const nIdrBit = 0x20 | 12
    let tmp = [];
    if (isIframe) {
        tmp[0] = idrBit;
    } else {
        tmp[0] = nIdrBit;
    }
    tmp[1] = 1;
    //
    tmp[2] = 0;
    tmp[3] = 0;
    tmp[4] = 0;

    const arrayBuffer = new Uint8Array(tmp.length + oneNALBuffer.byteLength);
    arrayBuffer.set(tmp, 0);
    arrayBuffer.set(oneNALBuffer, tmp.length);
    return arrayBuffer;
}

export function getHevcSeqHeadType(nalu) {
    return (nalu[0] & 0x7E) >> 1
}


export function isHevcSEIType(type) {
    return type === H265_NAL_TYPE.sei
}

// 32-40是VPS SPS PPS SUFFIX_SEI_NUT等
export function isHevcSeqHead(type) {
    return type >= 32 && type <= 40
}

export function isNotHevcSeqHead(type) {
    return !isHevcSeqHead(type)
}

// 16-21是关键(I)帧
export function isHevcNaluIFrame(type) {
    return type >= 16 && type <= 21
}

// 0-9是P帧
export function isHevcNalPFrame(type) {
    return type >= 0 && type <= 9
}


export function isSameHevcNaluType(naluList) {
    if (naluList.length === 0) {
        return false;
    }
    const type = getHevcSeqHeadType(naluList[0]);
    for (let i = 1; i < naluList.length; i++) {
        if (type !== getHevcSeqHeadType(naluList[i])) {
            return false;
        }
    }
    return true;
}
