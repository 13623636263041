import {sm4Decrypt} from "./sm4-crypto";

function decrypt(
    arrayBuffer,
    key,
    iv
) {
    //  start with 2 index，
    for (let i = 2; i < arrayBuffer.length; ++i) {
        const ii = i - 2;
        const a = key[ii % key.length];
        const b = iv[ii % iv.length];
        arrayBuffer[i] = arrayBuffer[i] ^ (a ^ b);
    }
    return arrayBuffer;
}


function getNaluLength(data) {
    let length = data[3] | (data[2] << 8) | (data[1] << 16) | (data[0] << 24)
    return length;
}

export function xorDecrypt(arrayBuffer, key, iv, isHevc = false) {
    const totalLength = arrayBuffer.byteLength;
    // 17(23)/27(39)[是否i帧] ,0/1(), 0,0,0, 0,0,0,0,[NALU],0,0,0,0,[NALU].... 只需要解密NALU部分数据。
    // 其中NALU部分需要跳过两个（nalu头 + 再加一个字节数据）
    let startIndex = 5;

    while (startIndex < totalLength) {
        let tempNaluLength = getNaluLength(arrayBuffer.slice(startIndex, startIndex + 4));
        if (tempNaluLength > totalLength) {
            break;
        }
        //
        let naluType = arrayBuffer[startIndex + 4];

        let needDecrypt = false;

        if (isHevc) {
            naluType = (naluType >>> 1) & 0x3f
            needDecrypt = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 17, 18, 19, 20, 21].includes(naluType);
        } else {
            naluType = naluType & 0x1f;
            needDecrypt = naluType === 1 || naluType === 5;
        }
        //
        if (needDecrypt) {
            const tempNalu = arrayBuffer.slice(startIndex + 4, startIndex + 4 + tempNaluLength);
            //  跳过2个字段
            const decryptMsg = decrypt(tempNalu, key, iv);

            arrayBuffer.set(decryptMsg, startIndex + 4);
        }
        startIndex = startIndex + 4 + tempNaluLength;
    }

    return arrayBuffer;
}
