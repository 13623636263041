import Emitter from "../utils/emitter";
import {AUDIO_ENC_TYPE, EVENTS} from "../constant";
import {clamp, noop} from "../utils";

export default class CommonContextLoader extends Emitter {
    constructor(player) {
        super();
        this.bufferList = [];
        this.player = player;
        this.$audio = null;
        this.scriptNode = null;
        this.workletProcessorNode = null;
        this.hasInitScriptNode = false;

        this.audioContext = new (window.AudioContext || window.webkitAudioContext)({
            sampleRate: 48000
        });

        //
        this.gainNode = this.audioContext.createGain();
        // Get an AudioBufferSourceNode.
        // This is the AudioNode to use when we want to play an AudioBuffer
        const source = this.audioContext.createBufferSource();
        // set the buffer in the AudioBufferSourceNode
        source.buffer = this.audioContext.createBuffer(1, 1, 22050);
        // connect the AudioBufferSourceNode to the
        // destination so we can hear the sound
        source.connect(this.audioContext.destination);
        // noteOn as start
        // start the source playing
        if (source.noteOn) {
            source.noteOn(0);
        } else {
            source.start(0);
        }
        this.audioBufferSourceNode = source;
        //
        this.mediaStreamAudioDestinationNode = this.audioContext.createMediaStreamDestination();
        //
        // default setting 0
        this.gainNode.gain.value = 0;

        this.playing = false;

        this.audioInfo = {
            encType: '',
            channels: '',
            sampleRate: '',
            depth: ''
        }
        this.init = false;
        this.hasAudio = false;
        this.audioResumeStateTimeout = null;
    }

    destroy() {
        this.closeAudio();
        this.resetInit();
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }

        this.gainNode = null;
        this.hasAudio = false;
        this.playing = false;

        if (this.scriptNode) {
            this.scriptNode.onaudioprocess = noop;
            this.scriptNode = null;
        }

        if (this.workletProcessorNode) {
            this.workletProcessorNode.port.onmessage = noop;
            this.workletProcessorNode = null;
        }

        this.clearAudioResumeStateTimeout();

        this.audioBufferSourceNode = null;
        this.mediaStreamAudioDestinationNode = null;
        this.hasInitScriptNode = false;
        this.off();
    }

    resetInit() {
        this.audioInfo = {
            encType: '',
            channels: '',
            sampleRate: '',
            depth: ''
        }
        this.init = false;
    }

    getAudioInfo() {
        return this.audioInfo;
    }

    updateAudioInfo(data) {
        if (data.encTypeCode) {
            this.audioInfo.encTypeCode = data.encTypeCode;
            this.audioInfo.encType = AUDIO_ENC_TYPE[data.encTypeCode];
        }


        if (data.channels) {
            this.audioInfo.channels = data.channels;
        }

        if (data.sampleRate) {
            this.audioInfo.sampleRate = data.sampleRate;
        }
        //
        if (data.depth) {
            this.audioInfo.depth = data.depth;
        }

        // audio 基本信息
        if (this.audioInfo.sampleRate && this.audioInfo.channels && this.audioInfo.encType && !this.init) {
            this.player.emit(EVENTS.audioInfo, this.audioInfo);
            this.init = true;
        }
    }

    //
    get isPlaying() {
        return this.playing;
    }

    get isMute() {
        return this.gainNode.gain.value === 0;
    }

    get volume() {
        return this.gainNode.gain.value;
    }

    get bufferSize() {
        return this.bufferList.length;
    }

    get audioContextState() {
        let result = null;
        if (this.audioContext) {
            result = this.audioContext.state;
        }
        return result;
    }

    initScriptNode() {

    }

    initMobileScriptNode() {

    }

    initWorkletScriptNode() {

    }

    getEngineType() {
        return ''
    }

    mute(flag) {
        if (flag) {
            if (!this.isMute) {
                this.player.emit(EVENTS.mute, flag);
            }
            this.setVolume(0);
            this.clear();
        } else {
            if (this.isMute) {
                this.player.emit(EVENTS.mute, flag);
            }
            this.setVolume(this.player.lastVolume || 0.5);
        }
    }

    setVolume(volume) {
        volume = parseFloat(volume).toFixed(2);
        if (isNaN(volume)) {
            return;
        }
        this.audioEnabled(true);
        volume = clamp(volume, 0, 1);
        this.gainNode.gain.value = volume;
        // this.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
        this.player.emit(EVENTS.volumechange, this.player.volume);
    }

    closeAudio() {
        if (this.hasInitScriptNode) {
            this.scriptNode && this.scriptNode.disconnect(this.gainNode);
            this.workletProcessorNode && this.workletProcessorNode.disconnect(this.gainNode);
            if (this.gainNode) {
                this.gainNode.disconnect(this.mediaStreamAudioDestinationNode);
                if (!this.$audio) {
                    this.gainNode.disconnect(this.audioContext.destination);
                }
            }
        }
        this.clear();
    }

    // 是否播放。。。
    audioEnabled(flag) {
        if (flag) {
            if (this.isStateSuspended()) {
                // resume
                this.audioContext.resume().then(() => {
                    this.player.emit(EVENTS.audioResumeState, {
                        state: this.audioContextState,
                        isRunning: this.isStateRunning(),
                    })
                });
                this.audioResumeStateTimeout = setTimeout(() => {
                    this.clearAudioResumeStateTimeout();
                    if (this.isStateSuspended()) {
                        this.player.emit(EVENTS.audioResumeState, {
                            state: this.audioContextState,
                            isRunning: this.isStateRunning(),
                        })
                    }
                }, 1000)
            }
        } else {
            if (this.isStateRunning()) {
                // suspend
                this.audioContext.suspend();
            }
        }
    }

    isStateRunning() {
        return this.audioContextState === 'running';
    }

    isStateSuspended() {
        return this.audioContextState === 'suspended';
    }

    clearAudioResumeStateTimeout() {
        if (this.audioResumeStateTimeout) {
            clearTimeout(this.audioResumeStateTimeout);
            this.audioResumeStateTimeout = null;
        }
    }


    clear() {
        this.bufferList = [];
    }

    play(buffer, ts) {
        // empty
    }

    pause() {
        this.playing = false;
        // this.clear();
    }

    resume() {
        this.playing = true;
    }

    setRate(value) {
        // empty
    }

    getAudioBufferSize() {
        return 0;
    }
}
