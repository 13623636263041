import Emitter from "../utils/emitter";
import {EVENTS, FILE_SUFFIX, RECORDING_TYPE, VIDEO_ENCODE_TYPE} from "../constant";
import MP4 from "../remux/mp4-generator";
import {now, saveBlobToFile} from "../utils";
import CommonLoader from "./commonLoader";

export default class MP4RecorderLoader extends CommonLoader {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'recorderMP4';
        this._reset();
        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        this._reset();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    _reset() {
        super._reset();
        this.totalDuration = 0;
        this.totalAudioDuration = 0;
        this.totalByteLength = 0;
        this.totalAudioByteLength = 0;
        this.bufferList = [];
        this.audioBufferList = [];
        this.cacheTrack = {};
        this.audioCacheTrack = {};
        this.sequenceNumber = 0;
        this.audioSequenceNumber = 0;
    }


    startRecord() {
        const debug = this.player.debug;
        this._isRecording = true;
        this.player.emit(EVENTS.recording, true);
        debug.log(this.TAG_NAME, 'start recording');
        this.player.emit(EVENTS.recordStart);
        this.startRecordingInterval();
    }

    // override
    startRecordingInterval() {
        this.stopRecordingInterval();
        this.recordingInterval = window.setInterval(() => {
            this.player.emit(EVENTS.recordingTimestamp, this.getTotalDuration());
        }, 1000);
    }

    formatFmp4Track(payload, isIframe, dts, cts) {
        const track = {
            id: 1,
            sequenceNumber: ++this.sequenceNumber,
            size: payload.byteLength,
            dts: dts,
            cts: cts,
            isKeyframe: isIframe,
            data: payload,
            duration: 0,
            flags: {
                isLeading: 0,
                dependsOn: isIframe ? 2 : 1,
                isDependedOn: isIframe ? 1 : 0,
                hasRedundancy: 0,
                isNonSync: isIframe ? 0 : 1
            }
        }
        return track;
    }

    formatAudioFmp4Track(payload, dts) {
        const audioTrack = {
            id: 2,
            sequenceNumber: ++this.audioSequenceNumber,
            size: payload.byteLength,
            dts: dts,
            pts: dts,
            cts: 0,
            data: new Uint8Array(payload),
            duration: 0,
            originalDts: dts,
            flags: {
                isLeading: 0,
                dependsOn: 1,
                isDependedOn: 0,
                hasRedundancy: 0
            }
        }

        return audioTrack;
    }

    handleAddNaluTrack(payload, isIframe, dts, cts) {
        if (this.cacheTrack.id && dts >= this.cacheTrack.dts) {
            //  主要就是为了这个duration 字段
            this.cacheTrack.duration = dts - this.cacheTrack.dts;
            this.handleAddFmp4Track(this.cacheTrack);
        } else {
            this.cacheTrack = {};
        }

        this.cacheTrack = this.formatFmp4Track(payload, isIframe, dts, cts);
    }


    handleAddAudioTrack(payload, dts) {
        // if (this.audioCacheTrack.id && dts >= this.audioCacheTrack.dts) {
        //     this.audioCacheTrack.duration = dts - this.audioCacheTrack.dts;
        //     this.handleAddFmp4AudioTrack(this.audioCacheTrack);
        // } else {
        //     this.audioCacheTrack = {};
        // }
        // this.audioCacheTrack = this.formatAudioFmp4Track(payload, dts);
    }


    //
    handleAddFmp4Track(track) {

        if (!this.isRecording) {
            this.player.debug.error(this.TAG_NAME, 'handleAddFmp4Track, isRecording is false ');
            return;
        }

        if (!(this.sps !== null && this.pps !== null) && this.isH264) {
            this.player.debug.error(this.TAG_NAME, 'handleAddFmp4Track, is h264 and this.sps or this.pps is null ');
            return;
        }
        if (!(this.sps !== null && this.pps !== null && this.vps !== null) && this.isH265) {
            this.player.debug.error(this.TAG_NAME, 'handleAddFmp4Track, is h265 and this.sps or this.pps or this.vps is null ');
            return;
        }
        const trackItem = Object.assign({}, track);
        trackItem.pts = trackItem.dts + trackItem.cts;
        const oldData = trackItem.data;
        if (trackItem.isKeyframe) {
            if (this.isH264) {
                const drFlag = new Uint8Array(this.sps.byteLength + this.pps.byteLength);
                drFlag.set(this.sps, 0);
                drFlag.set(this.pps, this.sps.byteLength);
                const newData = new Uint8Array(drFlag.byteLength + oldData.byteLength)
                newData.set(drFlag, 0);
                newData.set(oldData, drFlag.byteLength);
                trackItem.data = newData;
            } else if (this.isH265) {
                const drFlag = new Uint8Array(this.sps.byteLength + this.pps.byteLength + this.vps.byteLength);
                drFlag.set(this.vps, 0);
                drFlag.set(this.sps, this.vps.byteLength);
                drFlag.set(this.pps, this.vps.byteLength + this.sps.byteLength);
                const newData = new Uint8Array(drFlag.byteLength + oldData.byteLength)
                newData.set(drFlag, 0);
                newData.set(oldData, drFlag.byteLength);
                trackItem.data = newData;
            }
        }
        trackItem.size = trackItem.data.byteLength;
        this.totalDuration += trackItem.duration;
        this.totalByteLength += trackItem.data.byteLength;
        trackItem.duration = 0;
        trackItem.originalDts = trackItem.dts;
        delete trackItem.id;
        delete trackItem.sequenceNumber;
        this.bufferList.push(trackItem);
    }


    handleAddFmp4AudioTrack(track) {
        const trackItem = Object.assign({}, track);
        trackItem.pts = trackItem.dts + trackItem.cts;
        trackItem.size = trackItem.data.byteLength;
        this.totalAudioDuration += trackItem.duration;
        this.totalAudioByteLength += trackItem.data.byteLength;
        trackItem.duration = 0;
        trackItem.originalDts = trackItem.dts;
        delete trackItem.id;
        delete trackItem.sequenceNumber;
        this.audioBufferList.push(trackItem);
    }

    getTotalDuration() {
        return this.totalDuration / 1000;
    }

    getType() {
        return FILE_SUFFIX.mp4
    }

    //
    getToTalByteLength() {
        return this.totalByteLength + this.totalAudioByteLength;
    }

    stopRecordAndSave(type = RECORDING_TYPE.download, fileName) {

        return new Promise((resolve, reject) => {
            if (!this.isRecording) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, isRecording is false ');

                return reject('stop recording fail, isRecording is false ');
            }

            if (this.bufferList.length === 0) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, this.bufferList.length is 0 ');
                return reject('stop recording fail, this.bufferList.length is 0 ');
            }
            if (fileName) {
                this.setFileName(fileName);
            }

            const trakItemVideo = {
                id: 1,
                type: 'video',
                sps: this.sps,
                pps: this.pps,
                samples: this.bufferList,
                sequenceNumber: this.bufferList.length,
                length: 0,
                addSampleNum: 1,
                duration: 0,
                ...this.metaInfo,
            }
            const trakItemAudio = {
                id: 2,
                type: 'audio',
                sequenceNumber: this.audioBufferList.length,
                samples: this.audioBufferList,
                ...this.audioMetaInfo
            }

            const trackList = [trakItemVideo];

            if (trakItemAudio.samples.length > 0) {
                trackList.push(trakItemAudio);
            }
            this.player.debug.log(this.TAG_NAME, `trackList length is ${trackList.length}`)
            // console.error('trackList', trackList, this.totalByteLength, this.totalAudioByteLength, this.totalDuration, this.totalAudioDuration);

            const metaBox = MP4.generateInitSegment({
                timescale: 1000,   // flv 默认为1000
                duration: this.totalDuration,
            }, trackList, this.totalByteLength + this.totalAudioByteLength);
            this.player.debug.log(this.TAG_NAME, 'stop recording');
            const blob = new Blob([metaBox], {'type': 'application/octet-stream'})
            if (type === RECORDING_TYPE.blob) {
                resolve(blob);
                this.player.emit(EVENTS.recordBlob, blob);
            } else {
                resolve();
                const fileName = (this.fileName || now()) + '.' + FILE_SUFFIX.mp4;
                saveBlobToFile(fileName, blob);
            }
            this._reset();
            this.player.emit(EVENTS.recording, false);
        })

    }

}
