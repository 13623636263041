import NetLoader from "../../utils/netLoader";
import {BandwidthService} from "../../utils/bandwidthService";
import {HLS_EVENTS} from '../../constant';
import {StreamingError} from "../error";

export default class SegmentLoader {
    constructor(hls) {
        this.hls = hls;
        this.player = hls.player;
        this._bandwidthService = new BandwidthService()
        const {retryCount, retryDelay, loadTimeout, fetchOptions} = this.hls.config
        this._segmentLoader = new NetLoader({
            ...fetchOptions,
            responseType: 'arraybuffer',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry
        }, this.player)


        this._audioSegmentLoader = new NetLoader({
            ...fetchOptions,
            responseType: 'arraybuffer',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry
        }, this.player)


        this._keyLoader = new NetLoader({
            ...fetchOptions,
            responseType: 'arraybuffer',
            retry: retryCount,
            retryDelay: retryDelay,
            timeout: loadTimeout,
            onRetryError: this._onLoaderRetry
        }, this.player)
    }

    destroy() {
        this.reset();
        if (this._keyLoader) {
            this._keyLoader.destroy();
            this._keyLoader = null;
        }
        if (this._audioSegmentLoader) {
            this._audioSegmentLoader.destroy();
            this._audioSegmentLoader = null;
        }
        if (this._segmentLoader) {
            this._segmentLoader.destroy();
            this._segmentLoader = null;
        }
    }

    speedInfo() {
        return {
            speed: this._bandwidthService.getLatestSpeed(),
            avgSpeed: this._bandwidthService.getAvgSpeed()
        }
    }

    resetBandwidth() {
        this._bandwidthService.reset();
    }

    /**
     * @param {MediaSegment} seg
     * @param {MediaSegment} audioSeg
     * @param {boolean} loadInit
     * @param {boolean} loadAudioInit
     */
    load(seg, audioSeg, loadInit, loadAudioInit = loadInit) {
        const toLoad = []
        if (seg) toLoad[0] = this.loadVideoSegment(seg, loadInit)
        if (audioSeg) toLoad[1] = this.loadAudioSegment(audioSeg, loadAudioInit)
        return Promise.all(toLoad)
    }

    /**
     * @param {MediaSegment} seg
     * @param {boolean} loadInit
     */
    loadVideoSegment(seg, loadInit) {
        return this._loadSegment(this._segmentLoader, seg, loadInit)
    }

    /**
     * @param {MediaSegment} seg
     * @param {boolean} loadInit
     */
    loadAudioSegment(seg, loadInit) {
        return this._loadSegment(this._audioSegmentLoader, seg, loadInit)
    }

    /**
     * @param {NetLoader} segLoader
     * @param {MediaSegment} seg
     * @param {boolean} loadInit
     */
    async _loadSegment(segLoader, seg, loadInit) {
        let map
        let key
        let keyIv
        let mapKey
        let mapKeyIv
        const toLoad = []

        // load start
        // 开始拉流或者后续播放阶段时获取
        // 分片在发送请求之前触发，参数为 { url: string }，url 为请求 url 。
        this.hls.emit(HLS_EVENTS.LOAD_START, {url: seg.url})

        toLoad[0] = segLoader.load(seg.url)

        if (loadInit && seg.initSegment) {

            const mapUrl = seg.initSegment.url

            map = this._mapCache[mapUrl]

            if (!map) {
                // 开始拉流或者后续播放阶段时获取
                // 分片在发送请求之前触发，参数为 { url: string }，url 为请求 url 。

                this.hls.emit(HLS_EVENTS.LOAD_START, {url: mapUrl})

                toLoad[1] = segLoader.load(mapUrl).then(r => {
                    if (r) {
                        const l = Object.keys(this._mapCache)
                        if (l > 30) this._mapCache = {}
                        map = this._mapCache[mapUrl] = r.data
                        this._emitOnLoaded(r, mapUrl)
                    }
                })
            }

            const keyUrl = seg.initSegment.key?.url

            if (keyUrl) {

                mapKeyIv = seg.initSegment.key.iv

                mapKey = this._keyCache[keyUrl]

                if (!mapKey) {
                    // 开始拉流或者后续播放阶段时获取
                    // 分片在发送请求之前触发，参数为 { url: string }，url 为请求 url 。

                    this.hls.emit(HLS_EVENTS.LOAD_START, {url: keyUrl})
                    toLoad[2] = this._keyLoader.load(keyUrl).then(r => {
                        if (r) {
                            mapKey = this._keyCache[keyUrl] = r.data
                            this._emitOnLoaded(r, keyUrl)
                        }
                    })
                }
            }
        }

        const keyUrl = seg.key?.url

        if (keyUrl) {
            keyIv = seg.key.iv
            key = this._keyCache[keyUrl]
            if (!key) {
                // 开始拉流或者后续播放阶段时获取
                // 分片在发送请求之前触发，参数为 { url: string }，url 为请求 url 。

                this.hls.emit(HLS_EVENTS.LOAD_START, {url: keyUrl})
                toLoad[3] = this._keyLoader.load(keyUrl).then(r => {
                    if (r) {
                        key = this._keyCache[keyUrl] = r.data
                        this._emitOnLoaded(r, keyUrl)
                    }
                })
            }
        }

        const [s] = await Promise.all(toLoad)
        if (!s) return
        const data = s.data
        this._emitOnLoaded(s, seg.url)

        return {
            data,
            map,
            key,
            mapKey,
            keyIv,
            mapKeyIv
        }
    }

    reset() {
        this.error = null
        this._mapCache = {}
        this._keyCache = {}
        this._bandwidthService.reset()
    }

    async cancel() {
        await Promise.all([this._keyLoader.cancel(), this._segmentLoader.cancel(), this._audioSegmentLoader.cancel()])
    }

    _emitOnLoaded = (res, url) => {

        const {data, response, option} = res

        const {firstByteTime, startTime, endTime, contentLength} = option || {}

        const time = endTime - startTime

        this._bandwidthService.addRecord(contentLength || data.byteLength, time)

        // emit speed 事件
        // 当收集到网络速度统计时触发，参数如下
        this.hls.emit(HLS_EVENTS.SPEED, {time, byteLength: contentLength, url})
        // 在请求完成后触发，参数为 { url: string }，url 为请求 url 。
        this.hls.emit(HLS_EVENTS.LOAD_COMPLETE, {url, elapsed: time || 0})

        this.hls.emit(HLS_EVENTS.TTFB, {url, responseUrl: response.url, elapsed: firstByteTime - startTime})
        // 接收到请求响应头时触发，参数为 { headers: Headers | Recored<string, string>} 。
        // 如果当前环境支持 fetch 则 headers 为 Response.headers，否则是普通对象。
        this.hls.emit(HLS_EVENTS.LOAD_RESPONSE_HEADERS, {headers: response.headers})

    }


    _onLoaderRetry = (error, retryTime) => {

        this.hls.emit(HLS_EVENTS.LOAD_RETRY, {
            error: StreamingError.network(error),
            retryTime
        })
    }
}
