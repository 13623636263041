import {
    CANVAS_RENDER_TYPE,
    CONTROL_HEIGHT,
    CONTROL_PLAYBACK_HEIGHT,
    EVENTS, EVENTS_ERROR,
    PLAY_TYPE, RENDER_TYPE,
    SCREENSHOT_FORMAT_TYPE,
    SCREENSHOT_TYPE,
    VIDEO_ENC_TYPE
} from "../constant";
import {
    createContextGL, createContextGL2,
    createImageWatermark,
    dataURLToFile,
    downloadImg, isFalse,
    isMobile,
    isString,
    now,
    strokeRectOrTextInCanvas
} from "../utils";
import WebglRender from "../utils/webglRender";
import WebGpuRender from "../utils/webGpuRender";
import CommonLoader from "./commonLoader";
import saveAs from "../utils/file-save";
import WebglRectRender from "../utils/webglRectRender";
import {createWebGPUContext} from "../utils/webgpu";
import Webgl2Render from "../utils/webgl2Render";

export default class CommonCanvasLoader extends CommonLoader {
    constructor(player) {
        super();
        this.player = player;
        const $canvasElement = document.createElement("canvas");
        $canvasElement.style.position = "absolute";
        $canvasElement.style.top = 0;
        $canvasElement.style.left = 0;
        this.$videoElement = $canvasElement;
        player.$container.appendChild(this.$videoElement);

        this.context2D = null;
        this.contextGl = null;
        this.webglRender = null;
        this.webglRectRender = null;
        this.webGPURender = null;
        this.isWebglContextLost = false;
        this.isWcsWebgl2 = false;
        this.bitmaprenderer = null;
        this.renderType = null;
        this.controlHeight = 0;
        this.proxyDestroyList = [];
        //
        this._initCanvasRender();
    }

    destroy() {
        super.destroy();

        if (this.proxyDestroyList.length > 0) {
            this.proxyDestroyList.forEach((itemDestroy) => {
                itemDestroy && itemDestroy()
            })
            this.proxyDestroyList = [];
        }

        if (this.contextGl) {
            this.contextGl = null;
        }

        if (this.context2D) {
            this.context2D = null;
        }

        if (this.webglRender) {
            this.webglRender.destroy();
            this.webglRender = null;
        }

        if (this.webglRectRender) {
            this.webglRectRender.destroy();
            this.webglRectRender = null;
        }

        if (this.webGPURender) {
            this.webGPURender.destroy();
            this.webGPURender = null;
        }

        if (this.bitmaprenderer) {
            this.bitmaprenderer = null;
        }

        this.renderType = null;
        this.isWebglContextLost = false;

        this.videoInfo = {
            width: '',
            height: '',
            encType: '',
        }
        this.player.$container.removeChild(this.$videoElement);
        this.init = false;
        this.off();
    }

    _initContext2D(options = {}) {
        this.context2D = this.$videoElement.getContext('2d', options);
    }

    _initContextGl() {

        const {proxy} = this.player.events;

        this.contextGl = createContextGL(this.$videoElement);
        if (!this.contextGl) {
            this.player.debug.error('CommonCanvasLoader', `_initContextGl() createContextGL error`);
            return;
        }
        this._bindContextGlEvents();
        this.webglRender = new WebglRender(this.contextGl, this.player._opt.openWebglAlignment);
        // this.webglRectRender = new WebglRectRender(this.contextGl, this.player._opt.openWebglAlignment);
    }


    _initContextGl2() {
        this.contextGl = createContextGL2(this.$videoElement);
        if (!this.contextGl) {
            this.player.debug.error('CommonCanvasLoader', `_initContextGl2() createContextGL2 error`);
            return;
        }
        this._bindContextGlEvents(2);
        try {
            this.webglRender = new Webgl2Render(this.$videoElement, this.contextGl);
        } catch (e) {
            this.player.debug.error('CommonCanvasLoader', `create webgl2Render error is ${e} and next use context2d.draw render`);
            this.contextGl = null;
            this.webglRender = null;
            this._initContext2D();
        }
    }

    _bindContextGlEvents(version = 1) {
        const {proxy} = this.player.events;
        // 当WebGL上下文丢失时，WebGLRenderingContext会触发一个名为"webglcontextlost"的lostcontext事件。
        // 这个事件可以用来通知应用程序WebGL上下文已经丢失，并进行一些必要的清理和恢复操作，例如释放GPU资源、重新加载纹理等等。
        const webglContextLostProxy = proxy(this.$videoElement, 'webglcontextlost', (event) => {
            event.preventDefault();
            this.player.debug.error('canvasVideo', `webglcontextlost error`, event);

            this.isWebglContextLost = true;
            // destroy webglRender
            if (this.webglRender) {
                this.player.debug.log('CommonCanvasLoader', `webglcontextlost error and destroy webglRender`);
                this.webglRender.destroy();
                this.webglRender = null;
            }
            if (this.webglRectRender) {
                this.player.debug.log('CommonCanvasLoader', `webglcontextlost error and destroy webglRectRender`);
                this.webglRectRender.destroy();
                this.webglRectRender = null;
            }
            this.contextGl = null;
            setTimeout(() => {
                this.player.debug.log('CommonCanvasLoader', `createContextGL() version ${version}`);
                if (version === 1) {
                    this.contextGl = createContextGL(this.$videoElement);
                } else if (version === 2) {
                    this.contextGl = createContextGL2(this.$videoElement);
                }
                this.player.debug.log('CommonCanvasLoader', `createContextGL success`);
                if (this.contextGl &&
                    this.contextGl.getContextAttributes) {

                    const webglContextAttributes = this.contextGl.getContextAttributes();

                    if (webglContextAttributes && webglContextAttributes.stencil) {
                        if (version === 1) {
                            this.webglRender = new WebglRender(this.contextGl, this.player._opt.openWebglAlignment);
                            // this.webglRectRender = new WebglRectRender(this.contextGl, this.player._opt.openWebglAlignment);
                        } else if (version === 2) {
                            this.webglRender = new Webgl2Render(this.$videoElement, this.contextGl);
                        }

                        this.isWebglContextLost = false;
                        this.player.debug.log('CommonCanvasLoader', `webglcontextlost error reset and getContextAttributes().stencil is true`);
                    } else {
                        this.player.debug.error('CommonCanvasLoader', `webglcontextlost error, getContextAttributes().stencil is false`);
                        //   todo: 处理webglcontextlost error 事件 重新播放
                        this.player.emitError(EVENTS_ERROR.webglContextLostError);
                    }
                } else {
                    this.player.debug.error('CommonCanvasLoader', `webglcontextlost error, getContextAttributes().stencil is false`);
                    //   todo: 处理webglcontextlost error 事件 重新播放
                    this.player.emitError(EVENTS_ERROR.webglContextLostError);
                }

            }, 500)

        })
        // 当WebGL上下文被恢复时，WebGLRenderingContext会触发一个名为"webglcontextrestored"的contextrestored事件。
        const webglContextRestoredProxy = proxy(this.$videoElement, 'webglcontextrestored', (event) => {
            event.preventDefault();
            this.player.debug.log('CommonCanvasLoader', `webglcontextrestored `, event);
        });
        this.proxyDestroyList.push(webglContextLostProxy, webglContextRestoredProxy);
    }


    _initContextGPU() {
        createWebGPUContext(this.$videoElement).then((context) => {
            if (context) {
                this.webGPURender = new WebGpuRender(context);
                this.player.debug.log('CommonCanvasLoader', `webGPURender init success`);
            } else {
                this.player.debug.warn('CommonCanvasLoader', `createWebGPUContext error is ${e} and next use webgl render`);
                this.renderType = CANVAS_RENDER_TYPE.webgl;
                this._initContextGl();
            }
        }).catch((e) => {
            this.player.debug.warn('CommonCanvasLoader', `createWebGPUContext error is ${e} and next use webgl render`);
            this.renderType = CANVAS_RENDER_TYPE.webgl;
            this._initContextGl();
        })
    }

    initCanvasViewSize() {
        this.$videoElement.width = this.videoInfo.width;
        this.$videoElement.height = this.videoInfo.height;
        this.resize();
    }

    screenshot(filename, format, quality, type) {
        filename = filename || now();
        type = type || SCREENSHOT_TYPE.download;
        let encoderOptions = 0.92;
        if (!SCREENSHOT_FORMAT_TYPE[format] && SCREENSHOT_TYPE[format]) {
            type = format;
            format = 'png';
            quality = undefined
        }

        if (typeof quality === "string") {
            type = quality;
            quality = undefined;
        }

        if (typeof quality !== 'undefined') {
            encoderOptions = Number(quality);
        }
        const formatType = SCREENSHOT_FORMAT_TYPE[format] || SCREENSHOT_FORMAT_TYPE.png
        const dataURL = this.$videoElement.toDataURL(formatType, encoderOptions);

        if (type === SCREENSHOT_TYPE.base64) {
            return dataURL;
        } else {
            const file = dataURLToFile(dataURL)
            if (type === SCREENSHOT_TYPE.blob) {
                return file;
            } else if (type === SCREENSHOT_TYPE.download) {
                const suffix = formatType.split('/')[1];
                saveAs(file, filename + '.' + suffix);
            }
        }

    }

    screenshotWatermark(options) {
        return new Promise((resolve, reject) => {
            if (isString(options)) {
                options = {
                    filename: options
                }
            }
            options = options || {}
            options.width = this.videoInfo.width;
            options.height = this.videoInfo.height;
            options.filename = options.filename || now();
            options.format = options.format ? SCREENSHOT_FORMAT_TYPE[options.format] : SCREENSHOT_FORMAT_TYPE.png
            options.quality = Number(options.quality) || 0.92
            options.type = options.type || SCREENSHOT_TYPE.download
            const dataURL = this.$videoElement.toDataURL(options.format, options.quality);
            createImageWatermark(dataURL, options).then((dataURL2) => {
                if (options.type === SCREENSHOT_TYPE.base64) {
                    resolve(dataURL);
                } else {
                    const file = dataURLToFile(dataURL2)
                    if (options.type === SCREENSHOT_TYPE.blob) {
                        resolve(file);
                    } else if (options.type === SCREENSHOT_TYPE.download) {
                        resolve();
                        const suffix = options.format.split('/')[1];
                        saveAs(file, options.filename + '.' + suffix);
                    }
                }
            }).catch((e) => {
                reject(e);
            })

        })
    }

    //
    render() {

    }

    clearView() {
        super.clearView();
    }

    play() {

    }

    pause() {

    }


    _resize() {
        this.player.debug.log('canvasVideo', '_resize()');
        const option = this.player._opt;
        let width = this.player.width;
        let height = this.player.height;
        if (option.hasControl && !option.controlAutoHide) {
            const controlHeight = this.controlHeight;
            if (isMobile() && this.player.fullscreen && option.useWebFullScreen) {
                width -= controlHeight;
            } else {
                height -= controlHeight;
            }
        }
        let resizeWidth = this.$videoElement.width;
        let resizeHeight = this.$videoElement.height;
        const rotate = option.rotate;
        let left = ((width - resizeWidth) / 2)
        let top = ((height - resizeHeight) / 2)
        if (rotate === 270 || rotate === 90) {
            resizeWidth = this.$videoElement.height;
            resizeHeight = this.$videoElement.width;
        }

        const wScale = width / resizeWidth;
        const hScale = height / resizeHeight;

        let scale = wScale > hScale ? hScale : wScale;
        //
        if (isFalse(option.isResize)) {
            if (wScale !== hScale) {
                scale = wScale + ',' + hScale;
            }
        }
        //
        if (option.isFullResize) {
            scale = wScale > hScale ? wScale : hScale;
        }
        let transform = "scale(" + scale + ")";

        if (option.mirrorRotate === 'none') {
            if (rotate) {
                transform += ' rotate(' + rotate + 'deg)'
            }
        }

        if (option.mirrorRotate === 'level') {
            transform += ' rotateY(180deg)' // 水平镜像翻转
        } else if (option.mirrorRotate === 'vertical') {
            transform += ' rotateX(180deg)' // 垂直镜像翻转
        }

        this.$videoElement.style.height = this.videoInfo.height + "px";
        this.$videoElement.style.width = this.videoInfo.width + "px";
        this.$videoElement.style.padding = "0";
        this.$videoElement.style.transform = transform;
        this.$videoElement.style.left = left + "px"
        this.$videoElement.style.top = top + "px"
    }


    initFps() {

    }

    setStreamFps(fps) {

    }

    getStreamFps() {
        return 25;
    }

    getType() {
        return RENDER_TYPE.canvas
    }

    getCanvasType() {
        let result = this.renderType === CANVAS_RENDER_TYPE.webgpu ? CANVAS_RENDER_TYPE.webgpu : CANVAS_RENDER_TYPE.webgl;

        if (this.isWcsWebgl2) {
            result = CANVAS_RENDER_TYPE.webgl2;
        }

        return result;
    }
}
