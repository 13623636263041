export default class Processor {
    constructor(player, audio, channel, bufferSize) {
        this.player = player;
        this.audio = audio;
        this.channel = channel;
        this.bufferSize = bufferSize;
    }

    //
    extract(target, numFrames) {
        let data = this.provide(numFrames)
        for (let i = 0; i < data.size; i++) {
            target[i * 2] = data.left[i];
            target[i * 2 + 1] = data.right[i];
        }
        this.audio.tempAudioTimestamp = data.ts;
        return data.size
    }


    provide(sourceSize) {
        let left = new Float32Array(sourceSize);
        let right = new Float32Array(sourceSize);
        let size = 0;
        let ts = 0;
        let sourcePosition = 0;

        let num = sourceSize / this.bufferSize;
        const bufferList = this.audio.bufferList;

        if (num && bufferList.length >= num) {
            try {
                for (let i = 0; i < num; i++) {
                    const bufferItem = bufferList.shift();
                    if (this.channel === 2) {
                        left.set(bufferItem.buffer[0], sourcePosition);
                        right.set(bufferItem.buffer[1], sourcePosition);
                    } else {
                        left.set(bufferItem.buffer[0], sourcePosition);
                        right.set(bufferItem.buffer[0], sourcePosition);
                    }
                    sourcePosition += this.bufferSize;
                    ts = bufferItem.ts;
                }
            } catch (e) {
                this.player.debug.warn('Processor', 'provide()', e);
                left = new Float32Array(0);
                right = new Float32Array(0);
            }

            size = left.length;
        }

        return {
            size,
            ts,
            left,
            right
        }
    }

    destroy() {
        this.buffer = null;
        this.channel = null;
    }
}
