import Emitter from "../utils/emitter";
import {
    AUDIO_ENC_CODE,
    AUDIO_ENC_TYPE,
    DEMUX_LOOP_INTERVAL_TIMES,
    DOCUMENT_ONE_SECOND_BUFFER_LENGTH,
    EVENTS, FRAME_HEADER_EX, FRAME_TS_MAX_DIFF, FRAME_TYPE_EX, H264_NAL_TYPE, H265_NAL_TYPE,
    MEDIA_TYPE, PACKET_TYPE_EX,
    PLAY_TYPE, PLAYER_STREAM_TYPE, VIDEO_ENC_CODE, VIDEO_ENC_TYPE_SHOW, WORKER_CMD_TYPE
} from "../constant";
import {calcStreamFpsByBufferList, isEmpty, isFalse, isNotEmpty, isTrue, isVideoSequenceHeader, now} from "../utils";
import {isAAC, isAacCodecPacket} from "../utils/aac";
import {getUnitSizeFromVideoSequenceHeader, parseAnnexB, parseAvcC} from "../utils/nalu";
import {hevcEncoderNalePacketNotLength} from "../utils/h265";
import {aes256ctrDecrypt, aes256ctrDecryptAacAudio} from "../utils/crypto";
import {sm4Decrypt} from "../utils/sm4-crypto";
import {xorDecrypt} from "../utils/xor-crypto";

export default class CommonLoader extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'CommonDemux';
        this.player = player;

        this.stopId = null;
        this.firstTimestamp = null;
        this.startTimestamp = null;
        this.preDelayTimestamp = null;
        this.preLoopTimestamp = null;
        this.bufferStartDts = null;
        this.bufferStartLocalTs = null;
        this.preIframeTs = null;
        this.preFrameTs = null;
        this.preTimestamp = null;
        this.preTimestampDuration = 0;
        this.prevPayloadBufferSize = 0;
        this.isStreamTsMoreThanLocal = false;
        this.delay = -1;
        this.pushLatestDelay = -1;
        this.bufferList = [];
        this.historyIntervalDiffTimeList = [];
        this.playbackStreamFps = null;
        this.playbackStreamAudioFps = null;
        this.playbackStreamVideoFps = null;
        this.dropping = false;
        this.isPushDropping = false;
        this.nalUnitSize = null;
        this.initInterval();
        this.player.debug.log('CommonDemux', 'init');
    }

    destroy() {
        this.bufferList = [];
        this.historyIntervalDiffTimeList = [];
        this.playbackStreamFps = null;
        this.playbackStreamAudioFps = null;
        this.playbackStreamVideoFps = null;
        this.clearStopInterval();
        this.firstTimestamp = null;
        this.startTimestamp = null;
        this.bufferStartDts = null;
        this.bufferStartLocalTs = null;
        this.preDelayTimestamp = null;
        this.preLoopTimestamp = null;
        this.preIframeTs = null;
        this.preTimestamp = null;
        this.preTimestampDuration = 0;
        this.prevPayloadBufferSize = 0;
        this.isStreamTsMoreThanLocal = false;
        this.delay = -1;
        this.pushLatestDelay = -1;
        this.dropping = false;
        this.isPushDropping = false;
        this.nalUnitSize = null;
        this.off();
        this.player.debug.log('CommonDemux', 'destroy');
    }

    isDropping() {
        return this.dropping || this.isPushDropping;
    }

    getDelay(timestamp, type) {
        if (!timestamp || !this.player.isDemuxDecodeFirstIIframeInit()) {
            return -1
        }

        if (type === MEDIA_TYPE.audio) {
            return this.delay
        }

        //
        if (this.preDelayTimestamp && this.preDelayTimestamp > timestamp) {
            if (this.preDelayTimestamp - timestamp > 1000) {
                this.player.debug.warn('CommonDemux', `getDelay() and preDelayTimestamp is ${this.preDelayTimestamp} > timestamp is ${timestamp} more than ${this.preDelayTimestamp - timestamp}ms and return ${this.delay}`);
            }
            this.preDelayTimestamp = timestamp;
            return this.delay;
        }

        if (!this.firstTimestamp) {
            this.firstTimestamp = timestamp
            this.startTimestamp = Date.now()
            this.delay = -1;
        } else {
            if (timestamp) {
                const localTimestamp = (Date.now() - this.startTimestamp);
                const timeTimestamp = (timestamp - this.firstTimestamp);
                // console.error(`localTimestamp is ${localTimestamp}, timeTimestamp is ${timeTimestamp}`)
                if (localTimestamp >= timeTimestamp) {
                    this.isStreamTsMoreThanLocal = false;
                    this.delay = localTimestamp - timeTimestamp;
                } else {
                    // maybe some stream ts more large than local ts
                    this.isStreamTsMoreThanLocal = true;
                    this.delay = timeTimestamp - localTimestamp;
                }
            }
        }
        this.preDelayTimestamp = timestamp;
        return this.delay
    }

    getDelayNotUpdateDelay(timestamp, type) {
        if (!timestamp || !this.player.isDemuxDecodeFirstIIframeInit()) {
            return -1
        }

        if (type === MEDIA_TYPE.audio) {
            return this.pushLatestDelay;
        }

        if (this.preDelayTimestamp && ((this.preDelayTimestamp - timestamp) > 1000)) {
            this.player.debug.warn('CommonDemux', `getDelayNotUpdateDelay() and preDelayTimestamp is ${this.preDelayTimestamp} > timestamp is ${timestamp} more than ${this.preDelayTimestamp - timestamp}ms and return -1`);
            return -1;
        }

        if (!this.firstTimestamp) {
            return -1
        } else {
            let delay = -1;

            if (timestamp) {
                const localTimestamp = (Date.now() - this.startTimestamp);
                const timeTimestamp = (timestamp - this.firstTimestamp);
                if (localTimestamp >= timeTimestamp) {
                    delay = localTimestamp - timeTimestamp;
                } else {
                    // maybe some stream ts more large than local ts
                    delay = timeTimestamp - localTimestamp;
                }
            }
            return delay;
        }
    }

    resetDelay() {
        this.firstTimestamp = null;
        this.startTimestamp = null;
        this.delay = -1;
        this.dropping = false;
    }

    resetAllDelay() {
        this.resetDelay();
        this.preDelayTimestamp = null;
    }

    //
    initInterval() {
        if (this.player.isUseHls265()) {
            this.player.debug.log('CommonDemux', `initInterval() and is hls and support hls265 so return`);
            return
        }

        if (this.player.getStreamType().indexOf(PLAYER_STREAM_TYPE.worker) !== -1) {
            this.player.debug.log('CommonDemux', `initInterval() and is worker stream so return`);
            return;
        }

        if (this.player.isPlaybackCacheBeforeDecodeForFpsRender()) {
            this.player.debug.log('CommonDemux', `initInterval() and playback and playbackIsCacheBeforeDecodeForFpsRender is true so return`);
            return;
        }

        this.player.debug.log('CommonDemux', `setInterval()`);
        this._loop();
        this.stopId = setInterval(() => {
            let nowTime = new Date().getTime()
            if (!this.preLoopTimestamp) {
                this.preLoopTimestamp = nowTime;
            }
            const diffTime = nowTime - this.preLoopTimestamp;
            this.updateHistoryIntervalDiffTimeList(diffTime);
            if (diffTime > 100) {
                this.player.debug.warn('CommonDemux', `loop demux diff time is ${diffTime}`);
            }
            this._loop();
            this.preLoopTimestamp = new Date().getTime();
        }, DEMUX_LOOP_INTERVAL_TIMES);
    }


    clearStopInterval() {
        if (this.stopId) {
            clearInterval(this.stopId);
            this.stopId = null;
        }
    }

    updateHistoryIntervalDiffTimeList(diffTime) {
        if (this.historyIntervalDiffTimeList.length > 5) {
            this.historyIntervalDiffTimeList.shift();
        }
        this.historyIntervalDiffTimeList.push(diffTime);

    }

    isHistoryIntervalDiffTimeAllLarge() {
        if (this.historyIntervalDiffTimeList.length < 5) {
            return false;
        }

        for (let i = 0; i < this.historyIntervalDiffTimeList.length; i++) {
            //  less than 900ms
            if (this.historyIntervalDiffTimeList[i] < 900) {
                return false;
            }
        }
        return true;
    }


    //
    initPlaybackCacheLoop() {
        this.clearStopInterval();

        const loop = () => {
            let data = null;
            if (this.bufferList.length) {
                data = this.bufferList.shift();
                this._doDecoderDecode(data);
            }
        }

        loop();

        const fragDuration = Math.ceil(1000 / (this.playbackStreamFps * this.player.getPlaybackRate()));
        this.player.debug.log('CommonDemux', `initPlaybackCacheLoop() and fragDuration is ${fragDuration}, playbackStreamFps is ${this.playbackStreamFps}, playbackRate is ${this.player.getPlaybackRate()}`);
        this.stopId = setInterval(loop, fragDuration);
    }

    _loop() {
        let data;
        const videoBuffer = this.player._opt.videoBuffer;
        const videoBufferDelay = this.player._opt.videoBufferDelay;
        const isPlayer = this.player._opt.playType === PLAY_TYPE.player;
        if (this.bufferList.length) {
            if (this.isPushDropping) {
                this.player.debug.warn('CommonDemux', `_loop isPushDropping is true and bufferList length is ${this.bufferList.length}`);
                return;
            }

            if (this.dropping) {
                data = this.bufferList.shift();
                this.player.debug.warn('CommonDemux', `_loop is dropping and data.ts is ${data.ts}, data.type is ${data.type}, data.isIFrame is ${data.isIFrame}, delay is ${this.delay} ,buffer list is ${this.bufferList.length}`);

                while (!data.isIFrame && this.bufferList.length) {
                    data = this.bufferList.shift();
                }
                const tempDelay = this.getDelayNotUpdateDelay(data.ts, data.type);
                // i frame
                // min delay
                if (data.isIFrame && tempDelay <= this.getNotDroppingDelayTs()) {
                    this.player.debug.log('CommonDemux', `_loop data isIFrame is true and delay is ${this.delay}`);
                    this.dropping = false;
                    this._doDecoderDecode(data);
                    this._decodeNext(data);
                }
            } else {
                if (this.player.isPlayback() ||
                    this.player.isPlayUseMSE() ||
                    videoBuffer === 0) {
                    while (this.bufferList.length) {
                        data = this.bufferList.shift()
                        this._doDecoderDecode(data);
                    }
                } else {
                    data = this.bufferList[0];
                    if (this.getDelay(data.ts, data.type) === -1) {
                        this.player.debug.log('CommonDemux', `delay is -1 and data.ts is ${data.ts} data.type is ${data.type}`);
                        this.bufferList.shift()
                        this._doDecoderDecode(data);
                        this._decodeNext(data);
                    } else if (this.delay > (videoBufferDelay + videoBuffer) && isPlayer) {
                        if (this.hasIframeInBufferList()) {
                            this.player.debug.warn('CommonDemux', `_loop delay is ${this.delay}, set dropping is true`);
                            this.resetAllDelay();
                            this.dropping = true;
                            this.player.updateStats({isDropping: true});
                        } else {
                            this.bufferList.shift()
                            this._doDecoderDecode(data);
                            this._decodeNext(data);
                        }
                    } else {
                        while (this.bufferList.length) {
                            data = this.bufferList[0];
                            if (this.getDelay(data.ts, data.type) > videoBuffer) {
                                this.bufferList.shift()
                                this._doDecoderDecode(data);
                            } else {
                                if (this.delay < 0) {
                                    this.player.debug.warn('CommonDemux', `_loop delay is ${this.delay} bufferList is ${this.bufferList}`);
                                } else {
                                    // this.player.debug.log('CommonDemux', `delay is ${this.delay}`);
                                }
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            if (this.delay !== -1) {
                this.player.debug.log('CommonDemux', `loop() bufferList is empty and reset delay`);
            }
            this.resetAllDelay();
        }
    }

    //
    _doDecode(payload, type, ts, isIFrame, cts = 0) {
        const player = this.player;

        let options = {
            ts: ts,
            cts: cts,
            type: type,
            isIFrame: false
        }

        if (this.player.isPlayer()) {
            if (type === MEDIA_TYPE.video) {
                if (player._opt.playType === PLAY_TYPE.player) {
                    this.calcNetworkDelay(ts);
                }
            }

            // use offscreen
            if (player._opt.useWCS && !player._opt.useOffscreen) {
                if (type === MEDIA_TYPE.video) {
                    options.isIFrame = isIFrame;
                }
                this.pushBuffer(payload, options)
            } else if (player._opt.useMSE) {
                // use mse
                if (type === MEDIA_TYPE.video) {
                    options.isIFrame = isIFrame;
                }
                this.pushBuffer(payload, options)
            } else {
                // wasm
                if (type === MEDIA_TYPE.video) {
                    player.decoderWorker && player.decoderWorker.decodeVideo(payload, ts, isIFrame);
                } else if (type === MEDIA_TYPE.audio) {
                    if (player._opt.hasAudio) {
                        player.decoderWorker && player.decoderWorker.decodeAudio(payload, ts);
                    }
                }
            }
        } else if (this.player.isPlayback()) {
            if (type === MEDIA_TYPE.video) {
                options.isIFrame = isIFrame;
            }

            if (this.player.isPlaybackOnlyDecodeIFrame()) {
                //  only decode video not decode audio
                if (type === MEDIA_TYPE.video && isIFrame) {
                    this.pushBuffer(payload, options)
                }
            } else {
                // playback
                if (this.player.isPlaybackCacheBeforeDecodeForFpsRender()) {
                    this.pushBuffer(payload, options)
                } else {
                    if (this.player.getPlaybackRate() === 1) {
                        this.pushBuffer(payload, options)
                    } else {
                        this.pushBuffer(payload, options, false)
                    }
                }
            }
        }
    }

    //  for hls
    _doDecodeByHls(payload, type, ts, isIFrame, cts = 0) {

        let _isAudioAacCodecPacket = false;
        // decode aac header
        if (type === MEDIA_TYPE.audio &&
            isAacCodecPacket(payload)) {
            this.player.debug.log('CommonDemux', `hls pushBuffer audio ts is ${ts}, isAacCodecPacket is true`);
            _isAudioAacCodecPacket = true;
            //  flv recorder
            if (this.player.isRecordTypeFlv()) {
                const payloadCopy = new Uint8Array(payload);
                this.player.recorder.addAACSequenceHeader(payloadCopy, ts);
            }
        }

        let _isVideoSequenceHeader = false;
        // init video sequence header
        if (type === MEDIA_TYPE.video &&
            isIFrame &&
            isVideoSequenceHeader(payload)) {
            this.player.debug.log('CommonDemux', `hls pushBuffer video ts is ${ts}, isVideoSequenceHeader is true`);
            _isVideoSequenceHeader = true;
            //  flv recorder
            if (this.player.isRecordTypeFlv()) {
                const payloadCopy = new Uint8Array(payload);
                this.player.recorder.addVideoSequenceHeader(payloadCopy, ts);
            }
        }

        //  recording
        if (this.player.recording &&
            isFalse(_isVideoSequenceHeader) &&
            isFalse(_isAudioAacCodecPacket)) {
            this.handleRecording(payload, type, ts, isIFrame, cts);
        }


        if (type === MEDIA_TYPE.video) {
            this._doDecoderDecode({
                ts: ts,
                cts: cts,
                payload: payload,
                type: MEDIA_TYPE.video,
                isIFrame: isIFrame
            })
        } else if (type === MEDIA_TYPE.audio) {
            this._doDecoderDecode({
                ts: ts,
                payload: payload,
                type: MEDIA_TYPE.audio,
            })
        }
    }

    //  for fmp4
    _doDecodeByFmp4(payload, type, ts, isIFrame, cts = 0) {
        this._doDecode(payload, type, ts, isIFrame, cts);
    }

    _decodeNext(data) {
        const ts = data.ts;
        if (this.bufferList.length === 0) {
            return;
        }
        let nextData = this.bufferList[0];
        const diff = nextData.ts - ts;
        const isVideoAndNextAudio = data.type === MEDIA_TYPE.video && nextData.type === MEDIA_TYPE.audio;
        const isSqeHeader = data.type === MEDIA_TYPE.video && isVideoSequenceHeader(data.payload);
        //  如果
        if (diff <= DEMUX_LOOP_INTERVAL_TIMES ||
            isVideoAndNextAudio ||
            isSqeHeader) {
            this.player.debug.log('CommonDemux', `decode data type is ${data.type} and
            ts is ${ts} next data type is ${nextData.type} ts is ${nextData.ts}
            diff is ${diff} and isVideoAndNextAudio is ${isVideoAndNextAudio} and isVideoSqeHeader is ${isSqeHeader}`);

            this.bufferList.shift()
            this._doDecoderDecode(nextData);
        }
    }

    _doDecoderDecode(data) {
        const player = this.player;

        const {webcodecsDecoder, mseDecoder} = player;
        // player.debug.log('CommonDemux', `_doDecoderDecode data.type is ${data.type} data.ts is ${data.ts}`);

        if (this.player.isPlayer()) {
            this.player.updateStats({
                buf: this.delay
            })
        }

        if (data.type === MEDIA_TYPE.audio) {
            if (player._opt.hasAudio) {
                if (player._opt.useMSE &&
                    player._opt.mseDecodeAudio) {
                    mseDecoder.decodeAudio(data.payload, data.ts);
                } else {
                    player.decoderWorker && player.decoderWorker.decodeAudio(data.payload, data.ts)
                }
            }
        } else if (data.type === MEDIA_TYPE.video) {

            if (player._opt.isEmitSEI) {
                this.findSei(data.payload, data.ts);
            }

            if (player._opt.useWCS && !player._opt.useOffscreen) {
                webcodecsDecoder.decodeVideo(data.payload, data.ts, data.isIFrame, data.cts);
            } else if (player._opt.useMSE) {
                mseDecoder.decodeVideo(data.payload, data.ts, data.isIFrame, data.cts);
            } else {
                player.decoderWorker && player.decoderWorker.decodeVideo(data.payload, data.ts, data.isIFrame);
            }
        }
    }

    pushBuffer(payload, options, isPushBuffer = true) {
        // this.player.debug.error('CommonDemux', `
        // payload ts is ${options.ts} and
        // type is ${options.type} and isIFrame is ${options.isIFrame}`);

        const videoBuffer = this.player._opt.videoBuffer;
        const videoBufferDelay = this.player._opt.videoBufferDelay;
        const isPlayer = this.player.isPlayer();

        // decode aac header
        if (options.type === MEDIA_TYPE.audio &&
            isAacCodecPacket(payload)) {
            this.player.debug.log('CommonDemux', `pushBuffer() audio ts is ${options.ts}, isAacCodecPacket is true`);

            //  flv recorder
            if (this.player.isRecordTypeFlv()) {
                const payloadCopy = new Uint8Array(payload);
                this.player.recorder.addAACSequenceHeader(payloadCopy, options.ts);
            }

            this._doDecoderDecode({
                ts: options.ts,
                payload: payload,
                type: MEDIA_TYPE.audio,
            })
            return;
        }

        // init video sequence header
        if (options.type === MEDIA_TYPE.video &&
            options.isIFrame &&
            isVideoSequenceHeader(payload)) {
            this.player.debug.log('CommonDemux', `pushBuffer() video ts is ${options.ts}, isVideoSequenceHeader is true`);
            //  flv recorder
            if (this.player.isRecordTypeFlv()) {
                const payloadCopy = new Uint8Array(payload);
                this.player.recorder.addVideoSequenceHeader(payloadCopy, options.ts);
            }
            this._doDecoderDecode({
                ts: options.ts,
                payload: payload,
                type: MEDIA_TYPE.video,
                isIFrame: options.isIFrame,
                cts: options.cts
            })
            return;
        }

        //  recording
        if (this.player.recording) {
            this.handleRecording(payload, options.type, options.ts, options.isIFrame, options.cts);
        }

        if (isPlayer) {
            //  for player
            if (this.preTimestampDuration > 0 &&
                this.preTimestamp > 0 &&
                options.type === MEDIA_TYPE.video) {
                const diff = options.ts - this.preTimestamp;
                const maxDiff = (this.preTimestampDuration + this.preTimestampDuration / 2);
                if (diff >= maxDiff) {
                    this.player.debug.log('CommonDemux', `pushBuffer() video
                ts is ${options.ts}, preTimestamp is ${this.preTimestamp},
                diff is ${diff} and preTimestampDuration is ${this.preTimestampDuration} and maxDiff is ${maxDiff}
                maybe trigger black screen or flower screen`);
                }
            }

            //  for player
            if (this.preTimestamp > 0
                && options.ts < this.preTimestamp
                && options.type === MEDIA_TYPE.video
                && this.preTimestamp - options.ts > FRAME_TS_MAX_DIFF) {
                this.player.debug.warn('CommonDemux', `pushBuffer() video
            ts is ${options.ts}, preTimestamp is ${this.preTimestamp},
            diff is ${this.preTimestamp - options.ts} more than ${FRAME_TS_MAX_DIFF}
            and resetAllDelay()`);
                this.resetAllDelay();
            }

            //  for player
            if (options.ts <= this.preTimestamp &&
                this.preTimestamp > 0 &&
                options.type === MEDIA_TYPE.video) {
                this.player.debug.warn('CommonDemux', `pushBuffer() video
            ts is ${options.ts} less than (or equal) preTimestamp is ${this.preTimestamp} and
            payloadBufferSize is ${payload.byteLength} and prevPayloadBufferSize is ${this.prevPayloadBufferSize}`);

                if (this.player._opt.isDropSameTimestampGop &&
                    this.player.isDemuxDecodeFirstIIframeInit()) {
                    const hasIframe = this.hasIframeInBufferList();
                    const isNotPushDropping = isFalse(this.isPushDropping)
                    this.player.debug.log('CommonDemux', `pushBuffer(), isDropSameTimestampGop is true and
                    hasIframe is ${hasIframe} and isNotPushDropping is ${isNotPushDropping} and next drop buffer`)
                    if (hasIframe && isNotPushDropping) {
                        this.dropBuffer$2();
                    } else {
                        this.clearBuffer(true);
                    }
                    return;
                }
            }

            //  for player
            if (this.player.isDemuxDecodeFirstIIframeInit()) {
                let pushLatestDelay = this.getDelayNotUpdateDelay(options.ts, options.type);
                this.pushLatestDelay = pushLatestDelay;
                const maxDelay = videoBufferDelay + videoBuffer;
                if (this.player._opt.useMSE) {
                    if (pushLatestDelay > maxDelay && this.delay < maxDelay && this.delay > 0) {
                        if (this.hasIframeInBufferList() &&
                            this.isPushDropping === false) {
                            this.player.debug.warn('CommonDemux', `useMSE, pushLatestDelay is ${pushLatestDelay} > ${(videoBufferDelay + videoBuffer)}, bufferList is ${this.bufferList.length}, delay is ${this.delay} and dropBuffer$2()`);
                            this.dropBuffer$2();
                        }
                    }
                } else {
                    if (pushLatestDelay > maxDelay && this.delay < maxDelay && this.delay > 0) {
                        if (this.hasIframeInBufferList() &&
                            this.isPushDropping === false) {
                            this.player.debug.warn('CommonDemux', `useWCS, pushLatestDelay is ${pushLatestDelay} > ${(videoBufferDelay + videoBuffer)},bufferList is ${this.bufferList.length}, delay is ${this.delay} and dropBuffer$2()`);
                            this.dropBuffer$2();
                        }
                    }
                }

                //  for player
                if (this.isHistoryIntervalDiffTimeAllLarge() &&
                    isFalse(this.player.visibility)) {

                    if (this.player._opt.useMSE) {
                        if (this.hasIframeInBufferList() &&
                            this.isPushDropping === false) {
                            this.player.debug.warn('CommonDemux', `useMSE, page visibility is false and
                                history interval diff is ${this.historyIntervalDiffTimeList.join(',')}  and
                                bufferList is ${this.bufferList.length},
                                delay is ${this.delay} and dropBuffer$2()`);
                            this.dropBuffer$2();
                        }
                    } else {
                        if (this.hasIframeInBufferList() &&
                            this.isPushDropping === false) {
                            this.player.debug.warn('CommonDemux', `useWCS, page visibility is false and
                                history interval diff is ${this.historyIntervalDiffTimeList.join(',')}  and
                                bufferList is ${this.bufferList.length},
                                delay is ${this.delay} and dropBuffer$2()`);
                            this.dropBuffer$2();
                        }
                    }
                }
            }

            if (options.type === MEDIA_TYPE.video) {
                if (this.preTimestamp > 0) {
                    this.preTimestampDuration = options.ts - this.preTimestamp;
                }
                this.prevPayloadBufferSize = payload.byteLength;
                this.preTimestamp = options.ts;
            }
        }

        if (isPushBuffer) {
            // 音频
            if (options.type === MEDIA_TYPE.audio) {
                // this.player.debug.warn('CommonDemux', `pushBuffer audio ts is ${options.ts}`);
                this.bufferList.push({
                    ts: options.ts,
                    payload: payload,
                    type: MEDIA_TYPE.audio,
                })
            } else if (options.type === MEDIA_TYPE.video) {
                // this.player.debug.error('CommonDemux', `pushBuffer video ts is ${options.ts}`);
                this.bufferList.push({
                    ts: options.ts,
                    cts: options.cts,
                    payload: payload,
                    type: MEDIA_TYPE.video,
                    isIFrame: options.isIFrame
                })
            }
        } else {
            if (options.type === MEDIA_TYPE.video) {
                this._doDecoderDecode({
                    ts: options.ts,
                    cts: options.cts,
                    payload: payload,
                    type: MEDIA_TYPE.video,
                    isIFrame: options.isIFrame
                });
            } else if (options.type === MEDIA_TYPE.audio) {
                this._doDecoderDecode({
                    ts: options.ts,
                    payload: payload,
                    type: MEDIA_TYPE.audio,
                })
            }
        }

        //  for playback
        if (this.player.isPlaybackCacheBeforeDecodeForFpsRender()) {
            if (isEmpty(this.playbackStreamVideoFps) || isEmpty(this.playbackStreamAudioFps)) {
                let playbackStreamVideoFps = this.playbackStreamVideoFps;
                let playbackStreamAudioFps = this.playbackStreamAudioFps;

                if (isEmpty(this.playbackStreamVideoFps)) {
                    playbackStreamVideoFps = calcStreamFpsByBufferList(this.bufferList, MEDIA_TYPE.video);

                    if (playbackStreamVideoFps > 0) {
                        this.playbackStreamVideoFps = playbackStreamVideoFps;

                        if (this.player.video) {
                            this.player.video.setStreamFps(this.playbackStreamVideoFps);
                        }

                        if (playbackStreamAudioFps) {
                            this.playbackStreamFps = playbackStreamVideoFps + playbackStreamAudioFps;
                        } else {
                            this.playbackStreamFps = playbackStreamVideoFps;
                        }

                        if (isFalse(this.player._opt.hasAudio)) {
                            this.player.debug.log(this.TAG_NAME, 'playbackCacheBeforeDecodeForFpsRender,_opt.hasAudio is false and set streamAudioFps is 0')
                            this.playbackStreamAudioFps = 0;
                        }

                        this.initPlaybackCacheLoop()
                    }
                }

                if (isEmpty(this.playbackStreamAudioFps)) {
                    playbackStreamAudioFps = calcStreamFpsByBufferList(this.bufferList, MEDIA_TYPE.audio);

                    if (playbackStreamAudioFps > 0) {
                        this.playbackStreamAudioFps = playbackStreamAudioFps;

                        if (playbackStreamVideoFps) {
                            this.playbackStreamFps = playbackStreamVideoFps + playbackStreamAudioFps;
                        } else {
                            this.playbackStreamFps = playbackStreamAudioFps;
                        }
                        this.initPlaybackCacheLoop()
                    }
                }

                if (isEmpty(this.playbackStreamVideoFps) && isEmpty(this.playbackStreamAudioFps)) {
                    const tsList = this.bufferList.map((item) => {
                        return {
                            type: item.type,
                            ts: item.ts
                        }
                    })

                    this.player.debug.log('CommonDemux', `playbackCacheBeforeDecodeForFpsRender, calc streamAudioFps is ${playbackStreamAudioFps}, streamVideoFps is ${playbackStreamVideoFps}, bufferListLength  is ${this.bufferList.length} and ts list is ${JSON.stringify(tsList)}`);
                }

                const hasAudio = this.getAudioBufferLength() > 0;
                const maxBufferLength = hasAudio ? 60 : 40;
                if (this.bufferList.length >= maxBufferLength) {
                    this.debug.warn('CommonDemux', `playbackCacheBeforeDecodeForFpsRender, bufferListLength  is ${this.bufferList.length} more than ${maxBufferLength}, and hasAudio is ${hasAudio} an set streamFps is 25`);
                    this.playbackStreamVideoFps = playbackStreamVideoFps;

                    if (this.player.video) {
                        this.player.video.setStreamFps(this.playbackStreamVideoFps);
                    }
                    if (hasAudio) {
                        this.playbackStreamAudioFps = 25;
                        this.playbackStreamFps = this.playbackStreamVideoFps + this.playbackStreamAudioFps;
                    } else {
                        this.playbackStreamFps = this.playbackStreamVideoFps;
                    }
                    this.initPlaybackCacheLoop()
                }
            }
        }
    }

    dropBuffer$2() {
        if (this.bufferList.length > 0) {
            let iFrameIndex = this.bufferList.findIndex((bufferItem) => {
                return isTrue(bufferItem.isIFrame) && bufferItem.type === MEDIA_TYPE.video;
            })

            if (this.isAllIframeInBufferList()) {
                for (let i = 0; i < this.bufferList.length; i++) {
                    const bufferItem = this.bufferList[i];
                    const tempDelay = this.getDelayNotUpdateDelay(bufferItem.ts, bufferItem.type);
                    if (tempDelay >= this.getNotDroppingDelayTs()) {
                        this.player.debug.log('CommonDemux', `dropBuffer$2() isAllIframeInBufferList() is true, and index is ${i} and tempDelay is ${tempDelay} and notDroppingDelayTs is ${this.getNotDroppingDelayTs()}`)
                        iFrameIndex = i;
                        break;
                    }
                }
            }

            if (iFrameIndex >= 0) {
                this.isPushDropping = true;
                this.player.updateStats({isDropping: true});
                //  old bufferList length
                const bufferListLength = this.bufferList.length;
                this.bufferList = this.bufferList.slice(iFrameIndex);
                const iFrameItem = this.bufferList.shift();
                this.resetAllDelay();
                this.getDelay(iFrameItem.ts, iFrameItem.type)
                this._doDecoderDecode(iFrameItem);
                this.isPushDropping = false;
                this.player.debug.log('CommonDemux', `dropBuffer$2() iFrameIndex is ${iFrameIndex},and old bufferList length is ${bufferListLength} ,and new bufferList length is ${this.bufferList.length} and new delay is ${this.delay} `);
            } else {
                this.isPushDropping = false;
            }
        }

        if (this.bufferList.length === 0) {
            this.isPushDropping = false;
        }
    }

    clearBuffer(needClear = false) {
        this.player.debug.log('CommonDemux', `clearBuffer,buffer length is ${this.bufferList.length}, need clear is ${needClear} and _opt.checkFirstIFrame is ${this.player._opt.checkFirstIFrame}`)
        if (needClear) {
            this.bufferList = [];
        }
        if (this.player.isPlayer()) {
            this.resetAllDelay();
            if (isTrue(this.player._opt.checkFirstIFrame)) {
                this.dropping = true;
                this.player.updateStats({isDropping: true});
            }
        }
        //  waiting for i frame
        this.player.decoderCheckFirstIFrame();
    }

    calcNetworkDelay(dts) {
        //
        if (!(this.player.isDemuxDecodeFirstIIframeInit() && dts > 0)) {
            return;
        }
        if (this.bufferStartDts === null) {
            this.bufferStartDts = dts;
            this.bufferStartLocalTs = now()
        } else {
            //
            if (dts < this.bufferStartDts) {
                this.player.debug.warn('CommonDemux', `calcNetworkDelay dts is ${dts} and bufferStartDts is ${this.bufferStartDts}`);
                this.bufferStartDts = dts;
                this.bufferStartLocalTs = now()
            }
        }
        let diff1 = dts - this.bufferStartDts;
        let localDiff = now() - this.bufferStartLocalTs;
        let delay = localDiff > diff1 ? localDiff - diff1 : 0;

        // just for
        if (delay > this.player._opt.networkDelay && this.player._opt.playType === PLAY_TYPE.player) {
            this.player.debug.warn('CommonDemux', `delay is more than networkDelay and now dts:${dts},start dts is ${this.bufferStartDts}, vs start is ${diff1},local diff is ${localDiff} ,delay is ${delay}, _opt.networkDelay is ${this.player._opt.networkDelay}`);
            this.player.emit(EVENTS.networkDelayTimeout, delay)
        }
        this.player.updateStats({
            netBuf: delay
        })
    }

    calcIframeIntervalTimestamp(ts) {
        if (this.preIframeTs === null) {
            this.preIframeTs = ts;
        } else {
            if (this.preIframeTs < ts) {
                const intervalTimestamp = ts - this.preIframeTs;
                if (this.player) {
                    this.player.videoIframeIntervalTs = intervalTimestamp;
                }
                // post 到主线程里面去。
                this.preIframeTs = ts;
            }
        }
    }

    //  计算流的fps
    calcBufferFps(ts) {

    }

    getNotDroppingDelayTs() {
        return this.player._opt.videoBuffer + this.player._opt.videoBufferDelay / 2
    }

    getMaxDelayTs() {
        return this.player._opt.videoBuffer + this.player._opt.videoBufferDelay
    }

    getPushLatestDelay() {
        return this.pushLatestDelay;
    }

    getVideoBufferLength() {
        let result = 0;
        this.bufferList.forEach((item) => {
            if (item.type === MEDIA_TYPE.video) {
                result += 1;
            }
        })
        return result;
    }

    getAudioBufferLength() {
        let result = 0;
        this.bufferList.forEach((item) => {
            if (item.type === MEDIA_TYPE.audio) {
                result += 1;
            }
        })
        return result;
    }

    hasIframeInBufferList() {
        return this.bufferList.some((item) => {
            return item.type === MEDIA_TYPE.video && item.isIFrame
        })
    }

    isAllIframeInBufferList() {
        const videoBufferLength = this.getVideoBufferLength();
        let iFrameLength = 0;
        this.bufferList.forEach((item) => {
            if (item.type === MEDIA_TYPE.video && item.isIFrame) {
                iFrameLength += 1;
            }
        })
        return videoBufferLength === iFrameLength;
    }

    getInputByteLength() {
        return 0
    }

    getIsStreamTsMoreThanLocal() {
        return this.isStreamTsMoreThanLocal;
    }


    close() {

    }

    reset() {

    }

    findSei(payload, ts, isHevc = false) {

        let size = 4;
        if (isNotEmpty(this.nalUnitSize)) {
            size = this.nalUnitSize;
        }
        const units = parseAvcC(payload.slice(5), size);

        if (isFalse(isHevc)) {
            const videoInfo = this.player.getVideoInfo();
            if (videoInfo && videoInfo.encType) {
                isHevc = videoInfo.encType === VIDEO_ENC_TYPE_SHOW.h265;
            }
        }

        units.forEach((unit) => {
            const type = isHevc ? (unit[0] >>> 1) & 0x3f : unit[0] & 0x1f;
            if ((isHevc && (type === H265_NAL_TYPE.suffixSei || type === H265_NAL_TYPE.prefixSei)) ||
                (isFalse(isHevc) && type === H264_NAL_TYPE.kSliceSEI)) {
                this.player.emit(EVENTS.videoSEI, {
                    ts,
                    data: unit
                })
            }
        })
    }

    handleRecording(payload, type, ts, isIFrame, cts) {
        if (this.player.isRecordTypeFlv()) {
            const payloadCopy = new Uint8Array(payload);
            if (type === MEDIA_TYPE.video) {
                this.player.recorder.addVideo(payloadCopy, ts);
            } else if (type === MEDIA_TYPE.audio) {
                this.player.recorder.addAudio(payloadCopy, ts);
            }
        } else if (this.player.isRecordTypeMp4()) {
            const payloadCopy = new Uint8Array(payload);
            if (this.player.recorder.isWasmMp4()) {
                //  wasm mp4 录制
                if (type === MEDIA_TYPE.video) {
                    // remove video tag header(1) + CompositionTime(4)
                    this.player.recorder.handleAddNaluTrack(payloadCopy.slice(5), isIFrame, ts, cts);
                } else if (type === MEDIA_TYPE.audio) {
                    const payloadCopy = new Uint8Array(payload);
                    //  aac need remove 2
                    // other need remove 1
                    this.player.recorder.handleAddAudioTrack(isAAC(payloadCopy) ? payloadCopy.slice(2) : payloadCopy.slice(1), ts);
                }
            } else {
                //  普通的mp4录制。
                if (type === MEDIA_TYPE.video) {
                    this.player.recorder.handleAddNaluTrack(payloadCopy.slice(5), isIFrame, ts, cts);
                }
            }
        }
    }


    updateNalUnitSize(payload) {
        const videoCodec = (payload[0] & 0x0F);
        this.player.video.updateVideoInfo({
            encTypeCode: videoCodec
        })
        const isHevc = videoCodec === VIDEO_ENC_CODE.h265;
        this.nalUnitSize = getUnitSizeFromVideoSequenceHeader(payload, isHevc);
        this.player.debug.log(this.TAG_NAME, `demux() isVideoSequenceHeader is true and isHevc is ${isHevc} and nalUnitSize is ${this.nalUnitSize}`);
    }

    cryptoPayload(payload, isIFrame) {
        let payloadBuffer = payload;
        let player = this.player;
        if (player._opt.isM7sCrypto) {
            if ((player._opt.cryptoKey && player._opt.cryptoKey.byteLength > 0) &&
                (player._opt.cryptoIV && player._opt.cryptoIV.byteLength > 0)) {
                const videoInfo = this.player.video.getVideoInfo();
                if (videoInfo.encTypeCode) {
                    payloadBuffer = aes256ctrDecrypt(payload, player._opt.cryptoKey, player._opt.cryptoIV, videoInfo.encTypeCode === VIDEO_ENC_CODE.h265);
                } else {
                    player.debug.warn(this.TAG_NAME, `videoInfo.encTypeCode is ${videoInfo.encTypeCode}`);
                }
            } else {
                player.debug.error(this.TAG_NAME, `isM7sCrypto cryptoKey.length is ${player._opt.cryptoKey && player._opt.cryptoKey.byteLength} or cryptoIV.length is ${player._opt.cryptoIV && player._opt.cryptoIV.byteLength} null`);
            }
        } else if (player._opt.isSm4Crypto) {
            if (player._opt.sm4CryptoKey && isIFrame) {
                payloadBuffer = sm4Decrypt(payload, player._opt.sm4CryptoKey);
            } else {
                if (!player._opt.sm4CryptoKey) {
                    player.debug.error(this.TAG_NAME, `isSm4Crypto opt.sm4CryptoKey is null`);
                }
            }
        } else if (player._opt.isXorCrypto) {
            if ((player._opt.cryptoKey && player._opt.cryptoKey.byteLength > 0) &&
                (player._opt.cryptoIV && player._opt.cryptoIV.byteLength > 0)) {
                const videoInfo = this.player.video.getVideoInfo();
                payloadBuffer = xorDecrypt(payload, player._opt.cryptoKey, player._opt.cryptoIV, videoInfo.encTypeCode === VIDEO_ENC_CODE.h265);
            } else {
                player.debug.error(this.TAG_NAME, `isXorCrypto opt.xorCryptoKey is null`);
            }
        }
        return payloadBuffer;
    }


    cryptoPayloadAudio(payload) {
        let payloadBuffer = payload;
        let player = this.player;

        if (player._opt.isM7sCrypto) {
            if ((player._opt.cryptoKey && player._opt.cryptoKey.byteLength > 0) &&
                (player._opt.cryptoIV && player._opt.cryptoIV.byteLength > 0)) {
                const codecId = payload[0] >> 4;
                if (codecId === AUDIO_ENC_CODE.AAC) {
                    payloadBuffer = aes256ctrDecryptAacAudio(payload, player._opt.cryptoKey, player._opt.cryptoIV);
                }
            } else {
                player.debug.error(this.TAG_NAME, `isM7sCrypto cryptoKey.length is ${player._opt.cryptoKey && player._opt.cryptoKey.byteLength} or cryptoIV.length is ${player._opt.cryptoIV && player._opt.cryptoIV.byteLength} null`);
            }
        }

        return payloadBuffer;
    }

    _decodeEnhancedH265Video(payload, dts) {
        const flags = payload[0];
        const frameTypeEx = flags & 0x30;
        const packetEx = flags & 0x0F;
        const codecId = payload.slice(1, 5);
        const tmp = new ArrayBuffer(4);
        const tmp32 = new Uint32Array(tmp);
        // 'av01' 'hvc1'
        const isAV1 = String.fromCharCode(codecId[0]) == 'a';
        if (packetEx === PACKET_TYPE_EX.PACKET_TYPE_SEQ_START) {
            if (frameTypeEx === FRAME_TYPE_EX.FT_KEY) {
                // header video info
                const extraData = payload.slice(5);
                if (isAV1) {
                    // todo：待支持
                } else {
                    const payloadBuffer = new Uint8Array(5 + extraData.length);
                    payloadBuffer.set([0x1c, 0x00, 0x00, 0x00, 0x00], 0);
                    payloadBuffer.set(extraData, 5);
                    this.updateNalUnitSize(payloadBuffer);
                    this.player.debug.log(this.TAG_NAME, `demux() isVideoSequenceHeader(enhancedH265) is true and nalUnitSize is ${this.nalUnitSize}`);
                    this._doDecode(payloadBuffer, MEDIA_TYPE.video, 0, true, 0);
                }
            }
        } else if (packetEx === PACKET_TYPE_EX.PACKET_TYPE_FRAMES) {
            let payloadBuffer = payload;
            let cts = 0;
            const isIFrame = frameTypeEx === FRAME_TYPE_EX.FT_KEY;

            if (isIFrame) {
                this.calcIframeIntervalTimestamp(dts);
            }

            if (isAV1) {
                // todo:待定
            } else {
                // h265
                tmp32[0] = payload[4]
                tmp32[1] = payload[3]
                tmp32[2] = payload[2]
                tmp32[3] = 0
                cts = tmp32[0];
                const data = payload.slice(8);
                payloadBuffer = hevcEncoderNalePacketNotLength(data, isIFrame);
                payloadBuffer = this.cryptoPayload(payloadBuffer, isIFrame);
                this._doDecode(payloadBuffer, MEDIA_TYPE.video, dts, isIFrame, cts);
            }

        } else if (packetEx === PACKET_TYPE_EX.PACKET_TYPE_FRAMESX) {
            const isIFrame = frameTypeEx === FRAME_TYPE_EX.FT_KEY;
            const data = payload.slice(5);
            if (isIFrame) {
                this.calcIframeIntervalTimestamp(dts);
            }
            let payloadBuffer = hevcEncoderNalePacketNotLength(data, isIFrame);
            payloadBuffer = this.cryptoPayload(payloadBuffer, isIFrame);
            this._doDecode(payloadBuffer, MEDIA_TYPE.video, dts, isIFrame, 0);
        }
    }

    _isEnhancedH265Header(flags) {
        return (flags & FRAME_HEADER_EX) === FRAME_HEADER_EX;
    }
}
