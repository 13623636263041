import CommonWebrtc from "./commonWebrtc";
import {getWebRtcRemoteSdpForSRS} from "../utils/webrtc";

export default class WebrtcForSRSDecoder extends CommonWebrtc {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'WebrtcForSRSDecoder';
        this.player.debug.log(this.TAG_NAME, 'init')
    }

    destroy() {
        super.destroy();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    loadSource(url) {
        return new Promise((resolve, reject) => {
            const rtcPeerConnection = this.rtcPeerConnection;
            rtcPeerConnection.createOffer().then((res) => {
                rtcPeerConnection.setLocalDescription(res);
                this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp loadSource`);
                getWebRtcRemoteSdpForSRS(url, res.sdp).then((response) => {
                    this.player.debug.log(this.TAG_NAME, `getWebRtcRemoteSdp response and code is ${response.code}`);
                    const ret = response;

                    if (ret.code !== 0) {
                        return reject(ret.msg);
                    }

                    rtcPeerConnection.setRemoteDescription(
                        new RTCSessionDescription({
                            type: "answer",
                            sdp: ret
                        })
                    ).then(() => {
                        resolve()
                    }).catch((e) => {
                        reject(e);
                    })
                }).catch((e) => {
                    this.player.debug.error(this.TAG_NAME, `loadSource getWebRtcRemoteSdp response error`, e);
                    reject(e);
                })
            }).catch((e) => {
                this.player.debug.error(this.TAG_NAME, `loadSource rtcPeerConnection.createOffer() error`, e);
                reject(e);
            })
        })
    }
}
