import {
    AUDIO_ENC_CODE_SHOW,
    CONTROL_HEIGHT,
    CONTROL_PLAYBACK_HEIGHT, CPU_LEVEL, DEMUX_TYPE_SHOW,
    EVENTS,
    PLAY_TYPE,
    PLAYBACK_CONTROL_TIME_PRECISION,
    PLAYBACK_CONTROL_TIME_PRECISION_CLASS, PLAYBACK_CONTROL_TYPE, PLAYER_RESIZE_TIME, RENDER_TYPE
} from "../constant";
import {
    bpsSize,
    bpsSize$2, clamp, formatFileSize,
    formatTimeTips, getPerformanceMemory,
    getStyle, getTarget,
    isBoolean, isFalse,
    isMobile, isNotEmpty,
    isString, isTrue,
    parseTime, proVersionTime,
    setStyle, throttle, toNumber
} from "../utils";
import screenfull from "screenfull";
import {
    renderFiveMinTimeDay,
    renderHalfHourTimeDay,
    renderHourTimeDay,
    renderOneMinTimeDay,
    renderTenMinTimeDay
} from "./playback";
import {getPercentage, secondToTime} from "./utils";
import {getElementRect} from "../utils/dom";

export default (player, control) => {
    const {
        events: {proxy},
    } = player;

    const object = document.createElement('object');
    object.setAttribute('aria-hidden', 'true');
    object.setAttribute('tabindex', -1);
    object.type = 'text/html';
    object.data = 'about:blank';

    setStyle(object, {
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: '-1',
    });

    let playerWidth = player.width;
    let playerHeight = player.height;
    const resizeFn = throttle(() => {
        if (player.width !== playerWidth || player.height !== playerHeight) {
            playerWidth = player.width;
            playerHeight = player.height;
            player.emit(EVENTS.resize);
            screenfullH5Control();
        }
    }, PLAYER_RESIZE_TIME);
    proxy(object, 'load', () => {
        proxy(object.contentDocument.defaultView, 'resize', () => {
            resizeFn();
        });
    });
    player.$container.appendChild(object);

    player.on(EVENTS.destroy, () => {
        player.$container.removeChild(object);
    })

    function setVolumeHandle(percentage) {
        if (percentage === 0) {
            setStyle(control.$volumeOn, 'display', 'none');
            setStyle(control.$volumeOff, 'display', 'flex');
            setStyle(control.$volumeHandle, 'top', `${48}px`);
        } else {
            if (control.$volumeHandle && control.$volumePanel) {
                const panelHeight = getStyle(control.$volumePanel, 'height') || 60;
                const handleHeight = getStyle(control.$volumeHandle, 'height');
                const top = panelHeight - (panelHeight - handleHeight) * percentage - handleHeight;
                setStyle(control.$volumeHandle, 'top', `${top}px`);
                setStyle(control.$volumeOn, 'display', 'flex');
                setStyle(control.$volumeOff, 'display', 'none');
            }
        }
        control.$volumePanelText && (control.$volumePanelText.innerHTML = parseInt(percentage * 100))
    }

    player.on(EVENTS.volumechange, () => {
        setVolumeHandle(player.volume);
    });

    player.on(EVENTS.loading, (flag) => {
        setStyle(control.$loading, 'display', flag ? 'flex' : 'none');
        if ((isFalse(player._opt.backgroundLoadingShow) &&
                isTrue(flag)) ||
            isFalse(flag)) {
            // hidden
            setStyle(control.$poster, 'display', 'none')
        }

        if (flag) {
            setStyle(control.$playBig, 'display', 'none');
            setStyle(control.$tipsMessage, 'display', 'none');
        }

        if (!flag) {
            if (player._opt.extendDomConfig.showAfterLoading &&
                control.$extendDom) {
                setStyle(control.$extendDom, 'display', 'block');
            }
        }

        // just for pc
        if (!isMobile()) {
            if (!flag) {
                //  除了mse解码外。
                if (player.getRenderType() === RENDER_TYPE.canvas
                    && isFalse(player._opt.useMSE)) {
                    handleRemoveLoadingBgImage();
                }
            }
        }
    })

    //  主要是给 mse+ video 服务的。
    player.on(EVENTS.removeLoadingBgImage, () => {
        if (!isMobile()) {
            // need delay to hidden,because video play not immediately show view.
            handleRemoveLoadingBgImage();
        }
    })


    const handleRemoveLoadingBgImage = () => {
        if (control.$loadingBgImage &&
            control.$loadingBg &&
            control.$loadingBgImage.src) {
            player.debug && player.debug.log('Control', 'remove loading bg image');
            control.$loadingBgImage.width = 0;
            control.$loadingBgImage.height = 0;
            control.$loadingBgImage.src = '';
            setStyle(control.$loadingBg, 'display', 'none');
        }
    }

    const handleScreenfullChange = (e) => {
        // 全屏
        if (player.fullscreen) {
            if (getTarget(e) === player.$container) {
                screenfullChange();
            }
        } else {
            // 取消全屏
            screenfullChange();
        }
    }

    const screenfullChange = (fullscreen) => {
        let isFullScreen = isBoolean(fullscreen) ? fullscreen : player.fullscreen
        setStyle(control.$fullscreenExit, 'display', isFullScreen ? 'flex' : 'none');
        setStyle(control.$fullscreen, 'display', isFullScreen ? 'none' : 'flex');
        // control.autoSize();
    };
    const showPlaybackTFDom = () => {
        return player._opt.playType === PLAY_TYPE.playbackTF && player._opt.playbackConfig.showControl;
    }

    const screenfullH5Control = (fn) => {
        if (isMobile() && control.$controls && player._opt.useWebFullScreen) {
            setTimeout(() => {
                if (player.fullscreen) {
                    const controlHeight = showPlaybackTFDom() ? CONTROL_PLAYBACK_HEIGHT : CONTROL_HEIGHT
                    let translateX = player.height / 2 - player.width + controlHeight / 2;
                    let translateY = player.height / 2 - controlHeight / 2;

                    control.$controls.style.transform = `translateX(${-translateX}px) translateY(-${translateY}px) rotate(-90deg)`;

                    if (control.$zoomControls) {
                        const controlsWidth = 156;
                        const controlsHeight = 30;
                        const controlsTranslateX = (player.width / 2) + (controlsWidth / 2) - controlsHeight / 2;
                        control.$zoomControls.style.transform = `translateX(${-controlsTranslateX}px) translateY(${player.height / 2}px) rotate(-90deg)`;
                    }

                    if (control.$recording) {
                        const recordingWidth = 101;
                        const recordingHeight = 20;
                        const recordingTranslateX = (player.width / 2) + (recordingWidth / 2) - recordingHeight / 2;
                        control.$recording.style.transform = `translateX(${-recordingTranslateX}px) translateY(${player.height / 2}px) rotate(-90deg)`;
                    }

                } else {
                    control.$controls.style.transform = `translateX(0) translateY(0) rotate(0)`;
                    if (control.$zoomControls) {
                        control.$zoomControls.style.transform = `translateX(-50%) translateY(0) rotate(0)`;
                    }

                    if (control.$recording) {
                        control.$recording.style.transform = `translateX(-50%) translateY(0) rotate(0)`;
                    }
                }
                fn && fn();
            }, 10)
        }
    }

    try {

        screenfull.on('change', handleScreenfullChange);
        player.events.destroys.push(() => {
            screenfull.off('change', handleScreenfullChange);
        });
    } catch (error) {
        //
    }

    //
    player.on(EVENTS.webFullscreen, (value) => {
        if (isMobile()) {
            screenfullChange(value);
            screenfullH5Control(() => {
                _resizePlaybackTime();
            });
        }
    })


    player.on(EVENTS.recording, () => {
        if (player.playing) {
            setStyle(control.$record, 'display', player.recording ? 'none' : 'flex');
            setStyle(control.$recordStop, 'display', player.recording ? 'flex' : 'none');
            if (player._opt.hasControl || player._opt.isShowRecordingUI) {
                setStyle(control.$recording, 'display', player.recording ? 'flex' : 'none')

                if (isFalse(player.recording) && control.$recordingTime) {
                    control.$recordingTime.innerHTML = formatTimeTips(0)
                }
            }
        }
    })

    //
    player.on(EVENTS.recordingTimestamp, (timestamp) => {
        // console.log(timestamp);
        control.$recordingTime && (control.$recordingTime.innerHTML = formatTimeTips(timestamp))
    })
    player.on(EVENTS.zooming, () => {
        if (player.playing) {
            setStyle(control.$zoom, 'display', player.zooming ? 'none' : 'flex');
            setStyle(control.$zoomStop, 'display', player.zooming ? 'flex' : 'none');
            if (player._opt.hasControl || player._opt.isShowZoomingUI) {
                setStyle(control.$zoomControls, 'display', player.zooming ? 'flex' : 'none');
            }
        }
    })
    player.on(EVENTS.playing, (flag) => {
        handlePlaying(flag);
    })

    const handlePlaying = (flag) => {
        setStyle(control.$play, 'display', flag ? 'none' : 'flex');
        setStyle(control.$playBig, 'display', flag ? 'none' : 'block');
        setStyle(control.$pause, 'display', flag ? 'flex' : 'none');
        setStyle(control.$screenshot, 'display', flag ? 'flex' : 'none');
        setStyle(control.$record, 'display', flag ? 'flex' : 'none');
        setStyle(control.$qualityMenu, 'display', flag ? 'flex' : 'none');
        setStyle(control.$volume, 'display', flag ? 'flex' : 'none');
        setStyle(control.$ptz, 'display', flag ? 'flex' : 'none');
        setStyle(control.$zoom, 'display', flag ? 'flex' : 'none');
        setStyle(control.$scaleMenu, 'display', flag ? 'flex' : 'none');
        setStyle(control.$faceDetect, 'display', flag ? 'flex' : 'none');
        setStyle(control.$objectDetect, 'display', flag ? 'flex' : 'none');
        setStyle(control.$occlusionDetect, 'display', flag ? 'flex' : 'none');
        setStyle(control.$controlHtml, 'display', flag ? 'flex' : 'none');


        if (player.isPlayback()) {
            setStyle(control.$speedMenu, 'display', flag ? 'flex' : 'none');
        }
        screenfullChange();
        control.extendBtnList.forEach((item) => {
            if (item.$iconWrap) {
                setStyle(item.$iconWrap, 'display', flag ? 'flex' : 'none');
            }
            //  默认是隐藏的
            if (item.$activeIconWrap) {
                setStyle(item.$activeIconWrap, 'display', 'none');
            }
        })

        if (player._opt.showPerformance) {
            setStyle(control.$performanceActive, 'display', flag ? 'flex' : 'none');
        } else {
            setStyle(control.$performance, 'display', flag ? 'flex' : 'none');
            setStyle(control.$performanceActive, 'display', 'none');
        }

        // hidden
        setStyle(control.$poster, 'display', 'none');
        setStyle(control.$ptzActive, 'display', 'none');
        setStyle(control.$recordStop, 'display', 'none');
        setStyle(control.$zoomStop, 'display', 'none');
        setStyle(control.$faceDetectActive, 'display', 'none');
        setStyle(control.$objectDetectActive, 'display', 'none');

        // 不在播放
        if (!flag) {
            control.$speed && (control.$speed.innerHTML = bpsSize(''));
            // zoom
            setStyle(control.$zoomControls, 'display', 'none');
            // record
            setStyle(control.$recording, 'display', 'none');
            // ptz
            if (control.$ptzControl) {
                control.$ptzControl.classList.remove('jessibuca-ptz-controls-show');
            }
        }

        _resizePlaybackTime();
        if (flag) {
            _playbackTimeOffset();
        }
    }

    //
    player.on(EVENTS.playbackPause, (flag) => {
        handlePlaying(!flag);
    })


    player.on(EVENTS.kBps, (rate) => {
        const bps = bpsSize$2(rate);
        control.kbpsShow = bps;

        if (player._opt.showBandwidth) {
            control.$speed && (control.$speed.innerHTML = bps);
            _resizePlaybackTime();
        }
    })

    const _resizePlaybackTime = () => {
        if (showPlaybackTFDom()) {
            if (player._opt.playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.normal) {
                let width = control.controlsInnerRect.width - control.controlsLeftRect.width - control.controlsRightRect.width - control.controlsPlaybackBtnsRect.width
                if (isMobile() && player.webFullscreen) {
                    width = control.controlsInnerRect.height - control.controlsLeftRect.height - control.controlsRightRect.height - control.controlsPlaybackBtnsRect.height
                }
                control.$playbackTimeInner.style.width = (width) + 'px'
            }
        }
    }


    // playback time offset
    const _playbackTimeOffset = () => {
        if (!showPlaybackTFDom() ||
            player._opt.playbackConfig.controlType !== PLAYBACK_CONTROL_TYPE.normal) {
            return;
        }
        const leftPx = control.$playbackCurrentTime.style.left
        let left = parseInt(leftPx, 10);
        const innerWidth = control.controlsPlaybackTimeInner.width;
        if (left - innerWidth / 2 > 0) {
            left = parseInt(left - innerWidth / 2, 10)
        } else {
            left = 0
        }
        control.$playbackTimeInner.scrollLeft = left;
    }

    // show playback operate
    if (showPlaybackTFDom()) {

        // 计算偏移量
        const _calcCurrentTimeTextOffset = () => {
            if (showPlaybackTFDom()) {
                let left = 0;
                const playingTimestamp = player.playback && player.playback.playingTimestamp;
                if (playingTimestamp) {
                    const nowDate = new Date(playingTimestamp);
                    const hour = nowDate.getHours();
                    const min = nowDate.getMinutes();
                    const second = nowDate.getSeconds();
                    if (player.playback.is60Min) {
                        left = hour * 60 + min;
                    } else if (player.playback.is30Min) {
                        left = (hour * 60 + min) * 2 + parseInt(second / 30, 10);
                    } else if (player.playback.is10Min) {
                        left = (hour * 60 + min) * 6 + parseInt(second / 10, 10);
                    } else if (player.playback.is5Min) {
                        left = (hour * 60 + min) * 12 + parseInt(second / 5, 10);
                    } else if (player.playback.is1Min) {
                        left = (hour * 60 + min) * 60 + parseInt(second, 10);
                    }
                    control.$playbackCurrentTime.style.left = left + 'px'

                    // console.log('playingTimestamp', playingTimestamp, 'hour is ', hour, 'min is', min, 'second is', second, 'left is ', left)
                }
            }
        }
        // toggle playback button
        const _togglePlaybackButton = (precision) => {
            control.$playbackNarrow.classList.remove('disabled')
            control.$playbackExpand.classList.remove('disabled')

            if (precision === PLAYBACK_CONTROL_TIME_PRECISION.oneHour) {
                control.$playbackNarrow.classList.add('disabled')
            }

            if (precision === PLAYBACK_CONTROL_TIME_PRECISION.fiveMin) {
                control.$playbackExpand.classList.add('disabled')
            }
        }

        //
        // if (player._opt.showBandwidth) {
        //     control.$controlsLeft.style.width = '90px'
        // }
        // 播放时间
        player.on(EVENTS.playbackTime, (time) => {
            if (player._opt.playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.normal) {
                control.$playbackCurrentTimeText && (control.$playbackCurrentTimeText.innerText = parseTime(time, "{h}:{i}:{s}"));
                _calcCurrentTimeTextOffset();
            } else if (player._opt.playbackConfig.controlType === PLAYBACK_CONTROL_TYPE.simple) {
                // console.log('playbackTime', time);
                const percentage = getPercentage(time, player.playback.totalDuration);
                control.$playbackProgressPlayed.style.width = `${percentage * 100}%`;
                control.$playbackProgressIndicator.style.left = `calc(${percentage * 100}% - ${14 / 2}px)`;
                control.$playbackProgressTime.innerText = `${secondToTime(time)} / ${secondToTime(player.playback.totalDuration)}`;
            }

        })
        // 缩放类型
        player.on(EVENTS.playbackPrecision, (precision, playbackList) => {
            if (!showPlaybackTFDom() ||
                player._opt.playbackConfig.controlType !== PLAYBACK_CONTROL_TYPE.normal) {
                return;
            }
            // update class
            control.$playbackTimeScroll.classList.remove(PLAYBACK_CONTROL_TIME_PRECISION_CLASS.oneHour,
                PLAYBACK_CONTROL_TIME_PRECISION_CLASS.halfHour,
                PLAYBACK_CONTROL_TIME_PRECISION_CLASS.fiveMin,
                PLAYBACK_CONTROL_TIME_PRECISION_CLASS.tenMin
            )
            control.$playbackTimeScroll.classList.add(PLAYBACK_CONTROL_TIME_PRECISION_CLASS[precision]);

            if (control.rafId) {
                window.cancelAnimationFrame(control.rafId);
                control.rafId = null;
            }
            if (control.changePercisitionInterval) {
                clearTimeout(control.changePercisitionInterval);
                control.changePercisitionInterval = null;
            }

            // clear inner html
            control.$playbackTimeListOne.innerHTML = '';
            control.$playbackTimeListSecond.innerHTML = '';
            control.changePercisitionInterval = setTimeout(() => {
                control.$playbackTimeListOne.innerHTML = '';
                control.$playbackTimeListSecond.innerHTML = '';
                switch (precision) {
                    case PLAYBACK_CONTROL_TIME_PRECISION.oneHour:
                        renderHourTimeDay(playbackList, control);
                        break;
                    case PLAYBACK_CONTROL_TIME_PRECISION.halfHour:
                        renderHalfHourTimeDay(playbackList, control);
                        break;
                    case PLAYBACK_CONTROL_TIME_PRECISION.tenMin:
                        renderTenMinTimeDay(playbackList, control);
                        break;
                    case PLAYBACK_CONTROL_TIME_PRECISION.fiveMin:
                        renderFiveMinTimeDay(playbackList, control);
                        break;
                    // case PLAYBACK_CONTROL_TIME_PRECISION.oneMin:
                    //     renderDom = renderOneMinTimeDay();
                    //     break;
                    default:
                        break;
                }
                _calcCurrentTimeTextOffset();
                if (player._opt.playbackConfig.showPrecisionBtn) {
                    _togglePlaybackButton(precision);
                }
                _playbackTimeOffset();
            }, 16)

            // if (renderDom) {
            //     control.$playbackTimeList.insertAdjacentHTML(
            //         'beforeend',
            //         renderDom
            //     )
            //
            // }
        })

        // resize
        player.on(EVENTS.resize, () => {
            _resizePlaybackTime();
        })

        //  scroll
        player.on(EVENTS.playbackTimeScroll, () => {
            _playbackTimeOffset();
        })

        //
        _resizePlaybackTime();
    }

    // quality
    if (player._opt.operateBtns.quality && player._opt.qualityConfig.length > 0) {

        player.on(EVENTS.streamQualityChange, (value) => {
            _changeStreamQuality(value);
        })

        const _changeStreamQuality = (value) => {
            control.$qualityText.innerText = value;
            control.$qualityMenuItems.forEach(($qualityMenuItem) => {
                const quality = $qualityMenuItem.dataset.quality
                $qualityMenuItem.classList.remove('jessibuca-quality-menu-item-active');
                if (quality === value) {
                    $qualityMenuItem.classList.add('jessibuca-quality-menu-item-active');
                }
            })
        }
        const _renderQuality = () => {
            const qualityList = player._opt.qualityConfig || [];
            let qualityDom = '';
            qualityList.forEach((item) => {
                qualityDom += `
                    <div class="jessibuca-quality-menu-item" data-quality="${item}">${item}</div>
                `
            })
            if (qualityDom) {
                control.$qualityMenuList.insertAdjacentHTML(
                    'beforeend',
                    qualityDom
                )
                //
                Object.defineProperty(control, '$qualityMenuItems', {
                    value: player.$container.querySelectorAll('.jessibuca-quality-menu-item'),
                });

                // init default quality
            }
        }
        _renderQuality();
        if (player.streamQuality) {
            _changeStreamQuality(player.streamQuality)
        }
    }
    // scale
    if (player._opt.operateBtns.scale && player._opt.scaleConfig.length > 0) {
        player.on(EVENTS.viewResizeChange, (value) => {
            _changeViewResize(value);
        })

        const _changeViewResize = (value) => {
            const scaleTypeText = player._opt.scaleConfig[value];
            control.$scaleText.innerText = scaleTypeText;
            control.$scaleMenuItems.forEach(($scaleMenuItem) => {
                const scale = $scaleMenuItem.dataset.scale
                $scaleMenuItem.classList.remove('jessibuca-scale-menu-item-active');
                if (toNumber(scale) === toNumber(value)) {
                    $scaleMenuItem.classList.add('jessibuca-scale-menu-item-active');
                }
            })
        }


        const _renderScale = () => {
            const scaleList = player._opt.scaleConfig || [];
            let scaleDom = '';
            scaleList.forEach((item, index) => {
                scaleDom += `
                    <div class="jessibuca-scale-menu-item" data-scale="${index}">${item}</div>
                `
            })
            if (scaleDom) {
                control.$scaleMenuList.insertAdjacentHTML(
                    'beforeend',
                    scaleDom
                )
                //
                Object.defineProperty(control, '$scaleMenuItems', {
                    value: player.$container.querySelectorAll('.jessibuca-scale-menu-item'),
                });

                // init default quality
            }
        }
        _renderScale();
        _changeViewResize(player.scaleType)
    }


    // playback rate ui
    if (player.isPlayback()) {
        if (player._opt.playbackConfig.showRateBtn &&
            player._opt.playbackConfig.rateConfig.length > 0) {
            player.on(EVENTS.playbackRateChange, (value) => {
                _changePlaybackSpeed(value);
            })

            const _changePlaybackSpeed = (value) => {
                const speedConfig = player._opt.playbackConfig.rateConfig;
                const speedItem = speedConfig.find((item) => {
                    return toNumber(item.value) === toNumber(value)
                })
                if (speedItem) {
                    control.$speedText.innerText = speedItem.label;
                    control.$speedMenuItems.forEach(($speedMenuItem) => {
                        const speed = $speedMenuItem.dataset.speed
                        $speedMenuItem.classList.remove('jessibuca-speed-menu-item-active');
                        if (toNumber(speed) === toNumber(value)) {
                            $speedMenuItem.classList.add('jessibuca-speed-menu-item-active');
                        }
                    })
                }
            }

            const _renderPlaybackSpeed = () => {
                const speedList = player._opt.playbackConfig.rateConfig;
                let scaleDom = '';
                speedList.forEach((item, index) => {
                    scaleDom += `
                    <div class="jessibuca-speed-menu-item" data-speed="${item.value}">${item.label}</div>
                `
                })
                if (scaleDom) {
                    control.$speedMenuList.insertAdjacentHTML(
                        'beforeend',
                        scaleDom
                    )
                    //
                    Object.defineProperty(control, '$speedMenuItems', {
                        value: player.$container.querySelectorAll('.jessibuca-speed-menu-item'),
                    });
                    // init default quality
                }
            }
            _renderPlaybackSpeed();
            const defaultRate = player.playback ? player.playback.playbackRate : 1;
            _changePlaybackSpeed(defaultRate);
        }
    }

    //
    player.on(EVENTS.stats, (stats = {}) => {
        if (player._opt.showPerformance) {
            setStyle(control.$performancePanel, 'display', 'block');
            control.$performancePanel.innerHTML = '';
            const versionTime = proVersionTime;
            const performanceMemory = getPerformanceMemory();
            const cpuLevel = player.getCpuLevel();
            const cpuLevelTitle = (isNotEmpty(cpuLevel) && cpuLevel !== -1) ? `${CPU_LEVEL[cpuLevel]}` : '';
            const videoInfo = (player.video && player.video.videoInfo) || {};
            const audioInfo = (player.audio && player.audio.audioInfo) || {};
            const times = player._times || {};
            const renderType = player.getRenderType();
            const canvasRenderTpe = player.getCanvasRenderType();
            const decoderType = player.getDecodeType();
            const demuxType = player.getDemuxType();
            const streamType = player.getStreamType();
            const audioEngine = player.getAudioEngineType();
            let recordingDuration = player.getRecordingDuration();
            let recordingTotalSize = player.getRecordingByteLength();
            const isAudioPlaybackRateSpeed = player.isAudioPlaybackRateSpeed();
            const videoIframeIntervalTs = player.videoIframeIntervalTs;
            recordingDuration = formatTimeTips(recordingDuration);
            recordingTotalSize = formatFileSize(recordingTotalSize);
            const playType = player.isPlayback() ? '录播' : '直播';
            let isDropping = stats.isDropping;
            // let isWebrtcH264 = player.isWebrtcH264();
            const isMSEDecodeAudio = player._opt.useMSE && player._opt.mseDecodeAudio;
            const kbpsShow = player.control ? player.control.kbpsShow : '0 KB/s';
            const videoPlaybackQuality = player.getVideoPlaybackQuality();
            const renderDom = `
                <div class="jessibuca-performance-item">
                    <span>版本 ${versionTime}</span>
                </div>
                ${
                player._opt.isMulti ? `
                    <div class="jessibuca-performance-item">
                        <span>UUid ${player._opt.debugUuid}</span>
                    </div>
                    ` : ''
            }
                ${
                player.isInMulti() ? `
                    <div class="jessibuca-performance-item">
                        <span>窗口下标 ${player._opt.multiIndex}</span>
                    </div>
                    ` : ''
            }
                ${
                performanceMemory ? `
                    <div class="jessibuca-performance-item">
                        <span>内存大小限制 ${formatFileSize(performanceMemory.jsHeapSizeLimit)}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>可使用的内存 ${formatFileSize(performanceMemory.totalJSHeapSize)}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>已使用的内存 ${formatFileSize(performanceMemory.usedJSHeapSize)}</span>
                    </div>
                    ` : ''
            }
                ${
                cpuLevelTitle ? `
                    <div class="jessibuca-performance-item">
                        <span>CPU压力情况 ${cpuLevelTitle}</span>
                    </div>
                ` : ''
            }

                ${
                (performanceMemory &&
                    performanceMemory.usedJSHeapSize > performanceMemory.totalJSHeapSize) ? `
                    <div class="jessibuca-performance-item">
                        <span>可能内存泄漏 是}</span>
                    </div>
                    ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>播放模式 ${playType}</span>
                </div>
                ${
                player.isPlayback() ?
                    `
                        <div class="jessibuca-performance-item">
                            <span>播放倍率 ${player.playback.rate}倍</span>
                        </div>
                        <div class="jessibuca-performance-item">
                            <span>播放模式 ${player.playback.isUseFpsRender ? '固定FPS' : '动态FPS'}</span>
                        </div>
                        ${
                        player.playback.isUseFpsRender ?
                            `
                             <div class="jessibuca-performance-item">
                                <span>固定FPS ${player.video.getStreamFps()}</span>
                            </div>
                            ` : ''
                    }
                    ` : ""
            }
                <div class="jessibuca-performance-item">
                    <span>解封装模式 ${DEMUX_TYPE_SHOW[demuxType]}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>解码模式 ${decoderType}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>渲染组件 ${renderType}</span>
                </div>
                ${
                renderType === RENDER_TYPE.canvas ? `
                    <div class="jessibuca-performance-item">
                        <span>渲染引擎 ${canvasRenderTpe}</span>
                    </div>
                    ` : ''
            }
                 <div class="jessibuca-performance-item">
                    <span>网络请求组件 ${streamType}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>视频格式 ${videoInfo.encType || '-'}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>视频(宽x高) ${videoInfo.width || '-'}x${videoInfo.height || '-'}</span>
                </div>
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>视频GOP(ms) ${videoIframeIntervalTs || '-'}</span>
                    </div>
                 ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>音频格式 ${AUDIO_ENC_CODE_SHOW[audioInfo.encType] || '-'}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>音频引擎 ${audioEngine || '-'}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>音频通道 ${audioInfo.channels || '-'}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>音频采样率 ${audioInfo.sampleRate || '-'}</span>
                </div>
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>播放器初始化(ms) ${times.playTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>开始请求地址(ms) ${times.streamTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>请求响应(ms) ${times.streamResponseTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>解封装(ms) ${times.demuxTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>解码(ms) ${times.decodeTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>页面开始渲染(ms) ${times.videoTimestamp}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>初始化到页面渲染(ms) ${times.allTimestamp}</span>
                    </div>
                    ${player.recording ? `
                        <div class="jessibuca-performance-item">
                            <span>视频录制时间 ${recordingDuration}</span>
                        </div>
                        <div class="jessibuca-performance-item">
                            <span>视频录制大小 ${recordingTotalSize}</span>
                        </div>
                    ` : ''
                }
                ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>音频码率(bit) ${stats.abps}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>视频码率(bit) ${stats.vbps}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>视频帧率(fps) ${stats.fps}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>视频峰值帧率(fps) ${stats.maxFps}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>解码帧率(fps) ${stats.dfps}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>音频缓冲帧 ${stats.audioBuffer}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>音频缓冲时长(ms) ${stats.audioBufferDelayTs}</span>
                </div>
                ${
                player.isPlayer() ?
                    `
                        <div class="jessibuca-performance-item">
                            <span>视频待解码帧 ${stats.demuxBuffer}</span>
                        </div>
                    ` :
                    `
                         <div class="jessibuca-performance-item">
                            <span>缓存时长(ms) ${stats.playbackCacheDataDuration}</span>
                         </div>
                         <div class="jessibuca-performance-item">
                            <span>视频待渲染帧 ${stats.playbackVideoBuffer}</span>
                        </div>
                        <div class="jessibuca-performance-item">
                            <span>视频待解码帧 ${stats.demuxBuffer}</span>
                        </div>
                        <div class="jessibuca-performance-item">
                            <span>音频待解码帧 ${stats.audioDemuxBuffer}</span>
                        </div>
                    `
            }
                <div class="jessibuca-performance-item">
                    <span>待解封装数据(byte) ${stats.flvBuffer}</span>
                </div>
                ${
                (player._opt.useMSE) ? `
                    <div class="jessibuca-performance-item">
                        <span>MSE缓冲时长(ms) ${stats.mseDelay}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>MSE待解码帧 ${stats.msePendingBuffer}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>MSE缓存时长(s) ${stats.mseStore}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>MSE解码间隔(ms) ${stats.mseDecodeDiffTimes}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>MSE解码时间(ms) ${stats.mseTs}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>MSE播放模式 ${stats.mseDecodePlaybackRate > 1 ? '加速' : '正常'}</span>
                    </div>
                    ` : ''
            }
                ${
                player._opt.useWCS ? `
                    <div class="jessibuca-performance-item">
                        <span>WCS解码间隔(ms) ${stats.wcsDecodeDiffTimes}</span>
                    </div>
                    ` : ''
            }
                ${
                player.isOldHls() ?
                    `<div class="jessibuca-performance-item">
                        <span>HLS缓冲时长(ms) ${stats.hlsDelay}</span>
                     </div>
                     ` : ''
            }
                ${
                player.isUseHls265() ?
                    `<div class="jessibuca-performance-item">
                        <span>HLS缓冲时长(ms) ${stats.hlsDelay}</span>
                     </div>
                     <div class="jessibuca-performance-item">
                        <span>HLS待解码帧 ${stats.hlsDemuxLength}</span>
                     </div>
                     <div class="jessibuca-performance-item">
                        <span>HLS待解码视频帧 ${stats.hlsDemuxVideoLength}</span>
                     </div>
                     <div class="jessibuca-performance-item">
                        <span>HLS待解码音频帧 ${stats.hlsDemuxAudioLength}</span>
                     </div>
                     ` : ''
            }
                ${
                (player.isPlayer() && videoPlaybackQuality) ? `
                    <div class="jessibuca-performance-item">
                        <span>Video已渲染帧 ${videoPlaybackQuality.renderedVideoFrames}</span>
                    </div>
                     <div class="jessibuca-performance-item">
                        <span>Video已丢弃帧 ${videoPlaybackQuality.droppedVideoFrames}</span>
                     </div>
                ` : ''
            }
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>网络延迟(ms) ${stats.netBuf}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>缓冲时长(ms) ${stats.buf}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>最新缓冲时长(ms) ${stats.pushLatestDelay}</span>
                    </div>
                ` : ''
            }
                ${
                (player._opt.useMSE || player.isWebrtcH264() || player.isAliyunRtc()) ? `
                    <div class="jessibuca-performance-item">
                        <span>video显示时间(s) ${stats.videoCurrentTime}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>video间隔时间(s) ${stats.videoCurrentTimeDiff}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>videoBuffer缓存时间(ms) ${stats.mseVideoBufferDelayTime}</span>
                    </div>
                ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>视频显示时间(ms) ${stats.currentPts || stats.ts}</span>
                </div>
                ${
                (player._opt.hasAudio && player.isAudioNotMute() && isFalse(isMSEDecodeAudio)) ?
                    `
                        <div class="jessibuca-performance-item">
                            <span>音频显示时间(ms) ${stats.audioTs}</span>
                        </div>
                        ${
                        player._opt.hasVideo ?
                            `
                            <div class="jessibuca-performance-item">
                                <span>音视频同步时间戳(ms) ${stats.audioSyncVideo}</span>
                            </div>
                            ` : ''
                    }
                        <div class="jessibuca-performance-item">
                            <span>音频播放模式 ${isAudioPlaybackRateSpeed ? '加速' : '正常'}</span>
                        </div>
                        ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>视频解码时间(ms) ${stats.dts}</span>
                </div>
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>解码前-解码后延迟(ms) ${stats.delayTs}</span>
                    </div>
                    <div class="jessibuca-performance-item">
                        <span>总延迟(网络+解码)(ms) ${stats.totalDelayTs}</span>
                    </div>
                ` : ''
            }
                ${
                (player.isPlayer() && stats.isStreamTsMoreThanLocal) ?
                    `<div class="jessibuca-performance-item">
                        <span>是否超过一倍率推流 是</span>
                    </div>
                   ` : ''
            }
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>是否播放流畅 ${stats.videoSmooth}</span>
                    </div>
                    ` : ''
            }
                ${
                player.isPlayer() ? `
                    <div class="jessibuca-performance-item">
                        <span>是否在丢帧 ${isDropping}</span>
                    </div>
                    ` : ''
            }
                <div class="jessibuca-performance-item">
                    <span>网速 ${kbpsShow}</span>
                </div>
                <div class="jessibuca-performance-item">
                    <span>播放时长(s) ${formatTimeTips(stats.pTs)}</span>
                </div>
                <div class="jessibuca-performance-item-block"></div>
            `
            control.$performancePanel.insertAdjacentHTML(
                'beforeend',
                renderDom
            )
        } else {
            control.$performancePanel.innerHTML = '';
            setStyle(control.$performancePanel, 'display', 'none');
        }
    })

    player.on(EVENTS.togglePerformancePanel, (flag) => {
        setStyle(control.$performance, 'display', flag ? 'none' : 'flex');
        setStyle(control.$performanceActive, 'display', flag ? 'flex' : 'none');
    })

    player.on(EVENTS.faceDetectActive, (flag) => {
        setStyle(control.$faceDetect, 'display', flag ? 'none' : 'flex');
        setStyle(control.$faceDetectActive, 'display', flag ? 'flex' : 'none');
    })


    player.on(EVENTS.objectDetectActive, (flag) => {
        setStyle(control.$objectDetect, 'display', flag ? 'none' : 'flex');
        setStyle(control.$objectDetectActive, 'display', flag ? 'flex' : 'none');
    })

    player.on(EVENTS.occlusionDetectActive, (flag) => {
        setStyle(control.$occlusionDetect, 'display', flag ? 'none' : 'flex');
        setStyle(control.$occlusionDetectActive, 'display', flag ? 'flex' : 'none');
    })

}
