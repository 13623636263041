import Emitter from "../utils/emitter";
import {AUDIO_ENC_CODE, EVENTS, FILE_SUFFIX, VIDEO_ENC_CODE} from "../constant";
import {parseAVCDecoderConfigurationRecord, parseAVCDecoderSPSAndPPS} from "../utils/h264";
import {parseHEVCDecoderConfigurationRecord$2, parseHEVCDecoderVPSAndSPSAndPPS} from "../utils/h265";
import {parseAACAudioSpecificConfig, readAACSpecificConfig} from "../utils/aac";

export default class CommonLoader extends Emitter {
    constructor(player) {
        super();
        this.TAG_NAME = 'recorderCommon';
        this.player = player;
        this.fileName = '';
        this._isRecording = false;
        this._recordingTimestamp = 0;
        this.recordingInterval = null;

        //
        this.sps = null;
        this.pps = null;
        this.vps = null;
        this.codecId = null;
        this.audioCodeId = null;
        this.metaInfo = {
            codecWidth: 0,
            codecHeight: 0,
            presentWidth: 0,
            presentHeight: 0,
            refSampleDuration: 0,
            timescale: 1000,
            avcc: null,
            videoType: ''
        }

        this.audioMetaInfo = {
            timescale: 1000,
            sampleRate: 0,
            refSampleDuration: 0,
            channelCount: 0,
            codec: '',
            originalCodec: '',
            audioType: '',
            extraData: new Uint8Array(0)
        }
    }

    destroy() {
        this._reset();
        this.sps = null;
        this.pps = null;
        this.vps = null;
        this.codecId = null;
        this.audioCodeId = null;
        this.metaInfo = null;
        this.audioMetaInfo = null;
    }

    get isH264() {
        return this.codecId === VIDEO_ENC_CODE.h264;
    }

    get isH265() {
        return this.codecId === VIDEO_ENC_CODE.h265;
    }


    setFileName(fileName) {
        this.fileName = fileName;
    }

    get isRecording() {
        return this._isRecording;
    }

    get recording() {
        return this._isRecording;
    }

    get recordTime() {
        return this._recordingTimestamp;
    }

    startRecord() {
        //  子类实现
    }

    // just for mp4
    handleAddNaluTrack(payload, isIframe, dts, cts) {
        //  子类实现
    }

    // just for mp4
    handleAddAudioTrack(payload, dts) {
        //  子类实现
    }

    handleAddTrack(arrayBuffer) {
        //  子类实现
    }

    stopRecordAndSave() {
        //  子类实现
    }

    startRecordingInterval() {
        //  子类实现
    }

    isWasmMp4() {
        return false;
    }

    stopRecordingInterval() {
        if (this.recordingInterval) {
            clearInterval(this.recordingInterval);
        }
        this.recordingInterval = null;
    }

    getToTalByteLength() {
        //  子类实现
        return 0;
    }

    _reset() {
        this.fileName = '';
        this._isRecording = false;
        this._recordingTimestamp = 0;
        this.stopRecordingInterval();
    }


    initMetaData(arrayBuffer, codecId) {
        let metaData;
        const extraData = arrayBuffer.slice(5);
        this.codecId = codecId;
        this.metaInfo.avcc = extraData;
        //
        if (codecId === VIDEO_ENC_CODE.h264) {
            metaData = parseAVCDecoderConfigurationRecord(extraData)
        } else if (codecId === VIDEO_ENC_CODE.h265) {
            metaData = parseHEVCDecoderVPSAndSPSAndPPS(extraData);
            const metaData2 = parseHEVCDecoderConfigurationRecord$2(arrayBuffer);
            metaData = Object.assign(metaData, metaData2);
        }
        //console.log('initMetaData-metaData', metaData);
        if (metaData) {
            if (metaData.vps) {
                this.vps = metaData.vps;
            }
            if (metaData.pps) {
                this.pps = metaData.pps;
            }
            if (metaData.sps) {
                this.sps = metaData.sps;
            }

            if (metaData.presentWidth) {
                this.metaInfo.presentWidth = metaData.presentWidth;
            }
            if (metaData.presentHeight) {
                this.metaInfo.presentHeight = metaData.presentHeight;
            }
            if (metaData.codecWidth) {
                this.metaInfo.codecWidth = metaData.codecWidth;
            }
            if (metaData.codecHeight) {
                this.metaInfo.codecHeight = metaData.codecHeight;
            }

            if (metaData.timescale) {
                this.metaInfo.timescale = metaData.timescale;
            }
            if (metaData.refSampleDuration) {
                this.metaInfo.refSampleDuration = metaData.refSampleDuration;
            }

            if (metaData.videoType) {
                this.metaInfo.videoType = metaData.videoType;
            }
        }

        // console.log('initMetaData-metaInfo', this.metaInfo);
    }


    initAudioMetaData(arrayBuffer, codecId) {
        this.audioCodeId = codecId;
        const size = (arrayBuffer[0] >> 1) & 0x01;
        let metaData = null;
        if (codecId === AUDIO_ENC_CODE.AAC) {
            metaData = readAACSpecificConfig(arrayBuffer);
            if (metaData) {
                if (metaData.channelCount) {
                    this.audioMetaInfo.channelCount = metaData.channelCount;
                }
                if (metaData.codec) {
                    this.audioMetaInfo.codec = metaData.codec;
                }
                if (metaData.originalCodec) {
                    this.audioMetaInfo.originalCodec = metaData.originalCodec;
                }
                if (metaData.config) {
                    this.audioMetaInfo.config = metaData.config;
                }
                if (metaData.sampleRate) {
                    this.audioMetaInfo.sampleRate = metaData.sampleRate;
                }
                if (this.audioMetaInfo.sampleRate && this.audioMetaInfo.timescale) {
                    // The decode result of an aac sample is 1024 PCM samples
                    this.audioMetaInfo.refSampleDuration = 1024 / this.audioMetaInfo.sampleRate * this.audioMetaInfo.timescale;
                }
            }
            this.audioMetaInfo.depth = size ? 16 : 8;
            this.audioMetaInfo.extraData = arrayBuffer.slice(2);
        } else {
            this.audioMetaInfo.depth = size === 0 ? 8 : 16;
        }
    }

    initAudioAacExtraData(arrayBuffer) {
        this.audioMetaInfo.extraData = new Uint8Array(arrayBuffer);
    }
}
