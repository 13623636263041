import {
    formatAvcVideoDecoderConfigure,
    formatHevcVideoDecoderConfigure,
    getBrowser, isFalse, isVideoSequenceHeader,
    noop,
    now,
    supportWCSDecodeHevc
} from "../utils";
import Emitter from "../utils/emitter";
import {
    AVC_PACKET_TYPE,
    ENCODED_VIDEO_TYPE,
    EVENTS,
    EVENTS_ERROR,
    FILE_SUFFIX,
    VIDEO_ENC_CODE, VIDEO_PAYLOAD_MIN_SIZE,
    WCS_ERROR
} from "../constant";
import {parseAVCDecoderConfigurationRecord} from "../utils/h264";
import {parseHEVCDecoderConfigurationRecord$2} from "../utils/h265";


export default class WebcodecsDecoder extends Emitter {
    constructor(player) {
        super();
        this.player = player;
        this.hasInit = false;
        this.isDecodeFirstIIframe = isFalse(player._opt.checkFirstIFrame) ? true : false;
        this.isInitInfo = false;
        this.prevTimestamp = null;
        this.decodeDiffTimestamp = null;
        this.prevDts = null;
        this.decoder = null;
        this.isWidthOrHeightChanged = false;
        this.initDecoder();
        player.debug.log('Webcodecs', 'init')
    }

    destroy() {
        if (this.decoder) {
            if (isFalse(this.isDecodeStateClosed())) {
                this.decoder.close();
            }
            this.decoder = null;
        }
        this.prevTimestamp = null;
        this.decodeDiffTimestamp = null;
        this.prevDts = null;
        this.hasInit = false;
        this.isInitInfo = false;
        this.isDecodeFirstIIframe = false;
        this.isWidthOrHeightChanged = false;
        this.off();
        this.player.debug.log('Webcodecs', 'destroy')
    }

    initDecoder() {
        const _this = this;
        this.decoder = new VideoDecoder({
            output(videoFrame) {
                _this.handleDecode(videoFrame)
            },
            error(error) {
                _this.handleError(error)
            }
        })
    }

    handleDecode(videoFrame) {
        if (!this.isInitInfo) {
            this.player.video.updateVideoInfo({
                width: videoFrame.codedWidth,
                height: videoFrame.codedHeight
            })
            this.player.video.initCanvasViewSize();
            this.isInitInfo = true;
        }

        // console.log('Webcodecs', 'handleDecode duration and timestamp', videoFrame.duration, videoFrame.timestamp)

        //  player
        if (this.player.isPlayer()) {
            this.player.updateStats({dfps: true})
            if (!this.player._times.videoStart) {
                this.player._times.videoStart = now();
                this.player.handlePlayToRenderTimes();
            }
            this.player.video.render({
                videoFrame,
                ts: videoFrame.timestamp
            })
            this.player.handleRender();
        } else if (this.player.isPlayback()) {
            //  playback
            this.player.updateStats({dfps: true})
            if (isFalse(this.player.playbackPause)) {
                //  is not pause
                if (this.player.playback.isUseLocalCalculateTime) {
                    this.player.playback.increaseLocalTimestamp();
                }
                if (this.player.playback.isUseFpsRender) {
                    this.player.video.pushData({
                        videoFrame,
                        ts: videoFrame.timestamp
                    });
                } else {
                    this.player.video.render$2({
                        videoFrame,
                        ts: videoFrame.timestamp
                    });
                }
            } else {
                // 暂停的时候，如果不需要清除缓存
                if (isFalse(this.player.playback.isPlaybackPauseClearCache) &&
                    this.player.playback.isCacheBeforeDecodeForFpsRender) {
                    if (this.player.playback.isUseFpsRender) {
                        this.player.video.pushData({
                            videoFrame,
                            ts: videoFrame.timestamp
                        });
                    }
                }
            }
        }
    }

    handleError(error) {
        this.player.debug.error('Webcodecs', 'VideoDecoder handleError：', error.code, error)
        const errorString = error.toString();
        if (errorString.indexOf(WCS_ERROR.unsupportedConfiguration) !== -1) {
            this.player.emitError(EVENTS_ERROR.webcodecsUnsupportedConfigurationError, errorString);
        } else if (errorString.indexOf(WCS_ERROR.decoderFailure) !== -1) {
            this.player.emitError(EVENTS_ERROR.webcodecsDecodeError, errorString);
        } else if (errorString.indexOf(WCS_ERROR.decodingError) !== -1) {
            this.player.emitError(EVENTS_ERROR.webcodecsDecodeError, errorString);
        } else if (errorString.indexOf(WCS_ERROR.decoderError) !== -1) {
            this.player.emitError(EVENTS_ERROR.webcodecsDecodeError, errorString);
        } else if(errorString.indexOf(WCS_ERROR.hevcDecodingIsNotSupported) !== -1){
            this.player.emitError(EVENTS_ERROR.webcodecsH265NotSupport)
        }
    }

    decodeVideo(payload, ts, isIframe, cts) {
        // this.player.debug.log('Webcodecs decoder', 'decodeVideo', ts, isIframe);

        const player = this.player;

        if (!player) {
            return;
        }

        if (this.player.isDestroyed()) {
            this.player.debug.warn('Webcodecs', 'decodeVideo() player is destroyed')
            return;
        }


        if (!this.hasInit) {
            if (isIframe && payload[1] === AVC_PACKET_TYPE.sequenceHeader) {
                const videoCodec = (payload[0] & 0x0F);
                this.player.video.updateVideoInfo({
                    encTypeCode: videoCodec
                })

                // 如果解码出来的是
                if (videoCodec === VIDEO_ENC_CODE.h265 && !supportWCSDecodeHevc()) {
                    const browserInfo = getBrowser();
                    this.player.debug.warn('Webcodecs', 'WebcodecsDecoder not support hevc decode', browserInfo.type, browserInfo.version);
                    this.player.emitError(EVENTS_ERROR.webcodecsH265NotSupport)
                    return;
                }
                if (!this.player._times.decodeStart) {
                    this.player._times.decodeStart = now();
                }

                let config = null;
                let videInfo = null;
                const extraData = payload.slice(5)
                if (videoCodec === VIDEO_ENC_CODE.h264) {
                    config = formatAvcVideoDecoderConfigure(extraData);
                    videInfo = parseAVCDecoderConfigurationRecord(extraData);
                } else if (videoCodec === VIDEO_ENC_CODE.h265) {
                    config = formatHevcVideoDecoderConfigure(extraData);
                    videInfo = parseHEVCDecoderConfigurationRecord$2(payload);
                }

                if (videInfo && videInfo.codecWidth && videInfo.codecHeight) {
                    config.codedHeight = videInfo.codecHeight;
                    config.codedWidth = videInfo.codecWidth;
                }

                if (this.player.recorder &&
                    this.player._opt.recordType === FILE_SUFFIX.mp4) {
                    this.player.recorder.initMetaData(payload, videoCodec);
                }

                // console.log('webcodecs decodeVideo config is ', config);
                // this.player.debug.log('Webcodecs', 'decodeVideo and webcodecs configure', config);
                try {
                    this.decoder.configure(config);
                    this.hasInit = true;
                } catch (e) {
                    this.player.debug.log('Webcodecs', 'configure error', e.code, e);
                    const errorString = e.toString();
                    if (errorString.indexOf(WCS_ERROR.hevcDecodingIsNotSupported) !== -1) {
                        this.player.emitError(EVENTS_ERROR.webcodecsH265NotSupport);
                    } else {
                        this.player.emitError(EVENTS_ERROR.webcodecsDecodeConfigureError);
                    }
                }
            }
        } else {

            // fix : Uncaught DOMException: Failed to execute 'decode' on 'VideoDecoder': A key frame is required after configure() or flush().
            if (!this.isDecodeFirstIIframe && isIframe) {
                this.isDecodeFirstIIframe = true;
            }

            if (this.isDecodeFirstIIframe) {
                if (this.isDecodeStateClosed()) {
                    this.player.debug.warn('Webcodecs', 'VideoDecoder isDecodeStateClosed true');
                    return;
                }

                if (isIframe && payload[1] === 0) {
                    const videoCodec = (payload[0] & 0x0F);
                    let config = {};

                    if (videoCodec === VIDEO_ENC_CODE.h264) {
                        let data = payload.slice(5);
                        config = parseAVCDecoderConfigurationRecord(data);
                    } else if (videoCodec === VIDEO_ENC_CODE.h265) {
                        config = parseHEVCDecoderConfigurationRecord$2(payload);
                    }
                    const videoInfo = this.player.video.videoInfo;
                    if ((videoInfo && videoInfo.width && videoInfo.height) &&
                        (config && config.codecWidth && config.codecHeight) &&
                        (config.codecWidth !== videoInfo.width || config.codecHeight !== videoInfo.height)) {

                        this.player.debug.warn('Webcodecs', `decodeVideo: video width or height is changed,
                        old width is ${videoInfo.width}, old height is ${videoInfo.height},
                         new width is ${config.codecWidth}, new height is ${config.codecHeight},
                         and emit change event`)

                        this.isWidthOrHeightChanged = true;
                        this.player.emitError(EVENTS_ERROR.wcsWidthOrHeightChange);
                    }
                }

                if (this.isWidthOrHeightChanged) {
                    this.player.debug.warn('Webcodecs', `decodeVideo: video width or height is changed, and return`)
                    return;
                }

                if (isVideoSequenceHeader(payload)) {
                    this.player.debug.warn('Webcodecs', 'decodeVideo and payload is video sequence header so drop this frame');
                    return;
                }

                if (payload.byteLength < VIDEO_PAYLOAD_MIN_SIZE) {
                    this.player.debug.warn('Webcodecs', `decodeVideo and payload is too small , payload length is ${payload.byteLength}`)
                    return;
                }

                let isFirst = false;
                let nowTime = new Date().getTime()
                if (!this.prevTimestamp) {
                    this.prevTimestamp = nowTime;
                    isFirst = true;
                }

                const diffTime = nowTime - this.prevTimestamp;
                this.decodeDiffTimestamp = diffTime;
                if (diffTime > 500 &&
                    !isFirst &&
                    this.player.isPlayer()) {
                    this.player.debug.warn('Webcodecs', 'decodeVideo diff time is ', diffTime);
                }


                const buffer = payload.slice(5);
                const chunk = new EncodedVideoChunk({
                    data: buffer,
                    timestamp: ts,
                    type: isIframe ? ENCODED_VIDEO_TYPE.key : ENCODED_VIDEO_TYPE.delta
                })
                this.player.emit(EVENTS.timeUpdate, ts);

                // if (this.player.recorder &&
                //     this.player.recorder.isRecording &&
                //     this.player._opt.recordType === FILE_SUFFIX.mp4) {
                //     this.player.recorder.handleAddNaluTrack(buffer, isIframe, ts, cts);
                // }

                try {
                    this.decoder.decode(chunk);
                } catch (e) {
                    this.player.debug.error('Webcodecs', 'VideoDecoder', e)
                    const errorString = e.toString();
                    if (errorString.indexOf(WCS_ERROR.keyframeIsRequiredError) !== -1) {
                        this.player.emitError(EVENTS_ERROR.webcodecsDecodeError);
                    } else if (errorString.indexOf(WCS_ERROR.canNotDecodeClosedCodec) !== -1) {
                        this.player.emitError(EVENTS_ERROR.webcodecsDecodeError);
                    }
                }

                this.prevTimestamp = new Date().getTime();
            } else {
                this.player.debug.log('Webcodecs', 'VideoDecoder first frame is not iFrame')
            }
        }
    }


    getDecodeDiffTimes() {
        return this.decodeDiffTimestamp;
    }

    isDecodeStateClosed() {
        return this.decoder.state === 'closed';
    }

    isDecodeStateConfigured() {
        return this.decoder.state === 'configured';
    }

    isDecodeStateUnConfigured() {
        return this.decoder.state === 'unconfigured';
    }
}
