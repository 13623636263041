import CommonLoader from "./commonLoader";
import {AUDIO_ENC_CODE, EVENTS, FILE_SUFFIX, RECORDING_TYPE} from "../constant";
import {isFalse, now, saveBlobToFile} from "../utils";
import Mp3FrameParseLoader from "../demux/mp3Loader";

export default class WasmMp4Loader extends CommonLoader {
    constructor(player) {
        super(player);
        this.TAG_NAME = 'recorderWasmMP4';
        this._reset();
        //  config wasmMp4Recorder
        this.wasmMp4Recorder = new window.JessibucaProMp4Recorder({
            debug: player._opt.debug,
            debugLevel: player._opt.debugLevel,
            debugUuid: player._opt.debugUuid,
            decoder: player._opt.wasmMp4RecorderDecoder,
        });
        this.mp3Demuxer = null;
        this.isG711 = false;
        player.debug.log(this.TAG_NAME, 'init');
    }

    destroy() {
        super.destroy();
        if (this.mp3Demuxer) {
            this.mp3Demuxer.destroy();
            this.mp3Demuxer = null;
        }
        this.isG711 = false;
        this._reset();
        this.player.debug.log(this.TAG_NAME, 'destroy');
    }

    _reset() {
        super._reset();
        this.cacheTrack = {};
        this.audioCacheTrack = {};
        this.totalDuration = 0;
        this.totalAudioDuration = 0;
        this.totalByteLength = 0;
        this.totalAudioByteLength = 0;
        this.hasAudio = false;
        this.hasVideo = false;
    }

    getType() {
        return FILE_SUFFIX.mp4
    }

    isWasmMp4() {
        return true;
    }

    getTotalDuration() {
        return this.totalDuration / 1000;
    }

    //
    getToTalByteLength() {
        return this.totalByteLength + this.totalAudioByteLength;
    }

    startRecord() {
        const debug = this.player.debug;

        const audioInfo = this.player.getAudioInfo();
        const videoInfo = this.player.getVideoInfo();

        const params = {};

        if (this.codecId) {
            const video = {
                type: this.codecId,
                width: videoInfo.width,
                height: videoInfo.height,
                extraData: this.metaInfo.avcc,
            }
            params.video = video;
            this.hasVideo = true;
        }

        if (audioInfo.encTypeCode) {
            this.isG711 = audioInfo.encTypeCode === AUDIO_ENC_CODE.ALAW ||
                audioInfo.encTypeCode === AUDIO_ENC_CODE.MULAW;
            const audio = {
                type: audioInfo.encTypeCode,
                sampleRate: audioInfo.sampleRate,
                channels: audioInfo.channels,
                extraData: this.audioMetaInfo.extraData,
                depth: audioInfo.depth,
            }
            this.audioCodeId = audioInfo.encTypeCode;
            params.audio = audio;
            this.hasAudio = true;
        }

        this.wasmMp4Recorder.startRecord(params).then(() => {
            this._isRecording = true;
            this.player.emit(EVENTS.recording, true);
            debug.log(this.TAG_NAME, 'start recording');
            this.player.emit(EVENTS.recordStart);
            this.startRecordingInterval();
        }).catch((e) => {
            debug.error(this.TAG_NAME, 'startRecord error', e);
            this.player.emitError(EVENTS.recordCreateError, e);
        })
    }

    startRecordingInterval() {
        this.stopRecordingInterval();
        this.recordingInterval = window.setInterval(() => {
            this.player.emit(EVENTS.recordingTimestamp, this.getTotalDuration());
        }, 1000);
    }


    stopRecordAndSave(type = RECORDING_TYPE.download, fileName) {
        return new Promise((resolve, reject) => {
            if (!this.isRecording) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, isRecording is false ');

                return reject('stop recording fail, isRecording is false ');
            }

            if (this.totalDuration === 0) {
                this.player.debug.error(this.TAG_NAME, 'stop recording fail, totalDuration is 0 ');
                return reject('stop recording fail, totalDuration is 0 ');
            }
            if (fileName) {
                this.setFileName(fileName);
            }

            this.wasmMp4Recorder.stopRecord().then((blob) => {
                if (type === RECORDING_TYPE.blob) {
                    resolve(blob);
                    this.player.emit(EVENTS.recordBlob, blob);
                } else {
                    resolve();
                    const suffix = this.isG711 ? FILE_SUFFIX.mov : FILE_SUFFIX.mp4;
                    const fileName = (this.fileName || now()) + '.' + suffix;
                    saveBlobToFile(fileName, blob);
                }
            }).catch((e) => {
                this.player.debug.error(this.TAG_NAME, 'stopRecord error', e);
                reject(e);
            }).finally(() => {
                this._reset();
                this.player.emit(EVENTS.recording, false);
            })
        })
    }

    handleAddAudioTrack(payload, dts) {
        if (this.audioCodeId === AUDIO_ENC_CODE.MP3) {
            if (!this.mp3Demuxer) {
                this.mp3Demuxer = new Mp3FrameParseLoader(this.player);
                this.mp3Demuxer.on('data', (audioBuffer, audioTs) => {
                    this._handleAddAudioTrack(audioBuffer, audioTs);
                })
            }
            this.mp3Demuxer.dispatch(payload, dts);
        } else {
            this._handleAddAudioTrack(payload, dts)
        }
    }

    _handleAddAudioTrack(payload, dts) {
        if (isFalse(this.hasAudio)) {
            return;
        }

        if (this.audioCacheTrack.id && dts >= this.audioCacheTrack.dts) {
            this.audioCacheTrack.duration = dts - this.audioCacheTrack.dts;
            this.totalAudioDuration += this.audioCacheTrack.duration;
            this.totalAudioByteLength += this.audioCacheTrack.payload.byteLength;
            this.wasmMp4Recorder.sendAudioFrame(this.audioCacheTrack.payload, this.audioCacheTrack.dts);
        } else {
            this.audioCacheTrack = {}
        }

        this.audioCacheTrack = {
            id: 2,
            payload: payload,
            dts: dts,
        }
    }

    handleAddNaluTrack(payload, isIFrame, dts, cts) {

        if (isFalse(this.hasVideo)) {
            return;
        }

        if (this.cacheTrack.id && dts >= this.cacheTrack.dts) {
            this.cacheTrack.duration = dts - this.cacheTrack.dts;
            this.totalDuration += this.cacheTrack.duration;
            this.totalByteLength += this.cacheTrack.payload.byteLength;
            this.wasmMp4Recorder.sendVideoFrame(this.cacheTrack.payload, this.cacheTrack.isIFrame, this.cacheTrack.dts, this.cacheTrack.cts);
        } else {
            this.cacheTrack = {}
        }

        this.cacheTrack = {
            id: 1,
            payload: payload,
            isIFrame: isIFrame,
            dts: dts,
            cts: cts,
        }
    }
}
