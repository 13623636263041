import Emitter from "../utils/emitter";
import {
    clamp,
    createWorkletModuleUrl,
    getDefaultTalkOptions,
    isEmpty, isPc,
    isSupportGetUserMedia, isTrue,
    now,
    uuid16
} from "../utils";
import {
    DEFAULT_TALK_OPTIONS,
    EVENTS,
    EVENTS_ERROR,
    RTP_PAYLOAD_TYPE,
    TALK_ENC_TYPE, TALK_ENGINE, TALK_PACKAGE_TCP_SEND_TYPE,
    TALK_PACKET_TYPE, URL_OBJECT_CLEAR_TIME,
    WEBSOCKET_EVENTS,
    WEBSOCKET_STATUS
} from "../constant";
import Resampler from "../utils/resampler";
import {floatTo16BitPCM, floatTo8BitPCM} from "../utils/arraybuffer";
import {g711aEncoder, g711uEncoder} from "../utils/g711";
import Debug from "../utils/debug";
import Events from "../utils/events";


export default class Talk extends Emitter {
    constructor(player, options = {}) {
        super()
        /**@type {import('./constant').DEFAULT_TALK_OPTIONS}*/
        this._opt = {};

        if (player) {
            this.player = player;
        }
        this.tag = 'talk';
        const defaultOptions = getDefaultTalkOptions();

        this._opt = Object.assign({}, defaultOptions, options);

        this._opt.sampleRate = parseInt(this._opt.sampleRate, 10);
        this._opt.sampleBitsWidth = parseInt(this._opt.sampleBitsWidth, 10);
        this.audioContext = null;
        this.gainNode = null;
        this.recorder = null;
        this.workletRecorder = null;
        this.biquadFilter = null;
        this.userMediaStream = null;
        this.clearWorkletUrlTimeout = null;
        // buffersize
        this.bufferSize = 512; //
        this._opt.audioBufferLength = this.calcAudioBufferLength();
        this.audioBufferList = [];
        // socket
        this.socket = null;
        this.socketStatus = WEBSOCKET_STATUS.notConnect;

        //
        this.mediaStreamSource = null;
        this.heartInterval = null;
        this.checkGetUserMediaTimeout = null;
        this.wsUrl = null;
        this.startTimestamp = 0;

        // 报文数据
        this.sequenceId = 0;
        this.tempTimestamp = null;
        this.tempRtpBufferList = [];
        this.events = new Events(this);

        this._initTalk();
        //
        if (!this.player) {
            this.debug = new Debug(this)
        }

        if ((this._opt.encType === TALK_ENC_TYPE.g711a || this._opt.encType === TALK_ENC_TYPE.g711u) &&
            !(this._opt.sampleRate === 8000 && this._opt.sampleBitsWidth === 16)) {
            this.warn(this.tag, `
            encType is ${this._opt.encType} and sampleBitsWidth is ${this._opt.sampleBitsWidth}, set sampleBitsWidth to ${this._opt.sampleBitsWidth}。
            ${this._opt.encType} only support sampleRate 8000 and sampleBitsWidth 16`);
        }


        this.log(this.tag, 'init', JSON.stringify(this._opt))
    }

    destroy() {

        if (this.clearWorkletUrlTimeout) {
            clearTimeout(this.clearWorkletUrlTimeout);
            this.clearWorkletUrlTimeout = null;
        }

        //
        if (this.userMediaStream) {
            this.userMediaStream.getTracks && this.userMediaStream.getTracks().forEach((track) => {
                track.stop()
            })
            this.userMediaStream = null;
        }

        if (this.mediaStreamSource) {
            this.mediaStreamSource.disconnect();
            this.mediaStreamSource = null;
        }
        if (this.recorder) {
            this.recorder.disconnect();
            this.recorder.onaudioprocess = null;
            this.recorder = null;
        }

        if (this.biquadFilter) {
            this.biquadFilter.disconnect();
            this.biquadFilter = null;
        }

        if (this.gainNode) {
            this.gainNode.disconnect();
            this.gainNode = null;
        }

        if (this.workletRecorder) {
            this.workletRecorder.disconnect();
            this.workletRecorder = null;
        }

        if (this.socket) {
            if (this.socketStatus === WEBSOCKET_STATUS.open) {
                this._sendClose();
            }
            this.socket.close();
            this.socket = null;
        }
        this._stopHeartInterval();
        this._stopCheckGetUserMediaTimeout();
        this.audioContext = null;
        this.gainNode = null;
        this.recorder = null;
        this.audioBufferList = [];
        this.sequenceId = 0;
        this.wsUrl = null;
        this.tempTimestamp = null;
        this.tempRtpBufferList = [];
        this.startTimestamp = 0;

        this.log('talk', 'destroy');
    }

    addRtpToBuffer(rtp) {
        const len = rtp.length + this.tempRtpBufferList.length;
        const buffer = new Uint8Array(len);
        buffer.set(this.tempRtpBufferList, 0);
        buffer.set(rtp, this.tempRtpBufferList.length);
        this.tempRtpBufferList = buffer;
        //console.log('addRtpToBuffer length and byteLength ', this.tempRtpBufferList.length, this.tempRtpBufferList.byteLength)
    }

    downloadRtpFile() {
        const blob = new Blob([this.tempRtpBufferList]);
        try {
            const oa = document.createElement('a');
            oa.href = window.URL.createObjectURL(blob);
            oa.download = Date.now() + '.rtp';
            oa.click();
            window.URL.revokeObjectURL(oa.href);
        } catch (e) {
            console.error('downloadRtpFile', e);
        }
    }

    calcAudioBufferLength() {
        const {sampleRate, sampleBitsWidth} = this._opt;
        //  默认走的是 20ms 8000 采样率 16 位精度
        return ((sampleRate * 8) * (20 / 1000)) / 8;
    }

    get socketStatusOpen() {
        return this.socketStatus === WEBSOCKET_STATUS.open;
    }

    log(...args) {
        this._log('log', ...args)
    }

    warn(...args) {
        this._log('warn', ...args)
    }

    error(...args) {
        this._log('error', ...args)
    }

    _log(type, ...args) {
        if (this.player) {
            this.player.debug[type](...args);

        } else if (this.debug) {
            this.debug[type](...args);
        } else {
            console[type](...args);
        }
    }

    _getSequenceId() {
        return ++this.sequenceId
    }

    _createWebSocket() {
        return new Promise((resolve, reject) => {
            const proxy = this.events.proxy;
            this.socket = new WebSocket(this.wsUrl);
            this.socket.binaryType = 'arraybuffer';
            this.emit(EVENTS.talkStreamStart);

            proxy(this.socket, WEBSOCKET_EVENTS.open, () => {
                this.socketStatus = WEBSOCKET_STATUS.open;
                this.log(this.tag, 'websocket open -> do talk');
                this.emit(EVENTS.talkStreamOpen);
                resolve();
                this._doTalk();
            })

            proxy(this.socket, WEBSOCKET_EVENTS.message, (event) => {
                this.log(this.tag, 'websocket message', event.data);
            })

            proxy(this.socket, WEBSOCKET_EVENTS.close, (e) => {
                this.socketStatus = WEBSOCKET_STATUS.close;
                this.warn(this.tag, 'websocket close -> reject', e);
                this.emit(EVENTS.talkStreamClose);
                reject(e);
            })
            proxy(this.socket, WEBSOCKET_EVENTS.error, (error) => {
                this.socketStatus = WEBSOCKET_STATUS.error;
                this.error(this.tag, 'websocket error -> reject', error)
                this.emit(EVENTS.talkStreamError, error);
                reject(error);
            })
        })
    }

    _sendClose() {

    }


    _initTalk() {

        this._initMethods();
        if (this._opt.engine === TALK_ENGINE.worklet) {
            this._initWorklet();
        } else if (this._opt.engine === TALK_ENGINE.script) {
            this._initScriptProcessor();
        }
        this.log(this.tag, 'audioContext samplerate', this.audioContext.sampleRate)
    }

    _initMethods() {
        //
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)({sampleRate: 48000})
        this.gainNode = this.audioContext.createGain();
        // default 1
        this.gainNode.gain.value = 1;

        // 消音器
        this.biquadFilter = this.audioContext.createBiquadFilter();
        this.biquadFilter.type = "lowpass";
        this.biquadFilter.frequency.value = 3000;

        this.resampler = new Resampler({
            fromSampleRate: this.audioContext.sampleRate,
            toSampleRate: this._opt.sampleRate,
            channels: this._opt.numberChannels,
            inputBufferSize: this.bufferSize
        })
    }

    _initScriptProcessor() {
        //
        const createScript = this.audioContext.createScriptProcessor || this.audioContext.createJavaScriptNode;
        this.recorder = createScript.apply(this.audioContext, [this.bufferSize, this._opt.numberChannels, this._opt.numberChannels])
        this.recorder.onaudioprocess = (e) => this._onaudioprocess(e);
    }

    _initWorklet() {
        function workletProcess() {
            class TalkProcessor extends AudioWorkletProcessor {
                constructor(options) {
                    super();
                    this._cursor = 0;
                    this._bufferSize = options.processorOptions.bufferSize;
                    this._buffer = new Float32Array(this._bufferSize);
                }

                process(inputs, outputs, parameters) {
                    if (!inputs.length || !inputs[0].length) {
                        return true;
                    }
                    for (let i = 0; i < inputs[0][0].length; i++) {
                        this._cursor += 1;
                        if (this._cursor === this._bufferSize) {
                            this._cursor = 0;
                            this.port.postMessage({
                                eventType: 'data',
                                buffer: this._buffer
                            });
                        }
                        this._buffer[this._cursor] = inputs[0][0][i];
                    }

                    return true;
                }
            }

            registerProcessor('talk-processor', TalkProcessor)
        }

        const workletUrl = createWorkletModuleUrl(workletProcess);
        this.audioContext.audioWorklet && this.audioContext.audioWorklet.addModule(workletUrl).then(() => {
            const workletNode = new AudioWorkletNode(this.audioContext, 'talk-processor', {
                processorOptions: {
                    bufferSize: this.bufferSize
                }
            })
            workletNode.connect(this.gainNode);
            workletNode.port.onmessage = (e) => {
                if (e.data.eventType === 'data') {
                    this._encodeAudioData(e.data.buffer);
                }
            }
            this.workletRecorder = workletNode;
        })

        this.clearWorkletUrlTimeout = setTimeout(() => {
            URL.revokeObjectURL(workletUrl);
            this.clearWorkletUrlTimeout = null;
        }, URL_OBJECT_CLEAR_TIME)
    }


    _onaudioprocess(e) {
        // 数组里的每个数字都是32位的单精度浮点数
        // 默认是单精度
        const float32Array = e.inputBuffer.getChannelData(0);
        // send 出去。
        this._encodeAudioData(new Float32Array(float32Array));
    }

    _encodeAudioData(float32Array) {
        // 没有说话
        if (float32Array[0] === 0 && float32Array[1] === 0) {
            this.log(this.tag, 'empty audio data')
            return;
        }

        const resampleBuffer = this.resampler.resample(float32Array);
        // default 32Bit
        let tempArrayBuffer = resampleBuffer;
        if (this._opt.sampleBitsWidth === 16) {
            tempArrayBuffer = floatTo16BitPCM(resampleBuffer);
        } else if (this._opt.sampleBitsWidth === 8) {
            tempArrayBuffer = floatTo8BitPCM(resampleBuffer);
        }

        if (tempArrayBuffer.buffer !== null) {
            let typedArray = null;
            if (this._opt.encType === TALK_ENC_TYPE.g711a) {
                typedArray = g711aEncoder(tempArrayBuffer)
            } else if (this._opt.encType === TALK_ENC_TYPE.g711u) {
                typedArray = g711uEncoder(tempArrayBuffer)
            } else if (this._opt.encType === TALK_ENC_TYPE.pcm) {
                typedArray = tempArrayBuffer;
            }
            // 变成了8位 array 了
            const unit8Array = new Uint8Array(typedArray);
            for (let i = 0; i < unit8Array.length; i++) {
                let audioBufferLength = this.audioBufferList.length;
                this.audioBufferList[audioBufferLength++] = unit8Array[i];
                if (this.audioBufferList.length === this._opt.audioBufferLength) {
                    this._sendTalkMsg(new Uint8Array(this.audioBufferList));
                    this.audioBufferList = [];
                }
            }
        }
    }

    _parseAudioMsg(typedArray) {
        let typeArray2 = null;
        // rtp reumx just support g711a or g711u or opus
        if (this._opt.packetType === TALK_PACKET_TYPE.rtp &&
            (this._opt.encType === TALK_ENC_TYPE.g711a || this._opt.encType === TALK_ENC_TYPE.g711u)) {
            typeArray2 = this.rtpPacket(typedArray);
        } else if (this._opt.packetType === TALK_PACKET_TYPE.empty) {
            // 默认
            typeArray2 = typedArray;
        }

        return typeArray2;
    }

    rtpPacket(typedArray) {
        const rtpHeader = [];
        //2 bits RTP的版本，这里统一为2
        const version = 2;
        //1 bit 如果置1，在packet的末尾被填充，填充有时是方便一些针对固定长度的算法的封装
        const padding = 0;
        //1 bit 如果置1，在RTP Header会跟着一个header extension
        const extension = 0;
        //4 bits 表示头部后 特约信源 的个数
        const csrcCount = 0;
        //1 bit 不同的有效载荷有不同的含义，marker=1; 对于视频，标记一帧的结束；对于音频，标记会话的开始。
        const marker = 1;
        //7 bits 表示所传输的多媒体的类型，
        let playloadType = 0;
        //16 bits 每个RTP packet的sequence number会自动加一，以便接收端检测丢包情况
        let sequenceNumber = 0;
        //32 bits 时间戳
        let timestamp = 0;
        //32 bits 同步源的id，每两个同步源的id不能相同
        const ssrc = this._opt.rtpSsrc;
        //
        const frameLen = typedArray.length;

        if (this._opt.encType === TALK_ENC_TYPE.g711a) {
            playloadType = RTP_PAYLOAD_TYPE.g711a;
        } else if (this._opt.encType === TALK_ENC_TYPE.g711u) {
            playloadType = RTP_PAYLOAD_TYPE.g711u;
        } else if (this._opt.encType === TALK_ENC_TYPE.opus) {
            playloadType = RTP_PAYLOAD_TYPE.opus
        }

        if (!this.startTimestamp) {
            this.startTimestamp = now();
        }
        timestamp = now() - this.startTimestamp;
        sequenceNumber = this._getSequenceId();

        // frame length
        // 需要在rtp头前面加两个字节，表示数据包长度（整个rtp包长度）
        // 国标流udp不需要两个字节长度
        // 国标流tcp需要两个字节长度
        // websocket目前是按照tcp的做法做的
        let index = 0;

        if (this._opt.packetTcpSendType === TALK_PACKAGE_TCP_SEND_TYPE.tcp) {
            const rtpFrameLen = frameLen + 12;
            //  0
            rtpHeader[index++] = 0xFF & (rtpFrameLen >> 8);
            //  1
            rtpHeader[index++] = 0xFF & (rtpFrameLen >> 0);
        }

        rtpHeader[index++] = ((version << 6) + (padding << 5) + (extension << 4) + csrcCount);
        rtpHeader[index++] = ((marker << 7) + playloadType);
        rtpHeader[index++] = (sequenceNumber / (0xff + 1));
        rtpHeader[index++] = (sequenceNumber % (0xff + 1));
        rtpHeader[index++] = ((timestamp / (0xffff + 1)) / (0xff + 1));
        rtpHeader[index++] = ((timestamp / (0xffff + 1)) % (0xff + 1));
        rtpHeader[index++] = ((timestamp % (0xffff + 1)) / (0xff + 1));
        rtpHeader[index++] = ((timestamp % (0xffff + 1)) % (0xff + 1));
        rtpHeader[index++] = ((ssrc / (0xffff + 1)) / (0xff + 1));
        rtpHeader[index++] = ((ssrc / (0xffff + 1)) % (0xff + 1));
        rtpHeader[index++] = ((ssrc % (0xffff + 1)) / (0xff + 1));
        rtpHeader[index++] = ((ssrc % (0xffff + 1)) % (0xff + 1));

        let typeArray2 = rtpHeader.concat([...typedArray]);

        let binary = new Uint8Array(typeArray2.length);
        for (let ii = 0; ii < typeArray2.length; ii++) {
            binary[ii] = typeArray2[ii];
        }

        return binary;
    }


    opusPacket(typedArray) {
        //TODO:待完成

        return typedArray;
    }

    _sendTalkMsg(typedArray) {
        if (this.tempTimestamp === null) {
            this.tempTimestamp = now();
        }
        const timestamp = now();
        const diff = timestamp - this.tempTimestamp;
        const typedArray2 = this._parseAudioMsg(typedArray)
        this.log(this.tag, `'send talk msg and diff is ${diff} and byteLength is ${typedArray2.byteLength} and length is ${typedArray2.length}, and g711 length is ${typedArray.length}`);

        if (isTrue(this._opt.saveRtpToFile)) {
            if (this._opt.packetType === TALK_PACKET_TYPE.rtp) {
                this.addRtpToBuffer(typedArray2);
            }
        }


        if (typedArray2) {
            if (this.socketStatusOpen) {
                this.socket.send(typedArray2.buffer);
            } else {
                this.emit(EVENTS_ERROR.tallWebsocketClosedByError);
            }
        }
        this.tempTimestamp = timestamp;
    }

    _doTalk() {
        this._getUserMedia();
        // this._getUserMedia2();
        // this._getUserMedia3();
    }

    _getUserMedia() {
        this.log(this.tag, 'getUserMedia')
        // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
        if (window.navigator.mediaDevices === undefined) {
            window.navigator.mediaDevices = {};
        }

        // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
        // 因为这样可能会覆盖已有的属性。这里我们只会在没有 getUserMedia 属性的时候添加它。
        if (window.navigator.mediaDevices.getUserMedia === undefined) {
            this.log(this.tag, 'window.navigator.mediaDevices.getUserMedia is undefined and init function')
            window.navigator.mediaDevices.getUserMedia = function (constraints) {
                // 首先，如果有 getUserMedia 的话，就获得它
                var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

                // 一些浏览器根本没实现它 - 那么就返回一个 error 到 promise 的 reject 来保持一个统一的接口
                // 由于受浏览器的限制，navigator.mediaDevices.getUserMedia在https协议下是可以正常使用的，
                // 而在http协议下只允许localhost/127.0.0.1这两个域名访问，
                // 因此在开发时应做好容灾处理，上线时则需要确认生产环境是否处于https协议下。
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // 否则，为老的 navigator.getUserMedia 方法包裹一个 Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }

        if (this._opt.checkGetUserMediaTimeout) {
            this._startCheckGetUserMediaTimeout();
        }
        //  最后经过反复测试，只有noiseSuppression+echoCancellation同时生效时，打开录音后再播放音频，系统音量一定会变小，
        //  很惨的是getUserMedia只要你没有配置这两个参数，默认就是同时开启的；只要你给这两参数任意一个设为false，或者都设为false，
        //  就不会影响手机系统音量。
        window.navigator.mediaDevices.getUserMedia({
            audio: this._opt.audioConstraints,
            video: false
        }).then((stream) => {
            this.log(this.tag, 'getUserMedia success')
            this.userMediaStream = stream;
            this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream)
            this.mediaStreamSource.connect(this.biquadFilter);
            if (this.recorder) {
                this.biquadFilter.connect(this.recorder)
                this.recorder.connect(this.gainNode);
            } else if (this.workletRecorder) {
                this.biquadFilter.connect(this.workletRecorder)
                this.workletRecorder.connect(this.gainNode);
            }

            this.gainNode.connect(this.audioContext.destination);
            this.emit(EVENTS.talkGetUserMediaSuccess);

            // check stream inactive
            if (stream.oninactive === null) {
                stream.oninactive = (e) => {
                    this._handleStreamInactive(e);
                }
            }
        }).catch((e) => {
            this.error(this.tag, 'getUserMedia error', e.toString());
            this.emit(EVENTS.talkGetUserMediaFail, e.toString());
        }).finally(() => {
            this.log(this.tag, 'getUserMedia finally');
            this._stopCheckGetUserMediaTimeout();
        })
    }


    _getUserMedia2() {
        this.log(this.tag, 'getUserMedia')
        navigator.mediaDevices ?
            navigator.mediaDevices.getUserMedia(
                {audio: true}).then(
                (stream) => {
                    this.log(this.tag, 'getUserMedia2 success')
                }
            ) :
            navigator.getUserMedia(
                {audio: true},
                this.log(this.tag, 'getUserMedia2 success'),
                this.log(this.tag, 'getUserMedia2 fail')
            );
    }

    async _getUserMedia3() {
        this.log(this.tag, 'getUserMedia3')
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    latency: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                    echoCancellation: true,
                    sampleRate: 48000,
                    channelCount: 1
                },
                video: false
            });
            console.log('getUserMedia() got stream:', stream);
            this.log(this.tag, 'getUserMedia3 success')
        } catch (e) {
            this.log(this.tag, 'getUserMedia3 fail')
        }
    }

    _handleStreamInactive(e) {
        if (this.userMediaStream) {
            this.warn(this.tag, 'stream oninactive', e)
            this.emit(EVENTS.talkStreamInactive);
        }
    }

    _startCheckGetUserMediaTimeout() {
        this._stopCheckGetUserMediaTimeout();
        this.checkGetUserMediaTimeout = setTimeout(() => {
            this.log(this.tag, 'check getUserMedia timeout')
            this.emit(EVENTS.talkGetUserMediaTimeout);
        }, this._opt.getUserMediaTimeout)
    }

    _stopCheckGetUserMediaTimeout() {
        if (this.checkGetUserMediaTimeout) {
            this.log(this.tag, 'stop checkGetUserMediaTimeout')
            clearTimeout(this.checkGetUserMediaTimeout);
            this.checkGetUserMediaTimeout = null;
        }
    }

    _startHeartInterval() {
        // 定时发送心跳，
        this.heartInterval = setInterval(() => {
            this.log(this.tag, 'heart interval');
            let data = [0x23, 0x24, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
            data = new Uint8Array(data);
            this.socket.send(data.buffer);
        }, 15 * 1000);
    }

    _stopHeartInterval() {
        if (this.heartInterval) {
            this.log(this.tag, 'stop heart interval');
            clearInterval(this.heartInterval);
            this.heartInterval = null;
        }
    }


    startTalk(wsUrl) {
        return new Promise((resolve, reject) => {
            if (!isSupportGetUserMedia()) {
                return reject('not support getUserMedia');
            }
            this.wsUrl = wsUrl;

            if (this._opt.testMicrophone) {
                this._doTalk();
            } else {
                if (!this.wsUrl) {
                    return reject('wsUrl is null');
                }

                this._createWebSocket().catch((e) => {
                    reject(e)
                });
            }
            // reject
            this.once(EVENTS.talkGetUserMediaFail, () => {
                reject('getUserMedia fail');
            })
            // only get user media success and resolve
            this.once(EVENTS.talkGetUserMediaSuccess, () => {
                resolve()
            })
        })

    }

    setVolume(volume) {
        volume = parseFloat(volume).toFixed(2);
        if (isNaN(volume)) {
            return;
        }
        volume = clamp(volume, 0, 1);
        this.gainNode.gain.value = volume;
    }

    getOption() {
        return this._opt;
    }

    get volume() {
        return this.gainNode ? parseFloat(this.gainNode.gain.value * 100).toFixed(0) : null;
    }
}
